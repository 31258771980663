import { ATTRIBUTE_TYPE } from '../constants';

import { ResourceDefinition } from '../types';

const LoopParticipantInvitation: ResourceDefinition = {
  attributes: {
    invitedParticipantId: ATTRIBUTE_TYPE.STRING,
    inviterParticipantId: ATTRIBUTE_TYPE.STRING,
    inviterViewId: ATTRIBUTE_TYPE.STRING,
    inviterName: ATTRIBUTE_TYPE.STRING,
    status: ATTRIBUTE_TYPE.STRING,
    accepted: ATTRIBUTE_TYPE.BOOLEAN,
  },
};

export default LoopParticipantInvitation;
