import analytics, { Categories } from '~/analytics';
import detectNetwork from '~/lib/offline/detectNetwork';

export default function trackStatus() {
  let offlineTimestamp: number | null = null;

  function getTimestamp() {
    return new Date().getTime();
  }

  detectNetwork(isOnline => {
    if (isOnline) {
      if (offlineTimestamp !== null) {
        const currentTimestamp = getTimestamp();
        const timeOffline = currentTimestamp - offlineTimestamp;
        offlineTimestamp = null;

        if (timeOffline > 0) {
          analytics.track(Categories.SYSTEM, 'online', timeOffline);
        }
      }
    } else {
      offlineTimestamp = getTimestamp();
    }
  });
}
