const applicationId = '408a3306-4dec-4c5f-bf68-ba2d191f1a83';
const rumClientToken = 'pubedba743ad71d9d15f73456806997cbbb';

const datadogLookup = {
  rumClientId: {
    local: rumClientToken,
    dev: rumClientToken,
    qa: rumClientToken,
    stage: rumClientToken,
    test: rumClientToken,
    prod: rumClientToken,
  },
  rumAppId: {
    local: applicationId,
    dev: applicationId,
    qa: applicationId,
    stage: applicationId,
    test: applicationId,
    prod: applicationId,
  },
};

export default datadogLookup;
