export type WindowScrollAttributes = {
  scrollOffset: number;
  horizontalScrollOffset: number;
  bodyHeight: number;
  windowHeight: number;
  windowWidth: number;
  applicationHeight: number;
};
export function getWindowScrollAttributes(): WindowScrollAttributes {
  const {
    scrollY,
    scrollX,
    pageYOffset,
    pageXOffset,
    innerHeight: windowHeight,
    innerWidth: windowWidth,
  } = window;
  const bodyHeight = document.body ? document.body.offsetHeight : 0;
  const applicationHeight = Math.max(bodyHeight, windowHeight);

  // In IE 11, scrollY will always be undefined, so we need to fallback
  // to pageYOffset
  const scrollOffset = scrollY !== undefined ? scrollY : pageYOffset;
  const horizontalScrollOffset = scrollX !== undefined ? scrollX : pageXOffset;

  return {
    scrollOffset,
    horizontalScrollOffset,
    bodyHeight,
    windowHeight,
    windowWidth,
    applicationHeight,
  };
}

let scrollbarWidth = 0;

/**
 * Get the width of the scrollbar
 * Taken from: https://davidwalsh.name/detect-scrollbar-width
 */
export function getScrollbarWidth(): number {
  if (!!document.body && scrollbarWidth === undefined) {
    const body = document.body;

    // Create the measurement node
    const scrollDiv = document.createElement('div');

    scrollDiv.setAttribute(
      'style',
      'width: 100px;height:100px;overflow:scroll;position:absolute;top:-9999px'
    );
    body.appendChild(scrollDiv);

    // Get the scrollbar width
    scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

    // Delete the DIV
    body.removeChild(scrollDiv);
  }

  return scrollbarWidth;
}

export const checkHorizontalScroll = (
  elementRef: React.MutableRefObject<HTMLElement | null>,
  customScrollPixels?: { left?: number; right?: number }
) => {
  const { current } = elementRef;
  if (!current) {
    return { hasLeftScroll: false, hasRightScroll: false };
  }

  const { scrollLeft, scrollWidth, clientWidth } = current;
  const hasLeftScroll = scrollLeft > 0 + (customScrollPixels?.left ?? 0);
  // when the scroll is at the end of the container, it's a float number that we
  // need to round up to the next integer to check if there isn't a right scroll
  const hasRightScroll =
    Math.ceil(scrollLeft + (customScrollPixels?.right ?? 0)) <
    scrollWidth - clientWidth;
  return { hasLeftScroll, hasRightScroll };
};
