import { Link } from 'react-router-dom';
import styled from 'styled-components';

import config from '~/config';
import DotloopLogo from '~/components/icons/DotloopLogo';
import { useAppSelector } from '~/store/hooks';
import { createSelector } from 'reselect';
import { AppState } from '~/store';

const StyledCustomImage = styled.img({
  margin: 0,
  maxHeight: '100%',
});

const StyledLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
});

const {
  ui: { phoenixUrl },
} = config;

const currentProfileLogoUrlSelector = createSelector(
  (state: AppState) => state.account.currentProfile,
  (state: AppState) => state.api.resources.profiles.data,
  (currentProfile, profileResources) => {
    if (currentProfile) {
      const currentProfileResource = profileResources[currentProfile.id];

      return currentProfileResource
        ? currentProfileResource.attributes.logoUrl
        : currentProfile.logoUrl;
    }
  }
);

export const DotloopMainLogo = () => {
  const logoUrl =
    'https://assets.dev-dotloop.com/branding-images/9ee7c17f-9ecb-4846-b33a-de636eff0bd5.svg?width=400&height=55&keepAspectRatio=true`';

  return <StyledCustomImage src={logoUrl} alt="Dotloop Logo" />;
};

export const MainLogo = () => {
  const linkUrl = '/loops';
  const logoUrl = useAppSelector(currentProfileLogoUrlSelector);

  if (logoUrl) {
    let imageSrc: string | null = logoUrl;

    if (logoUrl.startsWith('/my/static')) {
      if (~logoUrl.indexOf('dotloop.png')) {
        imageSrc = null;
      } else {
        imageSrc = `${phoenixUrl}${logoUrl}`;
      }
    } else if (!logoUrl.startsWith('/') && !logoUrl.startsWith('http')) {
      imageSrc = `/${imageSrc}`;
    }

    if (imageSrc) {
      return (
        <StyledLink to={linkUrl}>
          <StyledCustomImage
            loading="lazy"
            src={`${imageSrc}?width=400&height=55&keepAspectRatio=true`}
            alt="Company logo"
          />
        </StyledLink>
      );
    }
  }

  return (
    <StyledLink to={linkUrl}>
      <DotloopLogo height="100%" />
    </StyledLink>
  );
};

export default MainLogo;
