import { configureStore } from '@reduxjs/toolkit';
import apiReducer from './features/api/apiSlice';
import accountReducer from './features/account/accountSlice';
import datadogMiddleware from './middlewares/datadog';

const store = configureStore({
  reducer: {
    api: apiReducer,
    account: accountReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(datadogMiddleware()),
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
