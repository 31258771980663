import { WheelEvent } from 'react';
import { getScrollbarWidth } from '~/lib/utils/scroll';

export function hasCssProperty(property: string): boolean {
  if (document && document.body) {
    return property in document.body.style;
  }

  return false;
}

let isMomentumScrolling: boolean;
export function hasMomentumScrolling(): boolean {
  if (isMomentumScrolling === undefined) {
    const overflowScrollingProp = '-webkit-overflow-scrolling';

    isMomentumScrolling = hasCssProperty(overflowScrollingProp);
  }

  return isMomentumScrolling;
}

type MomentumScrollingStylesType = {
  overflowY: 'scroll' | 'auto';
  WebkitOverflowScrolling?: 'touch';
};
export const momentumScrollingStyles: MomentumScrollingStylesType =
  hasMomentumScrolling()
    ? {
        overflowY: 'scroll',
        WebkitOverflowScrolling: 'touch',
      }
    : {
        overflowY: 'auto',
      };

type HorizontalMomentumScrollingStylesType = {
  overflowX: 'scroll' | 'auto';
  WebkitOverflowScrolling?: 'touch';
};
export const horizontalMomentumScrollingStyles: HorizontalMomentumScrollingStylesType =
  hasMomentumScrolling()
    ? {
        overflowX: 'scroll',
        WebkitOverflowScrolling: 'touch',
      }
    : {
        overflowX: 'auto',
      };

type TransformStyleType = {
  WebkitTransformStyle: 'preserve-3d';
  WebkitBackfaceVisibility: 'hidden';
  transform: string;
};

const scrollbarWidth = getScrollbarWidth();
export const forceFullWidthStyles = {
  position: 'relative',
  width: `calc(100vw - ${scrollbarWidth}px)`,
  left: '50%',
  right: '50%',
  marginLeft: `calc(-50vw + ${scrollbarWidth}px / 2)`,
  marginRight: `calc(-50vw + ${scrollbarWidth}px / 2)`,
};

export function createTransform(
  transform: string,
  isImportant?: boolean
): TransformStyleType {
  return {
    WebkitTransformStyle: 'preserve-3d',
    WebkitBackfaceVisibility: 'hidden',
    transform: `${transform}${isImportant ? ' !important' : ''}`,
  };
}

export function resetScrollPosition() {
  window.scrollTo(0, 0);
}

export const preventOutsideScrollingProps = {
  onWheel: function (e: WheelEvent<HTMLElement>) {
    const { isScrollingPastTop, isScrollingPastBottom } = getScrollDetails(e);

    const preventScroll = isScrollingPastTop || isScrollingPastBottom;

    if (preventScroll) {
      e.preventDefault();
    }

    return !preventScroll;
  },
};

type ScrollDetailsType = {
  isTop: boolean;
  isScrollingPastTop: boolean;
  isBottom: boolean;
  isScrollingPastBottom: boolean;
};

export function getScrollDetails(
  e: WheelEvent<HTMLElement>,
  threshold = 0
): ScrollDetailsType {
  const t = e.currentTarget;
  const isTop = t.scrollTop <= threshold;
  const isBottom = t.scrollHeight - t.clientHeight <= t.scrollTop + threshold;
  const isScrollingUp = e.deltaY > 0;
  const isScrollingDown = e.deltaY < 0;
  const isScrollingPastTop = isTop && isScrollingDown;
  const isScrollingPastBottom = isBottom && isScrollingUp;

  return { isTop, isScrollingPastTop, isBottom, isScrollingPastBottom };
}

const supportsSafeAreaInset = !!(window as any).CSS
  ? (window as any).CSS.supports(
      'padding-left: max(0, env(safe-area-inset-left))'
    )
  : false;

type PaddingType = string | number;
type DefaultPaddingType = {
  top?: PaddingType;
  right?: PaddingType;
  bottom?: PaddingType;
  left?: PaddingType;
};

type SafeInsetPaddingType = {
  paddingTop?: PaddingType;
  paddingRight?: PaddingType;
  paddingBottom?: PaddingType;
  paddingLeft?: PaddingType;
};

export function createSafeInsetPadding(
  defaultPadding: PaddingType | DefaultPaddingType,
  safePadding: {
    top?: boolean;
    right?: boolean;
    bottom?: boolean;
    left?: boolean;
  }
): SafeInsetPaddingType {
  if (
    typeof defaultPadding === 'string' ||
    typeof defaultPadding === 'number'
  ) {
    defaultPadding = {
      top: defaultPadding,
      right: defaultPadding,
      bottom: defaultPadding,
      left: defaultPadding,
    };
  }

  const cssPadding: SafeInsetPaddingType = {};

  if (defaultPadding.top) {
    cssPadding.paddingTop = defaultPadding.top;
  }

  if (defaultPadding.right) {
    cssPadding.paddingRight = defaultPadding.right;
  }

  if (defaultPadding.bottom) {
    cssPadding.paddingBottom = defaultPadding.bottom;
  }

  if (defaultPadding.left) {
    cssPadding.paddingLeft = defaultPadding.left;
  }

  if (supportsSafeAreaInset) {
    if (safePadding.top) {
      cssPadding.paddingTop = createSafePaddingRule(defaultPadding, 'top');
    }

    if (safePadding.right) {
      cssPadding.paddingRight = createSafePaddingRule(defaultPadding, 'right');
    }

    if (safePadding.bottom) {
      cssPadding.paddingBottom = createSafePaddingRule(
        defaultPadding,
        'bottom'
      );
    }

    if (safePadding.left) {
      cssPadding.paddingLeft = createSafePaddingRule(defaultPadding, 'left');
    }
  }

  return cssPadding;
}

type LocationType = 'top' | 'right' | 'bottom' | 'left';
function createSafePaddingRule(
  defaultPadding: DefaultPaddingType,
  location: LocationType
) {
  const padding = defaultPadding[location];

  return padding === 0 || padding === '0'
    ? `env(safe-area-inset-${location})`
    : `max(${padding}, env(safe-area-inset-${location}))`;
}

/**
 * Returns the rem equivalent of a pixel value
 */
export const toRem = (pxValue: number): string => {
  return pxValue / 16 + 'rem';
};
