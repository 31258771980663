import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';

import { ResourceDefinition, ResourceTypes } from '../types';

const Message: ResourceDefinition = {
  attributes: {
    text: ATTRIBUTE_TYPE.STRING,
    messageType: ATTRIBUTE_TYPE.STRING,
    sentDate: ATTRIBUTE_TYPE.DATE,
  },

  relationships: {
    sender: {
      resourceType: ResourceTypes.Participants,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
    conversation: {
      resourceType: ResourceTypes.Conversations,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
    document: {
      resourceType: ResourceTypes.Documents,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
    documentRevision: {
      resourceType: ResourceTypes.DocumentRevisions,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
    statuses: {
      resourceType: ResourceTypes.ParticipantStatuses,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
  },
};

export default Message;
