import { ReactNode, cloneElement } from 'react';

type ReactElements = {
  [key: string]: any;
};

/**
 * Given a key, encode it so that `injectReactElements` knows how to match
 * a key to a portion of the text it needs to inject into.
 **/
export function encodeReactKey(key: string) {
  return `<<${key}>>`;
}

/**
 * Given some text, try to inject react elements into the text at positions
 * indicated by the key of `reactElements`.
 **/
export function injectReactElements(
  text: string,
  reactElements?: ReactElements
) {
  if (!!reactElements && Object.keys(reactElements).length > 0) {
    const elements = reactElements;
    const keysToInject = Object.keys(elements);
    const textLength = text.length;
    const result: Array<ReactNode> = [];
    let shortestKeyLength: number = keysToInject.length;
    const encodedKeys = keysToInject.map(key => {
      shortestKeyLength = !!shortestKeyLength
        ? key.length
        : Math.min(shortestKeyLength, key.length);

      return encodeReactKey(key);
    });
    let index = 0;

    const checkPossibleMatch = (encodedKey: string) =>
      text.substring(index, index + encodedKey.length);

    while (index < textLength) {
      if (index + shortestKeyLength >= textLength) {
        // We have come to a point where there are no more possible keys
        // that fit in the remaining text, so add the rest of the text
        // to the result and exit the while loop
        const remainingText = text.substr(index);
        if (typeof result[result.length - 1] === 'string') {
          // The last result is not a react element, so just append the
          // remaining text to the last result
          result[result.length - 1] += remainingText;
        } else {
          result.push(remainingText);
        }

        break;
      }

      const possibleMatches = encodedKeys.map(checkPossibleMatch);

      for (let i = 0; i < possibleMatches.length; i++) {
        const key = keysToInject[i];
        const element = elements[key];
        const encodedKey = encodedKeys[i];
        const possibleMatch = possibleMatches[i];

        if (possibleMatch.toLowerCase() === encodedKey.toLowerCase()) {
          // We have a match, so inject the react element
          const el = cloneElement(element, { key: index });

          result.push(el);
          index += encodedKey.length;

          break;
        } else if (i === possibleMatches.length - 1) {
          // We're on the last possible match, but never found a match,
          // so advance to the next character in the text
          if (typeof result[result.length - 1] === 'string') {
            result[result.length - 1] += text[index];
          } else {
            result.push(text[index]);
          }

          index++;
        }
      }
    }

    return result;
  } else {
    return text;
  }
}
