import { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { datadogRum } from '@datadog/browser-rum';

import { FormattedMessage } from '~/components/i18n';

import PageContentError from './PageContentError';

interface Props {
  children?: ReactNode;
}

export function PageContentErrorBoundary(props: Props) {
  return (
    <ErrorBoundary
      onError={(error, { componentStack }) => {
        datadogRum.addError(error, {
          boundary: 'page-content',
          componentStack,
        });
      }}
      fallbackRender={({ resetErrorBoundary }) => (
        <PageContentError
          action={resetErrorBoundary}
          actionText={<FormattedMessage id="errors.serverError.actionText" />}
          message={<FormattedMessage id="errors.serverError.message" />}
          subMessage={<FormattedMessage id="errors.serverError.subMessage" />}
        />
      )}
    >
      {props.children}
    </ErrorBoundary>
  );
}

export default PageContentErrorBoundary;
