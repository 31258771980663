import { useState } from 'react';

import { createLoadablePage } from '~/pages/utils/createLoadablePage';
import CreateLoopLoading from './CreateLoopLoading';

const CreateLoopContainer = createLoadablePage({
  loader: () =>
    import(
      /* webpackChunkName: "create-loop" */ './containers/CreateLoopContainer'
    ),
  i18nNamespace: 'create-loop',
  loading: CreateLoopLoading,
});

function CreateLoopPage() {
  const [newLoopId, setNewLoopId] = useState<number | null>(null);

  return (
    <CreateLoopContainer newLoopId={newLoopId} setNewLoopId={setNewLoopId} />
  );
}

export default CreateLoopPage;
