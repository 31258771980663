import { ATTRIBUTE_TYPE } from '../constants';
import { ResourceDefinition } from '../types';

const ShareWorkflowTemplate: ResourceDefinition = {
  attributes: {
    templateName: ATTRIBUTE_TYPE.STRING,
    templateDescription: ATTRIBUTE_TYPE.STRING,
    templateDefaultMessage: ATTRIBUTE_TYPE.STRING,
    profileId: ATTRIBUTE_TYPE.NUMBER,
    parentShareWorkflowTemplateId: ATTRIBUTE_TYPE.NUMBER,
    visibilityStatus: ATTRIBUTE_TYPE.BOOLEAN,
    enteredByUsername: ATTRIBUTE_TYPE.STRING,
    changedByUsername: ATTRIBUTE_TYPE.STRING,
    shareWorkflowStepTemplateList: ATTRIBUTE_TYPE.AS_IS,
  },
};

export default ShareWorkflowTemplate;
