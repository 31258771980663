import styled from 'styled-components';
import { colors, fontWeights } from '~/styles';

type HighlightVariants = 'primary' | 'bold';

const StyledHighlight = styled.mark<{ $variant: HighlightVariants }>(
  ({ $variant }) => {
    const variants = {
      primary: {
        backgroundColor: colors.highlight,
        padding: '0 2px',
      },
      bold: {
        fontWeight: fontWeights.bold,
        backgroundColor: 'transparent',
      },
    };

    return variants[$variant];
  }
);

type Props = {
  /** This is the text that you want to make highlighted */
  sourceText: string | null;
  /** This is the text that you want to highlight in the sourceText */
  findText?: string;
  /** style of the highlighted text */
  variant?: HighlightVariants;
};

function getHighlightedText(
  source: string | null,
  variant: HighlightVariants,
  find?: string
) {
  const result = [];
  let index = 0;

  if (!find || !source) {
    return source;
  }

  while (index < source.length) {
    const possibleMatch = source.substring(index, index + find.length);
    if (possibleMatch.toLowerCase() === find.toLowerCase()) {
      result.push(
        <StyledHighlight key={index} $variant={variant}>
          {possibleMatch}
        </StyledHighlight>
      );

      index += find.length;
    } else {
      const previousResultIndex = result.length - 1;
      const nextCharacter = source[index];

      if (typeof result[previousResultIndex] === 'string') {
        result[previousResultIndex] += nextCharacter;
      } else {
        result.push(nextCharacter);
      }

      index++;
    }
  }

  return result;
}

export function Highlight({
  sourceText,
  findText,
  variant = 'primary',
}: Props) {
  const highlightedText = getHighlightedText(sourceText, variant, findText);

  return <span>{highlightedText}</span>;
}

export default Highlight;
