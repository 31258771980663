import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FormattedMessage } from '~/components/i18n';

import { LinkButton } from '~/components/buttons';
import LoadingMoreIndicator from '~/components/LoadingMoreIndicator';
import { colors, fontSizes, fontWeights, spacing } from '~/styles';

import { usePrevious, useRefData } from '~/hooks';
import { TransactionType } from '~/lib/utils/refData';

import NoResultsIllustration from '~/components/NoResultsIllustration';
import LoopSearchResultItem from './LoopSearchResultItem';

import { LoopResource } from '~/store/features/api/resources/loop/types';
import { AppliedSortType } from '~/components/loops/utils/sorting';
import { SearchScope } from '~/components/LoopSearchContainer/LoopSearchContainer';
import { SelectedFiltersType } from '~/store/features/api/resources/preference/types';
import { getFilterScope, FilterScope } from '../LoopFilters/utils';
import { GetItemPropsOptions } from 'downshift';

const DEFAULT_HEIGHT = '14rem';
const StyledSearchResultList = styled.ul({
  margin: 0,
  padding: 0,
});

const StyledSearchScopeContainer = styled.div({
  ...fontSizes.callout,
  padding: `${spacing.normal} ${spacing.medium}`,
  borderBottom: `1px solid ${colors.divider}`,
  color: colors.secondaryText,
  fontWeight: fontWeights.semiBold,
});

const StyledSearchLoading = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: DEFAULT_HEIGHT,
  overflow: 'hidden',
});

const StyledNoResultsAction = styled.div({
  marginTop: spacing.smaller,
  textAlign: 'center',
});

const StyledNoResultsDetails = styled.div({
  ...fontSizes.callout,
  marginTop: spacing.smaller,
  color: colors.secondaryText,
  lineHeight: spacing.medium,
  fontWeight: fontWeights.semiBold,
  textAlign: 'center',
});

const StyledNoResultsHeader = styled.div({
  ...fontSizes.secondaryTitle,
  color: colors.secondaryText,
  fontWeight: fontWeights.medium,
  textAlign: 'center',
});

const StyledNoResultsIllustration = styled.div({
  marginBottom: spacing.small,
});

const StyledNoResultsContent = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '80%',
  transform: 'translate(-50%, -50%)',
});

const StyledNoResultsContainer = styled.div({
  position: 'relative',
  padding: `${spacing.normal} ${spacing.medium}`,
  height: DEFAULT_HEIGHT,
  overflow: 'hidden',
});

const StyledFadeContainer = styled(motion.div)({
  overflow: 'hidden',
});

const StyledResultsContainer = styled(motion.div)({
  overflow: 'hidden',
});

type Props = {
  getMenuProps: (
    props: Record<string, unknown>,
    options: Record<string, unknown>
  ) => Record<string, unknown>;
  getItemProps: (
    props: GetItemPropsOptions<LoopResource>
  ) => Record<string, unknown>;
  isAdmin: boolean;
  isLoading: boolean;
  showSearchResults: boolean;
  searchValue: string;
  searchScope: SearchScope;
  onScopeToggle: () => void;
  hasComplianceGroups: boolean;
  appliedSort: AppliedSortType;
  highlightedIndex: number | null;
  matchingLoops: Array<LoopResource>;
  updateSearchQueryParameter: (searchValue: string) => void;
  hasActiveFilters: boolean;
  onValue?: () => void;
  appliedFilters?: SelectedFiltersType;
  hasDefaultFilters?: boolean;
};

export default function LoopSearchResults(props: Props) {
  const {
    getMenuProps,
    getItemProps,
    showSearchResults,
    searchValue,
    searchScope,
    onScopeToggle,
    hasComplianceGroups,
    appliedSort,
    isAdmin,
    highlightedIndex,
    matchingLoops,
    updateSearchQueryParameter,
    hasActiveFilters,
    appliedFilters,
    hasDefaultFilters,
    isLoading,
  } = props;

  const [showNoResults, setShowNoResults] = useState<boolean>(false);
  const previousIsLoading = usePrevious<boolean>(isLoading);
  const transactionTypes = useRefData<TransactionType>('transactionTypes');
  const hasMatchingLoops = !!matchingLoops && matchingLoops.length > 0;
  const isFilteredSearchScope = searchScope === SearchScope.Filtered;
  const filterScope = getFilterScope(appliedFilters);

  useEffect(() => {
    const hasMatchingLoops = !!matchingLoops && matchingLoops.length > 0;
    const didFinishLoading = previousIsLoading && !isLoading;
    const didStartLoading = !previousIsLoading && isLoading;

    if (didFinishLoading && !hasMatchingLoops && !showNoResults) {
      setShowNoResults(true);
    } else if (
      (!searchValue || didStartLoading || hasMatchingLoops) &&
      showNoResults
    ) {
      setShowNoResults(false);
    }
  }, [matchingLoops, previousIsLoading, isLoading, showNoResults, searchValue]);

  const toggleScopeLabelId = isFilteredSearchScope
    ? 'loops:search.searchAll'
    : 'loops:search.searchFiltered';

  let currentScopeLabelId = 'loops:search.showingAll';
  if (filterScope === FilterScope.Active && searchScope !== SearchScope.All) {
    currentScopeLabelId = 'loops:search.showingActive';
  } else if (
    filterScope === FilterScope.Filtered &&
    searchScope !== SearchScope.All
  ) {
    currentScopeLabelId = 'loops:search.showingFiltered';
  }

  const showMatchingLoops = showSearchResults && hasMatchingLoops;
  let containerVariant =
    showSearchResults && !!searchValue ? 'default' : 'empty';
  let contentVariant;

  if (showMatchingLoops) {
    containerVariant = 'results';
  } else if (!hasMatchingLoops) {
    if (!searchValue) {
      contentVariant = 'empty';
    } else if (showNoResults && !hasMatchingLoops && isFilteredSearchScope) {
      contentVariant = 'no-results-filtered';
    } else if (showNoResults && !hasMatchingLoops) {
      contentVariant = 'no-results';
    } else {
      contentVariant = 'loading';
    }
  }

  return (
    <StyledResultsContainer
      initial="empty"
      animate={containerVariant}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      variants={{
        empty: {
          maxHeight: '0px',
          overflow: 'hidden',
        },
        default: () => {
          const baseFontSize = getComputedStyle(window.document.body).fontSize;
          const remPixels = baseFontSize ? parseFloat(baseFontSize) : 0;
          const numRems = parseFloat(DEFAULT_HEIGHT);

          return {
            overflow: 'hidden',
            maxHeight: remPixels * numRems,
          };
        },
        results: () => ({
          maxHeight: `${window.innerHeight}px`,
          transitionEnd: { overflow: 'visible' },
        }),
      }}
    >
      {!hasMatchingLoops ? (
        <AnimatePresence>
          <StyledFadeContainer
            key={contentVariant}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {!searchValue ? null : showNoResults && !hasMatchingLoops ? (
              <StyledNoResultsContainer>
                <StyledNoResultsContent>
                  <StyledNoResultsIllustration>
                    <NoResultsIllustration height="5rem" />
                  </StyledNoResultsIllustration>
                  <StyledNoResultsHeader>
                    <FormattedMessage id="search.noResultsHeader" />
                  </StyledNoResultsHeader>
                  <StyledNoResultsDetails>
                    <FormattedMessage
                      id={
                        isFilteredSearchScope
                          ? 'loops:search.noFilteredResultsMessage'
                          : 'loops:search.noResultsMessage'
                      }
                    />
                  </StyledNoResultsDetails>
                  {isFilteredSearchScope && (
                    <StyledNoResultsAction>
                      <LinkButton
                        id="no-results-search-all"
                        small
                        removeSidePadding
                        removeTopBottomPadding
                        onClick={onScopeToggle}
                      >
                        <FormattedMessage id="loops:search.noFilteredResultsLink" />
                      </LinkButton>
                    </StyledNoResultsAction>
                  )}
                </StyledNoResultsContent>
              </StyledNoResultsContainer>
            ) : (
              <StyledSearchLoading>
                <LoadingMoreIndicator />
              </StyledSearchLoading>
            )}
          </StyledFadeContainer>
        </AnimatePresence>
      ) : (
        <Fragment>
          {(hasActiveFilters || hasDefaultFilters) &&
            filterScope !== FilterScope.All && (
              <StyledSearchScopeContainer>
                <FormattedMessage id={currentScopeLabelId} />
                &nbsp;
                <LinkButton
                  id="toggle-search-scope"
                  small
                  removeSidePadding
                  removeTopBottomPadding
                  onClick={onScopeToggle}
                >
                  <FormattedMessage id={toggleScopeLabelId} />
                </LinkButton>
              </StyledSearchScopeContainer>
            )}
          <StyledSearchResultList
            {...getMenuProps({}, { suppressRefError: true })}
          >
            {matchingLoops.map((loop, index) => (
              <LoopSearchResultItem
                key={loop.id}
                loop={loop}
                loopIndex={index}
                getItemProps={getItemProps}
                isAdmin={isAdmin}
                searchValue={searchValue}
                hasComplianceGroups={hasComplianceGroups}
                appliedSort={appliedSort}
                transactionTypes={transactionTypes}
                highlightedIndex={highlightedIndex}
                updateSearchQueryParameter={updateSearchQueryParameter}
              />
            ))}
          </StyledSearchResultList>
        </Fragment>
      )}
    </StyledResultsContainer>
  );
}
