import { SortDirectionType } from '~/lib/api/types';

export enum SortKey {
  Default = 'createdDate',
  LastUpdated = 'lastUpdatedDate',
  TransactionPrice = 'transactionPrice',
  ListedDate = 'listedDate',
  ExpirationDate = 'expirationDate',
  ClosingDate = 'closingDate',
  ReviewSubmitDate = 'reviewSubmissionDate',
  ContractAgreementDate = 'contractAgreementDate',
  AgentName = 'agentName',
  Relevancy = 'relevancy',
}

export type SortType = {
  labelId: string;
  sortId: SortKey;
  defaultSortDirection: SortDirectionType;
  cardIndicatorId?: string;
};
export type AppliedSortType =
  | SortType
  | { sortId: SortKey; sortDirection: SortDirectionType };

export const SORT_ASCENDING = 'asc';
export const SORT_DESCENDING = 'desc';

export const RELEVANCY_SORT: SortType = {
  labelId: 'my-loops:sort.relevancy',
  sortId: SortKey.Relevancy,
  defaultSortDirection: SORT_DESCENDING,
};

export const DEFAULT_SORT: SortType = {
  labelId: 'my-loops:sort.default',
  cardIndicatorId: 'loops:card.sortIndicator.default',
  sortId: SortKey.Default,
  defaultSortDirection: SORT_DESCENDING,
};

export const LAST_UPDATED_SORT: SortType = {
  labelId: 'my-loops:sort.lastUpdated',
  cardIndicatorId: 'loops:card.sortIndicator.lastUpdated',
  sortId: SortKey.LastUpdated,
  defaultSortDirection: SORT_DESCENDING,
};

export const TRANSACTION_PRICE_SORT: SortType = {
  labelId: 'my-loops:sort.transactionPrice',
  sortId: SortKey.TransactionPrice,
  defaultSortDirection: SORT_DESCENDING,
};

export const LISTED_DATE_SORT: SortType = {
  labelId: 'my-loops:sort.listedDate',
  cardIndicatorId: 'loops:card.sortIndicator.listedDate',
  sortId: SortKey.ListedDate,
  defaultSortDirection: SORT_DESCENDING,
};

export const EXPIRATION_DATE_SORT: SortType = {
  labelId: 'my-loops:sort.expDate',
  cardIndicatorId: 'loops:card.sortIndicator.expDate',
  sortId: SortKey.ExpirationDate,
  defaultSortDirection: SORT_ASCENDING,
};

export const CLOSING_DATE_SORT: SortType = {
  labelId: 'my-loops:sort.closingDate',
  sortId: SortKey.ClosingDate,
  defaultSortDirection: SORT_ASCENDING,
};

export const REVIEW_SUBMIT_DATE_SORT: SortType = {
  labelId: 'my-loops:sort.reviewSubmitDate',
  cardIndicatorId: 'loops:card.sortIndicator.reviewSubmitDate',
  sortId: SortKey.ReviewSubmitDate,
  defaultSortDirection: SORT_ASCENDING,
};

export const CONTRACT_AGREEMENT_DATE_SORT: SortType = {
  labelId: 'my-loops:sort.contractAgreementDate',
  cardIndicatorId: 'loops:card.sortIndicator.contractAgreementDate',
  sortId: SortKey.ContractAgreementDate,
  defaultSortDirection: SORT_ASCENDING,
};

export const AGENT_NAME_SORT: SortType = {
  labelId: 'my-loops:sort.agentName',
  sortId: SortKey.AgentName,
  defaultSortDirection: SORT_ASCENDING,
};

export function getAvailableSorts(
  isAdmin = true,
  showRelevancy = false
): Array<SortType> {
  let sorts = [
    DEFAULT_SORT,
    LAST_UPDATED_SORT,
    TRANSACTION_PRICE_SORT,
    LISTED_DATE_SORT,
    EXPIRATION_DATE_SORT,
    CLOSING_DATE_SORT,
    REVIEW_SUBMIT_DATE_SORT,
    CONTRACT_AGREEMENT_DATE_SORT,
  ];

  if (isAdmin) {
    sorts = [...sorts, AGENT_NAME_SORT];
  }

  if (showRelevancy) {
    sorts = [RELEVANCY_SORT, ...sorts];
  }

  return sorts;
}

type SortPreferenceValueType = {
  sortId: SortKey;
  sortDirection: SortDirectionType;
};
export function getAppliedSort(
  preferenceValue: SortPreferenceValueType | null
): AppliedSortType {
  let appliedSort: AppliedSortType = DEFAULT_SORT;

  if (preferenceValue) {
    const { sortId, sortDirection } = preferenceValue;
    const availableSorts = getAvailableSorts();
    const foundSort = availableSorts.find(s => s.sortId === sortId);
    const defaultSortDirection = foundSort
      ? foundSort.defaultSortDirection
      : SORT_ASCENDING;

    appliedSort = foundSort
      ? {
          ...foundSort,
          sortDirection,
        }
      : {
          sortId: SortKey.Default,
          sortDirection: sortDirection || defaultSortDirection,
        };
  }

  return appliedSort;
}
