import { MessageType } from '~/store/features/api/resources/message/constants';

import DocumentMessage from './DocumentMessage';
import TextMessage from './TextMessage';

import { MessageResource } from '~/store/features/api/resources/message/types';

type Props = {
  message: MessageResource;
  sentByMe: boolean;
};

export function ConversationMessage({ message, sentByMe }: Props) {
  if (message.messageType === MessageType.Document) {
    return <DocumentMessage message={message} sentByMe={sentByMe} />;
  } else if (message.messageType === MessageType.Text) {
    return <TextMessage message={message} sentByMe={sentByMe} />;
  } else {
    return null;
  }
}

export default ConversationMessage;
