import { Component } from 'react';
import { debounce } from 'lodash-es';

import {
  getWindowScrollAttributes,
  WindowScrollAttributes,
} from '~/lib/utils/scroll';

interface ResizeListener {
  (attributes: WindowScrollAttributes): void;
}

type Props = {
  onResize: ResizeListener;
};

const DEBOUNCE_TIME = 100;

class WindowResizeListener extends Component<Props> {
  static _listeners: Array<ResizeListener> = [];
  static _onResize = debounce(() => {
    const attributes = getWindowScrollAttributes();

    WindowResizeListener._listeners.forEach(listener => {
      listener(attributes);
    });
  }, DEBOUNCE_TIME);

  shouldComponentUpdate(nextProps: Props) {
    return nextProps.onResize !== this.props.onResize;
  }

  componentDidMount() {
    if (!WindowResizeListener._listeners.length) {
      window.addEventListener('resize', WindowResizeListener._onResize, false);
    }

    WindowResizeListener._listeners.push(this.props.onResize);
  }

  componentDidUpdate(prevProps: Props) {
    const { onResize: prevOnResize } = prevProps;
    const { onResize } = this.props;
    if (prevOnResize !== onResize) {
      const index = WindowResizeListener._listeners.indexOf(prevOnResize);

      WindowResizeListener._listeners.splice(index, 1, onResize);
    }
  }

  componentWillUnmount() {
    const index = WindowResizeListener._listeners.indexOf(this.props.onResize);

    WindowResizeListener._listeners.splice(index, 1);

    if (!WindowResizeListener._listeners.length) {
      window.removeEventListener(
        'resize',
        WindowResizeListener._onResize,
        false
      );
    }
  }

  render() {
    return null;
  }
}

export default WindowResizeListener;
