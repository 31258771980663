import { connect } from 'react-redux';
import styled from 'styled-components';
import { memo } from 'react';
import { breakpoints, colors, fontWeights, spacing } from '~/styles';

import { Container } from './layout';
import { FormattedMessage } from './i18n';
import { CloseIcon } from './icons';
import { AppState } from '~/store';
import AppLink from './AppLink';
import { LinkButton } from './buttons';
import { LinkButtonType } from './buttons/LinkButton';

import {
  areResourcesLoading,
  hasResourceBeenRequested,
} from '~/store/features/api/selectors';
import { makePreferenceSelector } from '~/store/features/api/selectors/preference';
import { PreferenceNames } from '~/store/features/api/resources/preference/constants';
import { PreferenceResource } from '~/store/features/api/resources/preference/types';
import { ResourceTypes } from '~/store/features/api/resources/types';

import { useI18n } from '~/hooks';
import { updateResource } from '~/store/features/api/apiSlice';

const StyledDismissContainer = styled.div({
  position: 'absolute',
  top: '50%',
  right: spacing.normal,
  transform: 'translateY(-50%)',

  [breakpoints.MEDIUM]: {
    position: 'static',
    top: 'unset',
    right: 'unset',
    transform: 'none',
    marginLeft: spacing.smaller,
  },
});

const StyledAppLink = styled(AppLink)({
  flex: '0 0 100%',
  color: colors.primaryBackgroundText,
  fontWeight: fontWeights.bold,

  '&:hover,&:focus': {
    color: colors.primaryBackgroundText,
    textDecoration: 'underline',
  },

  [breakpoints.MEDIUM]: {
    flex: '0 0 auto',
    marginLeft: 'auto',
  },
});

const StyledSupportBannerActionText = styled.span({
  display: 'none',

  [breakpoints.XLARGE]: {
    display: 'block',
    flex: '0 0 auto',
    marginLeft: spacing.smallest,
  },
});

const StyledSupportBannerIntroText = styled.span({
  flex: '0 0 100%',
  fontWeight: fontWeights.bold,

  [breakpoints.MEDIUM]: {
    flex: '0 0 auto',
  },
});

const StyledContainer = styled(Container)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  paddingTop: spacing.small,
  paddingBottom: spacing.small,
});

const StyledSupportBannerWrapper = styled.div({
  backgroundColor: colors.primaryButtonFocus,
  color: colors.primaryBackgroundText,
});

const DOTLOOP_SUPPORT_URL = `https://support.dotloop.com/`;

type HideSupportBannerPreference = {
  value: boolean;
};

type Props = {
  isActiveProfileChanging: boolean;
  havePreferencesBeenRequested: boolean;
  arePreferencesLoading: boolean;
  hideSupportBannerPreference: HideSupportBannerPreference | null | undefined;
  updatePreference: (
    id: string,
    preference: Partial<PreferenceResource>
  ) => Promise<any>;
};

function SupportBanner(props: Props) {
  const {
    hideSupportBannerPreference,
    isActiveProfileChanging,
    havePreferencesBeenRequested,
    arePreferencesLoading,
    updatePreference,
  } = props;
  const preferencesLoaded =
    havePreferencesBeenRequested && !arePreferencesLoading;
  const userDismissedBanner = !!hideSupportBannerPreference
    ? !!hideSupportBannerPreference.value
    : false;
  const i18n = useI18n();

  if (!preferencesLoaded || userDismissedBanner || isActiveProfileChanging) {
    return null;
  }

  const dismissSupportBanner = () => {
    updatePreference(PreferenceNames.HideSupportBanner, { value: true });
  };

  return (
    <StyledSupportBannerWrapper id="support-intro-banner">
      <StyledContainer>
        <StyledSupportBannerIntroText>
          <FormattedMessage id="supportBanner.introductionText" />
        </StyledSupportBannerIntroText>
        <StyledSupportBannerActionText>
          <FormattedMessage id="supportBanner.actionText" />
        </StyledSupportBannerActionText>
        <StyledAppLink openNewTab to={DOTLOOP_SUPPORT_URL}>
          <FormattedMessage id="supportBanner.goToHelpCenter" />
        </StyledAppLink>
        <StyledDismissContainer>
          <LinkButton
            id="dismiss-support-chat-announcement"
            name="dismiss-support-chat-announcement"
            aria-label={i18n.t('actions.dismissBanner')}
            icon={CloseIcon}
            buttonStyle={LinkButtonType.White}
            onClick={dismissSupportBanner}
          />
        </StyledDismissContainer>
      </StyledContainer>
    </StyledSupportBannerWrapper>
  );
}

function makeMapStateToProps() {
  const hideSupportBannerSelector = makePreferenceSelector(
    PreferenceNames.HideSupportBanner
  );

  return (state: AppState) => ({
    hideSupportBannerPreference: hideSupportBannerSelector(state),
    isActiveProfileChanging: state.account.activeProfileIsChanging,
    havePreferencesBeenRequested: hasResourceBeenRequested(
      state,
      ResourceTypes.Preferences
    ),
    arePreferencesLoading: areResourcesLoading(
      state,
      ResourceTypes.Preferences
    ),
  });
}

const mapDispatchToProps = {
  updatePreference: (id: string, preference: Partial<PreferenceResource>) =>
    updateResource({
      resourceName: ResourceTypes.Preferences,
      resourceId: id,
      attributesToUpdate: preference,
      resourceRelationships: null,
      updateOptions: {
        isOptimistic: true,
        allowRollback: false,
      },
    }),
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(memo(SupportBanner) as any);
