import { FormattedDate, FormattedMessage } from '~/components/i18n';

import endOfDay from 'date-fns/end_of_day';
import subWeeks from 'date-fns/sub_weeks';
import isAfter from 'date-fns/is_after';
import isToday from 'date-fns/is_today';
import isYesterday from 'date-fns/is_yesterday';

import { DATE_FORMATS } from '~/components/i18n/utils';

import { ContactResource } from '~/store/features/api/resources/contact/types';
import { ParticipantResource } from '~/store/features/api/resources/participant/types';
import { ExistingRecipient } from './RecipientMultiSelect';

const currentDate = new Date();

export function getMessageRelativeTimestamp(messageDate: Date) {
  let dayContents;

  if (messageDate) {
    if (isToday(messageDate)) {
      dayContents = <FormattedMessage id="dates.today" />;
    } else if (isYesterday(messageDate)) {
      dayContents = <FormattedMessage id="dates.yesterday" />;
    } else {
      const oneWeekAgo = subWeeks(currentDate, 1);
      const endOfOneWeekAgo = endOfDay(oneWeekAgo);
      const sentWithinLastWeek = isAfter(messageDate, endOfOneWeekAgo);

      if (sentWithinLastWeek) {
        dayContents = (
          <FormattedDate date={messageDate} dateFormat={DATE_FORMATS.DAY} />
        );
      } else {
        dayContents = (
          <FormattedDate
            date={messageDate}
            dateFormat={DATE_FORMATS.MONTH_DAY}
          />
        );
      }
    }
  }

  return dayContents;
}

export function getRecipientName(
  recipient: ContactResource | ExistingRecipient
) {
  if (recipient.displayName) {
    return recipient.displayName;
  } else {
    return `${recipient.firstName} ${recipient.lastName}`;
  }
}

export function getParticipantLabel(
  participant: ParticipantResource
): string | undefined {
  let label;

  if (participant) {
    label = participant.firstName;

    if (!label) {
      label =
        participant.routingType === 'EMAIL'
          ? participant.emailAddress
          : participant.phoneNumber;
    }
  }

  return label;
}
