import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';
import { ResourceDefinition, ResourceTypes } from '../types';

const LoopParticipantLink: ResourceDefinition = {
  attributes: {
    participantId: ATTRIBUTE_TYPE.NUMBER,
    loopId: ATTRIBUTE_TYPE.NUMBER,
    teamMember: ATTRIBUTE_TYPE.BOOLEAN,
    invitationStatus: ATTRIBUTE_TYPE.STRING,
    restricted: ATTRIBUTE_TYPE.BOOLEAN,
  },

  relationships: {
    role: {
      resourceType: ResourceTypes.LoopRoles,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },

    loopParticipant: {
      resourceType: ResourceTypes.LoopParticipants,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },

    roleFields: {
      resourceType: ResourceTypes.LoopData,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
  },
};

export default LoopParticipantLink;
