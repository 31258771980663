import { useEffect, useRef } from 'react';

export function useOnMount(onMount: () => void): void {
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      onMount && onMount();
    }
  });
}
