import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';

import { ResourceDefinition, ResourceTypes } from '../types';

const ComplianceGroup: ResourceDefinition = {
  attributes: {
    name: ATTRIBUTE_TYPE.STRING,
    active: ATTRIBUTE_TYPE.BOOLEAN,
    profileId: ATTRIBUTE_TYPE.NUMBER, // the parent profileId that owns the complianceGroup
  },

  relationships: {
    complianceStatuses: {
      resourceType: ResourceTypes.ComplianceStatuses,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
  },
};

export default ComplianceGroup;
