import { ComponentType } from 'react';
import config from '~/config';
import {
  NavReportingIcon,
  NavLoopsIcon,
  NavTasksIcon,
  NavPeopleIcon,
  NavTemplatesIcon,
  MessagingIcon,
} from '~/components/icons';

import { NavigationAccessType } from '~/store/features/account/types';

export type NavItem = {
  icon: ComponentType<any>;
  link: string;
  labelId: string;
};

const {
  ui: { phoenixUrl },
} = config;
export function getNavItems(
  navigationAccess: NavigationAccessType
): Array<NavItem> {
  const { hasReporting, hasLoops, hasPeople, hasTemplates, hasTasks } =
    navigationAccess;
  const navItems = [];

  if (hasReporting) {
    navItems.push({
      icon: NavReportingIcon,
      link: `${phoenixUrl}/my/dashboard`, // TODO: Change back to line below after new link shows up
      // link: `${phoenixUrl}/my/reporting`,
      labelId: 'navigation.reporting',
    });
  }

  if (hasLoops) {
    navItems.push({
      icon: NavLoopsIcon,
      link: '/loops',
      labelId: 'navigation.loops',
    });
  }

  if (hasTasks) {
    navItems.push({
      icon: NavTasksIcon,
      link: `${phoenixUrl}/my/tasks`,
      labelId: 'navigation.tasks',
    });
  }

  if (hasPeople) {
    navItems.push({
      icon: NavPeopleIcon,
      link: `${phoenixUrl}/my/people`,
      labelId: 'navigation.people',
    });
  }

  if (hasTemplates) {
    navItems.push({
      icon: NavTemplatesIcon,
      link: `${phoenixUrl}/my/templates`,
      labelId: 'navigation.templates',
    });
  }

  navItems.push({
    icon: MessagingIcon,
    link: `/messages`,
    labelId: 'navigation.messages',
  });

  return navItems;
}
