import { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { makeResourceSelector } from '~/store/features/api/selectors';
import { uploadBrandingLogo, CropData } from '~/services/upload-service';

import {
  SnackbarNotification,
  NotificationType,
} from '~/components/Notifications';
import { FormattedMessage, injectI18n } from '~/components/i18n';
import { AppModal } from '~/components/Modal';
import ImageUpload from '~/components/ImageUpload';
import { SettingsIcon } from '~/components/icons';
import { breakpoints, fontWeights, colors, spacing } from '~/styles';
import { ProfileResource } from '~/store/features/api/resources/profile/types';
import { I18nType } from '~/components/i18n/types';
import { AppState } from '~/store';
import { ResourceTypes } from '~/store/features/api/resources/types';
import { updateResource } from '~/store/features/api/apiSlice';

const mixins = {
  uploadContainerWithoutImage: {
    height: '9rem',
  },
};

const StyledUploadContainer = styled.div<{
  $uploadContainerWithoutImageEnabled: boolean;
}>(props => ({
  minHeight: '9rem',
  width: '100%',
  textAlign: 'center',

  [breakpoints.MEDIUM]: {
    width: '31rem',
  },

  ...(props.$uploadContainerWithoutImageEnabled
    ? mixins.uploadContainerWithoutImage
    : {}),
}));

const StyledSubTitle = styled.p({
  fontWeight: fontWeights.medium,
  color: colors.secondaryText,
  marginBottom: spacing.normal,
});

type ReduxProps = {
  profile: ProfileResource;
  updateLogoKey: (profileId: string, logoKey: string) => Promise<void>;
};
type OwnProps = {
  profileId: string | null;
  onClose: () => void;
  i18n: I18nType;
};
type Props = OwnProps & ReduxProps;
type State = {
  imageFile: File | null;
  cropData: CropData | null;
  errorId: string | null;
};

class ProfileSettingsModal extends Component<Props, State> {
  state: State = {
    imageFile: null,
    cropData: null,
    errorId: null,
  };

  componentDidUpdate(prevProps: Props) {
    const { profile, onClose } = this.props;
    if (prevProps.profile && !profile) {
      this.removeImage();
    }

    if (
      !!prevProps.profile &&
      !!profile &&
      prevProps.profile.logoUrl !== profile.logoUrl
    ) {
      onClose();
    }
  }

  saveImage = async () => {
    const { imageFile, cropData } = this.state;
    const { updateLogoKey, profile } = this.props;

    if (imageFile && cropData) {
      const logoKey = await uploadBrandingLogo(imageFile, cropData);

      if (typeof logoKey === 'string') {
        await updateLogoKey(profile.id, logoKey);
      } else if (typeof logoKey === 'object') {
        this.setState({
          errorId: logoKey.errorId,
        });
      }
    }
  };

  removeImage = () => {
    this.setState({
      imageFile: null,
      cropData: null,
    });
  };

  handleImageChange = (file: File) => {
    this.setState({
      imageFile: file,
    });
  };

  handleCropValuesChange = (cropData: CropData) => {
    this.setState({
      cropData,
    });
  };

  render() {
    const { profile, onClose, i18n } = this.props;
    const { imageFile, cropData, errorId } = this.state;
    const isOpen = !!profile;
    let subTitle;

    if (imageFile) {
      subTitle = i18n.t(`account.profileSettings.cropSubtitle`);
    } else if (profile) {
      subTitle = i18n.t(`account.profileSettings.subtitle`, {
        profileName: profile.name || '',
      });
    }

    const secondaryAction = imageFile
      ? {
          labelId: 'actions.remove',
          onClick: this.removeImage,
        }
      : undefined;

    return (
      <AppModal
        large
        name="profile-settings"
        isOpen={isOpen}
        title={i18n.t('account.profileSettings.title')}
        headerIcon={SettingsIcon}
        onRequestClose={onClose}
        primaryAction={{
          labelId: 'actions.save',
          onClick: this.saveImage,
        }}
        secondaryAction={secondaryAction}
      >
        {errorId && (
          <SnackbarNotification
            id={errorId}
            type={NotificationType.Error}
            dismissTime={0}
            onDismiss={() => this.setState({ errorId: null })}
          >
            <FormattedMessage id={errorId} />
          </SnackbarNotification>
        )}
        <StyledSubTitle>{subTitle}</StyledSubTitle>
        <StyledUploadContainer $uploadContainerWithoutImageEnabled={!imageFile}>
          <ImageUpload
            image={imageFile}
            cropData={cropData}
            onCropValuesChange={this.handleCropValuesChange}
            onImageChange={this.handleImageChange}
          />
        </StyledUploadContainer>
      </AppModal>
    );
  }
}

function makeMapStateToProps() {
  const profileSelector = makeResourceSelector(
    ResourceTypes.Profiles,
    (_state: AppState, ownProps: OwnProps) => ownProps.profileId
  );

  return (state: AppState, ownProps: OwnProps) => ({
    profile: profileSelector(state, ownProps),
  });
}

const mapDispatchToProps = {
  updateLogoKey: (profileId: string, logoKey: string) =>
    updateResource({
      resourceName: ResourceTypes.Profiles,
      resourceId: profileId,
      attributesToUpdate: {
        logoKey,
      },
    }),
};

export default injectI18n(
  connect(makeMapStateToProps, mapDispatchToProps)(ProfileSettingsModal as any)
);
