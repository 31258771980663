import { useEffect, ReactNode } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import FocusLock from 'react-focus-lock';

import analytics, { AnalyticsContext, Categories } from '~/analytics';
import { boxShadows, breakpoints, colors, spacing, zIndex } from '~/styles';

import { ModalContext, ModalContextType } from './context';
import {
  useBrand,
  useApplicationScrolling,
  useAppAriaHidden,
  usePrevious,
  useIsDesktop,
} from '~/hooks';
import Portal from '~/components/Portal';
import Overlay from '~/components/Overlay';
import { useIsInsideModal } from '~/hooks/useIsInsideModal';
import { noop } from 'lodash-es';
import useCallbackRef from '~/hooks/useCallbackRef';

export const DEFAULT_MODAL_DESKTOP_WIDTH = '45rem';
export const SMALL_MODAL_DESKTOP_WIDTH = '25rem';
export const LARGE_MODAL_DESKTOP_WIDTH = '35rem';
const mixins = {
  modalContainerNegative: {
    borderBottom: `${spacing.smaller} solid ${colors.errorText}`,
  },

  modalInsideModal: {
    zIndex: zIndex.nestedModalOverlayForeground,
  },
};

const StyledModalContent = styled.form({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  minHeight: 0,
});

const StyledModalContainer = styled(motion.div)<{
  $brandColor: string;
  $modalContainerNegativeEnabled: boolean;
  $modalInsideModalEnabled: boolean;
  $modalContainerSmallEnabled: boolean;
  $modalContainerLargeEnabled: boolean;
  $modalContainerFullPageEnabled: boolean;
}>(props => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  maxHeight: '100vh',
  padding: props.$modalContainerFullPageEnabled ? 0 : spacing.large,
  backgroundColor: colors.white,
  boxShadow: boxShadows.surroundDark,
  borderBottom: props.$modalContainerFullPageEnabled
    ? 0
    : `${spacing.smaller} solid ${props.$brandColor}`,
  outline: 'none',
  overflow: 'hidden',
  zIndex: zIndex.modalOverlayForeground,

  [breakpoints.MEDIUM]: {
    left: props.$modalContainerFullPageEnabled ? 0 : '50%',
    top: props.$modalContainerFullPageEnabled ? 0 : '50%',
    width: props.$modalContainerFullPageEnabled
      ? '100%'
      : props.$modalContainerLargeEnabled
      ? LARGE_MODAL_DESKTOP_WIDTH
      : props.$modalContainerSmallEnabled
      ? SMALL_MODAL_DESKTOP_WIDTH
      : DEFAULT_MODAL_DESKTOP_WIDTH,
    height: props.$modalContainerFullPageEnabled ? '100%' : 'auto',
    minHeight:
      props.$modalContainerLargeEnabled || props.$modalContainerSmallEnabled
        ? '12.5rem'
        : SMALL_MODAL_DESKTOP_WIDTH,
    maxHeight: props.$modalContainerFullPageEnabled ? '100vh' : '80vh',
    padding: props.$modalContainerFullPageEnabled ? 0 : spacing.large,
    borderBottom: props.$modalContainerFullPageEnabled ? 0 : '',
    opacity: '0',
  },

  ...(props.$modalContainerNegativeEnabled
    ? mixins.modalContainerNegative
    : {}),
  ...(props.$modalInsideModalEnabled ? mixins.modalInsideModal : {}),
}));

export const MODAL_PORTAL_ID = 'modal-portal';

export enum ModalType {
  Small = 'small',
  Large = 'large',
  Marketing = 'marketing',
  FullPage = 'fullPage',
}

type RenderProps = {
  onRequestClose: () => void;
};

export interface BaseModalConsumerProps {
  /** Name of the modal, used for analytics */
  name: string;
  /** Title of the modal, that will appear in the modal header */
  title: string;
  /** State of the modal, open or closed */
  isOpen: boolean;
  /** Marks a modal as dismissable, adds a close icon and close actions to overlay */
  dismissable?: boolean;
  /** Marks a modal as negative, showing a red border at the bottom */
  isNegative?: boolean;
  /** Event fired when some user action is directing the modal to close */
  onRequestClose: () => void;
  /** When modal is open, determines if outside click closes modal or not */
  closeOnOutsideClick?: boolean;
  /** Modal only used on 'mobile' screens that slides up halfway from the bottom
   * Is more of a dialog than a modal but this is the best component we have for now.
   * By setting this, will remove the overlay
   */
  isMobileSlideUp?: boolean;
  /** Needs to be proxied down in case this component is extended  */
  className?: string;
}

interface BaseCommonModalProps extends Omit<BaseModalConsumerProps, 'title'> {
  /** Type of modal (small, large, or marketing) */
  type?: ModalType;
  /** If this modal contains a form, the HTML name of the form */
  formName?: string;
  /** children as a function */
  children: (props: RenderProps) => ReactNode;
}

interface WithLabelProps extends BaseCommonModalProps {
  /** Use this to announce a label for the modal to screen readers when none is displayed inside the modal */
  'aria-label': string;
}
interface WithLabeledByProps extends BaseCommonModalProps {
  /** Use this to connect the modal's label to an existing DOM element. The value should be the HTML ID of the element that contains the label for the modal. */
  'aria-labelledby': string;
}

export type BaseModalProps = WithLabelProps | WithLabeledByProps;

export type BaseModalInnerProps = ModalContextType & BaseModalProps;

export function BaseModal(props: BaseModalInnerProps) {
  const {
    name,
    isOpen,
    registerModal,
    onRequestClose,
    activeModalName,
    formName,
    children,
    type = ModalType.Small,
    dismissable = true,
    isNegative = false,
    isMobileSlideUp = false,
    closeOnOutsideClick = true,
    className,
    ...a11yProps
  } = props;
  const isDesktop = useIsDesktop();
  const [modalRef, setModalRef] = useCallbackRef<HTMLElement>();
  const insideModal = useIsInsideModal(modalRef);
  const useDesktopAnimation = isDesktop && type !== ModalType.FullPage;
  const brandedColor = useBrand();
  const isOpenAndActive =
    isOpen && (name === activeModalName || activeModalName === null);
  const prevIsOpenAndActive = usePrevious(isOpenAndActive);
  const handleRequestClose = () => {
    if (dismissable) {
      onRequestClose();
    }
  };

  useAppAriaHidden(isOpen);
  useApplicationScrolling(!isOpen, `modal-${name}`);

  useEffect(() => {
    registerModal(name, isOpen);
  }, [name, isOpen, registerModal]);

  useEffect(() => {
    if (isOpenAndActive && !prevIsOpenAndActive) {
      analytics.addContext(AnalyticsContext.Modal, name);
      analytics.track(Categories.INTERACTION, 'modal_open', name);
    } else if (!isOpenAndActive && prevIsOpenAndActive) {
      analytics.track(Categories.INTERACTION, 'modal_close', name);
      analytics.removeContext(AnalyticsContext.Modal, name);
    }
  }, [name, isOpenAndActive, prevIsOpenAndActive]);

  return (
    <AnimatePresence>
      <div ref={setModalRef} />
      {!isMobileSlideUp && (
        <Overlay
          key="overlay"
          isVisible={isOpen}
          zIndex={
            insideModal
              ? zIndex.nestedModalOverlayBackground
              : zIndex.modalOverlayBackground
          }
          onClick={closeOnOutsideClick ? handleRequestClose : noop}
        />
      )}
      {isOpen && (
        <Portal key={MODAL_PORTAL_ID} id={MODAL_PORTAL_ID} forceAppendToBody>
          <FocusLock returnFocus>
            <StyledModalContainer
              key="modal-motion"
              transition={{ duration: 0.3, ease: 'anticipate' }}
              initial={{
                x: useDesktopAnimation ? '-50%' : 0,
                y: useDesktopAnimation ? '50%' : '100%',
                opacity: useDesktopAnimation ? 0 : 1,
              }}
              animate={{
                x: useDesktopAnimation ? '-50%' : 0,
                y: useDesktopAnimation ? '-50%' : 0,
                opacity: 1,
              }}
              exit={{
                x: useDesktopAnimation ? '-50%' : 0,
                y: useDesktopAnimation ? '50%' : '100%',
                opacity: useDesktopAnimation ? 0 : 1,
              }}
              $modalContainerNegativeEnabled={isNegative}
              $modalInsideModalEnabled={insideModal}
              $modalContainerSmallEnabled={type === ModalType.Small}
              $modalContainerLargeEnabled={type === ModalType.Large}
              $modalContainerFullPageEnabled={type === ModalType.FullPage}
              $brandColor={brandedColor}
              role="dialog"
              aria-modal={true}
              className={className}
              {...a11yProps}
            >
              {!!formName ? (
                <StyledModalContent
                  name={formName}
                  onSubmit={e => {
                    e.preventDefault();
                  }}
                >
                  {children({ onRequestClose: handleRequestClose })}
                </StyledModalContent>
              ) : (
                <StyledModalContent as="div">
                  {children({ onRequestClose: handleRequestClose })}
                </StyledModalContent>
              )}
            </StyledModalContainer>
          </FocusLock>
        </Portal>
      )}
    </AnimatePresence>
  );
}

function ModalWrapper(props: BaseModalProps) {
  return (
    <ModalContext.Consumer>
      {context => {
        return !!context ? (
          <BaseModal
            {...props}
            registerModal={context.registerModal}
            activeModalName={context.activeModalName}
          />
        ) : null;
      }}
    </ModalContext.Consumer>
  );
}

export default ModalWrapper;
