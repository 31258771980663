import {
  forwardRef,
  createElement,
  createRef,
  useEffect,
  useState,
  useRef,
  Fragment,
  ReactNode,
  RefObject,
  MouseEvent,
  KeyboardEvent,
  ComponentType,
  useMemo,
  ForwardedRef,
} from 'react';
import styled from 'styled-components';
import { Position } from '~/components/Popover';
import { colors, fontSizes, spacing, zIndex } from '~/styles';
import BaseButton from '~/components/buttons/BaseButton';
import BaseDropdown from './BaseDropdown';
import { FormattedMessage } from '~/components/i18n';
import { motion } from 'framer-motion';
import Overlay from '~/components/Overlay';
import Portal from '~/components/Portal';
import {
  useApplicationScrolling,
  useAppAriaHidden,
  useIsDesktop,
} from '~/hooks';
import { preventOutsideScrollingProps } from '~/styles/utilities';
import { Values } from '../i18n/FormattedMessage';

const mixins = {
  menuIsHighlighted: {
    backgroundColor: colors.menuItemHighlight,
  },

  disabledButton: {
    backgroundColor: colors.white,
    color: colors.disabledText,
    cursor: 'not-allowed',

    '&:hover, &:focus': {
      backgroundColor: colors.white,
    },
  },

  menuItemNegativeText: {
    color: colors.errorText,
  },

  disabledText: {
    color: colors.disabledText,
  },
};

const StyledMobileDropdownMenuContent = styled.div({
  flex: '1 1 auto',
  overflow: 'auto',
});

const StyledMobileDropdownMenuHeader = styled.div({
  flex: '0 0 auto',
  padding: spacing.normal,
  borderBottom: `1px solid ${colors.hintText}`,
});

const StyledMobileDropdownMenuContainer = styled(motion.div)({
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  maxHeight: '80vh',
  backgroundColor: colors.white,
  zIndex: zIndex.overlayForeground,
});

const StyledMenu = styled.ul({
  margin: 0,
  padding: 0,
  listStyle: 'none',
});

const StyledMenuItemText = styled.div<{
  $menuItemNegativeTextEnabled: boolean;
  $disabledTextEnabled: boolean;
  $hasIcon: boolean;
}>(props => ({
  flex: '1 0 auto',
  marginLeft: props.$hasIcon ? spacing.normal : 0,
  color: colors.mainText,
  textAlign: 'left',
  whiteSpace: 'nowrap',
  ...(props.$menuItemNegativeTextEnabled ? mixins.menuItemNegativeText : {}),
  ...(props.$disabledTextEnabled ? mixins.disabledText : {}),
}));

const StyledMenuItemIcon = styled.div({
  flex: `0 0 ${spacing.normal}`,
  width: spacing.normal,
  height: spacing.normal,
});

const StyledBaseButton = styled(BaseButton)<{
  $menuIsHighlightedEnabled: boolean;
  $disabledButtonEnabled: boolean;
}>(props => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  margin: 0,
  padding: `${spacing.small} ${spacing.max} ${spacing.small} ${spacing.medium}`,
  backgroundColor: colors.white,
  border: 'none',
  outline: 'none',
  cursor: 'pointer',

  '> div': {
    width: '100%',
    height: 'auto',
    display: 'block',
  },

  '&:hover, &:focus': {
    backgroundColor: colors.menuItemHighlight,
  },

  ...(props.$menuIsHighlightedEnabled ? mixins.menuIsHighlighted : {}),
  ...(props.$disabledButtonEnabled ? mixins.disabledButton : {}),
}));

const StyledMenuItem = styled.li({
  margin: 0,
  padding: 0,
  minWidth: '12rem',
});

const StyledFooter = styled.li({
  padding: `${spacing.smallest} ${spacing.medium}`,
  color: colors.warningText,
  ...fontSizes.caption,
});

const StyledDivider = styled.li({
  width: '100%',
  margin: 0,
  padding: 0,
  borderBottom: `1px solid ${colors.hintText}`,
});

const StyledCustomMenuItemContent = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export enum MenuType {
  Divider = 'menu-divider',
  Footer = 'footer',
  Custom = 'custom',
}

type CustomMenuItem = {
  id: string;
  kind: MenuType.Custom;
  isVisible?: boolean;
  isDisabled?: boolean;
  isHighlighted?: boolean;
  children: React.ReactNode;
  tabIndex?: number;
  link?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};
type ActionMenuItem = Omit<CustomMenuItem, 'kind' | 'children' | 'onClick'> & {
  kind?: undefined;
  labelId: string;
  labelIdValues?: Values;
  icon?: ComponentType<any>;
  isNegative?: boolean;
  link?: string;
  openNewTab?: boolean;
  menuItemRef?: React.RefObject<HTMLLIElement>;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isVisible?: boolean;
  isDisabled?: boolean;
};
interface DividerMenuItem {
  id: 'menu-divider';
  kind: MenuType.Divider;
  isVisible?: boolean;
}

interface FooterMenuItem {
  id: 'footer';
  kind: MenuType.Footer;
  isVisible?: boolean;
  message?: string;
}

export type MenuItemProps =
  | ActionMenuItem
  | CustomMenuItem
  | DividerMenuItem
  | FooterMenuItem;

export type DropdownMenuProps = {
  /** Optional function to render a header inside the mobile drawer */
  renderMobileHeader?: () => ReactNode;
  menuChildren: MenuItemProps[];
  /** Open state of the dropdown menu */
  isOpen: boolean;
  /** Force the menu to be as wide as the parent */
  fullWidthMenu?: boolean;
  /** The default anchor position of the dropdown */
  defaultPosition?: Position;
  /** HTML ID of the element that triggers the opening of this dropdown */
  triggerId: string;
  /** Handler when the menu should close (outside click, etc) */
  onRequestClose: () => void;
  /** Gives a way to inhibit auto focus on open, useful for autocomplete inputs */
  shouldFocusOnOpen?: boolean;
  /** Needs to be proxied down in case this component is extended  */
  className?: string;
};

const MenuDivider = () => {
  return <StyledDivider data-testid="menuDivider" />;
};

const Footer = ({ message }: FooterMenuItem) => {
  return <StyledFooter data-testid="footer">{message}</StyledFooter>;
};

type CustomMenuItemProps = Omit<CustomMenuItem, 'kind'>;
const CustomMenuItemForwardedRef = function CustomMenuItemForwardedRef(
  {
    id,
    children,
    isHighlighted,
    isDisabled,
    tabIndex,
    ...buttonProps
  }: CustomMenuItemProps,
  ref: ForwardedRef<HTMLLIElement>
) {
  return (
    <StyledMenuItem id={id} ref={ref}>
      <StyledBaseButton
        {...buttonProps}
        id={id}
        analyticsAction="menu_button_click"
        $menuIsHighlightedEnabled={isHighlighted}
        $disabledButtonEnabled={isDisabled}
        role="menuitem"
        tabIndex={tabIndex}
        disabled={isDisabled}
      >
        {children}
      </StyledBaseButton>
    </StyledMenuItem>
  );
};
const CustomMenuItem = forwardRef<HTMLLIElement, CustomMenuItemProps>(
  CustomMenuItemForwardedRef
);

const MenuItemForwardedRef = function MenuItemForwardedRef(
  {
    id,
    icon,
    labelId,
    labelIdValues,
    isNegative = false,
    isHighlighted,
    tabIndex,
    isDisabled,
    ...buttonProps
  }: ActionMenuItem,
  ref: ForwardedRef<HTMLLIElement>
) {
  const iconElement = !!icon
    ? createElement(icon, {
        type: isNegative ? 'errorIcon' : isDisabled ? 'disabledText' : 'black',
        height: '100%',
      })
    : undefined;

  return (
    <CustomMenuItem
      {...buttonProps}
      ref={ref}
      id={id}
      isHighlighted={isHighlighted}
      isDisabled={isDisabled}
      tabIndex={tabIndex}
    >
      <StyledCustomMenuItemContent>
        {iconElement && <StyledMenuItemIcon>{iconElement}</StyledMenuItemIcon>}
        <StyledMenuItemText
          $menuItemNegativeTextEnabled={isNegative}
          $disabledTextEnabled={!!isDisabled}
          $hasIcon={!!iconElement}
        >
          <FormattedMessage id={labelId} values={labelIdValues} />
        </StyledMenuItemText>
      </StyledCustomMenuItemContent>
    </CustomMenuItem>
  );
};

const MenuItem = forwardRef<HTMLLIElement, ActionMenuItem>(
  MenuItemForwardedRef
);

type MenuRefsById = Record<string, RefObject<HTMLLIElement>>;
export const DropdownMenu = ({
  isOpen,
  renderMobileHeader,
  triggerId,
  onRequestClose,
  defaultPosition,
  menuChildren,
  shouldFocusOnOpen = true,
  className,
}: DropdownMenuProps) => {
  const isDesktop = useIsDesktop();

  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const [highlightedId, setHighlightedId] = useState<undefined | string>(
    undefined
  );
  const menuRef = useRef<HTMLUListElement | null>(null);
  const { filteredMenuChildren, menuItems, menuItemIds, menuItemIdRefs } =
    useMemo(() => {
      const nonMenuItemKinds = ['menu-divider', 'footer'];
      const filteredMenuChildren = menuChildren.filter(
        ({ isVisible = true }) => isVisible
      );
      const menuItems = filteredMenuChildren.filter(({ kind }) =>
        !!kind ? !nonMenuItemKinds.includes(kind) : true
      );
      const menuItemIds = menuItems.map(({ id }) => id);
      const menuItemIdRefs = menuItemIds.reduce((idRefs: MenuRefsById, id) => {
        if (!idRefs[id] && id) {
          idRefs[id] = createRef();
        }

        return idRefs;
      }, {});

      return {
        filteredMenuChildren,
        menuItems,
        menuItemIds,
        menuItemIdRefs,
      };
    }, [menuChildren]);

  const searchItems = (code: string) => {
    const letter = code.replace('Key', '').toLowerCase();
    const itemSubsection = menuItems.slice(highlightedIndex + 1);

    const selectedItem = itemSubsection.find(menuItem => {
      return menuItem.id[0] === letter;
    });

    let index = 0;

    if (selectedItem) {
      index = menuItems.findIndex(menuItem => menuItem.id === selectedItem.id);
    } else {
      index = menuItems.findIndex(menuItem => menuItem.id[0] === letter);
    }

    return index;
  };

  const moveFocus = (code: string) => {
    const menuItemsCount = menuItemIds.length;
    let newHighlighted = 0;
    if (code === 'Home') {
      newHighlighted = 0;
    } else if (code === 'End') {
      newHighlighted = menuItemsCount - 1;
    } else if (code === 'ArrowUp') {
      newHighlighted =
        highlightedIndex > 0 ? highlightedIndex - 1 : menuItemsCount - 1;
    } else if (code === 'ArrowDown') {
      newHighlighted =
        highlightedIndex < menuItemsCount - 1 ? highlightedIndex + 1 : 0;
    } else {
      newHighlighted = searchItems(code);
    }

    setHighlightedIndex(newHighlighted);
    setHighlightedId(menuItemIds[newHighlighted]);
  };

  const handleMouseEventListener = (e: MouseEvent<HTMLUListElement>) => {
    e.preventDefault();

    const index = menuItemIds.findIndex(id => id === e.currentTarget.id);
    setHighlightedIndex(index);
    setHighlightedId(menuItemIds[index]);
  };

  const handleKeyEventListener = (e: KeyboardEvent) => {
    const { code } = e;
    e.preventDefault();

    if (
      code === 'Enter' &&
      highlightedId &&
      menuItemIdRefs[highlightedId].current
    ) {
      const button =
        menuItemIdRefs[highlightedId].current?.querySelector('button');
      const link = menuItemIdRefs[highlightedId].current?.querySelector('a');

      button ? button.click() : link?.click();
    } else if (code === 'Escape') {
      setHighlightedIndex(0);
      setHighlightedId(undefined);

      if (menuRef?.current) {
        menuRef.current.blur();
      }

      if (triggerId) {
        document.getElementById(triggerId)?.focus();
      }
      onRequestClose?.();
    } else {
      moveFocus(code);
    }
  };

  useEffect(() => {
    if (menuRef.current) {
      if (isOpen && menuItemIds.length > 0) {
        if (shouldFocusOnOpen) {
          menuRef.current.focus();
        }

        if (!highlightedIndex) {
          setHighlightedId(menuItemIds[0]);
        }
      } else {
        menuRef.current.blur();
        setHighlightedIndex(0);
        setHighlightedId(undefined);
      }
    }
  }, [shouldFocusOnOpen, isOpen, menuItemIds, highlightedIndex, triggerId]);

  const menuContent = (
    <StyledMenu
      onKeyDown={handleKeyEventListener}
      onMouseOver={handleMouseEventListener}
      tabIndex={-1}
      ref={menuRef}
      role="menu"
      aria-activedescendant={highlightedId ? highlightedId : ''}
      className={className}
      {...preventOutsideScrollingProps}
    >
      {filteredMenuChildren.map((props: MenuItemProps, i) => {
        if (props.kind === MenuType.Divider) {
          return <MenuDivider key={i} />;
        } else if (props.kind === MenuType.Footer) {
          return (
            <Footer
              key={i}
              message={props.message}
              id={props.id}
              kind={props.kind}
            />
          );
        } else if (props.kind === MenuType.Custom) {
          return (
            <CustomMenuItem
              {...props}
              ref={menuItemIdRefs[props.id]}
              isHighlighted={highlightedId === props.id}
              tabIndex={highlightedId === props.id ? -1 : 0}
              key={props.id}
            />
          );
        } else {
          return (
            <MenuItem
              {...props}
              ref={menuItemIdRefs[props.id]}
              isHighlighted={highlightedId === props.id}
              tabIndex={highlightedId === props.id ? -1 : 0}
              key={props.id}
            />
          );
        }
      })}
    </StyledMenu>
  );

  useAppAriaHidden(!isDesktop && isOpen);
  useApplicationScrolling(isDesktop || !isOpen, 'dropdown-menu');
  return isDesktop ? (
    <BaseDropdown
      isOpen={isOpen}
      defaultPosition={defaultPosition}
      triggerId={triggerId}
      onRequestClose={onRequestClose}
    >
      {menuContent}
    </BaseDropdown>
  ) : (
    <Fragment>
      <Overlay key="overlay" isVisible={isOpen} onClick={onRequestClose} />

      <Portal key="portal" id="dropdown-menu-portal">
        <StyledMobileDropdownMenuContainer
          key="mobile-menu"
          initial="closed"
          animate={isOpen ? 'open' : 'closed'}
          variants={{
            open: { display: 'block', y: 0 },
            closed: { y: '100%', transitionEnd: { display: 'none' } },
          }}
          transition={{ duration: 0.15, ease: 'easeInOut' }}
          data-testid="dropdown-menu-portal"
        >
          {!!renderMobileHeader && (
            <StyledMobileDropdownMenuHeader>
              {renderMobileHeader()}
            </StyledMobileDropdownMenuHeader>
          )}
          <StyledMobileDropdownMenuContent>
            {menuContent}
          </StyledMobileDropdownMenuContent>
        </StyledMobileDropdownMenuContainer>
      </Portal>
    </Fragment>
  );
};

export default DropdownMenu;
