import { useCallback, useState } from 'react';

export default function useCallbackRef<T>(initialValue?: T | null) {
  const [ref, setRef] = useState(initialValue);

  const callbackRef = useCallback(node => {
    if (node !== null) {
      setRef(node);
    }
  }, []);

  return [ref, callbackRef] as const;
}
