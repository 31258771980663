import { datadogRum } from '@datadog/browser-rum';
import { Middleware } from 'redux';

import config from '~/config';

const {
  ui: { isProductionBuild },
} = config;

export default function createDatadogMiddleware() {
  let datadogMiddleware: Middleware = () => next => action => {
    // Disabled middleware that just passes through, for when datadog is not configured
    return next(action);
  };

  if (isProductionBuild) {
    datadogMiddleware = () => next => action => {
      let message = action.type;

      if (!message && typeof action === 'function') {
        message = 'thunk';
      }

      try {
        return next(action);
      } catch (error) {
        console.error(
          '[datadog middleware] Reporting error to datadog:',
          error
        );

        datadogRum.addRumGlobalContext('evt', {
          name: message,
          outcome: 'failure',
        });
        datadogRum.addError(error, { action });
      }
    };
  }

  return datadogMiddleware;
}
