import { I18nContext } from './context';
import { formatDate } from './utils';
import { DateOptions } from './types';

type Props = {
  date: Date;
  dateFormat: keyof DateOptions;
};

export function FormattedDate(props: Props) {
  const { date, dateFormat } = props;

  return (
    <I18nContext.Consumer>
      {context => {
        const value = !!context
          ? formatDate(date, dateFormat, context.i18n.i18next)
          : null;

        return value;
      }}
    </I18nContext.Consumer>
  );
}

export default FormattedDate;
