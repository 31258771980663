import { useState, KeyboardEvent } from 'react';

import { makeVegaRequest } from '~/lib/api/network';
import APP_CONFIG from '~/config';
import InlineTextInput from '~/components/inputs/text/InlineTextInput';

const {
  api: { localProxyEnv },
} = APP_CONFIG;

type Props = {
  onAuthenticatedSuccess: () => void;
};

function LoginPage(props: Props) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameChange = (username: string) => {
    setUsername(username);
  };

  const handlePasswordChange = (password: string) => {
    setPassword(password);
  };

  const handleLogin = async () => {
    const body = JSON.stringify({
      username,
      password,
      rememberMe: true,
    });

    try {
      const result = await makeVegaRequest('login', {
        method: 'POST',
        body,
      });

      if (result.status === 200) {
        props.onAuthenticatedSuccess();
      } else {
        alert('Login failed');
      }
    } catch (e) {
      alert('Login failed');
    }
  };

  const checkForEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleLogin();
      e.preventDefault();
    }
  };

  return (
    <div>
      <h3>Login to {localProxyEnv} environment</h3>
      <InlineTextInput
        id="login-username"
        name="login-username"
        type="email"
        value={username}
        label="Enter Username"
        onChange={handleUsernameChange}
        onKeyUp={checkForEnter}
      />
      <InlineTextInput
        id="login-password"
        name="login-password"
        type="password"
        value={password}
        label="Enter Password"
        onChange={handlePasswordChange}
        onKeyUp={checkForEnter}
      />
      <button className="btn-login" onClick={handleLogin}>
        Login
      </button>
    </div>
  );
}

export default LoginPage;
