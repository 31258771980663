import styled from 'styled-components';

import LoadingIcon from '~/components/icons/LoadingIcon';

import { isIE } from '~/lib/utils/browser';

const StyledLoadingGif = styled.img({
  width: '100%',
  height: '100%',
});

const StyledLoadingIndicator = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '5rem',
  height: '5rem',
  transform: 'translate(50%, 50%) translate(-5rem, -5rem)',
});

type Props = {
  /** Color to be used. Either 'brand' or one of the colors from the color palette */
  type?: string;
};

export const LoadingIndicator = function ({ type = 'brand' }: Props) {
  const useGif = isIE();

  return (
    <StyledLoadingIndicator>
      {useGif ? (
        <StyledLoadingGif src="/dotloop-smile-loader.gif" alt="Loading" />
      ) : (
        <LoadingIcon type={type} width="100%" />
      )}
    </StyledLoadingIndicator>
  );
};

LoadingIndicator.displayName = 'LoadingIndicator';

export default LoadingIndicator;
