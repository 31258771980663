import { ColorPalette } from './types';
import { isFeatureEnabled } from '~/lib/feature-flags';
import FeatureFlag from '~/lib/feature-flags/constants';

export const DEFAULT_BRAND_COLOR = '#39A6FA';
export const USE_DARK_MODE = isFeatureEnabled(FeatureFlag.DarkMode);

enum THEME_LIGHT {
  primaryLight = '#E5F1FB',
  primaryBlue = '#0077D8',
  primaryDark = '#015A97',
  warningForeground = '#CD4D00',
  warningBackground = '#F8E4D9',
  errorForeground = '#C31F3B',
  errorBackground = '#F6E5E8',
  successForeground = '#077D5C',
  successBackground = '#E5F7F1',
  notification = '#FE4A49',
  highlight = '#FDEB6C',
  highlightBackground = '#FDF4B5',
  black = '#333',
  darkestGray = '#6C6C76',
  darkGray = '#9596A6',
  mediumDarkGray = '#D0D0D0',
  mediumGray = '#D8D8DB',
  lightGray = '#EBECED',
  backgroundGray = '#F6F6F6',
  white = '#FFF',
}

enum THEME_DARK {
  primaryLight = '#BADFFD',
  primaryBlue = '#0077D8',
  primaryDark = '#015A97',
  warningForeground = '#EC9464',
  warningBackground = '#F8E4D9',
  errorForeground = '#FE4A49',
  errorBackground = '#F6E5E8',
  successForeground = '#077D5C',
  successBackground = '#E5F7F1',
  notification = '#FE4A49',
  highlight = '#FDEB6C',
  highlightBackground = '#FDF4B5',
  black = '#E1E1E1',
  darkestGray = '#A8A89E',
  darkGray = '#7F7E6E',
  mediumDarkGray = '#444',
  mediumGray = '#3C3C39',
  lightGray = '#292827',
  backgroundGray = '#030303',
  white = '#1A1A1A',
}

enum BASE_COLORS {
  white = '#FFF',
}

const THEME = USE_DARK_MODE ? THEME_DARK : THEME_LIGHT;

const colors: ColorPalette = {
  // Primary Actions
  primaryLight: THEME.primaryLight,
  primaryAction: THEME.primaryBlue,
  primaryLink: THEME.primaryBlue,
  primaryLinkFocus: USE_DARK_MODE ? DEFAULT_BRAND_COLOR : THEME.primaryDark,
  primaryButton: THEME.primaryBlue,
  primaryButtonFocus: THEME.primaryDark,
  primaryBackground: THEME.primaryBlue,
  primaryBackgroundDark: THEME.primaryDark,
  primaryBackgroundText: BASE_COLORS.white,

  // Action / Information
  infoText: THEME.primaryDark,
  infoIcon: THEME.primaryDark,
  infoColor: THEME.primaryDark,
  infoBackground: '#E0EFF8',

  // Warning / Alerts
  warningText: THEME.warningForeground,
  warningIcon: THEME.warningForeground,
  warningColor: THEME.warningForeground,
  warningBackground: THEME.warningBackground,

  // Errors
  errorText: THEME.errorForeground,
  errorIcon: THEME.errorForeground,
  errorColor: THEME.errorForeground,
  errorBackground: THEME.errorBackground,

  // Alerts
  alertText: THEME.errorForeground,
  alertColor: THEME.errorForeground,

  // Positive / Success
  successText: THEME.successForeground,
  successIcon: THEME.successForeground,
  successColor: THEME.successForeground,
  successBackground: THEME.successBackground,

  // Notifications
  notificationIcon: THEME.notification,
  highlight: THEME.highlight,
  highlightBackground: THEME.highlightBackground,

  // Black / Gray / White
  white: THEME.white,

  // Black
  black: THEME.black,
  mainText: THEME.black,

  // Darkest Gray
  secondaryText: THEME.darkestGray,

  // Dark Gray
  hintText: THEME.darkGray,
  darkBorder: THEME.darkGray,

  // Medium dark Gray
  mediumBorder: THEME.mediumDarkGray,

  // Medium Gray
  lightBorder: THEME.mediumGray,
  disabledText: THEME.mediumGray,
  darkBackground: THEME.mediumGray,
  divider: THEME.mediumGray,

  // Light Gray
  lightBackground: THEME.lightGray,
  menuItemHighlight: THEME.lightGray,

  // Lightest Gray
  backgroundGray: THEME.backgroundGray,

  avatars: [
    '#B46CEF',
    '#714BE3',
    '#5D2E8C',
    '#E66692', // 😈
    '#F50057',
    '#AA1155',
    '#24A810',
    '#2A7221',
    '#F76700',
    '#D84A05',
    '#36A2B5',
    '#2E9598',
  ],
};

export default colors;
