import { useState } from 'react';
import { useAppSelector } from '~/store/hooks';

import { createLoadablePage } from '~/pages/utils/createLoadablePage';
import { useMedia } from '~/hooks';
import { screenSizes } from '~/styles';
import { AppState } from '~/store';
import { hasPermission } from '~/store/features/account/selectors';
import NoAccess from './components/NoAccess';

const MyLoopsContainer = createLoadablePage({
  loader: () =>
    import(/* webpackChunkName: "my-loops" */ './containers/MyLoopsContainer'),
  i18nNamespace: 'my-loops',
  loading: () => null,
});

const DEFAULT_LOOP_PAGE_SIZE = 12;
const DEFAULT_UNASSIGNED_LOOP_PAGE_SIZE = 250;
const DEFAULT_UNASSIGNED_LOOP_PAGE_NUMBER = 1;

export default function MyLoopsPage() {
  const [currentLoopPage, setCurrentLoopPage] = useState(1);
  const isPrinting = useMedia('print');
  const isLargeScreen = useMedia({ minWidth: screenSizes.LARGE });
  const hasManageLoopsPermission = useAppSelector((state: AppState) =>
    hasPermission(state, 'MANAGE_LOOPS')
  );

  const advancePage = () => {
    setCurrentLoopPage(prevLoopPage => prevLoopPage + 1);
  };

  const resetPaging = () => {
    setCurrentLoopPage(1);
  };

  return hasManageLoopsPermission ? (
    <MyLoopsContainer
      isPrinting={isPrinting}
      isLargeScreen={isLargeScreen}
      advancePage={advancePage}
      resetPaging={resetPaging}
      pageNumber={currentLoopPage}
      pageSize={DEFAULT_LOOP_PAGE_SIZE}
      unassignedPageNumber={DEFAULT_UNASSIGNED_LOOP_PAGE_NUMBER}
      unassignedPageSize={DEFAULT_UNASSIGNED_LOOP_PAGE_SIZE}
    />
  ) : (
    <NoAccess />
  );
}
