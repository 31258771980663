import { ComponentType, FunctionComponent, SVGProps } from 'react';
import styled from 'styled-components';

import calculateColor from './color';
import calculateDimensions from './dimensions';

import { Dimensions } from './dimensions';
import { BaseIconProps, IconProps, RotationDirection } from '../types';
import { useBrand } from '~/hooks';

const StyledCanvas = styled.canvas<{ $canvasDimensions: Dimensions }>(
  props => ({
    display: 'block',
    visibility: 'hidden',
    ...props.$canvasDimensions,
  })
);

const StyledSvgContainer = styled.div<{
  $svgContainerFillEnabled: boolean;
  $svgContainerStrokeEnabled: boolean;
  $svgColor: string;
  $svgDimensions: Dimensions;
}>(props => ({
  display: 'block',
  position: 'relative',
  userSelect: 'none',
  WebkitBackfaceVisibility: 'hidden',
  overflow: 'visible',
  ...props.$svgDimensions,

  svg: {
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',

    'path, g, circle, use, rect, ellipse': {
      stroke: props.$svgContainerFillEnabled
        ? `none !important`
        : props.$svgContainerStrokeEnabled
        ? `${props.$svgColor} !important`
        : '',
      fill: props.$svgContainerStrokeEnabled
        ? 'none !important'
        : props.$svgContainerFillEnabled
        ? `${props.$svgColor} !important`
        : '',
    },
  },
}));

function getRotationDegrees(direction?: RotationDirection): number {
  switch (direction) {
    case RotationDirection.Left:
      return 90;
    case RotationDirection.Up:
      return 180;
    case RotationDirection.Right:
      return 270;
    default:
      return 0;
  }
}

function getDisplayName(Component: ComponentType<any>) {
  let displayName = 'Component';

  if (Component) {
    displayName = Component.displayName || Component.name;
  }
  return displayName;
}

type IconType = 'stroke' | 'fill' | 'other';
type IconComponentType =
  | FunctionComponent<SVGProps<SVGSVGElement>>
  | ComponentType<BaseIconProps>;

export default function scalableIcon(
  viewboxWidth = 1,
  viewboxHeight = 1,
  iconType: IconType = 'stroke'
) {
  return function (IconComponent: IconComponentType) {
    const ScalableIcon = function ScalableIcon(props: IconProps) {
      const {
        type,
        width,
        height,
        svgProps,
        direction,
        className = '',
      } = props;
      const brand = useBrand();
      const color = calculateColor(brand, type);
      const rotation = getRotationDegrees(direction);
      const svgStyles = {
        transition: 'transform 0.2s ease',
        transform: `rotate(${rotation}deg)`,
      };
      const isFill = iconType === 'fill';
      const isStroke = iconType === 'stroke';

      if (!!svgProps) {
        return (
          <IconComponent svgProps={svgProps} style={svgStyles} color={color} />
        );
      } else {
        const dimensions = calculateDimensions(width, height);
        const canvasDimensions =
          'width' in dimensions ? { width: '100%' } : { height: '100%' };

        return (
          <StyledSvgContainer
            className={className}
            $svgColor={color}
            $svgDimensions={dimensions}
            $svgContainerFillEnabled={isFill}
            $svgContainerStrokeEnabled={isStroke}
          >
            <StyledCanvas
              width={viewboxWidth}
              height={viewboxHeight}
              $canvasDimensions={canvasDimensions}
            />
            <IconComponent style={svgStyles} color={color} />
          </StyledSvgContainer>
        );
      }
    };

    ScalableIcon.displayName = `scalableIcon(${getDisplayName(IconComponent)})`;

    return ScalableIcon;
  };
}
