import { ReactNode } from 'react';

import { useRadioContext } from './context';
import { useAutoId } from '~/lib/utils/auto-id';

import BaseRadioButton from './BaseRadioButton';

type Props = {
  /** Value of the radio input */
  value: string;
  /** Label of the radio input, displayed if no `labelRender` function is given */
  label: string | JSX.Element;
  /** Hides the label and places it as a title on the radio SVG */
  hideLabel?: boolean;
  /** Function called to render the label for the radio button */
  labelRender?: (label: string) => ReactNode;
  /** Whether or not the radio button is disabled */
  disabled?: boolean;
};

function RadioButton({
  value,
  label,
  hideLabel,
  labelRender,
  disabled,
}: Props) {
  const { name, selectedValue, onChange } = useRadioContext();
  const id = useAutoId();

  const isSelected = value === selectedValue;

  return (
    <BaseRadioButton
      id={id}
      name={name}
      value={value}
      isSelected={isSelected}
      label={label}
      hideLabel={hideLabel}
      labelRender={labelRender}
      onChange={onChange}
      disabled={disabled}
    />
  );
}

export default RadioButton;
