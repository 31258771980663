import OutlinedTextField from '~/components/inputs/text/OutlinedTextInput';
import { noop } from 'lodash-es';

type Props = {
  id: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  disabled?: boolean;
  validateEmail: (value: string) => string | undefined;
  highlighted: boolean;
  required?: boolean;
  autoFocus?: boolean;
};
const EmailAddressInput = ({
  id,
  label,
  value,
  onChange,
  onBlur = noop,
  disabled = false,
  validateEmail,
  highlighted,
  required = true,
  autoFocus = false,
}: Props) => {
  return (
    <OutlinedTextField
      id={id}
      label={label}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      type="email"
      disabled={disabled}
      validate={validateEmail}
      highlighted={highlighted}
      required={required}
      autoFocus={autoFocus}
    />
  );
};

export default EmailAddressInput;
