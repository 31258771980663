import { Fragment } from 'react';
import styled from 'styled-components';

import { FormattedDate, FormattedMessage } from '~/components/i18n';
import { DATE_FORMATS } from '~/components/i18n/utils';
import { fontSizes, spacing } from '~/styles';
import { ConversationType } from '~/store/features/api/resources/conversation/constants';
import { ParticipantStatus } from '~/store/features/api/resources/participantStatus/constants';
import { Avatar } from '~/components/Avatar';
import { getMessageRelativeTimestamp, getParticipantLabel } from './utils';

import { MessageResource } from '~/store/features/api/resources/message/types';
import { ParticipantResource } from '~/store/features/api/resources/participant/types';
import { ParticipantStatusResource } from '~/store/features/api/resources/participantStatus/types';

const mixins = {
  messageSentByMe: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
};

const StyledMessageTime = styled.div<{
  $messageSentByMeEnabled: boolean;
}>(props => ({
  ...fontSizes.callout,
  ...(props.$messageSentByMeEnabled ? mixins.messageSentByMe : {}),
}));

const StyledMessageReceiptContainer = styled.div({
  display: 'flex',
});

const StyledParticipantAvatar = styled.div({
  display: 'inline-block',
  width: '1.5rem',
  height: '1.5rem',
  marginLeft: spacing.smallest,
});

const StyledSeparator = styled.span({
  ...fontSizes.secondaryTitle,
  lineHeight: '1rem',
  margin: '0 0.125rem',
});

const MAX_RECIPIENTS_TO_DISPLAY = 3;

type StatusInfo = {
  sender?: ParticipantResource;
  statuses?: Array<ParticipantStatusResource>;
};

function getMessageStatusInfo({ sender, statuses }: StatusInfo) {
  if (!!sender && !!statuses) {
    const otherStatuses = statuses.filter(s => s.participantUuid !== sender.id);
    const isDelivered = !otherStatuses.some(
      s =>
        s.status !== ParticipantStatus.Delivered &&
        s.status !== ParticipantStatus.Read
    );
    const isRead = otherStatuses.some(s => s.status === ParticipantStatus.Read);
    const isOpened = otherStatuses.some(
      s => s.status === ParticipantStatus.DocumentOpened
    );

    return { isDelivered, isRead, isOpened };
  } else {
    return { isDelivered: false, isRead: false, isOpened: false };
  }
}

function Separator() {
  return <StyledSeparator>&middot;</StyledSeparator>;
}

type DirectMessageReceiptProps = {
  message: MessageResource;
};

function DirectMessageReceipt({ message }: DirectMessageReceiptProps) {
  const { sender, sentDate, statuses } = message;
  const { isDelivered, isRead, isOpened } = getMessageStatusInfo({
    sender,
    statuses,
  });
  const recipientStatus = !!statuses
    ? statuses.find(s => s.participantUuid !== sender.id)
    : null;
  const dateTimestamp = !!recipientStatus ? recipientStatus.date : sentDate;
  const dayContents = getMessageRelativeTimestamp(dateTimestamp);

  return (
    <Fragment>
      {isOpened ? (
        <FormattedMessage id="messaging.opened" />
      ) : isRead ? (
        <FormattedMessage id="messaging.read" />
      ) : isDelivered ? (
        <FormattedMessage id="messaging.delivered" />
      ) : (
        <FormattedMessage id="messaging.sent" />
      )}
      <Separator />
      {dayContents}
      <Separator />
      <FormattedDate date={dateTimestamp} dateFormat={DATE_FORMATS.TIME} />
    </Fragment>
  );
}

type GroupMessageReceiptProps = {
  message: MessageResource;
  participants: Array<ParticipantResource>;
};

function GroupMessageReceipt({
  message,
  participants,
}: GroupMessageReceiptProps) {
  const { sender, statuses } = message;
  const { isDelivered, isRead, isOpened } = getMessageStatusInfo({
    sender,
    statuses,
  });
  const readByParticipantIds = !!statuses
    ? statuses
        .filter(s => s.status === ParticipantStatus.Read)
        .map(s => s.participantUuid)
    : [];
  const readByParticipants = !!participants
    ? participants.filter(p => ~readByParticipantIds.indexOf(p.id))
    : [];
  const openedByParticipantIds = !!statuses
    ? statuses
        .filter(s => s.status === ParticipantStatus.DocumentOpened)
        .map(s => s.participantUuid)
    : [];
  const openedByParticipants = !!participants
    ? participants.filter(p => ~openedByParticipantIds.indexOf(p.id))
    : [];

  if (isRead || isOpened) {
    const participantList = isOpened
      ? openedByParticipants
      : readByParticipants;
    const hasExcessRecipients =
      participantList.length > MAX_RECIPIENTS_TO_DISPLAY;

    return (
      <Fragment>
        <FormattedMessage id="messaging.readBy" />
        {participantList.map((participant, index) => {
          if (hasExcessRecipients && index >= MAX_RECIPIENTS_TO_DISPLAY) {
            return null;
          }

          return (
            <StyledParticipantAvatar key={participant.id}>
              {hasExcessRecipients &&
              index === MAX_RECIPIENTS_TO_DISPLAY - 1 ? (
                <Avatar
                  hasMultipleRecipients
                  additionalNumber={
                    participantList.length - MAX_RECIPIENTS_TO_DISPLAY + 1
                  }
                />
              ) : (
                <Avatar hasMultipleRecipients participant={participant} />
              )}
            </StyledParticipantAvatar>
          );
        })}
      </Fragment>
    );
  } else if (isDelivered) {
    const { sentDate } = message;
    const dayContents = getMessageRelativeTimestamp(sentDate);

    return (
      <Fragment>
        <FormattedMessage id="messaging.delivered" />
        <Separator />
        {dayContents}
        <Separator />
        <FormattedDate date={sentDate} dateFormat={DATE_FORMATS.TIME} />
      </Fragment>
    );
  }

  return null;
}

type OtherParticipantReceiptProps = {
  message: MessageResource;
  isGroupConversation: boolean;
};

function OtherParticipantReceipt({
  message,
  isGroupConversation,
}: OtherParticipantReceiptProps) {
  const { sentDate, sender } = message;
  const dayContents = getMessageRelativeTimestamp(sentDate);

  return (
    <Fragment>
      {isGroupConversation && (
        <Fragment>
          {getParticipantLabel(sender)}
          <Separator />
        </Fragment>
      )}
      {dayContents}
      <Separator />
      <FormattedDate date={sentDate} dateFormat={DATE_FORMATS.TIME} />
    </Fragment>
  );
}

type Props = {
  message: MessageResource;
  currentDate: Date;
  sentByMe: boolean;
  conversationType: ConversationType;
  participants: Array<ParticipantResource>;
};

export function ConversationMessageReceipt({
  message,
  message: { sender },
  sentByMe,
  conversationType,
  participants,
}: Props) {
  const { sentDate } = message;
  const isGroupConversation = conversationType === ConversationType.Group;

  return (
    <StyledMessageReceiptContainer>
      <StyledMessageTime $messageSentByMeEnabled={sentByMe}>
        {!sentByMe && !!sender && (
          <OtherParticipantReceipt
            message={message}
            isGroupConversation={isGroupConversation}
          />
        )}
        {!!sentDate && sentByMe ? (
          <Fragment>
            {isGroupConversation ? (
              <GroupMessageReceipt
                message={message}
                participants={participants}
              />
            ) : (
              <DirectMessageReceipt message={message} />
            )}
          </Fragment>
        ) : !sentDate ? (
          <FormattedMessage id="messaging.sendingMessage" />
        ) : null}
      </StyledMessageTime>
    </StyledMessageReceiptContainer>
  );
}

export default ConversationMessageReceipt;
