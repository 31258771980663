import breakpoints from './breakpoints';

export enum spacing {
  smallest = '0.25rem',
  smaller = '0.5rem',
  small = '0.75rem',
  normal = '1rem',
  medium = '1.25rem',
  large = '1.5rem',
  larger = '1.75rem',
  largest = '2rem',
  max = '2.5rem',
}

export const MOBILE_HEADER_HEIGHT = '4rem';
export const LARGE_HEADER_HEIGHT = '4.25rem';

export const headerHeight = {
  styles: {
    height: MOBILE_HEADER_HEIGHT,
    [breakpoints.MEDIUM]: {
      height: LARGE_HEADER_HEIGHT,
    },
  },
  values: {
    default: MOBILE_HEADER_HEIGHT,
    breakpoints: [
      {
        mediaQuery: breakpoints.MEDIUM,
        height: LARGE_HEADER_HEIGHT,
      },
    ],
  },
};
