import { useState, useRef } from 'react';
import styled from 'styled-components';

import analytics, { Categories } from '~/analytics';
import { LinkButton, PrimaryButton } from '~/components/buttons';
import DynamicTextArea from '~/components/DynamicTextArea';
import { FormattedMessage } from '~/components/i18n';
import { ShareIcon } from '~/components/icons';

import { breakpoints, colors, fontWeights, spacing } from '~/styles';
import { useI18n, useIsDesktop } from '~/hooks';

const StyledTextArea = styled(DynamicTextArea)({
  display: 'block',
  flex: '1 0 auto',
  width: '100%',
  outline: 'none',
  resize: 'none',
  padding: spacing.small,
  paddingRight: `calc(1.5rem + ${spacing.small} + ${spacing.smaller})`,
  border: `1px solid ${colors.lightBorder}`,
  fontWeight: fontWeights.semiBold,
  backgroundColor: colors.white,

  '&:focus': {
    borderColor: colors.primaryAction,
  },

  [breakpoints.MEDIUM]: {
    paddingRight: `calc(${spacing.small} + ${spacing.smaller})`,
    paddingBottom: `calc(${spacing.normal} + ${spacing.max})`,
  },
});
const StyledSendButton = styled.div({
  position: 'absolute',
  top: '50%',
  right: spacing.small,
  transform: 'translateY(-50%)',

  '> button': {
    display: 'block',
  },

  [breakpoints.MEDIUM]: {
    top: 'auto',
    right: 'auto',
    left: spacing.small,
    bottom: spacing.small,
    transform: 'none',
  },
});

type Props = {
  onRowsChange: (rows: number) => void;
  sendTextMessage: (text: string) => Promise<any>;
  messageActionContainerRef: (ref: HTMLElement | null) => void;
};

export function ConversationMessageActions(props: Props) {
  const { onRowsChange, sendTextMessage } = props;
  const [newMessageText, setNewMessageText] = useState('');
  const i18n = useI18n();
  const isDesktop = useIsDesktop();
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const sendButtonDisabled = !newMessageText;

  const handleNewMessageChange = (value: string) => {
    setNewMessageText(value);
  };

  const sendMessage = async () => {
    if (newMessageText) {
      setNewMessageText('');

      if (textAreaRef.current) {
        textAreaRef.current.focus();
      }

      analytics.track(Categories.INTERACTION, 'messaging', 'send');

      await sendTextMessage(newMessageText);
    }
  };

  return (
    <div ref={props.messageActionContainerRef}>
      <StyledTextArea
        id="conversation-message-input"
        textAreaRef={(ref: HTMLTextAreaElement | null) =>
          (textAreaRef.current = ref)
        }
        defaultRows={isDesktop ? 3 : 1}
        placeholder={i18n.t('messaging.textInputPlaceholder')}
        value={newMessageText}
        onChange={handleNewMessageChange}
        onEnter={sendMessage}
        onRowsChange={onRowsChange}
      />
      <StyledSendButton>
        {isDesktop ? (
          <PrimaryButton
            id="send-message"
            icon={ShareIcon}
            defaultIconProps={{ height: spacing.medium }}
            disabled={sendButtonDisabled}
            onClick={sendMessage}
          >
            <FormattedMessage id="messaging.sendButton" />
          </PrimaryButton>
        ) : (
          <LinkButton
            id="send-message"
            removeSidePadding
            disabled={sendButtonDisabled}
            onClick={sendMessage}
            icon={ShareIcon}
            defaultIconProps={{
              height: '1.5rem',
            }}
          />
        )}
      </StyledSendButton>
    </div>
  );
}

export default ConversationMessageActions;
