import { datadogRum } from '@datadog/browser-rum';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  makePhoenixRequest,
  redirectToSignin,
  setCachedProfile,
} from '~/lib/api/network';
import { removeProfileRelatedResources } from '../api/apiSlice';
import { ProfileResource } from '../api/resources/profile/types';
import { AccountInfo } from './types';

export const fetchAccount = createAsyncThunk(
  'account/fetchAccount',
  async (opts, { rejectWithValue }) => {
    try {
      const inflightPromise = Promise.resolve(
        window.__PRELOADED_ACCOUNT_FETCH__
      );
      window.__PRELOADED_ACCOUNT_FETCH__ = undefined;
      const response = await inflightPromise;
      let json: AccountInfo;
      let status: number;

      if (response) {
        const rawJson = await response.json();
        status = response.status;
        json = {
          ...rawJson,
          isAssumingControl: rawJson.assumedControl,
        };
      } else {
        // fallback if the promise wasn't attached in the initial HTML or it was consumed already
        // and we need to fetch to get the latest account data
        const response = await makePhoenixRequest('account');
        json = response.payload;
        status = response.status;

        // adjust mapping from payload response to accountInfo
        json.isAssumingControl = response.payload.assumedControl;
      }

      const { currentProfile } = json;

      if (redirectableStatusCodes.includes(status)) {
        redirectToSignin();
      } else if (json && currentProfile) {
        setCachedProfile(currentProfile.id);
        datadogRum.setUser({
          id: json.userId.toString(),
          profileType: currentProfile.profileType.toString(),
          subscriptionLevel: currentProfile.subscriptionLevel.toString(),
        });
      }
      return { json, status };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

type ChangeProfileOpts = {
  profileId: string;
  onChange?: () => void;
};
export const changeProfile = createAsyncThunk(
  'account/changeProfile',
  async (
    { profileId, onChange }: ChangeProfileOpts,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const opts = {
        method: 'PUT',
        body: JSON.stringify({ profileId }),
      };

      const response = await makePhoenixRequest('user/profile/current', opts);

      if (!!response) {
        dispatch(removeProfileRelatedResources());

        // NO-1119 - We need to re-fetch the account endpoint, because loop limit
        // and loop count are returned via the account endpoint. Unfortunately, the
        // account endpoint looks at features for the currently active profile when
        // determining the loop limit and count, so this data becomes stale whenever
        // we change profiles. The ideal fix would be to make the account data return
        // a loop limit and count for all profiles, so the UI could determine whether
        // or not to show limit banners based on this data in conjunction with whether
        // or not the user's current profile has the `UNLIMITED_LOOPS` feature.
        //
        // We have to set the cached profile ID before re-fetching account data, since
        // the profile ID in the request header is used to hydrate the user token and
        // we want to get the account data associated with the newly selected profile.
        setCachedProfile(profileId);
        onChange?.();
        await dispatch(fetchAccount());

        return response;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export type AccountState = {
  activeProfileIsChanging: boolean;
  currentProfile: ProfileResource | null;
} & AccountInfo;

export const initialState: AccountState = {
  userId: 0,
  firstName: '',
  lastName: '',
  loopLimit: -1,
  emailVerified: false,
  registrationComplete: true,
  activeProfileIsChanging: false,
  currentProfile: null,
  userFeatures: [],
  isAssumingControl: false,
  individualProfileCount: -1,
  novaOptInPreferences: null,
  userRoleId: 0,
  pendo: undefined,
  nrdsId: '',
};

function normalizeProfile(profile: ProfileResource) {
  return {
    ...profile,
    id: profile.id.toString(),
  };
}

const redirectableStatusCodes = [302, 401, 403, 506];
const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    incrementLoopCount(state) {
      state.loopCount = (state.loopCount ?? 0) + 1;
    },
    registrationComplete(state) {
      state.registrationComplete = true;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAccount.pending, () => {});
    builder.addCase(fetchAccount.fulfilled, (state, { payload }) => {
      if (payload) {
        const json = payload.json;
        const currentProfile = json.currentProfile;

        json.currentProfile = currentProfile
          ? normalizeProfile(currentProfile)
          : null;

        return {
          ...json,
          activeProfileIsChanging: false,
        };
      }
    });
    builder.addCase(fetchAccount.rejected, () => {
      redirectToSignin();
    });

    builder
      .addCase(changeProfile.pending, state => {
        state.activeProfileIsChanging = true;
      })
      .addCase(changeProfile.fulfilled, state => {
        state.activeProfileIsChanging = false;
      })
      .addCase(changeProfile.rejected, state => {
        state.activeProfileIsChanging = false;
      });
  },
});

export const { incrementLoopCount, registrationComplete } =
  accountSlice.actions;
export default accountSlice.reducer;
