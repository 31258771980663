import { ReactNode } from 'react';

export enum NotificationType {
  Info = 'info',
  Success = 'success',
  Error = 'error',
  Offline = 'offline',
  Warning = 'warning',
  Alert = 'alert',
}

export type BaseNotificationMessage = {
  id: string;
  type: NotificationType;
  children: ReactNode;
};

export type NotificationMessage = {
  dismiss: () => void;
} & BaseNotificationMessage;
