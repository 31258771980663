import { Fragment, ReactNode, useContext } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import Portal from '~/components/Portal';
import { usePopover, Position } from '~/hooks/usePopover';
import { boxShadows, colors, spacing, zIndex } from '~/styles';
import useCallbackRef from '~/hooks/useCallbackRef';
import { ModalContext } from '../Modal/context';

const mixins = {
  containerInsideModal: {
    zIndex: zIndex.modalOverlayForeground + 1,
  },

  containerTop: {
    paddingTop: 0,
    paddingBottom: spacing.smaller,
  },

  containerWrapperTop: {
    marginTop: 0,
    marginBottom: spacing.small,
  },
};

const StyledContainerContent = styled.div({
  position: 'relative',
  backgroundColor: colors.white,
});

const StyledContainerShadow = styled.div({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  boxShadow: boxShadows.dropdown,
});

const StyledContainerWrapper = styled.div<{
  $containerWrapperTopEnabled: boolean;
}>(props => ({
  position: 'relative',
  minWidth: '10rem',
  ...(props.$containerWrapperTopEnabled ? mixins.containerWrapperTop : {}),
}));

const StyledContainer = styled(motion.div)<{
  $openModalCount: number;
  $containerTopEnabled: boolean;
}>(props => ({
  position: 'absolute',
  paddingTop: spacing.smaller,
  zIndex:
    props.$openModalCount > 0
      ? zIndex.nestedModalOverlayBackground + props.$openModalCount
      : zIndex.dropdown,
  ...(props.$containerTopEnabled ? mixins.containerTop : {}),
}));

export const DROPDOWN_PORTAL_ID = 'dropdown-portal';

export type BaseDropdownProps = {
  /** Open state of the dropdown menu */
  isOpen: boolean;
  /** Force the menu to be as wide as the parent -- utilized on positioning of menu*/
  fullWidthMenu?: boolean;
  /** Contents of the dropdown menu */
  children: ReactNode;
  /** The default anchor position of the dropdown */
  defaultPosition?: Position;
  /** HTML ID of the element that triggers the opening of this dropdown */
  triggerId: string;
  /** Callback invoked when the popover should close (outside click) */
  onRequestClose: (e: MouseEvent | TouchEvent) => any;
  /**updates menu to display: flex, default is display: block*/
  isDisplayFlex?: boolean;
  /** Disables the use of the portal for the dropdown */
  disregardPortal?: boolean;
};

export function BaseDropdown(props: BaseDropdownProps) {
  const {
    isOpen,
    fullWidthMenu,
    children,
    defaultPosition,
    onRequestClose,
    triggerId,
    isDisplayFlex = false,
    disregardPortal = true,
  } = props;
  const [popoverRef, setPopoverRef] = useCallbackRef<HTMLElement>();
  const { position, positionStyles } = usePopover({
    isOpen,
    triggerId,
    defaultPosition,
    onRequestClose,
    fullWidthMenu,
    popoverRef,
    disregardPortal,
  });
  const isTop = !!position && position.indexOf('top') !== -1;
  const modalContext = useContext(ModalContext);
  const openModalCount = modalContext?.openModalCount ?? 0;

  return (
    <Fragment>
      <Portal id={DROPDOWN_PORTAL_ID}>
        <StyledContainer
          data-open={isOpen}
          ref={setPopoverRef}
          $openModalCount={openModalCount}
          $containerTopEnabled={isTop}
          initial="closed"
          animate={isOpen ? (position ? 'open' : 'calculate') : 'closed'}
          variants={{
            calculate: {
              display: 'block',
              visibility: 'hidden',
              opacity: 0,
            },
            open: {
              display: isDisplayFlex ? 'flex' : 'block',
              visibility: 'visible',
              opacity: 1,
            },
            closed: {
              opacity: 0,
              transitionEnd: { display: 'none' },
            },
          }}
          transition={{ duration: 0.2 }}
          style={positionStyles ?? {}}
          data-testid={DROPDOWN_PORTAL_ID}
        >
          <StyledContainerWrapper $containerWrapperTopEnabled={isTop}>
            <StyledContainerShadow />
            <StyledContainerContent>{children}</StyledContainerContent>
          </StyledContainerWrapper>
        </StyledContainer>
      </Portal>
    </Fragment>
  );
}

export default BaseDropdown;
