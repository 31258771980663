import { Component } from 'react';

import detectNetwork from '~/lib/offline/detectNetwork';
import {
  SnackbarNotification,
  NotificationType,
} from '~/components/Notifications';

type Props = Record<string, unknown>;

type State = {
  showOfflineBanner: boolean;
};
class BrowserOffline extends Component<Props, State> {
  state = {
    showOfflineBanner: false,
  };

  componentDidMount() {
    detectNetwork((isOnline: boolean) => {
      if (isOnline) {
        this.hideOfflineBanner();
      } else {
        this.showOfflineBanner();
      }
    });
  }

  showOfflineBanner = () => {
    this.setState({
      showOfflineBanner: true,
    });
  };

  hideOfflineBanner = () => {
    this.setState({
      showOfflineBanner: false,
    });
  };

  render() {
    const { showOfflineBanner } = this.state;

    return showOfflineBanner ? (
      <SnackbarNotification
        id="browser-offline"
        type={NotificationType.Offline}
        onDismiss={this.hideOfflineBanner}
        dismissTime={0}
      >
        No internet connection
      </SnackbarNotification>
    ) : null;
  }
}

export default BrowserOffline;
