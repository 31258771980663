import { Component, ReactNode } from 'react';
import { NotificationType } from './types';
import NotificationList from './__internal__/NotificationList';
import { NotificationContext, ContextValue } from './context';

export type BaseNotificationMessage = {
  id: string;
  type: NotificationType;
  children: ReactNode;
};
export type NotificationMessage = {
  dismiss: () => void;
} & BaseNotificationMessage;

type Props = {
  children: ReactNode;
};

type State = {
  notifications: Array<NotificationMessage>;
};

class NotificationProvider extends Component<Props, State> {
  state = {
    notifications: [],
  };

  getContextValue(): ContextValue {
    return {
      showNotification: this.showNotification,
      updateNotification: this.updateNotification,
      removeNotification: this.removeNotification,
    };
  }

  showNotification = (message: NotificationMessage) => {
    this.setState(prevState => {
      return {
        notifications: [...prevState.notifications, message],
      };
    });
  };

  updateNotification = (messageToUpdate: BaseNotificationMessage) => {
    this.setState(prevState => ({
      notifications: prevState.notifications.map(message => {
        if (message.id !== messageToUpdate.id) {
          return message;
        } else {
          return {
            ...message,
            ...messageToUpdate,
          };
        }
      }),
    }));
  };

  removeNotification = (messageId: string) => {
    this.setState(prevState => ({
      notifications: prevState.notifications.filter(
        message => message.id !== messageId
      ),
    }));
  };

  render() {
    const contextValue = this.getContextValue();

    return (
      <NotificationContext.Provider value={contextValue}>
        <div data-testid="notification-provider-container">
          <NotificationList notifications={this.state.notifications} />
          {this.props.children}
        </div>
      </NotificationContext.Provider>
    );
  }
}

export default NotificationProvider;
