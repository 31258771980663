import { createSelector } from 'reselect';

import { makeAllResourcesSelector } from '~/store/features/api/selectors';
import { PreferenceNames } from '~/store/features/api/resources/preference/constants';
import {
  PreferenceResource,
  LoopsListPreferenceValue,
  FilterSettingGroupType,
} from '~/store/features/api/resources/preference/types';
import { archivedFilters } from '~/pages/MyLoops/components/LoopFilters/utils';
import { LoopStatus } from '~/store/features/api/resources/loop/constants';
import { ResourceTypes } from '../resources/types';

const preferencesSelector = makeAllResourcesSelector<PreferenceResource>(
  ResourceTypes.Preferences
);

/**
 * This function was written to do an "in place" data migration.
 * There was a time where user's were able to save filter settings
 * that had an old way of saving what was archived, this basically coincided
 * with a loop status id of "6". We changed archive of a loop to no longer be a loop status
 * but a property directly on a loop. With that change, we also changed how the filter settings were structured
 * to coincide with that business logic change. In lieu of doing a db migration, we decided to do an "in place"
 * migration in the UI to map from the old way to the new way. This logic won't be triggered unless "archivedFilter" is missing
 * in the filter setting group.
 *
 */
function mutateFilter(filter: LoopsListPreferenceValue) {
  if (!filter.archivedFilter) {
    let statusIds = filter.statusIds;

    if (statusIds) {
      const archivedIndex = statusIds.indexOf(LoopStatus.Archived);

      if (archivedIndex !== -1) {
        if (statusIds.length === 1) {
          filter.archivedFilter = archivedFilters.onlyArchived.value;
        } else {
          filter.archivedFilter = archivedFilters.all.value;
        }

        statusIds = statusIds.filter(
          statusId => statusId !== LoopStatus.Archived
        );

        filter.statusIds = statusIds;
      } else {
        filter.archivedFilter = archivedFilters.hideArchived.value;
      }
    }
  }

  return filter;
}

export function makePreferenceSelector(preferenceName: string) {
  return createSelector(preferencesSelector, preferences => {
    const preferencesById = preferences.filter(p => p.id === preferenceName);
    let result: PreferenceResource | undefined;

    if (preferencesById.length > 1) {
      console.warn(
        `You have multiple preferences for ${preferenceName}. That shouldn't happen`
      );
    }

    if (preferencesById.length === 1) {
      result = preferencesById[0];
    }

    if (
      result &&
      result.value &&
      preferenceName === PreferenceNames.LoopFilterSettings
    ) {
      let newValue = { ...result.value };
      newValue = mutateFilter(newValue);

      if (newValue.filterSettingGroups) {
        newValue.filterSettingGroups = newValue.filterSettingGroups.map(
          (filterSettingGroup: FilterSettingGroupType) =>
            mutateFilter({ ...filterSettingGroup })
        );
      }

      return {
        ...result,
        value: newValue,
      };
    }
    return result;
  });
}
