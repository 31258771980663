import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';

import { ResourceDefinition, ResourceTypes } from '../types';

const DocumentField: ResourceDefinition = {
  attributes: {
    pageNumber: ATTRIBUTE_TYPE.NUMBER,
    fieldType: ATTRIBUTE_TYPE.STRING,
    x: ATTRIBUTE_TYPE.NUMBER,
    y: ATTRIBUTE_TYPE.NUMBER,
    height: ATTRIBUTE_TYPE.NUMBER,
    width: ATTRIBUTE_TYPE.NUMBER,
    value: ATTRIBUTE_TYPE.STRING,
    fontFace: ATTRIBUTE_TYPE.STRING,
    fontStyle: ATTRIBUTE_TYPE.STRING,
    horizontalAlign: ATTRIBUTE_TYPE.STRING,
    verticalAlign: ATTRIBUTE_TYPE.STRING,
    fontColor: ATTRIBUTE_TYPE.STRING,
    backgroundColor: ATTRIBUTE_TYPE.STRING,
    fontSize: ATTRIBUTE_TYPE.NUMBER,
    transparent: ATTRIBUTE_TYPE.BOOLEAN,
    groupNumber: ATTRIBUTE_TYPE.NUMBER,
    signatureGroupNumber: ATTRIBUTE_TYPE.NUMBER,
    tabIndex: ATTRIBUTE_TYPE.NUMBER,
    fieldSigned: ATTRIBUTE_TYPE.BOOLEAN,
    fieldCleared: ATTRIBUTE_TYPE.BOOLEAN,
    readOnly: ATTRIBUTE_TYPE.BOOLEAN,
    assigneeParticipantId: ATTRIBUTE_TYPE.NUMBER,
    assigneeRoleId: ATTRIBUTE_TYPE.NUMBER,
    signedDate: ATTRIBUTE_TYPE.DATE,
    signedName: ATTRIBUTE_TYPE.STRING,
    signedHash: ATTRIBUTE_TYPE.STRING,
    signatureUrl: ATTRIBUTE_TYPE.STRING,
    s3ImageUrl: ATTRIBUTE_TYPE.STRING,
    verifiedBrand: ATTRIBUTE_TYPE.STRING,
  },
  relationships: {
    loop: {
      resourceType: ResourceTypes.Loops,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
  },
};

export default DocumentField;
