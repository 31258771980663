import { Fragment, ReactNode } from 'react';
import styled from 'styled-components';
import {
  breakpoints,
  colors,
  momentumScrollingStyles,
  spacing,
} from '~/styles';
import ModalActions, { ModalAction } from './ModalActions';
import { AutoFocusInside } from 'react-focus-lock';

type BodyOffset = {
  marginLeft: string;
  marginRight: string;
  paddingLeft: string;
  paddingRight: string;
};

const StyledModalActions = styled.div<{
  $mobileBodyOffset: BodyOffset;
  $desktopBodyOffset: BodyOffset;
}>(props => ({
  flex: '0 0 auto',
  paddingTop: spacing.large,
  ...props.$mobileBodyOffset,

  [breakpoints.MEDIUM]: {
    ...props.$desktopBodyOffset,
  },
}));

const StyledAutoFocusInside = styled(AutoFocusInside)<{
  $modalContentEnableScrollingEnabled: boolean;
  $mobileBodyOffset: BodyOffset;
  $desktopBodyOffset: BodyOffset;
}>(props => ({
  position: 'relative',
  flex: '1 1 auto',
  ...props.$mobileBodyOffset,

  [breakpoints.MEDIUM]: {
    ...props.$desktopBodyOffset,
  },

  ...(props.$modalContentEnableScrollingEnabled
    ? {
        ...momentumScrollingStyles,
        borderTop: `1px solid ${colors.lightBorder}`,
        borderBottom: `1px solid ${colors.lightBorder}`,
        paddingTop: spacing.normal,
        paddingBottom: spacing.normal,
      }
    : {}),
}));

const StyledModalBody = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  marginTop: spacing.medium,
  minHeight: 0,
});

type Props = {
  large: boolean;
  enableScroll: boolean;
  isNegative?: boolean;
  children?: ReactNode;
  formName?: string;
  primaryAction?: ModalAction;
  secondaryAction?: ModalAction;
  cancelAction?: () => void;
  hideCancel?: boolean;
  hideFooter?: boolean;
};

export function ModalBody(props: Props) {
  const {
    large,
    children,
    formName,
    isNegative,
    primaryAction,
    secondaryAction,
    cancelAction,
    hideCancel,
    enableScroll,
    hideFooter,
  } = props;
  const modalBodyOffset = {
    marginLeft: `-${spacing.large}`,
    marginRight: `-${spacing.large}`,
    paddingLeft: spacing.large,
    paddingRight: spacing.large,
  };

  return (
    <Fragment>
      <StyledModalBody>
        <StyledAutoFocusInside
          $modalContentEnableScrollingEnabled={enableScroll}
          $mobileBodyOffset={modalBodyOffset}
          $desktopBodyOffset={modalBodyOffset}
        >
          {children}
        </StyledAutoFocusInside>
      </StyledModalBody>
      {!hideFooter && (
        <StyledModalActions
          $mobileBodyOffset={modalBodyOffset}
          $desktopBodyOffset={modalBodyOffset}
        >
          <ModalActions
            large={large}
            formName={formName}
            isNegative={isNegative}
            primaryAction={primaryAction}
            cancelAction={cancelAction}
            hideCancel={hideCancel}
            secondaryAction={secondaryAction}
          />
        </StyledModalActions>
      )}
    </Fragment>
  );
}

ModalBody.defaultProps = {
  large: true,
  isNegative: false,
};

export default ModalBody;
