import { Fragment } from 'react';
import styled from 'styled-components';

import LoadingMoreIndicator from '~/components/LoadingMoreIndicator';
import { CheckmarkIcon } from '~/components/icons';
import Highlight from '~/components/Highlight';
import { FormattedMessage } from '~/components/i18n';
import {
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  momentumScrollingStyles,
  spacing,
} from '~/styles';

import { Avatar } from '~/components/Avatar';
import {
  VALID_PHONE_REGEX,
  ExistingRecipient,
  RecipientType,
  RecipientRoutingType,
} from './RecipientMultiSelect';
import { getRecipientName } from './utils';

import { ContactResource } from '~/store/features/api/resources/contact/types';
import { Recipient } from './RecipientMultiSelect';
import { GetItemPropsOptions } from 'downshift';

const mixins = {
  selectable: {
    cursor: 'pointer',
  },

  expandedContactListItemHighlighted: {
    backgroundColor: colors.menuItemHighlight,
  },

  recipientHighlighted: {
    outline: 'none',
    backgroundColor: colors.menuItemHighlight,
  },

  recipientContainerExpanded: {
    alignItems: 'center',
  },
};

const StyledExpandedContactList = styled.ul({
  margin: 0,
  padding: 0,
});

const StyledRecipientSecondaryDetail = styled.div({
  color: colors.secondaryText,
  lineHeight: '1.2rem',
});

const StyledRecipientName = styled.div({
  fontWeight: fontWeights.semiBold,
});

const StyledRecipientDetails = styled.div({
  flex: '1 0 auto',
});

const StyledParticipantSelectedIcon = styled.div({
  width: spacing.max,
  height: spacing.max,
  borderRadius: '50%',
  backgroundColor: colors.hintText,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledParticipantAvatar = styled.div({
  flex: '0 0 auto',
  width: spacing.max,
  height: spacing.max,
  marginRight: spacing.normal,
});

const StyledRecipientContainer = styled.div<{
  $recipientContainerExpandedEnabled: boolean;
}>(props => ({
  display: 'flex',
  borderLeft: '2px solid transparent',
  ...(props.$recipientContainerExpandedEnabled
    ? mixins.recipientContainerExpanded
    : {}),
}));

const StyledRecipient = styled.li<{
  $selectableEnabled: boolean;
  recipientHighlightedEnabled: boolean;
}>(props => ({
  margin: 0,
  padding: spacing.normal,
  ...(props.$selectableEnabled ? mixins.selectable : {}),
  ...(props.recipientHighlightedEnabled ? mixins.recipientHighlighted : {}),
}));

const StyledRecipientsContainer = styled.ul({
  margin: 0,
  padding: 0,
});

const StyledContactLetterHeader = styled.div({
  ...fontSizes.headline,
  fontWeight: fontWeights.semiBold,
  padding: `${spacing.normal} 2rem`,
});

const StyledContactsByLetterListItem = styled.li({
  margin: 0,

  '&:first-of-type > div:first-of-type': {
    marginTop: `-${spacing.normal}`,
  },
});

const StyledResultsContainer = styled.ul({
  ...momentumScrollingStyles,
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  margin: 0,
  padding: `${spacing.normal} 0`,
  backgroundColor: colors.white,
  marginTop: '1px',

  [breakpoints.MEDIUM]: {
    marginTop: '0.5px',
  },
});

const StyledExpandedContactListItem = styled.li<{
  $selectableEnabled: boolean;
  $expandedContactListItemHighlightedEnabled: boolean;
}>(props => ({
  padding: `${spacing.normal} ${spacing.normal} ${spacing.normal} calc(${spacing.max} + (${spacing.normal}) * 2)`,
  ...(props.$selectableEnabled ? mixins.selectable : {}),
  ...(props.$expandedContactListItemHighlightedEnabled
    ? mixins.expandedContactListItemHighlighted
    : {}),
}));

function isValidPhoneNumber(value: string) {
  return !!value && VALID_PHONE_REGEX.test(value);
}

type ListItemProps = {
  recipient: ContactResource;
  getItemProps: (
    props: GetItemPropsOptions<ContactResource | ExistingRecipient>
  ) => any;
  index: number;
  highlightedIndex: number | null;
  value: string;
  valueType: RecipientRoutingType;
};

function RecipientContactMethodListItem({
  recipient,
  value,
  valueType,
  index,
  highlightedIndex,
  getItemProps,
}: ListItemProps) {
  return (
    <StyledExpandedContactListItem
      $selectableEnabled
      $expandedContactListItemHighlightedEnabled={index === highlightedIndex}
      {...getItemProps({
        item: {
          ...recipient,
          selectedRoute: value,
          selectedRoutingType: valueType,
        },
        index,
      })}
    >
      {valueType === 'email' ? (
        value
      ) : (
        <Fragment>
          <FormattedMessage
            id={`messaging.recipientContactMethod.${valueType}`}
          />
          &nbsp;
          {value}
        </Fragment>
      )}
    </StyledExpandedContactListItem>
  );
}

function RecipientEmailListItem(props: Omit<ListItemProps, 'valueType'>) {
  return (
    <RecipientContactMethodListItem
      {...props}
      valueType={RecipientRoutingType.Email}
    />
  );
}

function RecipientPhoneNumberListItem(props: ListItemProps) {
  return <RecipientContactMethodListItem {...props} />;
}

type Props = {
  topOffset: string;
  isLoading: boolean;
  potentialRecipients: Array<ContactResource>;
  expandedPotentialRecipient: ContactResource | null;
  selectedRecipients: Array<Recipient>;
  searchTerm: string;
  highlightedIndex: number | null;
  getItemProps: (
    props: GetItemPropsOptions<ContactResource | ExistingRecipient>
  ) => any;
};

type RecipientByFirstName = { [letter: string]: Array<ContactResource> };

export function RecipientResultList({
  topOffset,
  isLoading,
  potentialRecipients,
  expandedPotentialRecipient,
  selectedRecipients,
  getItemProps,
  highlightedIndex,
  searchTerm,
}: Props) {
  const showLoadingIndicator = isLoading && potentialRecipients.length === 0;
  const recipientsByFirstName: RecipientByFirstName =
    potentialRecipients.reduce((result: RecipientByFirstName, recipient) => {
      const firstName = recipient.firstName;
      const firstLetter = !!firstName ? firstName.charAt(0).toUpperCase() : '';

      if (!result[firstLetter]) {
        result[firstLetter] = [];
      }

      result[firstLetter].push(recipient);

      return result;
    }, {});
  let itemIndex = 0;

  return (
    <StyledResultsContainer style={{ top: topOffset }}>
      {showLoadingIndicator ? (
        <div>
          <LoadingMoreIndicator />
        </div>
      ) : (
        Object.keys(recipientsByFirstName).map((firstLetter: string) => {
          const recipientsForLetter = recipientsByFirstName[firstLetter];

          return (
            <StyledContactsByLetterListItem key={firstLetter}>
              <StyledContactLetterHeader>
                {firstLetter.toUpperCase()}
              </StyledContactLetterHeader>
              <StyledRecipientsContainer>
                {recipientsForLetter.map(recipient => {
                  const isExpanded =
                    !!expandedPotentialRecipient &&
                    expandedPotentialRecipient.id === recipient.id;
                  const selectedRecipient = selectedRecipients.find(
                    r => 'id' in r && r.id === recipient.id
                  );
                  const isSelected = !!selectedRecipient;
                  const recipientIndex = itemIndex++;

                  return (
                    <Fragment key={recipientIndex}>
                      <StyledRecipient
                        $selectableEnabled
                        recipientHighlightedEnabled={
                          recipientIndex === highlightedIndex
                        }
                        {...getItemProps({
                          item: recipient,
                          index: recipientIndex,
                        })}
                      >
                        <StyledRecipientContainer
                          $recipientContainerExpandedEnabled={isExpanded}
                        >
                          <StyledParticipantAvatar>
                            {isSelected ? (
                              <StyledParticipantSelectedIcon>
                                <CheckmarkIcon width="1.5rem" type="white" />
                              </StyledParticipantSelectedIcon>
                            ) : (
                              <Avatar participant={recipient} />
                            )}
                          </StyledParticipantAvatar>
                          <StyledRecipientDetails>
                            <StyledRecipientName>
                              <Highlight
                                sourceText={getRecipientName(recipient)}
                                findText={searchTerm}
                              />
                            </StyledRecipientName>
                            {!!selectedRecipient &&
                              selectedRecipient.type ===
                                RecipientType.Existing && (
                                <StyledRecipientSecondaryDetail>
                                  {selectedRecipient.selectedRoutingType ===
                                  RecipientRoutingType.Email ? (
                                    selectedRecipient.selectedRoute
                                  ) : (
                                    <Fragment>
                                      <FormattedMessage
                                        id={`messaging.recipientContactMethod.${selectedRecipient.selectedRoutingType}`}
                                      />
                                      &nbsp;
                                      {selectedRecipient.selectedRoute}
                                    </Fragment>
                                  )}
                                </StyledRecipientSecondaryDetail>
                              )}
                            {!isExpanded && !isSelected && (
                              <Fragment>
                                <StyledRecipientSecondaryDetail>
                                  <Highlight
                                    sourceText={recipient.emailAddress}
                                    findText={searchTerm}
                                  />
                                </StyledRecipientSecondaryDetail>
                                {isValidPhoneNumber(recipient.phoneNumber) && (
                                  <StyledRecipientSecondaryDetail>
                                    <FormattedMessage
                                      id={`messaging.recipientContactMethod.phone`}
                                    />
                                    &nbsp;
                                    <Highlight
                                      sourceText={recipient.phoneNumber}
                                      findText={searchTerm}
                                    />
                                  </StyledRecipientSecondaryDetail>
                                )}
                                {isValidPhoneNumber(recipient.mobileNumber) && (
                                  <StyledRecipientSecondaryDetail>
                                    <FormattedMessage
                                      id={`messaging.recipientContactMethod.mobile`}
                                    />
                                    &nbsp;
                                    <Highlight
                                      sourceText={recipient.mobileNumber}
                                      findText={searchTerm}
                                    />
                                  </StyledRecipientSecondaryDetail>
                                )}
                                {isValidPhoneNumber(recipient.officeNumber) && (
                                  <StyledRecipientSecondaryDetail>
                                    <FormattedMessage
                                      id={`messaging.recipientContactMethod.office`}
                                    />
                                    &nbsp;
                                    <Highlight
                                      sourceText={recipient.officeNumber}
                                      findText={searchTerm}
                                    />
                                  </StyledRecipientSecondaryDetail>
                                )}
                              </Fragment>
                            )}
                          </StyledRecipientDetails>
                        </StyledRecipientContainer>
                      </StyledRecipient>
                      {isExpanded && (
                        <li>
                          <StyledExpandedContactList>
                            <RecipientEmailListItem
                              recipient={recipient}
                              getItemProps={getItemProps}
                              index={itemIndex++}
                              highlightedIndex={highlightedIndex}
                              value={recipient.emailAddress}
                            />
                            {isValidPhoneNumber(recipient.phoneNumber) && (
                              <RecipientPhoneNumberListItem
                                recipient={recipient}
                                getItemProps={getItemProps}
                                index={itemIndex++}
                                highlightedIndex={highlightedIndex}
                                value={recipient.phoneNumber}
                                valueType={RecipientRoutingType.Phone}
                              />
                            )}
                            {isValidPhoneNumber(recipient.mobileNumber) && (
                              <RecipientPhoneNumberListItem
                                recipient={recipient}
                                getItemProps={getItemProps}
                                index={itemIndex++}
                                highlightedIndex={highlightedIndex}
                                value={recipient.mobileNumber}
                                valueType={RecipientRoutingType.Mobile}
                              />
                            )}
                            {isValidPhoneNumber(recipient.officeNumber) && (
                              <RecipientPhoneNumberListItem
                                recipient={recipient}
                                getItemProps={getItemProps}
                                index={itemIndex++}
                                highlightedIndex={highlightedIndex}
                                value={recipient.officeNumber}
                                valueType={RecipientRoutingType.Office}
                              />
                            )}
                          </StyledExpandedContactList>
                        </li>
                      )}
                    </Fragment>
                  );
                })}
              </StyledRecipientsContainer>
            </StyledContactsByLetterListItem>
          );
        })
      )}
    </StyledResultsContainer>
  );
}

export default RecipientResultList;
