export function generateSearchOptions(
  searchValue: string,
  additionalOptions: any = {}
) {
  const searchOptions = !!searchValue
    ? { 'filter[search]': searchValue }
    : null;
  return {
    additionalOptions: { ...additionalOptions, ...searchOptions },
  };
}
