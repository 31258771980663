import APP_CONFIG from '../../config';
import { makeJSONAPIRequest } from './network';
import {
  JsonApiFetchOptions,
  ApiCreateResource,
  ApiResponse,
  ApiResource,
  ResourceIdentifier,
  ServerResponse,
  JsonApiCreateRequestBody,
  JsonApiSuccessResponse,
} from './types';

import { pluralize } from 'inflection';

const {
  api: { baseUrl: API_URL, baseSearchUrl: BASE_SEARCH_API_URL },
} = APP_CONFIG;
export const RELATIONSHIPS_NAMESPACE = 'relationships';

function getBaseUrl(options?: JsonApiFetchOptions) {
  if (options && options.useSearch) {
    return BASE_SEARCH_API_URL;
  }

  return API_URL;
}

export async function apiFetchAll(
  resourceName: string,
  options?: JsonApiFetchOptions
): Promise<ApiResponse> {
  const baseUrl = getBaseUrl(options);
  const url = [baseUrl, pluralize(resourceName)].join('/');
  const urlWithQueryString = generateUrlWithQueryString(url, options);
  const response = await makeJSONAPIRequest(urlWithQueryString);

  return flattenResponse(response);
}

export async function apiFetch(
  resourceName: string,
  id: string,
  options?: JsonApiFetchOptions
): Promise<ApiResponse> {
  const baseUrl = getBaseUrl(options);
  const url = [baseUrl, pluralize(resourceName), id].join('/');
  const urlWithQueryString = generateUrlWithQueryString(url, options);
  const response = await makeJSONAPIRequest(urlWithQueryString);

  return flattenResponse(response);
}

export async function apiFetchRelationship(
  resourceName: string,
  id: string,
  relationshipName: string,
  options?: JsonApiFetchOptions
): Promise<ApiResponse> {
  const baseUrl = getBaseUrl(options);
  const url = [baseUrl, pluralize(resourceName), id, relationshipName].join(
    '/'
  );
  const urlWithQueryString = generateUrlWithQueryString(url, options);
  const response = await makeJSONAPIRequest(urlWithQueryString);
  return flattenResponse(response);
}

export async function apiCreate(
  resource: ApiCreateResource,
  includedResources: Array<ApiResource | ResourceIdentifier> = [],
  meta?: Record<string, unknown>,
  apiVersion?: string
): Promise<ApiResponse> {
  const { type: resourceName } = resource;
  const baseUrl = [API_URL, pluralize(resourceName)].join('/');
  const url = generateUrlWithQueryString(baseUrl, { apiVersion }, meta);
  const body: JsonApiCreateRequestBody = { data: resource };

  if (includedResources.length > 0) {
    body.included = includedResources;
  }

  const response = await makeJSONAPIRequest(url, {
    method: 'POST',
    body: JSON.stringify(body),
  });

  return flattenResponse(response);
}

export async function apiUpdate(
  resource: ApiResource,
  meta?: Record<string, unknown>,
  apiVersion?: string
): Promise<ApiResponse> {
  const { id } = resource;
  let { type: resourceName } = resource;
  resourceName = pluralize(resourceName);
  const baseUrl = [API_URL, resourceName, id].join('/');
  const url = generateUrlWithQueryString(baseUrl, { apiVersion }, meta);
  const response = await makeJSONAPIRequest(url, {
    method: 'PATCH',
    body: JSON.stringify({ data: resource }),
  });

  return flattenResponse(response);
}

export async function apiDelete(
  resourceName: string,
  resourceId: string,
  meta?: Record<string, unknown>
): Promise<ApiResponse> {
  const baseUrl = [API_URL, pluralize(resourceName), resourceId].join('/');
  const url = generateUrlWithQueryString(baseUrl, {}, meta);
  const response = await makeJSONAPIRequest(url, {
    method: 'DELETE',
  });

  return flattenResponse(response);
}

export async function apiDeleteRelationship(
  resourceName: string,
  resourceId: string,
  relationshipName: string,
  relationshipData: ResourceIdentifier | Array<ResourceIdentifier>
): Promise<ApiResponse> {
  const url = [
    API_URL,
    pluralize(resourceName),
    resourceId,
    RELATIONSHIPS_NAMESPACE,
    relationshipName,
  ].join('/');
  const response = await makeJSONAPIRequest(url, {
    method: 'DELETE',
    body: JSON.stringify({ data: relationshipData }),
  });

  return flattenResponse(response);
}

export async function apiUpdateRelationship(
  resourceName: string,
  resourceId: string,
  relationshipName: string,
  relationshipData: ResourceIdentifier | Array<ResourceIdentifier>
): Promise<ApiResponse> {
  const url = [
    API_URL,
    pluralize(resourceName),
    resourceId,
    RELATIONSHIPS_NAMESPACE,
    relationshipName,
  ].join('/');
  const response = await makeJSONAPIRequest(url, {
    method: 'PATCH',
    body: JSON.stringify({ data: relationshipData }),
  });

  return flattenResponse(response);
}

export async function apiCreateRelationship(
  resourceName: string,
  resourceId: string,
  relationshipName: string,
  relationshipData: ResourceIdentifier | Array<ResourceIdentifier>,
  meta?: Record<string, unknown>,
  fetchOptions?: JsonApiFetchOptions
): Promise<ApiResponse> {
  resourceName = pluralize(resourceName);
  const baseUrl = [
    API_URL,
    pluralize(resourceName),
    resourceId,
    RELATIONSHIPS_NAMESPACE,
    relationshipName,
  ].join('/');
  const url = generateUrlWithQueryString(baseUrl, fetchOptions, meta);
  const response = await makeJSONAPIRequest(url, {
    method: 'POST',
    body: JSON.stringify({ data: relationshipData }),
  });

  return flattenResponse(response);
}

export function generateUrlWithQueryString(
  baseUrl: string,
  fetchOptions?: JsonApiFetchOptions,
  meta?: Record<string, unknown>
) {
  if (!!fetchOptions && !!fetchOptions.apiVersion) {
    baseUrl = baseUrl.replace('v1_0', fetchOptions.apiVersion);
  }
  const queryString = generateQueryString(fetchOptions, meta);
  return !!queryString ? `${baseUrl}?${queryString}` : baseUrl;
}

export function generateQueryString(
  fetchOptions?: JsonApiFetchOptions,
  meta?: Record<string, unknown>
) {
  const queryParamsFromOptions =
    generateQueryParamsFromFetchOptions(fetchOptions);
  const queryParamsFromMeta = generateQueryParamsFromMeta(meta);
  const queryParams = queryParamsFromOptions.concat(queryParamsFromMeta);

  return queryParams
    .map(p => encodeURIComponent(p))
    .join('&')
    .replaceAll('%3D', '=');
}

type Value = string | number | boolean | null | undefined;
function getQueryStringValue(
  value: Value | Value[]
): string | null | undefined {
  if (Array.isArray(value)) {
    return [...value].sort().map(getQueryStringValue).join(',');
  }

  if (value === undefined || value === null) {
    return value;
  }

  return value.toString();
}

type Meta = { [key: string]: any };
function generateQueryParamsFromMeta(meta?: Meta) {
  let queryParams: Array<string> = [];

  if (meta) {
    const initialParams: Array<string> = [];
    queryParams = Object.keys(meta).reduce((params, key) => {
      if (meta) {
        const value = meta[key];

        if (!!value) {
          const encodedValue = getQueryStringValue(value);

          params.push(`meta[${key}]=${encodedValue}`);
        }
      }

      return params;
    }, initialParams);
  }

  return queryParams;
}

function generateQueryParamsFromFetchOptions(
  fetchOptions?: JsonApiFetchOptions
): Array<string> {
  const queryParams = [];

  if (fetchOptions) {
    const { include, page, fields, filters, sort, additionalOptions } =
      fetchOptions;

    // Paging MUST come first so that sorting by page number is simple in our selectors
    if (page) {
      queryParams.push(`page[size]=${page.size}`);

      if ('number' in page && page.number !== undefined) {
        queryParams.push(`page[num]=${page.number}`);
      } else if ('cursor' in page && page.cursor !== undefined) {
        queryParams.push(`page[cursor]=${page.cursor}`);
      }
    }

    if (include && include.length > 0) {
      const includes = include.sort().join(',');
      queryParams.push(`include=${includes}`);
    }

    if (fields) {
      Object.keys(fields)
        .sort()
        .forEach(resourceName => {
          const fieldQuery = fields[resourceName].join(',');
          queryParams.push(`fields[${resourceName}]=${fieldQuery}`);
        });
    }

    if (filters) {
      Object.keys(filters)
        .sort()
        .forEach(key => {
          const filterValue = filters[key];
          const filter = getQueryStringValue(filterValue);

          if (!!filter) {
            queryParams.push(`filter[${key}]=${filter}`);
          }
        });
    }

    if (sort) {
      const sortKeys = Object.keys(sort).sort();

      if (sortKeys && sortKeys.length > 0) {
        // Sort options should be comma separated. If a sort key is in descending order, it is prefixed with a 'minus' sign
        const sortQuery = sortKeys
          .map(key => (sort[key] === 'asc' ? key : `-${key}`))
          .join(',');
        queryParams.push(`sort=${sortQuery}`);
      }
    }

    if (additionalOptions) {
      Object.keys(additionalOptions).forEach(key => {
        const value = additionalOptions[key];

        if (!!value) {
          queryParams.push(`${key}=${value}`);
        }
      });
    }
  }

  return queryParams;
}

function flattenResponse(response: ServerResponse): ApiResponse {
  if ('data' in response) {
    return flattenJsonApiResponse(response);
  } else if ('errors' in response) {
    return {
      type: 'error',
      data: response.errors,
    };
  } else if (response.status && response.status >= 300) {
    return {
      type: 'error',
      data: [{ id: 'UNKNOWN_SERVER_ERROR', title: response.error }],
    };
  } else if (!response.status && !!response.error) {
    return {
      type: 'error',
      data: [{ id: 'UNKNOWN_ERROR', title: response.error }],
    };
  } else {
    return {
      type: 'success',
      data: [],
    };
  }
}

function flattenJsonApiResponse(response: JsonApiSuccessResponse): ApiResponse {
  let data = response.data;
  const included = response.included || [];
  const totalResourceCount = response.meta ? response.meta.paging.total : null;

  if (data !== null && !Array.isArray(data)) {
    data = [data];
  } else if (data === null) {
    data = [];
  }

  return {
    type: 'success',
    data: data.concat(included),
    meta: { totalResourceCount },
  };
}
