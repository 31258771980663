export enum PreferenceNames {
  DisableBlankLoopCreation = 'DISABLE_BLANK_LOOP_CREATION',
  LoopFilterSettings = 'LOOP_FILTER_SETTINGS',
  NewlyAddedProfilesByAdmin = 'NEW_PROFILE_LIST',
  LoopViewTypeSetting = 'LOOP_VIEW_TYPE_SETTING',
  NovaLanguage = 'NOVA_LANGUAGE',
  HideSupportBanner = 'HIDE_SUPPORT_INTRO_BANNER',
  PopupDisplayPreferences = 'POPUP_DISPLAY_PREFERENCES',
  NovaOptInLoopDetails = 'NOVA_OPT_IN_LOOP_DETAILS',
  AllowAgentsArchiveRequiredDocs = 'ALLOW_AGENTS_ARCHIVE_REQUIRED_DOCS',
  TaskSorting = 'TASK_SORTING',
  TaskFilters = 'TASK_FILTER',
  TaskListStatus = 'TASK_LIST_STATUS',
}

export const loopFilterSettingsValueTypes = [
  'sortId',
  'sortDirection',
  'transactionTypeIds',
  'statusIds',
  'complianceStatusIds',
  'tags',
  'archivedFilter',
  'unsubmitted',
];
