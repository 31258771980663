import i18next, { i18n } from 'i18next';
import format from 'date-fns/format';

import APP_CONFIG from '~/config';
import { DateOptions, CurrencyOptions } from './types';
import { parse } from 'date-fns';

const {
  ui: { isTestBuild, isProductionBuild },
} = APP_CONFIG;

export function formatCurrency(
  value: number | string,
  currency: string,
  options: CurrencyOptions | null | undefined,
  i18n: i18n
): string {
  const fallbackLng = i18n.options.fallbackLng;
  let language: string = Array.isArray(fallbackLng) ? fallbackLng[0] : '';
  let hidingDecimal = false;
  const intlOptions: any = {
    style: 'currency',
    currency,
  };

  if (!!i18next.languages && i18next.languages.length > 0) {
    language = i18next.languages[0];
  }

  if (options && options.hideDecimal) {
    hidingDecimal = true;
    intlOptions.minimumFractionDigits = 0;
    intlOptions.maximumFractionDigits = 0;
  }

  const numberValue = typeof value === 'number' ? value : parseFloat(value);

  try {
    const currencyFormatter = new Intl.NumberFormat(language, intlOptions);

    if (!isNaN(numberValue)) {
      return currencyFormatter.format(numberValue);
    } else {
      return numberValue.toString();
    }
  } catch (e) {
    const fixedPlaces = hidingDecimal ? 0 : 2;

    return `$${numberValue.toFixed(fixedPlaces)}`;
  }
}

const DATE_FORMAT_OPTIONS: DateOptions = {
  DEFAULT: { year: 'numeric', month: '2-digit', day: '2-digit' },
  DEFAULT_DOT: { year: 'numeric', month: '2-digit', day: '2-digit' },
  DEFAULT_WITH_TIME: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  },
  SHORT_YEAR: { year: '2-digit', month: '2-digit', day: '2-digit' },
  SHORT_YEAR_DOT: { year: '2-digit', month: '2-digit', day: '2-digit' },
  MONTH_YEAR: { year: 'numeric', month: 'long' },
  MONTH_DAY: { month: 'short', day: 'numeric' },
  MONTH_DAY_YEAR: { month: 'short', day: 'numeric', year: 'numeric' },
  EXPANDED: { year: 'numeric', month: 'long', day: '2-digit' },
  DAY: { weekday: 'long' },
  TIME: { hour: 'numeric', minute: 'numeric' },
  SHORT_DATE_TIME: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  LONG_DATE_TIME: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  },
};

type DateFormatConstant = { [key: string]: keyof DateOptions };
export const DATE_FORMATS: DateFormatConstant = {
  DEFAULT: 'DEFAULT',
  DEFAULT_DOT: 'DEFAULT_DOT',
  DEFAULT_WITH_TIME: 'DEFAULT_WITH_TIME',
  SHORT_YEAR: 'SHORT_YEAR',
  SHORT_YEAR_DOT: 'SHORT_YEAR_DOT',
  MONTH_YEAR: 'MONTH_YEAR',
  MONTH_DAY: 'MONTH_DAY',
  MONTH_DAY_YEAR: 'MONTH_DAY_YEAR',
  EXPANDED: 'EXPANDED',
  DAY: 'DAY',
  TIME: 'TIME',
  SHORT_DATE_TIME: 'SHORT_DATE_TIME',
  LONG_DATE_TIME: 'LONG_DATE_TIME',
};

const currentYear = new Date().getFullYear();

export function formatDate(
  date: Date,
  dateFormat: keyof DateOptions,
  i18next: i18n
): string {
  let options = DATE_FORMAT_OPTIONS[dateFormat];
  const fallbackLng = i18next.options.fallbackLng;

  let language = Array.isArray(fallbackLng) ? fallbackLng[0] : '';

  if (!!i18next.languages && i18next.languages.length > 0) {
    language = i18next.languages[0];
  }

  try {
    const dateToFormat = typeof date === 'string' ? parse(date) : date;

    //Don't display year for SHORT_DATE_TIME if it's the current year
    if (
      dateFormat === DATE_FORMATS.SHORT_DATE_TIME &&
      dateToFormat.getFullYear() === currentYear
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { year, ...newOptions } = options;
      options = newOptions;
    }

    const dateFormatter = new Intl.DateTimeFormat(language, options);
    let result = dateFormatter.format(dateToFormat);

    if (
      dateFormat === DATE_FORMATS.DEFAULT_DOT ||
      dateFormat === DATE_FORMATS.SHORT_YEAR_DOT
    ) {
      result = result.replace(/\//g, '.');
    }

    return result;
  } catch (e) {
    if (!isProductionBuild && !isTestBuild) {
      console.warn('Error formatting date', e);
    }
    // Issue with browser supporting Intl.DateTimeFormat constructor
    return format(date, 'MM/DD/YYYY');
  }
}
