import { ReactNode } from 'react';
import styled from 'styled-components';
import { breakpoints, screenSizes, spacing } from '~/styles';
import { createSafeInsetPadding } from '~/styles/utilities';

export const PADDING_XSM = spacing.normal;
export const PADDING_SM = PADDING_XSM;
export const PADDING_MD = PADDING_SM;
export const PADDING_LG = spacing.medium;
export const PADDING_XLG = PADDING_LG;
export const PADDING_XXLG = PADDING_XLG;
const mixins = {
  gridContainerTopOffset: {
    [breakpoints.MEDIUM]: {
      marginTop: `-${PADDING_MD}`,
    },

    [breakpoints.LARGE]: {
      marginTop: `-${PADDING_LG}`,
    },

    [breakpoints.XLARGE]: {
      marginTop: `-${PADDING_XLG}`,
    },

    [breakpoints.XXLARGE]: {
      marginTop: `-${PADDING_XXLG}`,
    },
  },
};

const StyledGridContainer = styled.div({
  marginTop: '-1px',
  paddingTop: '1px',

  ...createSafeInsetPadding(
    {
      left: PADDING_XSM,
      right: PADDING_XSM,
    },
    {
      left: true,
      right: true,
    }
  ),

  width: '100%',
  WebkitBackfaceVisibility: 'hidden',

  [breakpoints.SMALL]: {
    ...createSafeInsetPadding(
      {
        left: PADDING_SM,
        right: PADDING_SM,
      },
      {
        left: true,
        right: true,
      }
    ),
  },

  [breakpoints.MEDIUM]: {
    ...createSafeInsetPadding(
      {
        left: PADDING_MD,
        right: PADDING_MD,
      },
      {
        left: true,
        right: true,
      }
    ),
  },

  [breakpoints.LARGE]: {
    ...createSafeInsetPadding(
      {
        left: PADDING_LG,
        right: PADDING_LG,
      },
      {
        left: true,
        right: true,
      }
    ),

    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: `calc(100% - (${spacing.normal} * 2))`,
  },

  [breakpoints.XLARGE]: {
    margin: '0 auto',

    ...createSafeInsetPadding(
      {
        left: PADDING_XLG,
        right: PADDING_XLG,
      },
      {
        left: true,
        right: true,
      }
    ),

    maxWidth: `auto`,
  },

  [breakpoints.XXLARGE]: {
    margin: '0 auto',

    ...createSafeInsetPadding(
      {
        left: PADDING_XLG,
        right: PADDING_XLG,
      },
      {
        left: true,
        right: true,
      }
    ),

    maxWidth: `auto`,
  },

  [breakpoints.XXXLARGE]: {
    margin: '0 auto',

    ...createSafeInsetPadding(
      {
        left: PADDING_XLG,
        right: PADDING_XLG,
      },
      {
        left: true,
        right: true,
      }
    ),

    maxWidth: `calc(${screenSizes.XXXLARGE}px - (${spacing.normal} * 2))`,
  },
});

const StyledGridContainerOffset = styled.div<{
  $gridContainerTopOffsetEnabled: boolean;
}>(props => ({
  width: '100vw',
  position: 'relative',
  left: '50%',
  right: '50%',
  marginLeft: '-50vw',
  marginRight: '-50vw',
  ...(props.$gridContainerTopOffsetEnabled
    ? mixins.gridContainerTopOffset
    : {}),
}));

type ContainerOffsetProps = {
  includeTopOffset: boolean;
  /** Contents of this container */
  children?: ReactNode;
};

function ContainerOffset(props: ContainerOffsetProps) {
  return (
    <StyledGridContainerOffset
      $gridContainerTopOffsetEnabled={props.includeTopOffset}
    >
      {props.children}
    </StyledGridContainerOffset>
  );
}

ContainerOffset.defaultProps = {
  includeTopAndBottomOffset: false,
};

type ContainerProps = {
  /** Needs to be proxied down in case this component is extended  */
  className?: string;
  /** Contents of this container */
  children?: ReactNode;
};

function Container(props: ContainerProps) {
  return (
    <StyledGridContainer className={props.className}>
      {props.children}
    </StyledGridContainer>
  );
}

export { Container, ContainerOffset };
export default Container;
