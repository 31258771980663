import { useState, useRef, useEffect, KeyboardEvent } from 'react';
import styled from 'styled-components';
import Downshift, {
  StateChangeOptions,
  ControllerStateAndHelpers,
} from 'downshift';
import { motion } from 'framer-motion';

import IconLink from '~/components/IconLink';
import { PADDING_XSM } from '~/components/layout/Container';
import Overlay from '~/components/Overlay';
import SearchInput from '~/components/SearchInput';
import DefaultMobileHeader from '~/components/Header/DefaultMobileHeader';
import { AddIcon, SearchIcon } from '~/components/icons';
import {
  navigateToLoopDetails,
  useLoopDetailsOptInPreference,
} from '~/services/loop-service';
import {
  boxShadows,
  colors,
  spacing,
  momentumScrollingStyles,
  headerHeight,
  zIndex,
} from '~/styles';

import LoopSearchContainer from '~/components/LoopSearchContainer';
import LoopSearchResults from './LoopSearch/LoopSearchResults';
import { LoopResource } from '~/store/features/api/resources/loop/types';
import { SearchScope } from '~/components/LoopSearchContainer/LoopSearchContainer';
import { AppliedSortType } from '~/components/loops/utils/sorting';
import { useI18n } from '~/hooks';
import { noop } from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import AccountUnverified from '~/components/AccountUnverified';

const mixins = {
  headerItem: {
    padding: `${spacing.small} 0`,
  },
};

const StyledSearchResults = styled(motion.div)({
  ...momentumScrollingStyles,
  position: 'absolute',
  top: headerHeight.values.default,
  left: `-${PADDING_XSM}`,
  right: `-${PADDING_XSM}`,
  maxHeight: `calc(100vh - ${headerHeight.values.default})`,
  backgroundColor: colors.white,
  boxShadow: boxShadows.bottom,
});

const StyledSearchInputContainer = styled(motion.div)({
  position: 'absolute',
  top: 0,
  left: `-${PADDING_XSM}`,
  right: `-${PADDING_XSM}`,
  height: '100%',
  padding: `0 ${PADDING_XSM}`,
  backgroundColor: colors.white,
  zIndex: zIndex.aboveSibling,
});

const StyledAddLoop = styled.div<{
  $headerItemEnabled: boolean;
}>(props => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  flex: '0 1 auto',
  height: '80%',
  marginLeft: spacing.normal,
  ...(props.$headerItemEnabled ? mixins.headerItem : {}),
}));

const StyledSearch = styled.div<{
  $headerItemEnabled: boolean;
}>(props => ({
  display: 'flex',
  alignItems: 'center',
  flex: '0 1 auto',
  height: '80%',
  marginLeft: 'auto',
  ...(props.$headerItemEnabled ? mixins.headerItem : {}),
}));

const StyledContainer = styled(motion.div)({
  position: 'relative',
  backgroundColor: colors.white,
  overflow: 'hidden',
});

interface Props {
  isAdmin: boolean;
  isLoading: boolean;
  matchingLoops: Array<LoopResource>;
  searchScope: SearchScope;
  searchValue: string;
  hasComplianceGroups: boolean;
  appliedSort: AppliedSortType;
  onScopeToggle: () => void;
  onSearchChange: (searchTerm: string) => void;
  showSearchResults: boolean;
  clearSearch: () => void;
  onSearchSubmitted: (e: KeyboardEvent<HTMLInputElement>) => void;
}

function MyLoopsMobileHeaderContents(props: Props) {
  const {
    isAdmin,
    isLoading,
    matchingLoops,
    searchScope,
    onScopeToggle,
    onSearchChange,
    searchValue,
    hasComplianceGroups,
    appliedSort,
    showSearchResults,
    onSearchSubmitted,
  } = props;
  const [searchVisible, setSearchVisible] = useState(!!props.searchValue);
  const inputRef = useRef<null | HTMLInputElement>(null);
  const req = useRef<null | number>(null);
  const i18n = useI18n();
  const navigate = useNavigate();
  const isOptedInToLoopDetails = useLoopDetailsOptInPreference();

  useEffect(() => {
    return () => {
      if (req.current) {
        cancelAnimationFrame(req.current);
        req.current = null;
      }
    };
  }, []);

  const setupInputRef = (ref: HTMLInputElement | null) => {
    inputRef.current = ref;
  };

  const showSearch = () => {
    if (!searchVisible && inputRef.current) {
      inputRef.current.focus();
    }

    setSearchVisible(true);
  };

  const hideSearch = () => {
    setSearchVisible(false);
  };

  const handleClearSearch = () => {
    if (!searchVisible) {
      props.clearSearch();
    }
  };

  const selectLoop = (loop: LoopResource | null) => {
    if (!!loop) {
      navigateToLoopDetails(navigate, loop.id, isOptedInToLoopDetails);
    }
  };

  const handleDownshiftStateChange = (
    changes: StateChangeOptions<LoopResource>,
    prevState: ControllerStateAndHelpers<LoopResource>
  ) => {
    if (
      changes.isOpen === false &&
      changes.isOpen !== prevState.isOpen &&
      changes.type === Downshift.stateChangeTypes.mouseUp
    ) {
      // When downshift wants to close due to a click outside the serach and menu,
      // we want to close the loop search by clearing the search value
      hideSearch();
    }
  };

  const hasSearchResults = matchingLoops && matchingLoops.length > 0;
  let headerVariant = 'init';

  if (searchVisible && !hasSearchResults && !isLoading) {
    headerVariant = 'emptySearch';
  } else if (searchVisible) {
    headerVariant = 'executedSearch';
  }

  return (
    <Downshift<LoopResource>
      id="loop-search"
      menuId="loop-search-menu"
      isOpen={showSearchResults}
      inputValue={searchValue || ''}
      itemToString={item => (item === null ? '' : item.name)}
      onChange={selectLoop}
      onStateChange={(changes, prevState) =>
        handleDownshiftStateChange(changes, prevState)
      }
    >
      {({
        getRootProps,
        getMenuProps,
        getItemProps,
        getInputProps,
        highlightedIndex,
      }) => (
        <div {...getRootProps(undefined, { suppressRefError: true })}>
          <Overlay
            isVisible={searchVisible && showSearchResults}
            onClick={hideSearch}
          />
          <StyledContainer
            initial="init"
            animate={headerVariant}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            variants={{
              init: {
                boxShadow: boxShadows.empty,
                transition: { when: 'afterChildren' },
                transitionEnd: { overflow: 'hidden', zIndex: zIndex.header },
              },
              emptySearch: {
                boxShadow: boxShadows.bottom,
                overflow: 'visible',
                zIndex: zIndex.headerSearchResults + zIndex.aboveSibling,
                transition: { when: 'beforeChildren' },
              },
              executedSearch: {
                boxShadow: boxShadows.empty,
                overflow: 'visible',
                zIndex: zIndex.headerSearchResults + zIndex.aboveSibling,
                transition: { when: 'beforeChildren' },
              },
            }}
          >
            <DefaultMobileHeader>
              <StyledSearch
                $headerItemEnabled
                onClick={showSearch}
                aria-label={i18n.t('actions.search')}
              >
                <SearchIcon type="grey" height={spacing.medium} />
              </StyledSearch>

              <StyledAddLoop
                $headerItemEnabled
                aria-label={i18n.t(`my-loops:add`)}
              >
                <AccountUnverified />
                <IconLink
                  link="/loops/create"
                  icon={AddIcon}
                  defaultIconProps={{ height: spacing.medium }}
                />
              </StyledAddLoop>

              <StyledSearchInputContainer
                initial="hidden"
                animate={searchVisible ? 'visible' : 'hidden'}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
                onAnimationComplete={handleClearSearch}
                variants={{
                  visible: {
                    x: '0%',
                  },
                  hidden: {
                    x: '-100%',
                    transition: {
                      delay: 0.4,
                      duration: 0.3,
                      ease: 'easeInOut',
                    },
                  },
                }}
              >
                <SearchInput
                  {...getInputProps()}
                  inputLabel="Search loops"
                  padding={false}
                  clearOnBlur={false}
                  fullHeight
                  persistentCloseIcon
                  placeholder={i18n.t('loops:search.placeholderMobile')}
                  onChange={onSearchChange}
                  onClose={hideSearch}
                  ref={setupInputRef}
                  onKeyUp={onSearchSubmitted}
                />
              </StyledSearchInputContainer>

              <StyledSearchResults
                initial="closed"
                animate={searchVisible && showSearchResults ? 'open' : 'closed'}
                transition={{ duration: 0.2, ease: 'easeInOut' }}
                variants={{
                  open: {
                    y: '0%',
                    opacity: 1,
                    pointerEvents: 'auto',
                    borderTop: `1px solid ${colors.primaryAction}`,
                  },
                  closed: {
                    y: '-100%',
                    borderTop: `0px solid ${colors.primaryAction}`,
                    transitionEnd: { opacity: 0, pointerEvents: 'none' },
                    transition: { duration: 0.3, ease: 'easeInOut' },
                  },
                }}
              >
                <LoopSearchResults
                  getMenuProps={getMenuProps}
                  getItemProps={getItemProps}
                  isAdmin={isAdmin}
                  isLoading={isLoading}
                  showSearchResults={showSearchResults}
                  searchScope={searchScope}
                  onScopeToggle={onScopeToggle}
                  searchValue={searchValue}
                  hasComplianceGroups={hasComplianceGroups}
                  appliedSort={appliedSort}
                  highlightedIndex={highlightedIndex}
                  matchingLoops={matchingLoops}
                  updateSearchQueryParameter={noop}
                  hasActiveFilters={false}
                />
              </StyledSearchResults>
            </DefaultMobileHeader>
          </StyledContainer>
        </div>
      )}
    </Downshift>
  );
}

export function MyLoopsMobileHeader() {
  return (
    <LoopSearchContainer resetPaging={noop} pageNumber={1}>
      {({
        isAdmin,
        isLoading,
        searchValue,
        searchScope,
        onScopeToggle,
        onSearchChange,
        hasComplianceGroups,
        appliedSort,
        matchingLoops,
        showSearchResults,
        onSearchSubmitted,
        clearSearch,
      }) => (
        <MyLoopsMobileHeaderContents
          isAdmin={isAdmin}
          isLoading={isLoading}
          searchValue={searchValue}
          searchScope={searchScope}
          hasComplianceGroups={hasComplianceGroups}
          appliedSort={appliedSort}
          onScopeToggle={onScopeToggle}
          onSearchChange={onSearchChange}
          matchingLoops={matchingLoops}
          showSearchResults={showSearchResults}
          onSearchSubmitted={onSearchSubmitted}
          clearSearch={clearSearch}
        />
      )}
    </LoopSearchContainer>
  );
}

export default MyLoopsMobileHeader;
