let doesBrowserRequireIntlPolyfill = false;
let doesBrowserRequireIntersectionObserverPolyfill = false;
let doesBrowserRequireClosestObserverPolyfill = false;
let hasPolyfillCheckOccurred = false;

type Callback = () => void;

export function loadPolyfillsIfNecessary(cb: Callback) {
  const {
    requiresIntlPolyfill,
    requiresIntersectionObserverPolyfill,
    requiresClosestPolfill,
  } = getNeededPolyfills();
  const polyfillPromises = [];

  if (requiresIntlPolyfill) {
    polyfillPromises.push(loadIntlPolyfill());
  }

  if (requiresIntersectionObserverPolyfill) {
    polyfillPromises.push(loadIntersectionObserverPolyfill());
  }

  if (requiresClosestPolfill) {
    polyfillPromises.push(loadClosestPolyfill());
  }

  if (polyfillPromises.length > 0) {
    Promise.all(polyfillPromises).then(cb, cb);
  } else {
    cb();
  }
}

export function isPolyfilled() {
  return window.__isBrowserPolyfilled__;
}

function getNeededPolyfills() {
  if (!hasPolyfillCheckOccurred) {
    const needsIntlPolyfill = typeof Intl === 'undefined';
    const needsIntersectionObserverPolyfill =
      typeof IntersectionObserver === 'undefined';
    const needsClosestPolyfill = document.body.closest === undefined;

    window.__isBrowserIntlPolyfilled__ = doesBrowserRequireIntlPolyfill =
      needsIntlPolyfill;
    window.__isBrowserIntersectionObserverPolyfilled__ =
      doesBrowserRequireIntersectionObserverPolyfill =
        needsIntersectionObserverPolyfill;
    window.__isBrowserClosestPolyfilled__ =
      doesBrowserRequireClosestObserverPolyfill = needsClosestPolyfill;

    hasPolyfillCheckOccurred = true;
  }

  return {
    requiresIntlPolyfill: doesBrowserRequireIntlPolyfill,
    requiresIntersectionObserverPolyfill:
      doesBrowserRequireIntersectionObserverPolyfill,
    requiresClosestPolfill: doesBrowserRequireClosestObserverPolyfill,
  };
}

async function loadIntlPolyfill() {
  await loadPolyfillScript('/scripts/intl-polyfill.js');
}

async function loadIntersectionObserverPolyfill() {
  await loadPolyfillScript('/scripts/intersection-observer-polyfill.js');
}

async function loadClosestPolyfill() {
  await loadPolyfillScript('/scripts/closest-polyfill.js');
}

async function loadPolyfillScript(scriptSrc: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const js = document.createElement('script');

    js.src = scriptSrc;
    js.onload = function () {
      resolve();
    };
    js.onerror = function () {
      reject(`Failed to load polyfill script: ${scriptSrc}`);
    };

    document.head.appendChild(js);
  });
}
