import styled from 'styled-components';
import { Fragment, ReactNode } from 'react';
import { ChevronIcon, CloseIcon } from '~/components/icons';
import { colors, fontSizes, spacing, utils, zIndex } from '~/styles';

import BaseSelectInput, {
  ConsumerSelectInputProps,
} from './__internal__/BaseSelectInput';

import { RotationDirection } from '~/components/icons/types';
import { SelectedValue } from '../dropdown/types';

const mixins = {
  triggerButtonWithPlaceholder: {
    padding: `${spacing.larger} ${spacing.small} ${spacing.smallest}`,

    '&:focus, &:hover, &:active': {
      '.label': {
        color: colors.primaryAction,
      },
    },
  },

  errorBorder: {
    '&:focus, &:hover': {
      '.label': {
        color: colors.errorText,
      },
    },
  },

  disabledDropdown: {
    backgroundColor: colors.lightBackground,
    pointerEvents: 'none',
  },
};

const StyledPlaceholder = styled.div({
  marginRight: spacing.smallest,
});

const StyledClearButtonIcon = styled.div({
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  background: 'none',
  padding: 0,
  width: '100%',
  height: '100%',
});

const StyledTriggerButtonIcon = styled.div({
  position: 'absolute',
  top: '50%',
  right: spacing.small,
  width: spacing.normal,
  transform: 'translateY(-50%)',
  zIndex: zIndex.aboveSibling,
});

const StyledTriggerButton = styled.button<{
  $triggerButtonWithPlaceholderEnabled: boolean;
  $triggerButtonWithValueEnabled: boolean;
  $errorBorderEnabled: boolean;
  $disabledDropdownEnabled: boolean;
}>(props => ({
  position: 'relative',
  width: '100%',
  padding: props.$triggerButtonWithPlaceholderEnabled
    ? `${spacing.larger} ${spacing.small} ${spacing.smallest}`
    : `${spacing.normal} ${spacing.small}`,
  border: 'none',
  outline: 'none',
  textAlign: 'left',
  cursor: 'pointer',
  borderRadius: utils.baseBorderRadius,
  backgroundColor: colors.white,
  borderColor: props.$errorBorderEnabled ? colors.errorColor : '',

  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    border: props.$errorBorderEnabled
      ? `1px solid ${colors.errorColor}`
      : `1px solid ${colors.lightBorder}`,
    borderRadius: utils.baseBorderRadius,
  },

  '&:hover, &:focus, &:active': {
    '&:after': {
      border: props.$errorBorderEnabled
        ? `2px solid ${colors.errorColor}`
        : `2px solid ${colors.primaryAction}`,
      color: props.$errorBorderEnabled ? colors.errorText : '',
    },
  },

  '&:disabled': {
    backgroundColor: colors.lightBackground,
    cursor: 'no-drop',

    '&:after': {
      borderColor: 'transparent',
    },

    '&:hover, &:focus, &:active': {
      '&:after': {
        borderColor: 'transparent',
      },
    },

    '.label': {
      color: colors.hintText,
    },
  },

  '.label': {
    ...(props.$triggerButtonWithPlaceholderEnabled
      ? fontSizes.callout
      : fontSizes.headline),
    color: props.$errorBorderEnabled
      ? colors.errorText
      : props.$triggerButtonWithPlaceholderEnabled
      ? colors.secondaryText
      : colors.black,
    maxWidth: '90%',
    ...utils.text.truncate,
    position: props.$triggerButtonWithPlaceholderEnabled ? 'absolute' : '',
    top: props.$triggerButtonWithPlaceholderEnabled ? spacing.smallest : '',
    left: props.$triggerButtonWithPlaceholderEnabled ? spacing.small : '',
  },

  '.placeholder': {
    color: props.$triggerButtonWithValueEnabled
      ? colors.black
      : props.$triggerButtonWithPlaceholderEnabled
      ? colors.hintText
      : '',
    ...(props.$triggerButtonWithPlaceholderEnabled ? fontSizes.headline : {}),
  },

  ...(props.$triggerButtonWithPlaceholderEnabled
    ? mixins.triggerButtonWithPlaceholder
    : {}),
  ...(props.$errorBorderEnabled ? mixins.errorBorder : {}),

  ...(props.$disabledDropdownEnabled ? mixins.disabledDropdown : {}),
}));

const StyledRequiredIndicator = styled.span({
  color: colors.errorIcon,
});

type Props<Item, IdType extends SelectedValue> = ConsumerSelectInputProps<
  Item,
  IdType
> & {
  /** Show a placeholder in the dropdown, causing the label to be small and at the top */
  showPlaceholder?: boolean;
  /** Allow the user to clear the currently selected option */
  clearOption?: boolean;
  /** Apply disabled styling without disabling HTML element */
  appearDisabled?: boolean;
  /** Custom header component for the mobile dropdown */
  mobileHeader?: ReactNode;
  /** Custom invalid check */
  isInvalid?: boolean;
  /** aria label for the chevron button */
  ariaLabel?: string;
};

export function OutlinedSelectInput<Item, IdType extends SelectedValue>({
  label,
  showPlaceholder = false,
  clearOption = false,
  appearDisabled = false,
  required = false,
  mobileHeader,
  isInvalid = false,
  ariaLabel = 'toggle-dropdown',
  ...baseSelectProps
}: Props<Item, IdType>) {
  return (
    <Fragment>
      <BaseSelectInput<Item, IdType>
        fullWidthMenu
        label={label}
        renderTrigger={({
          isOpen,
          invalid,
          displayText,
          toggleButtonProps,
          selectedItem,
          clearSelection,
        }) => (
          <Fragment>
            <StyledTriggerButton
              disabled={baseSelectProps.disabled}
              $triggerButtonWithPlaceholderEnabled={showPlaceholder}
              $triggerButtonWithValueEnabled={!!baseSelectProps.value}
              $errorBorderEnabled={invalid || isInvalid}
              $disabledDropdownEnabled={appearDisabled}
              {...toggleButtonProps}
              aria-labelledby={undefined}
              aria-label={label}
              aria-describedby="dropdownError"
            >
              <div className="label">
                {showPlaceholder ? label : displayText}
                {showPlaceholder && required && (
                  <StyledRequiredIndicator>*</StyledRequiredIndicator>
                )}
              </div>

              {showPlaceholder && (
                <StyledPlaceholder className="placeholder">
                  {displayText}
                </StyledPlaceholder>
              )}
              <StyledTriggerButtonIcon aria-label={ariaLabel}>
                {clearOption && selectedItem ? (
                  <StyledClearButtonIcon
                    id={`${baseSelectProps.id}-clear-button`}
                    aria-label="clear selection"
                    onClick={e => {
                      clearSelection?.();
                      e.stopPropagation();
                    }}
                  >
                    <CloseIcon
                      width={spacing.small}
                      type={baseSelectProps.disabled ? 'hintText' : 'black'}
                    />
                  </StyledClearButtonIcon>
                ) : (
                  <ChevronIcon
                    width="100%"
                    type={baseSelectProps.disabled ? 'hintText' : 'black'}
                    direction={
                      isOpen ? RotationDirection.Up : RotationDirection.Down
                    }
                  />
                )}
              </StyledTriggerButtonIcon>
            </StyledTriggerButton>
          </Fragment>
        )}
        {...baseSelectProps}
        mobileHeader={mobileHeader}
      />
    </Fragment>
  );
}

export default OutlinedSelectInput;
