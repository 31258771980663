const SMALL = 500;
const MEDIUM = 768;
const LARGE = 992;
const XLARGE = 1200;
const XXLARGE = 1440;
const XXXLARGE = 1650;

const screenSizes = {
  SMALL,
  MEDIUM,
  LARGE,
  XLARGE,
  XXLARGE,
  XXXLARGE,
};

const mediaBreakpoints = {
  SMALL: `@media (min-width: ${SMALL}px)`,
  MEDIUM: `@media (min-width: ${MEDIUM}px)`,
  LARGE: `@media (min-width: ${LARGE}px)`,
  XLARGE: `@media (min-width: ${XLARGE}px)`,
  XXLARGE: `@media (min-width: ${XXLARGE}px)`,
  XXXLARGE: `@media (min-width: ${XXXLARGE}px)`,
};

export { screenSizes };
export default mediaBreakpoints;
