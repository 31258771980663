import { MessageEvent as PubnubMessageEvent } from 'pubnub';

import { ResourceTypes } from '~/store/features/api/resources/types';
import { Dispatch } from 'redux';
import { MessageEvent, NewMessageData } from './pubnub-service';
import { ApiResource } from '~/lib/api/types';
import {
  fetchAllResources,
  fetchResource,
} from '~/store/features/api/apiSlice';

export type MessageStatus = {
  date: string;
  messageId: string;
  participantUuid: string;
  status: string;
};
type NewMessageStatusData = {
  messageId: string;
  messageType: string;
  conversation: {
    conversationId: string;
  };
  statuses: Array<MessageStatus>;
};

type ListenerCallback = (data: NewMessageData) => void;

type Subscribers = {
  [subscriberId: string]: ListenerCallback;
};

type Subscription = {
  subscribers: Subscribers;
};

type Subscriptions = {
  [conversationId: string]: Subscription | null;
};

type ConversationPromises = {
  [conversationId: string]: Promise<any>;
};

type ReceivedMessages = {
  [messageId: string]: boolean;
};

export default class Messaging {
  dispatch: Dispatch<any>;
  subscriptions: Subscriptions = {};
  conversationsInFlight: ConversationPromises = {};
  receivedMessages: ReceivedMessages = {};

  constructor(dispatch: Dispatch<any>) {
    this.dispatch = dispatch;
  }

  subscribeToConversation = (
    conversationId: string,
    callerId: string,
    callback: ListenerCallback
  ) => {
    if (!this.subscriptions[conversationId]) {
      this.subscriptions[conversationId] = {
        subscribers: {
          [callerId]: callback,
        },
      };
    } else {
      const subscription = this.subscriptions[conversationId];

      if (subscription && !subscription.subscribers[callerId]) {
        subscription.subscribers[callerId] = callback;
      }
    }
  };

  handleMessageEvent = async (
    messageEvent: MessageEvent | PubnubMessageEvent
  ) => {
    const { channel, message } = messageEvent;

    if (
      channel.startsWith('user-messaging-') &&
      'userMetadata' in messageEvent
    ) {
      const userMetadata = messageEvent.userMetadata;
      const messageArray = Array.isArray(message) ? message : [message];

      if (userMetadata.event === 'NEW_MESSAGE') {
        for (let i = 0; i < messageArray.length; i++) {
          const messageItem = messageArray[i];

          await this.addMessageToStore(messageItem);
          this.notifySubscribers(messageItem);
        }
      } else if (userMetadata.event === 'UPDATE_MESSAGE_STATUS') {
        const messageStatusArray: Array<NewMessageStatusData> =
          messageArray as Array<any>;
        for (let i = 0; i < messageStatusArray.length; i++) {
          const messageItem = messageStatusArray[i];

          await this.addMessageStatusToStore(messageItem);
        }
      }
    }
  };

  fetchEmptyConversationIfNeeded = async (
    data: NewMessageData | NewMessageStatusData
  ) => {
    const {
      conversation: { conversationId },
    } = data;

    if (
      !!this.conversationsInFlight[conversationId] ||
      !this.subscriptions[conversationId]
    ) {
      // Nobody is subscribed to this conversation, which means it is empty
      // (we received a new message for a conversation that hasn't yet been loaded).
      // Load the conversation now (or wait for previous conversation to finish fetching)
      // before pushing data into the store. If there's already a conversation request in flight,
      // return it regardless of if there's a subscription
      if (!this.conversationsInFlight[conversationId]) {
        const promise = this.dispatch(
          fetchResource({
            resourceName: ResourceTypes.Conversations,
            resourceId: conversationId,
            options: {
              apiVersion: 'v1_1',
            },
          })
        ) as any;

        this.conversationsInFlight[conversationId] = promise;
      }

      try {
        await this.conversationsInFlight[conversationId];
      } finally {
        delete this.conversationsInFlight[conversationId];
      }
    }
  };

  fetchEmptyMessageIfNeeded = async (data: NewMessageStatusData) => {
    const {
      messageId,
      conversation: { conversationId },
    } = data;

    if (!this.receivedMessages[messageId]) {
      await this.dispatch(
        fetchResource({
          resourceName: ResourceTypes.Messages,
          resourceId: messageId,
          options: {
            apiVersion: 'v1_1',
            additionalOptions: { 'filter[conversationId]': conversationId },
          },
        })
      );

      return [
        {
          id: conversationId,
          type: ResourceTypes.Conversations,
          attributes: {},
          relationships: {
            latestMessage: {
              data: { id: messageId, type: ResourceTypes.Messages },
            },
          },
        },
      ];
    } else {
      return [];
    }
  };

  addMessageToStore = async (data: NewMessageData) => {
    await this.fetchEmptyConversationIfNeeded(data);

    this.receivedMessages[data.messageId] = true;

    const messageResource: ApiResource = {
      id: data.messageId,
      type: ResourceTypes.Messages,
      attributes: {
        messageType: data.messageType,
        sentDate: data.sentDate,
        text: data.text,
      },
      relationships: {
        conversation: {
          data: {
            id: data.conversation.conversationId,
            type: ResourceTypes.Conversations,
          },
        },
        sender: {
          data: { id: data.sender.id, type: ResourceTypes.Participants },
        },
      },
    };
    const conversationResource = {
      id: data.conversation.conversationId,
      type: ResourceTypes.Conversations,
      attributes: {},
      relationships: {
        latestMessage: {
          data: { id: data.messageId, type: ResourceTypes.Messages },
        },
      },
    };
    const resourcesToAdd: Array<ApiResource> = [
      messageResource,
      conversationResource,
    ];
    const documentData = data.document;
    let documentResource, documentRevisionResource, loopResource;

    if (!!documentData) {
      const {
        documentId,
        name,
        loopName,
        viewId: loopId,
        revision,
        documentInvitationUrl,
        documentFieldChanges,
        canEdit = false,
        canFill = false,
        canSign = false,
        canView = false,
      } = documentData;
      const documentRevisionId = `${documentId}:${revision}`;

      documentResource = {
        id: documentId.toString(),
        type: ResourceTypes.Documents,
        attributes: {
          name,
        },
        relationships: {
          loop: {
            data: {
              id: loopId.toString(),
              type: 'loops',
            },
          },
        },
      };

      loopResource = {
        id: loopId.toString(),
        type: ResourceTypes.Loops,
        attributes: {
          name: loopName,
        },
      };

      documentRevisionResource = {
        id: documentRevisionId,
        type: ResourceTypes.DocumentRevisions,
        attributes: {
          documentInvitationUrl,
          documentFieldChanges,
          revision,
          canEdit,
          canFill,
          canSign,
          canView,
        },
        relationships: {
          document: {
            data: { id: documentId.toString(), type: ResourceTypes.Documents },
          },
        },
      };

      if (messageResource.relationships) {
        messageResource.relationships.document = {
          data: { id: documentId.toString(), type: ResourceTypes.Documents },
        };

        messageResource.relationships.documentRevision = {
          data: {
            id: documentRevisionId,
            type: ResourceTypes.DocumentRevisions,
          },
        };
      }

      resourcesToAdd.push(documentResource);
      resourcesToAdd.push(documentRevisionResource);
      resourcesToAdd.push(loopResource);
    }

    this.dispatch(
      fetchAllResources.fulfilled(
        { data: resourcesToAdd, type: 'success' },
        '',
        {
          resourceName: ResourceTypes.Messages,
        }
      )
    );
  };

  addMessageStatusToStore = async (data: NewMessageStatusData) => {
    await this.fetchEmptyConversationIfNeeded(data);
    const additionalResources = await this.fetchEmptyMessageIfNeeded(data);

    const buildStatusId = (status: MessageStatus) =>
      `${status.participantUuid}:${status.messageId}`;
    const statusResources = data.statuses.map(status => ({
      id: buildStatusId(status),
      type: ResourceTypes.ParticipantStatuses,
      attributes: {
        participantUuid: status.participantUuid,
        messageId: status.messageId,
        date: status.date,
        status: status.status,
      },
    }));
    const reduxAction = fetchAllResources.fulfilled(
      {
        type: 'success',
        data: [
          {
            id: data.messageId,
            type: ResourceTypes.Messages,
            attributes: {},
            relationships: {
              conversation: {
                data: {
                  id: data.conversation.conversationId,
                  type: ResourceTypes.Conversations,
                },
              },
              statuses: {
                data: data.statuses.map(status => ({
                  id: buildStatusId(status),
                  type: ResourceTypes.ParticipantStatuses,
                })),
              },
            },
          },
          ...statusResources,
          ...additionalResources,
        ],
      },
      '',
      {
        resourceName: ResourceTypes.Messages,
      }
    );
    this.dispatch(reduxAction);
  };

  notifySubscribers = (data: NewMessageData) => {
    const {
      conversation: { conversationId },
    } = data;
    const subscription = this.subscriptions[conversationId];

    if (subscription) {
      Object.keys(subscription.subscribers).forEach(callerId => {
        const callback = subscription.subscribers[callerId];

        callback(data);
      });
    }
  };
}
