import qs from 'qs';
import { makeVegaRequest } from '~/lib/api/network';
import config from '~/config';

import { UploadType } from './constants';

type FileUploadResult = {
  ok: boolean;
  data?: string;
  s3Key?: string;
  error?: any;
};

export type CropData = {
  x: number;
  y: number;
  width: number;
  height: number;
  aspect?: number;
};

type UploadErrorType = { errorId: string };
export async function uploadBrandingLogo(
  file: File,
  cropData: CropData,
  onProgress?: (progress: number) => void
): Promise<string | UploadErrorType> {
  try {
    const s3Result = await uploadFile(
      UploadType.BrandingLogo,
      file,
      onProgress
    );

    if (s3Result && s3Result.s3Key) {
      try {
        const imageResizeResult = await sendCropRequest(
          s3Result.s3Key,
          cropData
        );

        return imageResizeResult;
      } catch (e) {
        return {
          errorId: 'errors.upload.profileLogo.crop',
        };
      }
    } else {
      return {
        errorId: 'errors.upload.profileLogo.s3Timeout',
      };
    }
  } catch (e) {
    return {
      errorId: 'errors.upload.profileLogo.s3Failure',
    };
  }
}

async function sendCropRequest(
  s3ImageUrl: string,
  cropData: CropData
): Promise<string> {
  const {
    api: { imageResizeUrl },
  } = config;
  const queryString = qs.stringify(cropData);
  const url = `${imageResizeUrl}/${s3ImageUrl}?${queryString}`;

  const response = await fetch(encodeURI(url), {
    method: 'GET',
    mode: 'cors',
  });

  return await response.text();
}

export function uploadLoopPhoto(
  file: File,
  onProgress?: (progress: number) => void
): Promise<FileUploadResult> {
  return uploadFile(UploadType.LoopPhoto, file, onProgress);
}

export async function uploadSignature(blob: Blob): Promise<FileUploadResult> {
  return await uploadFileHelper(
    UploadType.UserSignature,
    blob,
    'userSignature',
    'png'
  );
}
export async function uploadInitials(blob: Blob): Promise<FileUploadResult> {
  return await uploadFileHelper(
    UploadType.UserSignature,
    blob,
    'userInitials',
    'png'
  );
}

export async function uploadDocument(
  document: File,
  onProgress?: (progress: number) => void
): Promise<FileUploadResult> {
  return await uploadFile(UploadType.Document, document, onProgress);
}

function uploadFile(
  uploadType: UploadType,
  file: File,
  onProgress?: (progress: number) => void
): Promise<FileUploadResult> {
  const lastIndex = file.name.lastIndexOf('.');
  const fileExtension = file.name.substring(lastIndex + 1);

  return uploadFileHelper(
    uploadType,
    file,
    file.name,
    fileExtension,
    onProgress
  );
}

async function uploadFileHelper(
  uploadType: UploadType,
  fileData: File | Blob,
  fileName: string,
  fileExtension: string,
  onProgress?: (progress: number) => void
): Promise<FileUploadResult> {
  const baseUrl = `upload/request/${uploadType}`;
  const url = `${baseUrl}?extension=${fileExtension}`;

  const response = await makeVegaRequest(url);
  const json = response.payload;

  return new Promise(resolve => {
    if (json && json.formFields) {
      const formData = new FormData();

      Object.keys(json.formFields).forEach(key => {
        formData.append(key, json.formFields[key]);
      });
      formData.append('file', fileData, fileName); //File must come last in FormData

      const fileRequest = new XMLHttpRequest();
      fileRequest.open('POST', json.uploadUrl);
      fileRequest.setRequestHeader('Accept', 'application/json');

      fileRequest.onload = () => {
        if (fileRequest.status !== 200 && fileRequest.status !== 201) {
          // 200 status for server uploads, 201 created for AWS S3 direct uploads
          resolve({ ok: false, error: 'Upload failed' });
        } else if (
          fileRequest.getResponseHeader('Content-Type') === 'application/json'
        ) {
          // Server uploads result in JSON
          const responseData = JSON.parse(fileRequest.responseText);
          resolve({ ok: true, data: responseData.formFields.key });
        } else {
          // Direct to S3 uploads always return XML, so we need to parse that instead
          const parser = new DOMParser();
          const xml = parser.parseFromString(
            fileRequest.responseText,
            'application/xml'
          );
          const documentKey = xml.getElementsByTagName('Key')[0].innerHTML;
          const match = documentKey.match(/.*\/(.*)/);

          if (!!match && match.length === 2) {
            // Remove the file extension from the uuid, if it exists
            const uuid = match[1].replace(/\.(.*)$/, '');
            resolve({ ok: true, data: uuid, s3Key: documentKey });
          } else {
            resolve({ ok: false, error: 'Failed to parse direct S3 upload' });
          }
        }
      };

      fileRequest.onerror = error => {
        resolve({ ok: false, error });
      };

      fileRequest.upload.onprogress = e => {
        if (e.lengthComputable) {
          const progress = Math.ceil((e.loaded / e.total) * 100);
          onProgress?.(progress);
        }
      };

      fileRequest.send(formData);
    } else {
      resolve({
        ok: false,
        error: 'Sorry, we were unable to upload your image.',
      });
    }
  });
}
