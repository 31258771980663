import PubNub, {
  StatusEvent,
  PresenceEvent,
  MessageEvent as PubnubMessageEvent,
} from 'pubnub';

import config from '~/config';
import { makeVegaRequest } from '~/lib/api/network';
import { DocumentFieldChange } from '~/store/features/api/resources/documentRevision/types';
import FeatureFlag from '~/lib/feature-flags/constants';
import { isFeatureEnabled } from '~/lib/feature-flags';

export type NewMessageData = {
  messageId: string;
  messageType: string;
  sentDate: string;
  text: string;
  conversation: {
    conversationId: string;
  };
  sender: {
    id: string;
  };
  document?: {
    documentId: number;
    name: string;
    loopName: string;
    viewId: number;
    documentFieldChanges: Array<DocumentFieldChange>;
    documentInvitationUrl: string;
    revision: number;
    profileId: number;
    canEdit: boolean;
    canFill: boolean;
    canSign: boolean;
    canView: boolean;
  };
};

export type MessageEvent = {
  channel: string;
  message: NewMessageData | Array<NewMessageData>;
  publisher: string;
  subscription: string;
  timetoken: string;
  userMetadata: {
    event: string;
  };
};

export type MessageEventHandler = (
  event: PubnubMessageEvent | MessageEvent
) => any;

const {
  ui: {
    isProductionBuild,
    pubnub: { publishKey, subscribeKey },
  },
} = config;

const isPubNubTokenEnabled = isFeatureEnabled(FeatureFlag.UsePubNubToken);

export default class PubNubService {
  pubnub: PubNub | null = null;
  messageHandlers: Array<MessageEventHandler>;

  constructor() {
    this.messageHandlers = [];
  }

  setupPubNub(userId: number) {
    if (isPubNubTokenEnabled) {
      makeVegaRequest('push/token', {
        method: 'GET',
      })
        .then(result => {
          if (result && result.payload) {
            const { token, uuid } = result.payload;
            this.pubnub = new PubNub({
              uuid,
              publishKey,
              subscribeKey,
              ssl: isProductionBuild,
            });
            this.pubnub.setToken(token);
            this.subscribeToUserChannels(userId, this.pubnub);
          }
        })
        .catch(error => {
          console.error('Error setting up PubNub', error);
        });
    } else {
      makeVegaRequest('push/subscriptions', {
        method: 'POST',
        body: JSON.stringify({
          channels: [`user-messaging-${userId}`],
        }),
      })
        .then(result => {
          if (result && result.payload) {
            const { authKey, uuid } = result.payload;
            this.pubnub = new PubNub({
              uuid,
              authKey,
              publishKey,
              subscribeKey,
              ssl: isProductionBuild,
            });
            this.subscribeToUserChannels(userId, this.pubnub);
          }
        })
        .catch(error => {
          console.error('Error setting up PubNub', error);
        });
    }
  }

  subscribeToUserChannels(userId: number, _pubnub: PubNub) {
    _pubnub.addListener({
      status: (statusEvent: StatusEvent) => {
        console.log('Status:', statusEvent);
      },
      message: (messageEvent: PubnubMessageEvent) => {
        if (this.messageHandlers && this.messageHandlers.length > 0) {
          this.messageHandlers.forEach(handler => handler(messageEvent));
        }
      },
      presence: (presenceEvent: PresenceEvent) => {
        console.log('Presence:', presenceEvent);
      },
    });
    _pubnub.subscribe({
      channelGroups: [`user-${userId}`],
      withPresence: false,
    });
  }

  addMessageEventHandler(handler: MessageEventHandler) {
    this.messageHandlers.push(handler);
  }
}
