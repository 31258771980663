import { useState, useEffect, useRef } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import styled from 'styled-components';
import { noop } from 'lodash-es';

import { useI18n } from '~/hooks';
import FileInput from '~/components/inputs/file/FileInput';
import { DropZoneTarget } from '~/components/FileDragDrop';
import { PrimaryButton } from '~/components/buttons';
import { UploadIcon } from '~/components/icons';
import { spacing } from '~/styles';

import { CropData } from '~/services/upload-service';

const StyledReactCrop = styled(ReactCrop)({
  background: 'transparent',
});

const StyledInput = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const StyledContainer = styled.div({
  width: '100%',
  height: '100%',
  position: 'relative',
});

const MAX_CROP_SIZE = 2000;
const MIN_CROP_SIZE = 32;

type CropType = {
  x: number;
  y: number;
  width?: number;
  height?: number;
  aspect?: number;
};

function calculateMinMax(
  dimensionSize: number,
  imageDimension: number,
  aspectMultiplier = 1
) {
  return (dimensionSize * 100) / (imageDimension * aspectMultiplier);
}

type ImageState = {
  imageAspect?: number | null;
  maxWidth?: number;
  maxHeight?: number;
  minWidth?: number;
  minHeight?: number;
};

type Props = {
  accept: string;
  buttonText: string;
  image?: File | null;
  aspect?: number;
  cropData?: CropData | null;
  onCropValuesChange: (crop: CropData) => void;
  onImageChange: (File: File) => void;
};

export function ImageUpload(props: Props) {
  const { accept, buttonText, image } = props;
  const [src, setSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState<CropType | undefined>();
  const [imageState, setImageState] = useState<ImageState>({
    maxWidth: 100,
    maxHeight: 100,
    minWidth: 0,
    minHeight: 0,
  });
  const { maxWidth, maxHeight, minWidth, minHeight } = imageState;
  const imageRef = useRef(image);
  const i18n = useI18n();

  useEffect(() => {
    if (image !== imageRef.current) {
      if (image) {
        const reader = new FileReader();

        reader.addEventListener('load', () => {
          if (reader.result) {
            setSrc(reader.result.toString());
          }
        });
        reader.readAsDataURL(image);
      } else {
        setSrc(null);
      }
    }
  }, [image]);

  if (!i18n) {
    return null;
  }

  const onImageLoaded = ({ naturalWidth, naturalHeight }: HTMLImageElement) => {
    const naturalAspect = naturalWidth / naturalHeight;
    let cropWidth =
      naturalWidth > MAX_CROP_SIZE
        ? calculateMinMax(MAX_CROP_SIZE, naturalWidth)
        : 100;
    let cropHeight =
      naturalHeight > MAX_CROP_SIZE
        ? calculateMinMax(MAX_CROP_SIZE, naturalHeight)
        : 100;
    let newCrop: CropType = {
      x: 0,
      y: 0,
      width: cropWidth,
      height: cropHeight,
    };
    const newImageState: ImageState = {
      maxWidth: cropWidth,
      maxHeight: cropHeight,
      minWidth: calculateMinMax(MIN_CROP_SIZE, naturalWidth),
      minHeight: calculateMinMax(MIN_CROP_SIZE, naturalHeight),
      imageAspect: naturalAspect,
    };
    const expectedAspect = props.aspect;

    if (expectedAspect) {
      if (naturalAspect > expectedAspect) {
        cropWidth = (100 * expectedAspect * naturalHeight) / naturalWidth;
      } else if (naturalAspect < expectedAspect) {
        cropHeight = (naturalWidth * 100) / (expectedAspect * naturalHeight);
      }

      const cropKey = cropWidth > cropHeight ? 'width' : 'height';
      const cropValue = cropWidth > cropHeight ? cropWidth : cropHeight;

      newImageState.minWidth =
        expectedAspect > 1
          ? calculateMinMax(MIN_CROP_SIZE, naturalWidth, expectedAspect)
          : newImageState.minWidth;
      newImageState.minHeight =
        expectedAspect < 1
          ? calculateMinMax(MIN_CROP_SIZE, naturalHeight, 1 / expectedAspect)
          : newImageState.minWidth;
      newCrop = {
        x: 0,
        y: 0,
        aspect: expectedAspect,
        [cropKey]: cropValue,
      };

      props.onCropValuesChange({
        x: 0,
        y: 0,
        width: (cropWidth * naturalWidth) / 100,
        height: (cropHeight * naturalHeight) / 100,
      });
    } else {
      props.onCropValuesChange({
        x: 0,
        y: 0,
        width: (cropWidth * naturalWidth) / 100,
        height: (cropHeight * naturalHeight) / 100,
      });
    }

    setCrop(newCrop);
    setImageState(newImageState);
  };

  const onCropChange = (crop: CropType, pixelCrop: CropData) => {
    setCrop(crop);
    props.onCropValuesChange(pixelCrop);
  };

  const handleFileUpload = (files: Array<File> | FileList) => {
    if (files && files.length > 0) {
      const file = files[0];

      props.onImageChange(file);
    }
  };

  return (
    <StyledContainer>
      {!src && (
        <DropZoneTarget
          accept={accept}
          hasAcceptedFile={!!image}
          onFileDrop={handleFileUpload}
          focusedText={i18n.t('imageDrop')}
        >
          <StyledInput>
            <FileInput
              name="photo-upload"
              onChange={handleFileUpload}
              uploadProps={{
                accept,
                multiple: false,
              }}
            >
              <PrimaryButton
                id={`image-upload-button`}
                onClick={noop}
                icon={UploadIcon}
                defaultIconProps={{ height: spacing.medium }}
              >
                {buttonText}
              </PrimaryButton>
            </FileInput>
          </StyledInput>
        </DropZoneTarget>
      )}
      {!!src && (
        <StyledReactCrop
          keepSelection
          src={src}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          minWidth={minWidth}
          minHeight={minHeight}
          crop={crop}
          onImageLoaded={onImageLoaded}
          onChange={onCropChange}
          imageStyle={{ marginBottom: 0 }}
        />
      )}
    </StyledContainer>
  );
}

ImageUpload.defaultProps = {
  accept: 'image/png, image/jpeg',
  buttonText: 'Upload Image',
};

export default ImageUpload;
