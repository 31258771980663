export enum UploadType {
  Document = 'document',
  LoopPhoto = 'loop_photo',
  UserPhoto = 'user_photo',
  ProfilePhoto = 'profile_photo',
  RecrutingAdzPhoto = 'recruiting_adz_photo',
  ClientLogo = 'client_logo',
  AnnouncementPhoto = 'announcement_photo',
  BrandingLogo = 'branding_logo',
  DocumentLogo = 'document_logo',
  ProductAnnouncementCSV = 'product_announcement_csv',
  UserSignature = 'user_signature',
}
