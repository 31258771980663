import { ATTRIBUTE_TYPE } from '../constants';
import { ResourceDefinition } from '../types';

const ShareWorkflowStepTemplate: ResourceDefinition = {
  attributes: {
    templateStepId: ATTRIBUTE_TYPE.NUMBER,
    shareWorkflowTemplateId: ATTRIBUTE_TYPE.NUMBER,
    orderNum: ATTRIBUTE_TYPE.NUMBER,
    stepIntent: ATTRIBUTE_TYPE.STRING,
    emailAttachment: ATTRIBUTE_TYPE.BOOLEAN,
    stepMessage: ATTRIBUTE_TYPE.STRING,
    roleId: ATTRIBUTE_TYPE.NUMBER,
    profileId: ATTRIBUTE_TYPE.NUMBER,
    userId: ATTRIBUTE_TYPE.NUMBER,
    roleSelected: ATTRIBUTE_TYPE.BOOLEAN,
  },
};

export default ShareWorkflowStepTemplate;
