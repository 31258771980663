import { Component } from 'react';
import styled from 'styled-components';
import { zIndex } from '~/styles';
import Portal from '~/components/Portal';

import { colors } from '~/styles';

const mixins = {
  backgroundOverlayTransition: {
    transition: 'opacity 0.5s ease-in-out',
  },

  backgroundOverlayOpaque: {
    opacity: 0.5,
    pointerEvents: 'auto',
  },

  backgroundOverlayOpaqueTransition: {
    transition: 'opacity 0.5s ease-in-out',
  },
};

const StyledBackgroundOverlay = styled.div<{
  $backgroundOverlayTransitionEnabled: boolean;
  $backgroundOverlayOpaqueEnabled: boolean;
  $backgroundOverlayOpaqueTransitionEnabled: boolean;
}>(props => ({
  position: 'fixed',
  top: '0',
  right: '0',
  bottom: '0',
  left: '0',
  backgroundColor: colors.black,
  opacity: 0,
  pointerEvents: 'none',
  ...(props.$backgroundOverlayTransitionEnabled
    ? mixins.backgroundOverlayTransition
    : {}),
  ...(props.$backgroundOverlayOpaqueEnabled
    ? mixins.backgroundOverlayOpaque
    : {}),
  ...(props.$backgroundOverlayOpaqueTransitionEnabled
    ? mixins.backgroundOverlayOpaqueTransition
    : {}),
}));

type Props = {
  isVisible: boolean;
  shouldAnimate?: boolean;
  overlayRef?: (ref: HTMLElement | null) => void;
  onClick?: () => void;
  zIndex?: number;
  /** Needs to be proxied down in case this component is extended  */
  className?: string;
};

const noop = () => {};

class Overlay extends Component<Props> {
  static defaultProps = {
    shouldAnimate: true,
    zIndex: zIndex.overlayBackground,
  };

  setupRef = (ref: HTMLElement | null) => {
    if (this.props.overlayRef) {
      this.props.overlayRef(ref);
    }
  };

  render() {
    const { isVisible, shouldAnimate, onClick, zIndex, className } = this.props;
    const handleClick = onClick || noop;

    return (
      <Portal id="overlay-portal" forceAppendToBody>
        <StyledBackgroundOverlay
          className={className}
          ref={this.setupRef}
          onClick={handleClick}
          role="button"
          style={{ zIndex }}
          $backgroundOverlayTransitionEnabled={!isVisible && !!shouldAnimate}
          $backgroundOverlayOpaqueEnabled={isVisible}
          $backgroundOverlayOpaqueTransitionEnabled={
            isVisible && !!shouldAnimate
          }
        />
      </Portal>
    );
  }
}

export default Overlay;
