import { createLoadablePage } from '~/pages/utils/createLoadablePage';

const CompleteRegistrationContainer = createLoadablePage({
  loader: () =>
    import(
      /* webpackChunkName: "registration" */ './CompleteRegistrationContainer'
    ),
  i18nNamespace: 'registration',
  loading: () => null,
});

export default function CompleteRegistrationPage() {
  return <CompleteRegistrationContainer />;
}
