import { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import { I18nContext, getContextValue } from '~/components/i18n/context';
import { ContextType } from '~/components/i18n/types';

import i18n from '~/lib/i18n';
import { PreferenceNames } from '~/store/features/api/resources/preference/constants';
import LocalStorageService from '~/services/local-storage-service';
import { ResourceTypes } from '~/store/features/api/resources/types';
import { updateResource } from '~/store/features/api/apiSlice';

type Props = {
  children?: ReactNode;
  initialNamespaces?: Array<string>;
  setLanguagePreference: (language: string) => void;
};

type State = { isLoaded: boolean; contextValue?: ContextType };

function importDateFnsLocale(lang: string): Promise<unknown> {
  const code = lang.split('-')[0];

  if (code === 'es') {
    return import(/* webpackChunkName: "date-fns-es" */ 'date-fns/locale/es');
  } else {
    return Promise.resolve();
  }
}

export class IntlProvider extends Component<Props, State> {
  dateFnsLocale?: string;
  lastReportedLanguage?: string;

  state = {
    isLoaded: i18n.exists('navigation.loops'),
    contextValue: getContextValue(),
  };

  componentDidMount() {
    const { initialNamespaces } = this.props;

    i18n.on('languageChanged', this.onLanguageChanged);
    i18n.on('loaded', this.updateContextValue);
    i18n.on('added', this.updateContextValue);
    i18n.on('removed', this.updateContextValue);

    this.lastReportedLanguage = LocalStorageService.get(
      LocalStorageService.KEYS.NovaLanguage
    );

    if (!!initialNamespaces) {
      i18n.loadNamespaces(initialNamespaces);
    }
  }

  componentDidUpdate() {
    if (!!i18n.language && i18n.language !== this.lastReportedLanguage) {
      // We have a language set now and it is either the first language loaded
      // or is different than the last language we saved a preference for,
      // so set the user's preferred language
      LocalStorageService.set(
        LocalStorageService.KEYS.NovaLanguage,
        i18n.language
      );
      this.lastReportedLanguage = i18n.language;
      this.props.setLanguagePreference(i18n.language);
    }
  }

  onLanguageChanged = (lang: string) => {
    importDateFnsLocale(lang).then(locale => {
      this.dateFnsLocale = locale as string;
      this.updateContextValue();
    });
  };

  updateContextValue = () => {
    this.setState({
      isLoaded: true,
      contextValue: getContextValue(this.dateFnsLocale),
    });
  };

  render() {
    const { isLoaded, contextValue } = this.state;

    window.__i18n__ = i18n;

    if (!isLoaded) {
      return null;
    }

    return (
      <I18nContext.Provider value={contextValue}>
        {this.props.children}
      </I18nContext.Provider>
    );
  }
}

const mapDispatchToProps = {
  setLanguagePreference: (language: string) =>
    updateResource({
      resourceName: ResourceTypes.Preferences,
      resourceId: PreferenceNames.NovaLanguage,
      attributesToUpdate: { value: language },
      resourceRelationships: null,
      updateOptions: {
        isOptimistic: true,
        allowRollback: false,
      },
    }),
};

export default connect(null, mapDispatchToProps)(IntlProvider);
