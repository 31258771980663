const CSRF_COOKIE = 'XSRF-TOKEN';

export function getCookieByName(name: string) {
  const value = '; ' + document.cookie;
  const parts = value.split(`; ${name}=`);
  let result;

  if (!!parts && parts.length === 2) {
    const cookieValue = parts.pop();

    if (!!cookieValue) {
      result = cookieValue.split(';').shift();
    }
  }

  return result;
}

export function getCsrfCookie() {
  const csrf_cookie = getCookieByName(CSRF_COOKIE);

  return csrf_cookie;
}

export const XSRF_HEADER = 'X-XSRF-TOKEN';

export enum ComplexFilterType {
  Text = 'text',
  Number = 'number',
  Date = 'date',
  Exists = 'exists',
  Missing = 'missing',
  NumberGT = 'number-gt',
  NumberLT = 'number-lt',
  NumberGTE = 'number-gte',
  NumberLTE = 'number-lte',
  DateRange = 'date-range',
}
export enum ComplexFilterOperand {
  And = 'AND',
  Or = 'OR',
}

type ComplexFilter = {
  id: number;
  type: ComplexFilterType;
  value?: string | number;
};
export function buildComplexFilter({
  id,
  type,
  value = '',
}: ComplexFilter): string {
  const valueString = ComplexFilterType.Text === type ? `"${value}"` : value;
  return `${id}:${type}:${valueString}`;
}
export function combineComplexFilters(
  isFeatureEnabled: boolean,
  firstComplexFilter: string,
  operand: ComplexFilterOperand,
  secondComplexFilter: string
): string {
  return isFeatureEnabled
    ? `(${operand} ${firstComplexFilter} ${secondComplexFilter})`
    : `${firstComplexFilter},${secondComplexFilter}`;
}

export function combineComplexGroupFilters(
  isFeatureEnabled: boolean,
  filters: Array<string>,
  operand: ComplexFilterOperand
): string {
  return filters.length === 1
    ? filters[0]
    : isFeatureEnabled
    ? `(${operand} ${filters.join(' ')})`
    : `${filters.join(',')}`;
}

export function buildComplexDateRangeFilter(
  id: number,
  startDate: string,
  endDate: string
): string {
  return `${id}:${ComplexFilterType.DateRange}:${startDate}..${endDate}`;
}
