import styled from 'styled-components';
import { colors, spacing, fontSizes, utils, breakpoints } from '~/styles';

const StyledCountBadge = styled.span({
  background: colors.errorIcon,
  color: colors.white,
  textAlign: 'center',
  ...fontSizes.caption,
  lineHeight: spacing.small,
  marginLeft: spacing.smallest,
  borderRadius: utils.buttonBorderRadius,
  paddingLeft: `calc(${spacing.smallest} + 2px)`,
  paddingRight: `calc(${spacing.smallest} + 2px)`,
  paddingTop: `calc(${spacing.smallest} - 4px)`,
  paddingBottom: `calc(${spacing.smallest} - 4px)`,
  [breakpoints.MEDIUM]: {
    paddingLeft: spacing.smaller,
    paddingRight: spacing.smaller,
    paddingTop: spacing.smallest,
    paddingBottom: spacing.smallest,
  },
});

type CountBadgeProps = {
  count: number;
  countLimit?: number;
  className?: string;
};

export function CountBadge(props: CountBadgeProps) {
  const { count, countLimit, className } = props;
  const countDisplay =
    countLimit && count > countLimit ? `${countLimit}+` : count;

  return count > 0 ? (
    <StyledCountBadge className={className}>{countDisplay}</StyledCountBadge>
  ) : null;
}

export default CountBadge;
