import { useState, useRef, Fragment, ReactNode, KeyboardEvent } from 'react';
import styled from 'styled-components';

import { useI18n, useIsDesktop, useMedia, useOutsideClick } from '~/hooks';
import { useAccount } from '~/hooks/api/account';

import { useHasFeature } from '~/hooks/api/feature';
import { ProfileAvatar } from '~/components/Avatar';
import { ChevronIcon } from '~/components/icons';
import { RotationDirection } from '~/components/icons/types';
import { ProfileResource } from '~/store/features/api/resources/profile/types';
import { getProfileNameDetails } from '~/store/features/api/resources/profile/utils';
import {
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  screenSizes,
  spacing,
  utils,
} from '~/styles';

import AccountMenu from './AccountMenu';
import ProfileSettingsModal from './ProfileSettingsModal';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '~/store/hooks';
import { changeProfile } from '~/store/features/account/accountSlice';

const StyledAccountMenuToggle = styled.button({
  display: 'flex',
  alignItems: 'center',
  flex: '1 1 auto',
  height: '100%',
  margin: 0,
  padding: 0,
  border: 'none',
  background: 'none',
  textAlign: 'left',
  cursor: 'pointer',

  [breakpoints.LARGE]: {
    height: 'auto',
    width: '215px',
    padding: `${spacing.smaller} ${spacing.normal}`,
    border: `2px solid ${colors.lightBorder}`,
    borderRadius: utils.baseBorderRadius,

    '&:hover, &:focus': {
      outline: 'none',
      borderColor: colors.primaryAction,
    },
  },
});

const StyledAccountContainer = styled.div({
  position: 'relative',
  height: '100%',
});

const StyledChevronIndicator = styled.div({
  flex: `0 0 ${spacing.normal}`,
  width: spacing.normal,
});

const StyledCurrentProfileSubtitle = styled.div({
  ...fontSizes.caption,
  ...utils.text.truncate,
  lineHeight: spacing.normal,
  marginTop: `-${spacing.smallest}`,
  color: colors.secondaryText,
});

const StyledCurrentProfileTitle = styled.div({
  ...fontSizes.callout,
  ...utils.text.truncate,
  fontWeight: fontWeights.semiBold,
});

const StyledCurrentProfileDetails = styled.div({
  flex: '1 1 auto',
  marginLeft: spacing.small,
  paddingRight: spacing.smallest,
  overflow: 'hidden',
});

const StyledAvatarContainer = styled.div({
  flex: `0 0 ${spacing.max}`,
  width: spacing.max,
  height: spacing.max,

  [breakpoints.LARGE]: {
    flex: `0 0 ${spacing.largest}`,
    width: spacing.largest,
    height: spacing.largest,
  },
});

const StyledCurrentProfileContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

interface ContentsProps {
  isAccountMenuOpen: boolean;
  profile: ProfileResource;
}
function ToggleButtonContents({ profile, isAccountMenuOpen }: ContentsProps) {
  const isLarge = useMedia({ minWidth: screenSizes.LARGE });
  const { title, subtitle } = getProfileNameDetails(profile);

  return (
    <StyledCurrentProfileContainer>
      <StyledAvatarContainer>
        <ProfileAvatar profile={profile} />
      </StyledAvatarContainer>
      {isLarge && (
        <Fragment>
          <StyledCurrentProfileDetails>
            <StyledCurrentProfileTitle>{title}</StyledCurrentProfileTitle>
            {!!subtitle && (
              <StyledCurrentProfileSubtitle>
                {subtitle}
              </StyledCurrentProfileSubtitle>
            )}
          </StyledCurrentProfileDetails>
          <StyledChevronIndicator>
            <ChevronIcon
              width="100%"
              type="secondaryText"
              direction={
                isAccountMenuOpen
                  ? RotationDirection.Up
                  : RotationDirection.Down
              }
            />
          </StyledChevronIndicator>
        </Fragment>
      )}
    </StyledCurrentProfileContainer>
  );
}

interface Props {
  menuToggle?: ReactNode;
}

export function Account(props: Props) {
  const { userId, firstName, lastName, currentProfile } = useAccount();
  const hasManageBranding = useHasFeature('MANAGE_BRANDING');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const i18n = useI18n();
  const isDesktop = useIsDesktop();
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [profileSettingsId, setProfileSettingsId] = useState<string | null>(
    null
  );
  const elementRef = useRef(null);
  const fullName = `${firstName ?? ''} ${lastName ?? ''}`;

  const toggleAccountMenu = () => {
    setIsAccountMenuOpen(!isAccountMenuOpen);
  };

  const closeAccountMenu = () => {
    setIsAccountMenuOpen(false);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      closeAccountMenu();
    }
  };

  const updateCurrentProfile = async (newCurrentProfile: ProfileResource) => {
    if (!currentProfile || currentProfile.id !== newCurrentProfile.id) {
      setIsAccountMenuOpen(false);
      await dispatch(
        changeProfile({
          profileId: newCurrentProfile.id,
          onChange: () => {
            // Redirect to the loops page after a successful profile change, but before
            // we update the main content loading section, if the user is on a different page
            if (window.location.pathname !== '/loops') {
              navigate('/loops', { state: { profileChanged: true } });
            }
          },
        })
      );
    }
  };

  const handleProfileSettingSelected = (profile: ProfileResource) => {
    setProfileSettingsId(profile.id);
    setIsAccountMenuOpen(!isDesktop);

    return false;
  };

  useOutsideClick(
    elementRef,
    () => {
      if (isAccountMenuOpen) {
        closeAccountMenu();
      }

      return true;
    },
    { additionalSelectors: ['#account-portal'] }
  );

  return (
    <StyledAccountContainer ref={elementRef}>
      <StyledAccountMenuToggle
        id="account-menu-toggle"
        onKeyDown={handleKeyDown}
        onClick={toggleAccountMenu}
        aria-haspopup
        aria-expanded={isAccountMenuOpen}
        aria-label={i18n.t(
          isAccountMenuOpen
            ? 'account.closeAccountMenu'
            : 'account.openAccountMenu'
        )}
      >
        {props.menuToggle ??
          (!!currentProfile && (
            <ToggleButtonContents
              profile={currentProfile}
              isAccountMenuOpen={isAccountMenuOpen}
            />
          ))}
      </StyledAccountMenuToggle>

      {hasManageBranding && (
        <ProfileSettingsModal
          profileId={profileSettingsId}
          onClose={() => setProfileSettingsId(null)}
        />
      )}

      <AccountMenu
        isOpen={isAccountMenuOpen}
        userId={userId}
        fullName={fullName}
        currentProfile={currentProfile}
        hasManageBranding={hasManageBranding}
        onCurrentProfileChanged={updateCurrentProfile}
        onProfileSettingSelected={handleProfileSettingSelected}
        openStateChanged={setIsAccountMenuOpen}
      />
    </StyledAccountContainer>
  );
}

export default Account;
