import { ButtonHTMLAttributes } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  link?: string;
}

type HelperFunction = (styles: object, props: ButtonProps) => object;
type ButtonStyleHelpers = {
  focus: HelperFunction;
  hover: HelperFunction;
  active: HelperFunction;
};

export const buttonStyleHelpers: ButtonStyleHelpers = {
  focus: (cssProps, buttonProps) => {
    return buttonProps.disabled
      ? {}
      : !!buttonProps.link
      ? {
          '&:focus': { ...cssProps },
        }
      : {
          '&:focus:enabled': { ...cssProps },
        };
  },
  hover: (cssProps, buttonProps) => {
    return buttonProps.disabled
      ? {}
      : !!buttonProps.link
      ? {
          '&:hover': { ...cssProps },
        }
      : {
          '&:hover:enabled': { ...cssProps },
        };
  },
  active: (cssProps, buttonProps) => {
    return buttonProps.disabled
      ? {}
      : !!buttonProps.link
      ? {
          '&:active': { ...cssProps },
        }
      : {
          '&:active:enabled': { ...cssProps },
        };
  },
};
