import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from '~/components/i18n';

import { AppModal } from '~/components/Modal';
import { RadioButton, RadioButtonGroup } from '~/components/inputs/radio';
import { spacing } from '~/styles';

import { selectAccountData } from '~/store/features/account/selectors';

import { PreferenceNames } from '~/store/features/api/resources/preference/constants';
import { makePreferenceSelector } from '~/store/features/api/selectors/preference';
import { ProfileType } from '~/store/features/api/resources/profile/constants';
import { ProfileResource } from '~/store/features/api/resources/profile/types';
import { AppState } from '~/store';
import { ResourceTypes } from '~/store/features/api/resources/types';
import { useProfiles } from '~/hooks/api/profile';
import { useI18n } from '~/hooks';

import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { updateResource } from '~/store/features/api/apiSlice';
import { changeProfile } from '~/store/features/account/accountSlice';

const StyledRadioButton = styled.div({
  marginTop: spacing.small,
});

const StyledRadioButtonContainer = styled.div({
  marginTop: spacing.normal,
});

const StyledItalic = styled.span({
  fontStyle: 'italic',
});

function isArrayWithEntries(collection: Array<any>) {
  return Array.isArray(collection) && collection.length > 0;
}

type NewlyAddedProfileType = {
  profileDisplayName: string | undefined;
  profileListDisplayName: string | undefined | JSX.Element;
  profileRadioDisplayName: JSX.Element;
  profileDisplayType: string | undefined;
} & ProfileResource;

function DefaultProfileSelection() {
  const i18n = useI18n();
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [selectedProfileId, setSelectedProfileId] = useState<string | null>(
    null
  );
  const selectNewlyAddedProfilesByAdmin = useMemo(() => {
    return makePreferenceSelector(PreferenceNames.NewlyAddedProfilesByAdmin);
  }, []);
  const newlyAddedProfilesByAdmin = useAppSelector((state: AppState) =>
    selectNewlyAddedProfilesByAdmin(state)
  );
  const account = useAppSelector((state: AppState) => selectAccountData(state));
  const [isFetchingProfiles, profiles, fetchAllProfiles] = useProfiles();
  const switchCurrentProfile = useCallback(
    (profile: ProfileResource) => {
      return dispatch(changeProfile({ profileId: profile.id }));
    },
    [dispatch]
  );
  const updateDefaultProfile = useCallback(
    (userId: string, profileId: string) => {
      return dispatch(
        updateResource({
          resourceName: ResourceTypes.Users,
          resourceId: userId,
          attributesToUpdate: {
            defaultProfileId: profileId,
          },
        })
      );
    },
    [dispatch]
  );
  const clearNewlyAddedProfiles = useCallback(() => {
    return dispatch(
      updateResource({
        resourceName: ResourceTypes.Preferences,
        resourceId: PreferenceNames.NewlyAddedProfilesByAdmin,
        attributesToUpdate: {
          id: PreferenceNames.NewlyAddedProfilesByAdmin,
          value: [],
        },
        resourceRelationships: null,
        updateOptions: {
          isOptimistic: true,
          allowRollback: false,
        },
      })
    );
  }, [dispatch]);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const skipSettingAsDefault = async () => {
    await clearNewlyAddedProfiles();
    handleClose();
  };

  const setProfileAsDefault = async (newDefaultProfile: ProfileResource) => {
    await updateDefaultProfile(account.userId.toString(), newDefaultProfile.id);
    await clearNewlyAddedProfiles();
    await switchCurrentProfile(newDefaultProfile);
    handleClose();
  };

  const newlyAddedProfilesByAdminValue = useMemo(() => {
    return newlyAddedProfilesByAdmin?.value ?? [];
  }, [newlyAddedProfilesByAdmin]);
  const hasAddedProfiles = isArrayWithEntries(newlyAddedProfilesByAdminValue);
  const hasLoadedProfiles = isArrayWithEntries(profiles);

  const newlyAddedProfiles = useMemo(() => {
    return !hasAddedProfiles
      ? []
      : profiles.reduce(
          (accum: Array<NewlyAddedProfileType>, current: ProfileResource) => {
            const foundProfile = newlyAddedProfilesByAdminValue.some(
              (p: number) => p === parseInt(current.id, 10)
            );

            if (foundProfile) {
              const isIndividualProfile =
                !!current.profileType &&
                current.profileType === ProfileType.Individual;
              const profileName = isIndividualProfile
                ? current.directParentProfileName
                : current.name;
              const profileListName = isIndividualProfile ? (
                current.directParentProfileName
              ) : (
                <FormattedMessage
                  id="account.adminProfileName"
                  values={{ name: current.name }}
                />
              );
              const profileRadioName = isIndividualProfile ? (
                <span>
                  {current.name}
                  &nbsp;&ndash;&nbsp;
                  <StyledItalic>
                    ({current.directParentProfileName})
                  </StyledItalic>
                </span>
              ) : (
                <FormattedMessage
                  id="account.adminProfileName"
                  values={{ name: current.name }}
                />
              );
              let profileType = isIndividualProfile
                ? current.directParentProfileType
                : current.profileType;

              if (!profileType) {
                profileType = '';
              } else {
                profileType = profileType.toLowerCase();
              }
              const profileInfo = {
                ...current,
                profileDisplayName: profileName,
                profileListDisplayName: profileListName,
                profileRadioDisplayName: profileRadioName,
                profileDisplayType: profileType,
              };

              accum.push(profileInfo);
            }

            return accum;
          },
          []
        );
  }, [hasAddedProfiles, profiles, newlyAddedProfilesByAdminValue]);

  const hasNewlyAddedProfiles = isArrayWithEntries(newlyAddedProfiles);
  const selectedProfile = hasNewlyAddedProfiles
    ? newlyAddedProfiles.find(p => p.id === selectedProfileId)
    : undefined;

  useEffect(() => {
    if (hasAddedProfiles && !hasLoadedProfiles && !isFetchingProfiles) {
      fetchAllProfiles();
    }
  }, [
    hasAddedProfiles,
    hasLoadedProfiles,
    isFetchingProfiles,
    fetchAllProfiles,
  ]);

  useEffect(() => {
    if (!selectedProfileId && hasNewlyAddedProfiles) {
      setSelectedProfileId(newlyAddedProfiles[0].id);
    }
  }, [hasNewlyAddedProfiles, newlyAddedProfiles, selectedProfileId]);

  if (!hasAddedProfiles || !hasLoadedProfiles || !hasNewlyAddedProfiles) {
    return null;
  }

  return (
    <AppModal
      dismissable={false}
      name="default-profile-selection-modal"
      isOpen={isModalOpen}
      title={i18n.t('setProfileAsDefault.title')}
      formName="choose-default-profile"
      onRequestClose={handleClose}
      cancelAction={skipSettingAsDefault}
      primaryAction={{
        labelId: 'setProfileAsDefault.primaryAction',
        onClick: () => {
          if (selectedProfile) {
            setProfileAsDefault(selectedProfile);
          }
        },
      }}
    >
      {newlyAddedProfiles.length === 1 ? (
        <FormattedMessage
          id="setProfileAsDefault.content"
          values={{
            profileName: newlyAddedProfiles[0].profileDisplayName,
            profileType: newlyAddedProfiles[0].profileDisplayType,
          }}
        />
      ) : (
        <Fragment>
          <FormattedMessage
            id="setProfileAsDefault.contentMultipleProfiles"
            values={{
              newProfiles: (
                <Fragment>
                  {newlyAddedProfiles.map((p, index) => (
                    <Fragment key={index}>
                      <span>{p.profileListDisplayName}</span>
                      {index !== newlyAddedProfiles.length - 1 && (
                        <span>{', '}</span>
                      )}
                    </Fragment>
                  ))}
                </Fragment>
              ),
            }}
          />

          <StyledRadioButtonContainer>
            <RadioButtonGroup
              name="profile"
              selectedValue={selectedProfileId}
              onChange={profileId => setSelectedProfileId(profileId)}
            >
              {newlyAddedProfiles.map(profile => (
                <StyledRadioButton key={profile.id}>
                  <RadioButton
                    value={profile.id}
                    label={profile.profileRadioDisplayName}
                  />
                </StyledRadioButton>
              ))}
            </RadioButtonGroup>
          </StyledRadioButtonContainer>
        </Fragment>
      )}
    </AppModal>
  );
}

export default DefaultProfileSelection;
