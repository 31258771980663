import { ResourceDefinition } from '~/store/features/api/resources/types';
import { ATTRIBUTE_TYPE } from '~/store/features/api/resources/constants';

const notification: ResourceDefinition = {
  attributes: {
    deleted: ATTRIBUTE_TYPE.BOOLEAN,
    read: ATTRIBUTE_TYPE.BOOLEAN,
  },
};

export default notification;
