import { ATTRIBUTE_TYPE } from '../constants';

import { ResourceDefinition } from '../types';

const User: ResourceDefinition = {
  attributes: {
    defaultProfileId: ATTRIBUTE_TYPE.NUMBER,
    countryCodeId: ATTRIBUTE_TYPE.NUMBER,
    timezoneId: ATTRIBUTE_TYPE.NUMBER,
    nrdsAgentId: ATTRIBUTE_TYPE.STRING,
    firstName: ATTRIBUTE_TYPE.STRING,
    lastName: ATTRIBUTE_TYPE.STRING,
    displayName: ATTRIBUTE_TYPE.STRING,
    displayInitials: ATTRIBUTE_TYPE.STRING,
    address01: ATTRIBUTE_TYPE.STRING,
    address02: ATTRIBUTE_TYPE.STRING,
    addressSuite: ATTRIBUTE_TYPE.STRING,
    addressCity: ATTRIBUTE_TYPE.STRING,
    state: ATTRIBUTE_TYPE.STRING,
    addressZipcode: ATTRIBUTE_TYPE.STRING,
    mobileNumber: ATTRIBUTE_TYPE.STRING,
    faxNumber: ATTRIBUTE_TYPE.STRING,
    tagline: ATTRIBUTE_TYPE.STRING,
  },
};

export default User;
