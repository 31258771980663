import { createSelector } from 'reselect';
import { ProfileType } from '~/store/features/api/resources/profile/constants';

import { AppState } from '~/store';
import { ProfileResource } from '~/store/features/api/resources/profile/types';

import { AccountInfo, NavigationAccessType } from './types';

export type AccountData = {
  currentProfile: ProfileResource | null;
} & AccountInfo;

export const selectAccountData = createSelector(
  (state: AppState) => state.account,
  account => {
    const {
      userId,
      firstName,
      lastName,
      emailVerified,
      registrationComplete,
      loopLimit,
      loopCount,
      currentProfile,
      isAssumingControl,
      userFeatures,
      individualProfileCount,
      novaOptInPreferences,
      userRoleId,
      pendo,
      nrdsId,
      tagLine,
      jobTitle,
      minLoopCreatedDate,
    } = account;

    return {
      userId,
      firstName,
      lastName,
      emailVerified,
      registrationComplete,
      loopLimit,
      loopCount,
      currentProfile,
      isAssumingControl,
      userFeatures,
      individualProfileCount,
      novaOptInPreferences,
      userRoleId,
      pendo,
      nrdsId,
      tagLine,
      jobTitle,
      minLoopCreatedDate,
    };
  }
);

export function selectCurrentProfile(state: AppState): ProfileResource | null {
  return state.account.currentProfile;
}

export function isAccountVerified(state: AppState): boolean {
  const accountData = selectAccountData(state);

  return accountData ? accountData.emailVerified : false;
}

export function isCurrentProfileAdmin(state: AppState): boolean {
  const currentProfile = selectCurrentProfile(state);
  const { userId } = selectAccountData(state);

  if (currentProfile) {
    return (
      currentProfile.profileType !== ProfileType.Individual ||
      currentProfile.primaryUserId !== userId
    );
  }

  return false;
}

export function isTeamAdminAssumingControlOfMember(state: AppState): boolean {
  const currentProfile = selectCurrentProfile(state);
  const { userId } = selectAccountData(state);

  if (currentProfile) {
    return (
      currentProfile.directParentProfileType === ProfileType.Team &&
      currentProfile.primaryUserId !== userId
    );
  }

  return false;
}

export function getLoopsRemaining(state: AppState): number {
  const accountData = selectAccountData(state);
  let result = -1;

  if (accountData) {
    const { loopLimit, loopCount = 0 } = accountData;

    if (loopLimit !== -1) {
      result = Math.max(0, loopLimit - loopCount);
    }
  }

  return result;
}

export function doesUserHaveLoopsRemaining(state: AppState): boolean {
  const loopsRemaining = getLoopsRemaining(state);

  return loopsRemaining === -1 || loopsRemaining > 0;
}

export function hasFeature(state: AppState, feature: string): boolean {
  const { currentProfile, userFeatures } = selectAccountData(state);
  let hasFeature = false;

  if (currentProfile) {
    //First, check active profile if they have permission
    hasFeature =
      !!currentProfile.features &&
      !!currentProfile.features.find(f => f === feature);

    if (!hasFeature) {
      // If they don't have the feature on the current Profile, check account level
      hasFeature = userFeatures && !!userFeatures.find(f => f === feature);
    }
  }

  return hasFeature;
}

export function hasPermission(state: AppState, permission: string): boolean {
  const { currentProfile } = selectAccountData(state);
  let hasPermission = false;

  if (!!currentProfile) {
    hasPermission =
      !!currentProfile.permissions &&
      !!currentProfile.permissions.find(p => p === permission);
  }

  return hasPermission;
}

export function selectIsAssumingControl(state: AppState): boolean {
  const { isAssumingControl } = selectAccountData(state);

  return isAssumingControl;
}

export function doesUserHaveSingleProfile(state: AppState): boolean {
  return state.account.individualProfileCount === 1;
}

export function doesUserHavePremium(state: AppState): boolean {
  return hasFeature(state, 'PREMIUM');
}

const DASHBOARD_FEATURE = 'VIEW_CHARTS';
const ZILLOW_OFFER_FEATURE = 'ZILLOW_OFFER';
const LOOPS_PERMISSION = 'MANAGE_LOOPS';
const PEOPLE_PERMISSION = 'MANAGE_PEOPLE';
const TEMPLATES_PERMISSION = 'MANAGE_TEMPLATES';

export function selectNavigationAccess(state: AppState): NavigationAccessType {
  const { currentProfile } = selectAccountData(state);
  const permissions = currentProfile ? currentProfile.permissions : [];
  const hasLoops = permissions.includes(LOOPS_PERMISSION);
  const hasPeople = permissions.includes(PEOPLE_PERMISSION);
  const hasTemplates = permissions.includes(TEMPLATES_PERMISSION);
  const hasReporting = hasFeature(state, DASHBOARD_FEATURE);
  const hasTasks = !hasFeature(state, ZILLOW_OFFER_FEATURE);

  return {
    hasReporting,
    hasLoops,
    hasPeople,
    hasTemplates,
    hasTasks,
  };
}

export function getUserRoleId(state: AppState): number {
  const { userRoleId = 0 } = selectAccountData(state);
  return userRoleId;
}
