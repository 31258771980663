import { ResourceDefinition } from '~/store/features/api/resources/types';
import { ATTRIBUTE_TYPE } from '~/store/features/api/resources/constants';

const ComplianceMessageRecipient: ResourceDefinition = {
  attributes: {
    id: ATTRIBUTE_TYPE.STRING,
    memberId: ATTRIBUTE_TYPE.NUMBER,
    userId: ATTRIBUTE_TYPE.NUMBER,
    profileId: ATTRIBUTE_TYPE.NUMBER,
    name: ATTRIBUTE_TYPE.STRING,
    profileName: ATTRIBUTE_TYPE.STRING,
    roleName: ATTRIBUTE_TYPE.STRING,
    isAdmin: ATTRIBUTE_TYPE.BOOLEAN,
    profileTypeId: ATTRIBUTE_TYPE.NUMBER,
    invitationId: ATTRIBUTE_TYPE.NUMBER,
    emailAddress: ATTRIBUTE_TYPE.STRING,
    isMe: ATTRIBUTE_TYPE.BOOLEAN,
    imageUrl: ATTRIBUTE_TYPE.STRING,
  },
};

export default ComplianceMessageRecipient;
