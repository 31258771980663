import { useEffect, useState } from 'react';
import { MODAL_PORTAL_ID } from '~/components/Modal/BaseModal';

export function useIsInsideModal(el: HTMLElement | null | undefined) {
  const [insideModal, setInsideModal] = useState(false);

  useEffect(() => {
    if (!!el) {
      const isInModal = el.closest(`#${MODAL_PORTAL_ID}`) !== null;

      setInsideModal(isInModal);
    }
  }, [el]);

  return insideModal;
}
