enum phoenixBaseUrlLookup {
  local = 'http://localhost:8080',
  dev = 'https://www.dev-dotloop.com',
  qa = 'https://www.qa-dotloop.com',
  stage = 'https://www.stage-dotloop.com',
  test = 'https://test.dotloop.com',
  prod = 'https://www.dotloop.com',
}

export default phoenixBaseUrlLookup;
