import { RefObject, useCallback, useEffect } from 'react';
export interface OutsideClickOptions {
  additionalSelectors?: Array<string>;
}

type ElementRef = RefObject<HTMLElement> | HTMLElement | undefined | null;
export function useOutsideClick(
  elementRef: ElementRef,
  onOutsideClick: (e: MouseEvent | TouchEvent) => any,
  options: OutsideClickOptions = {}
): ElementRef {
  const { additionalSelectors } = options;

  const handleDocumentClick = useCallback(
    (e: MouseEvent | TouchEvent) => {
      const { target } = e;
      let element: HTMLElement | null = null;
      if (elementRef) {
        if ('current' in elementRef && elementRef.current) {
          element = elementRef.current;
        } else {
          element = elementRef as HTMLElement;
        }
      }

      if (!!element && target instanceof HTMLElement) {
        const containsTarget = (node: Element) =>
          !!node && node.contains(target);
        const existsInDomNode = containsTarget(element);
        let existsInAdditionalDomNodes = false;

        if (!existsInDomNode && !!additionalSelectors) {
          existsInAdditionalDomNodes = additionalSelectors.some(selector => {
            let doesContainTarget = false;
            const domNodes = document.querySelectorAll(selector);

            for (let i = 0; i < domNodes.length; i++) {
              const domNode = domNodes[i];
              doesContainTarget = containsTarget(domNode);

              if (doesContainTarget) {
                break;
              }
            }

            return doesContainTarget;
          });
        }

        if (!existsInDomNode && !existsInAdditionalDomNodes) {
          onOutsideClick(e);
        }
      }
    },
    [elementRef, additionalSelectors, onOutsideClick]
  );

  useEffect(() => {
    window.document.addEventListener('mousedown', handleDocumentClick, true);
    window.document.addEventListener('touchstart', handleDocumentClick, true);

    return () => {
      window.document.removeEventListener(
        'mousedown',
        handleDocumentClick,
        true
      );
      window.document.removeEventListener(
        'touchstart',
        handleDocumentClick,
        true
      );
    };
  }, [handleDocumentClick]);

  return elementRef;
}
