import styled from 'styled-components';

import { colors, fontWeights, spacing } from '~/styles';

import { TextMessageResource } from '~/store/features/api/resources/message/types';

const mixins = {
  messageSentByMe: {
    marginLeft: 'auto',
    color: colors.black,
    backgroundColor: colors.darkBackground,
  },
};

const StyledMessageText = styled.div<{
  $messageSentByMeEnabled: boolean;
}>(props => ({
  padding: `${spacing.smaller} ${spacing.small}`,
  fontWeight: fontWeights.semiBold,
  color: colors.primaryBackgroundText,
  backgroundColor: colors.primaryLinkFocus,
  borderRadius: '22px',
  ...(props.$messageSentByMeEnabled ? mixins.messageSentByMe : {}),
}));

const StyledMessageContainer = styled.div({
  display: 'flex',
});

export default function TextMessage({
  message,
  sentByMe,
}: {
  message: TextMessageResource;
  sentByMe: boolean;
}) {
  const { text } = message;

  return (
    <StyledMessageContainer data-testid="conversation-text-message">
      <StyledMessageText $messageSentByMeEnabled={sentByMe}>
        {text.split('\n').map((line, index) => (
          <div key={index}>{line || <span>&nbsp;</span>}</div>
        ))}
      </StyledMessageText>
    </StyledMessageContainer>
  );
}
