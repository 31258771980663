import { memo } from 'react';
import styled from 'styled-components';
import { breakpoints } from '~/styles';

type FlexAlign = 'flex-start' | 'flex-end';
type AlignItems = FlexAlign | 'center' | 'baseline' | 'stretch';
type StyleProps = {
  $minGridItemWidth?: string;
  $gridGap?: string;
  $gridRepeatMode: 'auto-fit' | 'auto-fill';
  $rowReverse?: boolean;
  $columnReverse?: boolean;
  $noGutter?: boolean;
  $height?: string;
  $textAlign?: 'left' | 'center' | 'right';
  $verticalAlignXSm?: AlignItems;
  $verticalAlignSm?: AlignItems;
  $verticalAlignMd?: AlignItems;
  $verticalAlignLg?: AlignItems;
  $verticalAlignXLg?: AlignItems;
};

const StyledContainer = styled.div<StyleProps>(props => {
  const DEFAULT_VALIGN = props.$verticalAlignXSm || 'flex-start';
  const SMALL_VALIGN = props.$verticalAlignSm || DEFAULT_VALIGN;
  const MEDIUM_VALIGN = props.$verticalAlignMd || SMALL_VALIGN;
  const LARGE_VALIGN = props.$verticalAlignLg || MEDIUM_VALIGN;
  const XLARGE_VALIGN = props.$verticalAlignXLg || LARGE_VALIGN;
  const height = props.$height || 'auto';
  const margin = props.$noGutter ? '0' : '0 -0.5em';
  const textAlign = props.$textAlign || 'left';
  const gridGap = props.$gridGap || '1rem';
  const gridRepeatMode = props.$gridRepeatMode;
  const gridTemplateColumnSize = props.$minGridItemWidth
    ? `minmax(${props.$minGridItemWidth}, 1fr)`
    : `100%`;
  const gridTemplateMediaQuery = props.$minGridItemWidth
    ? {
        gridTemplateColumns: `repeat(${gridRepeatMode}, minmax(100%, 1fr))`,
      }
    : {};

  let flexDirection = 'row';

  if (props.$rowReverse) {
    flexDirection = 'row-reverse';
  } else if (props.$columnReverse) {
    flexDirection = 'column-reverse';
  }

  return {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    flex: '0 1 auto',
    flexDirection,
    margin,
    height,

    //default (mobile)
    textAlign: textAlign,
    alignItems: DEFAULT_VALIGN,

    //small
    [breakpoints.SMALL]: {
      alignItems: SMALL_VALIGN,
    },

    //medium
    [breakpoints.MEDIUM]: {
      alignItems: MEDIUM_VALIGN,
    },

    //large
    [breakpoints.LARGE]: {
      alignItems: LARGE_VALIGN,
    },

    //extra large
    [breakpoints.XLARGE]: {
      alignItems: XLARGE_VALIGN,
    },

    ['@supports (display: grid)']: {
      display: 'grid',
      gridTemplateColumns: `repeat(
        ${gridRepeatMode},
        ${gridTemplateColumnSize}
      )`,
      gridGap,
      margin: 0,
    },

    [`@media (max-width: ${props.$minGridItemWidth})`]: {
      ...gridTemplateMediaQuery,
    },
  };
});

type Props = {
  /** Minimum width of GridItems */
  minGridItemWidth?: string;
  /** Gap between grid items */
  gridGap?: string;
  /** CSS Grid ;epeate mode */
  gridRepeatMode: 'auto-fit' | 'auto-fill';
  /** Reverse the flexbox row direction */
  rowReverse?: boolean;
  /** Reverse the flexbox column direction */
  columnReverse?: boolean;
  /** Removes the negative margin that accounts for the gutter of GridItem components */
  noGutter?: boolean;
  /** Statically defined height of the GridLayout */
  height?: string;
  /** CSS text-align property. */
  textAlign?: 'left' | 'center' | 'right';
  /** Vertical flex item alignment on extra small screens and up */
  verticalAlignXSm?: AlignItems;
  /** Vertical flex item alignment on small screens and up */
  verticalAlignSm?: AlignItems;
  /** Vertical flex item alignment on medium screens and up */
  verticalAlignMd?: AlignItems;
  /** Vertical flex item alignment on large screens and up */
  verticalAlignLg?: AlignItems;
  /** Vertical flex item alignment on extra large screens and up */
  verticalAlignXLg?: AlignItems;
  /** Contents of this GridLayout */
  children: React.ReactNode;
  /** Needs to be proxied down in case this component is extended  */
  className?: string;
  /** Callback of the element that is mounted */
  refCallback?: (element: Element | null | undefined) => void;
  /** TestID to use for the layout */
  'data-testid'?: string;
};

const noop = () => {};

function GridLayout({
  minGridItemWidth,
  gridGap,
  gridRepeatMode,
  rowReverse,
  columnReverse,
  noGutter,
  height,
  textAlign,
  verticalAlignXSm,
  verticalAlignSm,
  verticalAlignMd,
  verticalAlignLg,
  verticalAlignXLg,
  children,
  className,
  refCallback = noop,
  'data-testid': dataTestId,
}: Props) {
  return (
    <StyledContainer
      $minGridItemWidth={minGridItemWidth}
      $gridGap={gridGap}
      $gridRepeatMode={gridRepeatMode}
      $rowReverse={rowReverse}
      $columnReverse={columnReverse}
      $noGutter={noGutter}
      $height={height}
      $textAlign={textAlign}
      $verticalAlignXSm={verticalAlignXSm}
      $verticalAlignSm={verticalAlignSm}
      $verticalAlignMd={verticalAlignMd}
      $verticalAlignLg={verticalAlignLg}
      $verticalAlignXLg={verticalAlignXLg}
      className={className}
      ref={refCallback}
      data-testid={dataTestId}
    >
      {children}
    </StyledContainer>
  );
}

GridLayout.defaultProps = {
  textAlign: 'left',
  verticalAlignXSm: 'flex-start',
  gridRepeatMode: 'auto-fill',
  refCallback: noop,
};

export default memo(GridLayout);
