import scalableIcon from '~/components/icons/utils/scalableIcon';

function NoResultsIllustration() {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="-330 -80 1204 922"
      xmlSpace="preserve"
    >
      <g id="Group-3_1_" transform="translate(0.000000, 107.000000)">
        <g id="Clip-2_1_" />
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter"
            filterUnits="userSpaceOnUse"
            x="266.443"
            y="669.097"
            width="576.104"
            height="16.597"
          >
            <feColorMatrix
              type="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
            />
          </filter>
        </defs>
        <mask
          maskUnits="userSpaceOnUse"
          x="266.443"
          y="669.097"
          width="576.104"
          height="16.597"
          id="mask-2_1_"
        >
          <g style={{ filter: 'url(#Adobe_OpacityMaskFilter)' }}>
            <polygon
              id="path-1_1_"
              style={{ fill: '#FFFFFF' }}
              points="266.442,669.097 842.547,669.097 842.547,685.694 266.442,685.694"
            />
          </g>
        </mask>
        <path
          id="Fill-1_1_"
          style={{ mask: 'url(#mask-2_1_)', fill: '#EAEBEC' }}
          d="M842.547,677.396c0,4.583-128.966,8.298-288.053,8.298
        		c-159.086,0-288.052-3.715-288.052-8.298c0-4.583,128.966-8.298,288.052-8.298C713.581,669.097,842.547,672.813,842.547,677.396"
        />
      </g>
      <path
        style={{ opacity: 0.51, fill: '#65CCF0' }}
        d="M408.393,505.451c13.026,2.96,25.876,8.396,39.191,7.324
      	c14.247-1.148,27.083-9.651,41.312-10.996c21.672-2.048,41.469,12.636,63.112,14.968c11.625,1.253,23.291-1.113,34.835-2.975
      	c38.221-6.165,77.084-6.876,115.792-7.573c13.327-0.24,28.125,0.056,37.839,9.184c7.505,7.052,10.106,17.758,12.053,27.871
      	c6.45,33.495,9.58,68.326,1.88,101.555c-7.7,33.23-27.505,64.864-57.765,80.606c-37.429,19.472-82.403,11.91-124.34,7.281
      	c-29.815-3.291-59.823-4.841-89.819-4.638c-30.482,0.206-62.911,1.757-89.204-13.664c-21.882-12.834-36.193-35.989-43.443-60.299
      	c-7.25-24.308-8.208-49.989-8.815-75.348C340.075,539.268,360.949,494.669,408.393,505.451z"
      />
      <polygon
        style={{ fill: '#8CD7F7' }}
        points="367.5,698.041 728.5,697 715.893,724.593 688.245,764.682 663.054,767.806 435.893,774.574
      	397.893,732.923 "
      />
      <path
        style={{ fill: 'none' }}
        d="M665.23,665.711c2.793,4.875,4.754,10.289,5.672,16.289h24.418c-3.175-10-12.425-17.125-23.32-17.125
      	C669.688,664.875,667.414,665.082,665.23,665.711z"
      />
      <path
        style={{ fill: '#46A0D8' }}
        d="M-309.974,236.058c0.338-8.545,3.983-16.446,10.263-22.249
      	c31.294-28.907,71.993-44.828,114.597-44.828c20.121,0,39.823,3.521,58.561,10.465c5.366,1.989,8.103,7.951,6.115,13.317
      	c-1.988,5.364-7.945,8.101-13.316,6.114c-16.425-6.087-33.704-9.174-51.359-9.174c-37.376,0-73.08,13.966-100.535,39.325
      	c-2.215,2.046-3.498,4.833-3.619,7.847c-0.119,3.016,0.944,5.897,2.987,8.108c4.235,4.576,11.397,4.86,15.968,0.631
      	c23.262-21.488,53.521-33.321,85.199-33.321c69.259,0,125.605,56.341,125.605,125.594c0,46.878-19.913,80.065-66.585,110.97
      	c-36.268,24.021-97.111,75.4-97.111,149.635c0,5.722-4.638,10.361-10.362,10.361c-5.724,0-10.361-4.64-10.361-10.361
      	c0-83.919,66.658-140.597,106.392-166.912c41.239-27.308,57.305-53.576,57.305-93.693c0-57.826-47.051-104.871-104.882-104.871
      	c-26.451,0-51.716,9.881-71.133,27.816c-12.952,11.983-33.248,11.188-45.248-1.779
      	C-307.301,252.774-310.313,244.605-309.974,236.058z"
      />
      <path
        style={{ fill: '#46A0D8' }}
        d="M-230.582,627.884c5.493-1.619,11.25,1.521,12.868,7.01c20.217,68.604,91.12,127.385,134.984,127.385
      	h201.473V607.505c0-5.723,4.638-10.361,10.361-10.361s10.361,4.639,10.361,10.361v154.773h86.209v-20.324
      	c0-17.67-14.374-32.046-32.043-32.046h-13.049c-2.783,0-5.448-1.119-7.396-3.105s-3.018-4.674-2.963-7.456
      	c0.134-6.896,0.526-31.192-0.042-54.367c-0.478-19.641,4.327-29.479,12.289-45.776c4.815-9.861,10.812-22.134,18.168-41.415
      	c2.153-5.64,3.673-11.146,4.522-16.364c0.919-5.648,6.234-9.491,11.89-8.564c5.647,0.919,9.481,6.242,8.562,11.891
      	c-1.073,6.605-2.964,13.477-5.614,20.426c-7.688,20.151-14.174,33.428-18.909,43.122c-7.459,15.271-10.549,21.594-10.192,36.176
      	c0.404,16.521,0.331,33.568,0.203,44.712h2.53c29.097,0,52.766,23.673,52.766,52.769v30.686c0,5.723-4.638,10.361-10.361,10.361
      	H-82.73c-26.675,0-59.37-15.616-89.706-42.846c-30.988-27.814-54.736-64.045-65.157-99.403
      	C-239.209,635.262-236.07,629.502-230.582,627.884z"
      />
      <path
        style={{ fill: '#65CCF0' }}
        d="M200.183,496.387c5.156-2.488,11.35-0.325,13.836,4.827c1.533,3.176,2.825,6.144,3.95,9.072
      	c2.05,5.343-0.618,11.335-5.959,13.387c-1.223,0.469-2.477,0.691-3.712,0.691c-4.167,0-8.093-2.53-9.677-6.651
      	c-0.916-2.387-1.985-4.837-3.265-7.49C192.869,505.07,195.029,498.876,200.183,496.387z"
      />
      <path
        style={{ fill: '#65CCF0' }}
        d="M-79.591,229.904c-2.525,0-5.059-0.919-7.054-2.774c-2.209-2.054-3.118-2.755-5.11-4.29
      	c-0.625-0.482-1.326-1.021-2.16-1.674c-4.506-3.527-5.299-10.04-1.771-14.546c3.528-4.505,10.038-5.297,14.545-1.771
      	c0.783,0.613,1.444,1.121,2.031,1.574c2.129,1.64,3.665,2.822,6.582,5.534c4.189,3.897,4.427,10.454,0.529,14.645
      	C-74.043,228.795-76.813,229.904-79.591,229.904z"
      />
      <path
        style={{ fill: '#46A0D8' }}
        d="M185.632,443.35c-0.353,14.037,0.249,27.408,2.642,37.218c1.355,5.559,6.966,8.967,12.521,7.609
      	c5.561-1.355,8.967-6.963,7.61-12.522c-1.826-7.486-2.473-19.467-1.957-34.607C199.632,442.286,192.684,443.056,185.632,443.35z"
      />
      <path
        style={{ fill: '#46A0D8' }}
        d="M36.736,486.271c-8.837,10.015-19.061,20.669-29.885,31.95
      	c-39.724,41.397-84.746,88.319-100.214,141.904c-4.916,17.022-7.738,34.117-8.43,50.994
      	c-46.588-17.469-78.099-62.038-78.099-112.626c0-54.01,48.689-94.306,77.712-113.519c59.466-39.391,85.984-84.752,85.984-147.085
      	c0-32.872-9.494-64.763-27.457-92.224c-3.132-4.788-9.553-6.133-14.343-2.999c-4.79,3.132-6.132,9.555-2.999,14.343
      	c15.751,24.079,24.076,52.048,24.076,80.88c0,54.634-23.658,94.668-76.703,129.806c-32.489,21.507-86.995,67.1-86.995,130.798
      	c0,64.598,43.819,120.824,106.562,136.73c0.841,0.213,1.696,0.318,2.546,0.318c2.335,0,4.629-0.791,6.491-2.285
      	c2.536-2.039,3.968-5.15,3.865-8.402c-0.608-19.339,1.982-39.184,7.699-58.98c14.05-48.675,57.191-93.637,95.256-133.305
      	c10.979-11.44,21.349-22.248,30.472-32.588c22.005-24.941,36.645-63.282,45.602-94.164c-6.32-3.344-12.438-7.118-18.333-11.28
      	C71.391,424.162,57.604,462.618,36.736,486.271z"
      />
      <path
        style={{ fill: '#46A0D8' }}
        d="M237,782.5 M687.351,767.5c51.176-41,81.148-102.207,81.148-168.5c0-50.207-17.203-98.5-48.742-136.5
      	H752v-47H353v47h33.242c-31.539,38-48.742,86.293-48.742,136.5c0,66.289,29.973,127.5,81.145,168.5 M368,447.5v-17h369v17H368z
      	 M386.601,687.504h-13.609C359.699,659.5,352.5,630.301,352.5,599c0-25.145,4.648-50.5,13.504-72.5l6.523-15
      	c8.609-18,19.898-35,33.629-49h293.688c34.621,37,53.656,85.555,53.656,136.5c0,31.301-7.199,60.5-20.492,88.5h-13.23 M704.32,687.5
      	h-24.418 M664.617,687.5h-63.234 M386.918,711.5c-1.996-3-3.902-6-5.738-9H724.82c-5.277,8-11.234,17-17.832,25l-13.922,15
      	c-9.492,9-19.973,17-31.379,25H444.312c-17.684-12-33.176-26-46.148-41"
      />
      <path
        style={{ fill: '#65CCF0' }}
        d="M315.297,287.584c3.804,2.029,8.532,0.591,10.561-3.213l9.517-17.84
      	c2.029-3.804,0.591-8.532-3.213-10.561c-3.804-2.029-8.532-0.59-10.561,3.213l-9.517,17.839
      	C310.055,280.826,311.493,285.555,315.297,287.584z"
      />
      <path
        style={{ fill: '#46A0D8' }}
        d="M390.795,229.839c0-1.711-0.472-3.419-1.407-4.921c-1.703-2.733-4.692-4.385-7.898-4.385
      	c-0.104,0-0.208,0.001-0.313,0.005l-77.683,2.607c-13.166-13.539-28.467-25.013-45.551-34.126
      	c-17.086-9.114-35.139-15.44-53.718-18.834l-41.094-65.965c-1.703-2.733-4.692-4.385-7.898-4.385c-0.102,0-0.206,0.001-0.313,0.005
      	c-3.322,0.112-6.332,1.987-7.896,4.92l-41.486,77.767c-24.118,11.899-43.272,30.176-55.453,53.014
      	c-9.575,17.947-14.386,37.67-14.385,57.982c0,17.026,3.377,34.465,10.144,51.632c14.665,37.205,43.571,68.774,81.366,88.936
      	c26.884,14.341,56.053,21.732,84.716,21.732c11.636,0,23.188-1.218,34.467-3.685c39.529-8.635,71.08-31.57,88.678-64.571
      	c9.489-17.786,14.331-37.543,14.33-58.006c0-5.812-0.397-11.682-1.183-17.579l41.483-77.765
      	C390.431,232.847,390.795,231.341,390.795,229.839z M329.382,311.726c0.938,5.987,1.402,11.938,1.402,17.812
      	c0,17.447-4.104,34.211-12.137,49.272c-10.044,18.824-25.33,33.393-43.8,43.276c-18.468,9.881-40.12,15.064-62.834,15.064
      	c-25.017,0-51.314-6.286-76.045-19.479c-51.26-27.336-81.597-76.796-81.583-124.195c0.001-17.009,3.895-33.756,12.118-49.173
      	c10.739-20.128,27.918-36.126,49.739-46.277c1.831-0.851,3.336-2.275,4.285-4.058l35.329-66.226l34.779,55.829
      	c1.427,2.292,3.78,3.85,6.445,4.271c18.015,2.847,35.542,8.765,52.101,17.599c16.56,8.834,31.236,20.095,43.634,33.471
      	c1.763,1.903,4.238,2.98,6.825,2.98c0.104,0,0.208-0.002,0.312-0.005l65.738-2.206l-35.328,66.226
      	c-0.723,1.354-1.096,2.858-1.096,4.379C329.269,310.764,329.306,311.246,329.382,311.726z"
      />
      <polygon
        style={{ fill: '#65CCF0' }}
        points="154.916,99.84 154.919,99.84 154.921,99.84 "
      />
      <path
        style={{ fill: '#65CCF0' }}
        d="M161.707,163.341c-1.391-0.742-2.896-1.097-4.374-1.097c-3.322-0.002-6.542,1.79-8.217,4.927
      	l-9.516,17.839c-0.742,1.391-1.098,2.896-1.098,4.374c-0.002,3.323,1.79,6.542,4.928,8.217l0.706-1.323l-0.706,1.324
      	c1.391,0.742,2.896,1.097,4.373,1.096c3.322,0.002,6.542-1.79,8.217-4.927l9.517-17.839c0.742-1.391,1.098-2.896,1.097-4.374
      	C166.637,168.235,164.845,165.015,161.707,163.341z"
      />
      <path
        style={{ fill: '#65CCF0' }}
        d="M152.89,315.793c0.596,0.549,1.246,1.008,1.933,1.374c1.368,0.729,2.874,1.091,4.374,1.091
      	c2.503,0.001,5.012-1.011,6.843-2.998c1.646-1.784,2.466-4.055,2.464-6.306c0.002-2.503-1.011-5.012-2.997-6.843l-28.074-25.888
      	c-1.786-1.646-4.057-2.465-6.308-2.463c-2.502-0.002-5.011,1.01-6.842,2.996c-1.646,1.785-2.466,4.056-2.465,6.307
      	c-0.001,2.503,1.011,5.012,2.998,6.843L152.89,315.793z"
      />
      <path
        style={{ fill: '#65CCF0' }}
        d="M144.507,345.808c4.211,0,8.018-2.87,9.045-7.139c0.174-0.727,0.258-1.457,0.258-2.175
      	c0-4.211-2.871-8.018-7.139-9.043l0,0l-37.137-8.9c-0.727-0.174-1.456-0.258-2.174-0.258c-4.211,0-8.019,2.87-9.045,7.139
      	c-0.174,0.727-0.258,1.456-0.258,2.175c0.002,3.434,1.902,6.59,4.922,8.203c0.686,0.366,1.43,0.652,2.217,0.84l37.137,8.9
      	C143.06,345.725,143.789,345.808,144.507,345.808z"
      />
      <path
        style={{ fill: '#65CCF0' }}
        d="M294.32,354.518l-37.137-8.899c-0.727-0.174-1.456-0.258-2.174-0.258
      	c-4.211,0-8.019,2.87-9.045,7.139c-0.174,0.726-0.258,1.456-0.258,2.175c0.002,3.434,1.902,6.59,4.922,8.203
      	c0.686,0.366,1.429,0.652,2.217,0.84l37.137,8.9c0.727,0.174,1.456,0.258,2.174,0.258c4.211,0,8.018-2.87,9.045-7.139
      	c0.174-0.727,0.258-1.457,0.258-2.175C301.459,359.351,298.589,355.545,294.32,354.518z"
      />
      <path
        style={{ fill: '#65CCF0' }}
        d="M246.627,375.376c-1.783-1.647-4.055-2.467-6.305-2.465c-2.504-0.002-5.014,1.011-6.845,2.999
      	c-1.646,1.784-2.466,4.055-2.465,6.306c-0.001,2.503,1.011,5.013,2.998,6.844l28.074,25.887c0.596,0.549,1.246,1.008,1.933,1.374
      	c1.368,0.729,2.874,1.091,4.374,1.091c2.503,0.001,5.012-1.011,6.843-2.997c1.646-1.784,2.466-4.055,2.465-6.306
      	c0.002-2.503-1.012-5.013-2.998-6.844L246.627,375.376z"
      />
      <path
        style={{ fill: '#65CCF0' }}
        d="M231.49,344.361l-28.146-15.015c-2.36-1.259-4.941-1.884-7.514-1.884
      	c-3.141,0-6.271,0.933-8.971,2.771c-4.404,2.995-6.983,7.931-6.982,13.175c0,0.608,0.035,1.221,0.105,1.834l2.212,19.294
      	c0.394,3.439,1.594,6.675,3.432,9.487c-3.332,2.865-7.595,4.446-11.954,4.445c-2.9,0-5.833-0.689-8.568-2.148l-12.396-6.613
      	c-1.391-0.742-2.896-1.097-4.374-1.097c-3.322-0.002-6.542,1.79-8.217,4.927c-0.742,1.391-1.098,2.896-1.098,4.374
      	c-0.002,3.323,1.79,6.542,4.928,8.217l12.397,6.613c5.507,2.938,11.44,4.333,17.287,4.333c4.42,0,8.791-0.797,12.901-2.321
      	c2.377,9.899,8.807,18.791,18.48,23.951l12.396,6.613c1.391,0.743,2.896,1.098,4.374,1.097c3.322,0.002,6.542-1.79,8.217-4.927
      	c0.742-1.391,1.098-2.896,1.097-4.374c0.003-3.323-1.789-6.542-4.927-8.217l-0.706,1.323l0.706-1.324l-12.397-6.613
      	c-6.158-3.284-9.668-9.616-9.67-16.157c0-0.723,0.059-1.448,0.146-2.171c3.359-0.04,6.716-0.841,9.793-2.428l17.257-8.909
      	c5.282-2.725,8.598-8.127,8.636-14.071v-0.109C239.937,352.536,236.702,347.139,231.49,344.361z M201.438,364.177
      	c-0.352-0.417-0.657-0.95-0.754-1.762l-1.636-14.265l19.217,10.25l-12.761,6.587c-0.563,0.289-1.054,0.376-1.501,0.378
      	c-0.704,0.003-1.313-0.253-1.583-0.399l-0.706,1.324l0.705-1.324C202.2,364.851,201.788,364.587,201.438,364.177z"
      />
      <path
        style={{ fill: '#46A0D8' }}
        d="M150.5,257.503l24.239,12.93c0.068,7.77,4.475,15.362,12.044,19.402
      	c3.474,1.854,7.204,2.737,10.847,2.737c4.289,0,8.453-1.237,11.963-3.545l2.152,1.148c0.86,0.46,1.797,0.68,2.714,0.679
      	c2.069,0.001,4.079-1.11,5.124-3.063l1.685-3.157c0.46-0.861,0.68-1.797,0.679-2.714c0.001-2.069-1.11-4.08-3.063-5.125
      	l-0.706,1.323l0.706-1.324l-1.63-0.869l-35.886-19.143l-23.729-12.658c-0.861-0.459-1.797-0.679-2.714-0.679
      	c-2.068-0.001-4.08,1.11-5.124,3.064l-1.685,3.156c-0.46,0.861-0.68,1.797-0.679,2.714
      	C147.436,254.447,148.547,256.458,150.5,257.503z"
      />
      <path
        style={{ fill: '#46A0D8' }}
        d="M275.954,335.136c3.474,1.854,7.204,2.737,10.848,2.737c4.289,0,8.453-1.237,11.963-3.545
      	l2.152,1.147c0.861,0.46,1.797,0.68,2.714,0.679c2.069,0.001,4.079-1.11,5.125-3.063l1.684-3.157c0.46-0.861,0.68-1.797,0.679-2.714
      	c0.001-2.069-1.109-4.08-3.063-5.125l0,0l-1.629-0.87l-35.887-19.144l-23.728-12.657c-0.861-0.46-1.798-0.68-2.715-0.679
      	c-2.068-0.001-4.08,1.11-5.124,3.064l-1.684,3.157c-0.46,0.861-0.68,1.797-0.68,2.714c-0.001,2.068,1.11,4.08,3.064,5.125
      	l24.238,12.929C263.979,323.506,268.387,331.097,275.954,335.136z"
      />
      <path
        style={{ fill: '#30231B' }}
        d="M330.75,15.852c0,27.241-19.26,50.067-44.875,55.578v25.2c0,6.606-5.363,11.965-11.97,11.965
      	c-6.605,0-11.969-5.358-11.969-11.965V60.728c0-6.606,5.363-11.965,11.969-11.965c18.146,0,32.911-14.765,32.911-32.911
      	c0-18.145-14.766-32.908-32.911-32.908c-18.145,0-32.91,14.763-32.91,32.908c0,6.608-5.358,11.967-11.965,11.967
      	s-11.969-5.358-11.969-11.967c0-31.343,25.503-56.842,56.844-56.842C305.247-40.989,330.75-15.491,330.75,15.852z M285.633,148.147
      	c0.158,0.757,0.242,1.555,0.242,2.333c0,0.778-0.084,1.575-0.242,2.333c-0.138,0.76-0.38,1.517-0.677,2.235
      	c-0.304,0.739-0.68,1.436-1.097,2.074c-0.442,0.657-0.939,1.277-1.499,1.815c-2.211,2.232-5.304,3.51-8.455,3.51
      	c-3.15,0-6.243-1.277-8.454-3.51c-0.56-0.539-1.061-1.158-1.499-1.815c-0.438-0.639-0.797-1.335-1.098-2.074
      	c-0.3-0.718-0.538-1.457-0.676-2.235c-0.158-0.757-0.242-1.555-0.242-2.333c0-0.778,0.084-1.576,0.242-2.333
      	c0.138-0.778,0.38-1.517,0.676-2.253c0.301-0.72,0.66-1.417,1.098-2.056c0.438-0.657,0.939-1.277,1.499-1.815
      	c2.211-2.232,5.304-3.51,8.454-3.51c3.151,0,6.244,1.277,8.455,3.51c0.56,0.539,1.057,1.158,1.499,1.815
      	c0.417,0.639,0.797,1.335,1.097,2.056C285.253,146.63,285.495,147.369,285.633,148.147z"
      />
      <circle style={{ fill: '#FFFFFF' }} cx="674.133" cy="594.43" r="15.635" />
      <circle
        style={{ fill: '#FFFFFF' }}
        cx="689.208"
        cy="641.742"
        r="15.635"
      />
      <circle style={{ fill: '#FFFFFF' }} cx={410} cy={590} r="9.883" />
      <circle style={{ fill: '#FFFFFF' }} cx="432.575" cy="561.575" r="7.885" />
      <circle style={{ fill: '#FFFFFF' }} cx="717.184" cy="613.305" r="9.883" />
      <circle style={{ fill: '#FFFFFF' }} cx="399.943" cy="553.081" r="9.883" />
      <path
        style={{ fill: '#46A0D8' }}
        d="M715.052,782.073H371.569c-4.125,0-7.5-3.375-7.5-7.5l0,0c0-4.125,3.375-7.5,7.5-7.5h343.483
      	c4.125,0,7.5,3.375,7.5,7.5l0,0C722.551,778.699,719.177,782.073,715.052,782.073z"
      />
    </svg>
  );
}

NoResultsIllustration.displayName = 'NoResultsIllustration';

const ScalableIcon = scalableIcon(1, 1, 'other')(NoResultsIllustration);

export { ScalableIcon as NoResultsIllustration };
export default ScalableIcon;
