import { createSelector } from 'reselect';
import { makeAllResourcesSelector } from '~/store/features/api/selectors';
import { ProfileResource } from '../resources/profile/types';
import { AppState } from '~/store';
import { ResourceTypes } from '../resources/types';

const profilesSelector: any = makeAllResourcesSelector(ResourceTypes.Profiles);

interface MakeProfileSelector {
  (state: AppState, showInactive: boolean): Array<ProfileResource>;
}

export function makeProfilesSelector(): MakeProfileSelector {
  return createSelector(
    profilesSelector,
    (_state: AppState, showInactive: boolean) => showInactive,
    (profiles: Array<ProfileResource>, showInactive: boolean) => {
      return profiles.filter(p => showInactive || p.active);
    }
  );
}
