import { merge, uniq, flatten } from 'lodash-es';
import { createSelector, Selector } from 'reselect';
import { generateQueryString } from '~/lib/api';
import { DEFAULT_QUERY_KEY } from '../state-helpers';
import { AppState } from '~/store';
import { ResourceIdentifier, JsonApiFetchOptions } from '~/lib/api/types';
import { ResourceDefinition, ResourceTypes } from '../resources/types';
import { StateResource, StateResourceData } from '../types';
import { camelize } from '../serialization';
import { ATTRIBUTE_TYPE } from '../resources/constants';
import parse from 'date-fns/parse';
import resourceDefinitions from '../resources';

export interface SelectorOptions {
  relationships?: Array<string>;
  includeMeta?: boolean;
}
type DataIndexer = { [key: string]: any };

const PAGING_CURSOR_PARAM = encodeURIComponent('page[cursor]');
const PAGING_NUM_PARAM = encodeURIComponent('page[num]');
const PAGING_SIZE_PARAM = encodeURIComponent('page[size]');
const PAGING_NUM_MATCH_REGEX = new RegExp(`${PAGING_NUM_PARAM}=(\\d+)`);
const PAGING_SIZE_MATCH_REGEX = new RegExp(`${PAGING_SIZE_PARAM}=(\\d+)`);
const PAGING_NUM_REMOVAL_REGEX = new RegExp(
  `${PAGING_SIZE_PARAM}=\\d+&${PAGING_NUM_PARAM}=\\d+`
);
const PAGING_CURSOR_REMOVAL_REGEX = new RegExp(
  `${PAGING_SIZE_PARAM}=\\d+&${PAGING_CURSOR_PARAM}=[a-zA-Z0-9-]+`
);
const shouldUseCursor = (queryString: string) =>
  queryString.includes(PAGING_CURSOR_PARAM);

export type FetchOptionsInputSelectorType<P> = Selector<
  AppState,
  JsonApiFetchOptions,
  [P]
>;

interface BaseResource {
  id: string;
}

/**
 * Creates a selector to retrieve all resources of a given type from the store.
 *
 * @param resourceName Name of the resource to retrieve, as `ResourceTypes` enum
 * @param options Optional `SelectorOptions`, specifying optional additional data items to select, like relationships and meta
 */
export function makeAllResourcesSelector<Resource extends BaseResource>(
  resourceName: ResourceTypes,
  options?: SelectorOptions
): Selector<AppState, Resource[]> {
  const resourceDataInputSelector = makeResourceDataInputSelector(resourceName);
  const relationshipDataSelectors = generateRelationshipInputSelectors(
    resourceName,
    options
  );

  return createSelector(
    [resourceDataInputSelector, ...relationshipDataSelectors],
    (data, ...relationshipData) => {
      return Object.keys(data).map(key =>
        normalizeData<Resource>(
          data,
          key,
          resourceName,
          relationshipData,
          options
        )
      );
    }
  );
}

/**
 * Creates a selector to retrieve all resources for a given API query
 * from the store.
 *
 * @param resourceName Name of the resource to retrieve, as `ResourceTypes` enum
 * @param fetchOptionsInputSelector Function to generate the `JSONApiFetchOptions` used to query the API.
 * @param options Optional `SelectorOptions`, specifying optional additional data items to select, like relationships and meta
 */
export function makeAllResourcesForQuerySelector<
  Resource extends BaseResource,
  P
>(
  resourceName: ResourceTypes,
  fetchOptionsInputSelector: FetchOptionsInputSelectorType<P>,
  selectorOptions?: SelectorOptions
): Selector<AppState, Resource[], [P]> {
  const resourceDataInputSelector = makeResourceDataInputSelector(resourceName);
  const resourceQueryDataInputSelector = makeQueryDataInputSelector(
    resourceName,
    fetchOptionsInputSelector
  );
  const relationshipDataSelectors = generateRelationshipInputSelectors(
    resourceName,
    selectorOptions
  );

  return createSelector(
    [
      resourceDataInputSelector,
      resourceQueryDataInputSelector,
      ...relationshipDataSelectors,
    ],
    (data, queryData, ...relationshipData) => {
      if (queryData && Array.isArray(queryData) && queryData.length > 0) {
        return queryData.map((resourceId: string) =>
          normalizeData<Resource>(
            data,
            resourceId,
            resourceName,
            relationshipData,
            selectorOptions
          )
        );
      } else {
        return [];
      }
    }
  );
}

export function makeMetaForQuerySelector<P>(
  resourceName: ResourceTypes,
  fetchOptionsInputSelector: FetchOptionsInputSelectorType<P>
): Selector<AppState, any, [P]> {
  const queryStringInputSelector = makeQueryStringInputSelector(
    fetchOptionsInputSelector
  );
  const queryInputSelector = makeQueryInputSelector(resourceName);

  return createSelector(
    [queryStringInputSelector, queryInputSelector],
    (queryString, queries) => {
      let queryStringWithoutPaging = queryString;
      let meta = DEFAULT_OBJECT;

      if (queryString.indexOf('page') !== -1) {
        const useCursor = shouldUseCursor(queryString);

        queryStringWithoutPaging = queryString.replace(
          useCursor ? PAGING_CURSOR_REMOVAL_REGEX : PAGING_NUM_REMOVAL_REGEX,
          ''
        );
        meta = {};

        Object.keys(queries)
          .sort((queryA, queryB) => {
            const queryAMatch = queryA.match(PAGING_NUM_MATCH_REGEX) || [];
            const queryBMatch = queryB.match(PAGING_NUM_MATCH_REGEX) || [];
            const queryAPage = parseInt(queryAMatch[1], 10);
            const queryBPage = parseInt(queryBMatch[1], 10);

            if (!isNaN(queryAPage) && !isNaN(queryBPage)) {
              // This is a query with number based pagination, so we need to sort
              // based on the page number of the query string
              return queryAPage - queryBPage;
            } else {
              // This is a normal query string, so we use the default sort algorithm
              return queryA < queryB ? -1 : 1;
            }
          })
          .forEach(storedQueryString => {
            const storedQueryWithoutPaging = storedQueryString.replace(
              useCursor
                ? PAGING_CURSOR_REMOVAL_REGEX
                : PAGING_NUM_REMOVAL_REGEX,
              ''
            );

            if (
              storedQueryWithoutPaging === queryStringWithoutPaging &&
              queries[storedQueryString].meta
            ) {
              meta = queries[storedQueryString].meta;
            }
          });
      } else {
        const selectedQuery = queries[queryString];

        if (selectedQuery && selectedQuery.meta) {
          meta = selectedQuery.meta;
        }
      }

      return meta;
    }
  );
}

export function makeMetaForResourceSelector<P>(
  resourceName: ResourceTypes,
  resourceIdInputSelector: IdSelector<P, string | null>
) {
  const resourceDataInputSelector = makeResourceDataInputSelector(resourceName);

  return createSelector(
    [resourceDataInputSelector, resourceIdInputSelector],
    (data, id) => {
      if (!id) {
        return null;
      }

      return data[id]?.meta ?? null;
    }
  );
}

export function makeDidResourceFetchFailForQuerySelector<P>(
  resourceName: ResourceTypes,
  fetchOptionsInputSelector: FetchOptionsInputSelectorType<P>
): Selector<AppState, boolean, [P]> {
  const queryStringInputSelector = makeQueryStringInputSelector(
    fetchOptionsInputSelector
  );
  const queryInputSelector = makeQueryInputSelector(resourceName);

  return createSelector(
    [queryStringInputSelector, queryInputSelector],
    (queryString, queries) => {
      const query = queries[queryString];

      return !!query && query.fetchFailed;
    }
  );
}

export function makeAreAllResourceForQueryLoadedSelector<P>(
  resourceName: ResourceTypes,
  fetchOptionsInputSelector: FetchOptionsInputSelectorType<P>
): Selector<AppState, boolean, [P]> {
  const queryStringInputSelector = makeQueryStringInputSelector(
    fetchOptionsInputSelector
  );
  const queryInputSelector = makeQueryInputSelector(resourceName);

  return createSelector(
    [queryStringInputSelector, queryInputSelector],
    (queryString, queries) => {
      const pageSizeMatch = queryString.match(PAGING_SIZE_MATCH_REGEX);

      if (pageSizeMatch && pageSizeMatch.length > 1) {
        const useCursor = shouldUseCursor(queryString);
        const pageSize = parseInt(pageSizeMatch[1], 10);
        const queryStringWithoutPaging = queryString.replace(
          useCursor ? PAGING_CURSOR_REMOVAL_REGEX : PAGING_NUM_REMOVAL_REGEX,
          ''
        );

        return Object.keys(queries)
          .filter(storeQueryString => {
            const storeQueryStringWithoutPaging = storeQueryString.replace(
              useCursor
                ? PAGING_CURSOR_REMOVAL_REGEX
                : PAGING_NUM_REMOVAL_REGEX,
              ''
            );

            return storeQueryStringWithoutPaging === queryStringWithoutPaging;
          })
          .some(storeQueryString => {
            const queryData = queries[storeQueryString].data;
            const isDataLoaded = Array.isArray(queryData);
            const isDataEmpty = isDataLoaded && queryData.length === 0;
            const isFullPage =
              isDataLoaded && queryData.length % pageSize === 0;

            // All resources are loaded if we hit an empty page or a page with less
            // than `pageSize` resources. If data for this query hasn't been loaded
            // yet, we cannot yet determine if all resources have been loaded.
            return isDataLoaded ? isDataEmpty || !isFullPage : false;
          });
      } else {
        // If no page size is given, always return true, since when
        // paging is off all resource are always loaded

        return true;
      }
    }
  );
}

type IdSelector<P, R> = Selector<AppState, R, [P]>;
export function makeResourceSelector<Resource extends BaseResource, P>(
  resourceName: ResourceTypes,
  resourceIdInputSelector: IdSelector<P, string | null>,
  options?: SelectorOptions
): Selector<AppState, Resource | null>;
export function makeResourceSelector<Resource extends BaseResource, P>(
  resourceName: ResourceTypes,
  resourceIdInputSelector: IdSelector<P, string[]>,
  options?: SelectorOptions
): Selector<AppState, Resource[]>;
export function makeResourceSelector<Resource extends BaseResource, P>(
  resourceName: ResourceTypes,
  resourceIdInputSelector: IdSelector<P, string | null | string[]>,
  options?: SelectorOptions
): Selector<AppState, Resource | null | Resource[]> {
  const resourceDataInputSelector = makeResourceDataInputSelector(resourceName);
  const relationshipDataSelectors = generateRelationshipInputSelectors(
    resourceName,
    options
  );

  return createSelector(
    [
      resourceDataInputSelector,
      resourceIdInputSelector,
      ...relationshipDataSelectors,
    ],
    (...result) => {
      const [data, id, ...relationshipData] = result;

      if (!id) {
        return null;
      }

      const isSelectingArray = Array.isArray(id);
      const idArray = Array.isArray(id) ? id : [id];
      const resourceArray = [];

      for (let i = 0; i < idArray.length; i++) {
        const currentId = idArray[i];
        const dataResource = data[currentId];

        if (
          !!dataResource &&
          !!dataResource.type &&
          !!dataResource.id &&
          Object.keys(dataResource.attributes).length > 0
        ) {
          const resource = normalizeData<Resource>(
            data,
            currentId,
            dataResource.type,
            relationshipData,
            options
          );
          resourceArray.push(resource);
        }
      }

      return isSelectingArray ? resourceArray : resourceArray[0] ?? null;
    }
  );
}

const DEFAULT_OBJECT: DataIndexer = {};
const DEFAULT_ARRAY: Array<any> = [];

function makeResourceDataInputSelector(resourceName: ResourceTypes) {
  return (state: AppState) => {
    const {
      api: { resources },
    } = state;
    const resourceByName = resources[resourceName];
    let data = DEFAULT_OBJECT;

    if (resourceByName && resourceByName.data) {
      data = resourceByName.data;
    }

    return data;
  };
}

function makeQueryDataInputSelector<P>(
  resourceName: ResourceTypes,
  fetchOptionsInputSelector: FetchOptionsInputSelectorType<P>
) {
  const queryStringInputSelector = makeQueryStringInputSelector(
    fetchOptionsInputSelector
  );
  const queryInputSelector = makeQueryInputSelector(resourceName);

  return createSelector(
    [queryStringInputSelector, queryInputSelector],
    (queryString, queries) => {
      let queryStringWithoutPaging = queryString;
      let data = DEFAULT_ARRAY;

      if (queryString.indexOf('page') !== -1) {
        const useCursor = shouldUseCursor(queryString);

        queryStringWithoutPaging = queryString.replace(
          useCursor ? PAGING_CURSOR_REMOVAL_REGEX : PAGING_NUM_REMOVAL_REGEX,
          ''
        );
        data = [];

        Object.keys(queries)
          .sort((queryA, queryB) => {
            const queryAMatch = queryA.match(PAGING_NUM_MATCH_REGEX) || [];
            const queryBMatch = queryB.match(PAGING_NUM_MATCH_REGEX) || [];
            const queryAPage = parseInt(queryAMatch[1], 10);
            const queryBPage = parseInt(queryBMatch[1], 10);

            if (!isNaN(queryAPage) && !isNaN(queryBPage)) {
              // This is a query with number based pagination, so we need to sort
              // based on the page number of the query string
              return queryAPage - queryBPage;
            } else {
              // This is a normal query string, so we use the default sort algorithm
              return queryA < queryB ? -1 : 1;
            }
          })
          .forEach(storedQueryString => {
            const storedQueryWithoutPaging = storedQueryString.replace(
              useCursor
                ? PAGING_CURSOR_REMOVAL_REGEX
                : PAGING_NUM_REMOVAL_REGEX,
              ''
            );

            if (
              storedQueryWithoutPaging === queryStringWithoutPaging &&
              queries[storedQueryString].data
            ) {
              data = data.concat(queries[storedQueryString].data);
            }
          });

        data = uniq(data);
      } else {
        const selectedQuery = queries[queryString];

        if (selectedQuery && selectedQuery.data) {
          data = selectedQuery.data;
        }
      }

      return data;
    }
  );
}

function makeQueryStringInputSelector<P>(
  fetchOptionsInputSelector: FetchOptionsInputSelectorType<P>
) {
  return (state: AppState, props: P) => {
    const fetchOptions = fetchOptionsInputSelector(state, props);
    const queryKey = generateQueryString(fetchOptions) || DEFAULT_QUERY_KEY;

    return queryKey;
  };
}

function makeQueryInputSelector(resourceName: ResourceTypes) {
  return (state: AppState) => {
    const {
      api: { resources },
    } = state;
    const resourceByName = resources[resourceName];
    let queries = DEFAULT_OBJECT;

    if (resourceByName && resourceByName.queries) {
      queries = resourceByName.queries;
    }

    return queries;
  };
}

function generateRelationshipInputSelectors(
  resourceName: ResourceTypes,
  options?: SelectorOptions
) {
  if (!!options && !!options.relationships) {
    const selectorArrays = options.relationships.map(key => {
      return makeResourceRelationshipInputSelector(resourceName, key);
    });

    return flatten(selectorArrays);
  }

  return [];
}

function makeResourceRelationshipInputSelector(
  resourceName: ResourceTypes,
  relationshipPath: string
) {
  const separatedRelationships = relationshipPath.split('.').reduce(
    (
      acc: { parts: Array<string>; relationships: Array<string> },
      relationshipName
    ) => {
      const fullPath = [...acc.parts, relationshipName].join('.');

      acc.parts.push(relationshipName);
      acc.relationships.push(fullPath);

      return acc;
    },
    { parts: [], relationships: [] }
  ).relationships;

  return separatedRelationships.map(relationshipPath =>
    makeRelationshipDataInputSelector(resourceName, relationshipPath)
  );
}

type PolymorphicData = Array<{ type: ResourceTypes; data: StateResourceData }>;
function makeRelationshipDataInputSelector(
  resourceName: ResourceTypes,
  relationshipPath: string
) {
  return (state: AppState) => {
    const {
      api: { resources },
    } = state;
    const relationshipNames = relationshipPath.split('.');
    const numRelationships = relationshipNames.length;
    let targetResourceNames: Array<ResourceTypes> = [resourceName];
    let targetRelationshipData: StateResourceData | PolymorphicData =
      DEFAULT_OBJECT;

    for (let i = 0; i < numRelationships; i++) {
      const relationshipName = relationshipNames[i];
      const resourceNames = [...targetResourceNames];
      let foundRelationship = false;

      for (let j = 0; j < resourceNames.length; j++) {
        const resourceByName: StateResource = resources[resourceNames[j]];

        if (
          resourceByName &&
          resourceByName.__definition__ &&
          resourceByName.__definition__.relationships
        ) {
          const relationshipDefinition =
            resourceByName.__definition__.relationships;
          const selectedRelationship = relationshipDefinition[relationshipName];

          if (!!selectedRelationship) {
            const targetResourceTypes = Array.isArray(
              selectedRelationship.resourceType
            )
              ? selectedRelationship.resourceType
              : [selectedRelationship.resourceType];

            if (foundRelationship) {
              targetResourceNames =
                targetResourceNames.concat(targetResourceTypes);
            } else {
              targetResourceNames = targetResourceTypes;
              foundRelationship = true;
            }
          }
        }
      }

      if (!foundRelationship) {
        console.warn(
          `Invalid relationship(s): "${resourceNames.join(
            ', '
          )}" has no relationship named "${relationshipName}"`
        );

        break;
      }

      if (i === numRelationships - 1) {
        // We're on the last part of the relationship path, so currentResourceName is
        // the resource name for the relationship we're targeting
        if (targetResourceNames.length === 1) {
          const resourceData = resources[targetResourceNames[0]];

          if (!!resourceData.data) {
            targetRelationshipData = resourceData.data;
          }
        } else {
          targetRelationshipData = targetResourceNames.map(name => ({
            type: name,
            data: resources[name].data,
          })) as PolymorphicData;
        }
      }
    }

    return targetRelationshipData;
  };
}

function normalizeData<Resource extends BaseResource>(
  data: DataIndexer,
  resourceId: string,
  resourceType: ResourceTypes,
  relationshipStateData: Array<StateResourceData | PolymorphicData>,
  options?: SelectorOptions
): Resource {
  let parsedRelationships;

  if (options && options.relationships) {
    parsedRelationships = parseRelationshipOptions(
      options.relationships,
      relationshipStateData
    );
  }

  const includeMeta = options && options.includeMeta;

  return normalizeDataWithRelationships<Resource>(
    data,
    resourceId,
    resourceType,
    parsedRelationships,
    includeMeta
  );
}

type ParsedRelationshipOption = {
  name: string;
  relationshipStateData: Array<StateResourceData | PolymorphicData>;
  childRelationships?: Array<ParsedRelationshipOption>;
};
function normalizeDataWithRelationships<Resource extends BaseResource>(
  data: StateResourceData | PolymorphicData,
  resourceId: string,
  resourceType: string,
  relationshipOptions?: Array<ParsedRelationshipOption>,
  includeMeta?: boolean
): Resource {
  const targetData = Array.isArray(data)
    ? data.find(d => d.type === resourceType)?.data
    : data;
  const resource = !!targetData ? targetData[resourceId] : null;
  let result;

  if (!!resource) {
    const relationships = Object.keys(resource?.relationships ?? {}).reduce(
      (acc: DataIndexer, relationshipName) => {
        const relationship = !!resource.relationships
          ? resource.relationships[relationshipName]
          : undefined;
        const relationshipData = relationship?.data;

        if (relationshipData) {
          if (relationshipOptions && relationshipOptions.length > 0) {
            const relationshipOption = relationshipOptions.find(
              x => x.name === relationshipName
            );

            if (!!relationshipOption) {
              const extractedData = extractRelationshipDataNew(
                relationshipOption,
                relationshipData
              );

              acc[relationshipName] = extractedData;
            }
          }
        }

        return acc;
      },
      {}
    );

    const meta = includeMeta ? resource.meta : {};
    const definition = resourceDefinitions[resource.type];

    result = {
      id: resourceId,
      __type__: resource.type,
      ...processAttributes(resource.attributes, definition),
      ...relationships,
    };

    for (const metaKey in meta) {
      if ((result as any)[metaKey]) {
        console.warn(
          `Property in meta ${metaKey} already exists on the resource.`
        );
      } else {
        (result as any)[metaKey] = meta[metaKey];
      }
    }
  }

  if (!!result) {
    // Because we're injecting `__type__` onto resources, we have to convert to unknown
    // first, due to a TS error.
    return result as unknown as Resource;
  } else {
    throw new Error(
      `Unable to normalize resource: ${resourceType}, ${resourceId}`
    );
  }
}

/**
 * State in redux has to be serializable. We used to store date objects in redux which violated this.
 * With the migration to RTK, this has changed but a lot of our app code depends on the attribute being a date object.
 * So we are mapping this in the selector.
 * @param attributes A resource attributes to convert.
 * @param definition Resource definition so that we can figure out its expected type
 */
function processAttributes(
  attributes: Record<string, any>,
  definition?: ResourceDefinition
) {
  const newAttributes: Record<string, any> = {};

  for (const key in attributes) {
    const camelKey = camelize(key);
    let attribute = attributes[key];
    const attrDefinition = definition ? definition.attributes[camelKey] : null;

    switch (attrDefinition) {
      case ATTRIBUTE_TYPE.DATE_TIME:
      case ATTRIBUTE_TYPE.DATE: {
        attribute = attribute ? parse(attribute) : attribute;
      }
    }

    newAttributes[key] = attribute;
  }

  return newAttributes;
}

function parseRelationshipOptions(
  relationshipOptions: Array<string>,
  relationshipStateData: Array<StateResourceData | PolymorphicData>
): Array<ParsedRelationshipOption> {
  const result = normalizeRelationshipOptions(
    relationshipOptions,
    relationshipStateData
  );

  return Object.keys(result).map(key =>
    translateRelationshipOptionsToArray(result, key)
  );
}

type NormalizedRelationshipOption = {
  name: string;
  relationshipStateData: Array<StateResourceData | PolymorphicData>;
  childRelationships: {
    [childRelationshipName: string]: NormalizedRelationshipOption;
  };
};
type NormalizedOptions = {
  [relationshipName: string]: NormalizedRelationshipOption;
};
function normalizeRelationshipOptions(
  relationshipOptions: Array<string>,
  relationshipStateData: Array<StateResourceData | PolymorphicData>
): NormalizedOptions {
  return relationshipOptions.reduce((acc: DataIndexer, option) => {
    const [relationshipName, ...rest] = option.split('.');
    let childRelationships;

    const numTargetRelationships = rest.length + 1;
    const optionIndex = relationshipOptions.indexOf(option);
    const precedingRelationships = relationshipOptions.slice(0, optionIndex);
    const numPrecedingRelationships = precedingRelationships.reduce(
      (count, rel) => count + rel.split('.').length,
      0
    );
    const targetRelationshipStateData = relationshipStateData.slice(
      numPrecedingRelationships,
      numTargetRelationships + numPrecedingRelationships
    );

    if (rest.length > 0) {
      const nextOptions = [rest.join('.')];

      childRelationships = normalizeRelationshipOptions(
        nextOptions,
        targetRelationshipStateData.slice(1)
      );
    }

    if (!!acc[relationshipName] && !!childRelationships) {
      const existingChildren = acc[relationshipName].childRelationships || {};

      acc[relationshipName].childRelationships = merge(
        {},
        existingChildren,
        childRelationships
      );
    } else if (!acc[relationshipName]) {
      const relationshipOption: {
        name: string;
        relationshipStateData: Array<StateResourceData | PolymorphicData>;
        childRelationships?: NormalizedOptions;
      } = {
        name: relationshipName,
        relationshipStateData: targetRelationshipStateData,
      };

      if (childRelationships) {
        relationshipOption.childRelationships = childRelationships;
      }

      acc[relationshipName] = relationshipOption;
    }

    return acc;
  }, {});
}

function translateRelationshipOptionsToArray(
  options: NormalizedOptions,
  key: string
): ParsedRelationshipOption {
  const { name, relationshipStateData, childRelationships } = options[key];
  const option: ParsedRelationshipOption = { name, relationshipStateData };

  if (childRelationships) {
    option.childRelationships = Object.keys(childRelationships).map(childKey =>
      translateRelationshipOptionsToArray(childRelationships, childKey)
    );
  }

  return option;
}

function extractRelationshipDataNew(
  targetRelationshipOption: ParsedRelationshipOption,
  relationshipData: ResourceIdentifier | Array<ResourceIdentifier>
) {
  const { childRelationships, relationshipStateData } =
    targetRelationshipOption;
  const stateRelationshipData = relationshipStateData[0];
  const relationshipDataType = typeof relationshipData;
  const relationshipIsObject = relationshipDataType === 'object';
  let resources;

  if (Array.isArray(relationshipData)) {
    resources = relationshipData.map(d =>
      normalizeDataWithRelationships(
        stateRelationshipData,
        d.id,
        d.type,
        childRelationships
      )
    );
  } else if (relationshipIsObject) {
    resources = normalizeDataWithRelationships(
      stateRelationshipData,
      relationshipData.id,
      relationshipData.type,
      childRelationships
    );
  }

  return resources;
}

export function hasResourceBeenRequested(
  state: AppState,
  resourceName: ResourceTypes,
  fetchOptions?: JsonApiFetchOptions
): boolean {
  const {
    api: { resources },
  } = state;
  const currentResource = resources[resourceName];
  const queryKey = generateQueryString(fetchOptions) || DEFAULT_QUERY_KEY;

  if (
    currentResource &&
    currentResource.queries &&
    currentResource.queries[queryKey]
  ) {
    return currentResource.queries[queryKey].hasBeenRequested;
  }

  return false;
}

export function isResourceStale(
  state: AppState,
  resourceName: ResourceTypes,
  fetchOptions?: JsonApiFetchOptions
): boolean {
  const {
    api: { resources },
  } = state;
  const currentResource = resources[resourceName];
  const queryKey = generateQueryString(fetchOptions) || DEFAULT_QUERY_KEY;

  return currentResource?.queries?.[queryKey]?.isStale || false;
}

export function areResourcesLoading(
  state: AppState,
  resourceName: ResourceTypes,
  fetchOptions?: JsonApiFetchOptions
): boolean {
  const {
    api: { resources },
  } = state;
  const currentResource = resources[resourceName];
  const queryKey = generateQueryString(fetchOptions) || DEFAULT_QUERY_KEY;

  if (
    currentResource &&
    currentResource.queries &&
    currentResource.queries[queryKey]
  ) {
    return currentResource.queries[queryKey].isFetching;
  }

  return false;
}

export function isResourceTypeCreating(
  state: AppState,
  resourceName: ResourceTypes
): boolean {
  const value = getResourceMetaProperty(state, resourceName, 'isCreating');

  return !!value && value > 0;
}

export function isResourceTypeUpdating(
  state: AppState,
  resourceName: ResourceTypes
): boolean {
  const value = getResourceMetaProperty(state, resourceName, 'isUpdating');

  return !!value && value > 0;
}

export function isResourceTypeDeleting(
  state: AppState,
  resourceName: ResourceTypes
): boolean {
  const value = getResourceMetaProperty(state, resourceName, 'isDeleting');

  return !!value && value > 0;
}

function getResourceMetaProperty(
  state: AppState,
  resourceName: ResourceTypes,
  property: string
) {
  const {
    api: { resources },
  } = state;
  const currentResource = resources[resourceName];

  if (currentResource) {
    return currentResource[property];
  } else {
    return 0;
  }
}
