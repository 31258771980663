import {
  ComponentType,
  ChangeEvent,
  FocusEvent,
  Fragment,
  InputHTMLAttributes,
  MouseEvent,
  ReactNode,
  createElement,
  forwardRef,
  useEffect,
  useRef,
  useReducer,
  useLayoutEffect,
  ForwardedRef,
} from 'react';
import styled from 'styled-components';

import analytics, { Categories } from '~/analytics';
import { useI18n } from '~/hooks';
import { useAutoId } from '~/lib/utils/auto-id';
import { colors, fontSizes, zIndex, spacing, utils } from '~/styles';

export type InputStyleProps = {
  isHovered: boolean;
  isFocused: boolean;
  isHighlighted: boolean;
  hasValue: boolean;
  isInvalid: boolean;
  isDisabled: boolean;
};

type ClassStyle =
  | Record<string, unknown>
  | Array<Record<string, unknown> | boolean>;

interface ClassNames {
  default: ClassStyle;
  hovered?: ClassStyle;
  focused?: ClassStyle;
  withValue?: ClassStyle;
  disabled?: ClassStyle;
  invalid?: ClassStyle;
  invalidFocused?: ClassStyle;
  invalidHovered?: ClassStyle;
  highlighted?: ClassStyle;
}
type MixinStyleRules = {
  $defaultMixin: Record<string, unknown>;
  $hoverMixin: Record<string, unknown>;
  $focusMixin: Record<string, unknown>;
  $highlightMixin: Record<string, unknown>;
  $valueMixin: Record<string, unknown>;
  $invalidMixin: Record<string, unknown>;
  $invalidHoveredMixin: Record<string, unknown>;
  $invalidFocusedMixin: Record<string, unknown>;
  $disabledMixin: Record<string, unknown>;
};

const mixins = {
  errorText: {
    color: colors.errorText,
  },

  inputIconDisabled: {
    cursor: 'not-allowed',
  },
};

const StyledBottomSpacer = styled.div({
  height: spacing.large,
});

function reduceStyles(styles: ClassStyle): Record<string, unknown> {
  if (Array.isArray(styles)) {
    return styles.reduce((result: Record<string, unknown>, style) => {
      if (typeof style !== 'boolean') {
        return { ...result, ...style };
      } else {
        return result;
      }
    }, {});
  } else {
    return styles;
  }
}

function generateMixinStyles(props: MixinStyleRules) {
  return {
    ...reduceStyles(props.$defaultMixin),
    ...reduceStyles(props.$hoverMixin),
    ...reduceStyles(props.$focusMixin),
    ...reduceStyles(props.$highlightMixin),
    ...reduceStyles(props.$valueMixin),
    ...reduceStyles(props.$invalidMixin),
    ...reduceStyles(props.$invalidHoveredMixin),
    ...reduceStyles(props.$invalidFocusedMixin),
    ...reduceStyles(props.$disabledMixin),
  };
}

const StyledInput = styled.input<MixinStyleRules>(generateMixinStyles);

const StyledInputIconWrapper = styled.div<{
  $inputIconDisabledEnabled: boolean;
}>(props => ({
  position: 'absolute',
  top: '50%',
  right: spacing.small,
  width: spacing.large,
  height: spacing.large,
  transform: 'translateY(-50%)',
  zIndex: zIndex.aboveSibling,
  cursor: 'pointer',
  ...(props.$inputIconDisabledEnabled ? mixins.inputIconDisabled : {}),
}));

const StyledInputWidthMeasurer = styled.div({
  visibility: 'hidden',
  position: 'fixed',
  display: 'inline-block',
  width: 'auto',
});

const StyledHintText = styled.div<{
  $errorTextEnabled: boolean;
}>(props => ({
  height: spacing.large,
  color: colors.secondaryText,
  ...utils.text.truncate,
  ...fontSizes.callout,
  ...(props.$errorTextEnabled ? mixins.errorText : {}),
  ...generateMixinStyles(props),
}));

const StyledInputWrapper = styled.div<{
  $inputWrapperHiddenEnabled: boolean;
}>(props => ({
  position: 'relative',
  width: '100%',
  ...(props.$inputWrapperHiddenEnabled
    ? {
        position: 'absolute',
        width: 0,
        height: 0,
        opacity: 0,
        pointerEvents: 'none',
        zIndex: zIndex.hidden,
      }
    : {}),
}));

const FormattedValueContainer =
  styled.div<MixinStyleRules>(generateMixinStyles);

const StyledRequiredIndicator = styled.span({
  color: colors.errorIcon,
});

const StyledLabel = styled.label<MixinStyleRules>(generateMixinStyles);
const Container = styled.div<MixinStyleRules>(generateMixinStyles);

interface BaseInputProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'id' | 'value' | 'onChange'
  > {
  /** ID attribute for the input */
  id: string;
  /**
   * Override the label sent to analytics for this button
   * (useful if button has a unique ID, for example buttons that trigger the datepicker input in a list of loop cards)
   */
  analyticsId?: string;
  /** Label associated with the input field */
  label: ReactNode | string;
  /** Value of the input field */
  value: string;
  /** Called when the value of the input field changes */
  onChange: (value: string) => void;
}
export interface ConsumerInputProps extends BaseInputProps {
  /** Marks the field as a required field */
  required?: boolean;
  /** Formatted version of the given input value */
  format?: 'none' | 'currency';
  /** Auto adjusts the input width to the content */
  autoAdjustToContent?: boolean;
  inputIcon?: ComponentType<any>;
  /** Text displayed below input */
  hintText?: string;
  /**
   * Additional validation logic for input values
   * ('required' validation is handled via the {required} prop)
   *
   * Return a custom error message to be displayed below the input
   * if it fails validation.
   */
  validate?: (value: string) => string | undefined;
  /** Highlight the background of the input (for use when 'autofilling' an input) */
  highlighted?: boolean;
  /**
   * Indicates the form has been submitted and the error state should be displayed
   * if {required} and not checked, even if the input wasn't yet "touched"
   */
  formSubmitted?: boolean;
  /**
   * Indicates if we want to show an error text if we leave the input empty
   */
  showErrorOnEmpty?: boolean;
  /**
   * Indicates when we want to show our custom error message
   */
  showCustomError?: boolean;
  /**
   * Custom error message that shows below the input
   */
  customErrorMessage?: string;
}

interface State {
  hovered: boolean;
  focused: boolean;
  touched: boolean;
}
enum StateActionType {
  MouseEnter,
  MouseLeave,
  FocusIn,
  FocusOut,
}
interface StateAction {
  type: StateActionType;
}
function stateReducer(state: State, action: StateAction) {
  switch (action.type) {
    case StateActionType.MouseEnter:
      return { ...state, hovered: true };
    case StateActionType.MouseLeave:
      return { ...state, hovered: false };
    case StateActionType.FocusIn:
      return { ...state, focused: true };
    case StateActionType.FocusOut:
      return { ...state, focused: false, touched: true };
  }
}

function useForwardedRef<T>(ref: ForwardedRef<T>) {
  const innerRef = useRef<T>(null);

  useEffect(() => {
    if (typeof ref === 'function') {
      ref(innerRef.current);
    } else if (!!ref) {
      ref.current = innerRef.current;
    }
  }, [ref, innerRef]);

  return innerRef;
}

interface Props extends ConsumerInputProps {
  /** Class names to apply to the container element */
  containerClasses: ClassNames;
  /** Class names to apply to the label element */
  labelClasses: ClassNames;
  /** Class names to apply to the input element */
  inputClasses: ClassNames;
  /** Class names to apply to the formatted value element */
  formattedValueClasses: ClassNames;
  /** Class names to apply to the input subtext (hint or error text) */
  subtextClasses?: ClassNames;
  /** Called on mouseEnter for the entire input container */
  onMouseEnter?: (e: MouseEvent<HTMLInputElement>) => void;
  /** Called on mouseLeave for the entire input container */
  onMouseLeave?: (e: MouseEvent<HTMLInputElement>) => void;
  /** Called onFocus for the entire input container */
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  /** Called onBlur for the entire input container */
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
}

function BaseInputForwardRef(
  {
    value,
    label,
    hintText,
    onChange,
    containerClasses,
    labelClasses,
    inputClasses,
    subtextClasses,
    formattedValueClasses,
    inputIcon,
    validate,
    formSubmitted,
    analyticsId,
    format = 'none',
    required = false,
    highlighted = false,
    autoAdjustToContent = false,
    showErrorOnEmpty = true,
    showCustomError = false,
    customErrorMessage = '',
    ...inputProps
  }: Props,
  input: ForwardedRef<HTMLInputElement>
) {
  const i18n = useI18n();
  const id = useAutoId(inputProps.id);
  const inputRef = useForwardedRef(input);
  const inputWidthRef = useRef<HTMLDivElement>(null);
  const inputStartValue = useRef('');
  const [state, dispatch] = useReducer(stateReducer, {
    hovered: false,
    focused: false,
    touched: false,
  });
  const formattedValue =
    format === 'currency' && value !== ''
      ? i18n.formatCurrency(value, 'USD', { hideDecimal: true })
      : value;
  const hasCustomFormat = format !== 'none';
  const showFormattedValue =
    hasCustomFormat && !!formattedValue && !state.focused;
  const inputTabIndex = inputProps.disabled ? -1 : 0;
  const labelId = inputProps['aria-labelledby'];
  const showRequiredErrorText = required && !value && showErrorOnEmpty;
  // If we have a custom error message, show that
  const showCustomErrorText = showCustomError && !!customErrorMessage;
  const validationResult = validate?.(value);
  const errorText = showCustomErrorText
    ? customErrorMessage
    : showRequiredErrorText
    ? i18n.t('errors.inputs.required', { label })
    : !!validationResult
    ? validationResult
    : undefined;
  const isInvalid = !!errorText;
  const showInvalidState = (state.touched || formSubmitted) && isInvalid;
  const buildCss = (classes: ClassNames) => ({
    $defaultMixin: classes.default,
    $hoverMixin: state.hovered ? classes.hovered : {},
    $focusMixin: state.focused ? classes.focused : {},
    $highlightMixin: highlighted ? classes.highlighted : {},
    $valueMixin: !!value ? classes.withValue : {},
    $invalidMixin: !!showInvalidState ? classes.invalid : {},
    $invalidHoveredMixin:
      showInvalidState && state.hovered && classes.invalidHovered,
    $invalidFocusedMixin:
      showInvalidState && state.focused && classes.invalidFocused,
    $disabledMixin: !!inputProps.disabled ? classes.disabled : {},
  });

  const consumerInputProps = {
    id,
    value,
    tabIndex: inputTabIndex,
    onFocus: (e: FocusEvent<HTMLInputElement>) => {
      inputStartValue.current = value;
      dispatch({ type: StateActionType.FocusIn });
      inputProps.onFocus?.(e);
    },
    onBlur: (e: FocusEvent<HTMLInputElement>) => {
      dispatch({ type: StateActionType.FocusOut });
      inputProps.onBlur?.(e);
      analytics.track(
        Categories.INTERACTION,
        inputStartValue.current !== value ? 'input_updated' : 'input_visited',
        analyticsId ?? id
      );
    },
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.value);
    },
    ref: inputRef,
  };
  let iconType = 'grey';

  if (!inputProps.disabled) {
    if (showInvalidState) {
      iconType = 'errorText';
    } else if (state.hovered || state.focused) {
      iconType = 'primaryAction';
    }
  }
  const IconElement = !!inputIcon
    ? createElement(inputIcon, { type: iconType }, null)
    : null;

  const focusTextbox = () => {
    inputRef?.current?.focus();
  };

  useLayoutEffect(() => {
    if (autoAdjustToContent && !!inputWidthRef.current && !!inputRef.current) {
      const { width } = inputWidthRef.current.getBoundingClientRect();
      const adjustedWidth = !!value ? `${width + 10}px` : '100%';

      inputRef.current.style.width = adjustedWidth;
      inputRef.current.style.minWidth = '50px';
    }
  }, [autoAdjustToContent, inputRef, inputWidthRef, value]);

  return (
    <Fragment>
      <Container
        onMouseEnter={(e: MouseEvent<HTMLInputElement>) => {
          dispatch({ type: StateActionType.MouseEnter });
          inputProps.onMouseEnter?.(e);
        }}
        onMouseLeave={(e: MouseEvent<HTMLInputElement>) => {
          dispatch({ type: StateActionType.MouseLeave });
          inputProps.onMouseLeave?.(e);
        }}
        {...buildCss(containerClasses)}
      >
        <StyledLabel
          id={labelId}
          htmlFor={inputProps.id}
          {...buildCss(labelClasses)}
        >
          {label}
          {required && <StyledRequiredIndicator>*</StyledRequiredIndicator>}
        </StyledLabel>
        {showFormattedValue && (
          <FormattedValueContainer
            onClick={focusTextbox}
            {...buildCss(formattedValueClasses)}
          >
            {formattedValue}
          </FormattedValueContainer>
        )}

        <StyledInputWrapper $inputWrapperHiddenEnabled={showFormattedValue}>
          <StyledInput
            {...inputProps}
            {...consumerInputProps}
            {...buildCss(inputClasses)}
            aria-describedby="errorInput"
            aria-invalid={showInvalidState}
          />

          {(showInvalidState || hintText) && (
            <StyledHintText
              id="errorInput"
              $errorTextEnabled={!!showInvalidState}
              role={showInvalidState ? 'alert' : ''}
              {...buildCss(subtextClasses)}
            >
              {showInvalidState ? errorText : hintText}
            </StyledHintText>
          )}
        </StyledInputWrapper>

        {autoAdjustToContent && (
          <StyledInputWidthMeasurer ref={inputWidthRef}>
            {value}
          </StyledInputWidthMeasurer>
        )}
        {!!IconElement && (
          <StyledInputIconWrapper
            $inputIconDisabledEnabled={inputProps.disabled}
            onClick={focusTextbox}
          >
            {IconElement}
          </StyledInputIconWrapper>
        )}
      </Container>

      {(required || !!validate || !!hintText) && <StyledBottomSpacer />}
    </Fragment>
  );
}
export const BaseInput = forwardRef<HTMLInputElement, Props>(
  BaseInputForwardRef
);

export default BaseInput;
