import styled from 'styled-components';

import { colors, utils } from '~/styles';
import BaseButton, { BaseButtonProps } from './BaseButton';
import { buttonStyleHelpers } from './utils';

const StyledBaseButton = styled(BaseButton)<BaseButtonProps>(props => ({
  border: '1px solid transparent',
  boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.25)',
  borderRadius: utils.buttonBorderRadius,
  color: colors.primaryBackgroundText,
  backgroundColor: colors.primaryButton,

  ...buttonStyleHelpers.focus(
    {
      color: colors.primaryBackgroundText,
      backgroundColor: colors.primaryButtonFocus,
      boxShadow: 'none',
    },
    props
  ),

  ...buttonStyleHelpers.hover(
    {
      color: colors.primaryBackgroundText,
      backgroundColor: colors.primaryButtonFocus,
      boxShadow: 'none',
    },
    props
  ),

  ...buttonStyleHelpers.active(
    {
      color: colors.primaryBackgroundText,
      backgroundColor: colors.primaryButtonFocus,
    },
    props
  ),
}));

export const PrimaryButton = (props: BaseButtonProps) => {
  const { defaultIconProps } = props;
  const iconProps = { ...defaultIconProps, type: 'primaryBackgroundText' };

  return <StyledBaseButton {...props} defaultIconProps={iconProps} />;
};

export default PrimaryButton;
