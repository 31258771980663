import { ATTRIBUTE_TYPE } from '../constants';
import { ResourceDefinition } from '../types';

//The ID of this resource is dataTypeLinkId, this resource is essentially a dataTypeLink resource
const FieldLabels: ResourceDefinition = {
  attributes: {
    sectionName: ATTRIBUTE_TYPE.STRING,
    roleName: ATTRIBUTE_TYPE.STRING,
    fieldName: ATTRIBUTE_TYPE.STRING,
    systemId: ATTRIBUTE_TYPE.NUMBER,
  },
};

export default FieldLabels;
