import { useRef, useState, useLayoutEffect, createRef, ReactNode } from 'react';
import styled from 'styled-components';
import { breakpoints, screenSizes } from '~/styles';

type ChildRenderFunction = (shouldRender: boolean) => ReactNode;

type Props = {
  $index?: number;
  /** Contents of this grid item */
  children?: ReactNode | ChildRenderFunction;
  /** Minimum height of the grid item - used for virtualization */
  $minHeight?: number;
  /** Background color of the item - used to apply solid background when off screen during virtualization */
  $backgroundColor?: string;
  /** CSS text-align property for this grid item */
  $textAlign?: 'left' | 'center' | 'right';
  /** Number of columns on extra small screens and up */
  $colXSm?: number;
  /** Number of columns on small screens and up */
  $colSm?: number;
  /** Number of columns on medium screens and up */
  $colMd?: number;
  /** Number of columns on large screens and up */
  $colLg?: number;
  /** Number of columns on extra large screens and up */
  $colXLg?: number;
  /** Number of columns on double XL screens and up */
  $colXXLg?: number;
  /** Number of columns to offset on extra small screens and up */
  $offsetXSm?: number;
  /** Number of columns to offset on small screens and up */
  $offsetSm?: number;
  /** Number of columns to offset on medium screens and up */
  $offsetMd?: number;
  /** Number of columns to offset on large screens and up */
  $offsetLg?: number;
  /** Number of columns to offset on extra large screens and up */
  $offsetXLg?: number;
  /** Number of columns to offset on double XL screens and up */
  $offsetXXLg?: number;
  /** Hide this item ONLY on extra small screens */
  $hideXSm?: boolean;
  /** Hide this item ONLY on small screens */
  $hideSm?: boolean;
  /** Hide this item ONLY on medium screens */
  $hideMd?: boolean;
  /** Hide this item ONLY on large screens */
  $hideLg?: boolean;
  /** Hide this item ONLY on extra large screens */
  $hideXLg?: boolean;
  /** Hide this item ONLY on double XL screens */
  $hideXXLg?: boolean;
  /** Whether or not the grid item should have height: 100% */
  $fullHeight?: boolean;
  /** Allow this item to align itself outside of the parent alignment */
  $alignSelf?: string;
  /** Specify this item to have no padding */
  $noPadding?: boolean;
  /** Specify this item to have no left padding */
  $noLeftPadding?: boolean;
  /** Specify this item to have no top padding */
  $noTopPadding?: boolean;
  /** Specify this item to have no right padding */
  $noRightPadding?: boolean;
  /** Specify this item to have no bottom padding */
  $noBottomPadding?: boolean;
  /** Specify a specific z-index order on the grid item */
  $zIndex?: number;
  /** Allows a way to opt out of intersection observer windowing technique */
  $useWindowing: boolean;
};

function getItemSizes(props: Props) {
  const DEFAULT = props.$colXSm || 0;
  const SMALL = props.$colSm || DEFAULT;
  const MEDIUM = props.$colMd || SMALL;
  const LARGE = props.$colLg || MEDIUM;
  const XLARGE = props.$colXLg || LARGE;
  const XXLARGE = props.$colXXLg || XLARGE;

  const DEFAULT_OFFSET = props.$offsetXSm || 0;
  const SMALL_OFFSET = props.$offsetSm || DEFAULT_OFFSET;
  const MEDIUM_OFFSET = props.$offsetMd || SMALL_OFFSET;
  const LARGE_OFFSET = props.$offsetLg || MEDIUM_OFFSET;
  const XLARGE_OFFSET = props.$offsetXLg || LARGE_OFFSET;
  const XXLARGE_OFFSET = props.$offsetXXLg || XLARGE_OFFSET;

  const DEFAULT_DISPLAY = props.$hideXSm ? 'none' : 'block';
  const SMALL_DISPLAY = props.$hideSm ? 'none' : 'block';
  const MEDIUM_DISPLAY = props.$hideMd ? 'none' : 'block';
  const LARGE_DISPLAY = props.$hideLg ? 'none' : 'block';
  const XLARGE_DISPLAY = props.$hideXLg ? 'none' : 'block';
  const XXLARGE_DISPLAY = props.$hideXXLg ? 'none' : 'block';

  return {
    columns: {
      colXSm: DEFAULT,
      colSm: SMALL,
      colMd: MEDIUM,
      colLg: LARGE,
      colXLg: XLARGE,
      colXXLg: XXLARGE,
    },
    offsets: {
      offsetXSm: DEFAULT_OFFSET,
      offsetSm: SMALL_OFFSET,
      offsetMd: MEDIUM_OFFSET,
      offsetLg: LARGE_OFFSET,
      offsetXLg: XLARGE_OFFSET,
      offsetXXLg: XXLARGE_OFFSET,
    },
    display: {
      displayXSm: DEFAULT_DISPLAY,
      displaySm: SMALL_DISPLAY,
      displayMd: MEDIUM_DISPLAY,
      displayLg: LARGE_DISPLAY,
      displayXLg: XLARGE_DISPLAY,
      displayXXLg: XXLARGE_DISPLAY,
    },
  };
}

const GridItemContainer = styled.div<Props>(props => {
  const SIZES = getItemSizes(props);
  const TEXT_ALIGN = props.$textAlign || 'left';
  const HEIGHT = props.$fullHeight ? '100%' : 'auto';

  return {
    paddingTop: props.$noPadding || props.$noTopPadding ? '0' : '0.5em',
    paddinBottom: props.$noPadding || props.$noBottomPadding ? '0' : '0.5em',
    paddingLeft: props.$noPadding || props.$noLeftPadding ? '0' : '0.5em',
    paddingRight: props.$noPadding || props.$noRightPadding ? '0' : '0.5em',
    alignSelf: props.$alignSelf || 'auto',
    flexGrow: 1,
    textAlign: TEXT_ALIGN,
    height: HEIGHT,
    zIndex: props.$zIndex,

    //default (mobile)
    flexBasis: (SIZES.columns.colXSm * 100) / TOTAL_COLUMNS + '%',
    maxWidth:
      SIZES.columns.colXSm === 0
        ? 100
        : (SIZES.columns.colXSm * 100) / TOTAL_COLUMNS + '%',
    marginLeft: (SIZES.offsets.offsetXSm * 100) / TOTAL_COLUMNS + '%',
    display: SIZES.display.displayXSm,

    //small
    [breakpoints.SMALL]: {
      flexBasis: (SIZES.columns.colSm * 100) / TOTAL_COLUMNS + '%',
      maxWidth:
        SIZES.columns.colSm === 0
          ? 100
          : (SIZES.columns.colSm * 100) / TOTAL_COLUMNS + '%',
      marginLeft: (SIZES.offsets.offsetSm * 100) / TOTAL_COLUMNS + '%',
      display: SIZES.display.displaySm,
    },

    //medium
    [breakpoints.MEDIUM]: {
      flexBasis: (SIZES.columns.colMd * 100) / TOTAL_COLUMNS + '%',
      maxWidth:
        SIZES.columns.colMd === 0
          ? 100
          : (SIZES.columns.colMd * 100) / TOTAL_COLUMNS + '%',
      marginLeft: (SIZES.offsets.offsetMd * 100) / TOTAL_COLUMNS + '%',
      display: SIZES.display.displayMd,
    },

    //large
    [breakpoints.LARGE]: {
      flexBasis: (SIZES.columns.colLg * 100) / TOTAL_COLUMNS + '%',
      maxWidth:
        SIZES.columns.colLg === 0
          ? 100
          : (SIZES.columns.colLg * 100) / TOTAL_COLUMNS + '%',
      marginLeft: (SIZES.offsets.offsetLg * 100) / TOTAL_COLUMNS + '%',
      display: SIZES.display.displayLg,
    },

    //extra large
    [breakpoints.XLARGE]: {
      flexBasis: (SIZES.columns.colXLg * 100) / TOTAL_COLUMNS + '%',
      maxWidth:
        SIZES.columns.colXLg === 0
          ? 100
          : (SIZES.columns.colXLg * 100) / TOTAL_COLUMNS + '%',
      marginLeft: (SIZES.offsets.offsetXLg * 100) / TOTAL_COLUMNS + '%',
      display: SIZES.display.displayXLg,
    },

    //double XL
    [breakpoints.XXLARGE]: {
      flexBasis: (SIZES.columns.colXXLg * 100) / TOTAL_COLUMNS + '%',
      maxWidth:
        SIZES.columns.colXXLg === 0
          ? 100
          : (SIZES.columns.colXXLg * 100) / TOTAL_COLUMNS + '%',
      marginLeft: (SIZES.offsets.offsetXXLg * 100) / TOTAL_COLUMNS + '%',
      display: SIZES.display.displayXXLg,
    },

    ['@supports (display: grid)']: {
      maxWidth: '100%',
      height: '100%',
      padding: 0,
    },
  };
});

const TOTAL_COLUMNS = 12;
const GRID_ITEMS: Map<Element, (intersectionRatio: number) => any> = new Map();
let intersectionObserver: IntersectionObserver | null = null;

function observerCallback(entries: Array<IntersectionObserverEntry>) {
  entries.forEach(entry => {
    const { intersectionRatio, target } = entry;
    const callback = GRID_ITEMS.get(target);

    if (!!callback) {
      callback(intersectionRatio);
    }
  });
}

function GridItem(props: Props) {
  const { $minHeight, $backgroundColor, $useWindowing } = props;
  const [renderItem, setRenderItem] = useState(() => {
    const index = props.$index;
    const minHeight = props.$minHeight;

    if (index !== undefined && minHeight !== undefined && minHeight > 0) {
      const sizes = getItemSizes(props);
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      let numItemsPerColumn;

      if (windowWidth < screenSizes.SMALL) {
        numItemsPerColumn = sizes.columns.colXSm;
      } else if (windowWidth < screenSizes.MEDIUM) {
        numItemsPerColumn = sizes.columns.colSm;
      } else if (windowWidth < screenSizes.LARGE) {
        numItemsPerColumn = sizes.columns.colMd;
      } else if (windowWidth < screenSizes.XLARGE) {
        numItemsPerColumn = sizes.columns.colLg;
      } else if (windowWidth < screenSizes.XXLARGE) {
        numItemsPerColumn = sizes.columns.colXLg;
      } else {
        numItemsPerColumn = sizes.columns.colXXLg;
      }

      const maxRowsForWindow = Math.floor(windowHeight / minHeight);
      const multiplier = 12 / numItemsPerColumn;
      const maxIndex = maxRowsForWindow * multiplier;

      return index <= maxIndex;
    } else {
      return true;
    }
  });
  const currentRenderState = useRef(renderItem);
  const gridItemRef = createRef<HTMLDivElement>();

  function handleObserverCallback(ratio: number) {
    const shouldRender = ratio > 0.05;

    if (shouldRender !== currentRenderState.current) {
      currentRenderState.current = shouldRender;
      setRenderItem(shouldRender);
    }
  }

  useLayoutEffect(() => {
    if ($useWindowing) {
      const gridItemElement = gridItemRef.current;

      if (!!gridItemElement) {
        if (!intersectionObserver) {
          intersectionObserver = new IntersectionObserver(observerCallback, {
            threshold: [0, 0.1],
            rootMargin: '500px 0px 500px 0px',
          });
        }

        GRID_ITEMS.set(gridItemElement, handleObserverCallback);

        intersectionObserver.observe(gridItemElement);
      }
      return () => {
        if (!!intersectionObserver && !!gridItemElement) {
          intersectionObserver.unobserve(gridItemElement);
        }

        if (!!gridItemElement && !!GRID_ITEMS.get(gridItemElement)) {
          GRID_ITEMS.delete(gridItemElement);
        }

        if (!!intersectionObserver && GRID_ITEMS.size === 0) {
          intersectionObserver.disconnect();
          intersectionObserver = null;
        }
      };
    }
  }, [$useWindowing, gridItemRef]);

  return (
    <GridItemContainer
      ref={gridItemRef}
      style={{ minHeight: $minHeight, backgroundColor: $backgroundColor }}
      {...props}
    >
      {typeof props.children === 'function'
        ? props.children(renderItem)
        : renderItem
        ? props.children
        : null}
    </GridItemContainer>
  );
}

GridItem.defaultProps = {
  $useWindowing: false,
};

export default GridItem;
