import {
  ResourceDefinition,
  ResourceTypes,
} from '~/store/features/api/resources/types';
import {
  ATTRIBUTE_TYPE,
  RELATIONSHIP_TYPE,
} from '~/store/features/api/resources/constants';

const ComplianceReviewNote: ResourceDefinition = {
  attributes: {
    id: ATTRIBUTE_TYPE.STRING,
    pageNumber: ATTRIBUTE_TYPE.NUMBER,
    x: ATTRIBUTE_TYPE.NUMBER,
    y: ATTRIBUTE_TYPE.NUMBER,
    resolved: ATTRIBUTE_TYPE.BOOLEAN,
    resolvedBy: ATTRIBUTE_TYPE.NUMBER,
    resolvedDate: ATTRIBUTE_TYPE.DATE,
    resolvedByMemberId: ATTRIBUTE_TYPE.NUMBER,
  },
  relationships: {
    document: {
      resourceType: ResourceTypes.Documents,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
    loop: {
      resourceType: ResourceTypes.Loops,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
    messages: {
      resourceType: ResourceTypes.ComplianceReviewMessage,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
  },
};

export default ComplianceReviewNote;
