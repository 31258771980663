import { useCallback, useContext } from 'react';
import styled from 'styled-components';

import { spacing } from '~/styles';

import { LinkButton } from '~/components/buttons';
import { NotificationContext } from '~/components/Notifications/context';
import { NotificationType } from '~/components/Notifications';

const StyledActionButton = styled.div({
  marginLeft: spacing.medium,
  marginRight: spacing.small,
});

const StyledChildrenContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const DISMISS_TIME = 5 * 1000;

type Action = {
  label: string;
  link?: string;
  onClick?: () => void;
};

interface CurrentNotifications {
  [id: string]: number;
}
const CURRENT_NOTIFICATIONS: CurrentNotifications = {};

const children = (id: string, message: string, action?: Action) => {
  if (!action) {
    return message;
  }

  const { label, ...buttonProps } = action;

  return (
    <StyledChildrenContainer>
      {message}

      <StyledActionButton>
        <LinkButton removeSidePadding id={`action-${id}`} {...buttonProps}>
          {label}
        </LinkButton>
      </StyledActionButton>
    </StyledChildrenContainer>
  );
};

export function useSimpleNotification() {
  const { showNotification, updateNotification, removeNotification } =
    useContext(NotificationContext);
  const setupDismissalTimer = useCallback(
    (idToDismiss: string) => {
      const timeout = CURRENT_NOTIFICATIONS[idToDismiss];

      if (timeout !== undefined) {
        window.clearTimeout(timeout);
      }

      CURRENT_NOTIFICATIONS[idToDismiss] = window.setTimeout(() => {
        removeNotification(idToDismiss);
        delete CURRENT_NOTIFICATIONS[idToDismiss];
      }, DISMISS_TIME);
    },
    [removeNotification]
  );

  const showSimpleSuccess = useCallback(
    (id: string, message: string, action?: Action) => {
      const current = CURRENT_NOTIFICATIONS[id];

      if (current !== undefined) {
        updateNotification({
          id,
          type: NotificationType.Success,
          children: children(id, message, action),
        });
      } else {
        showNotification({
          id,
          type: NotificationType.Success,
          dismiss: () => removeNotification(id),
          children: children(id, message, action),
        });
      }

      setupDismissalTimer(id);
    },
    [
      showNotification,
      setupDismissalTimer,
      updateNotification,
      removeNotification,
    ]
  );

  const showSimpleError = useCallback(
    (id: string, message: string, action?: Action) => {
      const current = CURRENT_NOTIFICATIONS[id];

      if (current !== undefined) {
        updateNotification({
          id,
          type: NotificationType.Error,
          children: children(id, message, action),
        });
      } else {
        showNotification({
          id,
          type: NotificationType.Error,
          dismiss: () => removeNotification(id),
          children: children(id, message, action),
        });
      }

      setupDismissalTimer(id);
    },
    [
      showNotification,
      setupDismissalTimer,
      updateNotification,
      removeNotification,
    ]
  );

  return { showSimpleError, showSimpleSuccess };
}
