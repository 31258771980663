import { Component } from 'react';
import styled from 'styled-components';
import { datadogRum } from '@datadog/browser-rum';

import analytics, { Categories } from '~/analytics';
import { LinkButton } from '~/components/buttons';
import OutlinedTextInput from '~/components/inputs/text/OutlinedTextInput';
import { FormattedMessage } from '~/components/i18n';
import {
  SnackbarNotification,
  NotificationType,
} from '~/components/Notifications';

import { breakpoints, colors, fontSizes, fontWeights, spacing } from '~/styles';

import RecipientMultiSelect, { RecipientType } from './RecipientMultiSelect';
import { getRecipientName } from './utils';

import { NewRecipient, Recipient } from './RecipientMultiSelect';

export const HEADER_HEIGHT = '5rem';
const DEFAULT_HEADER_HEIGHT = '6.875rem';
const mixins = {
  containerForGroupStep: {
    borderBottom: 'none',
    height: '100%',
  },
};

const StyledGroupNameInputSubtitle = styled.div({
  ...fontSizes.callout,
  color: colors.secondaryText,
});

const StyledGroupNameInput = styled.div({
  padding: `${spacing.smaller} ${spacing.normal}`,
  marginTop: spacing.small,

  [breakpoints.MEDIUM]: {
    padding: `${spacing.smallest} ${spacing.large} ${spacing.normal}`,
  },
});

const StyledGroupNameWrapper = styled.div({
  borderTop: `1px solid ${colors.lightBorder}`,
});

const StyledGroupNameRecipients = styled.div({
  fontWeight: fontWeights.semiBold,
  display: 'flex',
  flexWrap: 'wrap',
});

const StyledRecipientInput = styled.div({
  padding: `${spacing.smaller} ${spacing.normal}`,
  maxHeight: `calc(${HEADER_HEIGHT} * 2)`,
  overflow: 'auto',

  [breakpoints.MEDIUM]: {
    padding: `${spacing.smallest} ${spacing.large} ${spacing.normal}`,
  },
});

const StyledHeaderRightAction = styled.div({
  flex: '0 0 20%',
  width: '20%',
  textAlign: 'right',
});

const StyledHeaderTitle = styled.div({
  ...fontSizes.headline,
  flex: '1 0 auto',
  textAlign: 'center',
  fontWeight: fontWeights.bold,
});

const StyledHeaderLeftActionDesktop = styled.div({
  flex: '0 0 20%',
  width: '20%',
  display: 'none',

  [breakpoints.LARGE]: {
    display: 'block',
  },
});

const StyledHeaderLeftActionMobile = styled.div({
  flex: '0 0 20%',
  width: '20%',

  [breakpoints.LARGE]: {
    display: 'none',
  },
});

const StyledHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: spacing.normal,
  borderBottom: `1px solid ${colors.lightBorder}`,

  [breakpoints.MEDIUM]: {
    padding: `${spacing.medium} ${spacing.large} ${spacing.smallest}`,
    alignItems: 'flex-start',
    border: 'none',
  },
});

const StyledInvalidRecipientList = styled.ul({
  marginTop: spacing.smaller,
  marginBottom: 0,
  listStyle: 'disc',
});

const StyledContainer = styled.div<{
  $containerForGroupStepEnabled: boolean;
}>(props => ({
  position: 'relative',
  minHeight: HEADER_HEIGHT,
  backgroundColor: colors.white,
  borderBottom: `1px solid ${colors.lightBorder}`,
  ...(props.$containerForGroupStepEnabled ? mixins.containerForGroupStep : {}),
}));

const CONVERSATION_STEPS = {
  RECIPIENTS: 0,
  GROUP_NAME: 1,
};

function getInvalidRecipients(
  selectedRecipients: Array<Recipient>
): Array<NewRecipient> {
  return selectedRecipients.filter(
    r => r.type === RecipientType.New && !r.isValid
  ) as Array<NewRecipient>;
}

type Props = {
  searchTerm: string | null | undefined;
  onCancelNewConversation: () => void;
  createConversation: (
    recipients: Array<Recipient>,
    groupName?: string
  ) => Promise<any>;
};

type State = {
  searchTerm: string;
  headerHeight: string;
  selectedRecipients: Array<Recipient>;
  step: number;
  groupName: string;
  hasGroupNameError: boolean;
  hasMissingRecipientError: boolean;
  hasInvalidRecipientsError: boolean;
};

class NewConversationHeader extends Component<Props, State> {
  containerRef: HTMLElement | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      searchTerm: props.searchTerm || '',
      headerHeight: DEFAULT_HEADER_HEIGHT,
      selectedRecipients: [],
      step: CONVERSATION_STEPS.RECIPIENTS,
      groupName: '',
      hasGroupNameError: false,
      hasMissingRecipientError: false,
      hasInvalidRecipientsError: false,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.state.headerHeight === DEFAULT_HEADER_HEIGHT ||
      this.state.selectedRecipients.length !==
        prevState.selectedRecipients.length
    ) {
      this.updateHeaderHeight();
    }
  }

  updateHeaderHeight = () => {
    const container = this.containerRef;

    if (container) {
      const newHeight = `${container.clientHeight}`;

      if (newHeight !== this.state.headerHeight) {
        this.setState({ headerHeight: newHeight });
      }
    }
  };

  setupContainerRef = (ref: HTMLElement | null) => {
    this.containerRef = ref;
  };

  handleAddRecipient = (recipient: Recipient) => {
    this.setState(prevState => ({
      selectedRecipients: [...prevState.selectedRecipients, recipient],
    }));
  };

  handleRemoveRecipient = (recipientToRemove: Recipient) => {
    this.setState(prevState => {
      const selectedRecipients = prevState.selectedRecipients.filter(r => {
        const routesMatch =
          'route' in recipientToRemove &&
          'route' in r &&
          recipientToRemove.route === r.route;
        const idsMatch =
          'id' in recipientToRemove &&
          'id' in r &&
          recipientToRemove.id === r.id;

        return !(routesMatch || idsMatch);
      });

      return {
        selectedRecipients,
      };
    });
  };

  handleNextClick = async () => {
    const { selectedRecipients, step, groupName } = this.state;
    const hasMultipleRecipients = selectedRecipients.length > 1;

    if (selectedRecipients.length === 0) {
      this.setState({ hasMissingRecipientError: true });
    } else {
      const invalidRecipients = getInvalidRecipients(selectedRecipients);

      if (invalidRecipients.length > 0) {
        this.setState({ hasInvalidRecipientsError: true });
      } else {
        if (step === CONVERSATION_STEPS.RECIPIENTS) {
          if (hasMultipleRecipients) {
            analytics.track(
              Categories.APP_STATE,
              'messaging',
              'group-name-step'
            );
            this.setState({
              step: CONVERSATION_STEPS.GROUP_NAME,
            });
          } else {
            await this.createConversation(selectedRecipients);
          }
        } else if (step === CONVERSATION_STEPS.GROUP_NAME) {
          if (!!groupName) {
            await this.createConversation(selectedRecipients, groupName);
          } else {
            this.setState({
              hasGroupNameError: true,
            });
          }
        }
      }
    }
  };

  createConversation = async (
    selectedRecipients: Array<Recipient>,
    groupName?: string
  ) => {
    const baseLabel = !!groupName
      ? 'create-group-conversation'
      : 'create-conversation';
    const successLabel = `${baseLabel}-success`;
    const failLabel = `${baseLabel}-failed`;

    try {
      analytics.track(Categories.APP_STATE, 'messaging', baseLabel);
      await this.props.createConversation(selectedRecipients, groupName);
      analytics.track(Categories.APP_STATE, 'messaging', successLabel);
    } catch (error) {
      analytics.track(Categories.APP_STATE, 'messaging', failLabel);
      datadogRum.addError(error, {
        action: 'create-conversation',
        numRecipients: selectedRecipients.length,
      });
    }
  };

  handleGroupNameChange = (groupName: string) => {
    this.setState({
      groupName,
    });
  };

  handleSearchTermChanged = (searchTerm: string) => {
    this.setState({
      searchTerm,
    });
  };

  goBackToRecipientsStep = () => {
    this.setState({
      step: CONVERSATION_STEPS.RECIPIENTS,
    });
  };

  render() {
    const {
      hasMissingRecipientError,
      hasInvalidRecipientsError,
      hasGroupNameError,
      headerHeight,
      selectedRecipients,
      step,
      groupName,
      searchTerm,
    } = this.state;

    const { onCancelNewConversation } = this.props;
    const showGroupName = step === CONVERSATION_STEPS.GROUP_NAME;
    const invalidRecipients = getInvalidRecipients(selectedRecipients);

    return (
      <StyledContainer
        ref={this.setupContainerRef}
        $containerForGroupStepEnabled={showGroupName}
      >
        {hasMissingRecipientError && (
          <SnackbarNotification
            id="missing-recipients-erorr"
            type={NotificationType.Error}
            onDismiss={() => this.setState({ hasMissingRecipientError: false })}
          >
            <FormattedMessage id="messaging.create.errors.missingRecipient" />
          </SnackbarNotification>
        )}
        {hasInvalidRecipientsError && (
          <SnackbarNotification
            id="invalid-recipients-erorr"
            type={NotificationType.Error}
            onDismiss={() =>
              this.setState({ hasInvalidRecipientsError: false })
            }
          >
            <FormattedMessage
              id="messaging.create.errors.invalidRecipients"
              values={{ count: invalidRecipients.length }}
            />
            <StyledInvalidRecipientList>
              {invalidRecipients.map(recipient => (
                <li key={recipient.route}>{recipient.route}</li>
              ))}
            </StyledInvalidRecipientList>
          </SnackbarNotification>
        )}
        {hasGroupNameError && (
          <SnackbarNotification
            id="invalid-group-name"
            type={NotificationType.Error}
            onDismiss={() => this.setState({ hasGroupNameError: false })}
          >
            <FormattedMessage id="messaging.create.errors.missingGroupName" />
          </SnackbarNotification>
        )}
        <StyledHeader>
          <StyledHeaderLeftActionMobile>
            {showGroupName ? (
              <LinkButton
                id="back-to-recipients-button-mobile"
                removeSidePadding
                onClick={this.goBackToRecipientsStep}
              >
                <FormattedMessage id="actions.back" />
              </LinkButton>
            ) : (
              <LinkButton
                id="back-to-messages-button"
                removeSidePadding
                link="/messages"
              >
                <FormattedMessage id="actions.cancel" />
              </LinkButton>
            )}
          </StyledHeaderLeftActionMobile>
          <StyledHeaderLeftActionDesktop>
            <LinkButton
              removeSidePadding
              id={
                showGroupName
                  ? 'back-to-recipients-button'
                  : 'cancel-new-conversation-button'
              }
              onClick={
                showGroupName
                  ? this.goBackToRecipientsStep
                  : onCancelNewConversation
              }
            >
              <FormattedMessage
                id={showGroupName ? 'actions.back' : 'actions.cancel'}
              />
            </LinkButton>
          </StyledHeaderLeftActionDesktop>
          <StyledHeaderTitle>
            <FormattedMessage
              id={
                showGroupName
                  ? 'messaging.create.groupTitle'
                  : 'messaging.create.title'
              }
            />
          </StyledHeaderTitle>
          <StyledHeaderRightAction>
            <LinkButton
              id="next-create-conversation-button"
              removeSidePadding
              onClick={this.handleNextClick}
            >
              <FormattedMessage id="actions.next" />
            </LinkButton>
          </StyledHeaderRightAction>
        </StyledHeader>
        <StyledRecipientInput>
          {showGroupName ? (
            <StyledGroupNameRecipients>
              {selectedRecipients
                .map(recipient =>
                  recipient.type === RecipientType.Existing
                    ? getRecipientName(recipient)
                    : recipient.route
                )
                .join(', ')}
            </StyledGroupNameRecipients>
          ) : (
            <RecipientMultiSelect
              searchTerm={searchTerm}
              onSearchTermChanged={this.handleSearchTermChanged}
              headerHeight={headerHeight}
              updateHeaderHeight={this.updateHeaderHeight}
              selectedRecipients={selectedRecipients}
              addRecipient={this.handleAddRecipient}
              removeRecipient={this.handleRemoveRecipient}
            />
          )}
        </StyledRecipientInput>

        {showGroupName && (
          <StyledGroupNameWrapper>
            <StyledGroupNameInput>
              <OutlinedTextInput
                id="group-name-input"
                name="group-name-input"
                label={
                  <FormattedMessage id="messaging.create.inputPlaceholder" />
                }
                value={groupName}
                onChange={this.handleGroupNameChange}
              />
              <StyledGroupNameInputSubtitle>
                <FormattedMessage id="messaging.create.groupSubtitle" />
              </StyledGroupNameInputSubtitle>
            </StyledGroupNameInput>
          </StyledGroupNameWrapper>
        )}
      </StyledContainer>
    );
  }
}

export default NewConversationHeader;
