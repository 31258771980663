import { noop } from 'lodash-es';

let hasPermission: boolean;

enum NotificationPermission {
  Granted = 'granted',
  Denied = 'denied',
}
type NotificationWindowType = {
  permission: NotificationPermission;
  requestPermission: (
    callback: (permission: NotificationPermission) => void
  ) => void;
};

export function requestNotificationPermission(cb: () => void = noop) {
  if (!('Notification' in window) || hasPermission !== undefined) {
    return;
  }

  const notificationWindow: NotificationWindowType = (window as any)
    .Notification;

  if (notificationWindow.permission === NotificationPermission.Granted) {
    hasPermission = true;
    cb();
  } else if (notificationWindow.permission !== NotificationPermission.Denied) {
    notificationWindow.requestPermission(
      (permission: NotificationPermission) => {
        hasPermission = permission === NotificationPermission.Granted;
        cb();
      }
    );
  } else {
    hasPermission = false;
    cb();
  }
}

export type BrowserNotification = {
  title: string;
  body?: string;
  tag?: string;
  icon?: string;
  onClick?: () => void;
};

export function showNotification(notification: BrowserNotification) {
  if (hasPermission === undefined) {
    requestNotificationPermission(() => {
      if (hasPermission) {
        _showNotification(notification);
      }
    });
  } else if (hasPermission) {
    _showNotification(notification);
  }
}

function _showNotification(notification: BrowserNotification) {
  const { title, onClick, ...options } = notification;

  const n = new (window as any).Notification(title, options);

  n.onclick = function () {
    window.parent.focus();
    window.focus();

    if (onClick) {
      onClick();
    }

    this.close();
  };
}
