import { ComponentType } from 'react';
import { I18nContext } from './context';

export function injectI18n(Component: ComponentType<any>) {
  const Wrapper = (props: any) => (
    <I18nContext.Consumer>
      {value => {
        if (!!value) {
          return <Component {...props} i18n={value.i18n} />;
        } else {
          return null;
        }
      }}
    </I18nContext.Consumer>
  );

  Wrapper.displayName = `injectI18n(${
    Component.displayName || Component.name
  })`;
  Wrapper.WrappedComponent = Component;

  return Wrapper;
}

export default injectI18n;
