import { Fragment } from 'react';
import styled from 'styled-components';

import { LinkButton } from '~/components/buttons';
import { FormattedMessage } from '~/components/i18n';
import {
  SnackbarNotification,
  NotificationType,
} from '~/components/Notifications';

import { spacing } from '~/styles';

const StyledLinkButton = styled.span({
  marginLeft: spacing.smallest,
});

export type ConversationNotificationsType = {
  [conversationId: string]: number | null;
};

type Props = {
  conversationNotifications: ConversationNotificationsType;
  getConversationName: (conversationId: string) => string | null;
  viewConversation: (conversationId: string) => void;
  removeConversationNotification: (conversationId: string) => void;
};

export function ConversationNotifications(props: Props) {
  const {
    conversationNotifications,
    getConversationName,
    viewConversation,
    removeConversationNotification,
  } = props;

  return (
    <Fragment>
      {Object.keys(conversationNotifications).map(conversationId => {
        const numberOfMessages = conversationNotifications[conversationId];
        const conversationName = getConversationName(conversationId);

        if (numberOfMessages === null || !conversationName) {
          return null;
        } else {
          return (
            <SnackbarNotification
              key={conversationId}
              id={conversationId}
              type={NotificationType.Info}
              onDismiss={() => removeConversationNotification(conversationId)}
            >
              <FormattedMessage
                id="messaging.newMessageNotification"
                values={{
                  name: conversationName,
                  count: numberOfMessages,
                }}
              />
              <StyledLinkButton>
                <LinkButton
                  removeSidePadding
                  id={`view-conversation-${conversationId}`}
                  onClick={() => {
                    viewConversation(conversationId);
                    removeConversationNotification(conversationId);
                  }}
                >
                  <FormattedMessage
                    id="messaging.viewMessageLink"
                    values={{
                      count: numberOfMessages,
                    }}
                  />
                </LinkButton>
              </StyledLinkButton>
            </SnackbarNotification>
          );
        }
      })}
    </Fragment>
  );
}

export default ConversationNotifications;
