import { uniqueId } from 'lodash-es';
import { useState } from 'react';

export function useAutoId(givenId?: string) {
  const [autoId] = useState(() => givenId ?? uniqueId());

  return autoId;
}

export default uniqueId;
