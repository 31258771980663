import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from './constants';

export enum ResourceTypes {
  Users = 'users',
  Tags = 'tags',
  Loops = 'loops',
  LoopDetails = 'loopDetails',
  LoopTemplates = 'loopTemplates',
  Documents = 'documents',
  DocumentClasses = 'documentClasses',
  DocumentRevisions = 'documentRevisions',
  ComplianceMessageRecipients = 'complianceMessageRecipients',
  ComplianceReviewMessage = 'complianceReviewMessages',
  ComplianceReviewNote = 'complianceReviewNotes',
  ComplianceGroups = 'complianceGroups',
  ComplianceStatuses = 'complianceStatuses',
  Preferences = 'preferences',
  Profiles = 'profiles',
  Conversations = 'conversations',
  Messages = 'messages',
  Participants = 'participants',
  ParticipantStatuses = 'participantStatuses',
  Contacts = 'contacts',
  UserAnnouncements = 'userAnnouncements',
  TeamInvitations = 'teamInvitations',
  LoopFolders = 'loopFolders',
  LoopParticipants = 'loopParticipants',
  LoopParticipantInvitations = 'loopParticipantInvitations',
  LoopParticipantLinks = 'loopParticipantLinks',
  LoopRoles = 'loopRoles',
  ShareWorkflows = 'shareWorkflows',
  ShareWorkflowSteps = 'shareWorkflowSteps',
  ShareWorkflowTemplates = 'shareWorkflowTemplates',
  ShareWorkflowStepTemplates = 'shareWorkflowStepTemplates',
  LoopViewData = 'loopViewData',
  FieldLabels = 'fieldLabels',
  LoopData = 'loopData',
  DocumentPages = 'documentPages',
  DocumentFields = 'documentFields',
  UserSignatures = 'userSignatures',
  DrawnSignatures = 'drawnSignatures',
  Notifications = 'notifications',
  TemplateFolders = 'templateFolders',
  Placeholders = 'placeholders',
  LoopTaskLists = 'loopTaskLists',
  LoopTaskListItems = 'loopTaskListItems',
  TemplateTaskLists = 'templateTaskLists',
  TemplateTaskListItems = 'templateTaskListItems',
  LoopPeople = 'loopPeople',
}

export type AttributeType = ATTRIBUTE_TYPE;
export type RelationshipType = RELATIONSHIP_TYPE;

interface BaseRelationshipDefinition {
  relationshipType: RelationshipType;
}
interface DefaultRelationshipDefinition extends BaseRelationshipDefinition {
  resourceType: ResourceTypes;
}
interface PolymorphicRelationshipDefinition extends BaseRelationshipDefinition {
  resourceType: Array<ResourceTypes>;
  polymorphicResourceName: string;
}
export type RelationshipDefinition =
  | DefaultRelationshipDefinition
  | PolymorphicRelationshipDefinition;

export type RelationshipsDefinition = {
  [relationshipName: string]: RelationshipDefinition;
};

type MetaDefinition = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [attributeName: string]: any;
};

export type ResourceDefinition = {
  attributes: {
    [attributeName: string]: AttributeType;
  };
  relationships?: RelationshipsDefinition;
  meta?: MetaDefinition;
};
