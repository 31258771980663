import { createRef, Component, Fragment } from 'react';

import WindowResizeListener from '~/components/WindowResizeListener';

import { getWindowScrollAttributes } from '~/lib/utils/scroll';
import { usePortalContext } from './Portal';

const DEFAULT_POSITION_STYLES = {};

export enum Position {
  TopRight = 'top-right',
  TopLeft = 'top-left',
  BottomRight = 'bottom-right',
  BottomLeft = 'bottom-left',
}

export type PositionStyles = {
  top?: number | string;
  right?: number | string;
  bottom?: number | string;
  left?: number | string;
  minWidth?: number;
  width?: number;
  transform?: string;
};

function getRefHTMLElement(
  popoverElement: HTMLElement | null
): HTMLElement | null {
  if (popoverElement && popoverElement instanceof HTMLElement) {
    return popoverElement;
  } else {
    return null;
  }
}

function createPositionStyles(
  popover: HTMLElement | null,
  offset: HTMLElement | null,
  position: Position,
  fullWidthMenu: boolean,
  scrollContainerRef?: null | HTMLElement
): PositionStyles {
  const popoverElement = getRefHTMLElement(popover);
  const offsetElement = getRefHTMLElement(offset);
  const positionStyles: PositionStyles = {};

  if (~position.indexOf('top')) {
    positionStyles.top = 0;
    positionStyles.transform = 'translateY(-100%)';
  } else {
    positionStyles.bottom = 0;
    positionStyles.transform = 'translateY(100%)';
  }

  if (~position.indexOf('left')) {
    positionStyles.left = 0;
  } else {
    positionStyles.left = 'unset';
    positionStyles.right = 0;
  }

  if (fullWidthMenu && offsetElement) {
    positionStyles.width = offsetElement?.clientWidth;
  }

  if (!!popoverElement && !!offsetElement) {
    const popoverOffsetParent = popoverElement.offsetParent;
    const offsetParent = offsetElement.offsetParent;
    let { scrollOffset } = getWindowScrollAttributes();

    if (!!offsetParent && offsetParent instanceof HTMLElement) {
      positionStyles.minWidth = offsetParent.offsetWidth;

      if (popoverOffsetParent !== offsetParent) {
        // The contents of the popover are being rendered in a place where the
        // offset parent is different than the main offset parent, which is
        // what we use to determine positioning. This means we need to add in the
        // position of the main offset parent to the position styles
        const offsetCoords = offsetParent.getBoundingClientRect();
        const popoverOffsetCoords = !!popoverOffsetParent
          ? popoverOffsetParent.getBoundingClientRect()
          : document.body.getBoundingClientRect();

        if (!!scrollContainerRef) {
          scrollOffset = scrollContainerRef.scrollTop;
        }

        if (positionStyles.top === 0) {
          // This is a top positioned popover, so the top of the contents needs to match
          // the top of the main offset parent
          positionStyles.top = offsetCoords.top + scrollOffset;
        } else {
          // This is a bottom positioned popover, so the bottom of the contents needs to match
          // the bottom of the main offset parent
          delete positionStyles.bottom;
          positionStyles.top = offsetCoords.bottom + scrollOffset;
          positionStyles.transform = '';
        }

        if (!!scrollContainerRef) {
          positionStyles.top -= popoverOffsetCoords.top;
        }

        if (positionStyles.left === 0) {
          // This is a left positioned popover, so the left of the contents needs to match
          // the left of the main offset parent
          positionStyles.left = offsetCoords.left;
        } else {
          // This is a right positioned popover, so the left of the contents needs to match
          // the right of the main offset parent
          delete positionStyles.right;
          positionStyles.left = offsetCoords.right;
          positionStyles.transform += ' translateX(-100%)';
        }

        if (!!scrollContainerRef) {
          positionStyles.left -= popoverOffsetCoords.left;
        }
      }
    }
  }

  return positionStyles;
}

const BOUNDS_BUFFER = 20;
const DEFAULT_POSITION = Position.BottomLeft;
function getPopoverPosition(
  popover: HTMLElement | null,
  offset: HTMLElement | null,
  defaultPosition?: Position,
  scrollContainerRef?: null | HTMLElement,
  restrictToBounds?: boolean
): Position {
  let position = defaultPosition;

  if (!!popover) {
    let {
      scrollOffset,
      applicationHeight: height,
      windowWidth: width,
    } = getWindowScrollAttributes();
    const popoverElement = getRefHTMLElement(popover);
    const offsetElement = getRefHTMLElement(offset);
    let topEdge = 0;
    let leftEdge = 0;

    if (!!scrollContainerRef) {
      scrollOffset = scrollContainerRef.scrollTop;
      width = scrollContainerRef.scrollWidth;
      height = scrollContainerRef.scrollHeight;

      if (restrictToBounds) {
        const scrollRect = scrollContainerRef.getBoundingClientRect();

        topEdge = scrollRect.top;
        leftEdge = scrollRect.left;
      }
    }

    if (!!popoverElement && !!offsetElement) {
      const offsetParent = offsetElement.offsetParent;

      if (!!offsetParent && offsetParent instanceof HTMLElement) {
        const dimensions = offsetParent.getBoundingClientRect();

        const popoverTopBounds =
          dimensions.top + scrollOffset - popoverElement.clientHeight;
        const popoverBottomBounds =
          dimensions.bottom + scrollOffset + popoverElement.clientHeight;
        const popoverLeftBounds = dimensions.right - popoverElement.clientWidth;
        const popoverRightBounds = dimensions.left + popoverElement.clientWidth;

        const overflowsTop = popoverTopBounds - BOUNDS_BUFFER <= topEdge;
        const overflowsBottom = popoverBottomBounds + BOUNDS_BUFFER >= height;
        const overflowsLeft = popoverLeftBounds - BOUNDS_BUFFER <= leftEdge;
        const overflowsRight = popoverRightBounds + BOUNDS_BUFFER >= width;
        const validPositions: Array<Position> = [];

        // Ordering of these if statements dictates the preference order for anchoring:
        // * bottom-left
        // * bottom-right
        // * top-left
        // * top-right
        if (!overflowsRight && !overflowsBottom) {
          validPositions.push(Position.BottomLeft);
        }

        if (!overflowsLeft && !overflowsBottom) {
          validPositions.push(Position.BottomRight);
        }

        if (!overflowsRight && !overflowsTop) {
          validPositions.push(Position.TopLeft);
        }

        if (!overflowsLeft && !overflowsTop) {
          validPositions.push(Position.TopRight);
        }

        if (
          !defaultPosition ||
          validPositions.indexOf(defaultPosition) === -1
        ) {
          // No defaultPosition was given, or the default position given
          // is not valid, so use the first valid position
          position = validPositions[0];
        }
      }
    }
  }

  if (!position) {
    position = DEFAULT_POSITION;
  }

  return position;
}

type Ref = { current: null | HTMLDivElement };

type RenderProps = {
  shouldRender: boolean;
  isVisible: boolean;
  position: Position | null;
  positionStyles: PositionStyles;
  positionTripValueChanged: boolean;
  popoverRef: Ref;
};

type Props = {
  /** Open state of the popover */
  isOpen: boolean;
  /** Force the menu to be as wide as the parent */
  fullWidthMenu?: boolean;
  /** The default anchor position of the dropdown */
  defaultPosition?: Position;
  /** Value that, when changed, indicates the popover should recompute position */
  positionTripValue?: any;
  /** Whether or not the popover should calculate a position */
  shouldCalculatePosition?: boolean;
  /** Duration of the animation of the popover's children */
  exitDuration: number;
  /** Called when the popover enters */
  onEnter?: () => void;
  /** Called when the popover exits */
  onExit?: () => void;
  /** Function that renders the contents of the popover */
  render: (renderProps: RenderProps) => any;
  /** Whether or not to use passesd in position */
  shouldEnforcePosition?: boolean;
  /** scrollContainerRef and restrictToBounds handled by PortalContext */
  scrollContainerRef?: null | HTMLElement;
  restrictToBounds?: boolean;
};

type State = {
  shouldRender: boolean;
  isVisible: boolean;
  position: Position | null;
  positionStyles: PositionStyles;
  positionTripValueChanged: boolean;
};

export class Popover extends Component<Props, State> {
  req: number | null = null;
  timeout: number | null = null;
  popoverRef: Ref = createRef();
  offsetRef: Ref = createRef();

  static defaultProps = {
    shouldCalculatePosition: true,
  };

  state = {
    shouldRender: this.props.isOpen,
    isVisible: this.props.isOpen,
    position: null,
    positionStyles: DEFAULT_POSITION_STYLES,
    positionTripValueChanged: false,
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      shouldCalculatePosition,
      defaultPosition,
      positionTripValue,
      scrollContainerRef,
      shouldEnforcePosition,
      fullWidthMenu,
    } = this.props;
    const { position, isVisible } = this.state;
    const tripValueChanged = prevProps.positionTripValue !== positionTripValue;
    const defaultPositionChanged =
      prevProps.defaultPosition !== defaultPosition;
    const needsPosition = !position && isVisible;
    const shouldRecalculatePosition =
      shouldCalculatePosition &&
      (tripValueChanged || defaultPositionChanged || needsPosition);

    if (shouldRecalculatePosition) {
      const position =
        !!defaultPosition && shouldEnforcePosition
          ? defaultPosition
          : getPopoverPosition(
              this.popoverRef.current,
              this.offsetRef.current,
              defaultPosition,
              scrollContainerRef,
              this.props.restrictToBounds
            );
      const positionStyles = this.props.shouldCalculatePosition
        ? createPositionStyles(
            this.popoverRef.current,
            this.offsetRef.current,
            position,
            fullWidthMenu || false,
            scrollContainerRef
          )
        : DEFAULT_POSITION_STYLES;

      this.setState({
        position,
        positionStyles,
        positionTripValueChanged: tripValueChanged,
      });
    }

    if (
      prevProps.isOpen !== this.props.isOpen &&
      this.props.isOpen !== this.state.isVisible
    ) {
      this.setState({ positionTripValueChanged: tripValueChanged }, () => {
        this.updateComponentState(this.props.isOpen);
      });
    }

    if (prevState.shouldRender !== this.state.shouldRender) {
      if (this.state.shouldRender && this.props.onEnter) {
        this.props.onEnter();
      } else if (!this.state.shouldRender && this.props.onExit) {
        this.props.onExit();
      }
    }
  }

  componentWillUnmount() {
    this.clearHandlers();
  }

  invalidatePosition = () => {
    this.setState({ position: null });
  };

  clearHandlers = () => {
    if (this.req) {
      window.cancelAnimationFrame(this.req);
      this.req = null;
    }

    if (this.timeout !== null) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  };

  updateComponentState = (isOpening: boolean) => {
    this.clearHandlers();

    if (isOpening) {
      this.setState(
        {
          shouldRender: true,
        },
        () => {
          this.req = window.requestAnimationFrame(() => {
            this.req = null;
            this.setState({ isVisible: true });
          });
        }
      );
    } else {
      this.setState(
        {
          isVisible: false,
        },
        () => {
          this.timeout = window.setTimeout(() => {
            this.timeout = null;
            this.setState({
              shouldRender: false,
            });
          }, this.props.exitDuration);
        }
      );
    }
  };

  render() {
    const { shouldCalculatePosition, render } = this.props;
    const {
      shouldRender,
      isVisible,
      position,
      positionStyles,
      positionTripValueChanged,
    } = this.state;

    return (
      <Fragment>
        {shouldCalculatePosition && (
          <Fragment>
            <div ref={this.offsetRef} />
            <WindowResizeListener onResize={this.invalidatePosition} />
          </Fragment>
        )}
        {render({
          shouldRender,
          isVisible,
          position,
          positionStyles,
          positionTripValueChanged,
          popoverRef: this.popoverRef,
        })}
      </Fragment>
    );
  }
}

type PopoverProps = Omit<Props, 'scrollContainerRef'>;

export default function PopoverWrapper(props: PopoverProps) {
  const portalContext = usePortalContext();
  const portalElement = portalContext?.element ?? undefined;
  const restrictToBounds = portalContext?.restrictToBounds ?? false;

  return (
    <Popover
      {...props}
      scrollContainerRef={portalElement}
      restrictToBounds={restrictToBounds}
    />
  );
}
