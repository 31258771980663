import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { noop } from 'lodash-es';
import { memo, useEffect, useState } from 'react';
import APP_CONFIG from '~/config';
import {
  breakpoints,
  colors,
  fontWeights,
  screenSizes,
  spacing,
} from '~/styles';

import { Container } from './layout';
import { FormattedMessage } from './i18n';
import { SecondaryButton } from '~/components/buttons';
import Switch from './inputs/Switch';

import { PreferenceNames } from '~/store/features/api/resources/preference/constants';
import { useI18n, useMedia } from '~/hooks';
import { usePreferenceSelector } from '~/hooks/api/preference';

const StyledOptOutContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: 'auto',
});

const StyledFeedbackLinkText = styled.span({
  display: 'block',
});

const StyledFeedbackTextContainer = styled.div({
  display: 'flex',
});

const StyledIntroText = styled.span({
  display: 'none',
  fontWeight: fontWeights.bold,
  marginRight: spacing.normal,

  [breakpoints.MEDIUM]: {
    display: 'block',
  },
});

const StyledIntroContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const StyledContainer = styled(Container)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: spacing.small,
  paddingBottom: spacing.small,
});

const StyledWrapper = styled.div({
  backgroundColor: colors.primaryLight,
  color: colors.mainText,
});

const {
  ui: { phoenixUrl },
} = APP_CONFIG;

const pathnameLoopRegex = new RegExp(`^/loops/(\\d+)`);

function getLoopIdFromPath(pathname: string) {
  const match = pathname.match(pathnameLoopRegex);
  return match ? match[1] : null;
}

function OptOutBanner() {
  const { pathname } = useLocation();
  const i18n = useI18n();
  const isMediumScreen = useMedia({ minWidth: screenSizes.MEDIUM });
  const loopId = getLoopIdFromPath(pathname);

  const [saveInProgress, setSaveInProgress] = useState(false);

  const [optInPreference, updatePreference] = usePreferenceSelector(
    PreferenceNames.NovaOptInLoopDetails
  );
  const optOutPreferenceValue = optInPreference?.value ?? true;

  // Redirect to the old loop details when the preference is updated
  useEffect(() => {
    if (!optOutPreferenceValue) {
      if (loopId) {
        window.location.href = `${phoenixUrl}/my/loop/${loopId}`;
      }
    }
  }, [optOutPreferenceValue, loopId]);

  const optOut = async () => {
    setSaveInProgress(true);
    await updatePreference({ value: false });
  };

  if (!loopId) {
    return null;
  }

  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledIntroContainer>
          <StyledIntroText>
            <FormattedMessage id="optOutBanner.introText" />
          </StyledIntroText>

          <StyledFeedbackTextContainer>
            <StyledFeedbackLinkText>
              <SecondaryButton id="feedback-button" onClick={noop}>
                <FormattedMessage
                  id={
                    isMediumScreen
                      ? 'optOutBanner.feedbackActionText'
                      : 'optOutBanner.shareFeedbackActionText'
                  }
                />
              </SecondaryButton>
            </StyledFeedbackLinkText>
          </StyledFeedbackTextContainer>
        </StyledIntroContainer>

        <StyledOptOutContainer>
          <Switch
            id="beta-opt-in-switch"
            onChange={optOut}
            isChecked={optOutPreferenceValue}
            isInProgress={saveInProgress}
            label={i18n.t('optOutBanner.switchText')}
          />
        </StyledOptOutContainer>
      </StyledContainer>
    </StyledWrapper>
  );
}

export default memo(OptOutBanner);
