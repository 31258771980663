import React, { useEffect, useRef } from 'react';
import { usePrevious } from '~/hooks';
import { useAccount } from '~/hooks/api/account';

import APP_CONFIG from '~/config';

const PendoInitializer: React.FC = () => {
  const accountData = useAccount();
  const { currentProfile } = accountData;
  const previousProfile = usePrevious(currentProfile);
  const isPendoInitialized = useRef(false);

  useEffect(() => {
    /* eslint-disable @typescript-eslint/no-explicit-any, prefer-rest-params */
    (function (apiKey: string) {
      /**
       * Bootstrap the `pendo` namespace for deferred execution of common methods
       * until the Agent is fully loaded from the CDN. The deferred methods are:
       * - initialize
       * - identify
       * - updateOptions
       * - pageLoad
       */
      (function (p: any, e: any, n: string, d: string, ...o: any) {
        o = p[d] = p[d] || {};
        o._q = [];
        const v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
        for (let w = 0, x = v.length; w < x; ++w) {
          (function (m: string) {
            o[m] =
              o[m] ||
              function () {
                o._q[m === v[0] ? 'unshift' : 'push'](
                  [m].concat([].slice.call(arguments, 0))
                );
              };
          })(v[w]);
        }
        const y = e.createElement(n);
        y.async = !0;
        y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        const z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
    })(APP_CONFIG.analytics.pendoApiKey);
    /* eslint-enable @typescript-eslint/no-explicit-any, prefer-rest-params */
  }, []);

  useEffect(() => {
    const pendo = window.pendo;
    const pendoData = accountData?.pendo;

    // Guard against everything pendo related, since initialization could cause
    // issues with what exactly is loaded in `window.pendo`
    if (!!pendo && !!pendoData) {
      if (!isPendoInitialized.current && !!pendo.initialize) {
        pendo.initialize(pendoData);
        isPendoInitialized.current = true;
      } else if (
        !!previousProfile &&
        !!currentProfile &&
        previousProfile.id !== currentProfile.id
      ) {
        if (!!pendo.identify) {
          pendo.identify(pendoData);
        }

        if (!!pendo.loadGuides) {
          pendo.loadGuides();
        }
      }
    }
  }, [accountData, previousProfile, currentProfile, isPendoInitialized]);

  return null;
};

export default PendoInitializer;
