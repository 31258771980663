import styled from 'styled-components';

import { colors, utils } from '~/styles';
import BaseButton, { BaseButtonProps } from './BaseButton';
import { buttonStyleHelpers } from './utils';

const StyledBaseButton = styled(BaseButton)<BaseButtonProps>(props => ({
  border: `2px solid ${colors.errorText}`,
  boxShadow: '0 2px 2px 0 rgba(195, 31, 59, 0.25)',
  borderRadius: utils.buttonBorderRadius,
  color: colors.errorText,
  backgroundColor: colors.primaryBackgroundText,

  ...buttonStyleHelpers.focus(
    {
      color: colors.primaryBackgroundText,
      backgroundColor: colors.errorText,
      boxShadow: 'none',
    },
    props
  ),

  ...buttonStyleHelpers.hover(
    {
      color: colors.primaryBackgroundText,
      backgroundColor: colors.errorText,
      boxShadow: 'none',
    },
    props
  ),

  ...buttonStyleHelpers.active(
    {
      color: colors.primaryBackgroundText,
      backgroundColor: colors.errorText,
      boxShadow: 'none',
    },
    props
  ),
}));

export const NegativeButton = (props: BaseButtonProps) => {
  return <StyledBaseButton {...props} />;
};

export default NegativeButton;
