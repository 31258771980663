import { ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const mixins = {
  valid: {
    borderColor: colors.successText,
    opacity: 1,
    visibility: 'visible',
  },

  invalid: {
    borderColor: colors.errorText,
    opacity: 1,
    visibility: 'visible',
  },

  noOutline: {
    visibility: 'hidden',
    opacity: 0,
  },
};

const StyledOutline = styled.div<{
  $validEnabled: boolean;
  $invalidEnabled: boolean;
  $noOutlineEnabled: boolean;
}>(props => ({
  height: '100%',
  width: '100%',
  position: 'absolute',
  border: `2px dashed ${colors.lightBorder}`,
  ...(props.$validEnabled ? mixins.valid : {}),
  ...(props.$invalidEnabled ? mixins.invalid : {}),
  ...(props.$noOutlineEnabled ? mixins.noOutline : {}),
}));

type Props = {
  /** Whether a file has been dropped */
  hasAcceptedFile: boolean;
  /** File acceptance state the outline should reflect, valid or invalid file types */
  isValid: boolean;
  /** State of the outline. Active -> visible */
  isActive: boolean;
  /** Whether the outline is on a focused drop target */
  isFocused: boolean;
  /** Contents of the outline */
  children?: ReactNode;
};

const DropZoneOutline = (props: Props) => {
  const { isValid, isActive, hasAcceptedFile } = props;

  return (
    <StyledOutline
      $validEnabled={isActive && isValid}
      $invalidEnabled={isActive && !isValid}
      $noOutlineEnabled={hasAcceptedFile}
    >
      {props.children}
    </StyledOutline>
  );
};

DropZoneOutline.displayName = 'DropZoneOutline';

export default DropZoneOutline;
