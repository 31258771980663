import { createContext } from 'react';
import { noop } from 'lodash-es';

import { BaseNotificationMessage, NotificationMessage } from './types';

export type ContextValue = {
  removeNotification: (id: string) => void;
  showNotification: (message: NotificationMessage) => void;
  updateNotification: (message: BaseNotificationMessage) => void;
};

export const NotificationContext = createContext<ContextValue>({
  removeNotification: noop,
  showNotification: noop,
  updateNotification: noop,
});
