import { ProfileResource } from '~/store/features/api/resources/profile/types';

export enum AppPages {
  MyLoops = 'NOVA_OPT_IN_MY_LOOPS',
  LoopDetails = 'NOVA_OPT_IN_LOOP_DETAILS',
  DocEditor = 'NOVA_OPT_IN_DOCUMENT_EDITOR',
  MyAccount = 'NOVA_OPT_IN_MY_ACCOUNT',
  Dashboard = 'NOVA_OPT_IN_DASHBOARD',
  Tasks = 'NOVA_OPT_IN_TASKS',
  People = 'NOVA_OPT_IN_PEOPLE',
  Templates = 'NOVA_OPT_IN_TEMPLATES',
}

export enum UserRole {
  RealtorAgent = 1,
  TransactionCoordinator = 2,
  BrokerageManager = 3,
  PropertyManagement = 4,
  Mortgage = 5,
  EscrowTitle = 6,
  Attorney = 7,
  HomeWarranty = 8,
  MovingStorage = 9,
  HomeInsurance = 10,
  OtherHomeService = 11,
  Other = 12,
  Utilities = 13,
  HomeInspection = 14,
  HomeSecurity = 15,
  HomeImprovement = 16,
  BuyerSeller = 17,
  TeamLead = 18,
}

type NovaOptInPreferences = {
  [AppPages.MyLoops]?: boolean;
  [AppPages.LoopDetails]?: boolean;
  [AppPages.DocEditor]?: boolean;
  [AppPages.MyAccount]?: boolean;
  [AppPages.Dashboard]?: boolean;
  [AppPages.Tasks]?: boolean;
  [AppPages.People]?: boolean;
  [AppPages.Templates]?: boolean;
};

type PendoAttributes = {
  keyId: string | undefined;
  token: string | undefined;
  visitor: any | undefined;
  account: any | null;
  parentAccount: any | null;
};

export type AccountInfo = {
  userId: number;
  firstName: string;
  lastName: string;
  emailVerified: boolean;
  registrationComplete?: boolean;
  loopLimit: number;
  loopCount?: number;
  isAssumingControl: boolean;
  userFeatures: Array<string>;
  currentProfile: ProfileResource | null;
  novaOptInPreferences: NovaOptInPreferences | null;
  individualProfileCount: number;
  userRoleId?: number;
  pendo?: PendoAttributes;
  nrdsId: string;
  tagLine?: string;
  jobTitle?: string;
  minLoopCreatedDate?: string;
};

export type NavigationAccessType = {
  hasReporting: boolean;
  hasLoops: boolean;
  hasPeople: boolean;
  hasTemplates: boolean;
  hasTasks: boolean;
};
