import OutlinedSelectInput from '~/components/inputs/select/OutlinedSelectInput';
import useLoopRole from '~/hooks/api/useLoopRole';
import { LoopRoleResource } from '~/store/features/api/resources/loopRole/types';

type Props = {
  id: string;
  loopId: string;
  value: string | null;
  onChange: (value: string | null) => void;
  label: string;
  placeholderId: string;
};

const RoleDropdown = ({
  id,
  loopId,
  value,
  onChange,
  label,
  placeholderId,
}: Props) => {
  const { loopRoles } = useLoopRole({ loopId });
  return (
    <OutlinedSelectInput<LoopRoleResource, string>
      id={id}
      items={loopRoles}
      getItemText={item => item.name}
      value={value}
      onChange={onChange}
      label={label}
      placeholder={placeholderId}
      showPlaceholder
      required
    />
  );
};

export default RoleDropdown;
