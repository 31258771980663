import { startCase } from 'lodash-es';

import { ProfileType } from './constants';
import { ProfileResource } from './types';

export function sortCurrentProfileToTop(
  profiles: Array<ProfileResource>,
  currentProfileId: string
) {
  if (profiles) {
    return profiles.sort((profile1, profile2) => {
      const profile1IsActive = profile1.id === currentProfileId;
      const profile2IsActive = profile2.id === currentProfileId;

      if (profile1IsActive) {
        return -1;
      } else if (profile2IsActive) {
        return 1;
      } else {
        return profile1.name ? profile1.name.localeCompare(profile2.name) : -1;
      }
    });
  }

  return profiles;
}

export function getDirectIndividualProfiles(
  profiles: Array<ProfileResource>,
  userId: number
) {
  // We only want to display individual profiles
  // and profiles this user is a primary user of,
  // which removes the team admin use case
  // where a team admin is a direct user of an
  // individual profile
  return profiles
    ? profiles.filter(
        p =>
          p.profileType === ProfileType.Individual && p.primaryUserId === userId
      )
    : [];
}

export function getProfileNameDetails(profile: ProfileResource): {
  title: string;
  subtitle?: string;
} {
  const subtitle =
    profile.profileType !== ProfileType.Individual
      ? startCase(profile.profileType.toLowerCase())
      : profile.directParentProfileName;

  return { title: profile.name, subtitle };
}
