import { colors } from '~/styles';

import { BrandedColor, ColorPalette } from '~/styles/colors/types';
import { IconType } from '../types';

export default function calculateColor(
  brand: BrandedColor,
  iconType?: IconType
): string {
  switch (iconType) {
    case 'brand':
      return brand;
    case 'grey':
      return colors.secondaryText;
    case 'currentColor':
      return 'currentColor';
    default:
      const color = !!iconType
        ? colors[iconType as keyof ColorPalette]
        : colors.secondaryText;

      return Array.isArray(color) ? color[0] : color;
  }
}
