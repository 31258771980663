import { useAppSelector } from '~/store/hooks';

import {
  selectAccountData,
  selectCurrentProfile,
  AccountData,
} from '~/store/features/account/selectors';
import { ProfileResource } from '~/store/features/api/resources/profile/types';

export function useAccount(): AccountData {
  const accountData = useAppSelector(selectAccountData);

  return accountData;
}

export function useCurrentProfile(): ProfileResource {
  const currentProfile = useAppSelector(selectCurrentProfile);

  if (!currentProfile) {
    throw Error(`No current profile!`);
  }

  return currentProfile;
}
