import { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';

import { injectI18n } from '~/components/i18n';
import {
  requestNotificationPermission,
  showNotification,
} from '~/lib/utils/notifications';
import WebSocketService from '~/services/web-socket-service';

import { makeAllResourcesSelector } from '~/store/features/api/selectors';

import ConversationNotifications from './ConversationNotifications';
import { getConversationName } from './ConversationName';

import { ConversationResource } from '~/store/features/api/resources/conversation/types';
import { ConversationNotificationsType } from './ConversationNotifications';
import { I18nType } from '~/components/i18n/types';
import { AppState } from '~/store';
import { ResourceTypes } from '~/store/features/api/resources/types';
import { fetchAllResources } from '~/store/features/api/apiSlice';

type ReduxProps = {
  fetchAllConversations: () => Promise<any>;
  conversations: Array<ConversationResource>;
};

export type ConversationRenderProps = {
  isLoading: boolean;
  conversations: Array<ConversationResource>;
};

type ComponentProps = {
  i18n: I18nType;
  conversationVisible: boolean;
  selectedConversationId: string;
  viewConversation: (conversationId: string) => any;
  render: (renderProps: ConversationRenderProps) => ReactNode;
  webSocketService: WebSocketService | null;
};

type Props = ComponentProps & ReduxProps;

type State = {
  isLoading: boolean;
  conversationNotifications: ConversationNotificationsType;
};

export class ConversationFetcher extends Component<Props, State> {
  state: State = {
    isLoading: false,
    conversationNotifications: {},
  };

  componentDidMount() {
    requestNotificationPermission();
    this.fetchConversations();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    this.setupConversationSubscriptions();

    if (!document.hasFocus()) {
      Object.keys(this.state.conversationNotifications).forEach(
        conversationId => {
          if (
            prevState.conversationNotifications[conversationId] !==
              this.state.conversationNotifications[conversationId] &&
            !!this.state.conversationNotifications[conversationId]
          ) {
            this.showNativeNotification(conversationId);
          }
        }
      );
    }
  }

  fetchConversations = async () => {
    this.setState({ isLoading: true });

    try {
      await this.props.fetchAllConversations();
    } catch (e) {
      datadogRum.addError(e, {
        action: 'fetch-all-conversations',
      });
    }

    this.setState({ isLoading: false });
  };

  setupConversationSubscriptions = () => {
    this.props.conversations.forEach(conversation => {
      const { id } = conversation;
      const { webSocketService } = this.props;

      if (webSocketService && webSocketService.messaging) {
        webSocketService.messaging.subscribeToConversation(
          id,
          'message-sidebar',
          () => {
            if (!document.hasFocus()) {
              this.showNativeNotification(id);
            } else {
              const { conversationVisible, selectedConversationId } =
                this.props;
              const isConversationVisible =
                conversationVisible && selectedConversationId === id;

              if (!isConversationVisible) {
                this.setState(prevState => {
                  const { conversationNotifications } = prevState;
                  const numberOfMessages = conversationNotifications[id] || 0;
                  const newNumberOfMessages = numberOfMessages + 1;

                  return {
                    conversationNotifications: {
                      ...conversationNotifications,
                      [id]: newNumberOfMessages,
                    },
                  };
                });
              }
            }
          }
        );
      }
    });
  };

  showNativeNotification = (conversationId: string) => {
    // If our tab doesn't have focus and notifications changed,
    // try to show a native notification.
    const { i18n } = this.props;
    const numberOfMessages =
      this.state.conversationNotifications[conversationId];

    showNotification({
      title: i18n.t('messaging.nativeNotificationTitle', {
        count: numberOfMessages,
      }),
      body: i18n.t('messaging.newMessageNotification', {
        count: numberOfMessages,
        name: this.getNameForConversation(conversationId),
      }),
      tag: conversationId,
      icon: '/images/dotchat_logo.png',
      onClick: () => {
        this.props.viewConversation(conversationId);
        this.removeConversationNotification(conversationId);
      },
    });
  };

  getNameForConversation = (conversationId: string): string | null => {
    const conversation = this.props.conversations.find(
      x => x.id === conversationId
    );

    return getConversationName(conversation);
  };

  removeConversationNotification = (conversationId: string) => {
    this.setState(prevState => ({
      conversationNotifications: {
        ...prevState.conversationNotifications,
        [conversationId]: null,
      },
    }));
  };

  render() {
    const { render, conversations, viewConversation } = this.props;
    const { isLoading, conversationNotifications } = this.state;

    return (
      <Fragment>
        <ConversationNotifications
          conversationNotifications={conversationNotifications}
          getConversationName={this.getNameForConversation}
          viewConversation={viewConversation}
          removeConversationNotification={this.removeConversationNotification}
        />
        {render({
          isLoading,
          conversations,
        })}
      </Fragment>
    );
  }
}

const makeMapStateToProps = () => {
  const conversationsSelector = makeAllResourcesSelector<ConversationResource>(
    ResourceTypes.Conversations,
    {
      relationships: [
        'participants',
        'latestMessage.document',
        'latestMessage.sender',
        'latestMessage.statuses',
      ],
    }
  );

  return (state: AppState) => ({
    conversations: conversationsSelector(state),
  });
};

const mapDispatchToProps = {
  fetchAllConversations: () =>
    fetchAllResources({
      resourceName: ResourceTypes.Conversations,
      options: { apiVersion: 'v1_1' },
    }),
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(injectI18n(ConversationFetcher));
