import { noop } from 'lodash-es';
import OutlinedTextField from '~/components/inputs/text/OutlinedTextInput';

type Props = {
  id: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  disabled?: boolean;
  highlighted: boolean;
  validateFullName: (value: string) => string | undefined;
};

const FullNameInput = ({
  id,
  label,
  value,
  onChange,
  onBlur = noop,
  disabled,
  highlighted,
  validateFullName,
}: Props) => {
  return (
    <OutlinedTextField
      id={id}
      label={label}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      required
      disabled={disabled}
      highlighted={highlighted}
      validate={validateFullName}
    />
  );
};

export default FullNameInput;
