import styled from 'styled-components';

import { AvatarIcon } from '~/components/icons';
import { colors, fontSizes, spacing } from '~/styles';

import { deconstructNameIntoParts } from '~/lib/utils/name';
import { ParticipantResource } from '~/store/features/api/resources/participant/types';
import { LoopParticipantResource } from '~/store/features/api/resources/loopParticipant/types';
import { ContactResource } from '~/store/features/api/resources/contact/types';

import { getColorForName, getPersonName } from './utils';
import { useState } from 'react';
import { ComplianceMessageRecipientResource } from '~/store/features/api/resources/complianceMessageRecipient/types';
import { LoopPeopleResource } from '~/store/features/api/resources/loopPeople/types';

const mixins = {
  avatarContents: {
    fontSize: '0.75em',
  },

  avatarImage: {
    paddingTop: spacing.smallest,
  },
};

const StyledSpan_1 = styled.span<{
  $avatarContentsEnabled: boolean;
  $avatarImageEnabled: boolean;
}>(props => ({
  ...(props.$avatarContentsEnabled ? mixins.avatarContents : {}),
  ...(props.$avatarImageEnabled ? mixins.avatarImage : {}),
}));

const StyledAvatar = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  color: colors.primaryBackgroundText,
  backgroundColor: colors.primaryAction,
  borderRadius: '50%',
});

export type PersonResource =
  | ParticipantResource
  | LoopParticipantResource
  | ContactResource
  | ComplianceMessageRecipientResource
  | LoopPeopleResource;

type Props = {
  hasMultipleRecipients?: boolean;
  participant?: PersonResource;
  additionalNumber?: number;
  contentFontSize?: string;
  className?: string;
};

function getInitials(participant: PersonResource): string | null {
  const personName = getPersonName(participant);

  if (personName) {
    const parts = deconstructNameIntoParts(personName);

    const firstName = parts.firstName || '';
    const lastName = parts.lastName || '';

    if (!!firstName && !!lastName) {
      return `${firstName.charAt(0).toUpperCase()}${lastName
        .charAt(0)
        .toUpperCase()}`;
    }
    return null;
  } else {
    return null;
  }
}

function getAltText(participant: PersonResource | undefined): string {
  return !!participant ? getPersonName(participant) || ' ' : '';
}

export function Avatar({
  participant,
  additionalNumber,
  contentFontSize,
  hasMultipleRecipients = false,
  className,
}: Props) {
  const [imageLoadFailed, setImageLoadFailed] = useState(false);
  const contents = participant
    ? getInitials(participant)
    : additionalNumber
    ? `+${additionalNumber}`
    : null;
  let participantImageUrl = '';

  if (!!participant && participant.hasOwnProperty('imageUrl')) {
    participantImageUrl =
      (participant as LoopParticipantResource).imageUrl ?? '';
  }

  const participantColor = getColorForName(
    !!participantImageUrl && !imageLoadFailed ? '' : contents
  );

  return (
    <StyledAvatar
      style={{ backgroundColor: participantColor }}
      className={className}
    >
      {!!contents ? (
        <StyledSpan_1
          style={{ fontSize: contentFontSize || fontSizes.body.fontSize }}
          $avatarContentsEnabled={hasMultipleRecipients}
          $avatarImageEnabled={!imageLoadFailed && !!participantImageUrl}
        >
          {participantImageUrl && !imageLoadFailed ? (
            <img
              loading="lazy"
              src={participantImageUrl}
              alt={getAltText(participant)}
              onError={() => setImageLoadFailed(true)}
            />
          ) : (
            contents
          )}
        </StyledSpan_1>
      ) : (
        <AvatarIcon type="hintText" width="100%" />
      )}
    </StyledAvatar>
  );
}

export default Avatar;
