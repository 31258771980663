import { useState, useEffect, useRef } from 'react';

import refData, { RefData } from '~/lib/utils/refData';

export function useRefData<T extends RefData = RefData>(
  type: string
): Array<T> {
  const cacheRef = useRef<string>();
  const [data, setData] = useState(() => {
    cacheRef.current = type;

    return refData.getCached<T>(type);
  });

  useEffect(() => {
    let currentRefData = data;

    if (cacheRef.current !== type) {
      cacheRef.current = type;
      currentRefData = refData.getCached<T>(type);
      setData(currentRefData);
    }

    if (currentRefData.length === 0) {
      refData.get<T>(type).then(result => {
        setData(result);
      });
    }
  }, [type, data]);

  return data;
}

export default useRefData;
