import { FormattedMessage } from '~/components/i18n';
import styled from 'styled-components';
import differenceInHours from 'date-fns/difference_in_hours';

import { colors, fontSizes, fontWeights, spacing, utils } from '~/styles';

import { LoopResource } from '~/store/features/api/resources/loop/types';

const StyledText = styled.div<{ $isArchived: boolean }>(props => ({
  ...fontSizes.caption,
  position: 'relative',
  marginTop: 2,
  lineHeight: spacing.normal,
  textTransform: 'uppercase',
  color: props.$isArchived ? colors.white : colors.primaryBackgroundText,
}));

const StyledBackground = styled.div<{ $isArchived: boolean }>(props => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  borderRadius: utils.baseBorderRadius,
  backgroundColor: props.$isArchived
    ? colors.secondaryText
    : colors.primaryAction,
}));

const StyledContainer = styled.div({
  position: 'relative',
  marginBottom: -2,
  padding: `${spacing.smallest} ${spacing.small}`,
  fontWeight: fontWeights.bold,
});

const badgeTextIdMap = {
  new: 'loops:card.newIndicator',
  archived: 'loops:archived',
};

export function hasBadge(loopData: LoopResource): boolean {
  return !!getBadgeTextId(loopData);
}

function getBadgeTextId(loopData: LoopResource): string | null {
  const isNewLoop =
    loopData.createdDate &&
    differenceInHours(new Date(), loopData.createdDate) <= 24;

  let messageType;

  if (loopData.archived) {
    messageType = 'archived';
  } else if (isNewLoop) {
    messageType = 'new';
  }

  const badgeTextId = messageType
    ? badgeTextIdMap[messageType as keyof typeof badgeTextIdMap]
    : null;

  return badgeTextId;
}

type Props = {
  loopData: LoopResource;
};

const LoopListCardBadge = ({ loopData }: Props) => {
  const badgeTextId = getBadgeTextId(loopData);
  const isArchived = badgeTextId === badgeTextIdMap.archived;

  return !!badgeTextId ? (
    <StyledContainer>
      <StyledBackground $isArchived={isArchived} />
      <StyledText $isArchived={isArchived}>
        <FormattedMessage id={badgeTextId} />
      </StyledText>
    </StyledContainer>
  ) : null;
};

export default LoopListCardBadge;
