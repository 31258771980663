export enum FieldType {
  ContactTextbox = 'CONTACT_TEXTBOX',
  Checkbox = 'CHECKBOX',
  Date = 'DATE',
  DateTime = 'DATETIME',
  Time = 'TIME',
  Radio = 'RADIO',
  Signature = 'SIGNATURE',
  Initial = 'INITIAL',
  Textbox = 'TEXTBOX',
  Strike = 'STRIKE',
  Mask = 'MASK',
  Logo = 'LOGO',
}
