type PubNubConfig = {
  publishKey: string;
  subscribeKey: string;
};
type PubNubLookup = {
  local: PubNubConfig;
  dev: PubNubConfig;
  qa: PubNubConfig;
  stage: PubNubConfig;
  test: PubNubConfig;
  prod: PubNubConfig;
  [key: string]: PubNubConfig;
};
const pubNubLookup: PubNubLookup = {
  local: {
    publishKey: 'pub-c-7ea0e9b1-7bb1-4d46-8339-2f8143de1dc1',
    subscribeKey: 'sub-c-286bd2ee-53c7-11e8-89ba-521aa9825b79',
  },
  dev: {
    publishKey: 'pub-c-7ea0e9b1-7bb1-4d46-8339-2f8143de1dc1',
    subscribeKey: 'sub-c-286bd2ee-53c7-11e8-89ba-521aa9825b79',
  },
  qa: {
    publishKey: 'pub-c-656d5c1f-70f9-4f48-a4da-77984480cda1',
    subscribeKey: 'sub-c-2c3a864a-53c7-11e8-84ad-b20235bcb09b',
  },
  stage: {
    publishKey: 'pub-c-65c12800-cd17-450f-ad38-b8983998c4ab',
    subscribeKey: 'sub-c-4c7bb596-53c7-11e8-a697-1afc57e8b539',
  },
  test: {
    publishKey: 'pub-c-25862343-7d20-4bbf-9cc6-69c5d204a9ca',
    subscribeKey: 'sub-c-50e7be0e-53c7-11e8-8e86-76fe4fd11787',
  },
  prod: {
    publishKey: 'pub-c-25862343-7d20-4bbf-9cc6-69c5d204a9ca',
    subscribeKey: 'sub-c-50e7be0e-53c7-11e8-8e86-76fe4fd11787',
  },
};

export default pubNubLookup;
