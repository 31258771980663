import { forwardRef } from 'react';
import styled from 'styled-components';

import { colors, fontSizes, fontWeights, spacing } from '~/styles';
import BaseButton, { BaseButtonProps } from './BaseButton';
import { buttonStyleHelpers } from './utils';

type LinkButtonStyleProps = BaseButtonProps & {
  linkButtonWithChildrenEnabled: boolean;
  linkButtonWithIconOnlyEnabled: boolean;
  smallButtonEnabled: boolean;
  removeSidePaddingEnabled: boolean;
  removeTopBottomPaddingEnabled: boolean;
  buttonColor: string;
  pseudoColor: string;
};
const StyledBaseButton = styled(BaseButton)<LinkButtonStyleProps>(props => ({
  color: props.buttonColor,
  border: '1px solid transparent',
  backgroundColor: 'transparent',
  borderRadius: 0,

  '&&&:disabled': {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
  },

  ...(props.linkButtonWithChildrenEnabled
    ? {
        paddingTop: spacing.small,
        paddingBottom: spacing.small,

        ...buttonStyleHelpers.focus(
          {
            color: props.pseudoColor,
            borderBottom: `1px solid ${props.pseudoColor}`,
          },
          props
        ),

        ...buttonStyleHelpers.hover(
          {
            color: props.pseudoColor,
            borderBottom: `1px solid ${props.pseudoColor}`,
          },
          props
        ),

        ...buttonStyleHelpers.active(
          {
            color: props.pseudoColor,
            borderBottom: `1px solid ${props.pseudoColor}`,
          },
          props
        ),
      }
    : {}),
  ...(props.linkButtonWithIconOnlyEnabled
    ? {
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          opacity: 0.15,
          borderRadius: '50%',
        },

        ...buttonStyleHelpers.focus(
          {
            color: props.pseudoColor,

            '&:before': {
              backgroundColor: props.pseudoColor,
            },
          },
          props
        ),

        ...buttonStyleHelpers.hover(
          {
            color: props.pseudoColor,

            '&:before': {
              backgroundColor: props.pseudoColor,
            },
          },
          props
        ),

        ...buttonStyleHelpers.active(
          {
            color: props.pseudoColor,

            '&:before': {
              backgroundColor: props.pseudoColor,
            },
          },
          props
        ),
      }
    : {}),
  ...(props.smallButtonEnabled
    ? {
        ...fontSizes.callout,
        fontWeight: fontWeights.semiBold,
      }
    : {}),
  ...(props.removeSidePaddingEnabled
    ? {
        paddingLeft: 0,
        paddingRight: 0,
        minWidth: 0,
      }
    : {}),
  ...(props.removeTopBottomPaddingEnabled
    ? {
        paddingTop: 0,
        paddingBottom: 0,
      }
    : {}),
}));

export enum LinkButtonType {
  Plain = 'plain',
  Error = 'error',
  White = 'white',
  Warning = 'warning',
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
}

type LinkButtonBaseProps = {
  small?: boolean;
  truncate?: boolean;
  buttonStyle?: LinkButtonType;
  removeSidePadding?: boolean;
  removeTopBottomPadding?: boolean;
  loadingLableId?: string;
};

export type LinkButtonProps = LinkButtonBaseProps & BaseButtonProps;

function getButtonColorPaletteName(buttonStyle: LinkButtonType | undefined) {
  let color;

  switch (buttonStyle) {
    case LinkButtonType.Plain:
      color = 'black';
      break;
    case LinkButtonType.White:
      color = 'primaryBackgroundText';
      break;
    case LinkButtonType.Warning:
      color = 'warningIcon';
      break;
    case LinkButtonType.Error:
      color = 'errorIcon';
      break;
    case LinkButtonType.Secondary:
      color = 'secondaryText';
      break;
    case LinkButtonType.Success:
      color = 'successText';
      break;
    case LinkButtonType.Primary:
    default:
      color = 'primaryLink';
  }

  return color;
}

function LinkButton(
  props: LinkButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement | undefined | null>
) {
  const {
    small,
    truncate,
    buttonStyle,
    removeSidePadding,
    removeTopBottomPadding,
  } = props;

  const hasChildren = !!props.children;
  const defaultIconProps = !!props.defaultIconProps
    ? props.defaultIconProps
    : {};
  const iconType = getButtonColorPaletteName(props.buttonStyle);
  const iconProps = {
    ...defaultIconProps,
    type: iconType,
  };
  const colorName = getButtonColorPaletteName(buttonStyle);
  const focusColorName = `${colorName}Focus`;
  const color = (colors as any)[colorName];
  const pseudoColor = (colors as any)[focusColorName] || color;

  return (
    <StyledBaseButton
      innerRef={ref}
      {...props}
      defaultIconProps={iconProps}
      getIconProps={({ isHovered, isFocused, isActive }) => {
        return buttonStyle === LinkButtonType.Primary &&
          (isHovered || isFocused || isActive)
          ? { type: 'primaryLinkFocus' }
          : {};
      }}
      linkButtonWithChildrenEnabled={hasChildren}
      linkButtonWithIconOnlyEnabled={!hasChildren}
      smallButtonEnabled={!!small}
      removeSidePaddingEnabled={!!removeSidePadding}
      removeTopBottomPaddingEnabled={!!removeTopBottomPadding}
      pseudoColor={pseudoColor}
      buttonColor={color}
      truncateText={truncate}
    />
  );
}

const ForwardedLinkButton = forwardRef(LinkButton);
ForwardedLinkButton.defaultProps = {
  small: false,
  truncate: false,
  buttonStyle: LinkButtonType.Primary,
  removeSidePadding: false,
  removeTopBottomPadding: false,
  defaultIconProps: undefined,
};

export default ForwardedLinkButton;
