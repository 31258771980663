import { Component } from 'react';
import styled from 'styled-components';

import { PrimaryButton, LinkButton } from '~/components/buttons';
import { FormattedMessage, injectI18n } from '~/components/i18n';
import { EditIcon, MessagingIcon } from '~/components/icons';
import { getConversationName } from '~/components/Messaging/ConversationName';
import {
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  momentumScrollingStyles,
  spacing,
} from '~/styles';

import NoConversationsImage from './NoConversationsImage';
import ConversationListItem from './ConversationListItem';
import { HEADER_HEIGHT } from './NewConversationHeader';

import { ConversationResource } from '~/store/features/api/resources/conversation/types';
import { ConversationType } from '~/store/features/api/resources/conversation/constants';
import { I18nType } from '~/components/i18n/types';

const StyledConversationList = styled.ul({
  margin: 0,
  padding: 0,
});

const StyledNoConversationsAction = styled.div({
  textAlign: 'center',
});

const StyledNoConversationsMessage = styled.div({
  textAlign: 'center',
  marginBottom: spacing.large,
});

const StyledNoConversationsImage = styled.div({
  width: '100%',
  maxWidth: '250px',
  margin: `0 auto ${spacing.large}`,
});

const StyledNoConversersations = styled.div({
  position: 'fixed',
  top: '50%',
  left: '50%',
  width: '75%',
  transform: 'translate(-50%, -50%)',

  [breakpoints.MEDIUM]: {
    position: 'absolute',
    top: '20vh',
    transform: 'translateX(-50%)',
  },
});

const StyledConversationWrapper = styled.div({
  [breakpoints.MEDIUM]: {
    ...momentumScrollingStyles,
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    width: '100%',
    height: `calc(100% - ${HEADER_HEIGHT})`,
    backgroundColor: colors.white,
  },
});

const StyledComposeIcon = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  marginRight: `-${spacing.smaller}`,
});

const StyledConversationListTitle = styled.div({
  marginLeft: spacing.small,
});

const StyledConversationListHeader = styled.div({
  display: 'none',

  [breakpoints.MEDIUM]: {
    ...fontSizes.headline,
    display: 'flex',
    alignItems: 'center',
    height: HEADER_HEIGHT,
    padding: `${spacing.small} ${spacing.normal}`,
    fontWeight: fontWeights.bold,
    backgroundColor: colors.white,
    borderBottom: `1px solid ${colors.lightBorder}`,
  },

  [breakpoints.LARGE]: {
    ...fontSizes.secondaryTitle,
    padding: `${spacing.normal} ${spacing.normal} 0 ${spacing.larger}`,
  },
});

export const NEW_CONVERSATION_ID = 'new-conversation';

type Props = {
  i18n: I18nType;
  isLoading: boolean;
  selectedConversationId: string | null;
  isConversationVisible: boolean;
  conversations: Array<ConversationResource>;
  onConversationSelected: (conversationId: string) => void;
  createNewConversation: () => void;
};

export class ConversationList extends Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return (
      this.props.i18n !== nextProps.i18n ||
      this.props.isLoading !== nextProps.isLoading ||
      this.props.conversations !== nextProps.conversations ||
      this.props.selectedConversationId !== nextProps.selectedConversationId ||
      this.props.isConversationVisible !== nextProps.isConversationVisible
    );
  }

  render() {
    const {
      i18n,
      isLoading,
      conversations,
      selectedConversationId,
      isConversationVisible,
      onConversationSelected,
      createNewConversation,
    } = this.props;
    const isStartingNewConversation =
      selectedConversationId === NEW_CONVERSATION_ID;
    const sortedConversations = conversations.sort((a, b) => {
      const aSentDate =
        !!a.latestMessage && !!a.latestMessage.sentDate
          ? a.latestMessage.sentDate
          : null;
      const bSentDate =
        !!b.latestMessage && !!b.latestMessage.sentDate
          ? b.latestMessage.sentDate
          : null;

      if (!aSentDate && !bSentDate) {
        const aName = getConversationName(a) || '';
        const bName = getConversationName(b) || '';

        return aName.localeCompare(bName);
      } else if (!bSentDate) {
        return 1;
      } else if (!aSentDate) {
        return -1;
      } else {
        return bSentDate.getTime() - aSentDate.getTime();
      }
    });
    const newConversation: Array<ConversationResource> =
      isStartingNewConversation
        ? [
            {
              id: NEW_CONVERSATION_ID,
              name: i18n.t('messaging.create.title'),
              latestMessage: null,
              createdDate: new Date(),
              creatorParticipantId: '',
              // Force group type so 'name' property is used in list
              conversationType: ConversationType.Group,
              participants: [],
              messages: [],
            },
          ]
        : [];
    const conversationsToDisplay = newConversation.concat(sortedConversations);

    return (
      <div>
        <StyledConversationListHeader>
          <MessagingIcon type="black" width="2rem" />
          <StyledConversationListTitle>
            <FormattedMessage id="messaging.title" />
          </StyledConversationListTitle>
          <StyledComposeIcon>
            <LinkButton
              id="create-conversation"
              onClick={createNewConversation}
              icon={EditIcon}
              defaultIconProps={{
                width: '1.5rem',
                height: '1.5rem',
              }}
            />
          </StyledComposeIcon>
        </StyledConversationListHeader>
        <StyledConversationWrapper>
          {!isLoading && conversationsToDisplay.length === 0 ? (
            <StyledNoConversersations>
              <StyledNoConversationsImage>
                <NoConversationsImage />
              </StyledNoConversationsImage>
              <StyledNoConversationsMessage>
                <FormattedMessage id="messaging.noMessages" />
              </StyledNoConversationsMessage>
              <StyledNoConversationsAction>
                <PrimaryButton
                  id="no-conversations-create-conversation"
                  onClick={createNewConversation}
                >
                  <FormattedMessage id="messaging.startConversation" />
                </PrimaryButton>
              </StyledNoConversationsAction>
            </StyledNoConversersations>
          ) : (
            <StyledConversationList id="conversation-list">
              {conversationsToDisplay.map(conversation => (
                <ConversationListItem
                  key={conversation.id}
                  conversation={conversation}
                  selectedConversationId={selectedConversationId}
                  isConversationVisible={isConversationVisible}
                  onConversationSelected={onConversationSelected}
                />
              ))}
            </StyledConversationList>
          )}
        </StyledConversationWrapper>
      </div>
    );
  }
}

export default injectI18n(ConversationList);
