const DELIMETER = ' ';

export function constructNameFromParts(
  firstName: string,
  middleName: string,
  lastName: string
) {
  return [firstName, middleName, lastName].filter(n => n).join(DELIMETER);
}

export function deconstructNameIntoParts(name: string) {
  const nameParts = name.split(DELIMETER);

  if (nameParts.length === 2) {
    return {
      firstName: nameParts[0],
      lastName: nameParts[1],
    };
  } else if (nameParts.length >= 3) {
    return {
      firstName: nameParts[0],
      middleName: nameParts[1],
      lastName: nameParts[2],
    };
  } else {
    return {};
  }
}
