import { useState, useRef, useLayoutEffect, Fragment, MouseEvent } from 'react';
import { FormattedMessage } from '~/components/i18n';
import styled from 'styled-components';

import LoadingMoreIndicator from '~/components/LoadingMoreIndicator';
import Highlight from '~/components/Highlight';
import { ProfileAvatar } from '~/components/Avatar';
import SearchInput from '~/components/SearchInput';
import { LinkButton } from '~/components/buttons';
import { SettingsIcon } from '~/components/icons';

import { breakpoints, colors, fontSizes, fontWeights, spacing } from '~/styles';

import { useI18n } from '~/hooks';
import { useAccount } from '~/hooks/api/account';
import { useProfiles } from '~/hooks/api/profile';
import { ProfileType } from '~/store/features/api/resources/profile/constants';
import { getProfileNameDetails } from '~/store/features/api/resources/profile/utils';
import { ProfileResource } from '~/store/features/api/resources/profile/types';
import { LinkButtonType } from '~/components/buttons/LinkButton';

const mixins = {
  profileListItemActive: {
    position: 'relative',
    backgroundColor: colors.primaryLight,

    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      width: 4,
      backgroundColor: colors.primaryAction,
    },
  },

  profileHeaderWrapperWithBorder: {
    borderBottom: `1px solid ${colors.lightBorder}`,
  },
};

const StyledProfileList = styled.ul({
  margin: 0,
  padding: 0,
  paddingBottom: spacing.smaller,
});

const StyledProfileGroupHeader = styled.div({
  ...fontSizes.callout,
  color: colors.secondaryText,
  padding: spacing.normal,
  textTransform: 'uppercase',
});

const StyledProfileGroupListItem = styled.li({
  borderTop: `1px solid ${colors.lightBorder}`,

  '&:first-of-type': {
    borderTop: 'none',
  },
});

const StyledProfilesLoading = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: '3rem',
});

const StyledProfileGroupList = styled.ul({
  margin: 0,
  padding: 0,
  textAlign: 'left',

  [breakpoints.MEDIUM]: {
    maxHeight: '50vh',
  },
});

const StyledProfileHeaderWrapper = styled.div<{
  $profileHeaderWrapperWithBorderEnabled: boolean;
}>(props => ({
  position: 'relative',
  ...(props.$profileHeaderWrapperWithBorderEnabled
    ? mixins.profileHeaderWrapperWithBorder
    : {}),
}));

const StyledProfileSettingsIcon = styled.div({
  position: 'absolute',
  top: '50%',
  right: spacing.normal,
  transform: 'translateY(-50%)',
});

const StyledParentProfileName = styled.div({
  ...fontSizes.caption,
  color: colors.secondaryText,
});

const StyledProfileName = styled.div({
  lineHeight: '1.2rem',
  fontWeight: fontWeights.bold,
});

const StyledProfileDetails = styled.div({
  flex: '1 1 auto',
  marginLeft: spacing.normal,
});

const StyledProfileAvatar = styled.div({
  flex: `0 0 ${spacing.max}`,
  width: spacing.max,
  height: spacing.max,
});

const StyledProfileListItemButton = styled.button({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  margin: 0,
  padding: `${spacing.small} ${spacing.large}`,
  color: colors.mainText,
  backgroundColor: colors.white,
  border: 'none',
  outline: 'none',
  textAlign: 'left',
  cursor: 'pointer',

  '&:hover, &:focus': {
    backgroundColor: colors.menuItemHighlight,
    color: colors.mainText,
  },

  '&:disabled': {
    background: 'transparent',
    cursor: 'default',
  },
});

const StyledProfileListItem = styled.li<{
  $profileListItemActiveEnabled: boolean;
}>(props => ({
  position: 'relative',
  margin: 0,
  padding: 0,
  ...(props.$profileListItemActiveEnabled ? mixins.profileListItemActive : {}),
}));

const PROFILE_SEARCH_THRESHOLD = 10;

interface ProfileItemProps {
  profile: ProfileResource;
  hasManageBranding: boolean;
  searchQuery: string;
  currentProfileId: string;
  onProfileClick: (newCurrentProfile: ProfileResource) => void;
  onProfileSettingSelected: (profile: ProfileResource) => void;
  setShowMobileProfiles: (isOpen: boolean) => void;
}

function ProfileItem({
  hasManageBranding,
  profile,
  currentProfileId,
  onProfileClick,
  searchQuery,
  onProfileSettingSelected,
  setShowMobileProfiles,
}: ProfileItemProps) {
  const isActive = profile.id === currentProfileId;
  const { title, subtitle } = getProfileNameDetails(profile);

  return (
    <StyledProfileListItem
      key={profile.id}
      data-selected={isActive}
      $profileListItemActiveEnabled={isActive}
    >
      <StyledProfileListItemButton
        aria-label={`${title} - ${subtitle}`}
        disabled={isActive}
        onClick={() => onProfileClick(profile)}
      >
        <StyledProfileAvatar>
          <ProfileAvatar profile={profile} />
        </StyledProfileAvatar>

        <StyledProfileDetails>
          <StyledProfileName>
            <Highlight sourceText={title} findText={searchQuery} />
          </StyledProfileName>

          {!!subtitle && (
            <StyledParentProfileName>{subtitle}</StyledParentProfileName>
          )}
        </StyledProfileDetails>
      </StyledProfileListItemButton>

      {isActive && hasManageBranding && (
        <StyledProfileSettingsIcon>
          <LinkButton
            id={`profile-${profile.id}-settings`}
            icon={SettingsIcon}
            buttonStyle={LinkButtonType.Secondary}
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              setShowMobileProfiles(false);
              onProfileSettingSelected(profile);
            }}
          />
        </StyledProfileSettingsIcon>
      )}
    </StyledProfileListItem>
  );
}

interface Props {
  currentProfileId: string;
  hasManageBranding: boolean;
  onCurrentProfileChanged: (newCurrentProfile: ProfileResource) => any;
  onProfileSettingSelected: (profile: ProfileResource) => void;
  setShowMobileProfiles: (isOpen: boolean) => void;
}

interface ProfileGroups {
  individual: ProfileResource[];
  team: ProfileResource[];
  admin: ProfileResource[];
}

export function ProfileList({
  currentProfileId,
  hasManageBranding,
  onCurrentProfileChanged,
  onProfileSettingSelected,
  setShowMobileProfiles,
}: Props) {
  const i18n = useI18n();
  const [searchQuery, setSearchQuery] = useState('');
  const [isFetching, profiles] = useProfiles({ autoFetch: true });
  const account = useAccount();
  const profileGroups = profiles.reduce(
    (groups: ProfileGroups, profile: ProfileResource) => {
      const { profileType, name, isProfileAvailable, primaryUserId } = profile;
      const isAdminProfile = profileType !== ProfileType.Individual;
      const displayName = isAdminProfile
        ? i18n.t('account.adminProfileName', { name })
        : name;
      const includeProfileBySearchQeury = !!searchQuery
        ? displayName.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
        : true;

      if (includeProfileBySearchQeury && isProfileAvailable) {
        if (profileType !== ProfileType.Individual) {
          groups.admin.push(profile);
        } else if (primaryUserId !== account.userId) {
          groups.team.push(profile);
        } else {
          groups.individual.push(profile);
        }
      }

      return groups;
    },
    {
      individual: [],
      admin: [],
      team: [],
    }
  );

  const showSearch = profiles.length > PROFILE_SEARCH_THRESHOLD;
  const accountMenuListId = 'account-menu-list';
  const didScrollToProfile = useRef(false);

  useLayoutEffect(() => {
    if (!isFetching && profiles.length > 0 && !didScrollToProfile.current) {
      const selectedProfileListItem = document.querySelector(
        `#${accountMenuListId} li[data-selected=true]`
      );
      const accountMenu = document.getElementById('account-menu-list');

      if (
        !!selectedProfileListItem &&
        !!accountMenu &&
        !!accountMenu.parentElement
      ) {
        didScrollToProfile.current = true;

        const scrollContainer = accountMenu.parentElement;
        const li = selectedProfileListItem as HTMLLIElement;
        const { top: scrollContainerTop, height: scrollContainerHeight } =
          scrollContainer.getBoundingClientRect();
        const { top: liTop, height } = li.getBoundingClientRect();
        const liOffset = liTop - scrollContainerTop;

        if (liOffset + height > scrollContainerHeight) {
          window.requestAnimationFrame(() => {
            scrollContainer.scrollTop = liOffset;
          });
        }
      }
    }
  }, [isFetching, profiles]);

  const sortGroup = (group: ProfileResource[]) =>
    group.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));

  sortGroup(profileGroups.individual);
  sortGroup(profileGroups.admin);
  sortGroup(profileGroups.team);

  return (
    <Fragment>
      <StyledProfileHeaderWrapper
        $profileHeaderWrapperWithBorderEnabled={showSearch}
      >
        {showSearch && (
          <SearchInput
            id="search-filters"
            name="search-filters"
            inputLabel="Search filters"
            placeholder={i18n.t('account.searchProfiles')}
            clearOnBlur={false}
            value={searchQuery}
            onChange={setSearchQuery}
          />
        )}
      </StyledProfileHeaderWrapper>
      <StyledProfileGroupList id={accountMenuListId}>
        {isFetching ? (
          <StyledProfilesLoading>
            <LoadingMoreIndicator />
          </StyledProfilesLoading>
        ) : (
          <Fragment>
            {profileGroups.individual.length > 0 && (
              <StyledProfileGroupListItem>
                <StyledProfileGroupHeader>
                  <FormattedMessage id="account.profileGroups.individual" />
                </StyledProfileGroupHeader>
                <StyledProfileList>
                  {profileGroups.individual.map(profile => (
                    <ProfileItem
                      key={profile.id}
                      currentProfileId={currentProfileId}
                      hasManageBranding={hasManageBranding}
                      profile={profile}
                      searchQuery={searchQuery}
                      onProfileClick={onCurrentProfileChanged}
                      onProfileSettingSelected={onProfileSettingSelected}
                      setShowMobileProfiles={setShowMobileProfiles}
                    />
                  ))}
                </StyledProfileList>
              </StyledProfileGroupListItem>
            )}
            {profileGroups.admin.length > 0 && (
              <StyledProfileGroupListItem>
                <StyledProfileGroupHeader>
                  <FormattedMessage id="account.profileGroups.admin" />
                </StyledProfileGroupHeader>
                <StyledProfileList>
                  {profileGroups.admin.map(profile => (
                    <ProfileItem
                      key={profile.id}
                      currentProfileId={currentProfileId}
                      hasManageBranding={hasManageBranding}
                      profile={profile}
                      searchQuery={searchQuery}
                      onProfileClick={onCurrentProfileChanged}
                      onProfileSettingSelected={onProfileSettingSelected}
                      setShowMobileProfiles={setShowMobileProfiles}
                    />
                  ))}
                </StyledProfileList>
              </StyledProfileGroupListItem>
            )}
            {profileGroups.team.length > 0 && (
              <StyledProfileGroupListItem>
                <StyledProfileGroupHeader>
                  <FormattedMessage id="account.profileGroups.team" />
                </StyledProfileGroupHeader>
                <StyledProfileList>
                  {profileGroups.team.map(profile => (
                    <ProfileItem
                      key={profile.id}
                      currentProfileId={currentProfileId}
                      hasManageBranding={hasManageBranding}
                      profile={profile}
                      searchQuery={searchQuery}
                      onProfileClick={onCurrentProfileChanged}
                      onProfileSettingSelected={onProfileSettingSelected}
                      setShowMobileProfiles={setShowMobileProfiles}
                    />
                  ))}
                </StyledProfileList>
              </StyledProfileGroupListItem>
            )}
          </Fragment>
        )}
      </StyledProfileGroupList>
    </Fragment>
  );
}

export default ProfileList;
