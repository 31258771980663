import 'react-app-polyfill/ie11';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import smoothScroll from 'smoothscroll-polyfill';
import { ThemeProvider } from 'styled-components';
import { ThemeConstellation } from '@zillow/constellation';

import trackOfflineStatus from '~/lib/offline/trackStatus';
import { NotificationProvider } from '~/components/Notifications';
import APP_CONFIG from '~/config';
import store from '~/store';
import typography from '~/styles/typography';
import BrowserUpdate from '~/components/BrowserUpdate';
import BrowserOffline from '~/components/BrowserOffline';
import { clearOriginalPathCookies } from '~/lib/api/network';
import { datadogRum } from '@datadog/browser-rum';
import { initializeDatadog } from '~/lib/datadog';

import { loadPolyfillsIfNecessary } from './polyfill';
import * as serviceWorker from './serviceWorkerRegistration';
import IntlProvider from './IntlProvider';
import BrandProvider from './BrandProvider';
import ModalProvider from './ModalProvider';
import { FileDropZone } from '~/components/FileDragDrop';
import App from './App';
import { worker } from './mocks/browser';

// touch-action polyfill for removing 300ms delay on click
import 'hammer-timejs';

declare global {
  interface Window {
    __MAIN_SCRIPT_PARSED__?: boolean;
  }
}

// Tell `index.html` that the page has been parsed, so that any
// future errors don't accidentally send the user to the 'upgrade-browser.html'
// page.
(window as Window).__MAIN_SCRIPT_PARSED__ = true;

// Remove ORIGINAL_PATH cookies so future redirects don't accidentally come here
clearOriginalPathCookies();

// Datadog for error tracking / logging
initializeDatadog();

// polyfill for smooth scrolling in unsupported browsers like Safari and Edge
smoothScroll.polyfill();

const {
  ui: { basename },
} = APP_CONFIG;

if (process.env.NODE_ENV === 'development') {
  worker.start({
    onUnhandledRequest: 'bypass',
  });
}

const rootEl = document.getElementById('root');

if (!!rootEl) {
  loadPolyfillsIfNecessary(() => {
    // Setup typography system
    typography.injectStyles();

    trackOfflineStatus();

    ReactDOM.render(
      <ErrorBoundary
        fallbackRender={() => null}
        onError={(error, { componentStack }) => {
          datadogRum.addError(error, {
            boundary: 'root',
            componentStack,
          });
        }}
      >
        <Provider store={store}>
          <IntlProvider>
            <StrictMode>
              <BrandProvider>
                <ThemeProvider theme={ThemeConstellation}>
                  <BrowserRouter basename={basename}>
                    <NotificationProvider>
                      <FileDropZone>
                        <ModalProvider>
                          <BrowserUpdate />
                          <BrowserOffline />
                          <App />
                        </ModalProvider>
                      </FileDropZone>
                    </NotificationProvider>
                  </BrowserRouter>
                </ThemeProvider>
              </BrandProvider>
            </StrictMode>
          </IntlProvider>
        </Provider>
      </ErrorBoundary>,
      rootEl
    );

    // Register our service worker to serve some assets from a local cache
    serviceWorker.register();
  });
}
