import Typography, { BaseLine } from 'typography';

import colors from '~/styles/colors';

const typography = new Typography({
  baseFontSize: '16px',
  baseLineHeight: 1.5,
  blockMarginBottom: 0.8,
  headerColor: colors.mainText,
  headerFontFamily: ['ProximaNova', 'sans-serif'],
  bodyColor: colors.mainText,
  bodyFontFamily: ['ProximaNova', 'sans-serif'],
  headerWeight: 700,
  bodyWeight: 500,
  boldWeight: 700,
  overrideStyles: ({ rhythm }, options) => ({
    body: {
      letterSpacing: '0.28px',
    },
    'h1,h2,h3': {
      lineHeight: 1.3,
      marginBottom: rhythm(1 / 2),
      marginTop: rhythm(1),
    },
    'h4,h5,h6': {
      fontWeight: 500,
      lineHeight: 1.5,
      marginBottom: rhythm(1 / 4),
      marginTop: rhythm(1 / 2),
    },
    a: {
      color: colors.primaryLink,
      textDecoration: 'none',
      cursor: 'pointer',
    },
    'a:hover,a:active': {
      color: options.bodyColor,
    },
    'td, th': {
      borderBottom: 'none',
    },
    'input, textarea, select, button': {
      color: 'inherit',
      textTransform: 'inherit',
    },
    form: {
      margin: 0,
      padding: 0,
    },
    // Need an invalid rule here to override typography force setting the
    // last child element of a list item tag, which is more specific than
    // a single CSS class so messes up styling whenever trying to style
    // this element
    'li *:last-child': { marginBottom: 'none' },
  }),
});

interface FontSizes {
  /** Main title - 28px */
  mainTitle: BaseLine;
  /** Empty state title - 24px */
  emptyStateTitle: BaseLine;
  /** Secondary title - 20px */
  secondaryTitle: BaseLine;
  /** Headline - 18px */
  headline: BaseLine;
  /** Body - 16px */
  body: BaseLine;
  /** Callout - 14px */
  callout: BaseLine;
  /** Caption - 12px */
  caption: BaseLine;
}
export const fontSizes: FontSizes = {
  mainTitle: typography.adjustFontSizeTo('28px') as BaseLine,
  emptyStateTitle: typography.adjustFontSizeTo('24px') as BaseLine,
  secondaryTitle: typography.adjustFontSizeTo('20px') as BaseLine,
  headline: typography.adjustFontSizeTo('18px') as BaseLine,
  body: typography.adjustFontSizeTo('16px') as BaseLine,
  callout: typography.adjustFontSizeTo('14px') as BaseLine,
  caption: typography.adjustFontSizeTo('12px') as BaseLine,
};

export enum fontWeights {
  light = 300,
  regular = 400,
  medium = 500,
  semiBold = 600,
  bold = 700,
  extraBold = 800,
}

export const rhythm = typography.rhythm;

export default typography;
