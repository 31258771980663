import { useAppSelector } from '~/store/hooks';
import styled from 'styled-components';

import { makeResourceSelector } from '~/store/features/api/selectors';
import { Avatar } from '~/components/Avatar';
import { ArrowIcon } from '~/components/icons';
import { LinkButton } from '~/components/buttons';
import { FormattedMessage } from '~/components/i18n';
import { HEADER_HEIGHT, HEADER_HEIGHT_MEDIUM } from './ConversationHeader';

import {
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  spacing,
  utils,
} from '~/styles';
import { AppState } from '~/store';
import { RotationDirection } from '~/components/icons/types';
import { LinkButtonType } from '~/components/buttons/LinkButton';
import { ConversationResource } from '~/store/features/api/resources/conversation/types';
import { ParticipantResource } from '~/store/features/api/resources/participant/types';
import { RoutingType } from '~/store/features/api/resources/participant/constants';
import { ResourceTypes } from '~/store/features/api/resources/types';
import { useMemo } from 'react';

const AVATAR_OFFSET = '2rem';
const StyledParticipantSubtext = styled.div({
  ...utils.text.truncate,
  fontWeight: fontWeights.medium,
  color: colors.secondaryText,
});

const StyledParticipantText = styled.div({
  ...utils.text.truncate,
  marginLeft: spacing.normal,
  fontWeight: fontWeights.semiBold,
});

const StyledParticipantAvatar = styled.div({
  flex: '0 0 auto',
  width: AVATAR_OFFSET,
  height: AVATAR_OFFSET,
});

const StyledParticipant = styled.li({
  position: 'relative',
  padding: spacing.normal,
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${colors.secondaryText}`,

  [breakpoints.MEDIUM]: {
    border: 'none',
  },
});

const StyledParticipantList = styled.ul({
  margin: 0,
  padding: 0,
  height: '5rem',
});

const StyledHeaderText = styled.div({
  ...fontSizes.headline,
  fontWeight: fontWeights.bold,

  [breakpoints.MEDIUM]: {
    marginLeft: '2rem',
  },

  [breakpoints.LARGE]: {
    marginLeft: 0,
  },
});

const StyledBackButton = styled.div({
  display: 'none',

  [breakpoints.LARGE]: {
    display: 'block',
    marginRight: spacing.normal,
  },

  [breakpoints.XLARGE]: {
    display: 'none',
  },
});

const StyledBackButtonMobile = styled.div({
  position: 'absolute',
  top: '50%',
  left: spacing.normal,
  transform: 'translateY(-50%)',

  [breakpoints.LARGE]: {
    display: 'none',
  },
});

const StyledHeader = styled.div({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: HEADER_HEIGHT,
  borderBottom: `1px solid ${colors.lightBorder}`,

  [breakpoints.MEDIUM]: {
    height: HEADER_HEIGHT_MEDIUM,
    justifyContent: 'flex-start',
    padding: spacing.normal,
  },

  [breakpoints.LARGE]: {
    alignItems: 'center',
    padding: `${spacing.normal} ${spacing.large} 0`,
  },
});

const StyledContainer = styled.div({
  position: 'relative',
  height: '100%',
  width: '100%',
  background: colors.white,
});

interface Props {
  conversationId: string;
  toggleConversationDetails: (conversationId: string) => void;
}

function ConversationDetails({
  conversationId,
  toggleConversationDetails,
}: Props) {
  const selectConversation = useMemo(
    () =>
      makeResourceSelector<ConversationResource, undefined>(
        ResourceTypes.Conversations,
        (_state: AppState) => conversationId,
        {
          relationships: [
            ResourceTypes.Participants,
            'messages.sender',
            'messages.document',
            'latestMessage',
          ],
        }
      ),
    [conversationId]
  );
  const conversation = useAppSelector((state: AppState) =>
    selectConversation(state)
  );

  if (!conversation) {
    return null;
  }

  const participants = conversation.participants || [];
  const filteredParticipants = participants.filter(
    (p: ParticipantResource) => !p.me
  );

  return (
    <StyledContainer>
      <StyledHeader>
        <StyledBackButtonMobile>
          <LinkButton
            id="back-to-message-button-mobile"
            buttonStyle={LinkButtonType.Plain}
            removeSidePadding
            icon={ArrowIcon}
            defaultIconProps={{ direction: RotationDirection.Left }}
            link={`/messages/${conversationId}`}
          />
        </StyledBackButtonMobile>
        <StyledBackButton>
          <LinkButton
            id="back-to-message-button-desktop"
            buttonStyle={LinkButtonType.Plain}
            removeSidePadding
            icon={ArrowIcon}
            defaultIconProps={{ direction: RotationDirection.Left }}
            onClick={() => toggleConversationDetails(conversationId)}
          />
        </StyledBackButton>
        <StyledHeaderText>
          <FormattedMessage id="messaging.conversationDetailsHeader" />
        </StyledHeaderText>
      </StyledHeader>
      <StyledParticipantList>
        {filteredParticipants.map((participant: ParticipantResource) => {
          const {
            routingType,
            emailAddress,
            phoneNumber,
            firstName,
            lastName,
          } = participant;
          const participantRoute =
            routingType === RoutingType.Email ? emailAddress : phoneNumber;
          let displayName = participantRoute;
          let showSubtitle = false;

          if (firstName && lastName) {
            showSubtitle = true;
            displayName = `${firstName} ${lastName}`;
          }

          return (
            <StyledParticipant key={participant.id}>
              <StyledParticipantAvatar>
                <Avatar participant={participant} />
              </StyledParticipantAvatar>
              <StyledParticipantText>
                <div title={displayName}>{displayName}</div>
                {showSubtitle && (
                  <StyledParticipantSubtext title={participantRoute}>
                    {participantRoute}
                  </StyledParticipantSubtext>
                )}
              </StyledParticipantText>
            </StyledParticipant>
          );
        })}
      </StyledParticipantList>
    </StyledContainer>
  );
}

export default ConversationDetails;
