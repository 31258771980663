import LoopDetailsRedirect from '~/components/LoopDetailsRedirect';
import { createLoadablePage } from '~/pages/utils/createLoadablePage';
import { useParams } from 'react-router-dom';
import { useLoopDetailsOptInPreference } from '~/services/loop-service';

export const LoopDetailsContainer = createLoadablePage({
  loader: () =>
    import(
      /* webpackChunkName: "loop-details" */ './containers/LoopDetailsContainer'
    ),
  i18nNamespace: ['loop-details', 'signature-routing'],
  loading: () => null,
});

export function LoopDetails() {
  const params = useParams();
  const parsedLoopId = parseInt(params?.loopId || '');
  const loopId = isNaN(parsedLoopId) ? null : (params?.loopId as string);
  // Assume user can view the beta loop details page until we load the preference
  const novaOptInLoopDetailsPreferenceValue =
    useLoopDetailsOptInPreference(true);

  return novaOptInLoopDetailsPreferenceValue && !!loopId ? (
    <LoopDetailsContainer loopId={loopId} />
  ) : !!loopId ? (
    <LoopDetailsRedirect loopId={loopId} />
  ) : null;
}

export default LoopDetails;
