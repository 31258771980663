import styled from 'styled-components';

import { colors, spacing, utils } from '~/styles';
import BaseButton, { BaseButtonProps } from './BaseButton';
import { buttonStyleHelpers } from './utils';

const mixins = {
  clickedButton: {
    backgroundColor: colors.primaryLight,
  },
};

type BaseButtonStyleProps = BaseButtonProps & { clickedButtonEnabled: boolean };
const StyledBaseButton = styled(BaseButton)<BaseButtonStyleProps>(props => ({
  paddingTop: spacing.smaller,
  paddingBottom: spacing.smaller,
  border: `2px solid ${props.disabled ? 'transparent' : colors.primaryButton}`,
  boxShadow: '0 2px 2px 0 rgba(53, 143, 209, 0.25)',
  borderRadius: utils.buttonBorderRadius,
  color: colors.primaryButton,
  backgroundColor: colors.white,

  '&&&:disabled': {
    borderColor: 'transparent',
  },

  ...buttonStyleHelpers.focus(
    {
      color: colors.primaryBackgroundText,
      backgroundColor: colors.primaryButton,
      boxShadow: 'none',
    },
    props
  ),

  ...buttonStyleHelpers.hover(
    {
      color: colors.primaryBackgroundText,
      backgroundColor: colors.primaryButton,
      boxShadow: 'none',
    },
    props
  ),

  ...buttonStyleHelpers.active(
    {
      color: colors.primaryBackgroundText,
      backgroundColor: colors.primaryButton,
      boxShadow: 'none',
    },
    props
  ),

  ...(props.clickedButtonEnabled ? mixins.clickedButton : {}),
}));

type SecondaryButtonBaseProps = {
  isSelected?: boolean;
};

export type SecondaryButtonProps = BaseButtonProps & SecondaryButtonBaseProps;

export default function SecondaryButton(props: SecondaryButtonProps) {
  const defaultIconProps = !!props.defaultIconProps
    ? props.defaultIconProps
    : {};
  const iconProps = { ...defaultIconProps, type: 'primaryButton' };

  return (
    <StyledBaseButton
      {...props}
      defaultIconProps={iconProps}
      getIconProps={({ isHovered, isFocused, isActive }) => {
        return isHovered || isFocused || isActive
          ? { type: 'primaryBackgroundText' }
          : {};
      }}
      clickedButtonEnabled={!!props.isSelected}
    />
  );
}

SecondaryButton.defaultProps = {
  selected: false,
};
