import { Fragment } from 'react';
import styled from 'styled-components';

import { FormattedMessage } from '~/components/i18n';
import { breakpoints, colors, fontSizes, fontWeights, spacing } from '~/styles';

const StyledMemberId = styled.div({
  ...fontSizes.caption,
  color: colors.secondaryText,

  [breakpoints.MEDIUM]: {
    ...fontSizes.callout,
  },
});

const StyledMemberName = styled.div({
  fontWeight: fontWeights.bold,
  marginBottom: `-${spacing.smallest}`,

  [breakpoints.MEDIUM]: {
    ...fontSizes.secondaryTitle,
    marginBottom: 0,
  },
});

interface Props {
  id: number;
  name: string;
}

export function MemberInfo({ id, name }: Props) {
  return (
    <Fragment>
      <StyledMemberName>{name}</StyledMemberName>
      <StyledMemberId>
        <FormattedMessage id="account.memberId" values={{ id }} />
      </StyledMemberId>
    </Fragment>
  );
}
