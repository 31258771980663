import { FormattedMessage } from '~/components/i18n';
import styled from 'styled-components';

import Container from '~/components/layout/Container';
import { CloseIcon } from '~/components/icons';
import IconLink from '~/components/IconLink';
import { colors, fontSizes, fontWeights, headerHeight } from '~/styles';

const StyledIconLink = styled(IconLink)({
  color: colors.secondaryText,
  textDecoration: 'none',
});
const StyledHeaderCenter = styled.div({
  ...fontSizes.mainTitle,
  display: 'flex',
  flex: '2 0 auto',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  fontWeight: fontWeights.bold,
  color: colors.mainText,
});

const StyledHeaderLeft = styled.div({
  position: 'absolute',
  height: '1.75rem',
  width: '1.75rem',
  top: '50%',
  left: 0,
  transform: 'translateY(-50%)',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const StyledHeaderWrapper = styled.div({
  ...headerHeight.styles,
  position: 'relative',
  width: '100%',
});

const StyledContainer = styled(Container)({
  position: 'relative',
  backgroundColor: colors.white,
  borderBottom: `1px solid ${colors.lightBorder}`,
});

export function CreateLoopMobileHeader() {
  return (
    <StyledContainer>
      <StyledHeaderWrapper>
        <StyledHeaderLeft>
          <StyledIconLink link="/loops" icon={CloseIcon} />
        </StyledHeaderLeft>
        <StyledHeaderCenter>
          <FormattedMessage id="create-loop:title" />
        </StyledHeaderCenter>
      </StyledHeaderWrapper>
    </StyledContainer>
  );
}

export default CreateLoopMobileHeader;
