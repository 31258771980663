import { useEffect, useState, ReactNode, KeyboardEvent } from 'react';
import analytics, { Categories } from '~/analytics';

import { usePrevious } from '~/hooks';
import ConnectedLoopSearchContainer, {
  LoopSearchChildrenArgs,
} from './ConnectedLoopSearchContainer';

import {
  getSearchFilterParams,
  updateSearchFilterParams,
} from '~/lib/utils/query-params';
import { VALID_FILTER_QUERY_PARAMS } from './constants';
import { AppliedSortType, SortKey } from '~/components/loops/utils/sorting';

import { LoopsListPreference } from '~/store/features/api/resources/preference/types';
import { useLocation, useNavigate } from 'react-router-dom';

type Props = {
  children: (args: LoopSearchChildrenArgs) => ReactNode;
  resetPaging: () => void;
  loopsListPreference?: LoopsListPreference | null | undefined;
  preferencesLoaded?: boolean;
  pageNumber: number;
};

export enum SearchScope {
  Filtered = 'filtered',
  All = 'all',
}

export const USE_FILTERS_PARAM = 'useFilters';
export const SEARCH_FILTER_PARAM = 'loopSearch';
export const SEARCH_SORT_FILTER_PARAM = 'loopSearchSort';
export const SEARCH_SORT_DIRECTION_FILTER_PARAM = 'loopSearchSortDirection';
const VALID_SEARCH_FILTER_PARAMS = [
  USE_FILTERS_PARAM,
  SEARCH_FILTER_PARAM,
  SEARCH_SORT_FILTER_PARAM,
  SEARCH_SORT_DIRECTION_FILTER_PARAM,
];

function LoopSearchContainer(props: Props) {
  const { pageNumber, resetPaging, loopsListPreference, preferencesLoaded } =
    props;
  const location = useLocation();
  const navigate = useNavigate();
  const { search } = location;
  const result = getSearchFilterParams(
    location.search,
    VALID_SEARCH_FILTER_PARAMS
  );
  const useFilters = result[USE_FILTERS_PARAM];
  const searchParams = getSearchFilterParams(
    search,
    VALID_SEARCH_FILTER_PARAMS
  );
  const showRelevancySort = !!searchParams[SEARCH_FILTER_PARAM];
  const searchSort: AppliedSortType | null = showRelevancySort
    ? {
        sortId: searchParams[SEARCH_SORT_FILTER_PARAM],
        sortDirection: searchParams[SEARCH_SORT_DIRECTION_FILTER_PARAM],
      }
    : null;

  const [searchValue, setSearchValue] = useState<string>(
    result[SEARCH_FILTER_PARAM]
  );
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const [searchScope, setSearchScope] = useState<SearchScope>(
    useFilters === false ? SearchScope.All : SearchScope.Filtered
  );
  const previousLocation = usePrevious(location);

  useEffect(() => {
    const locationSearchChanged = previousLocation?.search !== location.search;

    if (locationSearchChanged) {
      const newSearch = getSearchFilterParams(search, [SEARCH_FILTER_PARAM]);

      if (!newSearch[SEARCH_FILTER_PARAM]) {
        handleSearchChange('');
      }
    }
  }, [search, previousLocation?.search, location.search]);

  const handleSearchSubmitted = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (pageNumber !== 1) {
        resetPaging();
      }
      const searchValue = e.currentTarget.value;
      analytics.track(Categories.APP_STATE, 'search_submitted', searchValue);
      toggleSearchResultsVisibility(false);
      updateSearchQueryParameter(searchValue);
    }
  };

  const updateSearchQueryParameter = (searchValue: string) => {
    const searchFilterParams = {
      [SEARCH_FILTER_PARAM]: !!searchValue ? searchValue : undefined,
      [SEARCH_SORT_FILTER_PARAM]: !!searchValue ? SortKey.Relevancy : undefined,
      [SEARCH_SORT_DIRECTION_FILTER_PARAM]: !!searchValue ? 'desc' : undefined,
    };

    updateSearchFilterParams(searchFilterParams, location, navigate);
  };

  const handleSearchChange = (searchValue: string) => {
    if (searchValue) {
      setShowSearchResults(true);
    }

    setSearchValue(searchValue);
  };

  const toggleSearchResultsVisibility = (showSearchResults: boolean) => {
    setShowSearchResults(showSearchResults);
  };

  const clearSearch = () => {
    handleSearchChange('');
    toggleSearchResultsVisibility(false);
    removeFilterQueryParams();
  };

  const removeFilterQueryParams = () => {
    const searchFilterParams: { [key: string]: string | undefined } = {};

    VALID_FILTER_QUERY_PARAMS.forEach(name => {
      searchFilterParams[name] = undefined;
    });

    updateSearchFilterParams(searchFilterParams, location, navigate);
  };

  const handleScopeToggle = () => {
    setSearchScope(
      searchScope === SearchScope.All ? SearchScope.Filtered : SearchScope.All
    );
  };

  return (
    <ConnectedLoopSearchContainer
      showRelevancy={showRelevancySort}
      searchValue={searchValue}
      searchScope={searchScope}
      searchSort={searchSort}
      loopsListPreference={loopsListPreference}
      preferencesLoaded={preferencesLoaded}
      onSearchChange={handleSearchChange}
      onSearchSubmitted={handleSearchSubmitted}
      onScopeToggle={handleScopeToggle}
      showSearchResults={showSearchResults}
      toggleSearchResultsVisibility={toggleSearchResultsVisibility}
      clearSearch={clearSearch}
      updateSearchQueryParameter={updateSearchQueryParameter}
    >
      {props.children}
    </ConnectedLoopSearchContainer>
  );
}

export default LoopSearchContainer;
