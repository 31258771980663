import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  apiCreate,
  apiCreateRelationship,
  apiDelete,
  apiDeleteRelationship,
  apiFetch,
  apiFetchAll,
  apiFetchRelationship,
  apiUpdate,
  apiUpdateRelationship,
} from '~/lib/api';
import {
  ApiError,
  ApiResource,
  JsonApiFetchOptions,
  ResourceAttributes,
  ResourceRelationshipLink,
} from '~/lib/api/types';
import { ResourceTypes } from './resources/types';
import resourceDefinitions from './resources';
import {
  camelize,
  serializeExisting,
  serializeNew,
  serializeRelationships,
} from './serialization';
import {
  generateOptimisticId,
  getInitialResources,
  updateFetchResourceMetadata,
} from './state-helpers';
import { ApiState } from './types';
import {
  addRelationships,
  deleteStateResource,
  getResourceNameForRelationship,
  insertOrUpdateResources,
  markQueryLookupStale,
  relationshipHelper,
  removeRelationships,
  rollbackStateResource,
  updateQueryLookup,
  updateRelationships,
  updateStateResourceMeta,
} from './state-helpers';
import { AppDispatch, AppState } from '~/store';

const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: AppState;
  dispatch: AppDispatch;
}>();

export class FetchException {
  errorMessages: string | null;
  data: Array<ApiError> | string;
  optional?: Record<string, unknown>;

  constructor(
    errorMessages: string | null = '',
    data: Array<ApiError> | string = '',
    optional?: Record<string, unknown>
  ) {
    this.errorMessages = errorMessages;
    this.data = data;
    this.optional = optional;
  }
}

const initialState: ApiState = {
  resources: getInitialResources(),
  isReading: 0,
};

export function getOptimisticId(
  state: ApiState,
  { resourceName, resourceAttributes, resourceRelationships }: CreateResourceArg
) {
  const resource = state.resources[resourceName];
  const resourceDefinition = resource.__definition__;
  const serializationResult = serializeNew(
    resourceName,
    resourceAttributes,
    resourceRelationships,
    resourceDefinition,
    state
  );
  const serializedResource =
    serializationResult.serializedResource as ApiResource;
  return generateOptimisticId(serializedResource);
}

type OptionMeta = { apiVersion?: string; meta?: Record<string, unknown> };
export type OptimisticOption = {
  isOptimistic?: boolean;
  allowRollback?: boolean;
  previousOptimisticId?: string;
};

type FetchAllResourcesArgs = {
  resourceName: ResourceTypes;
  options?: JsonApiFetchOptions;
  formatDataCallback?: (data: ApiResource[]) => ApiResource[];
};
export const fetchAllResources = createAppAsyncThunk(
  'api/fetchAllResources',
  async (
    { resourceName, options, formatDataCallback }: FetchAllResourcesArgs,
    { rejectWithValue }
  ) => {
    const camelizedResourceName = camelize(resourceName);

    try {
      const response = await apiFetchAll(camelizedResourceName, options);

      if (response.type === 'success') {
        if (formatDataCallback) {
          response.data = formatDataCallback(response.data);
        }
        return response;
      } else {
        const errorMessage = `Error fetching resource: ${resourceName}\n${JSON.stringify(
          response.data
        )}`;

        throw new FetchException(errorMessage, response.data);
      }
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  }
);

type FetchResourceArgs = {
  resourceName: ResourceTypes;
  resourceId: string;
  options?: JsonApiFetchOptions;
};
export const fetchResource = createAppAsyncThunk(
  'api/fetchResource',
  async (
    { resourceName, resourceId, options }: FetchResourceArgs,
    { rejectWithValue }
  ) => {
    const camelizedResourceName = camelize(resourceName);

    try {
      const response = await apiFetch(
        camelizedResourceName,
        resourceId,
        options
      );

      if (response.type === 'success') {
        return response;
      } else {
        throw new FetchException(null, response.data);
      }
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  }
);

/**
 * This is not being used in the application and the
 * existing implementation that is un-used seems incorrect based on the jsonAPI spec.
 * If we add support back in for this in the apiSlice, we should fix the test.
 */
type FetchResourceRelationshipArgs = {
  resourceName: ResourceTypes;
  resourceId: string;
  relationshipName: string;
  options?: JsonApiFetchOptions;
};
export const fetchResourceRelationship = createAppAsyncThunk(
  'api/fetchResourceRelationship',
  async (
    {
      relationshipName,
      resourceId,
      resourceName,
      options,
    }: FetchResourceRelationshipArgs,
    { rejectWithValue }
  ) => {
    const camelizedResourceName = camelize(resourceName);
    const camelizedRelationshipName = camelize(relationshipName);

    try {
      const response = await apiFetchRelationship(
        camelizedResourceName,
        resourceId,
        camelizedRelationshipName,
        options
      );

      if (response.type === 'success') {
        return response;
      } else {
        throw new FetchException(null, response.data);
      }
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  }
);

type CreateResourceArg = {
  resourceName: ResourceTypes;
  resourceAttributes: ResourceAttributes;
  resourceRelationships?: ResourceRelationshipLink;
  createOptions?: OptionMeta & OptimisticOption;
};
export const createResource = createAppAsyncThunk(
  'api/createResource',
  async (
    {
      resourceName,
      resourceAttributes,
      resourceRelationships,
      createOptions,
    }: CreateResourceArg,
    { fulfillWithValue, rejectWithValue, getState }
  ) => {
    const state = getState().api;
    const resource = state.resources[resourceName];

    if (!resource) {
      throw new FetchException(
        'The resource you are trying to update is not defined'
      );
    }

    const resourceDefinition = resource.__definition__;
    const { serializedResource, includedResources } = serializeNew(
      resourceName,
      resourceAttributes,
      resourceRelationships,
      resourceDefinition,
      state
    );

    try {
      const response = await apiCreate(
        serializedResource,
        includedResources,
        createOptions?.meta,
        createOptions?.apiVersion
      );

      if (response.type === 'success') {
        return fulfillWithValue(response);
      } else {
        throw new FetchException(null, response.data);
      }
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  }
);

type UpdateResourceArg = {
  resourceName: ResourceTypes;
  resourceId: string;
  attributesToUpdate: Record<string, unknown>;
  resourceRelationships?: ResourceRelationshipLink | null;
  updateOptions?: OptionMeta & OptimisticOption;
};
export const updateResource = createAppAsyncThunk(
  'api/updateResource',
  async (
    {
      resourceName,
      resourceId,
      attributesToUpdate,
      resourceRelationships,
      updateOptions,
    }: UpdateResourceArg,
    { rejectWithValue, getState }
  ) => {
    const state = getState().api;
    const resource = state.resources[resourceName];
    const resourceDefinition = resource.__definition__;
    const serializedResource = serializeExisting(
      resourceId,
      resourceName,
      attributesToUpdate,
      resourceRelationships,
      resourceDefinition,
      state
    );

    try {
      const response = await apiUpdate(
        serializedResource,
        updateOptions?.meta,
        updateOptions?.apiVersion
      );

      if (response.type === 'success') {
        return response.data?.length ? response.data : [serializedResource];
      } else {
        throw new FetchException(null, response.data, {
          optimisticId: resourceId,
        });
      }
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  }
);

type DeleteResourceArg = {
  resourceName: string;
  resourceId: string;
  deleteOptions?: OptionMeta;
};
export const deleteResource = createAppAsyncThunk(
  'api/deleteResource',
  async (
    { resourceName, resourceId, deleteOptions }: DeleteResourceArg,
    { rejectWithValue, fulfillWithValue }
  ) => {
    const camelizedResourceName = camelize(resourceName);
    try {
      const response = await apiDelete(
        camelizedResourceName,
        resourceId,
        deleteOptions?.meta
      );

      if (response.type === 'success') {
        return fulfillWithValue(response);
      } else {
        throw new FetchException(null, response.data);
      }
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  }
);

type DeleteRelationshipArg = {
  resourceName: ResourceTypes;
  resourceId: string;
  relationshipName: string;
  relationshipId: Array<string> | string;
};
export const deleteRelationship = createAppAsyncThunk(
  'api/deleteRelationship',
  async (
    {
      resourceName,
      resourceId,
      relationshipName,
      relationshipId,
    }: DeleteRelationshipArg,
    { fulfillWithValue, rejectWithValue, getState }
  ) => {
    const state = getState().api;
    const resource = state.resources[resourceName];
    const resourceDefinition = resource.__definition__;
    const camelizedResourceName = camelize(resourceName);
    const camelizedRelationshipName = camelize(relationshipName);
    const { relationships } = serializeRelationships(
      resourceName,
      { [relationshipName]: relationshipId },
      resourceDefinition
    );
    const relationshipData =
      relationships && relationships[relationshipName]
        ? relationships[relationshipName].data || []
        : [];

    try {
      const response = await apiDeleteRelationship(
        camelizedResourceName,
        resourceId,
        camelizedRelationshipName,
        relationshipData
      );

      if (response.type === 'success') {
        return fulfillWithValue(response);
      } else {
        throw new FetchException(null, response.data);
      }
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  }
);

type UpdateRelationshipArg = {
  resourceName: string;
  resourceId: string;
  relationshipName: string;
  relationshipId: Array<string> | string;
};
export const updateRelationship = createAppAsyncThunk(
  'api/updateRelationship',
  async (
    {
      resourceName,
      resourceId,
      relationshipName,
      relationshipId,
    }: UpdateRelationshipArg,
    { fulfillWithValue, rejectWithValue, getState }
  ) => {
    const state = getState().api;
    const camelizedResourceName = camelize(resourceName);
    const camelizedRelationshipName = camelize(relationshipName);
    const relationshipData = getRelationshipData(
      state,
      resourceName,
      relationshipName,
      relationshipId
    );

    try {
      const response = await apiUpdateRelationship(
        camelizedResourceName,
        resourceId,
        camelizedRelationshipName,
        relationshipData
      );

      if (response.type === 'success') {
        return fulfillWithValue(response);
      } else {
        throw new FetchException(null, response.data);
      }
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  }
);

type CreateRelationshipArg = {
  resourceName: ResourceTypes;
  resourceId: string;
  relationshipName: string;
  relationshipId: Array<string> | string;
  options?: OptionMeta;
  fetchOptions?: JsonApiFetchOptions;
};
export const createRelationship = createAppAsyncThunk(
  'api/createRelationship',
  async (
    {
      relationshipId,
      relationshipName,
      resourceId,
      resourceName,
      fetchOptions,
      options,
    }: CreateRelationshipArg,
    { rejectWithValue, fulfillWithValue, getState }
  ) => {
    const state = getState().api;
    const camelizedResourceName = camelize(resourceName);
    const camelizedRelationshipName = camelize(relationshipName);
    const relationshipData = getRelationshipData(
      state,
      resourceName,
      relationshipName,
      relationshipId
    );

    try {
      const response = await apiCreateRelationship(
        camelizedResourceName,
        resourceId,
        camelizedRelationshipName,
        relationshipData,
        options?.meta,
        fetchOptions
      );

      if (response.type === 'success') {
        return fulfillWithValue(response);
      } else {
        throw new FetchException(null, response.data);
      }
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  }
);

function getRelationshipData(
  state: ApiState,
  resourceName: string,
  relationshipName: string,
  relationshipId: Array<string> | string
) {
  const resource = state.resources[resourceName];
  const resourceDefinition = resource.__definition__;
  const { relationships } = serializeRelationships(
    resourceName,
    { [relationshipName]: relationshipId },
    resourceDefinition
  );
  const relationshipData = relationships?.[relationshipName]?.data ?? [];

  return relationshipData;
}

const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    apiStale: (state, { payload }: PayloadAction<string>) => {
      markQueryLookupStale(state, payload);
    },
    removeProfileRelatedResources: state => {
      for (const resource in resourceDefinitions) {
        if (resource !== ResourceTypes.Profiles) {
          state.resources[resource].data = {};
          state.resources[resource].queries = {};
        }
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAllResources.pending, (state, { meta }) => {
        const { resourceName, options } = meta.arg;
        state.isReading++;

        updateQueryLookup(state, resourceName, null, options, {
          hasBeenRequested: true,
          isFetching: true,
          fetchFailed: false,
          totalResourceCount: null,
        });
      })
      .addCase(fetchAllResources.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          const { resourceName, options } = meta.arg;
          const resources = payload.data;
          const totalResourceCount = payload.meta?.totalResourceCount;

          state.isReading--;

          updateQueryLookup(state, resourceName, resources, options, {
            isFetching: false,
            isStale: false,
            totalResourceCount,
          });

          insertOrUpdateResources(state, resources);
        }
      })
      .addCase(fetchAllResources.rejected, (state, { meta }) => {
        state.isReading--;

        const {
          arg: { resourceName, options },
        } = meta;

        updateQueryLookup(state, resourceName, null, options, {
          isFetching: false,
          fetchFailed: true,
          totalResourceCount: null,
        });
      });

    builder
      .addCase(fetchResource.pending, (state, { meta }) => {
        state.isReading++;
        const { resourceName, resourceId } = meta.arg;

        updateFetchResourceMetadata(state, resourceName, resourceId, {
          isFetching: true,
        });
      })
      .addCase(fetchResource.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          const { resourceName, resourceId } = meta.arg;
          const resources = payload.data;

          state.isReading--;

          updateFetchResourceMetadata(state, resourceName, resourceId, {
            isFetching: false,
          });

          insertOrUpdateResources(state, resources);
        }
      })
      .addCase(fetchResource.rejected, (state, { meta }) => {
        state.isReading--;

        const {
          arg: { resourceName, resourceId },
        } = meta;

        updateFetchResourceMetadata(state, resourceName, resourceId, {
          isFetching: false,
          fetchFailed: true,
        });
      });

    const getRelationshipType = (
      state: ApiState,
      { resourceName, relationshipName }: FetchResourceRelationshipArgs
    ) => {
      const resourceDefinition = state.resources[resourceName].__definition__;
      const relationshipDefinition =
        resourceDefinition.relationships?.[relationshipName];

      if (relationshipDefinition) {
        let relationshipType;

        if ('polymorphicResourceName' in relationshipDefinition) {
          // In polymorphic relationships, since there are multiple target resource types,
          // we need to operate under the name of the parent relationship.
          relationshipType = resourceName;
        } else {
          relationshipType = relationshipDefinition.resourceType;
        }

        return relationshipType;
      }
    };

    builder
      .addCase(fetchResourceRelationship.pending, (state, { meta }) => {
        state.isReading++;
        const { options } = meta.arg;
        const relationshipType = getRelationshipType(state, meta.arg);

        if (relationshipType) {
          updateQueryLookup(state, relationshipType, null, options, {
            hasBeenRequested: true,
            isFetching: true,
            fetchFailed: false,
            totalResourceCount: null,
          });
        }
      })
      .addCase(
        fetchResourceRelationship.fulfilled,
        (state, { meta, payload }) => {
          if (payload) {
            state.isReading--;

            const { options } = meta.arg;
            const resources = payload.data;
            const totalResourceCount = payload.meta?.totalResourceCount;
            const relationshipType = getRelationshipType(state, meta.arg);

            if (relationshipType) {
              updateQueryLookup(state, relationshipType, resources, options, {
                isFetching: false,
                isStale: false,
                totalResourceCount,
              });

              insertOrUpdateResources(state, resources);
            }
          }
        }
      )
      .addCase(fetchResourceRelationship.rejected, (state, { meta }) => {
        state.isReading--;

        const { arg } = meta;

        const relationshipType = getRelationshipType(state, arg);

        if (relationshipType) {
          updateQueryLookup(state, relationshipType, null, arg.options, {
            isFetching: false,
            fetchFailed: true,
            totalResourceCount: null,
          });
        }
      });

    builder
      .addCase(createResource.pending, (state, { meta }) => {
        const {
          resourceName,
          resourceAttributes,
          resourceRelationships,
          createOptions,
        } = meta.arg;
        const isOptimistic = createOptions?.isOptimistic;

        if (isOptimistic) {
          const previousOptimisticId = createOptions?.previousOptimisticId;
          const resource = state.resources[resourceName];
          const resourceDefinition = resource.__definition__;
          const serializationResult = serializeNew(
            resourceName,
            resourceAttributes,
            resourceRelationships,
            resourceDefinition,
            state
          );
          const serializedResource =
            serializationResult.serializedResource as ApiResource;
          const optimisticId = getOptimisticId(state, meta.arg);
          serializedResource.id = optimisticId;

          insertOrUpdateResources(state, [serializedResource], {
            existingOptimisticId: previousOptimisticId,
          });
        } else {
          updateStateResourceMeta(
            state,
            resourceName,
            'isCreating',
            'increment'
          );
        }
      })
      .addCase(createResource.fulfilled, (state, { meta, payload }) => {
        const { resourceName, createOptions } = meta.arg;

        updateStateResourceMeta(state, resourceName, 'isCreating', 'decrement');

        if (payload) {
          const optimisticId = createOptions?.isOptimistic
            ? getOptimisticId(state, meta.arg)
            : undefined;
          insertOrUpdateResources(state, payload.data, {
            existingOptimisticId: optimisticId,
          });
        }

        markQueryLookupStale(state, resourceName);
      })
      .addCase(createResource.rejected, (state, { meta }) => {
        const { resourceName, createOptions } = meta.arg;
        const isOptimistic = createOptions?.isOptimistic;
        const allowRollback = createOptions?.allowRollback;

        updateStateResourceMeta(state, resourceName, 'isCreating', 'decrement');

        if (isOptimistic && allowRollback) {
          const optimisticId = getOptimisticId(state, meta.arg);
          if (optimisticId) {
            deleteStateResource(state, resourceName, optimisticId);
          }
        }
      });

    builder
      .addCase(updateResource.pending, (state, { meta }) => {
        const {
          resourceName,
          resourceId: id,
          attributesToUpdate,
          updateOptions,
        } = meta.arg;
        const isOptimistic = updateOptions?.isOptimistic;

        if (isOptimistic) {
          const serializedResource = serializeExisting(
            id,
            resourceName,
            attributesToUpdate
          );
          insertOrUpdateResources(state, [serializedResource], {
            isOptimistic: true,
          });
        } else {
          updateStateResourceMeta(
            state,
            resourceName,
            'isUpdating',
            'increment'
          );
        }
      })
      .addCase(updateResource.fulfilled, (state, { meta, payload }) => {
        const { resourceName } = meta.arg;

        updateStateResourceMeta(state, resourceName, 'isUpdating', 'decrement');

        if (payload) {
          // TODO: Why is payload the raw array here vs it being under a data object like update?
          insertOrUpdateResources(state, payload);
        }
      })
      .addCase(updateResource.rejected, (state, { meta }) => {
        const { resourceName, resourceId: id, updateOptions } = meta.arg;
        const isOptimistic = updateOptions?.isOptimistic;
        const allowRollback = updateOptions?.allowRollback;

        updateStateResourceMeta(state, resourceName, 'isUpdating', 'decrement');

        if (isOptimistic && allowRollback) {
          rollbackStateResource(state, resourceName, id);
        }
      });

    builder
      .addCase(deleteResource.pending, (state, { meta }) => {
        const { resourceName } = meta.arg;

        updateStateResourceMeta(state, resourceName, 'isDeleting', 'increment');
      })
      .addCase(deleteResource.fulfilled, (state, { meta }) => {
        const { resourceName, resourceId } = meta.arg;

        updateStateResourceMeta(state, resourceName, 'isDeleting', 'decrement');
        deleteStateResource(state, resourceName, resourceId);
      })
      .addCase(deleteResource.rejected, (state, { meta }) => {
        const { resourceName } = meta.arg;

        updateStateResourceMeta(state, resourceName, 'isDeleting', 'decrement');
      });

    builder
      .addCase(deleteRelationship.pending, (state, { meta }) => {
        const { resourceName, relationshipName } = meta.arg;

        updateStateResourceMeta(state, resourceName, 'isUpdating', 'increment');
        updateStateResourceMeta(
          state,
          relationshipName,
          'isUpdating',
          'increment'
        );
      })
      .addCase(deleteRelationship.fulfilled, (state, { meta }) => {
        const { resourceName, resourceId, relationshipId, relationshipName } =
          meta.arg;

        updateStateResourceMeta(state, resourceName, 'isUpdating', 'decrement');
        removeRelationships(
          state,
          resourceName,
          resourceId,
          relationshipName,
          relationshipId
        );
      })
      .addCase(deleteRelationship.rejected, (state, { meta }) => {
        const { resourceName, relationshipName } = meta.arg;
        updateStateResourceMeta(state, resourceName, 'isUpdating', 'decrement');
        updateStateResourceMeta(
          state,
          relationshipName,
          'isUpdating',
          'decrement'
        );
      });

    builder
      .addCase(updateRelationship.pending, (state, { meta }) => {
        const { resourceName, resourceId, relationshipName, relationshipId } =
          meta.arg;

        updateStateResourceMeta(state, resourceName, 'isUpdating', 'increment');
        const { relationshipResourceType } = relationshipHelper(
          state,
          resourceName,
          resourceId,
          relationshipName,
          relationshipId
        );

        updateStateResourceMeta(
          state,
          relationshipResourceType,
          'isUpdating',
          'increment'
        );
      })
      .addCase(updateRelationship.fulfilled, (state, { meta }) => {
        const { resourceName, resourceId, relationshipName, relationshipId } =
          meta.arg;

        updateStateResourceMeta(state, resourceName, 'isUpdating', 'decrement');

        const { relationshipResourceType } = relationshipHelper(
          state,
          resourceName,
          resourceId,
          relationshipName,
          relationshipId
        );

        updateRelationships(
          state,
          resourceName,
          resourceId,
          relationshipName,
          relationshipId
        );

        updateStateResourceMeta(
          state,
          relationshipResourceType,
          'isUpdating',
          'decrement'
        );
      })
      .addCase(updateRelationship.rejected, (state, { meta }) => {
        const { resourceName, resourceId, relationshipName, relationshipId } =
          meta.arg;

        updateStateResourceMeta(state, resourceName, 'isUpdating', 'decrement');
        const { relationshipResourceType } = relationshipHelper(
          state,
          resourceName,
          resourceId,
          relationshipName,
          relationshipId
        );

        updateStateResourceMeta(
          state,
          relationshipResourceType,
          'isUpdating',
          'decrement'
        );
      });

    builder
      .addCase(createRelationship.pending, (state, { meta }) => {
        const { resourceName, relationshipName } = meta.arg;

        updateStateResourceMeta(state, resourceName, 'isUpdating', 'increment');

        const relationshipResourceName = getResourceNameForRelationship(
          state,
          resourceName,
          relationshipName
        );

        if (!!relationshipResourceName) {
          updateStateResourceMeta(
            state,
            relationshipResourceName,
            'isUpdating',
            'increment'
          );
        }
      })
      .addCase(createRelationship.fulfilled, (state, { meta }) => {
        const { relationshipId, relationshipName, resourceId, resourceName } =
          meta.arg;
        updateStateResourceMeta(state, resourceName, 'isUpdating', 'decrement');

        addRelationships(
          state,
          resourceName,
          resourceId,
          relationshipName,
          relationshipId
        );

        const relationshipResourceName = getResourceNameForRelationship(
          state,
          resourceName,
          relationshipName
        );

        if (!!relationshipResourceName) {
          updateStateResourceMeta(
            state,
            relationshipResourceName,
            'isUpdating',
            'decrement'
          );
        }
      })
      .addCase(createRelationship.rejected, (state, { meta }) => {
        const { resourceName, relationshipName } = meta.arg;

        updateStateResourceMeta(state, resourceName, 'isUpdating', 'decrement');

        const relationshipResourceName = getResourceNameForRelationship(
          state,
          resourceName,
          relationshipName
        );

        if (!!relationshipResourceName) {
          updateStateResourceMeta(
            state,
            relationshipResourceName,
            'isUpdating',
            'decrement'
          );
        }
      });
  },
});

export const { apiStale, removeProfileRelatedResources } = apiSlice.actions;

export default apiSlice.reducer;
