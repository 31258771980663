import { Fragment, MouseEvent } from 'react';
import styled from 'styled-components';
import FocusLock from 'react-focus-lock';
import { motion, AnimatePresence } from 'framer-motion';

import { CloseIcon, MessagingIcon } from '~/components/icons';
import Overlay from '~/components/Overlay';
import BaseButton from '~/components/buttons/BaseButton';
import {
  boxShadows,
  breakpoints,
  colors,
  fontWeights,
  screenSizes,
  zIndex,
} from '~/styles';

import NewConversation from './NewConversation';
import Conversation from './Conversation';
import ConversationDetails from './ConversationDetails';
import ConversationList, { NEW_CONVERSATION_ID } from './ConversationList';
import { isConversationUnread } from './ConversationListItem';
import ConversationFetcher, {
  ConversationRenderProps,
} from './ConversationFetcher';
import {
  useAppAriaHidden,
  useApplicationScrolling,
  useMedia,
  usePubNubWebSockeService,
} from '~/hooks';
import AccountUnverified from '~/components/AccountUnverified';

const TOGGLE_BUTTON_WIDTH_HEIGHT = '3.5rem';
const CONVERSATION_LIST_CONTAINER_WIDTHS: ContainerWidths = {
  NORMAL: '22rem',
  LARGE: '22rem',
  XLARGE: '20rem',
  XXLARGE: '25rem',
};
const CONVERSATION_MESSAGES_CONTAINER_WIDTHS: ContainerWidths = {
  NORMAL: '36rem',
  LARGE: '36rem',
  XLARGE: '30rem',
  XXLARGE: '40rem',
};
const CONVERSATION_DETAILS_CONTAINER_WIDTHS: ContainerWidths = {
  NORMAL: CONVERSATION_MESSAGES_CONTAINER_WIDTHS.NORMAL,
  LARGE: CONVERSATION_MESSAGES_CONTAINER_WIDTHS.LARGE,
  XLARGE: '20rem',
  XXLARGE: '20rem',
};
const ANIMATION_TIME_MS = 300;
const MOTION_TRANSITION = {
  duration: ANIMATION_TIME_MS / 1000,
  ease: 'easeInOut',
};
const mixins = {
  sidebarWrapperOpen: {
    zIndex: zIndex.overlayForeground,
  },
};

const StyledConversationDetailsContainer = styled(motion.div)({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  backgroundColor: colors.white,
  zIndex: 1,
  width: CONVERSATION_DETAILS_CONTAINER_WIDTHS.NORMAL,
  height: '100%',

  [breakpoints.LARGE]: {
    width: CONVERSATION_DETAILS_CONTAINER_WIDTHS.LARGE,
  },

  [breakpoints.XLARGE]: {
    boxShadow: '4px 2px 4px 0px rgba(0,0,0,0.24)',
    zIndex: 0,
    width: CONVERSATION_DETAILS_CONTAINER_WIDTHS.XLARGE,
  },

  [breakpoints.XXLARGE]: {
    width: CONVERSATION_DETAILS_CONTAINER_WIDTHS.XXLARGE,
  },
});

const StyledConversationMessagesContainer = styled(motion.div)({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  width: CONVERSATION_MESSAGES_CONTAINER_WIDTHS.NORMAL,
  backgroundColor: colors.white,
  borderRight: `1px solid ${colors.lightBorder}`,
  zIndex: 2,

  [breakpoints.LARGE]: {
    width: CONVERSATION_MESSAGES_CONTAINER_WIDTHS.LARGE,
  },

  [breakpoints.XLARGE]: {
    width: CONVERSATION_MESSAGES_CONTAINER_WIDTHS.XLARGE,
  },

  [breakpoints.XXLARGE]: {
    width: CONVERSATION_MESSAGES_CONTAINER_WIDTHS.XXLARGE,
  },
});

const StyledConversationListContainer = styled(motion.div)({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  width: CONVERSATION_LIST_CONTAINER_WIDTHS.NORMAL,
  borderRight: `1px solid ${colors.lightBorder}`,
  zIndex: 3,

  [breakpoints.LARGE]: {
    width: CONVERSATION_LIST_CONTAINER_WIDTHS.LARGE,
  },

  [breakpoints.XLARGE]: {
    width: CONVERSATION_LIST_CONTAINER_WIDTHS.XLARGE,
  },

  [breakpoints.XXLARGE]: {
    width: CONVERSATION_LIST_CONTAINER_WIDTHS.XXLARGE,
  },
});

const StyledToggleButtonUnreadIndicator = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  right: 0,
  width: '1.25rem',
  height: '1.25rem',
  borderRadius: '50%',
  fontWeight: fontWeights.bold,
  color: colors.primaryBackgroundText,
  backgroundColor: colors.notificationIcon,
  boxShadow: boxShadows.surround,
  transform: 'translate(10%, -10%)',
});

const StyledBaseButton = styled(BaseButton)({
  minWidth: '0',
  maxWidth: 'none',
  width: TOGGLE_BUTTON_WIDTH_HEIGHT,
  height: TOGGLE_BUTTON_WIDTH_HEIGHT,
  margin: 0,
  padding: 0,
  borderTopRightRadius: '50%',
  borderBottomRightRadius: '50%',
  backgroundColor: colors.primaryButton,
  boxShadow: boxShadows.messagingButton,
  border: 'none',
  outline: 'none',
  cursor: 'pointer',

  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    backgroundColor: colors.black,
    opacity: 0,
    transition: 'opacity 0.15s ease',
  },

  '&:hover, &:focus': {
    border: 'none',
    outline: 'none',

    '&:before': {
      opacity: 0.3,
    },
  },
});

const StyledToggleButtonContainer = styled(motion.div)({
  position: 'absolute',
  top: '50%',
  left: 0,
});

const StyledSidebarWrapper = styled.div<{
  $sidebarWrapperOpenEnabled: boolean;
}>(props => ({
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  width: 0,
  zIndex: zIndex.messaging,
  ...(props.$sidebarWrapperOpenEnabled ? mixins.sidebarWrapperOpen : {}),
}));

type ContainerWidths = {
  NORMAL: string;
  LARGE: string;
  XLARGE: string;
  XXLARGE: string;
};

type Props = {
  showConversationList: boolean;
  showConversation: boolean;
  showConversationDetails: boolean;
  selectedConversationId: string | null;
  searchTerm: string | null | undefined;
  createNewConversation: () => void;
  hideConversation: () => void;
  toggleSidebar: () => void;
  viewConversation: (conversationId: string, shouldToggle?: boolean) => void;
  toggleConversationDetails: () => void;
};

type ScreenSizeLabel = keyof ContainerWidths;

function getScreenSizeLabel(
  isLarge: boolean,
  isXLarge: boolean,
  isXXLarge: boolean
): ScreenSizeLabel {
  return isXXLarge
    ? 'XXLARGE'
    : isXLarge
    ? 'XLARGE'
    : isLarge
    ? 'LARGE'
    : 'NORMAL';
}

export function MessagingSidebar(props: Props) {
  const {
    showConversationList,
    showConversation,
    showConversationDetails,
    toggleConversationDetails,
    selectedConversationId,
    searchTerm,
    toggleSidebar,
    viewConversation,
    hideConversation,
    createNewConversation,
  } = props;
  const isLarge = useMedia({ minWidth: screenSizes.LARGE });
  const isXLarge = useMedia({ minWidth: screenSizes.XLARGE });
  const isXXLarge = useMedia({ minWidth: screenSizes.XXLARGE });
  const webSocketService = usePubNubWebSockeService();

  useAppAriaHidden(showConversationList);
  useApplicationScrolling(!showConversationList, 'messaging-sidebar');

  const handleConversationSelected = (conversationId: string) => {
    viewConversation(conversationId, true);
  };

  const screenSize = getScreenSizeLabel(isLarge, isXLarge, isXXLarge);

  const showSidebarToggle = showConversationList || isLarge;

  return (
    <ConversationFetcher
      conversationVisible={showConversationList && showConversation}
      selectedConversationId={selectedConversationId}
      viewConversation={viewConversation}
      webSocketService={webSocketService}
      render={({ conversations, isLoading }: ConversationRenderProps) => {
        const showUnreadIndicator = isLoading
          ? false
          : conversations.some(isConversationUnread);

        return (
          <Fragment>
            <Overlay isVisible={showConversationList} onClick={toggleSidebar} />
            <FocusLock disabled={!showConversationList}>
              <StyledSidebarWrapper
                $sidebarWrapperOpenEnabled={showConversationList}
              >
                <StyledToggleButtonContainer
                  initial="default"
                  animate={
                    showConversationDetails
                      ? 'detailsVisible'
                      : showConversation
                      ? 'conversationVisible'
                      : showConversationList
                      ? 'listVisible'
                      : 'default'
                  }
                  transition={MOTION_TRANSITION}
                  variants={{
                    listVisible: {
                      y: '-50%',
                      x: `calc(${CONVERSATION_LIST_CONTAINER_WIDTHS[screenSize]} + 0rem + 0rem)`,
                    },
                    conversationVisible: {
                      y: '-50%',
                      x: `calc(${CONVERSATION_LIST_CONTAINER_WIDTHS[screenSize]} + ${CONVERSATION_MESSAGES_CONTAINER_WIDTHS[screenSize]} + 0rem)`,
                    },
                    detailsVisible: {
                      y: '-50%',
                      x: `calc(${CONVERSATION_LIST_CONTAINER_WIDTHS[screenSize]} + ${CONVERSATION_MESSAGES_CONTAINER_WIDTHS[screenSize]} + ${CONVERSATION_DETAILS_CONTAINER_WIDTHS[screenSize]})`,
                    },
                    default: {
                      y: '-50%',
                      x: 'calc(0rem + 0rem + 0rem)',
                    },
                  }}
                >
                  <AccountUnverified />
                  {showSidebarToggle && (
                    <StyledBaseButton
                      id="messaging-toggle"
                      onClick={(e: MouseEvent<HTMLButtonElement>) => {
                        // Lose focus on the button after it's clicked so it doesn't keep
                        // the hover / focus styling
                        e.currentTarget &&
                          e.currentTarget.blur &&
                          e.currentTarget.blur();
                        toggleSidebar();
                      }}
                      aria-label="messaging toggle"
                    >
                      <Fragment>
                        {showConversationList ? (
                          <CloseIcon
                            type="primaryBackgroundText"
                            height="1.5rem"
                          />
                        ) : (
                          <MessagingIcon
                            type="primaryBackgroundText"
                            height="2rem"
                          />
                        )}
                        {!showConversationList && showUnreadIndicator && (
                          <StyledToggleButtonUnreadIndicator>
                            !
                          </StyledToggleButtonUnreadIndicator>
                        )}
                      </Fragment>
                    </StyledBaseButton>
                  )}
                </StyledToggleButtonContainer>
                <AnimatePresence>
                  {showConversationList && (
                    <Fragment>
                      <StyledConversationListContainer
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        transition={MOTION_TRANSITION}
                        variants={{
                          hidden: {
                            x: '-100%',
                          },
                          visible: {
                            x: '0%',
                          },
                        }}
                      >
                        <ConversationList
                          isLoading={isLoading}
                          conversations={conversations}
                          isConversationVisible={showConversation}
                          selectedConversationId={selectedConversationId}
                          onConversationSelected={handleConversationSelected}
                          createNewConversation={createNewConversation}
                        />
                      </StyledConversationListContainer>
                      <StyledConversationMessagesContainer
                        initial="hidden"
                        animate={
                          showConversation
                            ? 'visible'
                            : showConversationList
                            ? 'expanded'
                            : 'hidden'
                        }
                        exit="hidden"
                        transition={MOTION_TRANSITION}
                        variants={{
                          hidden: {
                            x: 'calc(0rem + 0%)',
                          },
                          expanded: {
                            x: `calc(${CONVERSATION_LIST_CONTAINER_WIDTHS[screenSize]} + 0%)`,
                          },
                          visible: {
                            x: `calc(${CONVERSATION_LIST_CONTAINER_WIDTHS[screenSize]} + 100%)`,
                          },
                        }}
                      >
                        {!!selectedConversationId &&
                          showConversation &&
                          selectedConversationId !== NEW_CONVERSATION_ID && (
                            <Conversation
                              conversationId={selectedConversationId}
                              toggleConversationDetails={
                                toggleConversationDetails
                              }
                            />
                          )}
                        {!!selectedConversationId &&
                          selectedConversationId === NEW_CONVERSATION_ID && (
                            <NewConversation
                              viewConversation={viewConversation}
                              hideConversation={hideConversation}
                              searchTerm={searchTerm}
                            />
                          )}
                      </StyledConversationMessagesContainer>
                      <StyledConversationDetailsContainer
                        initial="hidden"
                        animate={
                          showConversationDetails
                            ? 'visible'
                            : showConversation
                            ? 'expanded'
                            : showConversationList
                            ? 'semiExpanded'
                            : 'hidden'
                        }
                        exit="hidden"
                        variants={{
                          hidden: {
                            x: 'calc(0rem + 0rem + 0%)',
                          },
                          semiExpanded: {
                            x: `calc(${CONVERSATION_LIST_CONTAINER_WIDTHS[screenSize]} + 0rem + 0%)`,
                          },
                          expanded: {
                            x: `calc(${CONVERSATION_LIST_CONTAINER_WIDTHS[screenSize]} + ${CONVERSATION_MESSAGES_CONTAINER_WIDTHS[screenSize]} + 0%)`,
                          },
                          visible: {
                            x: `calc(${CONVERSATION_LIST_CONTAINER_WIDTHS[screenSize]} + ${CONVERSATION_MESSAGES_CONTAINER_WIDTHS[screenSize]} + 100%)`,
                          },
                        }}
                      >
                        {!!selectedConversationId && (
                          <ConversationDetails
                            toggleConversationDetails={
                              toggleConversationDetails
                            }
                            conversationId={selectedConversationId}
                          />
                        )}
                      </StyledConversationDetailsContainer>
                    </Fragment>
                  )}
                </AnimatePresence>
              </StyledSidebarWrapper>
            </FocusLock>
          </Fragment>
        );
      }}
    />
  );
}

export default MessagingSidebar;
