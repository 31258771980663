import { AnyAction, AsyncThunkAction } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { ApiResource } from '~/lib/api/types';
import { AppDispatch, AppState } from '.';

/**
 * Would love to not use any here for the 'AsyncThunkAction' generics
 * but it is highly variable on all the async thunks we have to get it typed right.
 * Doing this is more type safe than just having this typed as 'any'
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ActionType = AnyAction | AsyncThunkAction<any, any, any>;

const _useAppDispatch: () => AppDispatch = useDispatch;

export const useAppDispatch: () => (
  action: ActionType
) => Promise<ApiResource[]> = () => {
  const originalDispatch = _useAppDispatch();
  const result = useCallback(
    async (action: ActionType) => {
      const dispatchResult = originalDispatch(action);

      /**
       * Not every dispatched action will have 'unwrap' on the result.
       * This is only when added when using the 'createAsyncThunk' to create the async thunk.
       * All other dispatched actions will not have 'unwrap' and we must guard against it.
       * Each slice has examples where there are actions that are not created with 'createAsyncThunk'
       */
      if ('unwrap' in dispatchResult) {
        const unwrapResult = await dispatchResult.unwrap();
        return unwrapResult?.data;
      }

      return dispatchResult;
    },
    [originalDispatch]
  );

  return result;
};
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
