import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import { useIsDesktop } from '~/hooks';
import { breakpoints, screenSizes, spacing, zIndex } from '~/styles';

import NotificationSnackbar from './NotificationSnackbar';
import { NotificationMessage } from '../types';

const StyledNotificationListItem = styled(motion.li)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: spacing.small,

  '&:first-of-type': {
    marginTop: 0,
  },

  padding: `${spacing.normal} ${spacing.normal}`,

  [breakpoints.LARGE]: {
    justifyContent: 'flex-end',
  },
});

const StyledNotificationList = styled.ul({
  margin: 0,
  padding: 0,
});

const StyledNotificationWrapper = styled.div({
  position: 'fixed',
  bottom: 0,
  left: '50%',
  width: '80%',
  transform: 'translateX(-50%)',
  zIndex: zIndex.notificationSnackbars,

  [breakpoints.LARGE]: {
    top: '4.5rem',
    bottom: 'unset',
    right: spacing.medium,
    left: 'unset',
    width: '434px',
    transform: 'unset',
  },

  [breakpoints.XLARGE]: {
    right: `calc(((100% - ${screenSizes.XLARGE}px) / 2) + ${spacing.medium})`,
  },

  [breakpoints.XXLARGE]: {
    right: `calc(((100% - ${screenSizes.XXLARGE}px) / 2) + ${spacing.medium})`,
  },
});

const transition = { duration: 0.3, ease: 'easeInOut' };

type Props = {
  notifications: Array<NotificationMessage>;
};

const NotificationList = (props: Props) => {
  const { notifications } = props;
  const isDesktop = useIsDesktop();

  return (
    <StyledNotificationWrapper>
      <StyledNotificationList id="notification-list">
        <AnimatePresence>
          {notifications.map(notification => (
            <StyledNotificationListItem
              positionTransition
              key={notification.id}
              transition={transition}
              initial="inactive"
              animate="active"
              exit="inactive"
              variants={{
                inactive: { opacity: 0, y: isDesktop ? '-100%' : '50%' },
                active: { opacity: 1, y: 0 },
              }}
            >
              <NotificationSnackbar
                type={notification.type}
                dismiss={notification.dismiss}
              >
                {notification.children}
              </NotificationSnackbar>
            </StyledNotificationListItem>
          ))}
        </AnimatePresence>
      </StyledNotificationList>
    </StyledNotificationWrapper>
  );
};

export default NotificationList;
