import { useState } from 'react';
import styled from 'styled-components';

import { colors, zIndex } from '~/styles';
import { useAccount } from '~/hooks/api/account';
import { ProfileType } from '~/store/features/api/resources/profile/constants';
import { getColorForName } from './utils';

const StyledLetter = styled.span({
  zIndex: zIndex.aboveSibling,
  textTransform: 'uppercase',
});

const StyledProfileAvatar = styled.div<{
  $adminAvatarEnabled: boolean;
  $teamAvatarEnabled: boolean;
  $customImageAvatarEnabled: boolean;
  $targetColor: string;
}>(props => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  color: colors.primaryBackgroundText,
  borderRadius: '50%',
  fontSize: '22px',
  lineHeight: '0',
  letterSpacing: '0.25px',
  backgroundColor: props.$targetColor,
  ...(props.$adminAvatarEnabled
    ? {
        color: props.$targetColor,
        backgroundColor: colors.white,
        border: `2px solid ${props.$targetColor}`,
      }
    : {}),
  ...(props.$teamAvatarEnabled
    ? {
        position: 'relative',
        backgroundColor: colors.white,
        border: `2px solid ${props.$targetColor}`,

        '&:before': {
          content: '""',
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '70%',
          height: '70%',
          borderRadius: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: props.$targetColor,
        },
      }
    : {}),
  ...(props.$customImageAvatarEnabled
    ? {
        backgroundColor: 'transparent',

        '> img': {
          width: '100%',
          height: '100%',
          borderRadius: '50%',
        },
      }
    : {}),
}));

interface ProfileLikeResource {
  name: string;
  imageUrl?: string;
  profileType?: ProfileType;
  primaryUserId?: number;
}

interface Props<T extends ProfileLikeResource> {
  profile: T;
  className?: string;
}

export function ProfileAvatar<T extends ProfileLikeResource>({
  profile,
  className,
}: Props<T>) {
  const [photoLoadFailed, setPhotoLoadFailed] = useState(false);
  const account = useAccount();
  const profileColor = getColorForName(profile.name);
  const showImage = !!profile.imageUrl && !photoLoadFailed;
  let profileGroup = 'individual';

  if (profile.profileType !== ProfileType.Individual) {
    profileGroup = 'admin';
  } else if (
    profile.primaryUserId !== undefined &&
    profile.primaryUserId !== account.userId
  ) {
    profileGroup = 'team';
  }

  return (
    <StyledProfileAvatar
      $adminAvatarEnabled={!showImage && profileGroup === 'admin'}
      $teamAvatarEnabled={!showImage && profileGroup === 'team'}
      $customImageAvatarEnabled={showImage}
      $targetColor={profileColor}
      className={className}
    >
      {showImage ? (
        <img
          loading="lazy"
          src={profile.imageUrl}
          alt={`Profile for ${profile.name}`}
          onError={() => setPhotoLoadFailed(true)}
        />
      ) : (
        <StyledLetter>{profile.name.charAt(0)}</StyledLetter>
      )}
    </StyledProfileAvatar>
  );
}

export default ProfileAvatar;
