import { ComponentType } from 'react';
import loadable, { Loadable, DefaultComponent } from 'loadable-components';

import i18n from '~/lib/i18n';

type loaderFunc<T> = () => Promise<DefaultComponent<T>>;
type Props<T> = {
  loader: loaderFunc<T>;
  loading: ComponentType;
  i18nNamespace: string | string[];
};

export function createLoadablePage<T>({
  loader,
  loading,
  i18nNamespace,
}: Props<T>): Loadable<T> {
  const loaderFunction: loaderFunc<T> = () => {
    const i18nPromise = new Promise<void>(resolve => {
      i18n.loadNamespaces(i18nNamespace, err => {
        if (err) {
          resolve(err);
        } else {
          resolve();
        }
      });
    });

    const result = Promise.all([loader(), i18nPromise]).then(
      ([component]) => component
    );

    return result as Promise<DefaultComponent<T>>;
  };

  return loadable(loaderFunction, {
    LoadingComponent: loading,
  });
}
