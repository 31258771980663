import { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import autoId from '~/lib/utils/auto-id';
import { createOptionalContextHelper } from '~/lib/utils/context';

export interface Context {
  element: HTMLElement | null;
  restrictToBounds: boolean;
}

const [usePortalContext, PortalProvider] =
  createOptionalContextHelper<Context>();

export { usePortalContext, PortalProvider };

type Props = {
  id: string;
  scrollContainerRef?: null | HTMLElement;
  restrictToBounds?: boolean;
  children: any;
};

// Note: This component needs to remain a class component due to
// how we test out portals in our test suite. see: test-helpers.js
export class InternalPortal extends PureComponent<Props> {
  portalElement: HTMLElement | null = null;
  scrollContainerPortalId: string;

  constructor(props: Props) {
    super(props);

    this.scrollContainerPortalId = `${props.id}-${autoId()}`;

    this.handlePortalInit();
  }

  handlePortalInit = () => {
    const { id, scrollContainerRef } = this.props;
    let portal: HTMLElement | null = window.document.getElementById(id);
    let shouldAppendElement = false;

    if (scrollContainerRef) {
      portal = scrollContainerRef.querySelector(
        `#${this.scrollContainerPortalId}`
      );
    }

    if (!portal) {
      shouldAppendElement = true;
      portal = window.document.createElement('div');
    }

    if (scrollContainerRef) {
      portal.setAttribute('id', this.scrollContainerPortalId);
    } else {
      portal.setAttribute('id', id);
    }

    if (shouldAppendElement) {
      let element: HTMLElement = window.document.body;

      if (scrollContainerRef) {
        element = scrollContainerRef;
      }

      element.appendChild(portal);
    }

    this.portalElement = portal;
  };

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.scrollContainerRef && !!this.props.scrollContainerRef) {
      this.handlePortalInit();
    }
  }

  render() {
    const { restrictToBounds = false } = this.props;

    if (!!this.portalElement) {
      return (
        <PortalProvider
          value={{ element: this.portalElement, restrictToBounds }}
        >
          {ReactDOM.createPortal(this.props.children, this.portalElement)}
        </PortalProvider>
      );
    }
  }
}

type PortalProps = Omit<Props, 'scrollContainerRef'> & {
  /**
   * If `true`, ignore any portal element context that might exist in the hierarchy
   * and force the portal to be appended to the HTML document body.
   */
  forceAppendToBody?: boolean;
};

export default function PortalWrapper({
  forceAppendToBody = false,
  ...portalProps
}: PortalProps) {
  const portalContext = usePortalContext();
  let portalElement: HTMLElement | undefined;

  if (!forceAppendToBody) {
    portalElement = portalContext?.element ?? undefined;
  }

  return <InternalPortal {...portalProps} scrollContainerRef={portalElement} />;
}
