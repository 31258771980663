import constants from './constants';

const prodFlags = {
  [constants.DarkMode]: false,
  [constants.LoopDetails]: false,
  [constants.ActivityLogDownload]: false,
  [constants.EditInPrivate]: true,
  [constants.RequiredEditPermissionToShare]: true,
  [constants.UsePubNubToken]: true,
};

export default prodFlags;
