interface OnlineOfflineHandler {
  (callback: (isOnline: boolean) => void, isOnline: boolean): void;
}

const handle: OnlineOfflineHandler = (
  callback: (isOnline: boolean) => void,
  result: boolean
) => {
  if (window.requestAnimationFrame) {
    window.requestAnimationFrame(() => callback(result));
  } else {
    setTimeout(() => callback(result), 0);
  }
};

const detectNetwork = (callback: (isOnline: boolean) => void) => {
  if (window && window.addEventListener) {
    window.addEventListener('online', () => handle(callback, true));
    window.addEventListener('offline', () => handle(callback, false));
    handle(callback, window.navigator.onLine);
  }
};

export default detectNetwork;
