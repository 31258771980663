import { ATTRIBUTE_TYPE } from '../constants';

import { ResourceDefinition } from '../types';

const TeamInvitation: ResourceDefinition = {
  attributes: {
    inviterName: ATTRIBUTE_TYPE.STRING,
    parentProfileId: ATTRIBUTE_TYPE.NUMBER,
    invitationStatus: ATTRIBUTE_TYPE.STRING,
  },
};

export default TeamInvitation;
