import { makeVegaRequest } from '~/lib/api/network';
import { ContextEntry, Categories } from './constants';

const METRICS_URL = 'metrics/event';

export function page(page: string) {
  sendToEventLog(Categories.PAGE_VIEW, page);
}

export function track(
  category: string,
  action: string,
  label: string | number,
  context?: ContextEntry
) {
  const labelWithContext = !!context
    ? [context.key, label.toString()].join('|')
    : label;
  sendToEventLog(category, action, labelWithContext);
}

function sendToEventLog(
  category: string,
  action: string,
  label?: string | number
) {
  makeVegaRequest(METRICS_URL, {
    method: 'POST',
    body: JSON.stringify({ category, action, label }),
  });
}
