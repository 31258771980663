export type IconType = string;
export enum RotationDirection {
  Left = 'left',
  Right = 'right',
  Up = 'up',
  Down = 'down',
}
export type IconProps = {
  type?: IconType;
  width?: string;
  height?: string;
  svgProps?: any;
  /** Needs to be proxied down in case this component is extended  */
  className?: string;
  direction?: RotationDirection;
};
export type BaseIconProps = {
  color: string; // Needed to support non-svg icons
  style: Record<string, unknown>;
  /** Needs to be proxied down in case this component is extended  */
  className?: string;
  svgProps?: any;
};
