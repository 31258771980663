import { createContext, useContext } from 'react';

export function createOptionalContextHelper<A extends unknown | null>() {
  const ctx = createContext<A | undefined>(undefined);

  function useCtx() {
    return useContext(ctx);
  }

  return [useCtx, ctx.Provider] as const;
}

export function createRequiredContextHelper<A extends unknown | null>(
  defaultValue: A | undefined = undefined
) {
  const ctx = createContext<A | undefined>(defaultValue);

  function useCtx() {
    const contextValue = useContext(ctx);

    if (contextValue === undefined) {
      throw new Error('Must be used inside a provider');
    }

    return contextValue;
  }

  return [useCtx, ctx.Provider, ctx] as const;
}
