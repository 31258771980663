import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';
import { ResourceDefinition, ResourceTypes } from '../types';

const LoopParticipant: ResourceDefinition = {
  attributes: {
    name: ATTRIBUTE_TYPE.STRING,
    emailAddress: ATTRIBUTE_TYPE.STRING,
    modifiable: ATTRIBUTE_TYPE.BOOLEAN,
    canChangeName: ATTRIBUTE_TYPE.BOOLEAN,
    imageUrl: ATTRIBUTE_TYPE.STRING,
    profileId: ATTRIBUTE_TYPE.NUMBER,
    invitationId: ATTRIBUTE_TYPE.NUMBER,
    profileTypeId: ATTRIBUTE_TYPE.NUMBER,
    loopId: ATTRIBUTE_TYPE.NUMBER,
    restricted: ATTRIBUTE_TYPE.BOOLEAN,
    hasEmail: ATTRIBUTE_TYPE.BOOLEAN,
    invitationStatus: ATTRIBUTE_TYPE.STRING,
    invitedByMemberId: ATTRIBUTE_TYPE.STRING,
  },

  relationships: {
    loopParticipantLinks: {
      resourceType: ResourceTypes.LoopParticipantLinks,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },

    profileAdmins: {
      resourceType: ResourceTypes.Users,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
  },
};

export default LoopParticipant;
