import { ATTRIBUTE_TYPE } from '../constants';

import { ResourceDefinition } from '../types';

const Preference: ResourceDefinition = {
  attributes: {
    type: ATTRIBUTE_TYPE.STRING,
    typeId: ATTRIBUTE_TYPE.NUMBER,
    value: ATTRIBUTE_TYPE.JSON_STRING,
    profileId: ATTRIBUTE_TYPE.NUMBER,
    userId: ATTRIBUTE_TYPE.NUMBER,
    scope: ATTRIBUTE_TYPE.STRING,
    inherited: ATTRIBUTE_TYPE.BOOLEAN,
  },
};

export default Preference;
