import { ContactResource } from '~/store/features/api/resources/contact/types';
import styled from 'styled-components';
import { colors, fontSizes, fontWeights, spacing } from '~/styles';
import { Avatar } from '~/components/Avatar';
import Highlight from '~/components/Highlight';
import { getRecipientName } from '~/components/Messaging/utils';

const StyledEmail = styled.div({
  ...fontSizes.caption,
  color: colors.secondaryText,
});

const StyledName = styled.div({
  lineHeight: '1.2rem',
  fontWeight: fontWeights.bold,
});

const StyledContactDetail = styled.div({
  flex: '1 1 auto',
  marginLeft: spacing.normal,
});

const StyledAvatar = styled.div({
  flex: `0 0 ${spacing.max}`,
  width: spacing.max,
  height: spacing.max,
});

const StyledItemButton = styled.button({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  margin: 0,
  padding: `${spacing.small} ${spacing.large}`,
  color: colors.mainText,
  backgroundColor: colors.white,
  border: 'none',
  outline: 'none',
  textAlign: 'left',
  cursor: 'pointer',

  '&:hover, &:focus': {
    backgroundColor: colors.menuItemHighlight,
    color: colors.mainText,
  },

  '&:disabled': {
    background: 'transparent',
    cursor: 'default',
  },
});

const StyledListItem = styled.li({
  position: 'relative',
  margin: 0,
  padding: 0,
});

type Props = {
  contact: ContactResource;
  searchValue: string;
  importContact: (contact: ContactResource) => void;
};

export function ContactSearchResultItem(props: Props) {
  const { contact, searchValue, importContact } = props;
  const name = getRecipientName(contact);
  const email = contact.emailAddress;
  return (
    <StyledListItem key={contact.id}>
      <StyledItemButton onClick={() => importContact(contact)}>
        <StyledAvatar>
          <Avatar participant={contact}></Avatar>
        </StyledAvatar>
        <StyledContactDetail>
          <StyledName>
            <Highlight sourceText={name} findText={searchValue} />
          </StyledName>
          <StyledEmail>{email}</StyledEmail>
        </StyledContactDetail>
      </StyledItemButton>
    </StyledListItem>
  );
}

export default ContactSearchResultItem;
