import PubNub from './pubnub-service';
import Messaging from './messaging-service';
import { Dispatch } from 'redux';

// This class is a singleton class that initializes
// the PubNub and Messaging services
export default class WebSocketService {
  pubnub: PubNub | null = null;
  messaging: Messaging | null = null;
  static instance: WebSocketService | null = null;

  constructor() {
    // If the instance is already created, return the
    // instance instead of creating a new one
    if (WebSocketService.instance instanceof WebSocketService) {
      return WebSocketService.instance;
    }

    WebSocketService.instance = this;
    return this;
  }

  init(dispatch: Dispatch) {
    this.pubnub = new PubNub();
    this.messaging = new Messaging(dispatch);

    this.pubnub.addMessageEventHandler(this.messaging.handleMessageEvent);
  }

  setUserId(userId: number) {
    if (this.pubnub) {
      this.pubnub.setupPubNub(userId);
    }
  }
}
