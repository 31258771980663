import { Fragment, ReactNode } from 'react';
import styled from 'styled-components';

import { useI18n } from '~/hooks';
import { spacing } from '~/styles';

import BaseModal, { BaseModalConsumerProps, ModalType } from './BaseModal';
import ModalHeader from './ModalHeader';
import ModalActions, { ModalAction } from './ModalActions';

const StyledContent = styled.div({
  marginTop: spacing.small,
  marginBottom: spacing.normal,
});

const StyledMessageImage = styled.div({
  textAlign: 'center',
});

const StyledBody = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
});

type ImageType = React.ReactNode | string;

type Props = BaseModalConsumerProps & {
  /** Either a URL string or react component to render as the message's main image */
  messageImage: ImageType | null;
  /** Determines whether to show the action bar at the bottom or not */
  showActions?: boolean;
  /** Adds a back button to the modal in case there are multiple "steps" */
  canGoBack?: boolean;
  /** Event that is fired after the back button is clicked, only works if canGoBack is true */
  onNavigateBack?: () => void;
  /** The primary action button for the modal: {labelId: i18nKey, onClick: event handler}
   * If this is a modal with a form, this will be a submit button for the form.
   */
  primaryAction?: ModalAction;
  /** The secondary action button for the modal: {labelId: i18nKey, onClick: event handler} */
  secondaryAction?: ModalAction;
  /** The contents of the modal */
  children?: ReactNode;
};

type MessageImageProps = {
  messageImage: ImageType;
};

const MessageImage = ({ messageImage }: MessageImageProps) => {
  const i18n = useI18n();

  if (typeof messageImage === 'string') {
    return (
      <img loading="lazy" src={messageImage} alt={i18n.t('announcement')} />
    );
  } else {
    return <Fragment>{messageImage}</Fragment>;
  }
};

export function MessageModal(props: Props) {
  const {
    isOpen,
    children,
    primaryAction,
    title,
    messageImage,
    onRequestClose,
    onNavigateBack,
    dismissable = false,
    showActions = true,
    canGoBack = false,
    ...baseModalProps
  } = props;

  return (
    <BaseModal
      type={ModalType.Marketing}
      isOpen={isOpen}
      aria-label={title}
      dismissable={dismissable}
      onRequestClose={onRequestClose}
      {...baseModalProps}
    >
      {({ onRequestClose }) => (
        <Fragment>
          <ModalHeader
            title={null}
            showTitle={canGoBack || dismissable}
            closeModal={onRequestClose}
            showClose={dismissable}
            canGoBack={canGoBack}
            onNavigateBack={onNavigateBack}
          />
          <StyledBody>
            {!!messageImage && (
              <StyledMessageImage>
                <MessageImage messageImage={messageImage} />
              </StyledMessageImage>
            )}
            <StyledContent>{children}</StyledContent>
          </StyledBody>
          {showActions && <ModalActions primaryAction={primaryAction} />}
        </Fragment>
      )}
    </BaseModal>
  );
}

export default MessageModal;
