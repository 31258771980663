import { ATTRIBUTE_TYPE } from '../constants';

import { ResourceDefinition } from '../types';

const Announcement: ResourceDefinition = {
  attributes: {
    name: ATTRIBUTE_TYPE.STRING,
    platformType: ATTRIBUTE_TYPE.NUMBER,
    title: ATTRIBUTE_TYPE.STRING,
    linkUrl: ATTRIBUTE_TYPE.STRING,
    linkLabel: ATTRIBUTE_TYPE.STRING,
    imageUrl: ATTRIBUTE_TYPE.STRING,
    description: ATTRIBUTE_TYPE.STRING,
    bulletPoints: ATTRIBUTE_TYPE.JSON_STRING,
    excludeProfiles: ATTRIBUTE_TYPE.BOOLEAN,
    s3ImageUrl: ATTRIBUTE_TYPE.STRING,
    startDisplayDate: ATTRIBUTE_TYPE.STRING,
    endDisplayDate: ATTRIBUTE_TYPE.STRING,
    statusId: ATTRIBUTE_TYPE.NUMBER,
    status: ATTRIBUTE_TYPE.STRING,
    canEdit: ATTRIBUTE_TYPE.BOOLEAN,
    isRead: ATTRIBUTE_TYPE.BOOLEAN,
  },
};

export default Announcement;
