import { useReducer, ReactNode, Reducer } from 'react';
import { ModalContext, ModalContextType } from '~/components/Modal/context';

type ModalProviderState = {
  openModals: Array<string>;
  contextValue: ModalContextType;
};
type Action = {
  type: 'modalOpenStateChange';
  modalName: string;
  isOpen: boolean;
};
type ModalStateReducer = Reducer<ModalProviderState, Action>;

function modalStateReducer(
  state: ModalProviderState,
  action: Action
): ModalProviderState {
  const { openModals } = state;
  const { modalName, isOpen } = action;
  const modalExists = openModals.find(name => name === modalName);
  const updatedOpenModals =
    !modalExists && isOpen
      ? [...openModals, modalName]
      : modalExists && !isOpen
      ? openModals.filter(name => name !== modalName)
      : null;

  if (updatedOpenModals !== null) {
    const activeModalName =
      updatedOpenModals.length > 0 ? updatedOpenModals[0] : null;

    return {
      ...state,
      openModals: updatedOpenModals,
      contextValue: {
        ...state.contextValue,
        activeModalName,
        openModalCount: updatedOpenModals.length,
      },
    };
  }

  return state;
}

type Props = {
  children: ReactNode;
};

export function ModalProvider(props: Props) {
  const [modalState, dispatch] = useReducer<ModalStateReducer>(
    modalStateReducer,
    {
      openModals: [],
      contextValue: {
        openModalCount: 0,
        activeModalName: null,
        registerModal: (modalName: string, isOpen: boolean) => {
          dispatch({ type: 'modalOpenStateChange', modalName, isOpen });
        },
      },
    }
  );

  return (
    <ModalContext.Provider value={modalState.contextValue}>
      {props.children}
    </ModalContext.Provider>
  );
}

export default ModalProvider;
