import { Keyframes, keyframes } from 'styled-components';
import { kebabCase } from 'lodash-es';

export const boxShadows = {
  empty: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
  normal: '0px 2px 10px rgba(0,0,0,0.25)',
  dark: '0px 2px 10px rgba(0,0,0,0.4)',
  bottom: '0px 4px 2px -2px rgba(0,0,0,0.25)',
  bottomDark: '0px 4px 2px -2px rgba(0,0,0,0.4)',
  top: '0px -2px 10px rgba(0,0,0,0.25)',
  topDark: '0px -2px 10px rgba(0,0,0,0.4)',
  surroundLight: '0px 0px 10px rgba(0,0,0, 0.05)',
  surround: '0px 0px 10px rgba(0,0,0, 0.1)',
  surroundDark: '0px 0px 10px rgba(0,0,0, 0.4)',
  inset: 'inset 0px 1px 4px rgba(0, 0, 0, 0.6)',

  drawer: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
  dropdown: '0 0 8px 0 rgba(149, 150, 166, 0.75)',
  scrollContainerBottomShadow: '0px -2px 2px rgba(0, 0, 0, 0.25)',
  messagingButton: '0px 4px 5px 0px rgba(0, 0, 0, 0.24)',
  documentPreview: '0 4px 4px 0 rgba(209, 209, 209, 0.5)',
  documentUploadPreview: '0 2px 2px 0 rgba(51, 51, 51, 0.25)',
  focusDocumentNote: '0px 4px 4px rgba(108, 108, 118, 0.5)',

  normalWithColor(color: string) {
    return `0 2px 10px ${color}`;
  },
};

type Animations = {
  shimmer: Keyframes;
};
export const animations: Animations = {
  shimmer: keyframes`
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.65;
  }

  100% {
    opacity: 0.4;
  }
  `,
};

type Styles = { [propertyName: string]: any };
function spreadStyleTemplateLiteral(styleObject: Styles) {
  let result = '';
  const kebabStyleObject: Styles = {};

  if (Object.keys(styleObject).length === 0) {
    return '';
  }

  for (const key in styleObject) {
    kebabStyleObject[kebabCase(key)] = styleObject[key];
  }

  try {
    result = JSON.stringify(kebabStyleObject)
      .replace(/[,]/gi, ';')
      .replace(/[{}"]/gi, '');
  } catch (e) {
    console.error('Failed to translate style object to template literal.', e);
  }

  return `${result};`;
}

type Utils = {
  circleBorderRadius: string;
  spreadStyleTemplateLiteral: (styleObject: Styles) => string;
  baseBorderRadius: string;
  buttonBorderRadius: string;
  text: {
    truncate: object;
  };
};
export const utils: Utils = {
  spreadStyleTemplateLiteral,
  circleBorderRadius: '50%',
  baseBorderRadius: '4px',
  buttonBorderRadius: '50px',
  text: {
    truncate: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
};
