import { forwardRef } from 'react';
import styled from 'styled-components';

import { colors, spacing, utils } from '~/styles';
import BaseButton, { BaseButtonProps } from './BaseButton';
import { buttonStyleHelpers } from './utils';

const StyledToolbarButtonBadgeCount = styled.div({
  marginLeft: spacing.small,
  padding: `${spacing.smallest} ${spacing.smaller}`,
  color: colors.primaryLink,
  backgroundColor: colors.primaryLight,
  borderRadius: utils.baseBorderRadius,
});

const StyledToolbarButtonContents = styled.div({
  display: 'flex',
  alignItems: 'center',
});

type BaseButtonStyleProps = BaseButtonProps & { isIconButton: boolean };
const StyledBaseButton = styled(BaseButton)<BaseButtonStyleProps>(props => {
  const { buttonGroupPosition } = props;
  const leftBorderRadius =
    buttonGroupPosition === ButtonGroupPosition.First
      ? utils.baseBorderRadius
      : 0;
  const rightBorderRadius =
    buttonGroupPosition === ButtonGroupPosition.Last
      ? utils.baseBorderRadius
      : 0;
  const borderRadiusProps = !buttonGroupPosition
    ? { borderRadius: utils.baseBorderRadius }
    : {
        borderTopLeftRadius: leftBorderRadius,
        borderBottomLeftRadius: leftBorderRadius,
        borderTopRightRadius: rightBorderRadius,
        borderBottomRightRadius: rightBorderRadius,
      };
  const hoverAndFocusStateProps = {
    color: colors.mainText,
    backgroundColor: colors.darkBackground,
  };
  const activeStateProps = {
    color: colors.white,
    backgroundColor: colors.secondaryText,
  };

  return {
    // Subtract 1px to account for the borders on top and bottom
    padding: props.isIconButton
      ? spacing.small
      : `calc(${spacing.small} - 1px) ${spacing.normal}`,
    minWidth: props.isIconButton ? 0 : '',
    color: colors.mainText,
    backgroundColor: colors.white,
    border: `1px solid ${colors.lightBorder}`,
    borderRightWidth: buttonGroupPosition === 'first' ? 0 : '1px',
    ...borderRadiusProps,

    ...buttonStyleHelpers.focus(hoverAndFocusStateProps, props),
    ...buttonStyleHelpers.hover(hoverAndFocusStateProps, props),
    ...buttonStyleHelpers.active(activeStateProps, props),

    '> div': {
      justifyContent: 'space-between',
    },
  };
});

export enum ButtonGroupPosition {
  First = 'first',
  Middle = 'middle',
  Last = 'last',
}
type Props = {
  badgeCount?: number;
  className?: string;
} & BaseButtonProps;

function ToolbarButton(
  props: Props,
  ref: React.ForwardedRef<HTMLButtonElement | undefined | null>
) {
  const { badgeCount, className, children, ...baseButtonProps } = props;
  const iconProps = { ...props.defaultIconProps, type: 'black' };

  return (
    <StyledBaseButton
      className={className}
      innerRef={ref}
      {...baseButtonProps}
      isIconButton={!!props.icon && !props.children}
      defaultIconProps={iconProps}
      getIconProps={({ isActive }) => {
        return isActive ? { type: 'white' } : {};
      }}
    >
      {!!badgeCount ? (
        <StyledToolbarButtonContents>
          {children}
          <StyledToolbarButtonBadgeCount>
            {badgeCount}
          </StyledToolbarButtonBadgeCount>
        </StyledToolbarButtonContents>
      ) : (
        children
      )}
    </StyledBaseButton>
  );
}

ToolbarButton.displayName = 'ToolbarButton';

export default forwardRef(ToolbarButton);
