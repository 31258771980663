import scalableIcon from './utils/scalableIcon';

import { ReactComponent as AddDocumentIconSvg } from './svgs/fill/AddDocumentIcon.svg';
import { ReactComponent as AddPlaceholderIconSvg } from './svgs/fill/AddPlaceholderIcon.svg';
import { ReactComponent as AlertSolidIconSvg } from './svgs/fill/AlertSolidIcon.svg';
import { ReactComponent as AvatarIconSvg } from './svgs/fill/AvatarIcon.svg';
import { ReactComponent as CalendarIconSvg } from './svgs/fill/CalendarIcon.svg';
import { ReactComponent as CheckCircleSvg } from './svgs/fill/CheckCircle.svg';
import { ReactComponent as CompactViewIconSvg } from './svgs/fill/CompactViewIcon.svg';
import { ReactComponent as CopyIconSvg } from './svgs/fill/CopyIcon.svg';
import { ReactComponent as DocumentLargeIconSvg } from './svgs/fill/DocumentLargeIcon.svg';
import { ReactComponent as DocumentChuteIconSvg } from './svgs/fill/DocumentChuteIcon.svg';
import { ReactComponent as DragIndicatorIconSvg } from './svgs/fill/DragIndicatorIcon.svg';
import { ReactComponent as EditTagsIconSvg } from './svgs/fill/EditTagsIcon.svg';
import { ReactComponent as ErrorIconSvg } from './svgs/fill/ErrorIcon.svg';
import { ReactComponent as FaxIconSvg } from './svgs/fill/FaxIcon.svg';
import { ReactComponent as InformationIconSvg } from './svgs/fill/InformationIcon.svg';
import { ReactComponent as LockIconSvg } from './svgs/fill/LockIcon.svg';
import { ReactComponent as MoreOptionsIconSvg } from './svgs/fill/MoreOptionsIcon.svg';
import { ReactComponent as MoveIconSvg } from './svgs/fill/MoveIcon.svg';
import { ReactComponent as OpenAllIconSvg } from './svgs/fill/OpenAllIcon.svg';
import { ReactComponent as PdfFileIconSvg } from './svgs/fill/PdfFileIcon.svg';
import { ReactComponent as PrintIconSvg } from './svgs/fill/PrintIcon.svg';
import { ReactComponent as RenameIconSvg } from './svgs/fill/RenameIcon.svg';
import { ReactComponent as ReviewNotesIconSvg } from './svgs/fill/ReviewNotesIcon.svg';
import { ReactComponent as SearchIconSvg } from './svgs/fill/SearchIcon.svg';
import { ReactComponent as SettingsIconSvg } from './svgs/fill/SettingsIcon.svg';
import { ReactComponent as SignOutIconSvg } from './svgs/fill/SignOutIcon.svg';
import { ReactComponent as SignatureVerificationIconSvg } from './svgs/fill/SignatureVerificationIcon.svg';
import { ReactComponent as TagIconSvg } from './svgs/fill/TagIcon.svg';
import { ReactComponent as UnLockIconSvg } from './svgs/fill/UnLockIcon.svg';
import { ReactComponent as ZoomInIconSvg } from './svgs/fill/ZoomInIcon.svg';
import { ReactComponent as ZoomOutIconSvg } from './svgs/fill/ZoomOutIcon.svg';
import { ReactComponent as ActivityIconSvg } from './svgs/stroke/ActivityIcon.svg';
import { ReactComponent as AddIconSvg } from './svgs/stroke/AddIcon.svg';
import { ReactComponent as AddPersonIconSvg } from './svgs/stroke/AddPersonIcon.svg';
import { ReactComponent as AddTagIconSvg } from './svgs/stroke/AddTagIcon.svg';
import { ReactComponent as ArchiveIconSvg } from './svgs/stroke/ArchiveIcon.svg';
import { ReactComponent as ArrowIconSvg } from './svgs/stroke/ArrowIcon.svg';
import { ReactComponent as AscendSvg } from './svgs/stroke/Ascend.svg';
import { ReactComponent as CheckmarkIconSvg } from './svgs/stroke/CheckmarkIcon.svg';
import { ReactComponent as ChevronIconSvg } from './svgs/stroke/ChevronIcon.svg';
import { ReactComponent as CloseIconSvg } from './svgs/stroke/CloseIcon.svg';
import { ReactComponent as CloudDocumentIconSvg } from './svgs/stroke/CloudDocumentIcon.svg';
import { ReactComponent as CloudIconSvg } from './svgs/stroke/CloudIcon.svg';
import { ReactComponent as DeleteIconSvg } from './svgs/stroke/DeleteIcon.svg';
import { ReactComponent as DescendSvg } from './svgs/stroke/Descend.svg';
import { ReactComponent as DocumentIconSvg } from './svgs/stroke/DocumentIcon.svg';
import { ReactComponent as DocumentNotesIconSvg } from './svgs/stroke/DocumentNotesIcon.svg';
import { ReactComponent as DotloopSmileLogoSvg } from './svgs/stroke/DotloopSmileLogo.svg';
import { ReactComponent as DownloadIconSvg } from './svgs/stroke/DownloadIcon.svg';
import { ReactComponent as EditIconSvg } from './svgs/stroke/EditIcon.svg';
import { ReactComponent as EyeIconSvg } from './svgs/stroke/EyeIcon.svg';
import { ReactComponent as FolderIconSvg } from './svgs/stroke/FolderIcon.svg';
import { ReactComponent as GridViewIconSvg } from './svgs/stroke/GridViewIcon.svg';
import { ReactComponent as HistoryIconSvg } from './svgs/stroke/HistoryIcon.svg';
import { ReactComponent as ListViewIconSvg } from './svgs/stroke/ListViewIcon.svg';
import { ReactComponent as MenuIconSvg } from './svgs/stroke/MenuIcon.svg';
import { ReactComponent as MessagingIconSvg } from './svgs/stroke/MessagingIcon.svg';
import { ReactComponent as NavLoopsIconSvg } from './svgs/stroke/NavLoopsIcon.svg';
import { ReactComponent as NavPeopleIconSvg } from './svgs/stroke/NavPeopleIcon.svg';
import { ReactComponent as NavReportingIconSvg } from './svgs/stroke/NavReportingIcon.svg';
import { ReactComponent as NavTasksIconSvg } from './svgs/stroke/NavTasksIcon.svg';
import { ReactComponent as NavTemplatesIconSvg } from './svgs/stroke/NavTemplatesIcon.svg';
import { ReactComponent as NotificationIconSvg } from './svgs/stroke/NotificationIcon.svg';
import { ReactComponent as PersonIconSvg } from './svgs/stroke/PersonIcon.svg';
import { ReactComponent as PointedArrowIconSvg } from './svgs/stroke/PointedArrowIcon.svg';
import { ReactComponent as ProfileSvg } from './svgs/stroke/Profile.svg';
import { ReactComponent as ReviewDocumentsIconSvg } from './svgs/stroke/ReviewDocumentsIcon.svg';
import { ReactComponent as ShareIconSvg } from './svgs/stroke/ShareIcon.svg';
import { ReactComponent as UnarchiveIconSvg } from './svgs/stroke/UnarchiveIcon.svg';
import { ReactComponent as UploadIconSvg } from './svgs/stroke/UploadIcon.svg';
import { ReactComponent as AlertIconSvg } from './svgs/other/AlertIcon.svg';
import { ReactComponent as ArchivedFolderExpandedIconSvg } from './svgs/other/ArchivedFolderExpandedIcon.svg';
import { ReactComponent as ArchivedFolderCollapsedIconSvg } from './svgs/other/ArchivedFolderCollapsedIcon.svg';
import { ReactComponent as FolderExpandedIconSvg } from './svgs/other/FolderExpandedIcon.svg';
import { ReactComponent as FolderCollapsedIconSvg } from './svgs/other/FolderCollapsedIcon.svg';
import { ReactComponent as UnderConstructionIconSvg } from './svgs/other/UnderConstructionIcon.svg';
import { ReactComponent as PeopleEmptyStateIconSvg } from './svgs/other/PeopleEmptyStateIcon.svg';
import { ReactComponent as NoteAddIconSvg } from './svgs/fill/NoteAddIcon.svg';
import { ReactComponent as NotificationsEmptyStateIconSvg } from './svgs/other/NotificationsEmptyStateIcon.svg';
import { ReactComponent as HelpIconSvg } from './svgs/fill/HelpIcon.svg';
import { ReactComponent as ReviewDocumentIconSvg } from './svgs/fill/ReviewDocumentIcon.svg';
import { ReactComponent as UndoIconSvg } from './svgs/fill/UndoIcon.svg';
import { ReactComponent as TaskListSvg } from './svgs/other/TaskList.svg';
import { ReactComponent as EyeOffSvg } from './svgs/stroke/EyeOffIcon.svg';
import { ReactComponent as MinusIconSvg } from './svgs/stroke/MinusIcon.svg';
import { ReactComponent as PhotoIconSvg } from './svgs/fill/PhotoIcon.svg';
import { ReactComponent as PacketIconSvg } from './svgs/fill/PacketIcon.svg';
import { ReactComponent as ThumbsUpIconSvg } from './svgs/fill/ThumbsUpIcon.svg';
import { ReactComponent as LocationIconSvg } from './svgs/stroke/LocationIcon.svg';
import { ReactComponent as MailIconSvg } from './svgs/stroke/MailIcon.svg';
import { ReactComponent as PhoneIconSvg } from './svgs/stroke/PhoneIcon.svg';
import { ReactComponent as EmailSigInfoIconSvg } from './svgs/stroke/EmailSigInfoIcon.svg';
import { ReactComponent as LicenseIconSvg } from './svgs/stroke/LicenseIcon.svg';
import { ReactComponent as EmailDecorativeSvg } from './svgs/other/EmailDecorative.svg';
import { ReactComponent as ClockIconSvg } from './svgs/stroke/Clock.svg';
import { ReactComponent as ShareToPersonIconSvg } from './svgs/stroke/SharetoPersonIcon.svg';
import { ReactComponent as SttpIconSvg } from './svgs/other/StppIcon.svg';
import { ReactComponent as SentPacketIconSVG } from './svgs/other/SentPacketIcon.svg';
import { ReactComponent as ReceivedPacketIconSVG } from './svgs/other/ReceivedPacketIcon.svg';
const AddDocumentIcon = scalableIcon()(AddDocumentIconSvg);
const AddPlaceholderIcon = scalableIcon(1, 1, 'fill')(AddPlaceholderIconSvg);
const AlertSolidIcon = scalableIcon(1, 1, 'fill')(AlertSolidIconSvg);
const AvatarIcon = scalableIcon(1, 1, 'fill')(AvatarIconSvg);
const CalendarIcon = scalableIcon(1, 1, 'fill')(CalendarIconSvg);
const CheckCircle = scalableIcon(1, 1, 'fill')(CheckCircleSvg);
const CompactViewIcon = scalableIcon(1, 1, 'fill')(CompactViewIconSvg);
const CopyIcon = scalableIcon(1, 1, 'fill')(CopyIconSvg);
const DocumentLargeIcon = scalableIcon(1, 1, 'fill')(DocumentLargeIconSvg);
const DocumentChuteIcon = scalableIcon(1, 1, 'other')(DocumentChuteIconSvg);
const DragIndicatorIcon = scalableIcon(1, 1, 'fill')(DragIndicatorIconSvg);
const EditTagsIcon = scalableIcon(1, 1, 'fill')(EditTagsIconSvg);
const ErrorIcon = scalableIcon(1, 1, 'fill')(ErrorIconSvg);
const FaxIcon = scalableIcon(1, 1, 'fill')(FaxIconSvg);
const InformationIcon = scalableIcon(1, 1, 'fill')(InformationIconSvg);
const LockIcon = scalableIcon(1, 1, 'fill')(LockIconSvg);
const MoreOptionsIcon = scalableIcon(1, 1, 'fill')(MoreOptionsIconSvg);
const MoveIcon = scalableIcon(1, 1, 'fill')(MoveIconSvg);
const NoteAddIcon = scalableIcon(1, 1, 'fill')(NoteAddIconSvg);
const OpenAllIcon = scalableIcon(1, 1, 'fill')(OpenAllIconSvg);
const PdfFileIcon = scalableIcon(1, 1, 'fill')(PdfFileIconSvg);
const PrintIcon = scalableIcon(1, 1, 'fill')(PrintIconSvg);
const RenameIcon = scalableIcon(1, 1, 'fill')(RenameIconSvg);
const ReviewNotesIcon = scalableIcon(1, 1, 'fill')(ReviewNotesIconSvg);
const SearchIcon = scalableIcon(1, 1, 'fill')(SearchIconSvg);
const SettingsIcon = scalableIcon(1, 1, 'fill')(SettingsIconSvg);
const SignOutIcon = scalableIcon(1, 1, 'fill')(SignOutIconSvg);
const SignatureVerificationIcon = scalableIcon(
  1,
  1,
  'fill'
)(SignatureVerificationIconSvg);
const TagIcon = scalableIcon(1, 1, 'fill')(TagIconSvg);
const UnLockIcon = scalableIcon(1, 1, 'fill')(UnLockIconSvg);
const ZoomInIcon = scalableIcon(1, 1, 'fill')(ZoomInIconSvg);
const ZoomOutIcon = scalableIcon(1, 1, 'fill')(ZoomOutIconSvg);
const HelpIcon = scalableIcon(1, 1, 'fill')(HelpIconSvg);
const ReviewDocumentIcon = scalableIcon(1, 1, 'fill')(ReviewDocumentIconSvg);
const UndoIcon = scalableIcon(1, 1, 'fill')(UndoIconSvg);
const PhotoIcon = scalableIcon(1, 1, 'fill')(PhotoIconSvg);

const ActivityIcon = scalableIcon()(ActivityIconSvg);
const AddIcon = scalableIcon()(AddIconSvg);
const AddPersonIcon = scalableIcon()(AddPersonIconSvg);
const AddTagIcon = scalableIcon()(AddTagIconSvg);
const ArchiveIcon = scalableIcon()(ArchiveIconSvg);
const ArrowIcon = scalableIcon()(ArrowIconSvg);
const Ascend = scalableIcon()(AscendSvg);
const CheckmarkIcon = scalableIcon()(CheckmarkIconSvg);
const ChevronIcon = scalableIcon()(ChevronIconSvg);
const CloseIcon = scalableIcon()(CloseIconSvg);
const CloudDocumentIcon = scalableIcon()(CloudDocumentIconSvg);
const CloudIcon = scalableIcon()(CloudIconSvg);
const DeleteIcon = scalableIcon()(DeleteIconSvg);

const Descend = scalableIcon()(DescendSvg);
const DocumentIcon = scalableIcon()(DocumentIconSvg);
const DocumentNotesIcon = scalableIcon(1, 1, 'fill')(DocumentNotesIconSvg);
const DotloopSmileLogo = scalableIcon()(DotloopSmileLogoSvg);
const DownloadIcon = scalableIcon()(DownloadIconSvg);
const EditIcon = scalableIcon()(EditIconSvg);
const EyeIcon = scalableIcon()(EyeIconSvg);
const MinusIcon = scalableIcon()(MinusIconSvg);
const EyeOffIcon = scalableIcon()(EyeOffSvg);
const FolderIcon = scalableIcon()(FolderIconSvg);
const GridViewIcon = scalableIcon()(GridViewIconSvg);
const HistoryIcon = scalableIcon()(HistoryIconSvg);
const ListViewIcon = scalableIcon()(ListViewIconSvg);
const MenuIcon = scalableIcon()(MenuIconSvg);
const MessagingIcon = scalableIcon()(MessagingIconSvg);
const NavLoopsIcon = scalableIcon()(NavLoopsIconSvg);
const NavPeopleIcon = scalableIcon()(NavPeopleIconSvg);
const NavReportingIcon = scalableIcon()(NavReportingIconSvg);
const NavTasksIcon = scalableIcon()(NavTasksIconSvg);
const NavTemplatesIcon = scalableIcon()(NavTemplatesIconSvg);
const NotificationIcon = scalableIcon()(NotificationIconSvg);
const PersonIcon = scalableIcon()(PersonIconSvg);
const PointedArrowIcon = scalableIcon()(PointedArrowIconSvg);
const Profile = scalableIcon()(ProfileSvg);
const ReviewDocumentsIcon = scalableIcon()(ReviewDocumentsIconSvg);
const ShareIcon = scalableIcon()(ShareIconSvg);
const UnarchiveIcon = scalableIcon()(UnarchiveIconSvg);
const UploadIcon = scalableIcon()(UploadIconSvg);
const ClockIcon = scalableIcon(1, 1, 'fill')(ClockIconSvg);
const ShareToPersonIcon = scalableIcon(1, 1, 'fill')(ShareToPersonIconSvg);
const SttpIcon = scalableIcon(1, 1, 'other')(SttpIconSvg);
const AlertIcon = scalableIcon(1, 1, 'other')(AlertIconSvg);
const ArchivedFolderExpandedIcon = scalableIcon(
  1,
  1,
  'other'
)(ArchivedFolderExpandedIconSvg);
const ArchivedFolderCollapsedIcon = scalableIcon(
  1,
  1,
  'other'
)(ArchivedFolderCollapsedIconSvg);
const FolderExpandedIcon = scalableIcon(1, 1, 'other')(FolderExpandedIconSvg);
const FolderCollapsedIcon = scalableIcon(1, 1, 'other')(FolderCollapsedIconSvg);
const UnderConstructionIcon = scalableIcon(
  1,
  1,
  'other'
)(UnderConstructionIconSvg);
const PeopleEmptyStateIcon = scalableIcon(
  1.58,
  1,
  'other'
)(PeopleEmptyStateIconSvg);
const NotificationsEmptyStateIcon = scalableIcon(
  0.82,
  1,
  'other'
)(NotificationsEmptyStateIconSvg);
const TaskListIcon = scalableIcon(1, 1, 'other')(TaskListSvg);
const PacketIcon = scalableIcon(1, 1, 'fill')(PacketIconSvg);
const ThumbsUpIcon = scalableIcon(1, 1, 'fill')(ThumbsUpIconSvg);
const LocationIcon = scalableIcon()(LocationIconSvg);
const MailIcon = scalableIcon()(MailIconSvg);
const PhoneIcon = scalableIcon()(PhoneIconSvg);
const EmailSigInfoIcon = scalableIcon(1, 1, 'other')(EmailSigInfoIconSvg);
const LicenseIcon = scalableIcon(1, 1, 'other')(LicenseIconSvg);
const EmailDecorativeIcon = scalableIcon(1, 1, 'other')(EmailDecorativeSvg);
const SentPacketIcon = scalableIcon(1, 1, 'other')(SentPacketIconSVG);
const ReceivedPacketIcon = scalableIcon(1, 1, 'other')(ReceivedPacketIconSVG);
export {
  ShareToPersonIcon,
  SttpIcon,
  ClockIcon,
  LocationIcon,
  MailIcon,
  PhoneIcon,
  EmailSigInfoIcon,
  LicenseIcon,
  AddDocumentIcon,
  AddPlaceholderIcon,
  AlertSolidIcon,
  AvatarIcon,
  CalendarIcon,
  CheckCircle,
  CompactViewIcon,
  CopyIcon,
  DocumentLargeIcon,
  DocumentChuteIcon,
  DragIndicatorIcon,
  EditTagsIcon,
  ErrorIcon,
  FaxIcon,
  InformationIcon,
  LockIcon,
  MoreOptionsIcon,
  MoveIcon,
  OpenAllIcon,
  PdfFileIcon,
  PeopleEmptyStateIcon,
  NotificationsEmptyStateIcon,
  PrintIcon,
  RenameIcon,
  ReviewNotesIcon,
  SearchIcon,
  SettingsIcon,
  SignOutIcon,
  SignatureVerificationIcon,
  TagIcon,
  UnderConstructionIcon,
  UnLockIcon,
  ZoomInIcon,
  ZoomOutIcon,
  ActivityIcon,
  AddIcon,
  AddPersonIcon,
  AddTagIcon,
  AlertIcon,
  ArchiveIcon,
  ArrowIcon,
  Ascend,
  CheckmarkIcon,
  ChevronIcon,
  CloseIcon,
  CloudDocumentIcon,
  CloudIcon,
  DeleteIcon,
  Descend,
  DocumentIcon,
  DocumentNotesIcon,
  DotloopSmileLogo,
  DownloadIcon,
  EditIcon,
  EyeIcon,
  EyeOffIcon,
  FolderIcon,
  GridViewIcon,
  HistoryIcon,
  ListViewIcon,
  MenuIcon,
  MessagingIcon,
  NavLoopsIcon,
  NavPeopleIcon,
  NavReportingIcon,
  NavTasksIcon,
  NavTemplatesIcon,
  NotificationIcon,
  NoteAddIcon,
  PersonIcon,
  PointedArrowIcon,
  Profile,
  ReviewDocumentsIcon,
  ShareIcon,
  UnarchiveIcon,
  UploadIcon,
  ArchivedFolderExpandedIcon,
  ArchivedFolderCollapsedIcon,
  FolderExpandedIcon,
  FolderCollapsedIcon,
  HelpIcon,
  ReviewDocumentIcon,
  UndoIcon,
  TaskListIcon,
  MinusIcon,
  PhotoIcon,
  PacketIcon,
  ThumbsUpIcon,
  EmailDecorativeIcon,
  SentPacketIcon,
  ReceivedPacketIcon,
};
