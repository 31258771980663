import constants from './constants';

const devFlags = {
  [constants.DarkMode]: false,
  [constants.LoopDetails]: false,
  [constants.ActivityLogDownload]: true,
  [constants.EditInPrivate]: true,
  [constants.RequiredEditPermissionToShare]: true,
  [constants.UsePubNubToken]: true,
};

export default devFlags;
