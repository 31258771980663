import { Component } from 'react';
import styled from 'styled-components';

import { LinkButton } from '~/components/buttons';
import { FormattedMessage } from '~/components/i18n';
import { spacing } from '~/styles';

import ConversationMessage from './ConversationMessage';
import ConversationMessageReceipt from './ConversationMessageReceipt';
import { Avatar } from '~/components/Avatar';

import { ConversationType } from '~/store/features/api/resources/conversation/constants';
import { ParticipantStatus } from '~/store/features/api/resources/participantStatus/constants';

import { MessageResource } from '~/store/features/api/resources/message/types';
import { ParticipantResource } from '~/store/features/api/resources/participant/types';
import { LinkButtonType } from '~/components/buttons/LinkButton';

export const AVATAR_WIDTH = spacing.largest;
const mixins = {
  messageSentByMe: {
    marginLeft: 'auto',
  },
};

const StyledMessageFailed = styled.div({
  textAlign: 'right',
});

const StyledMessageParticipantAvatar = styled.div({
  position: 'absolute',
  top: 0,
  left: `-${spacing.smaller}`,
  width: AVATAR_WIDTH,
  height: AVATAR_WIDTH,
  transform: 'translateX(-100%)',
});

const StyledMessage = styled.li<{
  $messageSentByMeEnabled: boolean;
}>(props => ({
  position: 'relative',
  flex: '0 1 auto',
  width: '100%',
  margin: 0,
  marginTop: spacing.small,

  '&:first-of-type': {
    marginTop: 0,
  },

  ...(props.$messageSentByMeEnabled ? mixins.messageSentByMe : {}),
}));

const MESSAGE_ELEMENTS: Map<Element, { callback?: () => any }> = new Map();
let intersectionObserver: IntersectionObserver | null = null;

function observerCallback(
  entries: Array<IntersectionObserverEntry>,
  observer: IntersectionObserver
) {
  entries.forEach(entry => {
    const { intersectionRatio, target } = entry;
    const element = MESSAGE_ELEMENTS.get(target);

    if (!!element && !!element.callback && intersectionRatio === 1) {
      element.callback();

      // Remove the callback, since we only want invoke it once
      MESSAGE_ELEMENTS.set(target, { callback: undefined });
      observer.unobserve(target);
    }
  });
}

type Props = {
  messageList: Array<MessageResource>;
  message: MessageResource;
  messageIndex: number;
  participants: Array<ParticipantResource>;
  currentDate: Date;
  conversationId: string;
  conversationType: ConversationType;
  messageFailedToSend: boolean;
  markMessageAsRead: (
    conversationId: string,
    participantUuid: string,
    messageId: string
  ) => Promise<any>;
  retryFailedMessage: (
    conversationId: string,
    message: MessageResource
  ) => Promise<any>;
};

export class MessageListItem extends Component<Props> {
  messageItemRef: HTMLElement | null = null;

  componentWillUnmount() {
    const element = this.messageItemRef;

    if (!!intersectionObserver && !!element) {
      intersectionObserver.unobserve(element);
    }

    if (!!element && !!MESSAGE_ELEMENTS.get(element)) {
      MESSAGE_ELEMENTS.delete(element);
    }

    if (!!intersectionObserver && MESSAGE_ELEMENTS.size === 0) {
      // If we just removed the last message element, disconnect the observer,
      // since there's nothing left to observe
      intersectionObserver.disconnect();
      intersectionObserver = null;
    }
  }

  setupMessageItemRef = (ref: HTMLElement | null) => {
    this.messageItemRef = ref;

    this.observeMessageElement();
  };

  observeMessageElement = () => {
    const elementToObserve = this.messageItemRef;
    const scrollContainer = document.getElementById('message-scroll-container');

    if (!!elementToObserve && !!scrollContainer) {
      const alreadyObserved = !!MESSAGE_ELEMENTS.get(elementToObserve);

      if (!alreadyObserved) {
        const {
          message: { statuses },
        } = this.props;
        const { sentByMe, myParticipantResource } = this.getCalculatedProps();
        const myStatus =
          !!statuses &&
          !!myParticipantResource &&
          statuses.find(
            status => status.participantUuid === myParticipantResource.id
          );
        const isMarkedAsRead =
          !!myStatus && myStatus.status === ParticipantStatus.Read;

        if (!sentByMe && !isMarkedAsRead) {
          // If the message wasn't sent by me, observe this message and mark it as
          // 'read' once it is fully in the viewport
          if (!intersectionObserver) {
            intersectionObserver = new IntersectionObserver(observerCallback, {
              root: scrollContainer,
              threshold: 1.0,
            });
          }

          MESSAGE_ELEMENTS.set(elementToObserve, {
            callback: this.markMessageAsRead,
          });
          intersectionObserver.observe(elementToObserve);
        }
      }
    }
  };

  markMessageAsRead = async () => {
    const {
      conversationId,
      participants,
      markMessageAsRead,
      message: { id },
    } = this.props;
    const myParticipantResource = participants.find(x => x.me);

    if (!!myParticipantResource) {
      await markMessageAsRead(conversationId, myParticipantResource.id, id);
    }
  };

  retryMessage = async () => {
    const { conversationId, message, retryFailedMessage } = this.props;

    await retryFailedMessage(conversationId, message);
  };

  getCalculatedProps = () => {
    const { message, participants } = this.props;
    const { sender } = message;
    const senderParticipantId = sender ? sender.id : null;
    const myParticipantResource = participants.find(x => x.me);
    const senderParticipant = senderParticipantId
      ? participants.find(x => x.id === senderParticipantId)
      : myParticipantResource;
    const sentByMe =
      !!myParticipantResource &&
      !!senderParticipant &&
      myParticipantResource.id === senderParticipant.id;

    return { myParticipantResource, sentByMe, senderParticipantId };
  };

  render() {
    const {
      messageList,
      message,
      message: { sender },
      messageIndex,
      currentDate,
      conversationType,
      participants,
      messageFailedToSend,
    } = this.props;
    const { sentByMe, senderParticipantId } = this.getCalculatedProps();
    const prevMessage =
      messageIndex !== 0 ? messageList[messageIndex - 1] : null;
    const showParticipantAvatar =
      !sentByMe &&
      (!prevMessage || prevMessage.sender.id !== senderParticipantId);

    return (
      <StyledMessage
        ref={this.setupMessageItemRef}
        $messageSentByMeEnabled={sentByMe}
      >
        {showParticipantAvatar && (
          <StyledMessageParticipantAvatar>
            <Avatar participant={sender} />
          </StyledMessageParticipantAvatar>
        )}
        <ConversationMessage message={message} sentByMe={sentByMe} />
        {messageFailedToSend ? (
          <StyledMessageFailed>
            <LinkButton
              removeSidePadding
              buttonStyle={LinkButtonType.Error}
              id={`retry-message-button-${messageIndex}`}
              onClick={this.retryMessage}
            >
              <FormattedMessage id="messaging.errors.notSentTryAgain" />
            </LinkButton>
          </StyledMessageFailed>
        ) : (
          <ConversationMessageReceipt
            message={message}
            sentByMe={sentByMe}
            currentDate={currentDate}
            conversationType={conversationType}
            participants={participants}
          />
        )}
      </StyledMessage>
    );
  }
}

export default MessageListItem;
