import { NotificationType } from './types';
import {
  AlertSolidIcon,
  CheckCircle,
  InformationIcon,
  AlertIcon,
} from '../icons';
import styled from 'styled-components';
import { fontSizes, spacing } from '../../styles';

const StyledIconIndicator = styled.div({
  position: 'absolute',
  top: '-1px',
  left: '-1px',
  bottom: '-1px',
  display: 'flex',
  justifyContent: 'center',
  width: spacing.large,
});

const StyledContainer = styled.div({
  ...fontSizes.body,
  position: 'relative',
  paddingTop: spacing.small,
  paddingBottom: spacing.small,
  paddingRight: spacing.normal,
  paddingLeft: `calc(${spacing.large} + ${spacing.normal})`,
});

const ICON_TYPE_MAP = {
  info: InformationIcon,
  success: CheckCircle,
  warning: AlertIcon,
  error: AlertSolidIcon,
  offline: AlertSolidIcon,
};

type Props = {
  type: NotificationType;
  children: React.ReactNode;
};
const InlineFeedback = (props: Props) => {
  const { type, children } = props;
  const IconComponent = ICON_TYPE_MAP[type];
  const colorName = `${type}Text`;
  return (
    <StyledContainer>
      {children}
      <StyledIconIndicator>
        <IconComponent type={colorName} width="100%"></IconComponent>
      </StyledIconIndicator>
    </StyledContainer>
  );
};

InlineFeedback.displayName = 'InlineFeedback';

export default InlineFeedback;
