import { ATTRIBUTE_TYPE } from '../constants';

import { ResourceDefinition } from '../types';

const Contact: ResourceDefinition = {
  attributes: {
    firstName: ATTRIBUTE_TYPE.STRING,
    middleName: ATTRIBUTE_TYPE.STRING,
    lastName: ATTRIBUTE_TYPE.STRING,
    displayName: ATTRIBUTE_TYPE.STRING,
    displayInitials: ATTRIBUTE_TYPE.STRING,
    address01: ATTRIBUTE_TYPE.STRING,
    addressSuite: ATTRIBUTE_TYPE.STRING,
    address02: ATTRIBUTE_TYPE.STRING,
    addressCity: ATTRIBUTE_TYPE.STRING,
    state: ATTRIBUTE_TYPE.STRING,
    addressZipcode: ATTRIBUTE_TYPE.STRING,
    phoneNumber: ATTRIBUTE_TYPE.STRING,
    faxNumber: ATTRIBUTE_TYPE.STRING,
    officeNumber: ATTRIBUTE_TYPE.STRING,
    mobileNumber: ATTRIBUTE_TYPE.STRING,
    countryCodeId: ATTRIBUTE_TYPE.NUMBER,
    emailAddress: ATTRIBUTE_TYPE.STRING,
    jobTitle: ATTRIBUTE_TYPE.STRING,
    companyName: ATTRIBUTE_TYPE.STRING,
    userId: ATTRIBUTE_TYPE.NUMBER,
    roleId: ATTRIBUTE_TYPE.NUMBER,
    name: ATTRIBUTE_TYPE.STRING,
    streetNumber: ATTRIBUTE_TYPE.STRING,
    streetName: ATTRIBUTE_TYPE.STRING,

    //List<ContactDataDto> fields,
  },
};

export default Contact;
