import { useState } from 'react';
import styled from 'styled-components';
import { spacing } from '~/styles';
import APP_CONFIG from '~/config';
import { useI18n } from '~/hooks';
import { FormattedMessage } from '~/components/i18n';
import { AppModal } from '~/components/Modal';

const StyledFeaturesContainer = styled.ul({
  marginTop: spacing.normal,
  marginLeft: spacing.largest,
  listStyle: 'disc',
});

const {
  ui: { phoenixUrl },
} = APP_CONFIG;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  modalMessage: string;
};

export function UpgradePremiumInfoModal(props: Props) {
  const { isOpen, onClose, modalMessage } = props;
  const i18n = useI18n();
  const [upgradePremium, setUpgradePremium] = useState(false);

  return (
    <AppModal
      isOpen={isOpen}
      name="upgrade-premium-info-modal"
      title={i18n.t('upgradePremiumModal.title')}
      onRequestClose={onClose}
      enableScroll={false}
      large={true}
      primaryAction={{
        isDisabled: upgradePremium,
        labelId: 'actions.upgradeNow',
        onClick: () => {
          setUpgradePremium(true);
          window.location.href = `${phoenixUrl}/buy/premium?redirect=${encodeURIComponent(
            window.location.href
          )}`;
        },
      }}
    >
      <FormattedMessage id={modalMessage} />

      <StyledFeaturesContainer>
        <li>
          <FormattedMessage id="upgradePremiumModal.feature1" />
        </li>
        <li>
          <FormattedMessage id="upgradePremiumModal.feature2" />
        </li>
        <li>
          <FormattedMessage id="upgradePremiumModal.feature3" />
        </li>
        <li>
          <FormattedMessage id="upgradePremiumModal.feature4" />
        </li>
      </StyledFeaturesContainer>
    </AppModal>
  );
}

export default UpgradePremiumInfoModal;
