import { boxShadows, colors, momentumScrollingStyles, zIndex } from '~/styles';
import styled from 'styled-components';
import SearchInput from '~/components/SearchInput';
import { useI18n } from '~/hooks';
import { AppState } from '~/store';
import {
  hasResourceBeenRequested,
  makeAllResourcesForQuerySelector,
  makeAllResourcesSelector,
} from '~/store/features/api/selectors';
import { ContactResource } from '~/store/features/api/resources/contact/types';
import { ResourceTypes } from '~/store/features/api/resources/types';
import { useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import ContactSearchResults from '../ContactSearchResults';

import { debounce } from 'lodash-es';
import { fetchAllResources } from '~/store/features/api/apiSlice';

const StyledSearchResults = styled.div({
  ...momentumScrollingStyles,
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  maxHeight: '70vh',
  backgroundColor: colors.white,
  boxShadow: boxShadows.surround,
  transform: 'translateY(100%)',
  zIndex: zIndex.overlayForeground,
  overflowX: 'hidden',
});

const StyledRequirementsContainer = styled.div({
  position: 'relative',
  flex: '0 0 auto',
  textAlign: 'center',
  background: colors.backgroundGray,
});

type Props = {
  importContact: (contact: ContactResource) => void;
};

export function SearchContacts(props: Props) {
  const i18n = useI18n();
  const { importContact } = props;

  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState('');
  const showSearchResults = !!searchValue;
  const selectContacts = useMemo(
    () =>
      makeAllResourcesForQuerySelector<ContactResource, undefined>(
        ResourceTypes.Contacts,
        () => ({ filters: { searchTerm: searchValue } })
      ),
    [searchValue]
  );
  const storeContacts = useAppSelector((state: AppState) =>
    selectContacts(state, undefined)
  );
  const contacts = !!searchValue ? storeContacts : [];
  const selectAllContacts = useMemo(
    () => makeAllResourcesSelector<ContactResource>(ResourceTypes.Contacts),
    []
  );
  const allContacts = useAppSelector((state: AppState) =>
    selectAllContacts(state)
  );
  const haveContactsBeenRequested = useAppSelector((state: AppState) =>
    hasResourceBeenRequested(state, ResourceTypes.Contacts, {
      filters: { searchTerm: searchValue },
    })
  );
  const [showAllContacts, setShowAllContacts] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const searchForContacts = useMemo(
    () =>
      debounce(async (searchTerm: string) => {
        try {
          setIsLoading(true);
          await dispatch(
            fetchAllResources({
              resourceName: ResourceTypes.Contacts,
              options: {
                filters: { searchTerm },
              },
            })
          );
        } finally {
          setIsLoading(false);
        }
      }, 300),
    [dispatch]
  );

  const fetchAllContacts = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(
        fetchAllResources({ resourceName: ResourceTypes.Contacts })
      );
      setShowAllContacts(true);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  const handleSearch = (value: string) => {
    setSearchValue(value);

    if (showAllContacts) {
      setShowAllContacts(false);
    }

    if (!!value) {
      searchForContacts(value);
    }
  };

  const clearSearch = () => {
    setSearchValue('');
    setShowAllContacts(false);
  };

  const handleImportContact = (contact: ContactResource) => {
    clearSearch();
    importContact(contact);
  };

  return (
    <StyledRequirementsContainer>
      <SearchInput
        border
        name="contact-search-input"
        id="contact-search-input"
        inputLabel="Search contacts"
        clearOnBlur={false}
        placeholder={i18n.t(
          'loop-details:people.participantForm.searchContacts.title'
        )}
        value={searchValue}
        onChange={handleSearch}
        onClose={clearSearch}
      />
      <StyledSearchResults>
        <ContactSearchResults
          contacts={contacts}
          searchValue={searchValue}
          isLoading={isLoading}
          showSearchResults={showSearchResults}
          importContact={handleImportContact}
          fetchAllContacts={fetchAllContacts}
          allContacts={allContacts}
          showAllContacts={showAllContacts}
          haveContactsBeenRequested={haveContactsBeenRequested}
        />
      </StyledSearchResults>
    </StyledRequirementsContainer>
  );
}
export default SearchContacts;
