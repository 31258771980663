import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { AddPersonIcon } from '~/components/icons';
import { FormattedMessage } from '~/components/i18n';
import { AppModal } from '~/components/Modal';
import Checkbox from '~/components/inputs/checkbox';

import { colors, fontSizes, spacing, zIndex } from '~/styles';

import { ResourceTypes } from '~/store/features/api/resources/types';
import { useAppDispatch } from '~/store/hooks';
import { createResource } from '~/store/features/api/apiSlice';
import { LoopParticipantResource } from '~/store/features/api/resources/loopParticipant/types';
import { ContactResource } from '~/store/features/api/resources/contact/types';

import { useI18n } from '~/hooks';
import { useSimpleNotification } from '~/hooks/useSimpleNotification';
import { validateEmail, validateFullName } from '~/lib/utils/validation';
import { constructNameFromParts } from '~/lib/utils/name';
import SearchContactsSection from '~/pages/LoopDetails/components/People/components/SearchContactsSection';
import {
  EmailAddressInput,
  FullNameInput,
  RoleDropdown,
} from '~/pages/LoopDetails/components/People/components/ParticipantFormComponents';
import { useCanAddNewParticipant } from '~/hooks/useCanAddNewParticipant';
import UpgradePremiumInfoModal from '~/components/UpgradePremiumInfoModal';

const StyledIntroCheckboxPadding = styled.div({
  marginTop: spacing.normal,
});

const StyledWarningWrapper = styled.div({
  ...fontSizes.caption,
  backgroundColor: colors.highlight,
  padding: spacing.small,
  margin: `${spacing.small} 0`,
  lineHeight: spacing.normal,
});

const StyledItem = styled.div({
  marginBottom: spacing.normal,
});

const StyledSearchContactsContainer = styled.div({
  margin: `-${spacing.normal} -${spacing.large} ${spacing.large}`,
});

type Props = {
  isOpen: boolean;
  loopId: string;
  loopParticipants: Array<LoopParticipantResource>;
  onClose: () => void;
  onSave: (participant: string, name: string, isFullAccess: boolean) => void;
};

function AddParticipantModal(props: Props) {
  const { isOpen, loopId, loopParticipants, onClose, onSave } = props;
  const i18n = useI18n();
  const { showSimpleError } = useSimpleNotification();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [roleId, setRoleId] = useState<string | null>(null);
  const [isFullAccess, setIsFullAccess] = useState(false);
  const [isSendIntroEmail, setIsSendIntroEmail] = useState(false);
  const dispatch = useAppDispatch();
  const [isNameHighlighted, setIsNameHighlighted] = useState(false);
  const [isEmailHighlighted, setIsEmailHighlighted] = useState(false);

  const canAddNewParticipant = useCanAddNewParticipant(
    loopId,
    loopParticipants
  );

  const createParticipant = (
    participantAttrs: Partial<LoopParticipantResource>
  ) =>
    dispatch(
      createResource({
        resourceName: ResourceTypes.LoopParticipants,
        resourceAttributes: participantAttrs,
        resourceRelationships: undefined,

        createOptions: {
          meta: {
            loop: loopId,
            role: roleId,
            inviteAsTeamMember: isFullAccess,
            sendIntroEmail: isSendIntroEmail,
          },
        },
      })
    );

  const resetModal = () => {
    setName('');
    setEmail('');
    setRoleId(null);
    setIsFullAccess(false);
    setIsSendIntroEmail(false);
    setIsEmailHighlighted(false);
    setIsNameHighlighted(false);
  };

  const handleClose = () => {
    onClose();
    resetModal();
  };

  const handleSave = async () => {
    const existingParticipant = loopParticipants.find(
      participant => participant.emailAddress === email
    );

    if (existingParticipant) {
      showSimpleError(
        'add-person-error',
        i18n.t('loop-details:notifications.addDupePersonError')
      );
    } else {
      const attrs = {
        name,
        emailAddress: email,
      };

      const [newParticipant] = await createParticipant(attrs);
      onSave(newParticipant.id, attrs.name, isFullAccess);
      resetModal();
    }
  };

  useEffect(() => {
    // Hacky solution to fix the add person role dropdown from displaying behind the modal
    const element = document.querySelector('#add-person-role-menu');

    if (!!element?.parentElement?.parentElement?.parentElement?.style) {
      element.parentElement.parentElement.parentElement.style.zIndex = (
        zIndex.nestedModalOverlayForeground + 1
      ).toString();
    }
  });

  const importContactInformation = (contact: ContactResource) => {
    const name = constructNameFromParts(
      contact.firstName,
      contact.middleName,
      contact.lastName
    );

    setEmail(contact.emailAddress);
    setName(name);

    if (name && name.length > 0) {
      setIsNameHighlighted(true);
    }
    if (contact.emailAddress && contact.emailAddress.length > 0) {
      setIsEmailHighlighted(true);
    }
  };

  return canAddNewParticipant ? (
    <AppModal
      isOpen={isOpen}
      name="add-participant"
      title={i18n.t('addParticipantModal.title')}
      headerIcon={AddPersonIcon}
      formName="add-participant-form"
      large
      enableScroll
      onRequestClose={handleClose}
      cancelAction={handleClose}
      primaryAction={{
        labelId: 'addParticipantModal.addButton',
        onClick: handleSave,
        isDisabled: !validateFullName(name) || !validateEmail(email) || !roleId,
      }}
    >
      <StyledSearchContactsContainer>
        <SearchContactsSection
          descriptionId="addParticipantModal.description"
          importContactInformation={importContactInformation}
        />
      </StyledSearchContactsContainer>

      <StyledItem>
        <FullNameInput
          id="add-person-name"
          label={i18n.t('addParticipantModal.name')}
          value={name}
          onChange={(value: string) => {
            setName(value);
            setIsNameHighlighted(false);
          }}
          highlighted={isNameHighlighted}
          validateFullName={(value: string) => {
            const isFullName = validateFullName(value);
            if (!isFullName) {
              return i18n.t('errors.inputs.invalidFullName');
            }
          }}
        />
      </StyledItem>

      <StyledItem>
        <EmailAddressInput
          id="add-person-email"
          label={i18n.t('addParticipantModal.email')}
          value={email}
          onChange={(value: string) => {
            setEmail(value);
            setIsEmailHighlighted(false);
          }}
          highlighted={isEmailHighlighted}
          validateEmail={(value: string) => {
            const isValid = validateEmail(value);
            if (!isValid) {
              return i18n.t('errors.inputs.invalidEmail');
            }
          }}
        />
      </StyledItem>

      <StyledItem>
        <RoleDropdown
          id="add-person-role"
          loopId={loopId}
          value={roleId}
          onChange={(value: string | null) => setRoleId(value)}
          label={i18n.t('addParticipantModal.role')}
          placeholderId={i18n.t('addParticipantModal.rolePlaceholder')}
        />
      </StyledItem>

      <StyledItem>
        <Checkbox
          label={i18n.t('addParticipantModal.fullAccess')}
          isChecked={isFullAccess}
          onChange={() => setIsFullAccess(!isFullAccess)}
        />

        {isFullAccess && (
          <StyledWarningWrapper>
            <FormattedMessage id="loop-details:common.fullAccessExplanation" />
          </StyledWarningWrapper>
        )}

        <StyledIntroCheckboxPadding>
          <Checkbox
            label={i18n.t('addParticipantModal.introEmail')}
            isChecked={isSendIntroEmail}
            onChange={() => setIsSendIntroEmail(!isSendIntroEmail)}
          />
        </StyledIntroCheckboxPadding>
      </StyledItem>
    </AppModal>
  ) : (
    <UpgradePremiumInfoModal
      isOpen={isOpen}
      onClose={onClose}
      modalMessage="upgradePremiumModal.addParticipantDescription"
    />
  );
}

export default AddParticipantModal;
