import { isEqual, omit, xor } from 'lodash-es';

import { I18nType } from '~/components/i18n/types';
import { RefData, TransactionType } from '~/lib/utils/refData';
import { loopFilterSettingsValueTypes } from '~/store/features/api/resources/preference/constants';
import { ArchivedFilter } from '~/store/features/api/resources/loop/constants';
import {
  SelectedFiltersType,
  FilterSettingGroupType,
  LoopsListPreferenceValue,
} from '~/store/features/api/resources/preference/types';

export enum FilterScope {
  All = 'all',
  Filtered = 'filtered',
  Active = 'Active',
}

export function upgradePreferenceValue(
  preferenceValue: LoopsListPreferenceValue
): LoopsListPreferenceValue {
  Object.keys(preferenceValue).forEach(key => {
    const valueKey = key as keyof LoopsListPreferenceValue;
    const value = preferenceValue[valueKey];

    if (valueKey === 'filterSettingGroups' && !!value && Array.isArray(value)) {
      // We want to make sure to "upgrade" custom filter groups when we go to save them.
      // This is because there could be old filter groups that don't match the shape of
      // filter groups as they exist now, so we fill in the 'blank' spots with what an
      // empty filter group contains.
      preferenceValue[valueKey] = (value as Array<FilterSettingGroupType>).map(
        group => {
          return {
            ...getEmptyFilterGroup(),
            ...group,
          };
        }
      );
    } else if (
      loopFilterSettingsValueTypes.indexOf(key) === -1 ||
      value === undefined ||
      (Array.isArray(value) && value.length === 0)
    ) {
      // There is some old data where there are some invalid keys on the root
      // preference value that were copied from `filterSettingGroups`.
      // This is invalid and causes preference validation to fail in lower environments.
      //
      // Additionally, force remove undefined / empty array values from the preference
      delete preferenceValue[valueKey];
    }
  });

  return preferenceValue;
}

export function areFiltersActive(filters?: SelectedFiltersType): boolean {
  if (!filters) {
    return false;
  }

  return getFilterCount(filters) > 0;
}

export function getFilterCount(filters?: SelectedFiltersType): number {
  if (!filters) {
    return 0;
  }

  const internalFilters: SelectedFiltersType = filters;

  return Object.keys(internalFilters).reduce((count, currentKey) => {
    const currentFilters =
      internalFilters[currentKey as keyof SelectedFiltersType];

    // Dealing with unsubmitted or archivedFilter radio buttons here
    if (!Array.isArray(currentFilters)) {
      const unsubmittedFilterActive =
        typeof currentFilters === 'boolean' && !!currentFilters;
      const hideArchivedNotActive =
        typeof currentFilters === 'string' &&
        currentFilters !== archivedFilters.hideArchived.value;

      if (unsubmittedFilterActive || hideArchivedNotActive) {
        return count + 1;
      }
    } else {
      return count + currentFilters.length;
    }

    return count;
  }, 0);
}

type ArchivedFilterValueType = {
  [key: string]: {
    label: string;
    value: ArchivedFilter;
    description: string;
  };
};

export const archivedFilters: ArchivedFilterValueType = {
  hideArchived: {
    label: 'my-loops:filters.archivedFilter.hideArchived',
    description: 'my-loops:filters.archivedFilterDescription.hideArchived',
    value: ArchivedFilter.HideArchived,
  },
  onlyArchived: {
    label: 'my-loops:filters.archivedFilter.onlyArchived',
    description: 'my-loops:filters.archivedFilterDescription.onlyArchived',
    value: ArchivedFilter.OnlyArchived,
  },
  all: {
    label: 'my-loops:filters.archivedFilter.all',
    description: 'my-loops:filters.archivedFilterDescription.all',
    value: ArchivedFilter.All,
  },
};

export function getArchivedFilterByValue(value: string) {
  for (const name in archivedFilters) {
    const filter = archivedFilters[name];
    if (filter.value === value) {
      return filter;
    }
  }
}

export function getEmptyFilters(): SelectedFiltersType {
  return {
    transactionTypeIds: [],
    statusIds: [],
    complianceStatusIds: [],
    tags: [],
    archivedFilter: archivedFilters.hideArchived.value,
    unsubmitted: false,
  };
}

export function getEmptyFilterGroup(): FilterSettingGroupType {
  return {
    name: '',
    transactionTypeIds: [],
    statusIds: [],
    complianceStatusIds: [],
    tags: [],
    archivedFilter: archivedFilters.hideArchived.value,
    unsubmittedFilter: false,
  };
}

interface FilterComparisonType
  extends Omit<SelectedFiltersType, 'archivedFilter'> {
  archivedFilter?: ArchivedFilter;
}
interface FilterSettingGroupComparisonType
  extends Omit<FilterSettingGroupType, 'archivedFilter'> {
  archivedFilter?: ArchivedFilter;
}
export function areFiltersSame(
  firstFilters?: FilterComparisonType,
  secondFilters?: FilterComparisonType | FilterSettingGroupComparisonType
) {
  if (!!firstFilters && !!secondFilters) {
    const archivedFilterDiffers =
      firstFilters.archivedFilter !== secondFilters.archivedFilter;
    const transactionTypesDiffer =
      xor(firstFilters.transactionTypeIds, secondFilters.transactionTypeIds)
        .length > 0;
    const loopStatusesDiffer =
      xor(firstFilters.statusIds, secondFilters.statusIds).length > 0;
    const unsubmittedFilterDiffers =
      firstFilters.unsubmitted !==
      ('unsubmitted' in secondFilters
        ? secondFilters.unsubmitted
        : secondFilters.unsubmittedFilter);
    const complianceStatusesDiffer =
      xor(firstFilters.complianceStatusIds, secondFilters.complianceStatusIds)
        .length > 0;
    const tagsDiffer = xor(firstFilters.tags, secondFilters.tags).length;

    return !(
      archivedFilterDiffers ||
      transactionTypesDiffer ||
      loopStatusesDiffer ||
      unsubmittedFilterDiffers ||
      complianceStatusesDiffer ||
      tagsDiffer
    );
  }

  return firstFilters === secondFilters;
}

export function isFilterSettingGroupChecked(
  filterSettingGroup: FilterSettingGroupType,
  selectedFilters: SelectedFiltersType
): boolean {
  return areFiltersSame(selectedFilters, filterSettingGroup);
}

export function getVisibleFiltersForType<T extends { [K in keyof T]: any }>(
  searchQuery = '',
  filterCollection: Array<T>,
  filterBy: keyof T
): Array<T> {
  if (!!searchQuery) {
    const searchLowerCase = searchQuery.toLowerCase();

    return filterCollection.filter(filterItem => {
      const filterProperty = filterItem[filterBy];
      const propertyValue = !!filterProperty ? filterProperty.toString() : null;

      return propertyValue
        ? ~propertyValue.toLowerCase().indexOf(searchLowerCase)
        : true;
    });
  } else {
    return filterCollection;
  }
}

export function getMaxHeight(
  baseMaxHeight: number | string,
  ...additionalSpace: Array<number | string>
): string {
  const calcedMaxHeight = [baseMaxHeight]
    .concat(additionalSpace)
    .filter(x => !!x)
    .join(' - ');

  return `calc(${calcedMaxHeight})`;
}

export function getTransactionTypeLabel(
  transactionTypeId: number,
  transactionTypes: Array<TransactionType>,
  i18n: I18nType
) {
  const transactionType = transactionTypes.find(
    t => t.id === transactionTypeId
  );

  return transactionType
    ? i18n.t(`loops:transactionTypes.${transactionType.text}`)
    : '';
}

export function getLoopStatusLabel(
  statusId: number,
  statuses: Array<RefData>,
  i18n: I18nType
) {
  const status = statuses.find(s => s.id === statusId);

  return status ? i18n.t(`loops:loopStatuses.${status.text}`) : '';
}

export function getFilterScope(
  appliedFilters: SelectedFiltersType | undefined
): FilterScope {
  if (!appliedFilters) {
    return FilterScope.All;
  }

  const hasDefaultFilters = isEqual(appliedFilters, getEmptyFilters());
  const appliedFilterWithoutArchive = omit(appliedFilters, ['archivedFilter']);
  const defaultFiltersWitoutArchived = omit(getEmptyFilters(), [
    'archivedFilter',
  ]);

  const hasDefaultFiltersExcludingArchived = areFiltersSame(
    appliedFilterWithoutArchive,
    defaultFiltersWitoutArchived
  );

  if (hasDefaultFilters) {
    return FilterScope.Active;
  } else if (
    hasDefaultFiltersExcludingArchived &&
    appliedFilters.archivedFilter === ArchivedFilter.All
  ) {
    return FilterScope.All;
  }

  return FilterScope.Filtered;
}
