import { Route, Routes } from 'react-router-dom';

import DefaultMobileHeader from './DefaultMobileHeader';

// Page specific mobile headers
import MyLoopsMobileHeader from '~/pages/MyLoops/components/MyLoopsMobileHeader';
import CreateLoopMobileHeader from '~/pages/CreateLoop/components/CreateLoopMobileHeader';
import LoopDetailsMobileHeader from '~/pages/LoopDetails/components/LoopDetailsMobileHeader';
import MessagingMobileHeader from '~/pages/Messaging/components/MessagingMobileHeader';

export function MobileHeader() {
  return (
    <Routes>
      <Route path="messages/*" element={<MessagingMobileHeader />} />
      <Route path="loops" element={<MyLoopsMobileHeader />} />
      <Route path="loops/create" element={<CreateLoopMobileHeader />} />
      <Route path="loops/:loopId/*" element={<LoopDetailsMobileHeader />} />
      <Route path="*" element={<DefaultMobileHeader />} />
    </Routes>
  );
}

export default MobileHeader;
