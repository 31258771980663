import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useI18n } from '~/hooks';
import { LinkButton } from '~/components/buttons';
import { LinkButtonType } from '~/components/buttons/LinkButton';
import {
  ArrowIcon,
  HistoryIcon,
  NotificationIcon,
  MoreOptionsIcon,
} from '~/components/icons';
import { RotationDirection } from '~/components/icons/types';
import Container from '~/components/layout/Container';
import LoopActionsMenu from './Overview/LoopActionsMenu';
import { colors, headerHeight, spacing } from '~/styles';
import AccountUnverified from '~/components/AccountUnverified';

import { ResourceTypes } from '~/store/features/api/resources/types';
import { useLoop } from '~/hooks/api/loop';
import { useParams } from 'react-router-dom';
import CountBadge from '~/components/CountBadge';
import { useAppDispatch } from '~/store/hooks';
import { fetchResource } from '~/store/features/api/apiSlice';
import StppLink from './StppLink';

const StyledAdditionalLinks = styled.div({
  marginLeft: 'auto',
  position: 'relative',

  '& > a:first-of-type': {
    marginLeft: 0,
  },
});

const StyledHeaderWrapper = styled.div({
  ...headerHeight.styles,
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
});

const StyledContainer = styled(Container)({
  position: 'relative',
  backgroundColor: colors.primaryBackgroundDark,
});

const StyledNotificationLink = styled.span<{ $hasCount: boolean }>(props => ({
  marginRight: props.$hasCount ? `calc(-${spacing.medium})` : '',
}));

const StyledCountBadge = styled(CountBadge)({
  position: 'relative',
  left: `calc(-${spacing.medium} - 2px)`,
  top: `calc(-${spacing.small})`,
});

type Props = {
  loopId: string;
  notificationCount?: number;
};

export function LinkButtonNotification(props: Props) {
  const { loopId, notificationCount } = props;
  return (
    <StyledNotificationLink
      $hasCount={notificationCount ? notificationCount > 0 : false}
    >
      <LinkButton
        id="mobile-loop-details-notification-link-button"
        link={`/loops/${loopId}/notifications`}
        icon={NotificationIcon}
        buttonStyle={LinkButtonType.White}
      />
      <StyledCountBadge count={notificationCount ?? 0} countLimit={99} />
    </StyledNotificationLink>
  );
}

function LoopDetailsMobileHeader() {
  const { loopId = '' } = useParams();
  const dispatch = useAppDispatch();
  const loop = useLoop(loopId, {
    relationships: [ResourceTypes.Tags],
    includeMeta: true,
  });

  const [isActionsDropdownOpen, setIsActionsDropdownOpen] = useState(false);

  useEffect(() => {
    if (!loop) {
      dispatch(
        fetchResource({ resourceName: ResourceTypes.Loops, resourceId: loopId })
      );
    }
  }, [loopId, loop, dispatch]);

  const toggleActionsDropdown = () => {
    setIsActionsDropdownOpen(!isActionsDropdownOpen);
  };
  const i18n = useI18n();

  return (
    <StyledContainer>
      <StyledHeaderWrapper>
        <LinkButton
          id="mobile-back-to-my-loops-button"
          buttonStyle={LinkButtonType.White}
          removeSidePadding
          icon={ArrowIcon}
          defaultIconProps={{ direction: RotationDirection.Left }}
          link="/loops"
          aria-label={i18n.t('actions.back')}
        >
          Loops
        </LinkButton>

        <StyledAdditionalLinks>
          <AccountUnverified />

          <StppLink loopId={loopId} />

          <LinkButton
            id="mobile-loop-details-activity-log-link-button"
            link={`/loops/${loopId}/activity`}
            icon={HistoryIcon}
            buttonStyle={LinkButtonType.White}
          />
          <LinkButtonNotification
            loopId={loopId}
            notificationCount={loop?.notificationCount}
          />
          <LinkButton
            id="mobile-loop-details-options-button"
            icon={MoreOptionsIcon}
            aria-label={i18n.t('loop-details:overview.actions.loopOptions')}
            buttonStyle={LinkButtonType.White}
            onClick={toggleActionsDropdown}
            defaultIconProps={{
              direction: RotationDirection.Right,
            }}
          />
        </StyledAdditionalLinks>
      </StyledHeaderWrapper>

      {!!loop && (
        <LoopActionsMenu
          triggerId="more-options"
          isOpen={isActionsDropdownOpen}
          onRequestClose={() => setIsActionsDropdownOpen(false)}
          loop={loop}
        />
      )}
    </StyledContainer>
  );
}

export default LoopDetailsMobileHeader;
