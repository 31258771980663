import qs from 'qs';
import config from '~/config';

import dev from './dev';
import prod from './prod';
import store from '~/store';

import LocalStorageService from '~/services/local-storage-service';

const {
  ui: { useDevFeatureFlags },
} = config;

let localStorageJson = LocalStorageService.get(
  LocalStorageService.KEYS.NovaFeatureFlags
);

export type FeatureFlagSettings = { [key: string]: boolean };

const featureFlags: FeatureFlagSettings = {};
const baseFlags: FeatureFlagSettings = {};

export function buildBaseFlags() {
  const flags: FeatureFlagSettings = useDevFeatureFlags
    ? { ...dev }
    : { ...prod };

  for (const flag in flags) {
    baseFlags[flag] = flags[flag];
  }

  return baseFlags;
}

interface QSFeatureFlags {
  [flag: string]: string;
}
export function buildFeatureFlags() {
  let additionalFlags: FeatureFlagSettings = {};
  if (Object.keys(baseFlags).length === 0) {
    buildBaseFlags();
  }

  if (!!window.location && !!window.location.search) {
    const search = window.location.search.slice(1);
    const searchValues = qs.parse(search);

    if (!!searchValues.NOVA_FEATURE_FLAGS) {
      const qsFeatureFlags = searchValues.NOVA_FEATURE_FLAGS as QSFeatureFlags;
      localStorageJson = localStorageJson || {};

      Object.keys(qsFeatureFlags).forEach(key => {
        localStorageJson[key] = qsFeatureFlags[key] === 'true';
      });

      LocalStorageService.set(
        LocalStorageService.KEYS.NovaFeatureFlags,
        localStorageJson
      );

      delete searchValues.NOVA_FEATURE_FLAGS;

      const updatedSearch = qs.stringify(searchValues);

      if (updatedSearch) {
        window.location.search = `?${updatedSearch}`;
      } else {
        window.location.href = window.location.pathname;
      }
    }
  }

  if (!!localStorageJson) {
    if (typeof localStorageJson === 'object') {
      additionalFlags = { ...additionalFlags, ...localStorageJson };
    }
  }

  Object.keys(baseFlags).forEach(flag => {
    if (additionalFlags.hasOwnProperty(flag)) {
      featureFlags[flag] = additionalFlags[flag];
    } else {
      featureFlags[flag] = baseFlags[flag];
    }
  });

  return featureFlags;
}

export function isFeatureEnabled(name: string): boolean {
  if (Object.keys(featureFlags).length === 0) {
    buildFeatureFlags();
  }

  const state = store.getState();
  const isAssumingControl = state.account.isAssumingControl;

  const flag = isAssumingControl ? baseFlags[name] : featureFlags[name];
  let featureEnabled = false;

  if (flag === undefined) {
    console.warn(
      `Undefined feature flag "${name}" passed to FeatureFlag component!`
    );
  } else if (typeof flag !== 'boolean') {
    console.warn(`Unexpected type "${typeof flag}" for feature flag "${name}"`);
  } else {
    featureEnabled = flag;
  }

  return featureEnabled;
}
