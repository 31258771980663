import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';

import { ResourceDefinition, ResourceTypes } from '../types';

const DocumentRevision: ResourceDefinition = {
  attributes: {
    documentId: ATTRIBUTE_TYPE.NUMBER,
    revision: ATTRIBUTE_TYPE.NUMBER,
    documentInvitationUrl: ATTRIBUTE_TYPE.STRING,
    canEdit: ATTRIBUTE_TYPE.BOOLEAN,
    canFill: ATTRIBUTE_TYPE.BOOLEAN,
    canSign: ATTRIBUTE_TYPE.BOOLEAN,
    canView: ATTRIBUTE_TYPE.BOOLEAN,
    documentFieldChanges: ATTRIBUTE_TYPE.AS_IS,
  },
  relationships: {
    document: {
      resourceType: ResourceTypes.Documents,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
    fields: {
      resourceType: ResourceTypes.DocumentFields,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
  },
};

export default DocumentRevision;
