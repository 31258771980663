import { ReactNode } from 'react';
import NotificationSnackbar from './__internal__/NotificationSnackbar';
import { NotificationType } from './types';

type Props = {
  type: NotificationType;
  children: ReactNode;
  hideClose?: boolean;
  onDismiss?: () => void;
  hideIcon?: boolean;
};

const InlineNotification = (props: Props) => {
  const {
    type,
    hideClose = true,
    onDismiss,
    children,
    hideIcon = false,
  } = props;

  return (
    <NotificationSnackbar
      inline
      type={type}
      hideClose={hideClose}
      dismiss={onDismiss}
      hideIcon={hideIcon}
    >
      {children}
    </NotificationSnackbar>
  );
};

InlineNotification.displayName = 'InlineNotification';

export default InlineNotification;
