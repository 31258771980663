import { makeVegaRequest } from '~/lib/api/network';

import {
  Recipient,
  RecipientType,
  RecipientRoutingType,
} from '~/components/Messaging/RecipientMultiSelect';
import { ParticipantResource } from '~/store/features/api/resources/participant/types';
import { ResourceTypes } from '~/store/features/api/resources/types';

export async function createParticipants(
  recipients: Array<Recipient>
): Promise<Array<ParticipantResource>> {
  const participantsToCreate = recipients.map(recipient => {
    let route, routingType;

    if (recipient.type === RecipientType.New) {
      // This is a new recipient, not an existing contact
      route = recipient.route;
      routingType = recipient.routingType;
    } else {
      // This is an existing contact record
      route = recipient.selectedRoute;
      routingType = recipient.selectedRoutingType;
    }

    const isPhoneNumber = routingType !== RecipientRoutingType.Email;
    const routeAttr = isPhoneNumber ? 'phoneNumber' : 'emailAddress';
    const transformedRoute =
      isPhoneNumber && !route.startsWith('+') ? `+1${route}` : route;

    const recipientRelationship = {
      participantId: transformedRoute,
      routingType: isPhoneNumber ? 'PHONE_NUMBER' : 'EMAIL',
      [routeAttr]: transformedRoute,
      firstName: recipient.firstName,
      lastName: recipient.lastName,
    };

    return recipientRelationship;
  });

  const result = await makeVegaRequest('participants/actions/bulkUpdate', {
    method: 'POST',
    body: JSON.stringify(participantsToCreate),
  });

  if (!!result && result.status === 200 && !!result.payload) {
    return result.payload.map((item: any) => ({
      id: item.id,
      type: ResourceTypes.Participants,
    }));
  } else {
    throw new Error('Failed to create participants');
  }
}
