import { useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '~/store/hooks';

import { makePreferenceSelector } from '~/store/features/api/selectors/preference';

import { PreferenceResource } from '~/store/features/api/resources/preference/types';
import { ResourceTypes } from '~/store/features/api/resources/types';
import { AppState } from '~/store';
import {
  areResourcesLoading,
  hasResourceBeenRequested,
} from '~/store/features/api/selectors';
import {
  fetchAllResources,
  updateResource,
} from '~/store/features/api/apiSlice';

/**
 * A hook to check if preferences have been loaded
 */
export function useArePreferencesLoaded() {
  /*
    Follow-up: Is there a case where preferences has been requested but is not loading? Kind of confusing.
    If so, then the `arePreferencesLoaded` can return a misleading true value, we would need an `isLoaded` status.
  */
  const havePreferencesBeenRequested = useAppSelector((state: AppState) =>
    hasResourceBeenRequested(state, ResourceTypes.Preferences)
  );
  const arePreferencesLoading = useAppSelector((state: AppState) =>
    areResourcesLoading(state, ResourceTypes.Preferences)
  );
  const arePreferencesLoaded =
    havePreferencesBeenRequested && !arePreferencesLoading;

  return arePreferencesLoaded;
}

/**
 * A hook that dispatches a thunk to load all preferences into redux state.
 * Only fetches preferences if not yet loaded.
 */
export function useFetchAllPreferences() {
  const dispatch = useAppDispatch();

  const arePreferencesLoaded = useArePreferencesLoaded();

  useEffect(() => {
    if (!arePreferencesLoaded) {
      dispatch(fetchAllResources({ resourceName: ResourceTypes.Preferences }));
    }
  }, [arePreferencesLoaded, dispatch]);
}

/**
 * A hook that returns the current value of a named PreferenceResource, and a convenience function for making subsequent updates.
 */
export function usePreferenceSelector(preferenceName: string) {
  const dispatch = useAppDispatch();
  const arePreferencesLoaded = useArePreferencesLoaded();

  const selectPreference = useMemo(
    () => makePreferenceSelector(preferenceName),
    [preferenceName]
  );

  // Need to default to null, because some preference value checks check especially for `=== undefined`
  // The intention being `undefined` means it hasn't loaded, and null for undefined values
  const preference = useAppSelector(selectPreference) ?? null;

  const updatePreference = useCallback(
    async (newPreferenceValue: Partial<PreferenceResource>) => {
      await dispatch(
        updateResource({
          resourceName: ResourceTypes.Preferences,
          resourceId: preferenceName,
          attributesToUpdate: newPreferenceValue,
          resourceRelationships: null,
          updateOptions: {
            isOptimistic: true,
            allowRollback: false,
          },
        })
      );
    },
    [dispatch, preferenceName]
  );

  const preferenceValue = arePreferencesLoaded ? preference : undefined;
  return [preferenceValue, updatePreference] as const;
}
