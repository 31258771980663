import { makePhoenixRequest, makeVegaRequest } from '~/lib/api/network';
import { AddressSuggestion } from './address-service/types';
import { FetchResponse } from '~/lib/api/types';

export async function resendEmailVerification(): Promise<any> {
  return makeVegaRequest('actions/sendEmailVerification');
}

export type AuthorizationResult = {
  isAuthorized: boolean;
  errorCode?: string;
};
export async function isAuthorizedForMlsAddressSuggestion(
  addressSuggestion: AddressSuggestion
): Promise<AuthorizationResult> {
  const { id, source } = addressSuggestion;
  const queryParams = [
    `meta[addressSourceId]=${id}`,
    `meta[addressSourceType]=${source}`,
  ];
  const queryString = queryParams.join('&');

  try {
    const response = await makeVegaRequest(
      `users/actions/mls/authentication?${queryString}`
    );

    return generateAuthorizationResult(response);
  } catch (error) {
    return { isAuthorized: false };
  }
}

export async function addParentProfile(
  profileId: string,
  parentProfileId: string,
  profileOptions: Record<string, string> = {
    name: '',
    address1: '',
    city: '',
    state: '',
    zipcode: '',
    phoneNumber: '',
  }
) {
  const body = JSON.stringify({
    ...profileOptions,
    profileId: parentProfileId,
  });

  try {
    await makePhoenixRequest(
      `user/profile/${profileId}/parent/${parentProfileId}`,
      {
        method: 'PUT',
        body,
      }
    );
  } catch (error) {}
}

export async function authorizeUserForMlsAddressSuggestion(
  agentCode: string,
  addressSuggestion: AddressSuggestion
): Promise<AuthorizationResult> {
  const { id } = addressSuggestion;
  const body = JSON.stringify({
    agentCode,
    addressSourceId: id,
  });

  try {
    const response = await makeVegaRequest(`users/actions/mls/authentication`, {
      method: 'POST',
      body,
    });

    return generateAuthorizationResult(response);
  } catch (error) {
    return { isAuthorized: false };
  }
}

function generateAuthorizationResult(response: FetchResponse) {
  const { status, payload } = response;
  const isAuthorized = status === 200 || status === 204;
  const result: AuthorizationResult = { isAuthorized };

  if (!isAuthorized && payload && payload.errors && payload.errors.length) {
    const [error] = payload.errors;

    result.errorCode = error.code;
  }

  return result;
}
