import { Fragment, ReactNode } from 'react';

import BaseModal, { BaseModalConsumerProps, ModalType } from './BaseModal';
import ModalHeader, { ModalHeaderTitleID } from './ModalHeader';
import ModalBody from './ModalBody';
import { ModalAction } from './ModalActions';

type Props = BaseModalConsumerProps & {
  /** Determines if the large variant of the modal should be used */
  large?: boolean;
  /** The primary action button for the modal: {labelId: i18nKey, onClick: event handler} */
  primaryAction?: ModalAction;
  /** Function called when cancel button is clicked */
  onCancel: () => void;
  /** Hides cancel button on the modal */
  hideCancel?: boolean;
  /** The contents of the modal */
  children?: ReactNode;
};

export function ConfirmationModal(props: Props) {
  const {
    isOpen,
    children,
    primaryAction,
    dismissable = false,
    large = false,
    isNegative,
    hideCancel,
    onCancel,
    title,
    ...baseModalProps
  } = props;

  return (
    <BaseModal
      isNegative={isNegative}
      type={large ? ModalType.Large : ModalType.Small}
      isOpen={isOpen}
      aria-labelledby={ModalHeaderTitleID}
      dismissable={dismissable}
      {...baseModalProps}
    >
      {({ onRequestClose }) => (
        <Fragment>
          <ModalHeader
            showTitle
            title={title}
            closeModal={onRequestClose}
            showClose={dismissable}
            canGoBack={false}
          />
          <ModalBody
            isNegative={isNegative}
            hideCancel={hideCancel}
            enableScroll={false}
            primaryAction={primaryAction}
            cancelAction={onCancel}
          >
            {children}
          </ModalBody>
        </Fragment>
      )}
    </BaseModal>
  );
}

ConfirmationModal.defaultProps = {
  large: false,
  showTitle: true,
  enableScroll: false,
  canGoBack: false,
};

export default ConfirmationModal;
