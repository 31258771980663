import config from '~/config';
import { merge } from 'lodash-es';
import { getCsrfCookie, XSRF_HEADER } from '~/lib/api/utils';

import { FetchResponse, ServerResponse } from './types';

let __JWT__: string;

const {
  api: {
    baseUrl: API_URL,
    phoenixBaseUrl: PHOENIX_API_URL,
    baseSearchUrl: BASE_SEARCH_API_URL,
    localProxyEnv,
  },
  ui: { phoenixUrl, phoenixDomain, isProductionBuild, isPullRequestBuild },
} = config;
const JSON_MIME_TYPE = 'application/json';
const JSONAPI_MIME_TYPE = 'application/vnd.api+json';
const PROXY_ENV_HEADER = 'X-DOTLOOP-ENV';

const BASE_OPTIONS = {
  method: 'GET',
  credentials: 'include',
};

export const CACHED_PROFILE_KEY = '__CURRENT_PROFILE_ID__';
export function setCachedProfile(profileId: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any)[CACHED_PROFILE_KEY] = profileId;
}
export function getCachedProfile() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (window as any)[CACHED_PROFILE_KEY];
}

export function forceJwtAuth(jwt: string) {
  __JWT__ = jwt;
}

export const PROFILE_ID_HEADER = 'X-DOTLOOP-PROFILE-ID';
export function redirectToSignin() {
  const ORIGINAL_PATH = encodeURIComponent(window.location.href);
  document.cookie = `ORIGINAL_PATH=${ORIGINAL_PATH}; path=/; domain=.${phoenixDomain}.com`;
  document.cookie = `ORIGINAL_PATH=${ORIGINAL_PATH}; path=/;`; //This is needed for test-cafe to pick up since it runs on a different domain

  if ((isProductionBuild && !isPullRequestBuild) || !localProxyEnv) {
    window.location.href = `${phoenixUrl}/#signin`;
  }
}

export function clearOriginalPathCookies() {
  const date = new Date(new Date().getTime() - 1).toUTCString();
  document.cookie = `ORIGINAL_PATH=; expires=${date};path=/; domain=.${phoenixDomain}.com`;
  document.cookie = `ORIGINAL_PATH=; expires=${date};path=/;`;
}

export function makePhoenixRequest(
  urlPath: string,
  options: RequestInit = {}
): Promise<FetchResponse> {
  const url = `${PHOENIX_API_URL}/${urlPath}`;
  const opts = mergeRequestOptions('json', options);

  return makeRequest(url, opts);
}

export function makeVegaRequest(
  urlPath: string,
  options: RequestInit = {}
): Promise<FetchResponse> {
  const url = `${API_URL}/${urlPath}`;
  const opts = mergeRequestOptions('json', options);

  return makeRequest(url, opts);
}

export function makeSearchRequest(
  urlPath: string,
  options: RequestInit = {}
): Promise<FetchResponse> {
  const url = `${BASE_SEARCH_API_URL}/${urlPath}`;
  const opts = mergeRequestOptions('json', options);

  return makeRequest(url, opts);
}

async function makeRequest(url: string, opts: RequestInit) {
  let response;
  let fetchResponse: FetchResponse;
  try {
    response = await fetch(encodeURI(url), opts);
    const json = await response.json();

    if (response.status >= 200 && response.status < 300) {
      fetchResponse = {
        status: response.status,
        payload: json,
        ok: response.ok,
      };
    } else {
      fetchResponse = {
        status: response.status,
        error: json.errors ? json.errors[0] : json,
        ok: response.ok,
      };
    }
  } catch (error) {
    fetchResponse = {
      status: response ? response.status : 506,
      error: error instanceof Error ? error.message : String(error),
      ok: response ? response.ok : false,
    };
  }
  return fetchResponse;
}

export async function makeJSONAPIRequest(
  url: string,
  options: RequestInit = { method: 'GET' }
): Promise<ServerResponse> {
  const opts = mergeRequestOptions('jsonapi', options);
  let response;

  try {
    response = await fetch(url, opts);

    if (response.status === 204) {
      return { status: response.status };
    } else if (response.status === 401) {
      redirectToSignin();
    }

    const json = await response.json();

    const isStatusOk = response.status >= 200 && response.status < 300;

    if ((isStatusOk && json.data) || json.errors !== undefined) {
      return json;
    } else if (isStatusOk) {
      return { status: response.status, payload: json };
    }

    return { status: response.status, error: response.statusText };
  } catch (error) {
    return {
      status: !!response ? response.status : 418,
      error: error instanceof Error ? error.message : String(error),
    };
  }
}

type RequestType = 'json' | 'jsonapi';
type Options = RequestInit;
function mergeRequestOptions(
  requestType: RequestType,
  options: Options
): Options {
  const CURRENT_PROFILE_ID = getCachedProfile();
  const mimeType = requestType === 'json' ? JSON_MIME_TYPE : JSONAPI_MIME_TYPE;
  const headers: Record<string, unknown> = {
    Accept: mimeType,
    'Content-Type': mimeType,
    ...options.headers,
  };

  if (!!__JWT__) {
    headers['Authorization'] = `Bearer ${__JWT__}`;
  } else {
    headers[XSRF_HEADER] = getCsrfCookie();
  }

  // Only set this header if we actually have a value, otherwise we get a 400 - bad request
  if (CURRENT_PROFILE_ID && !headers[PROFILE_ID_HEADER]) {
    headers[PROFILE_ID_HEADER] = CURRENT_PROFILE_ID;
  }

  if ((!isProductionBuild || isPullRequestBuild) && localProxyEnv) {
    headers[PROXY_ENV_HEADER] = localProxyEnv;
  }

  const mergedOptions: Options = merge({}, BASE_OPTIONS, options, { headers });

  return mergedOptions;
}
