import { ReactNode } from 'react';
import styled from 'styled-components';

import { noop } from 'lodash-es';

import {
  CheckmarkIcon,
  CloseIcon,
  ErrorIcon,
  AlertSolidIcon,
  InformationIcon,
} from '~/components/icons';
import {
  boxShadows,
  breakpoints,
  colors,
  createTransform,
  fontSizes,
  spacing,
  utils,
} from '~/styles';

import { NotificationType } from '../types';
import { LinkButton } from '~/components/buttons';
import { LinkButtonType } from '~/components/buttons/LinkButton';
import { useAutoId } from '~/lib/utils/auto-id';
import { useI18n } from '~/hooks';

const StyledCloseIcon = styled.div({
  ...createTransform('translateY(-50%)'),
  position: 'absolute',
  top: '50%',
  right: spacing.smaller,
});

const StyledIndicator = styled.div<{
  $backgroundColor: string;
  $indicatorWithIconEnabled?: boolean;
}>(props => ({
  position: 'absolute',
  top: '-1px',
  left: '-1px',
  bottom: '-1px',
  width: '0.5rem',
  backgroundColor: props.$backgroundColor,
  borderTopLeftRadius: utils.baseBorderRadius,
  borderBottomLeftRadius: utils.baseBorderRadius,
  ...(props.$indicatorWithIconEnabled
    ? {
        display: 'none',

        [breakpoints.MEDIUM]: {
          display: 'flex',
          justifyContent: 'center',
          width: '3rem',
        },
      }
    : {}),
}));

const StyledContainer = styled.div<{
  $isInline: boolean;
  $borderColor: string;
}>(props => ({
  ...fontSizes.body,
  position: 'relative',
  paddingTop: spacing.small,
  paddingBottom: spacing.small,
  paddingLeft: spacing.normal,
  paddingRight: props.$isInline
    ? spacing.normal
    : `calc(${spacing.larger} + ${spacing.normal})`,
  backgroundColor: colors.white,
  border: `1px solid ${props.$borderColor}`,
  borderRadius: utils.baseBorderRadius,
  boxShadow: props.$isInline ? 'none' : boxShadows.surroundDark,

  [breakpoints.MEDIUM]: {
    paddingLeft: `calc(3rem + ${spacing.normal})`,
  },
}));

type Props = {
  type: NotificationType;
  children: ReactNode;
  inline?: boolean;
  hideClose?: boolean;
  dismiss?: () => void;
  hideIcon?: boolean;
};

const ICON_TYPE_MAP = {
  info: InformationIcon,
  success: CheckmarkIcon,
  warning: AlertSolidIcon,
  error: ErrorIcon,
  offline: AlertSolidIcon,
  alert: AlertSolidIcon,
};

export default function NotificationSnackbar({
  type,
  dismiss,
  children,
  inline = false,
  hideClose = false,
  hideIcon = false,
}: Props) {
  const i18n = useI18n();
  const notificationId = useAutoId();
  const colorName = `${type}Color`;
  const color = (colors as any)[colorName] || colors['hintText'];
  const IconComponent = ICON_TYPE_MAP[type];
  const onClose = dismiss || noop;

  return (
    <StyledContainer $isInline={inline} $borderColor={color}>
      <span data-id="snackbar-notification">{children}</span>
      <StyledIndicator $backgroundColor={color}>&nbsp;</StyledIndicator>
      <StyledIndicator
        $backgroundColor={color}
        $indicatorWithIconEnabled={!hideIcon}
      >
        {!hideIcon && (
          <IconComponent type="primaryBackgroundText" width="40%" />
        )}
      </StyledIndicator>
      {!hideClose && (
        <StyledCloseIcon>
          <LinkButton
            id={`dismiss-notification-${notificationId}`}
            name="dismiss-notification"
            aria-label={i18n.t('actions.dismissNotification')}
            icon={CloseIcon}
            buttonStyle={LinkButtonType.Plain}
            defaultIconProps={{
              height: spacing.normal,
              type: 'grey',
            }}
            onClick={onClose}
          />
        </StyledCloseIcon>
      )}
    </StyledContainer>
  );
}
