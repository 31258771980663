export const Categories = {
  SYSTEM: 'system',
  INTERACTION: 'interaction',
  APP_STATE: 'application_state',
  PAGE_VIEW: 'page_view',
};

export enum AnalyticsContext {
  Page = 'Page',
  Modal = 'Modal',
}

export interface ContextEntry {
  type: AnalyticsContext;
  name: string;
  key: string;
}
