import { forwardRef, ForwardedRef } from 'react';

import {
  colors,
  spacing,
  fontSizes,
  fontWeights,
  utils,
  zIndex,
} from '~/styles';
import BaseInput, { ConsumerInputProps } from './__internal__/BaseInput';

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    maxWidth: '100%',
  },
  hidden: {
    flex: '0 0 0',
    opacity: 0,
    pointerEvents: 'none',
    zIndex: zIndex.hidden,
  },
  formattedValue: {
    ...utils.text.truncate,
    flex: '1 1 auto',
    order: 1,
    color: colors.black,
    fontWeight: fontWeights.semiBold,
  },
  label: {
    flex: '0 0 auto',
    order: 0,
    marginRight: spacing.smaller,
  },
  input: {
    ...fontSizes.body,
    width: '100%',
    padding: 0,
    margin: 0,
    border: 'none',
    borderRadius: 0,
    backgroundColor: 'inherit',
    outline: 'none',
    cursor: 'initial',
    order: 1,

    '&::-ms-clear': {
      display: 'none',
    },

    '&::placeholder': {
      color: colors.primaryLink,
      fontWeight: fontWeights.semiBold,
      opacity: 1,
    },
  },
  inputWithValue: {
    color: colors.black,
    fontWeight: fontWeights.semiBold,
  },
  inputDisabled: {
    color: colors.disabledText,

    '&::placeholder': {
      color: colors.disabledText,
    },
  },
  mutedInputPlaceholder: {
    '&::placeholder': {
      color: colors.hintText,
      opacity: 1,
    },
  },
  invalidInput: {
    borderBottom: `1px solid ${colors.errorIcon}`,

    '&::placeholder': {
      color: colors.errorText,
    },
  },
  subtext: {
    borderTop: '1px solid transparent',
  },
  invalidSubtextFocused: {
    borderTop: `1px solid ${colors.errorIcon}`,
  },
};

interface Props extends ConsumerInputProps {
  /** Lets consumer control whether to show placeholder */
  showPlaceholder?: boolean;
  /** Mutes the color of the input placeholder */
  mutedPlaceholder?: boolean;
}

function InlineTextInputBase(
  {
    placeholder,
    showPlaceholder = true,
    mutedPlaceholder = false,
    ...inputProps
  }: Props,
  inputRef: ForwardedRef<HTMLInputElement>
) {
  const inputPlaceholder = showPlaceholder ? placeholder : undefined;

  return (
    <BaseInput
      {...inputProps}
      ref={inputRef}
      placeholder={inputPlaceholder}
      containerClasses={{ default: styles.container }}
      labelClasses={{ default: styles.label }}
      inputClasses={{
        default: [
          styles.input,
          mutedPlaceholder && styles.mutedInputPlaceholder,
        ],
        withValue: styles.inputWithValue,
        invalid: styles.invalidInput,
        disabled: styles.inputDisabled,
      }}
      subtextClasses={{
        default: styles.subtext,
        invalidFocused: styles.invalidSubtextFocused,
        invalidHovered: styles.invalidSubtextFocused,
      }}
      formattedValueClasses={{ default: styles.formattedValue }}
    />
  );
}

export const InlineTextInput = forwardRef<HTMLInputElement, Props>(
  InlineTextInputBase
);

export default InlineTextInput;
