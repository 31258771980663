import isValid from 'date-fns/is_valid';
import parse from 'date-fns/parse';

import { TransactionType } from '~/store/features/api/resources/loop/constants';

import {
  getAvailableSorts,
  SortKey,
  AppliedSortType,
} from '../../utils/sorting';
import { LoopResource } from '~/store/features/api/resources/loop/types';

const DEFAULT_HIDDEN_SORTS = [SortKey.TransactionPrice, SortKey.ClosingDate];

type DisplayObject = {
  labelId: string;
  value: Date;
};

export function getSortDisplay(
  appliedSort: AppliedSortType | null,
  loopData: LoopResource,
  hiddenSortList: Array<SortKey> = DEFAULT_HIDDEN_SORTS
): DisplayObject | null {
  if (!appliedSort) {
    return null;
  } else {
    const sortId = appliedSort.sortId;

    if (~hiddenSortList.indexOf(sortId)) {
      return null;
    }

    const value = loopData[sortId];
    let parsedValue: Date;

    if (!value) {
      return null;
    } else {
      parsedValue = parse(value);

      if (!isValid(parsedValue)) {
        return null;
      }
    }

    const availableSorts = getAvailableSorts();
    const foundSort = availableSorts.find(s => s.sortId === sortId);
    if (!foundSort || !foundSort.cardIndicatorId) {
      return null;
    }

    return {
      labelId: foundSort.cardIndicatorId,
      value: parsedValue,
    };
  }
}

export function isRentTransactionType(transactionTypeId?: number) {
  return (
    transactionTypeId === TransactionType.ListingForLease ||
    transactionTypeId === TransactionType.Lease
  );
}
