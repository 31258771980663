import { Component } from 'react';
import { FormattedMessage } from '~/components/i18n';
import styled from 'styled-components';
import differenceInHours from 'date-fns/difference_in_hours';

import Container from '~/components/layout/Container';
import { CloseIcon } from '~/components/icons';
import {
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  spacing,
  zIndex,
} from '~/styles';

import { isPolyfilled } from '~/polyfill';
import LocalStorageService from '~/services/local-storage-service';

const StyledCloseIconContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: spacing.largest,
  cursor: 'pointer',
  marginLeft: 'auto',
});

const StyledText = styled.div({
  ...fontSizes.body,
  display: 'flex',
  alignItems: 'center',
  flex: '0 0 95%',
});

const StyledContainer = styled(Container)({
  fontWeight: fontWeights.semiBold,
  display: 'flex',
  alignItems: 'center',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',

  [breakpoints.LARGE]: {
    padding: '0.5rem 0 !important',
  },
});

const StyledWrapper = styled.div({
  color: colors.warningText,
  backgroundColor: colors.warningBackground,
  zIndex: zIndex.notificationSnackbars,
});

function setBrowserDismissal() {
  const browserDisclaimer = {
    hasDismissedBrowserWarning: true,
    timestamp: new Date(),
  };

  LocalStorageService.set(
    LocalStorageService.KEYS.BrowserDisclaimerKey,
    browserDisclaimer
  );
}

function showBrowserWarning(): boolean {
  let result = isPolyfilled();

  if (result) {
    const browserWarning = LocalStorageService.get(
      LocalStorageService.KEYS.BrowserDisclaimerKey
    );

    if (browserWarning) {
      const isDismissalOld =
        differenceInHours(new Date(), browserWarning.timestamp) >= 24;

      result = !browserWarning.hasDismissedBrowserWarning || isDismissalOld;
    }
  }

  return result;
}

type Props = Record<string, unknown>;

type State = {
  showBanner: boolean;
};

class BrowserUpdate extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showBanner: showBrowserWarning(),
    };
  }

  closeBanner = () => {
    this.setState(
      {
        showBanner: false,
      },
      () => {
        setBrowserDismissal();
      }
    );
  };

  render() {
    const { showBanner } = this.state;

    if (!showBanner) {
      return null;
    }

    return (
      <StyledWrapper id="browser-update-banner">
        <StyledContainer>
          <StyledText>
            <FormattedMessage id="unsupportedBrowser" />
          </StyledText>
          <StyledCloseIconContainer onClick={this.closeBanner} role="button">
            <CloseIcon type="warningText" height="60%" />
          </StyledCloseIconContainer>
        </StyledContainer>
      </StyledWrapper>
    );
  }
}

export default BrowserUpdate;
