import { useState, useRef, MouseEvent } from 'react';
import styled from 'styled-components';
import { isEqual } from 'lodash-es';

import { LinkButton, PrimaryButton } from '~/components/buttons';
import { ChevronIcon } from '~/components/icons';
import { RotationDirection } from '~/components/icons/types';
import Checkbox from '~/components/inputs/checkbox';
import { useHasFeature } from '~/hooks/api/feature';
import {
  boxShadows,
  breakpoints,
  colors,
  fontWeights,
  spacing,
  utils,
  zIndex,
} from '~/styles';

import LocalStorageService from '~/services/local-storage-service';
import {
  buildFeatureFlags,
  buildBaseFlags,
  FeatureFlagSettings,
} from '~/lib/feature-flags';
import { useIsDesktop } from '~/hooks';

const StyledActions = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '100%',

  '& > button:last-child': {
    marginLeft: 'auto',
  },
});

const StyledFeatureList = styled.ul({
  margin: 0,
  padding: 0,
  listStyle: 'none',
});

const StyledMessageBold = styled.div({
  marginTop: spacing.small,
  fontWeight: fontWeights.bold,
});

const StyledMessage = styled.div({
  marginBottom: spacing.normal,
  lineHeight: spacing.medium,
});

const StyledContent = styled.div({
  padding: spacing.small,
  backgroundColor: colors.white,
  borderLeft: `1px solid ${colors.lightBorder}`,
  borderRight: `1px solid ${colors.lightBorder}`,
});

const StyledToggle = styled.button({
  position: 'absolute',
  bottom: 0,
  left: '50%',
  display: 'flex',
  alignItems: 'center',
  width: '12rem',
  padding: `${spacing.smallest} ${spacing.small}`,
  color: colors.primaryBackgroundText,
  boxShadow: boxShadows.bottom,
  borderBottomLeftRadius: utils.baseBorderRadius,
  borderBottomRightRadius: utils.baseBorderRadius,
  backgroundColor: colors.primaryButton,
  transform: 'translate(-50%, 100%)',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',

  '&:focus, &:active': {
    border: 'none',
    outline: 'none',
    backgroundColor: colors.primaryButtonFocus,
  },

  '> div': {
    marginLeft: 'auto',
  },

  [breakpoints.MEDIUM]: {
    top: 0,
    bottom: 'auto',
    left: 'auto',
    width: '20rem',
    boxShadow: boxShadows.top,
    borderTopLeftRadius: utils.baseBorderRadius,
    borderTopRightRadius: utils.baseBorderRadius,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transform: 'translateY(-100%)',
  },
});

const StyledContainer = styled.div<{
  $containerExpandedEnabled: boolean;
}>(props => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  boxShadow: boxShadows.bottom,
  transform: 'translateY(-100%)',
  zIndex: zIndex.overlayBackground,
  transition: 'transform 0.2s ease-in-out',
  ...(props.$containerExpandedEnabled
    ? { [breakpoints.MEDIUM]: { transform: 'translateY(0)' } }
    : {}),

  [breakpoints.MEDIUM]: {
    top: 'auto',
    left: 'auto',
    bottom: 0,
    right: spacing.larger,
    width: '20rem',
    boxShadow: boxShadows.top,
    transform: 'translateY(100%)',
    ...(props.$containerExpandedEnabled
      ? { [breakpoints.MEDIUM]: { transform: 'translateY(0)' } }
      : {}),
  },
}));

export function SuperAdminFeatureFlagManagement() {
  const hasAccessToAdmin = useHasFeature('ACCESS_DOTLOOP_ADMIN_TOOL');
  const isDesktop = useIsDesktop();
  const [isExpanded, setIsExpanded] = useState(false);
  const featureFlags = useRef<FeatureFlagSettings>(buildFeatureFlags()).current;
  const baseFlags = useRef<FeatureFlagSettings>(buildBaseFlags()).current;
  const [featureFlagToggles, setFeatureFlagToggles] = useState(
    () => featureFlags
  );
  const togglesMatchCurrentFlags = isEqual(featureFlagToggles, featureFlags);
  const collapsedIconDirection = isDesktop
    ? RotationDirection.Up
    : RotationDirection.Down;
  const expandedIconDirection = isDesktop
    ? RotationDirection.Down
    : RotationDirection.Up;

  if (!hasAccessToAdmin) {
    return null;
  }

  const handleToggleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget && e.currentTarget.blur) {
      e.currentTarget.blur();
    }

    if (isExpanded) {
      setFeatureFlagToggles({ ...featureFlags });
    }

    setIsExpanded(!isExpanded);
  };

  const handleCheckboxClick = (flag: string) => {
    setFeatureFlagToggles({
      ...featureFlagToggles,
      [flag]: !featureFlagToggles[flag],
    });
  };

  const handleSaveFeatureFlags = () => {
    const togglesMatchBaseFlags = isEqual(featureFlagToggles, baseFlags);

    if (togglesMatchBaseFlags) {
      // We're saving toggles that exactly match the base flags,
      // remove feature flags from local storage
      LocalStorageService.remove(LocalStorageService.KEYS.NovaFeatureFlags);
    } else {
      // Save only the difference between baseFlags and the updated toggles
      const flagsToStore = Object.keys(featureFlagToggles).reduce(
        (diffFlags: typeof featureFlagToggles, flag) => {
          if (baseFlags[flag] !== featureFlagToggles[flag]) {
            diffFlags[flag] = featureFlagToggles[flag];
          }

          return diffFlags;
        },
        {}
      );

      LocalStorageService.set(
        LocalStorageService.KEYS.NovaFeatureFlags,
        flagsToStore
      );
    }

    window.location.reload();
  };

  return (
    <StyledContainer $containerExpandedEnabled={isExpanded}>
      <StyledToggle onClick={handleToggleClick}>
        {isDesktop ? 'Manage Feature Flags' : 'Feature Flags'}
        <ChevronIcon
          type="primaryBackgroundText"
          height="0.75rem"
          direction={
            isExpanded ? expandedIconDirection : collapsedIconDirection
          }
        />
      </StyledToggle>
      <StyledContent>
        <StyledMessage>
          Use the checkboxes below to enable / disable experimental features.
          <StyledMessageBold>
            This is only visible to internal dotloop employees
          </StyledMessageBold>
        </StyledMessage>
        <StyledFeatureList>
          {Object.keys(featureFlags).map(flag => (
            <li key={flag}>
              <Checkbox
                label={flag
                  .split('_')
                  .map(
                    word =>
                      `${word.charAt(0)}${word.substring(1).toLowerCase()}`
                  )
                  .join(' ')}
                isChecked={featureFlagToggles[flag]}
                onChange={() => {
                  handleCheckboxClick(flag);
                }}
              />
            </li>
          ))}
        </StyledFeatureList>
        <StyledActions>
          <LinkButton
            id="cancel-feature-flags"
            removeSidePadding
            onClick={handleToggleClick}
          >
            Cancel
          </LinkButton>
          <PrimaryButton
            id="save-feature-flags"
            disabled={togglesMatchCurrentFlags}
            onClick={handleSaveFeatureFlags}
          >
            Save
          </PrimaryButton>
        </StyledActions>
      </StyledContent>
    </StyledContainer>
  );
}

export default SuperAdminFeatureFlagManagement;
