import { useState } from 'react';
import { FormattedMessage } from '~/components/i18n';
import styled from 'styled-components';

import { AppState } from '~/store';
import { isAccountVerified } from '~/store/features/account/selectors';
import { useAppSelector } from '~/store/hooks';

import { AppModal } from '~/components/Modal';

import { colors, fontSizes, spacing, zIndex } from '~/styles';
import { resendEmailVerification } from '~/services/user-service';
import { useI18n } from '~/hooks';

const StyledUnverifiedOverlay = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  background: 'transparent',
  zIndex: zIndex.foreground,
  cursor: 'pointer',
});

const StyledTip = styled.div({
  ...fontSizes.callout,
  color: colors.secondaryText,
  marginTop: spacing.large,
});

function AccountUnverified() {
  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
  const i18n = useI18n();
  const accountVerified = useAppSelector((state: AppState) =>
    isAccountVerified(state)
  );

  const closeVerifyEmailModal = () => {
    setShowVerifyEmailModal(false);
  };

  const openVerifyEmailModal = () => {
    setShowVerifyEmailModal(true);
  };

  const handleSendEmailVerification = async () => {
    await resendEmailVerification();
    closeVerifyEmailModal();
  };

  if (accountVerified) {
    return null;
  }

  return (
    <div>
      <StyledUnverifiedOverlay onClick={openVerifyEmailModal} />
      <AppModal
        name="verify-email"
        isOpen={showVerifyEmailModal}
        title={i18n.t('accountUnverified.title')}
        onRequestClose={closeVerifyEmailModal}
        enableScroll={false}
        large
        secondaryAction={{
          labelId: 'accountUnverified.secondaryAction',
          onClick: () => window.location.reload(),
        }}
        primaryAction={{
          labelId: 'accountUnverified.primaryAction',
          onClick: handleSendEmailVerification,
        }}
      >
        <FormattedMessage id="accountUnverified.content" />

        <StyledTip>
          <FormattedMessage id="accountUnverified.tip" />
        </StyledTip>
      </AppModal>
    </div>
  );
}

export default AccountUnverified;
