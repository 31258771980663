enum imageResizeLookup {
  local = 'https://ir-api.dev-dotloop.com/dev',
  dev = 'https://ir-api.dev-dotloop.com/dev',
  qa = 'https://ir-api.qa-dotloop.com/qa',
  stage = 'https://ir-api.stage-dotloop.com/stage',
  test = 'https://ir-api.dotloop.com/prod',
  prod = 'https://ir-api.dotloop.com/prod',
}

export default imageResizeLookup;
