import { useEffect } from 'react';

/**
 * Set whether the main app element (div#root) is visible to screen readers
 */
function setAppAriaHidden(hidden: boolean) {
  const rootEl = document.getElementById('root');

  if (rootEl && hidden) {
    rootEl.setAttribute('aria-hidden', 'true');
  } else if (rootEl) {
    rootEl.removeAttribute('aria-hidden');
  }
}

export function useAppAriaHidden(ariaHidden: boolean) {
  useEffect(() => {
    setAppAriaHidden(ariaHidden);
    return () => {
      setAppAriaHidden(false);
    };
  }, [ariaHidden]);
}
