import styled from 'styled-components';
import { Component, ChangeEvent, KeyboardEvent } from 'react';

const StyledTextarea_1 = styled.textarea({});

type Props = {
  /** id of the textarea input */
  id: string;
  defaultRows: number;
  value: string;
  disabled: boolean;
  onChange: (value: string) => any;
  placeholder?: string;
  /** Needs to be proxied down in case this component is extended  */
  className?: string;
  onEnter?: () => any;
  onRowsChange?: (rows: number) => any;
  textAreaRef?: (ref: HTMLTextAreaElement | null) => void;
};

type State = {
  rows: number;
};

export class DynamicTextArea extends Component<Props, State> {
  textAreaRef: HTMLTextAreaElement | null = null;

  static defaultProps = {
    disabled: false,
  };

  state = {
    rows: this.props.defaultRows,
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.rows !== this.state.rows && this.props.onRowsChange) {
      this.props.onRowsChange(this.state.rows);
    } else {
      this.updateTextAreaRows(prevProps, prevState);
    }
  }

  setupTextAreaRef = (ref: HTMLTextAreaElement | null) => {
    this.textAreaRef = ref;

    if (this.props.textAreaRef) {
      this.props.textAreaRef(ref);
    }
  };

  handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;

    this.props.onChange(value);
  };

  handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    const { onEnter } = this.props;

    if (
      onEnter &&
      event.key === 'Enter' &&
      !event.shiftKey &&
      !event.ctrlKey &&
      !event.altKey &&
      !event.metaKey
    ) {
      event.preventDefault();
      onEnter();
    }
  };

  updateTextAreaRows = (prevProps: Props, prevState: State) => {
    const textAreaRef = this.textAreaRef;

    if (textAreaRef) {
      if (
        prevProps.defaultRows !== this.props.defaultRows &&
        this.props.defaultRows > this.state.rows
      ) {
        // Default rows changed and is larger than the current number of
        // rows, so increase the current number to the new default
        this.setState({ rows: this.props.defaultRows });
      } else if (this.props.value !== prevProps.value) {
        const prevLength = prevProps.value.length;
        const currentLength = this.props.value.length;

        // Message text has changed, so we need to determine if we need
        // to grow or shrink the number of rows in the text area
        if (currentLength > prevLength) {
          // We added text, tran and see if we need to increase the number of rows
          this.increaseRowsIfNeeded(textAreaRef, prevState);
        } else if (currentLength < prevLength && this.state.rows > 1) {
          // We removed text, try and see if we can reduce the number of rows
          this.decreaseRowsIfNeeded(textAreaRef, prevState);
        }
      }
    }
  };

  increaseRowsIfNeeded = (
    textAreaRef: HTMLTextAreaElement,
    prevState: State
  ) => {
    let newNumRows = prevState.rows;

    while (textAreaRef.scrollHeight > textAreaRef.clientHeight) {
      newNumRows++;
      textAreaRef.rows = newNumRows;
    }

    // Reset the changes to textAreaRef.rows, since it's managed by state
    textAreaRef.rows = prevState.rows;

    if (newNumRows !== prevState.rows) {
      this.setState({ rows: newNumRows });
    }
  };

  decreaseRowsIfNeeded = (
    textAreaRef: HTMLTextAreaElement,
    prevState: State
  ) => {
    let newNumRows = prevState.rows;

    while (
      newNumRows > 1 &&
      newNumRows > this.props.defaultRows - 1 &&
      textAreaRef.scrollHeight <= textAreaRef.clientHeight
    ) {
      newNumRows--;
      textAreaRef.rows = newNumRows;
    }

    if (newNumRows < prevState.rows) {
      // We ended with newNumRows causing overflow, so the actual number
      // of desired rows is one greater than newNumRows
      newNumRows = newNumRows + 1;

      // Reset the changes to textAreaRef.rows, since it's managed by state
      textAreaRef.rows = prevState.rows;

      if (newNumRows !== prevState.rows) {
        // We changed the number of rows, so update state
        this.setState({ rows: newNumRows });
      }
    }
  };

  render() {
    const { id, value, disabled, className, placeholder } = this.props;
    const { rows } = this.state;

    return (
      <StyledTextarea_1
        id={id}
        ref={this.setupTextAreaRef}
        className={className}
        rows={rows}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
      />
    );
  }
}

export default DynamicTextArea;
