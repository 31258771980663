import { ContextEntry } from './constants';

export function track(
  category: string,
  action: string,
  label: string | number,
  context?: ContextEntry
) {
  const pendo = (window as any).pendo;

  if (pendo && pendo.track) {
    pendo.track(action, {
      event_category: category,
      event_label: label,
      context,
    });
  }
}
