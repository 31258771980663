import { Fragment } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import { PADDING_XSM, PADDING_SM } from '~/components/layout/Container';
import NewConversation from '~/components/Messaging/NewConversation';
import Conversation from '~/components/Messaging/Conversation';
import ConversationList, {
  NEW_CONVERSATION_ID,
} from '~/components/Messaging/ConversationList';
import ConversationDetails from '~/components/Messaging/ConversationDetails';
import ConversationFetcher, {
  ConversationRenderProps,
} from '~/components/Messaging/ConversationFetcher';
import PubNubInitializer from '~/components/Analytics/PubNub';
import { getSearchFilterParams } from '~/lib/utils/query-params';

import {
  breakpoints,
  colors,
  headerHeight,
  screenSizes,
  zIndex,
} from '~/styles';
import {
  useApplicationScrolling,
  useMedia,
  usePubNubWebSockeService,
} from '~/hooks';

const CONVERSATION_WIDTH = '19rem';
const MEDIUM_BREAKPOINT = headerHeight.styles[breakpoints.MEDIUM];
const TABLET_HEADER_OFFSET =
  typeof MEDIUM_BREAKPOINT === 'object' ? MEDIUM_BREAKPOINT.height : '';

const slideTransition = { duration: 0.3, ease: 'easeInOut' };

const StyledConversationDetails = styled.div<{
  $hiddenEnabled: boolean;
}>(props => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: zIndex.fullPageOverlay,

  [breakpoints.MEDIUM]: {
    top: TABLET_HEADER_OFFSET,
    left: CONVERSATION_WIDTH,
    width: `calc(100% - ${CONVERSATION_WIDTH})`,
  },

  ...(props.$hiddenEnabled
    ? {
        display: 'none',
        pointerEvents: 'none',
      }
    : {}),
}));

const StyledConversation = styled.div<{ $isDisplayed: boolean }>(props => ({
  display: props.$isDisplayed ? 'block' : 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: zIndex.fullPageOverlay,

  [breakpoints.MEDIUM]: {
    top: TABLET_HEADER_OFFSET,
    left: CONVERSATION_WIDTH,
    width: `calc(100% - ${CONVERSATION_WIDTH})`,
    transform: 'translateZ(0)',
  },
}));

const StyledConversationList = styled.div({
  marginLeft: `-${PADDING_XSM}`,
  marginRight: `-${PADDING_XSM}`,
  borderTop: `1px solid ${colors.lightBorder}`,

  [breakpoints.SMALL]: {
    marginLeft: `-${PADDING_SM}`,
    marginRight: `-${PADDING_SM}`,
  },

  [breakpoints.MEDIUM]: {
    position: 'fixed',
    top: TABLET_HEADER_OFFSET,
    bottom: 0,
    width: CONVERSATION_WIDTH,
    borderTop: 'none',
    borderRight: `1px solid ${colors.lightBorder}`,
  },
});

const StyledConversationContents = styled(motion.div)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

type ConversationDetailsContainerProps = {
  conversationId: string | undefined;
  isShowingDetails: boolean;
  hideConversationDetails: (conversationId: string) => void;
};

function ConversationDetailsContainer(
  props: ConversationDetailsContainerProps
) {
  const { conversationId, isShowingDetails, hideConversationDetails } = props;
  const showContents = isShowingDetails;

  return (
    <motion.div
      style={{ height: '100%' }}
      transition={slideTransition}
      initial={{ x: '100%' }}
      animate={{ x: showContents ? 0 : '100%' }}
    >
      {!!conversationId && (
        <ConversationDetails
          conversationId={conversationId}
          toggleConversationDetails={hideConversationDetails}
        />
      )}
    </motion.div>
  );
}

type ContainerProps = {
  isShowingDetails: boolean;
  conversationId: string | undefined;
  searchTerm: string | null;
  viewConversation: (conversationId: string) => void;
  viewConversationDetails: (conversationId: string) => void;
};

function ConversationContainer(props: ContainerProps) {
  const {
    conversationId,
    searchTerm,
    isShowingDetails,
    viewConversation,
    viewConversationDetails,
  } = props;
  const isTablet = useMedia({
    isTablet: { minWidth: screenSizes.MEDIUM },
  });
  const showNewConversation = conversationId === NEW_CONVERSATION_ID;
  useApplicationScrolling(!conversationId, 'conversation');

  const handleViewConversationDetails = () => {
    if (!!conversationId) {
      viewConversationDetails(conversationId);
    }
  };
  const contents = showNewConversation ? (
    <NewConversation
      viewConversation={viewConversation}
      searchTerm={searchTerm}
    />
  ) : conversationId ? (
    <Conversation
      conversationId={conversationId}
      toggleConversationDetails={handleViewConversationDetails}
    />
  ) : null;
  const showContents = !!conversationId || isShowingDetails;

  return isTablet && showContents ? (
    contents
  ) : !isTablet ? (
    <AnimatePresence initial={false}>
      {!!contents && (
        <StyledConversationContents
          transition={slideTransition}
          initial={{ x: '100%' }}
          animate={{ x: 0 }}
          exit={{ x: '100%' }}
        >
          {contents}
        </StyledConversationContents>
      )}
    </AnimatePresence>
  ) : null;
}

function MessagingPage() {
  const conversationMatch = useMatch('messages/:conversationId/*');
  const detailsMatch = useMatch('messages/:conversationId/details');
  const { conversationId } = conversationMatch?.params || {};
  const navigate = useNavigate();
  const webSocketService = usePubNubWebSockeService();

  const { searchTerm } = getSearchFilterParams(
    window.location.search,
    'searchTerm'
  );

  const viewConversation = (conversationId: string | undefined) => {
    if (!!conversationId) {
      navigate(`/messages/${conversationId}`);
    }
  };

  const createNewConversation = () => {
    navigate(`/messages/${NEW_CONVERSATION_ID}`);
  };

  const viewConversationDetails = (conversationId: string) => {
    navigate(`/messages/${conversationId}/details`);
  };

  return (
    <ConversationFetcher
      viewConversation={viewConversation}
      webSocketService={webSocketService}
      render={({ isLoading, conversations }: ConversationRenderProps) => (
        <Fragment>
          <StyledConversationList>
            <ConversationList
              isLoading={isLoading}
              conversations={conversations}
              isConversationVisible={!!conversationId}
              selectedConversationId={conversationId}
              onConversationSelected={viewConversation}
              createNewConversation={createNewConversation}
            />
          </StyledConversationList>
          <StyledConversation $isDisplayed={!!conversationId}>
            <ConversationContainer
              conversationId={conversationId}
              searchTerm={searchTerm}
              isShowingDetails={!!detailsMatch}
              viewConversation={viewConversation}
              viewConversationDetails={viewConversationDetails}
            />
          </StyledConversation>
          <StyledConversationDetails $hiddenEnabled={!detailsMatch}>
            <ConversationDetailsContainer
              isShowingDetails={!!detailsMatch}
              conversationId={conversationId}
              hideConversationDetails={() => viewConversation(conversationId)}
            />
          </StyledConversationDetails>
          <PubNubInitializer />
        </Fragment>
      )}
    />
  );
}

export default MessagingPage;
