import { datadogRum } from '@datadog/browser-rum';
import { ContextEntry } from './constants';

export function page(path: string) {
  datadogRum.startView(path);
}

export function track(
  category: string,
  action: string,
  label: string | number,
  context?: ContextEntry
) {
  datadogRum.addAction(action, { category, label, context });
}
