import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';
import {
  ResourceDefinition,
  ResourceTypes,
} from '~/store/features/api/resources/types';

const LoopTaskListItem: ResourceDefinition = {
  attributes: {
    text: ATTRIBUTE_TYPE.STRING,
    timeFrame: ATTRIBUTE_TYPE.STRING,
    dueDate: ATTRIBUTE_TYPE.DATE_TIME,
    sortOrder: ATTRIBUTE_TYPE.NUMBER,
    complete: ATTRIBUTE_TYPE.BOOLEAN,
    deleted: ATTRIBUTE_TYPE.BOOLEAN,
    parentTaskId: ATTRIBUTE_TYPE.STRING,
    profileIdOwner: ATTRIBUTE_TYPE.STRING,
  },
  relationships: {
    taskList: {
      resourceType: ResourceTypes.LoopTaskLists,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
    assignedPerson: {
      resourceType: ResourceTypes.LoopPeople,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
  },
};
export default LoopTaskListItem;
