export enum ATTRIBUTE_TYPE {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  DATE = 'date',
  DATE_TIME = 'date_time',
  JSON_STRING = 'json_string',
  AS_IS = 'as_is',
}

export enum RELATIONSHIP_TYPE {
  ONE = 'one',
  MANY = 'many',
}

export enum InvitationStatus {
  Pending = 'PENDING',
  Sent = 'SENT',
  Accept = 'ACCEPT',
  Decline = 'DECLINE',
  Cancel = 'CANCEL',
  AutoAccept = 'AUTOACCEPT',
}
