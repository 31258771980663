import { makeVegaRequest } from '../api/network';

export interface RefData {
  id: number;
  text: string;
}

export interface TransactionType extends RefData {
  statuses: Array<RefData>;
}

interface RefDataCache<T extends RefData> {
  [type: string]: Array<T> | null;
}

interface PromiseCache<T extends RefData> {
  [type: string]: Promise<Array<T>>;
}

const refDataCache: RefDataCache<any> = {};
const promiseCache: PromiseCache<any> = {};

const refData = {
  getCached<T extends RefData = RefData>(type: string): Array<T> {
    let cachedList: Array<T> | null = refDataCache[type];

    if (!cachedList) {
      cachedList = [];
    }

    return cachedList;
  },

  async get<T extends RefData = RefData>(
    type: string,
    bustCache = false
  ): Promise<Array<T>> {
    // Request is in flight, return the cached promise
    if (!!promiseCache[type]) {
      return promiseCache[type];
    }

    if (bustCache) {
      refDataCache[type] = null;
    }

    const cachedData = refDataCache[type];
    if (!!cachedData) {
      return Promise.resolve(cachedData);
    }

    // Loading promise into cache to prevent duplicate fetches
    promiseCache[type] = this.fetch(type);

    try {
      const payload = await promiseCache[type];

      refDataCache[type] = payload;
      delete promiseCache[type];

      return payload;
    } catch (e) {
      // Clear the cache so it can be reattempted next invocation
      refDataCache[type] = null;
      throw e;
    }
  },

  async fetch<T extends RefData = RefData>(type: string): Promise<Array<T>> {
    try {
      const url = `references/${type}`;
      const res = await makeVegaRequest(url);
      const { status, payload } = res;
      if (status === 200 && !!payload) {
        return payload;
      } else {
        throw Error('Invalid Response');
      }
    } catch (error: any) {
      throw error && error.message ? error.message : error;
    }
  },
};

export default refData;
