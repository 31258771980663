import { rest } from 'msw';

export const handlers = [
  rest.get('/my/rest/v1_0/account', async (req, res, ctx) => {
    const originalResponse = await ctx.fetch(req);

    if (originalResponse.ok) {
      const originalResponseData = await originalResponse.json();

      return res(
        ctx.json({
          ...originalResponseData,
          // registrationComplete: false, // Uncomment and set to false if wanting to test the complete registration UI
        })
      );
    }
  }),

  rest.patch('/vega/v1_0/users/*', async (req, res, ctx) => {
    const json = await req.json();

    // If the complete registration flow is being used, this will return a 200 to proceed
    // otherwise, it will continue on to the backend as normal
    if (json.data.attributes.fullName) {
      return res(ctx.status(200));
    }
  }),
];
