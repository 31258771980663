import {
  ATTRIBUTE_TYPE,
  RELATIONSHIP_TYPE,
} from '~/store/features/api/resources/constants';

import { ResourceDefinition, ResourceTypes } from '../types';

const Loop: ResourceDefinition = {
  attributes: {
    name: ATTRIBUTE_TYPE.STRING,
    address: ATTRIBUTE_TYPE.STRING,
    fullAddress: ATTRIBUTE_TYPE.STRING,
    imageUrl: ATTRIBUTE_TYPE.STRING,
    imageKey: ATTRIBUTE_TYPE.STRING,
    createdDate: ATTRIBUTE_TYPE.DATE,
    closingDate: ATTRIBUTE_TYPE.DATE,
    occupancyDate: ATTRIBUTE_TYPE.DATE,
    lastUpdatedDate: ATTRIBUTE_TYPE.DATE,
    expirationDate: ATTRIBUTE_TYPE.DATE,
    reviewSubmissionDate: ATTRIBUTE_TYPE.DATE,
    agentName: ATTRIBUTE_TYPE.STRING,
    loopTemplateId: ATTRIBUTE_TYPE.NUMBER,
    taskCount: ATTRIBUTE_TYPE.NUMBER,
    transactionTypeId: ATTRIBUTE_TYPE.NUMBER,
    statusId: ATTRIBUTE_TYPE.NUMBER,
    loopStatusId: ATTRIBUTE_TYPE.NUMBER,
    archived: ATTRIBUTE_TYPE.BOOLEAN,
    transactionPrice: ATTRIBUTE_TYPE.NUMBER,
    purchasePrice: ATTRIBUTE_TYPE.NUMBER,
    tags: ATTRIBUTE_TYPE.AS_IS,
    complianceStatusIds: ATTRIBUTE_TYPE.AS_IS,
    complianceStatusNames: ATTRIBUTE_TYPE.AS_IS,
    mlsNumber: ATTRIBUTE_TYPE.STRING,
    contractAgreementDate: ATTRIBUTE_TYPE.DATE,
    notificationCount: ATTRIBUTE_TYPE.NUMBER,
    canSubmitForReview: ATTRIBUTE_TYPE.BOOLEAN,
    listedDate: ATTRIBUTE_TYPE.DATE,
    loopTemplateOption: ATTRIBUTE_TYPE.STRING,
    invitationStatus: ATTRIBUTE_TYPE.STRING,
  },

  relationships: {
    tags: {
      resourceType: ResourceTypes.Tags,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
    participants: {
      resourceType: ResourceTypes.LoopParticipants,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
    loopData: {
      resourceType: ResourceTypes.LoopData,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
    documents: {
      resourceType: ResourceTypes.Documents,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
    submitForReviewProfiles: {
      resourceType: ResourceTypes.Profiles,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
    loopParticipantInvitations: {
      resourceType: ResourceTypes.LoopParticipantInvitations,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
  },
};

export default Loop;
