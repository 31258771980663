import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';

import { ResourceDefinition, ResourceTypes } from '../types';

const Tag: ResourceDefinition = {
  attributes: {
    name: ATTRIBUTE_TYPE.STRING,
    profileId: ATTRIBUTE_TYPE.STRING,
    createdDate: ATTRIBUTE_TYPE.DATE,
  },

  relationships: {
    loops: {
      resourceType: ResourceTypes.Loops,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
    documents: {
      resourceType: ResourceTypes.Documents,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
  },
};

export default Tag;
