export function isIE() {
  const version = getInternetExplorerVersion();

  return version !== -1;
}

function getInternetExplorerVersion() {
  const userAgent = navigator.userAgent;
  let ieVersion = -1;
  let matcher;

  if (navigator.appName === 'Microsoft Internet Explorer') {
    matcher = new RegExp('MSIE ([0-9]{1,}[.0-9]{0,})');
  } else if (navigator.appName === 'Netscape') {
    matcher = new RegExp('Trident/.*rv:([0-9]{1,}[.0-9]{0,})');
  }

  if (matcher && matcher.exec(userAgent) != null) {
    ieVersion = parseFloat(RegExp.$1);
  }
  return ieVersion;
}
