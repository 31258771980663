import { colors } from '~/styles';
import { ColorPalette } from '~/styles/colors/types';

type Color = keyof ColorPalette;
type Props = {
  /** This is the text that you want to make highlighted */
  sourceText: string;
  /** This is the list of tokens that should be highlighted within the sourceText */
  tokens: Array<string> | null;
  /** Highlight color to use instead of the default yellow */
  highlightColor?: Color;
  /** Font color to use instead of the default black */
  fontColor?: Color;
};

function getHighlightedText(
  source: string,
  tokens: Array<string> | null,
  highlightColor: string,
  fontColor: string
) {
  const result = [];
  let index = 0;

  if (!tokens || !source) {
    return source;
  }

  while (index < source.length) {
    const remainingStr = source.substring(index);
    const tokenMatch = tokens.find(token =>
      remainingStr.toLowerCase().startsWith(token.toLowerCase())
    );

    if (!!tokenMatch) {
      result.push(
        <mark
          key={index}
          style={{
            padding: '2px',
            backgroundColor: highlightColor,
            color: fontColor,
          }}
        >
          {remainingStr.substring(0, tokenMatch.length)}
        </mark>
      );

      index += tokenMatch.length;
    } else {
      const previousResultIndex = result.length - 1;
      const nextCharacter = source[index];

      if (typeof result[previousResultIndex] === 'string') {
        result[previousResultIndex] += nextCharacter;
      } else {
        result.push(nextCharacter);
      }

      index++;
    }
  }

  return result;
}

export function HighlightTokens(props: Props) {
  const {
    sourceText,
    tokens,
    highlightColor = colors.highlight,
    fontColor = colors.black,
  } = props;
  const highlightedText = getHighlightedText(
    sourceText,
    tokens,
    highlightColor as string,
    fontColor as string
  );
  return <span>{highlightedText}</span>;
}

export default HighlightTokens;
