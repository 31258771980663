import {
  Fragment,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { debounce } from 'lodash-es';

import { usePrevious } from '~/hooks';

import {
  isCurrentProfileAdmin,
  selectAccountData,
} from '~/store/features/account/selectors';
import {
  makeAllResourcesSelector,
  makeAllResourcesForQuerySelector,
} from '~/store/features/api/selectors';
import { generateSearchOptions } from '~/store/features/api/utils';
import { SortDirectionType } from '~/lib/api/types';

import {
  getAppliedSort,
  AppliedSortType,
} from '~/components/loops/utils/sorting';
import { generateLoopsApiOptions } from './utils/api';

import { LoopResource } from '~/store/features/api/resources/loop/types';
import { ComplianceGroupResource } from '~/store/features/api/resources/complianceGroup/types';
import { SearchScope } from './LoopSearchContainer';
import { LoopsListPreference } from '~/store/features/api/resources/preference/types';
import { AppState } from '~/store';
import { ResourceTypes } from '~/store/features/api/resources/types';
import { fetchAllResources } from '~/store/features/api/apiSlice';

const SEARCH_PAGE_SIZE = 20;

type BaseComponentProps = {
  showRelevancy: boolean;
  searchValue: string;
  searchScope: SearchScope;
  showSearchResults: boolean;
  loopsListPreference?: LoopsListPreference | null | undefined;
  preferencesLoaded?: boolean;
  onScopeToggle: () => void;
  clearSearch: () => void;
  onSearchChange: (searchValue: string) => void;
  onSearchSubmitted: (e: KeyboardEvent<HTMLInputElement>) => void;
  toggleSearchResultsVisibility: (showSearchResults: boolean) => void;
  updateSearchQueryParameter: (searchValue: string) => void;
};

export type LoopSearchChildrenArgs = BaseComponentProps & {
  hasComplianceGroups: boolean;
  appliedSort: AppliedSortType;
  matchingLoops: Array<LoopResource>;
  isLoading: boolean;
  isAdmin: boolean;
};

type Props = BaseComponentProps & {
  searchSort: AppliedSortType | null;
  children: (renderArg: LoopSearchChildrenArgs) => ReactNode;
};

function shouldAllowSearch(
  searchValue: string,
  currentSearchScope: SearchScope,
  preferencesLoaded: boolean
) {
  const useFilteredSearch = currentSearchScope === SearchScope.Filtered;
  const allowSearch = useFilteredSearch ? preferencesLoaded : true;

  return allowSearch && !!searchValue;
}

function generateLoopSearchApiOptions(
  loopsListPreference: LoopsListPreference | null | undefined,
  searchValue: string,
  minLoopCreatedDate?: string
) {
  const baseApiOptions = generateLoopsApiOptions(
    loopsListPreference,
    null,
    1,
    SEARCH_PAGE_SIZE,
    undefined,
    minLoopCreatedDate
  );
  const searchApiOptions = generateSearchOptions(searchValue, {});
  const defaultSort = { sort: { relevancy: 'desc' as SortDirectionType } };

  return {
    ...baseApiOptions,
    ...searchApiOptions,
    ...defaultSort,
    useSearch: true,
  };
}

function generateSearchAllLoopsOptions(
  searchValue: string,
  minLoopCreatedDate?: string
) {
  return {
    ...generateSearchOptions(searchValue, {
      'filter[archivedFilter]': 'ALL',
      'filter[createdDate]': minLoopCreatedDate,
    }),
    sort: { relevancy: 'desc' as SortDirectionType },
    useSearch: true,
  };
}

function generateLoopSearchApiOptionsFromState(
  _state: AppState,
  ownProps: Props
) {
  const { searchValue, searchScope, loopsListPreference } = ownProps;
  const accountData = selectAccountData(_state);
  if (searchScope === SearchScope.All) {
    return generateSearchAllLoopsOptions(
      searchValue,
      accountData?.minLoopCreatedDate
    );
  }

  return generateLoopSearchApiOptions(
    loopsListPreference,
    searchValue,
    accountData?.minLoopCreatedDate
  );
}

const makeComplianceSelector = () =>
  makeAllResourcesSelector<ComplianceGroupResource>(
    ResourceTypes.ComplianceGroups,
    {
      relationships: [ResourceTypes.ComplianceStatuses],
    }
  );

const makeMatchingLoopsSelector = () =>
  makeAllResourcesForQuerySelector<LoopResource, Props>(
    ResourceTypes.Loops,
    generateLoopSearchApiOptionsFromState,
    {
      relationships: [ResourceTypes.Tags],
      includeMeta: true,
    }
  );

export default function ConnectedLoopSearchContainer(props: Props) {
  const {
    searchValue,
    searchScope,
    showRelevancy,
    searchSort,
    onSearchChange,
    onSearchSubmitted,
    showSearchResults,
    toggleSearchResultsVisibility,
    clearSearch,
    onScopeToggle,
    updateSearchQueryParameter,
    loopsListPreference,
    preferencesLoaded = true,
    children,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentSearchScope, setCurrentSearchScope] =
    useState<SearchScope>(searchScope);

  const selectComplianceGroups = useMemo(makeComplianceSelector, []);
  const complianceGroups = useAppSelector((state: AppState) =>
    selectComplianceGroups(state)
  );
  const selectMatchingLoops = useMemo(makeMatchingLoopsSelector, []);
  const matchingLoops = useAppSelector((state: AppState) =>
    selectMatchingLoops(state, props)
  );
  const isAdmin = useAppSelector((state: AppState) =>
    isCurrentProfileAdmin(state)
  );

  const previousSearchValue = usePrevious<string>(searchValue);
  const previousSearchScope = usePrevious<SearchScope>(searchScope);

  const dispatch = useAppDispatch();

  const { minLoopCreatedDate } = useAppSelector((state: AppState) =>
    selectAccountData(state)
  );

  const searchForAllLoops = useCallback(
    (searchValue: string) =>
      dispatch(
        fetchAllResources({
          resourceName: ResourceTypes.Loops,
          options: generateSearchAllLoopsOptions(
            searchValue,
            minLoopCreatedDate
          ),
        })
      ),
    [dispatch, minLoopCreatedDate]
  );
  const searchForFilteredLoops = useCallback(
    (
      loopsListPreference: LoopsListPreference | null | undefined,
      searchValue: string
    ) =>
      dispatch(
        fetchAllResources({
          resourceName: ResourceTypes.Loops,
          options: generateLoopSearchApiOptions(
            loopsListPreference,
            searchValue,
            minLoopCreatedDate
          ),
        })
      ),
    [dispatch, minLoopCreatedDate]
  );

  useEffect(() => {
    const searchValueChanged = searchValue !== previousSearchValue;
    const searchScopeChanged = searchScope !== previousSearchScope;
    const shouldSearchForLoops = searchValueChanged || searchScopeChanged;

    if (shouldSearchForLoops) {
      searchForLoops();
    }
  });

  useEffect(() => {
    if (searchScope !== currentSearchScope) {
      setCurrentSearchScope(searchScope);
      setIsLoading(
        shouldAllowSearch(searchValue, currentSearchScope, preferencesLoaded)
      );
    }
  }, [searchScope, currentSearchScope, searchValue, preferencesLoaded]);

  const executeSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        const useFilteredSearch = searchScope === SearchScope.Filtered;

        try {
          if (useFilteredSearch) {
            await searchForFilteredLoops(loopsListPreference, value);
          } else {
            await searchForAllLoops(value);
          }
        } finally {
          setIsLoading(false);
        }
      }, 300),
    [
      searchScope,
      loopsListPreference,
      searchForFilteredLoops,
      searchForAllLoops,
    ]
  );

  const searchForLoops = useCallback(() => {
    const allowSearch = shouldAllowSearch(
      searchValue,
      currentSearchScope,
      preferencesLoaded
    );

    if (allowSearch) {
      setIsLoading(true);
      executeSearch(searchValue);
    }
  }, [currentSearchScope, executeSearch, searchValue, preferencesLoaded]);

  const hasComplianceGroups =
    !!complianceGroups &&
    complianceGroups.length > 0 &&
    !!complianceGroups[0].complianceStatuses;
  const preferenceValue = !!loopsListPreference
    ? loopsListPreference.value
    : null;
  const appliedSort = !!searchSort
    ? searchSort
    : getAppliedSort(
        preferenceValue &&
          preferenceValue.sortId &&
          preferenceValue.sortDirection
          ? {
              sortId: preferenceValue.sortId,
              sortDirection: preferenceValue.sortDirection,
            }
          : null
      );

  if (typeof children !== 'function') {
    throw new Error('Child of `LoopSearchContainer` must be a function!');
  }

  return (
    <Fragment>
      {children({
        showRelevancy,
        searchValue,
        searchScope: currentSearchScope,
        showSearchResults,
        loopsListPreference,
        toggleSearchResultsVisibility,
        clearSearch,
        onSearchChange,
        onSearchSubmitted,
        onScopeToggle,
        hasComplianceGroups,
        appliedSort,
        matchingLoops,
        isLoading,
        isAdmin,
        updateSearchQueryParameter,
      })}
    </Fragment>
  );
}
