import { useState } from 'react';

import { AppModal } from '~/components/Modal';
import { RenameIcon } from '~/components/icons';
import OutlinedTextInput from '~/components/inputs/text/OutlinedTextInput';

import { useI18n } from '~/hooks';

type Props = {
  isOpen: boolean;
  loopName: string;
  onRequestClose: () => void;
  onSave: (loopName: string) => void;
};

function RenameLoopModal(props: Props) {
  const i18n = useI18n();

  const [loopName, setLoopName] = useState(props.loopName);

  const handleLoopNameChange = (loopName: string) => {
    setLoopName(loopName);
  };

  const handleRenameLoop = () => {
    return props.onSave(loopName);
  };

  const handleClose = () => {
    props.onRequestClose();
    setLoopName(props.loopName);
  };

  return (
    <AppModal
      isOpen={props.isOpen}
      name="rename-loop"
      title={i18n.t('loop-details:overview.renameLoopModal.title')}
      formName="rename-loop-form"
      headerIcon={RenameIcon}
      onRequestClose={handleClose}
      enableScroll={false}
      large={true}
      primaryAction={{
        labelId: 'loop-details:overview.renameLoopModal.button',
        onClick: handleRenameLoop,
        isDisabled: !loopName.trim().length,
      }}
    >
      <OutlinedTextInput
        autoFocus
        id="loop-name"
        name="loop-name"
        label={i18n.t('loop-details:overview.renameLoopModal.loopName')}
        value={loopName}
        onChange={handleLoopNameChange}
      />
    </AppModal>
  );
}

export default RenameLoopModal;
