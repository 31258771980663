import {
  ButtonHTMLAttributes,
  MouseEvent,
  KeyboardEvent,
  FocusEvent,
  ComponentType,
  Component,
  Fragment,
  ReactNode,
  createElement,
} from 'react';
import styled from 'styled-components';
import analytics, { Categories } from '~/analytics';

import AppLink, { AppLinkProps } from '~/components/AppLink';
import { FormattedMessage } from '~/components/i18n';
import { IconProps } from '~/components/icons/types';
import { noop } from 'lodash-es';
import { colors, fontSizes, fontWeights, spacing, utils } from '~/styles';

const mixins = {
  iconButtonContent: {
    height: 'auto',
  },

  buttonContentLarge: {
    height: fontSizes.headline.fontSize,
  },

  buttonIconRight: {
    order: 2,
  },

  buttonChildrenWithIcon: {
    marginLeft: spacing.smaller,
  },

  buttonChildrenWithIconRight: {
    marginLeft: 0,
    marginRight: spacing.smaller,
  },
};

type ButtonStyleProps = {
  $hasNoChildren: boolean;
  $isLarge: boolean;
  $isNonLink: boolean;
  $isFullWidth: boolean;
  $isDisabled: boolean;
};

function generateButtonStyles(props: ButtonStyleProps) {
  return {
    display: 'inline-block',
    position: 'relative',
    minWidth: props.$isLarge ? '6.25rem' : props.$hasNoChildren ? 0 : '5rem',
    maxWidth: '100%',
    width: props.$isFullWidth ? '100%' : 'auto',
    lineHeight: spacing.normal,
    touchAction: 'none',
    border: 0,
    outline: 0,
    padding: props.$isLarge
      ? `${spacing.normal} ${spacing.large}`
      : props.$hasNoChildren
      ? ICON_BUTTON_PADDING
      : `calc(${spacing.medium} / 2) ${spacing.large}`,
    fontWeight: fontWeights.semiBold,
    fontSize: props.$isLarge ? fontSizes.headline.fontSize : '',

    '&:disabled': {
      backgroundColor: colors.lightBackground,
      color: colors.hintText,
      boxShadow: 'unset',
      cursor: 'not-allowed',
    },
    '&:enabled': {
      cursor: 'pointer',
    },

    '&:focus': {
      borderColor: 'transparent',
      outlineColor: 'transparent',
    },

    '&:focus:enabled': {
      borderColor: props.$isNonLink ? 'transparent' : '',
      outlineColor: props.$isNonLink ? 'transparent' : '',
    },
  };
}

const StyledAppLink = styled(AppLink)<ButtonStyleProps>(props =>
  generateButtonStyles(props)
);

const StyledButton = styled.button<ButtonStyleProps>(props =>
  generateButtonStyles(props)
);

const StyledButtonChildren = styled.div<{
  $buttonChildrenWithIconEnabled?: boolean;
  $buttonChildrenWithIconRightEnabled?: boolean;
}>(props => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  minWidth: 0,
  ...(props.$buttonChildrenWithIconEnabled
    ? mixins.buttonChildrenWithIcon
    : {}),
  ...(props.$buttonChildrenWithIconRightEnabled
    ? mixins.buttonChildrenWithIconRight
    : {}),
}));

const StyledButtonText = styled.span<{ $truncateText?: boolean }>(props => ({
  minWidth: 0,
  ...(props.$truncateText ? { ...utils.text.truncate } : {}),
}));

const StyledDiv_1 = styled.div<{
  $buttonIconRightEnabled?: boolean;
}>(props => ({
  ...(props.$buttonIconRightEnabled ? mixins.buttonIconRight : {}),
  pointerEvents: 'none', // Prevent unpredictable SVGs behavior if clicked within a button (blur on open dropdowns etc.)
}));

const StyledButtonContent = styled.div<{
  $iconButtonContentEnabled?: boolean;
  $buttonContentLargeEnabled?: boolean;
}>(props => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: fontSizes.body.fontSize,
  ...(props.$iconButtonContentEnabled ? mixins.iconButtonContent : {}),
  ...(props.$buttonContentLargeEnabled ? mixins.buttonContentLarge : {}),
}));

export enum ButtonType {
  Submit = 'submit',
  Button = 'button',
}

export interface BaseButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  /** HTML id for the button - used in analytics to describe what button was clicked (unless `analyticsId` is given) */
  id: string;
  /**
   * Override the label sent to analytics for this button
   * (useful if button has a unique ID, for example buttons that trigger the datepicker input in a list of loop cards)
   */
  analyticsId?: string;
  /** Enlarge button (additional padding + height) */
  large?: boolean;
  /** Hyperlink to navigate to when the button is clicked */
  link?: string;
  /** Trigger a blur event after the button is clicked, so it doesn't retain focus */
  blurOnClick?: boolean;
  /** Function called when the button is clicked */
  onClick?: (e: MouseEvent<HTMLButtonElement>) => any;
  /** Position in button group: 'first', 'middle', or 'last' */
  buttonGroupPosition?: 'first' | 'middle' | 'last';
  /** Icon class to display in the button */
  icon?: ComponentType<IconProps>;
  /** Props to pass to the icon component */
  defaultIconProps?: IconProps;
  /** Position the icon to the right of the button children */
  iconRight?: boolean;
  /** Determines whether the button is disabled */
  disabled?: boolean;
  /** Show the loading state of the button */
  isLoading?: boolean;
  /** The button label text when in the loading state */
  loadingLabelId?: string;
  /** Force set the tabIndex of the button */
  tabIndex?: number;
  /** For link buttons, whether or not to open the link in a new tab */
  openNewTab?: boolean;
  /** Contents of the button */
  children?: ReactNode;
  /** Prop that defines if the button occupies the full width of the container  */
  fullWidth?: boolean;
  /** ref that is forwarded from the parent component */
  innerRef?: React.ForwardedRef<HTMLButtonElement | null | undefined>;
  /** Needs to be proxied down in case this component is extended  */
  className?: string;
  /** Truncate button text  */
  truncateText?: boolean;
}

type ButtonState = {
  isHovered: boolean;
  isFocused: boolean;
  isActive: boolean;
};

type BaseProps = {
  /**
   * Override the action sent to analytics for this button (normally 'button_click')
   * (Useful for other components that utilize BaseButton, such as MenuItem)
   */
  analyticsAction?: string;
  getIconProps?: (buttonState: ButtonState) => object;
};

type Props = BaseButtonProps & BaseProps;

type State = ButtonState & {
  isLoadingFromState: boolean;
};

export const ICON_BUTTON_PADDING = spacing.smaller;

type ButtonContentProps = Props & ButtonState;
function ButtonContents({
  large,
  icon,
  iconRight,
  defaultIconProps,
  getIconProps,
  isLoading,
  isHovered,
  isFocused,
  isActive,
  disabled,
  loadingLabelId,
  children,
  truncateText,
}: ButtonContentProps) {
  let IconElement = null;

  if (icon) {
    const disabledProps = disabled ? { type: 'hintText' } : {};
    const defaultProps = defaultIconProps || { type: 'black' };
    const stateProps = !!getIconProps
      ? getIconProps({ isHovered, isFocused, isActive })
      : {};
    const props = { ...defaultProps, ...stateProps, ...disabledProps };
    let height;

    if (!children) {
      height = fontSizes.headline.fontSize;
    } else if (large) {
      height = fontSizes.callout.fontSize;
    } else {
      height = spacing.normal;
    }

    IconElement = createElement(
      icon,
      {
        height,
        ...props,
      },
      null
    );
  }

  return (
    <StyledButtonContent
      $iconButtonContentEnabled={!children}
      $buttonContentLargeEnabled={large}
    >
      {isLoading ? (
        <div>
          <FormattedMessage id={loadingLabelId || 'states.loading'} />
        </div>
      ) : (
        <Fragment>
          {icon && (
            <StyledDiv_1 $buttonIconRightEnabled={iconRight}>
              {IconElement}
            </StyledDiv_1>
          )}
          {children && (
            <StyledButtonChildren
              $buttonChildrenWithIconEnabled={!!icon}
              $buttonChildrenWithIconRightEnabled={iconRight}
            >
              <StyledButtonText $truncateText={truncateText}>
                {children}
              </StyledButtonText>
            </StyledButtonChildren>
          )}
        </Fragment>
      )}
    </StyledButtonContent>
  );
}

export class BaseButton extends Component<Props, State> {
  timeout: number | null = null;

  static defaultProps = {
    large: false,
    blurOnClick: true,
    iconRight: false,
    additionalStyles: null,
    additionalDisabledStyles: null,
    onClick: noop,
    openNewTab: false,
  };

  state = {
    isHovered: false,
    isFocused: false,
    isActive: false,
    isLoadingFromState: false,
  };

  componentWillUnmount() {
    this.removeEventListeners();

    if (this.timeout) {
      clearTimeout(this.timeout);

      this.timeout = null;
    }
  }

  callButtonPropsHandler = (method: keyof BaseButtonProps, ...args: any) => {
    if (!!this.props[method]) {
      this.props[method](...args);
    }
  };

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  handleFocus = (
    e: FocusEvent<HTMLButtonElement> | FocusEvent<HTMLAnchorElement>
  ) => {
    this.setState({ isFocused: true });
    this.callButtonPropsHandler('onFocus', e);
  };

  handleBlur = (
    e: FocusEvent<HTMLButtonElement> | FocusEvent<HTMLAnchorElement>
  ) => {
    this.setState({ isFocused: false, isActive: false });
    this.callButtonPropsHandler('onBlur', e);
  };

  handleActivate = () => {
    this.setState({ isActive: true });
    window.addEventListener('mouseup', this.handleDeactivate);
    window.addEventListener('touchend', this.handleDeactivate);
  };

  handleDeactivate = () => {
    this.removeEventListeners();
    this.setState({ isActive: false });
  };

  removeEventListeners = () => {
    window.removeEventListener('mouseup', this.handleDeactivate);
    window.removeEventListener('touchend', this.handleDeactivate);
  };

  handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    const { id, analyticsId, analyticsAction, blurOnClick } = this.props;

    if (!!this.props.onClick && !this.state.isLoadingFromState) {
      const onClick = this.props.onClick;

      if (blurOnClick && e.currentTarget && e.currentTarget.blur) {
        e.currentTarget.blur();
      } else {
        this.handleDeactivate();
      }

      analytics.track(
        Categories.INTERACTION,
        analyticsAction ?? 'button_click',
        analyticsId ?? id
      );

      const result = onClick(e);

      if (!!result && !!result.then) {
        this.timeout = window.setTimeout(() => {
          this.setState({
            isLoadingFromState: true,
          });
        }, 200);

        try {
          await result;
        } catch (e) {
          console.error(e);
        } finally {
          if (this.timeout) {
            clearTimeout(this.timeout);

            this.timeout = null;
            this.setState({
              isLoadingFromState: false,
            });
          }
        }
      }
    }
  };

  render() {
    const {
      id,
      name,
      large,
      disabled,
      tabIndex,
      children,
      fullWidth,
      type = 'button',
      innerRef = null,
      className,
    } = this.props;
    const { isHovered, isFocused, isActive, isLoadingFromState } = this.state;
    const link = this.props.link ? this.props.link : null;
    const openNewTab = this.props.openNewTab || false;
    const showLoading = this.props.isLoading || isLoadingFromState;
    const isButtonDisabled = showLoading || disabled;
    const sharedButtonProps = {
      id,
      name,
      className,
      disabled: isButtonDisabled,
      tabIndex: tabIndex !== undefined ? tabIndex : disabled ? -1 : 0,
      onMouseEnter: this.handleMouseEnter,
      onMouseLeave: this.handleMouseLeave,
      onFocus: this.handleFocus,
      onBlur: this.handleBlur,
      onMouseDown: this.handleActivate,
      onTouchStart: this.handleActivate,
      onKeyDown: (
        e: KeyboardEvent<HTMLButtonElement> | KeyboardEvent<HTMLAnchorElement>
      ) => {
        if (e.key === ' ') {
          // Toggle active state when activating button with spacebar
          this.handleActivate();

          requestAnimationFrame(() => {
            this.handleDeactivate();
          });
        }

        this.callButtonPropsHandler('onKeyDown', e);
      },
    };

    const styleProps = {
      $hasNoChildren: !children,
      $isLarge: !!large,
      $isNonLink: !link,
      $isFullWidth: !!fullWidth,
      $isDisabled: !!isButtonDisabled,
    };

    // Proxy down `aria-` and `data-` props
    Object.keys(this.props).forEach(key => {
      if (key.indexOf('aria-') !== -1 || key.indexOf('data-') !== -1) {
        // @ts-expect-error - Need to eventually refactor button with something like react-aria
        sharedButtonProps[key] = this.props[key];
      }
    });

    const contents = (
      <ButtonContents
        {...this.props}
        isHovered={isHovered}
        isFocused={isFocused}
        isActive={isActive}
        isLoading={showLoading}
      />
    );

    if (!!link) {
      const buttonProps = {
        ...sharedButtonProps,
        to: link,
        ref: innerRef,
      } as AppLinkProps;

      if (openNewTab) {
        buttonProps.rel = 'noopener noreferrer';
        buttonProps.target = '_blank';
      }

      return (
        <StyledAppLink {...buttonProps} {...styleProps}>
          {contents}
        </StyledAppLink>
      );
    } else {
      const buttonProps = {
        ...sharedButtonProps,
        type,
        onClick: this.handleClick,
        ref: innerRef,
      } as BaseButtonProps;

      return (
        <StyledButton {...buttonProps} {...styleProps}>
          {contents}
        </StyledButton>
      );
    }
  }
}

export default BaseButton;
