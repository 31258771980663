import qs from 'qs';
import { pick, isEqual } from 'lodash-es';
import { Location, NavigateFunction } from 'react-router-dom';

function processArraySearchResultValue(arrayValues: Array<any>) {
  return arrayValues.reduce((items, currentItem) => {
    items.push(processSearchResultValue(currentItem));

    return items;
  }, []);
}

function processSearchResultValue(value: any) {
  if (Array.isArray(value)) {
    return processArraySearchResultValue(value);
  } else if (~value.indexOf(',')) {
    const arrayValues = value.split(',');

    return processArraySearchResultValue(arrayValues);
  } else if (value.toLowerCase() === 'true') {
    return true;
  } else if (value.toLowerCase() === 'false') {
    return false;
  } else if (value !== '' && !isNaN(Number(value))) {
    return parseFloat(value);
  } else {
    return value;
  }
}

function processSearchResult(searchResult: { [key: string]: any }): any {
  const newResult: { [key: string]: any } = {};

  for (const key in searchResult) {
    const value = searchResult[key];

    newResult[key] = processSearchResultValue(value);
  }

  return newResult;
}

function parseSearch(search: string | null | undefined): {
  [key: string]: any;
} {
  let result = {};
  if (search && search.startsWith('?')) {
    search = search.slice(1);
  }

  if (search && search.length >= 0) {
    const parsedSearch = qs.parse(search);
    result = processSearchResult(parsedSearch);
  }

  return result;
}

export function getSearchFilterParams(
  search: string | null | undefined,
  paramsToGet?: Array<string> | string
) {
  const parsedSearch = parseSearch(search);

  return paramsToGet ? pick(parsedSearch, paramsToGet) : parsedSearch;
}

interface PrimitiveQueryParams {
  [key: string]:
    | string
    | number
    | boolean
    | Array<string | number | boolean>
    | null
    | undefined;
}

export function generateSearchString<T extends PrimitiveQueryParams>(
  search: string,
  paramsToUpdate: T
) {
  const existingSearch = parseSearch(search);

  for (const key in paramsToUpdate) {
    existingSearch[key] = paramsToUpdate[key];
  }

  for (const key in existingSearch) {
    const value = existingSearch[key];

    if (Array.isArray(value)) {
      existingSearch[key] = value.join(',');
    }
  }

  return qs.stringify(existingSearch);
}

export function updateSearchFilterParams<T extends PrimitiveQueryParams>(
  paramsToUpdate: T,
  location: Location,
  navigate: NavigateFunction
) {
  const { pathname, search } = location;
  const updatedSearch = generateSearchString(search, paramsToUpdate);

  navigate(`${pathname}?${updatedSearch}`, { replace: true });
}

export function getStringifiedSearchFilterParams(
  search: string | null | undefined
) {
  return JSON.stringify(getSearchFilterParams(search));
}

export function hasSearchFilterParams(
  search: string | null | undefined,
  paramsToGet?: Array<string> | string
) {
  const searchFilterParams = getSearchFilterParams(search, paramsToGet);

  return Object.keys(searchFilterParams).length > 0;
}

export function convertParamValueToList<T>(value: T): Array<T> {
  return value ? (Array.isArray(value) ? value : [value]) : [];
}

export function didParamsChange(
  prevSearch: string | null | undefined,
  nextSearch: string | null | undefined,
  paramsToCheck: Array<string>
) {
  const prevParams = getSearchFilterParams(prevSearch, paramsToCheck);
  const nextParams = getSearchFilterParams(nextSearch, paramsToCheck);
  const areEqual = isEqual(prevParams, nextParams);

  return !areEqual;
}

export function clearQueryParams(navigate: NavigateFunction) {
  const { pathname } = window.location;

  navigate(pathname);
}
