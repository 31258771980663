export enum TransactionType {
  Other = 16,
  ListingForSale = 18,
  ListingForLease = 19,
  Purchase = 20,
  Lease = 21,
  RealEstateOther = 22,
}

export enum LoopStatus {
  PrivateListing = 1,
  ActiveListing = 2,
  UnderContract = 3,
  Sold = 4,
  Leased = 5,
  Archived = 6,
  PreListing = 7,
  PreOffer = 8,
  New = 9,
  InProgress = 10,
  Done = 11,
}

export enum LoopInvitationStatus {
  Unaccepted = 'UNACCEPTED',
  Accepted = 'ACCEPTED',
  All = 'ALL',
}

export enum ArchivedFilter {
  All = 'ALL',
  OnlyArchived = 'ONLY_ARCHIVED',
  HideArchived = 'HIDE_ARCHIVED',
}

export enum LoopTemplateOption {
  Required = 'REQUIRED',
  Available = 'AVAILABLE',
  None = 'NONE',
}
