import i18next from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

import config from '~/config';
import { formatDate, DATE_FORMATS } from '~/components/i18n/utils';
import { DateOptions } from '~/components/i18n/types';

const {
  ui: { basename },
} = config;

type DateFormat = keyof DateOptions;

enum Locales {
  English = 'en',
  Spanish = 'es',
}

i18next
  .use(LngDetector)
  .use(XHR)
  .init({
    fallbackLng: 'en',
    whitelist: ['en', 'es'],
    nonExplicitWhitelist: true,
    ns: ['common', 'loops'],
    defaultNS: 'common',
    fallbackNS: 'common',
    interpolation: {
      escapeValue: false,
      format: (value, format) => {
        const dateFormat: DateFormat | null =
          Object.values(DATE_FORMATS).indexOf(format as DateFormat) !== -1
            ? (format as DateFormat)
            : null;

        if (value instanceof Date && !!dateFormat) {
          return formatDate(value, dateFormat, i18next);
        }

        return value;
      },
    },
    detection: {
      caches: [],
    },
    backend: {
      allowMultiLoading: false,
      loadPath: ([lng]: [string], [ns]: [string]) => {
        const translationHashes = window.__TRANSLATION_FILENAME_LOOKUP__;
        let fileName = `${ns}.json`;
        lng = parseLanguage(lng);

        if (!!translationHashes) {
          const localeHashes = translationHashes[lng];

          if (!!localeHashes) {
            const hash = localeHashes[`${ns}.json`];

            if (!!hash) {
              fileName = `${ns}.${hash}.json`;
            }
          }
        }

        return `${basename}locales/${lng}/${fileName}`;
      },
    },
  });

function parseLanguage(lng: string) {
  if (lng.toLowerCase().startsWith('es')) {
    return Locales.Spanish;
  } else {
    return Locales.English;
  }
}

export default i18next;
