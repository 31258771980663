import { createElement } from 'react';
import { useAppSelector } from '~/store/hooks';
import { FormattedMessage } from '~/components/i18n';
import styled from 'styled-components';

import AppLink from '~/components/AppLink';
import { useBrand } from '~/hooks';
import { AppState } from '~/store';
import { selectNavigationAccess } from '~/store/features/account/selectors';
import { createSafeInsetPadding } from '~/styles/utilities';
import { boxShadows, colors, fontSizes, spacing, zIndex } from '~/styles';

import { getNavItems, NavItem } from './index';
import { useLocation, Location } from 'react-router-dom';
import { NavigationAccessType } from '~/store/features/account/types';

export const BOTTOM_NAV_HEIGHT = '3rem';
const NavContainer = styled.div({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  minHeight: BOTTOM_NAV_HEIGHT,
  ...createSafeInsetPadding(0, { bottom: true }),
  color: colors.secondaryText,
  backgroundColor: colors.white,
  boxShadow: boxShadows.top,
  zIndex: zIndex.bottomNav,

  '@media print': {
    display: 'none',
  },
});
const NavList = styled.ul({
  display: 'flex',
  margin: 0,
  padding: 0,
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
});
const NavItemLink = styled(AppLink)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
});
const NavItemIcon = styled.div({
  height: '1.5rem',
});
const NavItemText = styled.div<{ $isActive: boolean; $brandColor: string }>(
  props => ({
    ...fontSizes.caption,
    lineHeight: spacing.normal,
    color: props.$isActive ? props.$brandColor : colors.secondaryText,
  })
);
const NavListItem = styled.li<{ $itemWidth: string }>(props => ({
  flex: `1 0 ${props.$itemWidth}`,
  margin: 0,
  height: '100%',
  width: props.$itemWidth,
  padding: `${spacing.smallest} ${spacing.small}`,
}));

type BottomNavItemProps = {
  location: Location;
  item: NavItem;
};

function getBottomNavItems(navigationAccess: NavigationAccessType) {
  const navItems = getNavItems(navigationAccess);

  return navItems.filter(item => {
    return item.labelId.indexOf('reporting') === -1;
  });
}

const BottomNavItem = (props: BottomNavItemProps) => {
  const { location, item } = props;
  const isActive = location.pathname.indexOf(item.link) !== -1;
  const IconElement = createElement(
    item.icon,
    { type: isActive ? 'brand' : 'grey', height: '100%' },
    null
  );
  const brandedColor = useBrand();
  const navigationAccess = useAppSelector((state: AppState) =>
    selectNavigationAccess(state)
  );
  const navItems = getBottomNavItems(navigationAccess);
  const itemWidth = `${100 / navItems.length}%`;

  return (
    <NavListItem $itemWidth={itemWidth}>
      <NavItemLink to={item.link}>
        <NavItemIcon>{IconElement}</NavItemIcon>
        <NavItemText $isActive={isActive} $brandColor={brandedColor}>
          <FormattedMessage id={item.labelId} />
        </NavItemText>
      </NavItemLink>
    </NavListItem>
  );
};

const BottomNav = () => {
  const location = useLocation();
  const navigationAccess = useAppSelector((state: AppState) =>
    selectNavigationAccess(state)
  );
  const navItems = getBottomNavItems(navigationAccess);

  return (
    <NavContainer>
      <NavList>
        {navItems.map(item => (
          <BottomNavItem key={item.link} item={item} location={location} />
        ))}
      </NavList>
    </NavContainer>
  );
};

export default BottomNav;
