import styled, { keyframes } from 'styled-components';

import { colors } from '~/styles';

export const SIMPLE_LOADING_INDICATOR_HEIGHT = '4rem';

const bounceAnimation = keyframes`
  0%, 100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
`;

const StyledCircle = styled.div<{ $enableDelay?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.primaryAction};
  border-radius: 50%;
  opacity: 0.6;
  animation: ${bounceAnimation} 2s infinite ease-in-out;
  animation-delay: ${props => (props.$enableDelay ? '-1s' : '')};
`;

const StyledSpinner = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: SIMPLE_LOADING_INDICATOR_HEIGHT,
  height: SIMPLE_LOADING_INDICATOR_HEIGHT,
  transform: 'translate(-50%, -50%)',
});

/**
 * Currently used for indicating uploading / copying documents, creating a loop.
 */
const SimpleLoadingIndicator = () => {
  return (
    <StyledSpinner>
      <StyledCircle />
      <StyledCircle $enableDelay />
    </StyledSpinner>
  );
};

export default SimpleLoadingIndicator;
