import { noop } from 'lodash-es';
import { AnalyticsContext, ContextEntry, Categories } from './constants';
import {
  page as pageWithEventLog,
  track as trackWithEventLog,
} from './event-log';
import { track as trackWithPendo } from './pendo';
import { page as pageWithDatadog, track as trackWithDatadog } from './datadog';

interface OptionalProps {
  [prop: string]: string | number | boolean;
}

interface Analytics {
  addContext: (contextType: AnalyticsContext, contextName: string) => void;
  removeContext: (contextType: AnalyticsContext, contextName: string) => void;
  identify: (userId: string, traits?: OptionalProps) => void;
  track: (category: string, action: string, label: string | number) => void;
  page: (path: string) => void;
}

const IS_IN_STORYBOOK = Object.keys(import.meta.env).some(
  key => key.indexOf('STORYBOOK') !== -1
);
const IS_RUNNING_JEST =
  typeof process !== 'undefined' && process.env.JEST_WORKER_ID !== undefined;
const DISABLE_ANALYTICS = IS_IN_STORYBOOK || IS_RUNNING_JEST;
const CONTEXT_STACK: Array<ContextEntry> = [];
const PAGE_HANDLERS = [pageWithDatadog, pageWithEventLog];
const TRACK_HANDLERS = [trackWithEventLog, trackWithPendo, trackWithDatadog];

function createContextEntry(
  contextType: AnalyticsContext,
  contextName: string
) {
  return {
    type: contextType,
    name: contextName,
    key: `${contextType}:${contextName}`,
  };
}

function addToContext(contextType: AnalyticsContext, contextName: string) {
  CONTEXT_STACK.push(createContextEntry(contextType, contextName));
}

function removeFromContext(contextType: AnalyticsContext, contextName: string) {
  const context = createContextEntry(contextType, contextName);
  let contextIndex = CONTEXT_STACK.length - 1;
  const mostRecentContext = CONTEXT_STACK[contextIndex];

  if (mostRecentContext.key !== context.key) {
    console.warn(
      'Attempting to remove analytics context that does not match the most recent context!'
    );
    contextIndex = CONTEXT_STACK.findIndex(c => c.key === context.key);

    if (contextIndex === -1) {
      console.error(
        `Unable to find analytics context "${context}" in the current context stack!`
      );
      return;
    }
  }

  CONTEXT_STACK.splice(contextIndex, 1);
}

function resetContext() {
  CONTEXT_STACK.length = 0;
}

function getLatestContext(): ContextEntry | undefined {
  const mostRecentContext = CONTEXT_STACK[CONTEXT_STACK.length - 1];

  return mostRecentContext || undefined;
}

const disabledAnalytics: Analytics = {
  addContext: noop,
  removeContext: noop,
  identify: noop,
  track: noop,
  page: noop,
};

const analytics: Analytics = {
  addContext: (contextType, contextName) => {
    addToContext(contextType, contextName);
  },
  removeContext: (contextType, contextName) => {
    removeFromContext(contextType, contextName);
  },
  identify: (userId, traits) => {
    console.log(`Analytics :: Identifying user ${userId}`, traits);
  },
  track: (category, action, label) => {
    const context = getLatestContext();

    console.log(
      `Analytics :: Tracking event ${
        !!context ? `:: Context - ${context.key}  ` : ''
      }:: ${category} - ${action} - ${label}`
    );

    TRACK_HANDLERS.forEach(handler =>
      handler(category, action, label, context)
    );
  },
  page: path => {
    console.log(`Analytics :: Tracking page view :: ${path}`);

    resetContext();
    addToContext(AnalyticsContext.Page, path);

    PAGE_HANDLERS.forEach(handler => handler(path));
  },
};

const appAnalytics: Analytics = DISABLE_ANALYTICS
  ? disabledAnalytics
  : analytics;

export { AnalyticsContext, Categories };
export default appAnalytics;
