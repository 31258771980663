import { connect } from 'react-redux';
import { FormattedMessage } from '~/components/i18n';

import styled from 'styled-components';
import { colors, spacing, fontWeights } from '~/styles';

import { SecondaryButton } from '~/components/buttons';
import Container from '~/components/layout/Container';

import APP_CONFIG from '~/config';

import { selectAccountData } from '~/store/features/account/selectors';
import { AppState } from '~/store';
import { AccountInfo } from '~/store/features/account/types';

const StyledAssumeControlBannerMainText = styled.span({
  color: colors.primaryBackgroundText,
  fontWeight: fontWeights.bold,
  marginLeft: spacing.large,
});

const StyledContainer = styled(Container)({
  display: 'flex',
  alignItems: 'center',
  paddingTop: spacing.small,
  paddingBottom: spacing.small,
});

const StyledAssumeControlBannerWrapper = styled.div({
  backgroundColor: colors.primaryButtonFocus,
});

const {
  ui: { phoenixUrl },
} = APP_CONFIG;
const PHOENIX_ADMIN_URL = `${phoenixUrl}/my/admin`;

type ReduxProps = {
  accountData: AccountInfo;
};

type Props = ReduxProps;

function AssumeControlBanner(props: Props) {
  const { accountData } = props;
  const isAssumingControl = accountData.isAssumingControl;

  return isAssumingControl ? (
    <StyledAssumeControlBannerWrapper>
      <StyledContainer>
        <SecondaryButton id="back-to-admin-button" link={PHOENIX_ADMIN_URL}>
          <FormattedMessage id="assumeControlBanner.backToAdmin" />
        </SecondaryButton>
        <StyledAssumeControlBannerMainText>
          <FormattedMessage
            id="assumeControlBanner.actingAs"
            values={{
              firstName: accountData.firstName,
              lastName: accountData.lastName,
            }}
          />
        </StyledAssumeControlBannerMainText>
      </StyledContainer>
    </StyledAssumeControlBannerWrapper>
  ) : null;
}

const mapStateToProps = (state: AppState): ReduxProps => ({
  accountData: selectAccountData(state),
});

export default connect<
  ReduxProps,
  Record<string, unknown>,
  Record<string, unknown>,
  AppState
>(mapStateToProps)(AssumeControlBanner);
