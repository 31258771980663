import { ReactNode, useRef, useState } from 'react';
import { DragDropContext, ContextValue } from './context';
import { getDataTransferFiles } from './utils';

interface Props {
  /** Contents of the drop zone */
  children: ReactNode;
}

/**
 * The `FileDropZone` component is meant to be rendered at the root of
 * the application. By doing so, we can listen to all file drag events
 * that come into the window, regardless of location.
 */
export default function FileDropZone({ children }: Props) {
  const enterCounter = useRef(0);
  const [context, setContext] = useState<ContextValue>({
    isActive: false,
    files: [],
  });

  return (
    <DragDropContext.Provider value={context}>
      <div
        onDrop={e => {
          e.preventDefault();

          enterCounter.current = 0;
          setContext({ isActive: false, files: [] });
        }}
        onDragOver={e => {
          e.preventDefault();
          e.stopPropagation();

          return false;
        }}
        onDragEnter={e => {
          e.preventDefault();
          e.stopPropagation();

          const { currentTarget, dataTransfer } = e;
          const files = getDataTransferFiles(currentTarget, dataTransfer);

          ++enterCounter.current;
          setContext({
            isActive: true,
            files,
          });
        }}
        onDragLeave={e => {
          e.preventDefault();

          if (--enterCounter.current === 0) {
            setContext({ isActive: false, files: [] });
          }
        }}
      >
        {children}
      </div>
    </DragDropContext.Provider>
  );
}
