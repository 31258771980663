import { ReactNode, useMemo } from 'react';

import { RadioProvider } from './context';

type Props = {
  /** HTML `name` attribute for all radio button inputs */
  name: string;
  /** Currently selected value for the radio button group */
  selectedValue: string | null;
  /** Called with the newly selected value when a different radio button is selected */
  onChange: (value: string) => void;
  /** Contents of the radio button group. Should contain `RadioButton` components */
  children: ReactNode;
};

function RadioButtonGroup({ children, onChange, name, selectedValue }: Props) {
  const contextValue = useMemo(
    () => ({
      name,
      selectedValue,
      onChange,
    }),
    [name, selectedValue, onChange]
  );
  return <RadioProvider value={contextValue}>{children}</RadioProvider>;
}

export default RadioButtonGroup;
