import { useState } from 'react';
import { AppModal } from '~/components/Modal';
import { DeleteIcon, PhotoIcon, UploadIcon } from '~/components/icons';

import { useI18n } from '~/hooks';
import { FormattedMessage } from '~/components/i18n';
import FileInput from '~/components/inputs/file/FileInput';
import { LinkButton } from '~/components/buttons';
import { LinkButtonType } from '~/components/buttons/LinkButton';
import styled from 'styled-components';
import { colors, spacing, utils } from '~/styles';
import { LoopResource } from '~/store/features/api/resources/loop/types';
import { ResourceTypes } from '~/store/features/api/resources/types';
import { uploadLoopPhoto } from '~/services/upload-service';
import { useSimpleNotification } from '~/hooks/useSimpleNotification';
import breakpoints from '~/styles/breakpoints';
import { updateResource } from '~/store/features/api/apiSlice';
import { useUploadLoopPhoto } from '~/hooks/useUploadLoopPhoto';
import { useAppDispatch } from '~/store/hooks';

const StyledPhotoMessage = styled.div({
  textDecoration: 'underline',
});

const StyledPhotoIconContainer = styled.div({
  width: '3rem',
  margin: '0 auto 1rem',
});

const StyledPhotoMessageContainer = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const StyledPhoto = styled.div<{
  $photoImageEnabled: boolean;
  $image: string | null;
}>(props => ({
  width: '100%',
  height: '100%',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  transition: 'opacity 0.5s ease',
  borderRadius: utils.baseBorderRadius,
  position: 'relative',
  ...(props.$photoImageEnabled
    ? {
        backgroundImage: !!props.$image ? `url(${props.$image})` : '',
      }
    : {}),
}));

const StyledPhotoContainer = styled.div<{
  $photoContainerWithImageEnabled: boolean;
}>(props => ({
  position: 'relative',
  backgroundColor: props.$photoContainerWithImageEnabled
    ? colors.white
    : colors.lightBackground,
  width: '100%',
  height: '100%',
}));

const StyledWrapper = styled.div({
  position: 'relative',
  top: '50%',
  transform: 'translateY(-50%)',
  marginBottom: spacing.max,
  height: '14rem',
  [breakpoints.MEDIUM]: {
    transform: 'none',
    top: '0',
  },
});

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  loop: LoopResource;
  updateLoop: (id: string, loop: Partial<LoopResource>) => void;
};

function UploadLoopPhotoModal(props: Props) {
  const { onRequestClose, isOpen, loop, updateLoop } = props;
  const i18n = useI18n();
  const { showSimpleSuccess, showSimpleError } = useSimpleNotification();
  const dispatch = useAppDispatch();

  const [loopPhoto, setLoopPhoto] = useState<string | null>(loop.imageUrl);
  const [newLoopPhotoFile, setNewLoopPhotoFile] = useState<File | null>(null);
  const [invalidPhoto, setInvalidPhoto] = useState(false);
  const [hasExistingPhoto, setHasExistingPhoto] = useState(!!loop.imageUrl);
  const loopPhotoPresent = !!loopPhoto;

  const onAddPhotoFailure = () => {
    setInvalidPhoto(true);
    showSimpleError(
      `photo-too-small-error`,
      i18n.t('create-loop:photoStep.imageTooSmall')
    );
  };

  const onAddPhotoSuccess = (
    photo: string | null,
    loopPhotoFile: File | null
  ) => {
    setLoopPhoto(photo);
    setNewLoopPhotoFile(loopPhotoFile);
  };

  const { handleFileUpload, handleFileInputRef, openFileDialog } =
    useUploadLoopPhoto({
      addPhotoSuccess: onAddPhotoSuccess,
      addPhotoFailure: onAddPhotoFailure,
    });

  const handleClose = () => {
    onRequestClose();
    setNewLoopPhotoFile(null);
    setInvalidPhoto(false);
  };

  const onSavePhoto = async () => {
    // determine if we uploaded a new photo
    if (newLoopPhotoFile) {
      try {
        const result = await uploadLoopPhoto(newLoopPhotoFile);
        await updateLoop(loop.id, { imageKey: result.data });

        showSimpleSuccess(
          `update-photo-success`,
          i18n.t(
            hasExistingPhoto
              ? 'loop-details:overview.uploadLoopPhotoModal.photoUpdateSuccess'
              : 'loop-details:overview.uploadLoopPhotoModal.photoUploadSuccess'
          )
        );
      } catch (e) {
        showSimpleError(
          `update-photo-failure`,
          i18n.t('loop-details:overview.uploadLoopPhotoModal.photoUpdateFailed')
        );
      }
    }
    handleClose();
    setHasExistingPhoto(true);
  };

  const onRemovePhoto = async () => {
    try {
      await updateLoop(loop.id, { imageKey: '' });
      showSimpleSuccess(
        'remove-photo-success',
        i18n.t('loop-details:overview.uploadLoopPhotoModal.photoDeleteSuccess')
      );
      dispatch(
        updateResource.fulfilled(
          [
            {
              type: ResourceTypes.Loops,
              id: loop.id,
              attributes: { imageUrl: null },
            },
          ],
          '',
          {
            resourceName: ResourceTypes.Loops,
          }
        )
      );
    } catch (e) {
      showSimpleError(
        `update-photo-failure`,
        i18n.t('loop-details:overview.uploadLoopPhotoModal.photoUpdateFailed')
      );
    }
    handleClose();
    setLoopPhoto(null);
    setHasExistingPhoto(false);
  };

  return (
    <AppModal
      isOpen={isOpen}
      large
      name="loop-photo-modal"
      title={i18n.t(
        hasExistingPhoto
          ? 'loop-details:overview.uploadLoopPhotoModal.updateLoopPhoto'
          : 'loop-details:overview.actions.addLoopPhoto'
      )}
      formName="update-loop-photo"
      headerIcon={PhotoIcon}
      onRequestClose={handleClose}
      enableScroll={false}
      primaryAction={{
        labelId: 'actions.save',
        onClick: onSavePhoto,
        isDisabled: invalidPhoto,
      }}
      secondaryAction={
        loopPhotoPresent
          ? {
              labelId:
                'loop-details:overview.uploadLoopPhotoModal.selectDifferentPhoto',
              onClick: openFileDialog,
            }
          : undefined
      }
    >
      <StyledWrapper>
        <FileInput
          name="loop-photo-upload"
          hidden={loopPhotoPresent}
          onChange={handleFileUpload}
          sendFileInputRef={handleFileInputRef}
          uploadProps={{
            accept: 'image/png, image/jpeg',
            multiple: false,
          }}
        >
          <StyledPhotoContainer
            $photoContainerWithImageEnabled={loopPhotoPresent}
          >
            <StyledPhoto
              $photoImageEnabled={loopPhotoPresent}
              $image={loopPhoto}
            />
            {!loopPhotoPresent && (
              <StyledPhotoMessageContainer>
                <StyledPhotoIconContainer>
                  <UploadIcon width="3rem" />
                </StyledPhotoIconContainer>
                <StyledPhotoMessage>
                  <FormattedMessage id="loop-details:overview.uploadLoopPhotoModal.uploadPhoto" />
                </StyledPhotoMessage>
              </StyledPhotoMessageContainer>
            )}
          </StyledPhotoContainer>
        </FileInput>
        {hasExistingPhoto && (
          <LinkButton
            removeSidePadding
            id="delete-photo"
            buttonStyle={LinkButtonType.Warning}
            onClick={onRemovePhoto}
            icon={DeleteIcon}
          >
            <FormattedMessage id="loop-details:overview.uploadLoopPhotoModal.deletePhoto" />
          </LinkButton>
        )}
      </StyledWrapper>
    </AppModal>
  );
}

export default UploadLoopPhotoModal;
