import { useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '~/store/hooks';

import { areResourcesLoading } from '~/store/features/api/selectors';
import { makeProfilesSelector } from '~/store/features/api/selectors/profile';

import { AppState } from '~/store';
import { ResourceTypes } from '~/store/features/api/resources/types';
import { ProfileResource } from '~/store/features/api/resources/profile/types';
import { ApiResource } from '~/lib/api/types';
import { fetchAllResources } from '~/store/features/api/apiSlice';

let hasFetchedProfiles = false;

interface UseProfileOptions {
  autoFetch?: boolean;
  showInactive?: boolean;
}
export function useProfiles({
  autoFetch = false,
  showInactive = false,
}: UseProfileOptions = {}): [
  boolean,
  Array<ProfileResource>,
  () => Promise<ApiResource[]>
] {
  const dispatch = useAppDispatch();
  const profileFetchOptions = useMemo(() => {
    return showInactive
      ? {}
      : {
          filters: {
            active: true,
          },
        };
  }, [showInactive]);
  const fetchAllProfiles = useCallback(() => {
    return dispatch(
      fetchAllResources({
        resourceName: ResourceTypes.Profiles,
        options: profileFetchOptions,
        formatDataCallback: formatProfileData,
      })
    );
  }, [dispatch, profileFetchOptions]);
  const isFetching = useAppSelector((state: AppState) =>
    areResourcesLoading(state, ResourceTypes.Profiles, profileFetchOptions)
  );
  const profiles = useProfilesSelector(showInactive);

  useEffect(() => {
    if (!hasFetchedProfiles && !isFetching && autoFetch) {
      fetchAllProfiles();
      hasFetchedProfiles = true;
    }
  }, [profileFetchOptions, isFetching, autoFetch, fetchAllProfiles]);

  return [isFetching, profiles, fetchAllProfiles];
}

// Since there are other endpoint that also returns profiles as part of the
// response and they are stores in redux, we need to format the data to set the
// isProfileAvailable flag to identify the profiles that can be used by the user
function formatProfileData(data: ApiResource[]): ApiResource[] {
  return data.map((resource: ApiResource) => {
    if (resource.type !== ResourceTypes.Profiles) {
      return resource;
    }
    resource.attributes = {
      ...resource.attributes,
      isProfileAvailable: true,
    };
    return resource;
  });
}

export function useProfilesSelector(
  showInactive = false
): Array<ProfileResource> {
  // Memoized selector via Reselect, since this depends on
  // local props and could be used in multiple different components
  const selectProfiles = useMemo(makeProfilesSelector, []);
  const profiles = useAppSelector((state: AppState) => {
    return selectProfiles(state, showInactive);
  });

  return profiles;
}
