import { ComponentType, createElement, Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
});

type Props = {
  link: string;
  icon: ComponentType<any>;
  defaultIconProps?: any;
  /** Needs to be proxied down in case this component is extended  */
  className?: string;
};

const DEFAULT_ICON_PROPS = { type: 'grey', height: '100%' };
function IconLink({ link, icon, defaultIconProps, className }: Props) {
  const iconProps = {
    ...DEFAULT_ICON_PROPS,
    ...defaultIconProps,
  };
  const IconElement = createElement(icon, iconProps, null);

  return (
    <StyledLink to={link} className={className}>
      <Fragment>{IconElement}</Fragment>
    </StyledLink>
  );
}

export default IconLink;
