import { makeVegaRequest } from '~/lib/api/network';

export type Engagement = {
  workspaceId: string;
  engagementId: string;
};

export async function fetchEngagement(
  loopId: string
): Promise<Engagement | null> {
  try {
    const response = await makeVegaRequest(
      `stpp/actions/engagements?filter[viewId]=${loopId}`,
      {
        method: 'GET',
      }
    );
    return response.payload;
  } catch (e) {
    return null;
  }
}
