import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';

import { ResourceDefinition, ResourceTypes } from '../types';

const TemplateFolder: ResourceDefinition = {
  attributes: {
    name: ATTRIBUTE_TYPE.STRING,
    sortOrder: ATTRIBUTE_TYPE.NUMBER,
  },
  relationships: {
    items: {
      polymorphicResourceName: 'items',
      relationshipType: RELATIONSHIP_TYPE.MANY,
      resourceType: [ResourceTypes.Documents, ResourceTypes.Placeholders],
    },
    parent: {
      relationshipType: RELATIONSHIP_TYPE.ONE,
      resourceType: ResourceTypes.Profiles,
    },
  },
};

export default TemplateFolder;
