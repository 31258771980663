import { colors } from '~/styles';
import { DEFAULT_BRAND_COLOR } from '~/styles/colors';
import { BaseIconProps } from '~/components/icons/types';
import scalableIcon from '~/components/icons/utils/scalableIcon';

function DotloopLogo(props: BaseIconProps) {
  const { svgProps = {} } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 32" {...svgProps}>
      <defs>
        <path id="a" d="M32.947 0h-32v32h32z" />
        <path id="c" d="M0 .494v10.308h6.52V.494z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill={DEFAULT_BRAND_COLOR}
          d="M57.467 17.579h.399c.201 0 .333 0 .401-.067.066-.066.133-.133.133-.266 0-.067 0-.134-.067-.2l-.134-.134c-.066 0-.199-.066-.333-.066h-.399v.733zm-.334 1.2v-2.134h.733c.267 0 .467 0 .534.067a.93.93 0 0 1 .266.2c.068.067.134.2.134.334 0 .133-.066.266-.2.399a.604.604 0 0 1-.467.2c.066 0 .134.067.2.134.067.067.2.2.333.4l.267.4h-.4l-.2-.333c-.133-.267-.266-.467-.333-.534-.067-.066-.134-.066-.266-.066h-.267v.933h-.334zm.867-2.8c-.266 0-.534.067-.801.2a1.31 1.31 0 0 0-.599.6 1.837 1.837 0 0 0 0 1.667c.134.266.333.466.599.6.268.133.535.2.801.2.267 0 .533-.067.8-.2.267-.134.467-.334.6-.6.133-.267.2-.534.2-.801 0-.266-.067-.6-.2-.866a1.302 1.302 0 0 0-.6-.6 1.78 1.78 0 0 0-.8-.2zm0-.267c.333 0 .666.067 1 .267.333.2.6.4.734.733.132.334.266.667.266 1 0 .334-.067.667-.266 1a1.945 1.945 0 0 1-.734.734 1.892 1.892 0 0 1-1 .266c-.334 0-.667-.067-1-.266-.334-.2-.534-.4-.733-.734a1.88 1.88 0 0 1-.267-1c0-.333.067-.666.267-1 .2-.333.399-.6.733-.733a1.88 1.88 0 0 1 1-.267zm-17.817-2.19c-1.408 0-1.943 1.338-1.943 2.577 0 .634.14 1.296.437 1.803.323.493.788.83 1.506.83 1.409 0 1.944-1.337 1.944-2.633 0-1.253-.494-2.577-1.944-2.577m-8.208 0c-1.408 0-1.943 1.338-1.943 2.577 0 .634.14 1.296.437 1.803.323.493.788.83 1.506.83 1.408 0 1.943-1.337 1.943-2.633 0-1.253-.492-2.577-1.943-2.577m16.191.028c-1.352 0-1.803 1.422-1.803 2.55 0 .562.099 1.21.381 1.731.281.507.732.887 1.408.887 1.422 0 1.775-1.45 1.775-2.619 0-1.169-.311-2.549-1.761-2.549"
        />
        <g transform="translate(21)">
          <mask id="b" fill={colors.white}>
            <use xlinkHref="#a" />
          </mask>
          <path
            fill={DEFAULT_BRAND_COLOR}
            d="M27.532 19.803c-.9 0-1.774-.451-2.126-1.295v4.182h-1.408v-7.858c0-.831-.029-1.535-.07-2.225h1.336l.057 1.21c.38-.844 1.324-1.351 2.24-1.351 2.14 0 2.886 1.76 2.886 3.633 0 1.859-.789 3.704-2.915 3.704zm-8.35 0c-.563 0-1.055-.084-1.492-.268-1.28-.563-1.97-1.83-1.97-3.436 0-2.14 1.365-3.633 3.462-3.633 2.226 0 3.465 1.437 3.465 3.633 0 2.14-1.239 3.704-3.465 3.704zm-8.208 0c-.563 0-1.055-.084-1.492-.268-1.28-.563-1.97-1.83-1.97-3.436 0-2.14 1.365-3.633 3.462-3.633 2.225 0 3.465 1.437 3.465 3.633 0 2.14-1.24 3.704-3.465 3.704zm-6.163-.155H6.22V9.495H4.811v10.153zM16.947 0c-8.836 0-16 7.164-16 16 0 8.837 7.164 16 16 16 8.837 0 16-7.163 16-16 0-8.836-7.163-16-16-16z"
            mask="url(#b)"
          />
        </g>
        <g transform="translate(0 9)">
          <mask id="d" fill={colors.white}>
            <use xlinkHref="#c" />
          </mask>
          <path
            fill={DEFAULT_BRAND_COLOR}
            d="M3.295 9.718c1.366 0 1.788-1.493 1.788-2.605 0-1.126-.45-2.563-1.803-2.563-1.45 0-1.76 1.38-1.76 2.563 0 .577.086 1.225.352 1.732.268.507.705.873 1.423.873zm1.915.93L5.126 9.48c-.436.901-1.24 1.323-2.211 1.323C.788 10.802 0 8.958 0 7.1c0-1.873.788-3.633 2.915-3.633.887 0 1.775.464 2.126 1.31V.494h1.408v8.041c0 .79.029 1.451.07 2.113H5.21z"
            mask="url(#d)"
          />
        </g>
        <path
          fill={DEFAULT_BRAND_COLOR}
          d="M11.264 18.733c1.409 0 1.943-1.338 1.943-2.634 0-1.252-.492-2.576-1.943-2.576-1.408 0-1.943 1.337-1.943 2.576 0 .634.14 1.296.437 1.802.323.494.788.832 1.506.832M7.8 16.099c0-2.14 1.366-3.633 3.464-3.633 2.225 0 3.465 1.437 3.465 3.633 0 2.14-1.24 3.703-3.465 3.703-.563 0-1.056-.084-1.492-.267C8.49 18.971 7.8 17.706 7.8 16.1m12.004 3.464a4.144 4.144 0 0 1-1.352.239c-1.506 0-1.788-1.042-1.788-2.408V13.62h-1.296v-1.014h1.296v-1.521l1.422-.464v1.985h1.577v1.014h-1.577v3.943c0 .605.155 1.127.873 1.127.268 0 .522-.056.775-.17l.07 1.043z"
        />
      </g>
    </svg>
  );
}

const ScalableIcon = scalableIcon(60, 32, 'other')(DotloopLogo);

export default ScalableIcon;
