import { ResourceDefinition, ResourceTypes } from '../types';
import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';

const LoopRole: ResourceDefinition = {
  attributes: {
    name: ATTRIBUTE_TYPE.STRING,
    systemId: ATTRIBUTE_TYPE.NUMBER,
  },
  relationships: {
    loopParticipantLinks: {
      resourceType: ResourceTypes.LoopParticipantLinks,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
  },
};

export default LoopRole;
