import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';

import { ResourceDefinition, ResourceTypes } from '../types';

const ComplianceStatus: ResourceDefinition = {
  attributes: {
    name: ATTRIBUTE_TYPE.STRING,
  },

  relationships: {
    complianceGroup: {
      resourceType: ResourceTypes.ComplianceGroups,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
  },
};

export default ComplianceStatus;
