import { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '~/store/hooks';
import styled from 'styled-components';

import DesktopHeader from '~/components/Header/DesktopHeader';
import MobileHeader from '~/components/Header/MobileHeader';
import BottomNav, {
  BOTTOM_NAV_HEIGHT,
} from '~/components/Navigation/BottomNav';
import { breakpoints, colors, spacing } from '~/styles';
import { createSafeInsetPadding } from '~/styles/utilities';

import LoadingIndicator from '~/components/LoadingIndicator';
import PageContentErrorBoundary from './PageContentErrorBoundary';
import Container, { PADDING_MD, PADDING_LG, PADDING_XLG } from './Container';
import { useIsDesktop } from '~/hooks';
import { AppState } from '~/store';

const StyledLoadingIndicatorContainer = styled.div({
  position: 'fixed',
  top: '50vh',
  left: '50vw',
  background: colors.white,
});

const StyledContainer = styled(Container)<{
  $pageContentNoBottomPaddingEnabled: boolean;
}>(props => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 auto',
  marginBottom: `calc(${BOTTOM_NAV_HEIGHT} + ${spacing.smallest})`,

  ...createSafeInsetPadding(0, {
    bottom: true,
  }),

  [breakpoints.MEDIUM]: {
    flex: '1 0 auto',
    marginTop: 0,
    marginBottom: 0,
    ...createSafeInsetPadding(PADDING_MD, {
      left: true,
      right: true,
    }),
    paddingBottom: props.$pageContentNoBottomPaddingEnabled ? 0 : '',
  },

  [breakpoints.LARGE]: {
    flex: '0 1 auto',
    ...createSafeInsetPadding(PADDING_LG, {
      left: true,
      right: true,
    }),
    paddingBottom: props.$pageContentNoBottomPaddingEnabled ? 0 : '',
    paddingTop: 0,
  },

  [breakpoints.XLARGE]: {
    ...createSafeInsetPadding(PADDING_XLG, {
      left: true,
      right: true,
    }),
    paddingBottom: props.$pageContentNoBottomPaddingEnabled ? 0 : '',
    paddingTop: 0,
  },
}));

const StyledDesktopPageContentContainer = styled.div<{
  $desktopNoBottomPaddingEnabled: boolean;
}>(props => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 auto',

  [breakpoints.LARGE]: {
    paddingBottom: props.$desktopNoBottomPaddingEnabled ? 0 : spacing.small,
  },
}));

const StyledHeaderContainer = styled.div({
  flex: '0 1 auto',

  '@media print': {
    display: 'none',
  },
});

const StyledPageContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

interface Props {
  whiteBackground?: boolean;
  removeBottomPadding?: boolean;
  children: React.ReactNode;
}

export function DefaultLayout({
  children,
  removeBottomPadding = false,
  whiteBackground = false,
}: Props) {
  const location = useLocation();
  const isDesktop = useIsDesktop();
  const isActiveProfileChanging = useAppSelector(
    (state: AppState) => state.account.activeProfileIsChanging
  );

  useEffect(() => {
    const root = document.documentElement;
    const body = document.body;

    if (!!root && !!body) {
      if (whiteBackground) {
        root.classList.add('white');
        body.classList.add('white');
      } else {
        root.classList.remove('white');
        body.classList.remove('white');
      }
    }
  }, [whiteBackground]);

  const renderBottomNav = !isDesktop;

  return (
    <Fragment>
      <StyledPageContainer>
        <StyledHeaderContainer>
          {isDesktop ? <DesktopHeader location={location} /> : <MobileHeader />}
        </StyledHeaderContainer>
        <StyledDesktopPageContentContainer
          $desktopNoBottomPaddingEnabled={removeBottomPadding}
        >
          <PageContentErrorBoundary>
            <StyledContainer
              $pageContentNoBottomPaddingEnabled={removeBottomPadding}
            >
              {isActiveProfileChanging ? (
                <StyledLoadingIndicatorContainer>
                  <LoadingIndicator type="brand" />
                </StyledLoadingIndicatorContainer>
              ) : (
                children
              )}
            </StyledContainer>
          </PageContentErrorBoundary>
        </StyledDesktopPageContentContainer>
      </StyledPageContainer>
      {renderBottomNav && <BottomNav />}
    </Fragment>
  );
}

export default DefaultLayout;
