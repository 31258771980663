import { useEffect, useState } from 'react';
import { debounce } from 'lodash-es';
import { useAutoId } from '~/lib/utils/auto-id';
import { usePrevious } from './usePrevious';

interface ResizeListener {
  (windowSize: WindowSize): void;
}

interface WindowSize {
  width: number | undefined;
  height: number | undefined;
}

const __RESIZE_LISTENERS__: Record<string, ResizeListener> = {};
const __ON_RESIZE__ = debounce(() => {
  const windowSize = getWindowSize();
  Object.values(__RESIZE_LISTENERS__).forEach(listener => listener(windowSize));
}, 100);
const hasResizeListeners = () => Object.keys(__RESIZE_LISTENERS__).length === 0;
const getWindowSize = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
});

export function useWindowSize() {
  const hookId = useAutoId();
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });
  const previousWindowSize = usePrevious(windowSize);

  useEffect(() => {
    function handleResize(size: WindowSize) {
      setWindowSize(size);
    }

    if (!hasResizeListeners()) {
      window.addEventListener('resize', __ON_RESIZE__);
    }

    __RESIZE_LISTENERS__[hookId] = handleResize;

    if (windowSize.width === undefined) {
      handleResize(getWindowSize());
    }

    return () => {
      delete __RESIZE_LISTENERS__[hookId];

      if (!hasResizeListeners()) {
        window.removeEventListener('resize', __ON_RESIZE__);
      }
    };
  }, [hookId, windowSize.width]);

  return [windowSize, previousWindowSize];
}

export default useWindowSize;
