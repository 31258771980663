import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';

import { ResourceDefinition, ResourceTypes } from '../types';

const UserSignatures: ResourceDefinition = {
  attributes: {
    name: ATTRIBUTE_TYPE.STRING,
    initial: ATTRIBUTE_TYPE.STRING,
  },
  relationships: {
    drawnSignature: {
      resourceType: ResourceTypes.DrawnSignatures,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
  },
};

export default UserSignatures;
