import {
  Component,
  InputHTMLAttributes,
  ReactNode,
  MouseEvent,
  ChangeEvent,
} from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '~/components/buttons';
import autoId from '~/lib/utils/auto-id';
import { noop } from 'lodash-es';

const StyledFileInput = styled.input<{
  $fileInputHiddenEnabled: boolean;
}>(props => ({
  position: 'absolute',
  top: 0,
  right: 0,
  opacity: 0,
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  visibility: props.$fileInputHiddenEnabled ? 'hidden' : 'visible',
}));

const StyledContainer = styled.div({
  overflow: 'hidden',
  position: 'relative',
  height: '100%',
  width: '100%',
});

type State = {
  id: string;
};

type Props = {
  /** Name for the file input */
  name: string;
  /** Function to give parent input ref */
  sendFileInputRef?: (inputRef: HTMLInputElement | null) => void;
  /** Called when user has selected files to upload */
  onChange: (fileList: FileList) => void;
  /** Hide the file input so it's not clickable **/
  hidden: boolean;
  /** Set this to true if the click event should not propagate its event */
  stopPropagation?: boolean;
  /** HTML input props (multiple, accepts, etc) */
  uploadProps?: InputHTMLAttributes<HTMLInputElement>;
  /** Contents of label for the file input.
   * Clicking on the contents will trigger the upload dialog.
   * Defaults to a raised button with generic text. */
  children?: ReactNode;
};

class FileInput extends Component<Props, State> {
  inputRef: HTMLInputElement | null = null;

  static defaultProps = {
    hidden: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      id: autoId(),
    };
  }

  renderDefaultLabel() {
    const { id } = this.state;
    return (
      <PrimaryButton id={`input-${id}-button`} onClick={noop}>
        Add files
      </PrimaryButton>
    );
  }

  setupInputRef = (ref: HTMLInputElement | null) => {
    const { sendFileInputRef } = this.props;

    this.inputRef = ref;

    if (sendFileInputRef) {
      sendFileInputRef(ref);
    }
  };

  handleClick = (e: MouseEvent<HTMLInputElement>) => {
    if (this.props.stopPropagation) {
      e.stopPropagation();
    }
  };

  handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files && this.props.onChange) {
      this.props.onChange(e.target.files);
    }

    if (this.inputRef) {
      this.inputRef.value = '';
    }
  };

  render() {
    const {
      name,
      hidden,
      children,
      uploadProps: providedUploadProps,
    } = this.props;
    const uploadProps = {
      'aria-label': 'File Upload',
      onClick: this.handleClick,
      ...providedUploadProps,
    };

    return (
      <StyledContainer>
        {children ? children : this.renderDefaultLabel()}
        <StyledFileInput
          name={name}
          ref={this.setupInputRef}
          type="file"
          onChange={this.handleFileChange}
          $fileInputHiddenEnabled={hidden}
          {...uploadProps}
        />
      </StyledContainer>
    );
  }
}

export default FileInput;
