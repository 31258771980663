import { useState, useEffect } from 'react';
import json2mq from 'json2mq';

type MediaMatchQuery = string | { [key: string]: any };

const getMediaQueryList = (query: MediaMatchQuery) => {
  const mediaQueryList = window.matchMedia(
    typeof query === 'string' ? query : json2mq(query)
  );
  return mediaQueryList;
};

/**
 * Hook for checking media queries against window state
 * @param initialQuery - string or string map representing media query
 * @returns Boolean - if the current browser view matches the media query param
 */
export function useMedia(initialQuery: MediaMatchQuery) {
  // Assumes query doesn't change
  const [mediaQueryList] = useState(() => getMediaQueryList(initialQuery));
  const [matches, setMatches] = useState(!!mediaQueryList.matches);

  // Only call once, since this sets up event listeners
  useEffect(() => {
    let active = true;

    const listener = () => {
      if (!active) {
        return;
      }

      setMatches(mediaQueryList.matches);
    };

    // Using 'addListener' instead of 'addEventListener', even though 'addListener' is no longer supported
    // Our build process breaks using the correct one.
    mediaQueryList.addListener(listener);

    return () => {
      active = false;
      mediaQueryList.removeListener(listener);
    };
  }, [mediaQueryList]);

  return matches;
}
