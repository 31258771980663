type TransferFile = { type: string };
type PlainFileType = File | TransferFile;
export type PlainFileList = Array<PlainFileType>;
function createPlainFileList(
  list: FileList | DataTransferItemList
): PlainFileList {
  let plainFileList: PlainFileList;

  if (list instanceof FileList) {
    plainFileList = Array.prototype.slice.call(list);
  } else {
    plainFileList = [];

    for (let i = 0; i < list.length; i++) {
      const item = list[i];

      if (item instanceof File) {
        plainFileList.push(item);
      } else if (item instanceof DataTransferItem) {
        plainFileList.push({ type: item.type });
      }
    }
  }

  return plainFileList;
}

export function getDataTransferFiles(
  target: EventTarget,
  dataTransfer: DataTransfer,
  isMultipleAllowed = true
): PlainFileList {
  let plainFileList: PlainFileList = [];
  if (dataTransfer) {
    if (dataTransfer.files && dataTransfer.files.length) {
      plainFileList = createPlainFileList(dataTransfer.files);
    } else if (dataTransfer.items && dataTransfer.items.length) {
      // During the drag event the dataTransfer.files is null
      // but Chrome implements some drag store, which is accesible via dataTransfer.items
      plainFileList = createPlainFileList(dataTransfer.items);
    }
  } else if (
    target &&
    target instanceof HTMLInputElement &&
    target.files &&
    target.files instanceof FileList
  ) {
    plainFileList = createPlainFileList(target.files);
  }

  if (plainFileList.length > 0 && !isMultipleAllowed) {
    plainFileList = [plainFileList[0]];
  }

  return plainFileList;
}

/**
 * Returns a tuple of [acceptedFiles, rejectedFiles], according
 * to the `accept` mime type sent
 */
export function validateFiles<T extends PlainFileType = PlainFileType>(
  files: T[],
  accept: string
) {
  const { valid, invalid } = files.reduce<{
    valid: T[];
    invalid: T[];
  }>(
    (acc, file) => {
      const isValid = _isValidFileExtension(file, accept);

      if (isValid) {
        acc.valid.push(file);
      } else {
        acc.invalid.push(file);
      }
      return acc;
    },
    { valid: [], invalid: [] }
  );

  return [valid, invalid];
}

/**
 * Returns `true` if *all* files in the list have valid extensions, `false` otherwise
 */
export function isValidFileExtension(
  files: PlainFileList,
  accept: string
): boolean {
  let isValid = true;

  if (files && accept) {
    isValid = files.every(file => _isValidFileExtension(file, accept));
  }

  return isValid;
}

function _isValidFileExtension(file: File | TransferFile, accept: string) {
  const mimeType = file.type || '';
  const validType = accept.trim();
  return !!mimeType && validType.includes(mimeType);
}
