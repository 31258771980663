import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';
import {
  ResourceDefinition,
  ResourceTypes,
} from '~/store/features/api/resources/types';

const LoopTaskList: ResourceDefinition = {
  attributes: {
    name: ATTRIBUTE_TYPE.STRING,
    restricted: ATTRIBUTE_TYPE.BOOLEAN,
    locked: ATTRIBUTE_TYPE.BOOLEAN,
    memberCount: ATTRIBUTE_TYPE.NUMBER,
    profileIdOwner: ATTRIBUTE_TYPE.STRING,
    sourceTaskListId: ATTRIBUTE_TYPE.STRING,
    autoGenerated: ATTRIBUTE_TYPE.BOOLEAN,
  },
  relationships: {
    loop: {
      resourceType: ResourceTypes.Loops,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
    items: {
      resourceType: ResourceTypes.LoopTaskListItems,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
    participants: {
      resourceType: ResourceTypes.LoopParticipants,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
  },
};
export default LoopTaskList;
