import { Component, ComponentType, ReactNode, DragEvent } from 'react';

import { DragDropContext, ContextValue } from './context';
import { isValidFileExtension } from './utils';
import DropZoneOverlay from './__internal__/DropZoneOverlay';
import DropZoneOutline from './__internal__/DropZoneOutline';

type ComponentProps = {
  /** MIME type(s) of acceptable file types */
  accept: string;
  /** Whether a file has been dropped */
  hasAcceptedFile: boolean;
  /** Called when valid files are dropped on this target */
  onFileDrop: (files: FileList) => void;
  /** Contents of the drop zone target */
  children?: ReactNode;
  /** Icon to be displayed inside container */
  icon?: ComponentType;
  /** Text to display in overlay when target has focus */
  focusedText: string;
};

type Props = ComponentProps & ContextValue;

type State = {
  isValid: boolean;
  isActive: boolean;
  isFocused: boolean;
};

class DropZoneTarget extends Component<Props, State> {
  enterCounter = 0;

  state = {
    isValid: true,
    isActive: false,
    isFocused: false,
  };

  static getDerivedStateFromProps(nextProps: Props) {
    const files = nextProps.files;
    const isActive = nextProps.isActive;
    const state = { isActive, isValid: true };

    if (isActive && files && files.length > 0) {
      state.isValid = isValidFileExtension(files, nextProps.accept);
    }

    return state;
  }

  handleDrop = (event: DragEvent) => {
    event.preventDefault();

    const { isValid } = this.state;
    const {
      dataTransfer: { files },
    } = event;
    this.enterCounter = 0;

    this.setState({
      isFocused: false,
    });

    if (isValid && files && files.length > 0) {
      this.props.onFileDrop(files);
    }
  };

  handleDragOver = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    return false;
  };

  handleDragStart = (event: DragEvent) => {
    event.preventDefault();
  };

  handleDragEnter = (event: DragEvent) => {
    event.preventDefault();

    ++this.enterCounter;
    this.setState({
      isFocused: true,
    });
  };

  handleDragLeave = (event: DragEvent) => {
    event.preventDefault();

    if (--this.enterCounter === 0) {
      this.setState({
        isFocused: false,
      });
    }
  };

  render() {
    const { isFocused, isActive, isValid } = this.state;
    const { icon, focusedText, children, hasAcceptedFile } = this.props;

    return (
      <div
        onDrop={this.handleDrop}
        onDragOver={this.handleDragOver}
        onDragStart={this.handleDragStart}
        onDragEnter={this.handleDragEnter}
        onDragLeave={this.handleDragLeave}
      >
        <DropZoneOverlay
          isFocused={isFocused}
          isActive={isActive}
          isValid={isValid}
          icon={icon}
          focusedText={focusedText}
        />
        <DropZoneOutline
          isFocused={isFocused}
          isActive={isActive}
          isValid={isValid}
          hasAcceptedFile={hasAcceptedFile}
        />
        {children}
      </div>
    );
  }
}

export const DragDropWrapper = ({
  accept,
  onFileDrop,
  children,
  icon,
  focusedText,
  hasAcceptedFile,
}: ComponentProps) => (
  <DragDropContext.Consumer>
    {({ files, isActive }) => (
      <DropZoneTarget
        files={files}
        isActive={isActive}
        accept={accept}
        onFileDrop={onFileDrop}
        icon={icon}
        focusedText={focusedText}
        hasAcceptedFile={hasAcceptedFile}
      >
        {children}
      </DropZoneTarget>
    )}
  </DragDropContext.Consumer>
);
export default DragDropWrapper;
