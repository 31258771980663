import WebSocketService from '~/services/web-socket-service';
import { useEffect, useState } from 'react';

export function usePubNubWebSockeService() {
  const [webSocketService, setWebSocketService] =
    useState<WebSocketService | null>(null);

  useEffect(() => {
    setWebSocketService(new WebSocketService());
  }, []);

  return webSocketService;
}
