import { createElement, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from '~/components/i18n';
import styled from 'styled-components';
import { Location } from 'react-router-dom';

import { AppState } from '~/store';
import {
  selectNavigationAccess,
  selectCurrentProfile,
  getUserRoleId,
} from '~/store/features/account/selectors';

import { useBrand, useMedia } from '~/hooks';
import AppLink from '~/components/AppLink';
import Container from '~/components/layout/Container';
import { PREMIUM_LINK_URL } from '~/components/LoopLimitInlineNotification';
import { MESSAGE_BREAKPOINT_SCREEN_SIZE } from '~/components/Messaging/MessagingRouter';
import MainLogo from '~/components/MainLogo';
import { getNavItems } from '~/components/Navigation';
import {
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  headerHeight,
  spacing,
} from '~/styles';

import { NavItem } from '~/components/Navigation';
import { SubscriptionLevel } from '~/store/features/api/resources/profile/constants';
import { ProfileResource } from '~/store/features/api/resources/profile/types';

import Account from './Account';
import { LinkButton } from '~/components/buttons';
import { ResourceTypes } from '~/store/features/api/resources/types';
import config from '../../config';
import { NavigationAccessType, UserRole } from '~/store/features/account/types';

type BrandColorType = { $brandColor: string };

const StyledNavItemLink = styled.div<
  BrandColorType & {
    $accountIconLinkEnabled: boolean;
  }
>(props => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  outline: 0,
  border: '2px solid transparent',

  '&:active, &:focus': {
    outline: 'none',
    border: `2px solid ${props.$brandColor}`,
  },

  ...(props.$accountIconLinkEnabled
    ? {
        justifyContent: 'center',

        '&:active, &:focus': {
          outline: 'none',
          borderColor: 'transparent',
        },
      }
    : {}),
}));

const StyledNavList = styled.ul({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  margin: 0,
  padding: 0,
});

const StyledAccountLevelDivider = styled.div<BrandColorType>(props => ({
  display: 'none',

  [breakpoints.XLARGE]: {
    display: 'block',
    flex: '0 0 auto',
    width: '2px',
    height: '35%',
    margin: `0 ${spacing.small}`,
    backgroundColor: props.$brandColor,
  },
}));

const StyledHeaderItem = styled.div<{
  $navEnabled?: boolean;
  $accountLevelTextEnabled?: boolean;
}>(props => ({
  display: 'flex',
  alignItems: 'center',
  flex: '0 1 auto',
  height: '100%',
  padding: `${spacing.small} 0`,
  ...(props.$accountLevelTextEnabled
    ? {
        display: 'none',

        [breakpoints.XLARGE]: {
          ...fontSizes.secondaryTitle,
          display: 'block',
          height: 'auto',
        },
      }
    : {}),
  ...(props.$navEnabled
    ? {
        padding: 0,
        paddingLeft: spacing.small,
        marginLeft: 'auto',
      }
    : {}),
}));

const StyledHeaderWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
});

const StyledContainer = styled(Container)({
  height: '100%',
  paddingTop: 0,
});

const StyledHeader = styled.header({
  ...headerHeight.styles,
  backgroundColor: colors.white,
  borderBottom: `1px solid ${colors.lightBorder}`,
});

const StyledIntranetLinkHeader = styled.header({
  borderBottom: `1px solid ${colors.lightBorder}`,
  backgroundColor: colors.white,
  paddingTop: spacing.small,
  paddingBottom: spacing.small,
  marginBottom: 1,
});

const StyledNavItemText = styled.div<
  BrandColorType & {
    $navItemHoveredTextEnabled: boolean;
    $navItemActiveTextEnabled: boolean;
  }
>(props => ({
  marginTop: 'auto',
  marginBottom: '-2px',
  padding: `0 ${spacing.small}`,
  color: colors.secondaryText,
  lineHeight: 1.5,
  fontSize: fontSizes.callout.fontSize,
  fontWeight: fontWeights.semiBold,
  borderBottom: '2px solid transparent',
  ...(props.$navItemHoveredTextEnabled
    ? {
        color: props.$brandColor,
      }
    : {}),
  ...(props.$navItemActiveTextEnabled
    ? {
        color: props.$brandColor,
        fontWeight: fontWeights.bold,
        borderBottomColor: props.$brandColor,
      }
    : {}),
}));

type NavItemIconProps = {
  $accountMenuIconEnabled?: boolean;
};
const StyledNavItemIcon = styled.div<NavItemIconProps>(props => ({
  height: '24px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  ...(props.$accountMenuIconEnabled
    ? {
        position: 'static',
        height: 'auto',
        transform: 'none',
      }
    : {}),
}));

const StyledAppLink = styled(AppLink)<BrandColorType>(props => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  outline: 0,
  border: '2px solid transparent',

  '&:active, &:focus': {
    outline: 'none',
    border: `2px solid ${props.$brandColor}`,
  },
}));

const StyledNavListItem = styled.li({
  flex: '0 1 auto',
  height: '100%',
  margin: 0,

  '&:not(:last-child)': {
    marginRight: spacing.normal,
    paddingRight: spacing.normal,
  },
});

type ReduxProps = {
  navigationAccess: NavigationAccessType;
  currentProfile: ProfileResource | null;
  userRoleId: number;
};

type ComponentProps = {
  location: Location;
} & ReduxProps;

type Props = ComponentProps;

type HeaderNavItemProps = {
  location: Location;
  item: NavItem;
  brandColor: string;
};

function HeaderNavItem(props: HeaderNavItemProps) {
  const [isHovered, setIsHovered] = useState(false);
  const { location, item } = props;
  const isActive = location.pathname.indexOf(item.link) !== -1;
  const iconType = isActive || isHovered ? 'brand' : 'secondaryText';
  const IconElement = createElement(
    item.icon,
    { type: iconType, height: '100%' },
    null
  );

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <StyledNavListItem
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <StyledAppLink to={item.link} $brandColor={props.brandColor}>
        <StyledNavItemIcon>{IconElement}</StyledNavItemIcon>
        <StyledNavItemText
          $navItemHoveredTextEnabled={isHovered}
          $navItemActiveTextEnabled={isActive}
          $brandColor={props.brandColor}
        >
          <FormattedMessage id={item.labelId} />
        </StyledNavItemText>
      </StyledAppLink>
    </StyledNavListItem>
  );
}

function getDesktopNavItems(
  navigationAccess: NavigationAccessType,
  hideMessaging: boolean
) {
  const navItems = getNavItems(navigationAccess);

  return navItems.filter(item => {
    if (hideMessaging) {
      return item.labelId.indexOf(ResourceTypes.Messages) === -1;
    } else {
      return item.labelId.indexOf('reporting') === -1;
    }
  });
}

export function DesktopHeader(props: Props) {
  const { location, currentProfile, navigationAccess, userRoleId } = props;
  const hideMessaging = useMedia({ minWidth: MESSAGE_BREAKPOINT_SCREEN_SIZE });
  const brandedColor = useBrand();
  const navItems = getDesktopNavItems(navigationAccess, hideMessaging);
  const subscriptionLevel =
    currentProfile && currentProfile.subscriptionLevel
      ? currentProfile.subscriptionLevel.toLowerCase()
      : '';
  const hasIntranetLink =
    currentProfile &&
    currentProfile.intranetLinkUrl !== undefined &&
    currentProfile.intranetLinkText !== undefined;
  const intranetLinkUrl =
    currentProfile && currentProfile.intranetLinkUrl
      ? currentProfile.intranetLinkUrl
      : '';

  const baseUrl = `${config.ui.phoenixUrl}/products/plans-pricing/?utm_campaign=Product_Marketing&utm_source=dotloop&utm_medium=upsell_prompt&utm_content=`;
  let upsellUrl = PREMIUM_LINK_URL;
  let isOpenNewTab = false;
  if (userRoleId === UserRole.BrokerageManager) {
    upsellUrl = `${baseUrl}brokerage_manager`;
    isOpenNewTab = true;
  } else if (userRoleId === UserRole.TransactionCoordinator) {
    upsellUrl = `${baseUrl}transaction_coordinator`;
    isOpenNewTab = true;
  } else if (userRoleId === UserRole.TeamLead) {
    upsellUrl = `${baseUrl}team_lead`;
    isOpenNewTab = true;
  }

  return (
    <Fragment>
      {currentProfile && hasIntranetLink && (
        <StyledIntranetLinkHeader>
          <Container>
            <AppLink to={intranetLinkUrl}>
              <span>
                <FormattedMessage id="intranetLink.actionText" />
                &nbsp;
                {!!currentProfile.intranetLinkText && (
                  <FormattedMessage id={currentProfile.intranetLinkText} />
                )}
              </span>
            </AppLink>
          </Container>
        </StyledIntranetLinkHeader>
      )}
      <StyledHeader>
        <StyledContainer>
          <StyledHeaderWrapper>
            <StyledHeaderItem $accountLevelTextEnabled={false}>
              <MainLogo />
            </StyledHeaderItem>
            {subscriptionLevel === SubscriptionLevel.Free ? (
              <Fragment>
                <StyledAccountLevelDivider $brandColor={brandedColor} />
                <LinkButton
                  removeSidePadding
                  removeTopBottomPadding
                  openNewTab={isOpenNewTab}
                  id="desktop-header-upgrade-link"
                  link={upsellUrl}
                >
                  <FormattedMessage id="actions.upgradeNow" />
                </LinkButton>
              </Fragment>
            ) : (
              <Fragment>
                <StyledAccountLevelDivider $brandColor={brandedColor} />
                <StyledHeaderItem $accountLevelTextEnabled>
                  <FormattedMessage
                    id={`account.subscriptionLevels.${subscriptionLevel}`}
                  />
                </StyledHeaderItem>
              </Fragment>
            )}
            <StyledHeaderItem as="nav" $navEnabled>
              <StyledNavList>
                {navItems.map(item => (
                  <HeaderNavItem
                    key={item.link}
                    item={item}
                    location={location}
                    brandColor={brandedColor}
                  />
                ))}
                <StyledNavListItem>
                  <StyledNavItemLink
                    $accountIconLinkEnabled
                    $brandColor={brandedColor}
                  >
                    <StyledNavItemIcon $accountMenuIconEnabled>
                      <Account />
                    </StyledNavItemIcon>
                  </StyledNavItemLink>
                </StyledNavListItem>
              </StyledNavList>
            </StyledHeaderItem>
          </StyledHeaderWrapper>
        </StyledContainer>
      </StyledHeader>
    </Fragment>
  );
}

const mapStateToProps = (state: AppState): ReduxProps => ({
  navigationAccess: selectNavigationAccess(state),
  currentProfile: selectCurrentProfile(state),
  userRoleId: getUserRoleId(state),
});

export default connect(mapStateToProps)(DesktopHeader);
