import { useState } from 'react';
import styled from 'styled-components';

import { boxShadows, colors, fontWeights, spacing } from '~/styles';

import LoadingMoreIndicator from '~/components/LoadingMoreIndicator';

const StyledLabel = styled.label<{
  $isDisabled: boolean;
}>(props => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '50%',
  opacity: props.$isDisabled ? 0.4 : 1,
  cursor: 'pointer',
}));

const StyledToggle = styled.input({
  opacity: 0,
  width: 0,
  height: 0,
});

const StyledSlider = styled.span<{
  $isDisabled: boolean;
  $isChecked: boolean;
}>(props => ({
  position: 'relative',
  width: spacing.max,
  height: spacing.medium,
  backgroundColor: props.$isChecked
    ? colors.primaryBackground
    : colors.lightBackground,
  borderRadius: spacing.normal,
  transition: 'background-color .4s',

  '.hovered &, .focused &': {
    boxShadow: boxShadows.surround,
    backgroundColor: props.$isDisabled
      ? props.$isChecked
        ? colors.primaryBackground
        : colors.lightBackground
      : colors.primaryBackgroundDark,
  },
}));

const StyledButton = styled.div<{
  $isChecked: boolean;
}>(props => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
  left: spacing.smallest,
  height: spacing.small,
  width: spacing.small,
  color: props.$isChecked ? colors.primaryBackground : colors.white,
  backgroundColor: props.$isChecked ? colors.white : colors.darkBorder,
  borderRadius: '50%',
  transition: 'background-color .4s, transform .4s',
  transform: props.$isChecked
    ? `translate(${spacing.medium}, -50%)`
    : `translate(0, -50%)`,

  '.hovered &, .focused &': {
    color: colors.primaryBackgroundDark,
    backgroundColor: colors.white,
  },
}));

const StyledLabelText = styled.span({
  fontWeight: fontWeights.semiBold,
  marginRight: spacing.smaller,
});

type Props = {
  /** HTML ID attribute for the switch input */
  id?: string;
  /** The label for the switch */
  label: string;
  /** State of the switch, checked or unchecked */
  isChecked: boolean;
  /** Whether or not the switch is disabled */
  isDisabled?: boolean;
  /** Displays waiting indicator and hides switch */
  isInProgress?: boolean;
  /** Hides the label and places it as a title on the switch SVG */
  hideLabel?: boolean;
  /** Called when the checked state of the switch changes */
  onChange: (isChecked: boolean) => void;
};

function Switch(props: Props) {
  const {
    id,
    isChecked = false,
    label,
    isDisabled = false,
    onChange,
    hideLabel = false,
    isInProgress = false,
  } = props;
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const toggleChecked = () => {
    if (onChange) {
      onChange(!isChecked);
    }
  };

  return (
    <StyledLabel
      $isDisabled={isDisabled}
      htmlFor={id}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`switch-label ${isFocused ? 'focused' : ''} ${
        isHovered ? 'hovered' : ''
      }`}
    >
      {isInProgress ? (
        <LoadingMoreIndicator width={spacing.max} numberOfDots={3} />
      ) : (
        <>
          {!hideLabel && <StyledLabelText>{label}</StyledLabelText>}
          <StyledToggle
            id={id}
            type="checkbox"
            role="switch"
            aria-label={label}
            checked={isChecked}
            disabled={isDisabled}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={toggleChecked}
          />
          <StyledSlider $isDisabled={isDisabled} $isChecked={isChecked}>
            <StyledButton $isChecked={isChecked} />
          </StyledSlider>
        </>
      )}
    </StyledLabel>
  );
}

export default Switch;
