import {
  ResourceDefinition,
  ResourceTypes,
} from '~/store/features/api/resources/types';
import {
  ATTRIBUTE_TYPE,
  RELATIONSHIP_TYPE,
} from '~/store/features/api/resources/constants';

const ComplianceReviewMessage: ResourceDefinition = {
  attributes: {
    id: ATTRIBUTE_TYPE.STRING,
    message: ATTRIBUTE_TYPE.STRING,
    author: ATTRIBUTE_TYPE.AS_IS,
    profileTypeId: ATTRIBUTE_TYPE.STRING,
    createdDate: ATTRIBUTE_TYPE.DATE,
  },
  relationships: {
    participants: {
      resourceType: ResourceTypes.ComplianceMessageRecipients,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
    note: {
      resourceType: ResourceTypes.ComplianceReviewNote,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
  },
};

export default ComplianceReviewMessage;
