import { Fragment, useState } from 'react';

import {
  ArchiveIcon,
  UnarchiveIcon,
  RenameIcon,
  PhotoIcon,
} from '~/components/icons';
import DropdownMenu, { MenuType } from '~/components/dropdowns/DropdownMenu';
import RenameLoopModal from './RenameLoopModal';
import { Position } from '~/components/Popover';
import {
  SnackbarNotification,
  NotificationType,
} from '~/components/Notifications';
import { FormattedMessage } from '~/components/i18n';

import { LoopResource } from '~/store/features/api/resources/loop/types';
import { ResourceTypes } from '~/store/features/api/resources/types';
import { useAppDispatch } from '~/store/hooks';
import { updateResource } from '~/store/features/api/apiSlice';
import UploadLoopPhotoModal from '~/pages/LoopDetails/components/Overview/UploadLoopPhotoModal';

type Props = {
  isOpen: boolean;
  triggerId: string;
  onRequestClose: () => void;
  loop: LoopResource;
};

function LoopActionsDropdownMenu(props: Props) {
  const dispatch = useAppDispatch();

  const { loop, onRequestClose, ...dropdownMenuProps } = props;
  const [isRenameLoopModalOpen, setIsRenameLoopModalOpen] = useState(false);
  const [isUploadPhotoModalOpen, setIsUploadPhotoModalOpen] = useState(false);
  const [updateError, setUpdateError] = useState<string | null>(null);
  const [updateSuccess, setUpdateSuccess] = useState<string | null>(null);

  const updateLoop = (id: string, loop: Partial<LoopResource>) =>
    dispatch(
      updateResource({
        resourceName: ResourceTypes.Loops,
        resourceId: id,
        attributesToUpdate: loop,
        resourceRelationships: null,
      })
    );

  const showRenameLoopModal = () => {
    setIsRenameLoopModalOpen(true);
    onRequestClose();
  };

  const showUploadPhotoModal = () => {
    setIsUploadPhotoModalOpen(true);
    onRequestClose();
  };

  const handleSaveLoopName = async (loopName: string) => {
    await updateLoop(loop.id, { name: loopName })
      .then(() => {
        setUpdateSuccess('loop-details:overview.actions.loopNameUpdateSuccess');
        setIsRenameLoopModalOpen(false);
      })
      .catch(() => {
        setUpdateError('loop-details:overview.actions.loopUpdateFailed');
      });
  };

  const handleToggleArchive = async (archived: boolean) => {
    onRequestClose();

    await updateLoop(loop.id, { archived })
      .then(() => {
        setUpdateSuccess(
          archived
            ? 'loop-details:overview.actions.archivedLoopSuccess'
            : 'loop-details:overview.actions.unarchivedLoopSuccess'
        );
        setIsRenameLoopModalOpen(false);
      })
      .catch(() => {
        setUpdateError('loop-details:overview.actions.loopUpdateFailed');
      });
  };

  if (!loop) {
    return null;
  }

  return (
    <Fragment>
      <DropdownMenu
        {...dropdownMenuProps}
        onRequestClose={onRequestClose}
        defaultPosition={Position.BottomRight}
        menuChildren={[
          {
            id: 'rename-loop',
            labelId: 'loop-details:overview.actions.renameLoop',
            icon: RenameIcon,
            onClick: showRenameLoopModal,
          },
          {
            id: 'add-loop-photo',
            labelId: loop.imageUrl
              ? 'loop-details:overview.uploadLoopPhotoModal.updateLoopPhoto'
              : 'loop-details:overview.actions.addLoopPhoto',
            icon: PhotoIcon,
            onClick: showUploadPhotoModal,
          },
          { id: 'menu-divider', kind: MenuType.Divider },
          {
            isNegative: true,
            id: 'archive-loop',
            labelId: loop.archived
              ? 'loop-details:overview.actions.unarchiveLoop'
              : 'loop-details:overview.actions.archiveLoop',
            icon: loop.archived ? UnarchiveIcon : ArchiveIcon,
            onClick: () => handleToggleArchive(!loop.archived),
          },
        ]}
      />

      <RenameLoopModal
        isOpen={isRenameLoopModalOpen}
        loopName={loop.name}
        onRequestClose={() => setIsRenameLoopModalOpen(false)}
        onSave={handleSaveLoopName}
      />

      <UploadLoopPhotoModal
        isOpen={isUploadPhotoModalOpen}
        onRequestClose={() => setIsUploadPhotoModalOpen(false)}
        loop={loop}
        updateLoop={updateLoop}
      />

      {updateError && (
        <SnackbarNotification
          id={`loop-update-error-${updateError}`}
          type={NotificationType.Error}
          onDismiss={() => setUpdateError(null)}
        >
          <FormattedMessage id={updateError} />
        </SnackbarNotification>
      )}

      {updateSuccess && (
        <SnackbarNotification
          id={`loop-update-success-${updateSuccess}`}
          type={NotificationType.Success}
          onDismiss={() => setUpdateSuccess(null)}
        >
          <FormattedMessage id={updateSuccess} />
        </SnackbarNotification>
      )}
    </Fragment>
  );
}

export default LoopActionsDropdownMenu;
