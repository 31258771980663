import { ReactNode } from 'react';
import styled from 'styled-components';

import { colors, fontSizes, createTransform, boxShadows } from '~/styles';

const mixins = {
  radioButtonSvgContainerSelected: {
    border: 'none',
  },

  radioButtonBackgroundSelected: {
    opacity: 1,
    borderColor: colors.primaryAction,
    fill: colors.primaryAction,
  },
};

const StyledRadioButtonBackground = styled.circle<{
  $radioButtonBackgroundSelectedEnabled: boolean;
}>(props => ({
  opacity: 0,
  transition: 'opacity 0.3s ease-out',
  background: colors.white,
  ...(props.$radioButtonBackgroundSelectedEnabled
    ? mixins.radioButtonBackgroundSelected
    : {}),
}));

const StyledRadioButtonSvg = styled.svg({
  width: '100%',
  height: '100%',
});

const StyledRadioButtonSvgContainer = styled.div<{
  $radioButtonSvgContainerSelectedEnabled: boolean;
  $isDisabled: boolean;
  $isSelected: boolean;
  $radioWidthHeight: string | number;
  $svgTop: string | number;
  $svgTransform: Record<string, unknown>;
}>(props => ({
  ...props.$svgTransform,
  position: 'absolute',
  top: props.$svgTop,
  left: 0,
  width: props.$radioWidthHeight,
  height: props.$radioWidthHeight,
  border: `2px solid ${colors.lightBorder}`,
  borderColor: props.$isDisabled
    ? colors.disabledText
    : props.$isSelected
    ? colors.primaryAction
    : colors.lightBorder,
  borderRadius: '50%',
  background: colors.white,
  ...(props.$radioButtonSvgContainerSelectedEnabled
    ? mixins.radioButtonSvgContainerSelected
    : {}),
  cursor: props.$isDisabled ? 'not-allowed' : 'pointer',
}));

const StyledRadioButtonLabel = styled.label<{
  $isDisabled: boolean;
  $radioWidthHeight: string | number;
}>(props => ({
  paddingLeft: `calc(${props.$radioWidthHeight} * 1.5)`,
  display: 'block',
  margin: 0,
  cursor: props.$isDisabled ? 'not-allowed' : 'pointer',
  color: props.$isDisabled ? colors.disabledText : undefined,
}));

const StyledRadioButtonInput = styled.input({
  position: 'absolute',
  display: 'inline',
  top: 0,
  left: 0,
  margin: 0,
  opacity: 0,

  '&:focus ~ label .svgRadioButtonContainer': {
    borderColor: colors.primaryAction,
    boxShadow: boxShadows.normalWithColor(colors.primaryAction),
  },
  '&:disabled ~ label .svgRadioButtonContainer': {
    backgroundColor: colors.lightBackground,
  },
});

const StyledRadioButton = styled.div({
  position: 'relative',
});

type Props = {
  /** HTML id attribute for the radio button */
  id: string;
  /** HTML name attribute for the radio button */
  name?: string;
  /** Value of the radio input */
  value: string;
  /** State of the radio button, selected or unselected */
  isSelected: boolean;
  /** Label of the radio input, displayed if no `labelRender` function is given */
  label: string | JSX.Element;
  /** Whether or not the radio button is disabled */
  disabled?: boolean;
  /** Forces a specific width / height (used in document editor) */
  overrideSize?: number;
  /** Function called to render the label for the radio button */
  labelRender?: (label: string) => ReactNode;
  /** Hides the label and places it as a title on the radio SVG */
  hideLabel?: boolean;
  /** Called when the radio button is selected */
  onChange: (value: string) => void;
};

export function BaseRadioButton(props: Props) {
  const {
    id,
    name,
    value,
    isSelected,
    label,
    disabled,
    overrideSize,
    labelRender,
    onChange,
    hideLabel = false,
  } = props;
  //const styles = createStyles(isSelected, disabled, overrideSize);
  const labelToRender =
    labelRender && typeof label === 'string' ? labelRender(label) : label;
  const radioWidthHeight = !!overrideSize
    ? overrideSize
    : fontSizes.secondaryTitle.fontSize;
  const svgTop = !!overrideSize ? 0 : '50%';
  const svgTransform = !!overrideSize
    ? {}
    : createTransform('translateY(-50%)');

  return (
    <StyledRadioButton>
      <StyledRadioButtonInput
        id={id}
        type="radio"
        checked={isSelected}
        onChange={() => onChange(value)}
        name={name}
        value={value}
        disabled={disabled}
      />
      <StyledRadioButtonLabel
        htmlFor={id}
        $isDisabled={!!disabled}
        $radioWidthHeight={radioWidthHeight}
      >
        {!hideLabel && labelToRender}
        <StyledRadioButtonSvgContainer
          className="svgRadioButtonContainer"
          $radioButtonSvgContainerSelectedEnabled={isSelected}
          $isDisabled={!!disabled}
          $isSelected={isSelected}
          $radioWidthHeight={radioWidthHeight}
          $svgTop={svgTop}
          $svgTransform={svgTransform}
        >
          <StyledRadioButtonSvg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            {hideLabel && <title>{labelToRender}</title>}
            <StyledRadioButtonBackground
              cx="26"
              cy="26"
              r="26"
              fill={colors.primaryAction}
              $radioButtonBackgroundSelectedEnabled={isSelected}
            />
            <circle
              cx="26"
              cy="26"
              r="10"
              fill={
                isSelected
                  ? colors.primaryBackgroundText
                  : disabled
                  ? colors.lightBackground
                  : colors.white
              }
            />
          </StyledRadioButtonSvg>
        </StyledRadioButtonSvgContainer>
      </StyledRadioButtonLabel>
    </StyledRadioButton>
  );
}

export default BaseRadioButton;
