import { createContext } from 'react';
import i18n from '~/lib/i18n';
import distanceInWords from 'date-fns/distance_in_words';
import distanceInWordsStrict from 'date-fns/distance_in_words_strict';

import { formatCurrency, formatDate } from './utils';
import { ContextType, CurrencyOptions, DateOptions } from './types';
import { Options } from './types';

export function getContextValue(dateFnsLocale?: string): ContextType {
  return {
    loadedAt: new Date(),
    i18n: {
      i18next: i18n,
      dateFnsLocale: dateFnsLocale,
      t: (...args) => i18n.t(...args),
      exists: (...args) => i18n.exists(...args),
      formatCurrency: (
        value: number | string,
        currency: string,
        options: CurrencyOptions | null | undefined
      ) => {
        return formatCurrency(value, currency, options, i18n);
      },
      formatDate: (date: Date, dateFormat: keyof DateOptions) => {
        return formatDate(date, dateFormat, i18n);
      },
      formatDateDistanceInWords: (date, dateToCompare, options = {}) => {
        const opts: Options = { ...options };

        if (dateFnsLocale) {
          opts.locale = dateFnsLocale;
        }

        return distanceInWords(date, dateToCompare, opts);
      },
      formatDateDistanceInWordsStrict: (date, dateToCompare, options = {}) => {
        const opts: Options = { ...options };

        if (dateFnsLocale) {
          opts.locale = dateFnsLocale;
        }

        return distanceInWordsStrict(date, dateToCompare, opts);
      },
    },
  };
}

const defaultContext = getContextValue();

export const I18nContext = createContext<ContextType>(defaultContext);
