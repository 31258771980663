import { createRequiredContextHelper } from '~/lib/utils/context';

export type ContextValue = {
  name: string;
  selectedValue: string | null;
  onChange: (value: string) => void;
};

const [useRadioContext, RadioProvider] =
  createRequiredContextHelper<ContextValue>();

export { useRadioContext, RadioProvider };
