import { ATTRIBUTE_TYPE } from '../constants';

import { ResourceDefinition } from '../types';

const DocumentPage: ResourceDefinition = {
  attributes: {
    number: ATTRIBUTE_TYPE.NUMBER,
    s3ImageUrl: ATTRIBUTE_TYPE.STRING,
    height: ATTRIBUTE_TYPE.NUMBER,
    width: ATTRIBUTE_TYPE.NUMBER,
    rotation: ATTRIBUTE_TYPE.STRING,
    documentId: ATTRIBUTE_TYPE.NUMBER,
  },
};

export default DocumentPage;
