import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '~/store/hooks';
import { AppState } from '~/store';
import { LoopResource } from '~/store/features/api/resources/loop/types';
import { ResourceTypes } from '~/store/features/api/resources/types';
import { makeResourceSelector } from '~/store/features/api/selectors';
import { SelectorOptions } from '~/store/features/api/selectors/selectors';

export function useLoop(
  loopId?: string,
  options?: SelectorOptions
): LoopResource | null {
  const { loopId: pathLoopId } = useParams();
  const id = loopId ? loopId : pathLoopId;

  const selectLoop = useMemo(() => {
    return makeResourceSelector<LoopResource>(
      ResourceTypes.Loops,
      () => id,
      options
    );
  }, [id, options]);
  const loop = useAppSelector((state: AppState) => selectLoop(state));

  return loop || null;
}
