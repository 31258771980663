import { ATTRIBUTE_TYPE } from '../constants';
import { ResourceDefinition } from '../types';

const LoopViewData: ResourceDefinition = {
  attributes: {
    value: ATTRIBUTE_TYPE.STRING,
    name: ATTRIBUTE_TYPE.STRING,
  },
};

export default LoopViewData;
