import styled, { keyframes } from 'styled-components';

import { BaseIconProps } from './types';
import scalableIcon from './utils/scalableIcon';

const FAST_SLOW = 'cubic-bezier(0.1, 0, 0.25, 1)';
const SLOW_FAST = 'cubic-bezier(0.75, 0, 0.9, 1)';
const innerAnimation = keyframes`
  0%, 20% {
    stroke-dashoffset: 25.1;
    animation-timing-function: ${FAST_SLOW};
  }

  30%, 60% {
    stroke-dashoffset: 12.55;
    animation-timing-function: ${SLOW_FAST};
  }

  70%, 100% {
    stroke-dashoffset: 25.1;
    animation-timing-function: ${SLOW_FAST};
  }
`;

const outerAnimation = keyframes`
  0% {
    stroke-dashoffset: 43.9;
    animation-timing-function: ${SLOW_FAST};
  }

  20%, 70% {
    stroke-dashoffset: 0;
    animation-timing-function: ${FAST_SLOW};
  }

  90%, 100% {
    stroke-dashoffset: 43.9;
    animation-timing-function: ${FAST_SLOW};
  }
`;

const flipAnimation = keyframes`
  0%, 35% {
    transform: rotate3d(0, 1, 0, 0deg);
  }

  55% {
    transform: rotate3d(0, 1, 0, 360deg);
    animation-timing-function: ${SLOW_FAST};
  }

  55.001%, 100% {
    transform: rotate3d(0, 1, 0, 0deg);
  }
`;

const StyledOuterCircle = styled.circle`
  animation: ${outerAnimation} 5s infinite;
`;

const StyledInnerCircle = styled.circle`
  animation: ${innerAnimation} 5s infinite;
`;

const StyledSvg = styled.svg<{ $strokeColor: string }>`
  stroke: ${props => props.color};
  animation: ${flipAnimation} 5s infinite;
`;

function LoadingIcon(props: BaseIconProps) {
  const { color } = props;

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      $strokeColor={color}
    >
      <StyledInnerCircle
        cx="8"
        cy="8"
        r="4"
        strokeWidth="1"
        strokeDasharray="25.1"
        strokeDashoffset="25.1"
        fill="transparent"
      />
      <StyledOuterCircle
        cx="8"
        cy="8"
        r="7"
        strokeWidth="1"
        strokeDasharray="43.9"
        strokeDashoffset="43.9"
        fill="transparent"
      />
    </StyledSvg>
  );
}

const ScalableIcon = scalableIcon(1, 1, 'stroke')(LoadingIcon);

export { ScalableIcon as LoadingIcon };
export default ScalableIcon;
