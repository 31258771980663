import datadogLookup from './datadog';
import pubnubLookup from './pubnub';
import imageResizeLookup from './imageResizeLookup';
import docTranscoderLookup from './docTranscoderLookup';
import phoenixBaseUrlLookup from './phoenixBaseUrlLookup';
import stppBaseUrlLookup from './stppBaseUrlLookup';

export type Environment = 'local' | 'dev' | 'qa' | 'stage' | 'test' | 'prod';

export function isPullRequestBuild(): boolean {
  return !!import.meta.env.VITE_PR_BUILD;
}

export function getBasename(): string {
  const mrMatch = window.location.pathname.match(/^\/merge-requests\/(\d+)/);

  return !!mrMatch ? mrMatch[0] + '/' : '/';
}

export function getLocalProxyEnv(): Environment | null {
  const localProxyEnv = import.meta.env.VITE_LOCAL_PROXY_ENV;

  if (
    localProxyEnv === 'local' ||
    localProxyEnv === 'dev' ||
    localProxyEnv === 'qa' ||
    localProxyEnv === 'stage' ||
    localProxyEnv === 'prod'
  ) {
    return localProxyEnv;
  }

  return null;
}

function isLocal(origin: string): boolean {
  return (
    origin.indexOf('localhost') >= 0 ||
    origin.indexOf('3000') >= 0 ||
    origin.indexOf('3008') >= 0 ||
    origin.indexOf('3009') >= 0
  );
}

type Host = string;
export function getOrigin(): Host {
  // Fix for IE not supporting window.location.origin
  // https://tosbourn.com/a-fix-for-window-location-origin-in-internet-explorer/
  if (!window.location.origin) {
    Object.defineProperty(window.location, 'origin', {
      writable: true,
      value: () => {
        return (
          window.location.protocol +
          '//' +
          window.location.hostname +
          (window.location.port ? ':' + window.location.port : '')
        );
      },
    });
  }

  return window.location.origin;
}

export function computeStppBaseUrl(): string {
  const env = getEnvironmentName();
  return stppBaseUrlLookup[env];
}

export function computePhoenixBaseUrl(): string {
  const origin = getOrigin();
  const isLocalOrigin = isLocal(origin);
  const isLocalProxyEnv = getLocalProxyEnv();

  if (isLocalProxyEnv) {
    const env = getEnvironmentName();
    return phoenixBaseUrlLookup[env];
  } else if (isLocalOrigin) {
    const port = window.location.port || '3000';

    return origin.replace('https', 'http').replace(port, '8080');
  } else {
    return origin.replace('https://my.', 'https://www.');
  }
}

export function computePhoenixApiBaseUrl(): string {
  const isLocalProxyEnv = getLocalProxyEnv();

  if (isLocalProxyEnv) {
    return '/my/rest/v1_0';
  } else {
    const phoenixBaseUrl = computePhoenixBaseUrl();
    return `${phoenixBaseUrl}/my/rest/v1_0`;
  }
}

export function getPhoenixDomain(): string {
  const host = window.location.hostname;
  const phoenixDomain = host
    .replace('my.', '')
    .replace('.com', '')
    .replace('test.', '');

  return phoenixDomain;
}

export function getEnvironmentName(ignoreProxyEnv?: boolean): Environment {
  const origin = getOrigin();
  const localProxyEnv = getLocalProxyEnv();
  let env;

  if (!!localProxyEnv && !ignoreProxyEnv) {
    return localProxyEnv;
  } else if (isLocal(origin)) {
    env = 'local';
  } else {
    const separatorIndex = origin.indexOf('-');

    if (separatorIndex !== -1) {
      const protocol = 'https://my.';
      const prefix = origin.substring(protocol.length, separatorIndex);

      switch (prefix) {
        case 'dev':
          env = 'dev';
          break;
        case 'qa':
          env = 'qa';
          break;
        case 'stage':
          env = 'stage';
          break;
        default:
          console.error(`ERROR: Unknown environment: ${prefix}`);
          env = 'local';
      }
    } else if (origin.indexOf('test.dotloop.com') !== -1) {
      env = 'test';
    } else {
      env = 'prod';
    }
  }

  return env as Environment;
}

const API_VERSION = 'v1_0';
const API_NAMESPACE = 'vega';
const SEARCH_NAMESPACE = 'search';
export const API_GATEWAY_LOCAL_PORT = '8888';

export function computeApiUrl() {
  const baseUrl = computePhoenixBaseUrl();
  const origin = getOrigin();
  const isLocalOrigin = isLocal(origin);
  const isLocalProxyEnv = getLocalProxyEnv();

  if (isLocalProxyEnv) {
    return `/${API_NAMESPACE}/${API_VERSION}`;
  } else if (isLocalOrigin) {
    const localOrigin = baseUrl
      .replace('https', 'http')
      .replace('8080', API_GATEWAY_LOCAL_PORT);
    return `${localOrigin}/${API_NAMESPACE}/${API_VERSION}`;
  } else {
    return `${baseUrl}/${API_NAMESPACE}/${API_VERSION}`;
  }
}

export function computeSearchApiUrl() {
  const baseUrl = computePhoenixBaseUrl();
  const origin = getOrigin();
  const isLocalOrigin = isLocal(origin);
  const isLocalProxyEnv = getLocalProxyEnv();

  if (isLocalProxyEnv) {
    return `/${API_NAMESPACE}/${API_VERSION}/${SEARCH_NAMESPACE}`;
  } else if (isLocalOrigin) {
    const localOrigin = baseUrl
      .replace('https', 'http')
      .replace('8080', API_GATEWAY_LOCAL_PORT);
    return `${localOrigin}/${API_NAMESPACE}/${API_VERSION}/${SEARCH_NAMESPACE}`;
  } else {
    return `${baseUrl}/${API_NAMESPACE}/${API_VERSION}/${SEARCH_NAMESPACE}`;
  }
}

export function computePubnubConfig() {
  const envName = getEnvironmentName(true);

  return pubnubLookup[envName];
}

export function computeDatadogRumAppId() {
  const envName = getEnvironmentName(true);

  return datadogLookup.rumAppId[envName];
}

export function computeDatadogRumClientToken() {
  const envName = getEnvironmentName(true);

  return datadogLookup.rumClientId[envName];
}

export function computeImageResizeUrl() {
  const envName = getEnvironmentName();

  return imageResizeLookup[envName];
}

export function computeDocTranscoderUrl() {
  const envName = getEnvironmentName();

  return docTranscoderLookup[envName];
}
