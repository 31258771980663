import { useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { JsonApiFetchOptions } from '~/lib/api/types';
import { AppState } from '~/store';

import { LoopRoleResource } from '~/store/features/api/resources/loopRole/types';
import { ResourceTypes } from '~/store/features/api/resources/types';
import { makeAllResourcesForQuerySelector } from '~/store/features/api/selectors';
import { fetchAllResources } from '~/store/features/api/apiSlice';

type SelectorOptions = { loopId: string };
export type RoleIdLookUp = { [roleId: string]: LoopRoleResource };
function generateLoopRolesApiOptions(loopId: string) {
  return {
    filters: {
      loop: loopId,
    },
  };
}

function generateLoopRolesApiOptionsFromState(
  _state: AppState,
  ownProps: SelectorOptions
): JsonApiFetchOptions {
  const { loopId } = ownProps;

  return generateLoopRolesApiOptions(loopId);
}

const makeLoopRolesSelect = () => {
  return makeAllResourcesForQuerySelector<LoopRoleResource, SelectorOptions>(
    ResourceTypes.LoopRoles,
    generateLoopRolesApiOptionsFromState
  );
};

export default function useLoopRole(props: SelectorOptions) {
  const { loopId } = props;
  const selectLoopRoles = useMemo(makeLoopRolesSelect, []);
  const loopRoles = useAppSelector((state: AppState) =>
    selectLoopRoles(state, props)
  );
  const dispatch = useAppDispatch();
  const getLoopRoles = useCallback(
    (loopId: string) => {
      return dispatch(
        fetchAllResources({
          resourceName: ResourceTypes.LoopRoles,
          options: generateLoopRolesApiOptions(loopId),
        })
      );
    },
    [dispatch]
  );

  const loopRoleIdLookUp: RoleIdLookUp = loopRoles.reduce(
    (lookup: RoleIdLookUp, role) => {
      lookup[role.id] = role;
      return lookup;
    },
    {}
  );
  useEffect(() => {
    if (loopRoles.length === 0) {
      getLoopRoles(loopId);
    }
  }, [getLoopRoles, loopId, loopRoles]);

  return { loopRoles, getLoopRoles, loopRoleIdLookUp };
}
