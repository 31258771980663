import styled from 'styled-components';
import { FormattedMessage } from '~/components/i18n';
import { ContactResource } from '~/store/features/api/resources/contact/types';
import { colors, spacing } from '~/styles';
import SearchContacts from './SearchContacts';

const StyledSearchContactsContainer = styled.div({
  paddingTop: spacing.normal,
});

const StyledFormHeaderContainer = styled.div({
  maxWidth: '35rem',
  '> *': {
    marginBottom: spacing.normal,
  },

  textAlign: 'center',
});

const StyledFormHeader = styled.div<{ $centered: boolean }>(props => ({
  position: 'relative',
  flex: '0 0 auto',
  background: colors.backgroundGray,
  padding: spacing.larger,
  display: props.$centered ? 'flex' : 'block',
  justifyContent: props.$centered ? 'center' : 'start',
}));

type Props = {
  importContactInformation: (contact: ContactResource) => void;
  descriptionId: string;
  centered?: boolean;
};

const SearchContactsSection = ({
  importContactInformation,
  descriptionId,
  centered = false,
}: Props) => (
  <StyledFormHeader $centered={centered}>
    <StyledFormHeaderContainer>
      <FormattedMessage id={descriptionId} />
      <StyledSearchContactsContainer>
        <SearchContacts importContact={importContactInformation} />
      </StyledSearchContactsContainer>
    </StyledFormHeaderContainer>
  </StyledFormHeader>
);

export default SearchContactsSection;
