import { FormattedMessage } from '~/components/i18n';

import { FieldType } from '~/store/features/api/resources/documentRevision/constants';

import { DocumentFieldChange } from '~/store/features/api/resources/documentRevision/types';
import { useI18n } from '~/hooks';

type Props = { fieldChange: DocumentFieldChange };

function isCheckableField(fieldType: FieldType) {
  return fieldType === FieldType.Checkbox || fieldType === FieldType.Radio;
}

function SignatureOrInitialFieldChange({
  fieldChange: { fieldType, type, oldValue, newValue },
}: Props) {
  let key;

  if (fieldType === FieldType.Signature) {
    switch (type) {
      case 'ADDED':
        key = !!newValue ? 'fieldAddedWithSignature' : 'fieldAdded';
        break;
      case 'REMOVED':
        key = !!oldValue ? 'fieldRemovedWithSignature' : 'fieldRemoved';
        break;
      default:
        key = !!newValue
          ? 'fieldChangeSignatureSigned'
          : 'fieldChangeSignatureCleared';
    }
  } else {
    switch (type) {
      case 'ADDED':
        key = !!newValue ? 'fieldAddedWithInitial' : 'fieldAdded';
        break;
      case 'REMOVED':
        key = !!oldValue ? 'fieldRemovedWithInitial' : 'fieldRemoved';
        break;
      default:
        key = !!newValue
          ? 'fieldChangeInitialSigned'
          : 'fieldChangeInitialCleared';
    }
  }

  return (
    <FormattedMessage
      id={`messaging.documentFieldChanges.${key}`}
      values={{
        fieldType: (
          <FormattedMessage
            id={`messaging.documentFieldChanges.fieldTypes.${fieldType}`}
          />
        ),
      }}
    />
  );
}

function PlainFieldChange({
  fieldChange: { fieldType, fieldLabel, oldValue, newValue, type },
}: Props) {
  const i18n = useI18n();
  const isCheckable = isCheckableField(fieldType);

  if (isCheckable) {
    oldValue =
      !!oldValue || !!newValue
        ? i18n.t(
            `messaging.documentFieldChanges.${
              oldValue === 'true' ? 'fieldChecked' : 'fieldUnchecked'
            }`
          )
        : oldValue;
    newValue =
      !!oldValue || !!newValue
        ? i18n.t(
            `messaging.documentFieldChanges.${
              newValue === 'true' ? 'fieldChecked' : 'fieldUnchecked'
            }`
          )
        : newValue;
  }

  const values = {
    fieldType: !!fieldLabel ? (
      fieldLabel
    ) : (
      <FormattedMessage
        id={`messaging.documentFieldChanges.fieldTypes.${fieldType}`}
      />
    ),
    oldFieldValue: oldValue,
    newFieldValue: <strong>{newValue}</strong>,
  };
  let key;

  switch (type) {
    case 'ADDED':
      key = !!newValue ? 'fieldAddedWithValue' : 'fieldAdded';
      break;
    case 'REMOVED':
      key = !!oldValue ? 'fieldRemovedWithValue' : 'fieldRemoved';
      break;
    default:
      if (!oldValue && !!newValue) {
        key = 'fieldChangeFromEmpty';
      } else {
        key = 'fieldChange';

        if (!isCheckable && !newValue) {
          // Value was removed, so show the old value
          // with a strikethrough
          values.newFieldValue = (
            <strong>
              <s>{oldValue}</s>
            </strong>
          );
        }
      }
  }

  return (
    <FormattedMessage
      id={`messaging.documentFieldChanges.${key}`}
      values={values}
    />
  );
}

export function DocumentFieldChangeComponent(props: Props) {
  const {
    fieldChange: { fieldType },
  } = props;

  if (fieldType === FieldType.Signature || fieldType === FieldType.Initial) {
    return <SignatureOrInitialFieldChange {...props} />;
  } else {
    return <PlainFieldChange {...props} />;
  }
}

export default DocumentFieldChangeComponent;
