import { createContext } from 'react';

import { PlainFileList } from './utils';

export type ContextValue = {
  isActive: boolean;
  files: PlainFileList;
};

export const DragDropContext = createContext<ContextValue>({
  isActive: false,
  files: [],
});
