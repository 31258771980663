import format from 'date-fns/format';
import isValid from 'date-fns/is_valid';
import parse from 'date-fns/parse';
import { pluralize } from 'inflection';
import { isPlainObject } from 'lodash-es';

import config from '~/config';
import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from './resources/constants';
import { AttributeType, ResourceDefinition } from './resources/types';
import {
  ApiResource,
  ApiCreateResource,
  ResourceIdentifier,
  ResourceAttributes,
  ResourceRelationships,
  ResourceRelationshipLink,
} from '~/lib/api/types';
import { WritableDraft } from 'immer/dist/internal';
import { ApiState } from './types';

const {
  ui: { isProductionBuild },
} = config;

export function camelize(dashCaseString: string): string {
  return dashCaseString.replace(/-([a-z])/g, function (match) {
    return match[1].toUpperCase();
  });
}

export function dasherize(camelizedCaseString: string): string {
  return camelizedCaseString.replace(/[A-Z]/g, function (char, index) {
    return (index !== 0 ? '-' : '') + char.toLowerCase();
  });
}

export function getSerializedResourceType(resourceName: string) {
  return camelize(pluralize(resourceName));
}

export function getDeserializedResourceType(type: string) {
  return pluralize(camelize(type));
}

export function serializeNew(
  resourceName: string,
  resourceAttributes: ResourceAttributes,
  resourceRelationships?: ResourceRelationshipLink,
  definition?: ResourceDefinition,
  state?: WritableDraft<ApiState>
): {
  serializedResource: ApiCreateResource;
  includedResources: Array<ApiResource | ResourceIdentifier>;
} {
  const attributes = serializeAttrs(
    resourceName,
    resourceAttributes,
    definition
  );
  const { relationships, includedResources } = serializeRelationships(
    resourceName,
    resourceRelationships,
    definition,
    state
  );
  const result: ApiCreateResource = {
    type: getSerializedResourceType(resourceName),
    attributes,
  };

  if (!!relationships) {
    result.relationships = relationships;
  }

  return { serializedResource: result, includedResources };
}

export function serializeExisting(
  id: string,
  resourceName: string,
  resourceAttributes: ResourceAttributes,
  resourceRelationships?: ResourceRelationshipLink | null,
  definition?: ResourceDefinition,
  state?: ApiState
): ApiResource {
  const attributes = serializeAttrs(
    resourceName,
    resourceAttributes,
    definition
  );

  const { relationships } = serializeRelationships(
    resourceName,
    resourceRelationships,
    definition,
    state
  );
  const result: ApiResource = {
    id,
    attributes,
    type: getSerializedResourceType(resourceName),
  };

  if (!!relationships) {
    result.relationships = relationships;
  }

  return result;
}

function serializeAttrs(
  resourceName: string,
  resourceAttributes: ResourceAttributes,
  definition?: ResourceDefinition
): ResourceAttributes {
  const initialAttributes: ResourceAttributes = {};
  return Object.keys(resourceAttributes).reduce((currentAttributes, key) => {
    if (key !== 'id') {
      const dasherizedKey = camelize(key);
      const attribute = resourceAttributes[key];
      const attrDefinition =
        definition && definition.attributes ? definition.attributes[key] : null;

      if (attrDefinition) {
        currentAttributes[dasherizedKey] = serializeAttribute(
          attribute,
          attrDefinition
        );
      } else {
        if (!isProductionBuild) {
          console.warn(
            `Encountered unknown attribute for ${resourceName}: ${key}`
          );
        }

        currentAttributes[dasherizedKey] = attribute;
      }
    }

    return currentAttributes;
  }, initialAttributes);
}

export function serializeRelationships(
  resourceName: string,
  resourceRelationships?: ResourceRelationshipLink | null,
  definition?: ResourceDefinition,
  state?: ApiState
): {
  relationships: ResourceRelationships | null | undefined;
  includedResources: Array<ApiResource | ResourceIdentifier>;
} {
  let includedResources: Array<ApiResource | ResourceIdentifier> = [];
  let result;

  const _resourceRelationships = resourceRelationships;
  if (definition && definition.relationships && _resourceRelationships) {
    const relationshipDefinitions = definition.relationships;
    const initialRelationships: ResourceRelationships = {};

    result = Object.keys(_resourceRelationships).reduce(
      (relationships, currentRelationship) => {
        const value = _resourceRelationships[currentRelationship];
        const relationshipDefinition =
          relationshipDefinitions[currentRelationship];

        if (relationshipDefinition) {
          const relationshipType = relationshipDefinition.relationshipType;
          const relationshipResourceType = relationshipDefinition.resourceType;
          const isSingle = relationshipType === RELATIONSHIP_TYPE.ONE;
          let relationshipValue = value;

          if (isSingle && Array.isArray(value)) {
            if (value.length === 1) {
              relationshipValue = value[0];
            } else if (!isProductionBuild) {
              console.warn(
                `You passed in a relationship that is of type ${relationshipType} but the value does not match this type`
              );
            }
          } else if (!isSingle) {
            if (!Array.isArray(value)) {
              relationshipValue = [value];
            }
          }

          relationships[currentRelationship] = {};

          if (
            !isSingle &&
            Array.isArray(relationshipValue) &&
            relationshipValue.length > 0
          ) {
            if (typeof relationshipValue[0] === 'object') {
              includedResources = relationshipValue.map(currentValue => {
                if (typeof currentValue === 'object') {
                  const { id, type, attributes } = currentValue;
                  const possibleResourceTypes = Array.isArray(
                    relationshipResourceType
                  )
                    ? relationshipResourceType
                    : [relationshipResourceType];
                  const targetResourceType = possibleResourceTypes.find(
                    t => t === type
                  );

                  if (state && targetResourceType) {
                    const relationshipStateResource =
                      state.resources[targetResourceType];
                    const relationshipDefinition =
                      relationshipStateResource.__definition__;

                    return serializeExisting(
                      id,
                      targetResourceType,
                      attributes,
                      null,
                      relationshipDefinition
                    );
                  } else if (targetResourceType) {
                    if (!isProductionBuild) {
                      console.warn(
                        `You passed in a relationship resource with an unknown type: "${targetResourceType}"`
                      );
                    }

                    return { id, type: targetResourceType };
                  } else {
                    return { id, type: 'UNKNOWN' };
                  }
                } else if (!Array.isArray(relationshipResourceType)) {
                  return { id: currentValue, type: relationshipResourceType };
                } else {
                  return { id: currentValue, type: 'UNKNOWN' };
                }
              });
            }

            relationships[currentRelationship].data = relationshipValue.map(
              currentValue => {
                if (
                  Array.isArray(relationshipResourceType) &&
                  typeof currentValue !== 'object'
                ) {
                  throw new Error(
                    `Unable to automatically create polymorphic relationship data from primitive value. Relationship types: ${relationshipResourceType.join(
                      ', '
                    )}`
                  );
                }

                return {
                  type: Array.isArray(relationshipResourceType)
                    ? (currentValue as ApiResource).type
                    : relationshipResourceType,
                  id:
                    typeof currentValue === 'object'
                      ? currentValue.id
                      : currentValue,
                };
              }
            );
          } else if (isSingle && !Array.isArray(relationshipValue)) {
            if (typeof relationshipValue === 'object') {
              const { id, type, attributes } = relationshipValue;
              const possibleResourceTypes = Array.isArray(
                relationshipResourceType
              )
                ? relationshipResourceType
                : [relationshipResourceType];
              const targetResourceType = possibleResourceTypes.find(
                t => t === type
              );

              if (state && targetResourceType) {
                const relationshipStateResource =
                  state.resources[targetResourceType];
                const relationshipDefinition =
                  relationshipStateResource.__definition__;

                includedResources = [
                  serializeExisting(
                    id,
                    targetResourceType,
                    attributes,
                    null,
                    relationshipDefinition
                  ),
                ];
              } else {
                if (!isProductionBuild) {
                  console.warn(
                    `You passed in a relationship resource with an unknown type: "${relationshipResourceType}"`
                  );
                }
              }
            } else if (Array.isArray(relationshipResourceType)) {
              throw new Error(
                `Unable to automatically create polymorphic relationship data from primitive value. Relationship types: ${relationshipResourceType.join(
                  ', '
                )}`
              );
            }

            relationships[currentRelationship].data = {
              type: Array.isArray(relationshipResourceType)
                ? (relationshipValue as ApiResource).type
                : relationshipResourceType,
              id:
                typeof relationshipValue === 'object'
                  ? relationshipValue.id
                  : relationshipValue,
            };
          } else if (!isProductionBuild) {
            console.warn(
              `You passed in a relationship that is of type "${relationshipType}" but the value does not match this type`
            );
          }
        } else if (!isProductionBuild) {
          console.warn(
            'There is no relationship defined for the passed in relationship data'
          );
        }

        return relationships;
      },
      initialRelationships
    );
  } else if (_resourceRelationships && !isProductionBuild) {
    console.warn(
      `No relationship definition set for resource "${resourceName}" but relationships were passed in`
    );
  }

  return { relationships: result, includedResources };
}

export function deserialize(
  resource: ApiResource,
  definition?: ResourceDefinition
) {
  //JSONApi does not prescribe inflection rules, but we want to pluralize all types to be consistent
  if (resource.type) {
    resource.type = getDeserializedResourceType(resource.type);
  }

  if (resource.relationships) {
    const relationships = resource.relationships;
    resource.relationships = Object.keys(relationships).reduce(
      (currentRelationships, relationshipKey) => {
        const camelRelationshipKey = camelize(relationshipKey);

        return {
          ...currentRelationships,
          [camelRelationshipKey]: relationships[relationshipKey],
        };
      },
      {}
    );
  }

  return Object.keys(resource.attributes).reduce((currentResource, key) => {
    const camelKey = camelize(key);
    const attrDefinition = definition ? definition.attributes[camelKey] : null;
    let updatedResource;
    let attributeValue = resource.attributes[key];

    if (key === 'id') {
      attributeValue = parseInt(attributeValue, 10);
    }

    if (attrDefinition) {
      updatedResource = {
        ...currentResource,
        attributes: {
          ...currentResource.attributes,
          [camelKey]: deserializeAttribute(attributeValue, attrDefinition),
        },
      };
    } else {
      if (!isProductionBuild) {
        console.warn(
          `Encountered unknown attribute for ${currentResource.type}: ${key}`
        );
      }
      updatedResource = {
        ...currentResource,
        attributes: {
          ...currentResource.attributes,
          [camelKey]: attributeValue,
        },
      };
    }

    if (camelKey !== key) {
      delete updatedResource.attributes[key];
    }

    return updatedResource;
  }, resource);
}

export function serializeAttribute(
  attribute: any,
  attributeType: AttributeType
) {
  switch (attributeType) {
    case ATTRIBUTE_TYPE.DATE:
      return serializeDate(attribute);
    case ATTRIBUTE_TYPE.DATE_TIME:
      return serializeDateTime(attribute);
    case ATTRIBUTE_TYPE.JSON_STRING:
      return serializeJsonString(attribute);
    default:
      return attribute;
  }
}

const DATE_FORMAT = 'YYYY-MM-DD';
function serializeDate(attribute: Date): string | null {
  return attribute ? format(attribute, DATE_FORMAT) : null;
}

function serializeDateTime(attribute: Date): string | null {
  return attribute ? attribute.toISOString() : null;
}

function serializeJsonString(attribute: any) {
  let result = attribute;
  const attributeType = typeof attribute;

  try {
    if (attributeType === 'object') {
      result = JSON.stringify(attribute);
    }
  } catch (e) {}

  return result;
}

function deserializeAttribute(attribute: any, attributeType: AttributeType) {
  switch (attributeType) {
    case ATTRIBUTE_TYPE.STRING:
      return deserializeString(attribute);
    case ATTRIBUTE_TYPE.DATE:
      return deserializeDate(attribute);
    case ATTRIBUTE_TYPE.DATE_TIME:
      return deserializeDateTime(attribute);
    case ATTRIBUTE_TYPE.NUMBER:
      return deserializeNumber(attribute);
    case ATTRIBUTE_TYPE.BOOLEAN:
      return deserializeBoolean(attribute);
    case ATTRIBUTE_TYPE.JSON_STRING:
      return deserializeJsonString(attribute);
    default:
      if (isPlainObject(attribute)) {
        const initialAttributes: ResourceAttributes = {};
        attribute = Object.keys(attribute).reduce((currentAttribute, key) => {
          const camelKey = camelize(key);

          currentAttribute[camelKey] = attribute[key];

          return currentAttribute;
        }, initialAttributes);
      }
      return attribute;
  }
}

function deserializeJsonString(attribute: any) {
  let result = attribute;

  if (attribute !== null && attribute !== undefined) {
    try {
      result = JSON.parse(attribute);
    } catch (e) {}
  }

  return result;
}

function deserializeString(attribute: any) {
  if (attribute !== null && attribute !== undefined) {
    return attribute.toString();
  }

  return attribute;
}

function deserializeNumber(attribute: any) {
  const type = typeof attribute;

  if (type === 'number') {
    return attribute;
  } else if (type === 'string') {
    if (attribute.indexOf('.') !== -1) {
      return parseFloat(attribute);
    } else {
      return parseInt(attribute, 10);
    }
  } else if (attribute === null) {
    return null;
  } else {
    return parseFloat(attribute);
  }
}

function deserializeBoolean(attribute: any) {
  const type = typeof attribute;

  if (type === 'boolean') {
    return attribute;
  } else if (type === 'string') {
    return attribute.match(/^[T|t]rue$/i) !== null;
  } else if (type === 'number') {
    return attribute === 1;
  } else {
    return false;
  }
}

function deserializeDate(attribute: any) {
  if (attribute === undefined) {
    return attribute;
  } else {
    const type = typeof attribute;

    if (type === 'number' || type === 'string') {
      const date = parse(attribute);

      return isValid(date) ? date.toISOString() : undefined;
    } else {
      return undefined;
    }
  }
}

function deserializeDateTime(attribute: string) {
  if (attribute === undefined) {
    return attribute;
  } else {
    const date = parse(attribute);

    return isValid(date) ? date.toISOString() : undefined;
  }
}
