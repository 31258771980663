enum stppBaseUrlLookup {
  local = 'http://localhost:3001',
  dev = 'https://dev-app.showingtimeplus.net/pro',
  qa = 'https://app.showingtimeplus.net/pro',
  stage = '',
  test = '',
  prod = 'https://app.showingtimeplus.com/pro',
}

export default stppBaseUrlLookup;
