import { ComponentType, createElement } from 'react';
import { IconProps } from '~/components/icons/types';
import { ColorPalette } from '~/styles/colors/types';
import styled from 'styled-components';
import { breakpoints, colors, spacing } from '~/styles';
import { toRem } from '~/styles/utilities';

// Header Icon Sizing
const iconWrapperSizePx = 52;
const iconSizePx = iconWrapperSizePx * 0.375; // Maintaining original ratio
const iconWrapperSize = toRem(iconWrapperSizePx);
const iconSize = toRem(iconSizePx);

const StyledHeaderIconWrapper = styled.div<{
  $type?: keyof ColorPalette;
}>(props => ({
  border: `2px solid ${
    !!props?.$type ? colors[props.$type] : colors.primaryAction
  }`,

  borderRadius: '50%',
  width: iconWrapperSize,
  height: iconWrapperSize,
  flex: `0 0 ${iconWrapperSize}`,
  position: 'relative',
  marginRight: spacing.largest,

  [breakpoints.MEDIUM]: {
    marginRight: `calc(${spacing.largest} - ${spacing.smallest})`,
  },
}));

const StyledHeaderIcon = styled.div({
  width: iconSize,
  height: iconSize,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

type Props = {
  icon: ComponentType<IconProps>;
  iconProps?: IconProps;
};

export function EncircledIcon({ icon, iconProps = {} }: Props) {
  const props = Object.assign(
    {},
    { height: '100%', type: 'primaryButton' },
    iconProps
  );
  const IconElement = createElement(icon, props);

  return (
    <StyledHeaderIconWrapper $type={iconProps?.type}>
      <StyledHeaderIcon>{IconElement}</StyledHeaderIcon>
    </StyledHeaderIconWrapper>
  );
}

export default EncircledIcon;
