import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';
import { ResourceDefinition, ResourceTypes } from '../types';

const ShareWorkflow: ResourceDefinition = {
  attributes: {
    name: ATTRIBUTE_TYPE.STRING,
    sharerDisplayName: ATTRIBUTE_TYPE.STRING,
    currentRevision: ATTRIBUTE_TYPE.NUMBER,
    sharerUserId: ATTRIBUTE_TYPE.NUMBER,
    sharerViewId: ATTRIBUTE_TYPE.NUMBER,
    sharerMemberId: ATTRIBUTE_TYPE.NUMBER,
    documentId: ATTRIBUTE_TYPE.STRING,
    documents: ATTRIBUTE_TYPE.AS_IS,
    workflowStatus: ATTRIBUTE_TYPE.STRING,
    message: ATTRIBUTE_TYPE.STRING,
    createdDate: ATTRIBUTE_TYPE.DATE,
    workflowSteps: ATTRIBUTE_TYPE.AS_IS,
    attachPdf: ATTRIBUTE_TYPE.BOOLEAN,
    formattedCreatedDate: ATTRIBUTE_TYPE.STRING,
    shareWorkflowTemplateId: ATTRIBUTE_TYPE.NUMBER,
    templateModified: ATTRIBUTE_TYPE.BOOLEAN,
    withdrawalMessage: ATTRIBUTE_TYPE.STRING,
    deliveryType: ATTRIBUTE_TYPE.AS_IS,
    receivedPacketStatus: ATTRIBUTE_TYPE.AS_IS,
    sentPacketStatus: ATTRIBUTE_TYPE.AS_IS,
  },
  relationships: {
    documents: {
      resourceType: ResourceTypes.Documents,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
    workflowSteps: {
      resourceType: ResourceTypes.ShareWorkflowSteps,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
  },
};

export default ShareWorkflow;
