import {
  computeApiUrl,
  computeSearchApiUrl,
  computePhoenixBaseUrl,
  computePhoenixApiBaseUrl,
  computeDatadogRumClientToken,
  computeDatadogRumAppId,
  computePubnubConfig,
  computeImageResizeUrl,
  computeDocTranscoderUrl,
  getEnvironmentName,
  getPhoenixDomain,
  getOrigin,
  getBasename,
  getLocalProxyEnv,
  isPullRequestBuild,
  computeStppBaseUrl,
} from './computeEnvironmentConfig';

const IS_PRODUCTION = import.meta.env.PROD;

const config = {
  api: {
    baseUrl: computeApiUrl(),
    phoenixBaseUrl: computePhoenixApiBaseUrl(),
    baseSearchUrl: computeSearchApiUrl(),
    imageResizeUrl: computeImageResizeUrl(),
    docTranscoderUrl: computeDocTranscoderUrl(),
    localProxyEnv: getLocalProxyEnv(),
  },
  ui: {
    stppBaseUrl: computeStppBaseUrl(),
    phoenixUrl: computePhoenixBaseUrl(),
    phoenixDomain: getPhoenixDomain(),
    isPullRequestBuild: isPullRequestBuild(),
    datadogRumAppId: computeDatadogRumAppId(),
    datadogRumClientToken: computeDatadogRumClientToken(),
    environment: getEnvironmentName(),
    pubnub: computePubnubConfig(),
    origin: getOrigin(),
    basename: getBasename(),
    isProductionBuild: IS_PRODUCTION,
    isTestBuild: import.meta.env.MODE === 'test',
    useDevFeatureFlags:
      !IS_PRODUCTION || import.meta.env.VITE_DEV_FLAGS === 'true',
    version: import.meta.env.VITE_NOVA_VERSION as string,
  },
  analytics: {
    pendoApiKey: 'ac94a39f-2e01-4c63-59e7-8bc22df99834',
  },
};

export default config;
