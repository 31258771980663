enum docTranscoderLookup {
  local = 'https://docs.dev-dotloop.com',
  dev = 'https://docs.dev-dotloop.com',
  qa = 'https://docs.qa-dotloop.com',
  stage = 'https://docs.stage-dotloop.com',
  test = 'https://docs.dotloop.com',
  prod = 'https://docs.dotloop.com',
}

export default docTranscoderLookup;
