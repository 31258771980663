import { Fragment } from 'react';
import styled from 'styled-components';

import { FormattedMessage } from '~/components/i18n';
import { colors, fontSizes, fontWeights, spacing, utils } from '~/styles';

import { LoopResource } from '~/store/features/api/resources/loop/types';

const StyledComplianceListSeparator = styled.span({
  ...fontSizes.body,
  display: 'inline-block',
  lineHeight: spacing.normal,
  margin: `0 ${spacing.smallest}`,
  fontWeight: fontWeights.semiBold,
});

const StyledComplianceList = styled.div({
  ...utils.text.truncate,
  fontWeight: fontWeights.semiBold,
  color: colors.secondaryText,
});

type Props = {
  loopData: LoopResource;
};

function LoopCardComplianceStatuses({ loopData }: Props) {
  const complianceStatusNames = !!loopData.complianceStatusNames
    ? loopData.complianceStatusNames
    : [];
  const hasComplianceStatusNames = complianceStatusNames.length > 0;

  return (
    <StyledComplianceList>
      {hasComplianceStatusNames ? (
        loopData.complianceStatusNames.map((status, index) => (
          <Fragment key={status}>
            {status}
            {index !== loopData.complianceStatusNames.length - 1 && (
              <StyledComplianceListSeparator>
                &middot;
              </StyledComplianceListSeparator>
            )}
          </Fragment>
        ))
      ) : (
        <FormattedMessage id="loops:card.reviewNotSubmitted" />
      )}
    </StyledComplianceList>
  );
}

export default LoopCardComplianceStatuses;
