import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';
import {
  ResourceDefinition,
  ResourceTypes,
} from '~/store/features/api/resources/types';

const TemplateTaskList: ResourceDefinition = {
  attributes: {
    name: ATTRIBUTE_TYPE.STRING,
    restricted: ATTRIBUTE_TYPE.BOOLEAN,
    locked: ATTRIBUTE_TYPE.BOOLEAN,
    sourceTaskListId: ATTRIBUTE_TYPE.STRING,
  },
  relationships: {
    items: {
      resourceType: ResourceTypes.TemplateTaskListItems,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
  },
};
export default TemplateTaskList;
