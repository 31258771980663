import { useContext } from 'react';

import { I18nContext } from '~/components/i18n/context';
import { I18nType } from '~/components/i18n/types';

export function useI18n(): I18nType {
  const { i18n } = useContext(I18nContext);

  return i18n;
}
