import { useState, useCallback, useLayoutEffect } from 'react';
import { noop } from 'lodash-es';

import { usePortalContext } from '~/components/Portal';
import { getWindowScrollAttributes } from '~/lib/utils/scroll';

import useWindowSize from './useWindowSize';
import { useOutsideClick } from './useOutsideClick';

export enum Position {
  TopRight = 'top-right',
  TopLeft = 'top-left',
  BottomRight = 'bottom-right',
  BottomLeft = 'bottom-left',
}

export type PositionStyles = {
  top?: number | string;
  right?: number | string;
  bottom?: number | string;
  left?: number | string;
  minWidth?: number;
  width?: number;
  transform?: string;
};

function createPositionStyles(
  popoverElement: HTMLElement | null | undefined,
  offsetElement: HTMLElement | null,
  scrollContainerRef: HTMLElement | null,
  position: Position,
  fullWidthMenu: boolean
): PositionStyles {
  // Initially position off screen, so scrollbars are not effected during calculation
  const positionStyles: PositionStyles = {};

  if (~position.indexOf('top')) {
    positionStyles.top = 0;
    positionStyles.transform = 'translateY(-100%)';
  } else {
    positionStyles.bottom = 0;
    positionStyles.transform = 'translateY(100%)';
  }

  if (~position.indexOf('left')) {
    positionStyles.left = 0;
  } else {
    positionStyles.left = 'unset';
    positionStyles.right = 0;
  }

  if (fullWidthMenu && offsetElement) {
    positionStyles.width = offsetElement.clientWidth;
  }

  if (!!popoverElement && !!offsetElement) {
    const popoverOffsetParent = popoverElement.offsetParent;
    const offsetParent = offsetElement.offsetParent;
    let { scrollOffset } = getWindowScrollAttributes();

    if (!!offsetParent && offsetParent instanceof HTMLElement) {
      positionStyles.minWidth = offsetParent.offsetWidth;

      if (popoverOffsetParent !== offsetParent) {
        // The contents of the popover are being rendered in a place where the
        // offset parent is different than the main offset parent, which is
        // what we use to determine positioning. This means we need to add in the
        // position of the main offset parent to the position styles
        const offsetCoords = offsetParent.getBoundingClientRect();
        const popoverOffsetCoords = !!popoverOffsetParent
          ? popoverOffsetParent.getBoundingClientRect()
          : document.body.getBoundingClientRect();

        if (!!scrollContainerRef) {
          scrollOffset = scrollContainerRef.scrollTop;
        }

        if (positionStyles.top === 0) {
          // This is a top positioned popover, so the top of the contents needs to match
          // the top of the main offset parent
          positionStyles.top = offsetCoords.top + scrollOffset;
        } else {
          // This is a bottom positioned popover, so the bottom of the contents needs to match
          // the bottom of the main offset parent
          delete positionStyles.bottom;
          positionStyles.top = offsetCoords.bottom + scrollOffset;
          positionStyles.transform = '';
        }

        if (!!scrollContainerRef) {
          positionStyles.top -= Math.max(popoverOffsetCoords.top, 0);
        }

        if (positionStyles.left === 0) {
          // This is a left positioned popover, so the left of the contents needs to match
          // the left of the main offset parent
          positionStyles.left = offsetCoords.left;
        } else {
          // This is a right positioned popover, so the left of the contents needs to match
          // the right of the main offset parent
          delete positionStyles.right;
          positionStyles.left = offsetCoords.right;
          positionStyles.transform += ' translateX(-100%)';
        }

        if (!!scrollContainerRef) {
          positionStyles.left -= Math.max(popoverOffsetCoords.left, 0);
        }
      }
    }
  }

  return positionStyles;
}

const BOUNDS_BUFFER = 20;
const DEFAULT_POSITION = Position.BottomLeft;
function getPopoverPosition(
  popoverElement: HTMLElement | null | undefined,
  offsetElement: HTMLElement | null,
  scrollContainerRef: HTMLElement | null,
  defaultPosition?: Position,
  restrictToBounds?: boolean
): Position {
  let position = defaultPosition;

  if (!!popoverElement) {
    let {
      scrollOffset,
      applicationHeight: height,
      windowWidth: width,
    } = getWindowScrollAttributes();
    let topEdge = 0;
    let leftEdge = 0;

    if (!!scrollContainerRef) {
      scrollOffset = scrollContainerRef.scrollTop;
      width = scrollContainerRef.scrollWidth;
      height = scrollContainerRef.scrollHeight;

      if (restrictToBounds) {
        const scrollRect = scrollContainerRef.getBoundingClientRect();

        topEdge = scrollRect.top;
        leftEdge = scrollRect.left;
      }
    }

    if (!!popoverElement && !!offsetElement) {
      const offsetParent = offsetElement.offsetParent;

      if (!!offsetParent && offsetParent instanceof HTMLElement) {
        const dimensions = offsetParent.getBoundingClientRect();

        const popoverTopBounds =
          dimensions.top + scrollOffset - popoverElement.clientHeight;
        const popoverBottomBounds =
          dimensions.bottom + scrollOffset + popoverElement.clientHeight;
        const popoverLeftBounds = dimensions.right - popoverElement.clientWidth;
        const popoverRightBounds = dimensions.left + popoverElement.clientWidth;

        const overflowsTop = popoverTopBounds - BOUNDS_BUFFER <= topEdge;
        const overflowsBottom = popoverBottomBounds + BOUNDS_BUFFER >= height;
        const overflowsLeft = popoverLeftBounds - BOUNDS_BUFFER <= leftEdge;
        const overflowsRight = popoverRightBounds + BOUNDS_BUFFER >= width;
        const validPositions: Array<Position> = [];

        // Ordering of these if statements dictates the preference order for anchoring:
        // * bottom-left
        // * bottom-right
        // * top-left
        // * top-right
        if (!overflowsRight && !overflowsBottom) {
          validPositions.push(Position.BottomLeft);
        }

        if (!overflowsLeft && !overflowsBottom) {
          validPositions.push(Position.BottomRight);
        }

        if (!overflowsRight && !overflowsTop) {
          validPositions.push(Position.TopLeft);
        }

        if (!overflowsLeft && !overflowsTop) {
          validPositions.push(Position.TopRight);
        }

        if (
          !defaultPosition ||
          (validPositions.length > 0 &&
            validPositions.indexOf(defaultPosition) === -1)
        ) {
          // No defaultPosition was given, or the default position given
          // is not valid, so use the first valid position
          position = validPositions[0];
        }
      }
    }
  }

  if (!position) {
    position = DEFAULT_POSITION;
  }

  return position;
}

interface Props {
  /** Element that the popover is using as a reference for position calculations */
  popoverRef: HTMLElement | null | undefined;
  /** Open state of the popover */
  isOpen: boolean;
  /** Force the menu to be as wide as the parent */
  fullWidthMenu?: boolean;
  /** The default anchor position of the dropdown */
  defaultPosition?: Position;
  /** HTML ID of the element that triggers the opening of this dropdown */
  triggerId: string;
  /** Callback invoked when the popover should close (outside click) */
  onRequestClose: (e: MouseEvent | TouchEvent) => any;
  /**
   * If `true`, ignore any portal element context that might exist in the hierarchy
   */
  disregardPortal?: boolean;
}

export function usePopover({
  popoverRef,
  isOpen,
  triggerId,
  defaultPosition,
  onRequestClose = noop,
  fullWidthMenu = false,
  disregardPortal = true,
}: Props) {
  const [windowSize, previousWindowSize] = useWindowSize();
  const portalContext = usePortalContext();
  const portalElement = disregardPortal ? null : portalContext?.element ?? null;
  const restrictToBounds = portalContext?.restrictToBounds ?? false;
  const [positionStyles, setPositionStyles] = useState<PositionStyles | null>(
    null
  );
  const [position, setPosition] = useState<Position | null>(null);

  useOutsideClick(popoverRef, onRequestClose);

  const updatePosition = useCallback(() => {
    const popoverElement = popoverRef;
    const offsetElement = document.getElementById(triggerId);
    const position = getPopoverPosition(
      popoverElement,
      offsetElement,
      portalElement,
      defaultPosition,
      restrictToBounds
    );
    const positionStyles = createPositionStyles(
      popoverElement,
      offsetElement,
      portalElement,
      position,
      fullWidthMenu
    );

    setPositionStyles(positionStyles);
    setPosition(position);
  }, [
    defaultPosition,
    fullWidthMenu,
    portalElement,
    restrictToBounds,
    triggerId,
    popoverRef,
  ]);

  // When the window size changes, handle updating the position:
  // * If the popover is open, recalculate position immediately
  // * If the popover is closed, reset the position so it recalculates on next open
  useLayoutEffect(() => {
    const windowSizeChanged =
      previousWindowSize !== null && windowSize !== previousWindowSize;

    if (windowSizeChanged && position !== null) {
      updatePosition();
    }
  }, [windowSize, previousWindowSize, position, updatePosition]);
  // Observe when the triggering element changes position, indicating
  // we need to update the popover's position
  useLayoutEffect(() => {
    const offsetElement = document.getElementById(triggerId);
    let resizeObserver: ResizeObserverInterface;
    let requestId: number;
    let cleanup = () => {};

    if (offsetElement !== null) {
      resizeObserver = new ResizeObserver(entries => {
        requestId = window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          updatePosition();
        });
      });

      resizeObserver.observe(offsetElement, { box: 'border-box' });

      cleanup = () => {
        resizeObserver.unobserve(offsetElement);
        resizeObserver.disconnect();
        window.cancelAnimationFrame(requestId);
      };
    }

    return cleanup;
  }, [updatePosition, triggerId]);

  useLayoutEffect(() => {
    if (isOpen) {
      updatePosition();
    }
  }, [isOpen, updatePosition]);

  return { positionStyles, position };
}
