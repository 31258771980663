import styled from 'styled-components';

import SimpleLoadingIndicator from '~/components/SimpleLoadingIndicator';
import { PADDING_XSM } from '~/components/layout/Container';

import { STEP_CONTAINER_HEIGHT } from './constants';

import { breakpoints, fontSizes, fontWeights, spacing } from '~/styles';

const StyledLoadingText = styled.div({
  ...fontSizes.headline,
  position: 'absolute',
  top: '50%',
  left: '50%',
  fontWeight: fontWeights.semiBold,
  transform: 'translate(-50%, 50%) translateY(3rem)',
});

const StyledLoadingWrapper = styled.div({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  pointerEvents: 'none',

  [breakpoints.MEDIUM]: {
    position: 'absolute',
  },
});

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 auto',
  marginLeft: `-${PADDING_XSM}`,
  marginRight: `-${PADDING_XSM}`,

  [breakpoints.MEDIUM]: {
    position: 'relative',
    height: STEP_CONTAINER_HEIGHT,
    paddingBottom: spacing.larger,
  },
});

const CreateLoopLoading = () => {
  return (
    <StyledContainer>
      <StyledLoadingWrapper>
        <StyledLoadingText>Loading</StyledLoadingText>
        <SimpleLoadingIndicator />
      </StyledLoadingWrapper>
    </StyledContainer>
  );
};

export default CreateLoopLoading;
