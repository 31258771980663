import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';

import { ResourceDefinition, ResourceTypes } from '../types';

const Placeholder: ResourceDefinition = {
  attributes: {
    name: ATTRIBUTE_TYPE.STRING,
    required: ATTRIBUTE_TYPE.BOOLEAN,
    sortOrder: ATTRIBUTE_TYPE.NUMBER,
    sortValue: ATTRIBUTE_TYPE.STRING,
    archived: ATTRIBUTE_TYPE.BOOLEAN,
    lastUpdated: ATTRIBUTE_TYPE.DATE,
  },
  relationships: {
    loopFolder: {
      resourceType: ResourceTypes.LoopFolders,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
    templateFolder: {
      resourceType: ResourceTypes.TemplateFolders,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
    loop: {
      resourceType: ResourceTypes.Loops,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
  },
};

export default Placeholder;
