import styled from 'styled-components';

import DefaultMobileHeader from '~/components/Header/DefaultMobileHeader';
import { EditIcon } from '~/components/icons';
import IconLink from '~/components/IconLink';

import { spacing } from '~/styles';

const StyledCreateMessage = styled.div({
  flex: '0 1 auto',
  height: '80%',
  marginLeft: 'auto',
  padding: `${spacing.small} 0`,
});

export function MessagingMobileHeader() {
  return (
    <DefaultMobileHeader>
      <StyledCreateMessage>
        <IconLink link="/messages/new-conversation" icon={EditIcon} />
      </StyledCreateMessage>
    </DefaultMobileHeader>
  );
}

export default MessagingMobileHeader;
