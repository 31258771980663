import { MouseEvent } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from '~/components/i18n';
import {
  LinkButton,
  NegativeButton,
  PrimaryButton,
} from '~/components/buttons';
import { breakpoints, spacing } from '~/styles';
import { useIsDesktop } from '~/hooks';

const mixins = {
  cancelActionWithoutSecondaryAction: {
    flex: '0 0 auto',
    width: 'auto',
  },

  primaryActionWithoutSecondaryAction: {
    flex: '0 0 auto',
    width: 'auto',
    marginTop: 0,
    marginLeft: 'auto',
  },

  primaryActionFullWidth: {
    flex: '1 1 100%',
    width: '100%',
    maxWidth: '100%',

    [breakpoints.MEDIUM]: {
      flex: '1 1 50%',
      width: '50%',
      maxWidth: '50%',
      margin: `0 auto`,
    },
  },
};

const StyledPrimaryAction = styled.div<{
  $primaryActionWithoutSecondaryActionEnabled: boolean;
  $primaryActionFullWidthEnabled: boolean;
}>(props => ({
  flex: '0 0 100%',
  width: '100%',
  marginTop: spacing.normal,

  [breakpoints.MEDIUM]: {
    flex: '0 0 auto',
    width: 'auto',
    marginTop: 0,
    marginLeft: spacing.normal,
  },

  ...(props.$primaryActionWithoutSecondaryActionEnabled
    ? mixins.primaryActionWithoutSecondaryAction
    : {}),
  ...(props.$primaryActionFullWidthEnabled
    ? mixins.primaryActionFullWidth
    : {}),
}));

const StyledCancelAction = styled.div<{
  $cancelActionWithoutSecondaryActionEnabled: boolean;
}>(props => ({
  display: 'flex',
  justifyContent: 'flex-end',
  flex: '0 0 50%',
  width: '50%',

  [breakpoints.MEDIUM]: {
    flex: '0 0 auto',
    width: 'auto',
    marginLeft: 'auto',
  },

  ...(props.$cancelActionWithoutSecondaryActionEnabled
    ? mixins.cancelActionWithoutSecondaryAction
    : {}),
}));

const StyledSecondaryAction = styled.div({
  flex: '0 0 50%',
  width: '50%',

  [breakpoints.MEDIUM]: {
    flex: '1 0 auto',
    width: 'auto',
  },
});

const StyledModalActionContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
});

export const ACTION_HEIGHT = '5rem';

type Values = {
  [key: string]: any;
};

export type ModalAction = {
  labelId: string;
  values?: Values;
  isDisabled?: boolean;
  onClick: () => any;
};

type Props = {
  large: boolean;
  formName?: string;
  isNegative: boolean;
  primaryAction?: ModalAction;
  secondaryAction?: ModalAction;
  cancelAction?: () => any;
  hideCancel?: boolean;
};

const ModalActions = (props: Props) => {
  const {
    large,
    formName,
    isNegative,
    primaryAction,
    cancelAction,
    hideCancel,
    secondaryAction,
  } = props;
  const isDesktop = useIsDesktop();
  const PrimaryActionComponent = isNegative ? NegativeButton : PrimaryButton;

  // We use a full width primary action if it is either
  // * The primary action is the only action
  // * There are secondary and cancel actions, and we're on a mobile device
  const useFullWidthButton =
    hideCancel ||
    (!secondaryAction && !cancelAction) ||
    (!!secondaryAction && !!cancelAction && !isDesktop);

  if (!primaryAction) {
    return null;
  }

  return (
    <StyledModalActionContainer>
      {large && !!secondaryAction && (
        <StyledSecondaryAction>
          <LinkButton
            id="secondary-modal-action"
            removeSidePadding
            disabled={secondaryAction.isDisabled}
            onClick={secondaryAction.onClick}
          >
            <FormattedMessage id={secondaryAction.labelId} />
          </LinkButton>
        </StyledSecondaryAction>
      )}

      {!hideCancel && !!cancelAction && (
        <StyledCancelAction
          $cancelActionWithoutSecondaryActionEnabled={
            !secondaryAction || !large
          }
        >
          <LinkButton
            id="cancel-modal-action"
            removeSidePadding
            onClick={cancelAction}
          >
            <FormattedMessage id="actions.cancel" />
          </LinkButton>
        </StyledCancelAction>
      )}

      <StyledPrimaryAction
        $primaryActionWithoutSecondaryActionEnabled={!secondaryAction || !large}
        $primaryActionFullWidthEnabled={useFullWidthButton}
      >
        <PrimaryActionComponent
          id="primary-modal-action"
          fullWidth={useFullWidthButton}
          type={formName ? 'submit' : 'button'}
          disabled={primaryAction.isDisabled}
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            return primaryAction.onClick();
          }}
        >
          <FormattedMessage
            id={primaryAction.labelId}
            values={primaryAction.values}
          />
        </PrimaryActionComponent>
      </StyledPrimaryAction>
    </StyledModalActionContainer>
  );
};

ModalActions.defaultProps = {
  large: true,
  isNegative: false,
  contentMaxHeightExceeded: false,
};

export default ModalActions;
