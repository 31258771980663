import { Fragment, ReactNode } from 'react';
import { useLocation, useNavigate, Location, Navigate } from 'react-router-dom';
import qs, { ParsedQs } from 'qs';

import MessagingSidebar from '~/components/Messaging/MessagingSidebar';
import { NEW_CONVERSATION_ID } from '~/components/Messaging/ConversationList';
import PubNubInitializer from '../Analytics/PubNub';

import { useIsDesktop } from '~/hooks';
import { breakpoints, screenSizes } from '~/styles';

export const MESSAGE_BREAKPOINT = breakpoints.LARGE;
export const MESSAGE_BREAKPOINT_SCREEN_SIZE = screenSizes.LARGE;

const conversationIdMatcher = '[a-zA-Z0-9-]*';
const showConversationListSearchValue = 'showConversationList';
const showConversationSearchValue = 'showConversation';
const selectedConversationSearchValue = 'selectedConversationId';
const showConversationDetailsSearchValue = 'showConversationDetails';
const searchTermValue = 'searchTerm';
const pathnameConversationRegex = new RegExp(
  `^/messages/(${conversationIdMatcher})`
);
const pathnameConversationDetailsRegex = new RegExp(
  `^/messages/(${conversationIdMatcher})/details`
);

function getConversationIdFromSearch(searchValues: ParsedQs): string | null {
  const conversationId = searchValues[selectedConversationSearchValue];

  if (!!conversationId && typeof conversationId === 'string') {
    return conversationId;
  }

  return null;
}

function getConversationIdFromPath(pathname: string) {
  const match = pathname.match(pathnameConversationRegex);

  if (match) {
    return match[1];
  } else {
    return null;
  }
}

function getSearchTermFromSearch(searchValues: ParsedQs): string | null {
  const searchTerm = searchValues[searchTermValue];

  if (!!searchTerm && typeof searchTerm === 'string') {
    return searchTerm;
  }

  return null;
}

function parseSearch(search: string): ParsedQs {
  if (search.startsWith('?')) {
    search = search.slice(1);
  }

  return qs.parse(search);
}

interface ParsedLocationValues {
  showConversationList: boolean;
  showConversation: boolean;
  showConversationDetails: boolean;
  conversationId: string | null;
  searchTerm: string | null;
}
interface ParsedLocation {
  desktop: ParsedLocationValues;
  mobile: ParsedLocationValues;
}
function parseLocation(location: Location): ParsedLocation {
  const { search, pathname } = location;
  const searchValues = parseSearch(search);

  return {
    desktop: {
      showConversationList:
        searchValues[showConversationListSearchValue] === 'true',
      showConversation: searchValues[showConversationSearchValue] === 'true',
      showConversationDetails:
        searchValues[showConversationDetailsSearchValue] === 'true',
      conversationId: getConversationIdFromSearch(searchValues),
      searchTerm: getSearchTermFromSearch(searchValues),
    },
    mobile: {
      showConversationList: pathname.startsWith('/messages'),
      showConversation: pathnameConversationRegex.test(pathname),
      showConversationDetails: pathnameConversationDetailsRegex.test(pathname),
      conversationId: getConversationIdFromPath(pathname),
      searchTerm: getSearchTermFromSearch(searchValues),
    },
  };
}

interface Props {
  children: ReactNode;
}

export function MessagingRouter({ children }: Props) {
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();
  const location = useLocation();
  const locationDetails = parseLocation(location);
  const searchValues = parseSearch(location.search);

  const toggleMessagingSidebar = () => {
    const {
      desktop: { showConversationList },
    } = locationDetails;

    if (!showConversationList) {
      searchValues[showConversationListSearchValue] = 'true';
    } else {
      delete searchValues[showConversationListSearchValue];
      delete searchValues[showConversationSearchValue];
      delete searchValues[showConversationDetailsSearchValue];
      delete searchValues[selectedConversationSearchValue];
      delete searchValues[searchTermValue];
    }
    const searchString = qs.stringify(searchValues);

    navigate(`${location.pathname}?${searchString}`, { replace: true });
  };

  const hideConversation = () => {
    searchValues[showConversationListSearchValue] = 'true';
    delete searchValues[showConversationSearchValue];
    delete searchValues[showConversationDetailsSearchValue];
    delete searchValues[selectedConversationSearchValue];
    delete searchValues[searchTermValue];

    const searchString = qs.stringify(searchValues);

    navigate(`${location.pathname}?${searchString}`, { replace: true });
  };

  const viewConversation = (conversationId: string, shouldToggle = false) => {
    const hideConversation =
      locationDetails.desktop.conversationId === conversationId &&
      locationDetails.desktop.showConversation;
    const showConversation = shouldToggle ? !hideConversation : true;
    const showConversationDetails = hideConversation
      ? false
      : locationDetails.desktop.showConversationDetails;

    searchValues[showConversationListSearchValue] = 'true';
    searchValues[showConversationSearchValue] = showConversation.toString();
    searchValues[selectedConversationSearchValue] = conversationId;
    searchValues[showConversationDetailsSearchValue] =
      showConversationDetails.toString();

    const search = qs.stringify(searchValues);
    navigate(`${location.pathname}?${search}`, { replace: true });
  };

  const toggleConversationDetails = () => {
    const showConversationDetails =
      searchValues[showConversationDetailsSearchValue];

    searchValues[showConversationDetailsSearchValue] =
      showConversationDetails === 'true' ? 'false' : 'true';

    const search = qs.stringify(searchValues);
    navigate(`${location.pathname}?${search}`, { replace: true });
  };

  const createNewConversation = () => {
    searchValues[showConversationSearchValue] = 'true';
    searchValues[selectedConversationSearchValue] = NEW_CONVERSATION_ID;
    delete searchValues[showConversationDetailsSearchValue];

    const search = qs.stringify(searchValues);
    navigate(`${location.pathname}?${search}`, { replace: true });
  };

  if (!isDesktop && locationDetails.desktop.showConversationList) {
    let redirectPath = '/messages';

    if (
      locationDetails.desktop.showConversation &&
      !!locationDetails.desktop.conversationId
    ) {
      redirectPath += `/${locationDetails.desktop.conversationId}`;
    }

    if (locationDetails.desktop.showConversationDetails) {
      redirectPath += '/details';
    }

    if (locationDetails.desktop.searchTerm) {
      redirectPath += `?searchTerm=${encodeURIComponent(
        locationDetails.desktop.searchTerm
      )}`;
    }

    return <Navigate to={redirectPath} />;
  } else if (isDesktop && locationDetails.mobile.showConversationList) {
    const searchValues: ParsedQs = {
      [showConversationListSearchValue]: 'true',
    };
    const redirectLocation: Partial<Location> = {
      pathname: '/loops',
    };

    if (
      locationDetails.mobile.showConversation &&
      !!locationDetails.mobile.conversationId
    ) {
      searchValues[showConversationSearchValue] = 'true';
      searchValues[selectedConversationSearchValue] =
        locationDetails.mobile.conversationId;
    }

    if (locationDetails.mobile.showConversationDetails) {
      searchValues[showConversationDetailsSearchValue] = 'true';
    }

    redirectLocation.search = `?${qs.stringify(searchValues)}`;

    return <Navigate to={redirectLocation} />;
  } else {
    return (
      <Fragment>
        {children}
        {isDesktop && (
          <MessagingSidebar
            showConversationList={locationDetails.desktop.showConversationList}
            showConversation={locationDetails.desktop.showConversation}
            selectedConversationId={locationDetails.desktop.conversationId}
            showConversationDetails={
              locationDetails.desktop.showConversationDetails
            }
            createNewConversation={createNewConversation}
            toggleSidebar={toggleMessagingSidebar}
            viewConversation={viewConversation}
            hideConversation={hideConversation}
            toggleConversationDetails={toggleConversationDetails}
            searchTerm={locationDetails.desktop.searchTerm}
          />
        )}
        <PubNubInitializer />
      </Fragment>
    );
  }
}

export default MessagingRouter;
