import { useEffect } from 'react';

type AppScrollOptions = {
  hideScrollbar: boolean;
};

let scrollPosition: number;
let scrollingDisabled = false;
const scrollBlockers: Array<string> = [];

/**
 * Set whether the app can scroll or not. Useful for when there is a
 * popover / overlay and the main page contents shouldn't be scrollable
 * @param {boolean} canScroll
 *
 * Identifier used when setting the application to not scroll
 * This is useful for overlays that need to scroll but the elements behind should not scroll
 * @param {string} callerId
 */
export function setApplicationScrolling(
  canScroll: boolean,
  callerId: string,
  options: AppScrollOptions = { hideScrollbar: false }
) {
  const html = document.getElementsByTagName('html')[0];
  const root = document.getElementById('root');

  if (html && root) {
    const blockerIndex = scrollBlockers.indexOf(callerId);

    if (canScroll && blockerIndex !== -1) {
      scrollBlockers.splice(blockerIndex, 1);

      if (scrollBlockers.length === 0) {
        // Only re-enable scrolling if there are no blockers left
        scrollingDisabled = false;
        html.style.position = '';
        html.style.overflowY = '';
        html.style.top = '';
        window.scrollTo(0, scrollPosition);
      }
    } else if (!canScroll && blockerIndex === -1) {
      scrollBlockers.push(callerId);

      if (!scrollingDisabled) {
        // We only need to disable if it's not already disabled
        scrollingDisabled = true;
        scrollPosition = window.scrollY;
        html.style.position = 'fixed';
        html.style.top = `-${scrollPosition}px`;
      }

      if (options.hideScrollbar) {
        html.style.setProperty('overflow-y', 'hidden', 'important');
      }
    }
  }
}

export function useApplicationScrolling(
  canScroll: boolean,
  callerId: string,
  options: AppScrollOptions = { hideScrollbar: false }
) {
  useEffect(() => {
    setApplicationScrolling(canScroll, callerId, options);

    return () => {
      setApplicationScrolling(true, callerId, options);
    };
  }, [canScroll, callerId, options]);
}
