import { WritableDraft } from 'immer/dist/internal';
import update from 'lodash-es/update';
import { generateQueryString } from '~/lib/api';
import {
  ApiCreateResource,
  ApiResource,
  JsonApiFetchOptions,
  RelationshipObject,
  ResourceIdentifier,
  ResourceRelationships,
} from '~/lib/api/types';
import {
  ApiState,
  StateResource,
  StateResourceDataItem,
  StateResourcesMap,
} from './types';
import resourceDefs from './resources';
import { pluralize } from 'inflection';
import { deserialize } from './serialization';
import { RELATIONSHIP_TYPE } from './resources/constants';
import {
  RelationshipsDefinition,
  RelationshipType,
  ResourceTypes,
} from './resources/types';
import { original } from '@reduxjs/toolkit';

export function generateOptimisticId(resource: ApiCreateResource) {
  return `OPTIMISTIC_ID:${JSON.stringify(resource)}`;
}

export const DEFAULT_QUERY_KEY = 'default';
export function getInitialResources(): StateResourcesMap {
  return Object.keys(resourceDefs).reduce((currentResources, resourceName) => {
    return {
      ...currentResources,
      [resourceName]: {
        __definition__: resourceDefs[resourceName],
        queries: {},
        data: {},
        isCreating: 0,
        isUpdating: 0,
        isDeleting: 0,
      },
    };
  }, {});
}

export function getResourceNameForRelationship(
  state: ApiState,
  resourceName: string,
  relationshipName: string
) {
  const { resources } = state;
  const resourceDefinition = resources[resourceName]
    ? resources[resourceName].__definition__
    : null;
  let relationshipResourceName;

  if (!!resourceDefinition) {
    const { relationships } = resourceDefinition;
    const relationshipDefinition = relationships
      ? relationships[relationshipName]
      : null;

    if (!!relationshipDefinition) {
      relationshipResourceName =
        'polymorphicResourceName' in relationshipDefinition
          ? relationshipDefinition.polymorphicResourceName
          : relationshipDefinition.resourceType;
    }
  }

  return relationshipResourceName;
}

const resourceNameInvariant = (
  state: WritableDraft<ApiState>,
  resourceName: string
) => {
  const stateResources = state.resources[resourceName];

  if (!stateResources) {
    throw new Error(`Resource "${resourceName}" does not exist!`);
  }

  return stateResources;
};

type QueryData = {
  isFetching?: boolean;
  fetchFailed?: boolean;
  hasBeenRequested?: boolean;
  isStale?: boolean;
  /**
   * This property isn't on the top level query data in the state tree,
   * but actually in the nested meta object
   */
  totalResourceCount?: number | null;
};
export function updateQueryLookup(
  state: WritableDraft<ApiState>,
  resourceName: string,
  resources: ApiResource[] | ResourceIdentifier[] | null,
  options: JsonApiFetchOptions | undefined = undefined,
  queryData: QueryData = {}
) {
  const { totalResourceCount, ...restQueryData } = queryData;
  const newTotalResourceCount = totalResourceCount ?? null;
  const stateResources = resourceNameInvariant(state, resourceName);
  const queryKey = generateQueryString(options) || DEFAULT_QUERY_KEY;
  const resourceIdSet = new Set<string>();

  if (resources) {
    resources.forEach(resource => {
      if (resource.type === resourceName) {
        resourceIdSet.add(resource.id);
      }
    });
  }

  if (!stateResources.queries) {
    stateResources.queries = {};
  }

  if (!stateResources.queries[queryKey]) {
    stateResources.queries[queryKey] = {
      isFetching: false,
      fetchFailed: false,
      hasBeenRequested: false,
      isStale: false,
      data: null,
      meta: { totalResourceCount: newTotalResourceCount },
      ...restQueryData,
    };
  }

  const currentQuery = stateResources.queries[queryKey];
  currentQuery.isFetching = queryData.isFetching ?? currentQuery.isFetching;
  currentQuery.fetchFailed = queryData.fetchFailed ?? currentQuery.fetchFailed;
  currentQuery.hasBeenRequested =
    queryData.hasBeenRequested ?? currentQuery.hasBeenRequested;
  currentQuery.isStale = queryData.isStale ?? currentQuery.isStale;
  currentQuery.data = resources ? Array.from(resourceIdSet) : currentQuery.data;
  currentQuery.meta = { totalResourceCount: newTotalResourceCount };
}

export function updateFetchResourceMetadata(
  state: WritableDraft<ApiState>,
  resourceName: string,
  resourceId: string,
  queryData: QueryData = {}
) {
  const { totalResourceCount, ...restQueryData } = queryData;
  const newTotalResourceCount = totalResourceCount ?? null;
  const stateResources = resourceNameInvariant(state, resourceName);
  const queryKey = `${resourceName}-${resourceId}`;

  if (!stateResources.queries[queryKey]) {
    stateResources.queries[queryKey] = {
      isFetching: false,
      fetchFailed: false,
      hasBeenRequested: false,
      isStale: false,
      data: null,
      meta: { totalResourceCount: newTotalResourceCount },
      ...restQueryData,
    };
  }

  const currentQuery = stateResources.queries[queryKey];
  currentQuery.isFetching = queryData.isFetching ?? currentQuery.isFetching;
  currentQuery.fetchFailed = queryData.fetchFailed ?? currentQuery.fetchFailed;
  currentQuery.hasBeenRequested =
    queryData.hasBeenRequested ?? currentQuery.hasBeenRequested;
  currentQuery.isStale = queryData.isStale ?? currentQuery.isStale;
  currentQuery.meta = { totalResourceCount: newTotalResourceCount };
}

export function markQueryLookupStale(
  state: WritableDraft<ApiState>,
  resourceName: string
) {
  const stateResources = resourceNameInvariant(state, resourceName);

  if (stateResources.queries) {
    Object.values(stateResources.queries).forEach(
      query => (query.isStale = true)
    );
  }
}

type InsertUpdateOptions = {
  isOptimistic?: boolean;
  existingOptimisticId?: string;
};

export function insertOrUpdateResources(
  state: WritableDraft<ApiState>,
  resources: Array<ApiResource>,
  options: InsertUpdateOptions = {}
) {
  resources.forEach(resource => {
    const type = pluralize(resource.type);
    const currentResourceType = state.resources[type];

    if (currentResourceType) {
      const currentResourceTypeDefinition = currentResourceType.__definition__;
      const serializedResource = deserialize(
        resource,
        currentResourceTypeDefinition
      );

      updateStateResource(
        state,
        type,
        resource.id,
        serializedResource,
        options
      );

      updateReverseRelationships(state, serializedResource);
    } else {
      console.error(
        `Attempted to insert unknown resource type returned (${resource.type}, ${type}) into state.`
      );
    }
  });
}

export function updateStateResource(
  state: WritableDraft<ApiState>,
  resourceName: string,
  resourceId: string,
  updatedValues: Partial<ApiResource | StateResourceDataItem>,
  options: InsertUpdateOptions = {}
) {
  const { isOptimistic, existingOptimisticId } = options;
  const stateResources = resourceNameInvariant(state, resourceName);
  const resourceData = stateResources.data;
  const reverseRelationshipsToDelete: ApiResource[] = [];
  let stateResource = resourceData[resourceId];

  if (!stateResource) {
    if (existingOptimisticId) {
      stateResource = resourceData[existingOptimisticId];
      reverseRelationshipsToDelete.push(stateResource);
      delete resourceData[existingOptimisticId];
    } else {
      stateResource = {
        id: resourceId,
        type: resourceName,
        attributes: {},
        relationships: {},
      };
    }
  } else if (isOptimistic) {
    stateResource.previousValue = original(stateResource);
  } else {
    delete stateResource.previousValue;
  }

  stateResource.attributes = {
    ...stateResource.attributes,
    ...updatedValues.attributes,
  };
  stateResource.relationships = {
    ...stateResource.relationships,
    ...updatedValues.relationships,
  };
  stateResource.meta = {
    ...stateResource.meta,
    ...updatedValues.meta,
  };

  stateResources.data[resourceId] = stateResource;

  reverseRelationshipsToDelete.forEach(reverseRelationshipToDelete =>
    updateReverseRelationships(state, reverseRelationshipToDelete, true)
  );
}

export function deleteStateResource(
  state: WritableDraft<ApiState>,
  resourceName: string,
  resourceId: string
) {
  const stateResource = resourceNameInvariant(state, resourceName);
  let deletedResource;

  if (stateResource.data) {
    deletedResource = stateResource.data[resourceId];
    delete stateResource.data[resourceId];

    if (Object.keys(stateResource.data).length === 0) {
      // If there is no data left, reset data to null
      stateResource.data = {};
    }
  }

  if (stateResource.queries) {
    Object.values(stateResource.queries).forEach(query => {
      if (query.data) {
        query.data = query.data.filter(id => id !== resourceId);
      }
    });
  }

  if (deletedResource) {
    updateReverseRelationships(state, deletedResource, true);
  }
}

type StateResourceMetaProperty = 'isCreating' | 'isUpdating' | 'isDeleting';
type StateResourceMetaAction = 'increment' | 'decrement';
export function updateStateResourceMeta(
  state: WritableDraft<ApiState>,
  resourceName: string,
  propertyName: StateResourceMetaProperty,
  action: StateResourceMetaAction
) {
  const stateResource = resourceNameInvariant(state, resourceName);
  const addend = action === 'increment' ? 1 : -1;

  stateResource[propertyName] = Math.max(
    stateResource[propertyName] + addend,
    0
  );
}

function updateReverseRelationships(
  state: WritableDraft<ApiState>,
  sourceResource: ApiResource,
  deleteRelationship = false
) {
  const resource = resourceNameInvariant(state, sourceResource.type);
  const sourceResourceDefinition = resource.__definition__;

  if (sourceResource.relationships && sourceResourceDefinition) {
    for (const [relationshipKey, sourceRelationship] of Object.entries(
      sourceResource.relationships
    )) {
      const sourceRelationshipType = sourceResourceDefinition.relationships
        ? sourceResourceDefinition.relationships[relationshipKey]
        : undefined;

      if (sourceRelationshipType && sourceRelationship) {
        updateReverseRelationship(
          state,
          sourceResource,
          sourceRelationship,
          deleteRelationship
        );
      }
    }
  }
}

function updateReverseRelationship(
  state: WritableDraft<ApiState>,
  sourceResource: ApiResource,
  sourceRelationship: RelationshipObject,
  deleteRelationship: boolean
) {
  if (sourceRelationship.data) {
    const sourceRelationshipData = Array.isArray(sourceRelationship.data)
      ? sourceRelationship.data
      : [sourceRelationship.data];

    sourceRelationshipData.forEach(sourceRelationshipDataItem =>
      _updateReverseRelationship(
        state,
        sourceResource,
        sourceRelationshipDataItem,
        deleteRelationship
      )
    );
  }
}

function _updateReverseRelationship(
  state: WritableDraft<ApiState>,
  sourceResource: ApiResource,
  sourceRelationshipData: ResourceIdentifier,
  deleteRelationship: boolean
) {
  let targetStateResource = state.resources[sourceRelationshipData.type];

  if (targetStateResource) {
    const { data: targetResourceData } = targetStateResource;

    if (!targetResourceData || !targetResourceData[sourceRelationshipData.id]) {
      // We're trying to update a reverse relationship for a resource that doesn't
      // yet exist, so we need to make it first.
      const newResource = {
        id: sourceRelationshipData.id,
        type: sourceRelationshipData.type,
        attributes: {},
        relationships: {},
      };
      insertOrUpdateResources(state, [newResource]);
      targetStateResource = state.resources[sourceRelationshipData.type];
    }
  }

  if (targetStateResource && targetStateResource.data) {
    const targetRelationshipDefinitions =
      targetStateResource.__definition__.relationships;
    const targetResource = targetStateResource.data[sourceRelationshipData.id];

    if (targetRelationshipDefinitions && targetResource) {
      const targetResourceRelationships = targetResource.relationships || {};
      const targetRelationshipDefinitionKey = _getRelationshipDefinitionKey(
        targetRelationshipDefinitions,
        sourceResource
      );

      if (targetRelationshipDefinitionKey) {
        const targetRelationshipDefinition =
          targetRelationshipDefinitions[targetRelationshipDefinitionKey];
        const existingTargetRelationships = _getRelationship(
          targetResource,
          targetRelationshipDefinitionKey
        );
        const sourceResourceIdentifier = {
          id: sourceResource.id,
          type: sourceResource.type,
        };
        const reverseRelationship = _calculateReverseRelationship(
          sourceResourceIdentifier,
          existingTargetRelationships,
          targetRelationshipDefinition.relationshipType,
          deleteRelationship
        );

        updateStateResource(state, targetResource.type, targetResource.id, {
          relationships: {
            ...targetResourceRelationships,
            [targetRelationshipDefinitionKey]: {
              data: reverseRelationship,
            },
          },
        });
      }
    }
  }
}

export function addRelationships(
  state: WritableDraft<ApiState>,
  resourceName: string,
  resourceId: string,
  relationshipName: string,
  relationshipIds: string | string[]
) {
  relationshipIds = coerceToArray(relationshipIds);
  relationshipIds.forEach(relationshipId =>
    addRelationship(
      state,
      resourceName,
      resourceId,
      relationshipName,
      relationshipId
    )
  );
}

export function addRelationship(
  state: WritableDraft<ApiState>,
  resourceName: string,
  resourceId: string,
  relationshipName: string,
  relationshipId: string
) {
  const {
    relationshipData,
    relationshipResourceType,
    relationshipType,
    reverseStateResource,
    reverseResource,
    reverseResourceRelationships,
    reverseRelationshipType,
    reverseRelationshipName,
    reverseRelationshipData,
  } = relationshipHelper(
    state,
    resourceName,
    resourceId,
    relationshipName,
    relationshipId
  );

  const newRelationship = {
    type: relationshipResourceType,
    id: relationshipId,
  };
  let newRelationshipData: ResourceIdentifier | Array<ResourceIdentifier> =
    newRelationship;

  if (
    relationshipType === RELATIONSHIP_TYPE.MANY &&
    Array.isArray(relationshipData)
  ) {
    newRelationshipData = relationshipData.concat([newRelationship]);
  }

  const newReverseRelationship = { type: resourceName, id: resourceId };
  let newReverseRelationshipData:
    | ResourceIdentifier
    | Array<ResourceIdentifier> = newReverseRelationship;

  if (
    reverseRelationshipType === RELATIONSHIP_TYPE.MANY &&
    Array.isArray(reverseRelationshipData)
  ) {
    newReverseRelationshipData = reverseRelationshipData.concat([
      newReverseRelationship,
    ]);
  }

  const reverseRelationshipStateData = createReverseRelationshipStateData(
    relationshipId,
    reverseResource,
    reverseResourceRelationships,
    reverseRelationshipName,
    newReverseRelationshipData
  );

  update(
    state,
    [
      'resources',
      resourceName,
      'data',
      resourceId,
      'relationships',
      relationshipName,
      'data',
    ],
    () => newRelationshipData
  );

  state.resources[relationshipResourceType].data = {
    ...reverseStateResource.data,
    ...reverseRelationshipStateData,
  };
}

function createReverseRelationshipStateData(
  relationshipId: string | null,
  reverseResource: StateResourceDataItem | null,
  reverseResourceRelationships: ResourceRelationships | null,
  reverseRelationshipName: ResourceTypes,
  newReverseRelationshipData:
    | undefined
    | ResourceIdentifier
    | ResourceIdentifier[]
) {
  let reverseRelationshipStateData = null;

  if (!!relationshipId && !!reverseResource) {
    const relatedResource: StateResourceDataItem = {
      ...reverseResource,
      relationships: {
        ...reverseResourceRelationships,
        [reverseRelationshipName]: {
          data: newReverseRelationshipData,
        },
      },
    };

    reverseRelationshipStateData = { [relationshipId]: relatedResource };
  }

  return reverseRelationshipStateData;
}

export function removeRelationships(
  state: WritableDraft<ApiState>,
  resourceName: string,
  resourceId: string,
  relationshipName: string,
  relationshipIds: string | string[]
) {
  relationshipIds = coerceToArray(relationshipIds);

  relationshipIds.forEach(relationshipId => {
    removeRelationship(
      state,
      resourceName,
      resourceId,
      relationshipName,
      relationshipId
    );
  });
}

function removeRelationship(
  state: WritableDraft<ApiState>,
  resourceName: string,
  resourceId: string,
  relationshipName: string,
  relationshipId: string
) {
  const {
    relationshipData,
    relationshipResourceType,
    relationshipType,
    reverseStateResource,
    reverseResource,
    reverseResourceRelationships,
    reverseRelationshipType,
    reverseRelationshipName,
    reverseRelationshipData,
  } = relationshipHelper(
    state,
    resourceName,
    resourceId,
    relationshipName,
    relationshipId
  );

  let newRelationshipData: Array<ResourceIdentifier> | undefined = undefined;
  if (
    relationshipType === RELATIONSHIP_TYPE.MANY &&
    Array.isArray(relationshipData)
  ) {
    const filteredRelationshipData = relationshipData.filter(
      r => r.id !== relationshipId
    );
    newRelationshipData = filteredRelationshipData.length
      ? filteredRelationshipData
      : undefined;
  }

  let newReverseRelationshipData: Array<ResourceIdentifier> | undefined =
    undefined;
  if (
    reverseRelationshipType === RELATIONSHIP_TYPE.MANY &&
    Array.isArray(reverseRelationshipData)
  ) {
    const filteredReverseRelationshipData = reverseRelationshipData.filter(
      r => r.id !== resourceId
    );
    newReverseRelationshipData = filteredReverseRelationshipData.length
      ? filteredReverseRelationshipData
      : undefined;
  }

  const reverseRelationshipStateData = createReverseRelationshipStateData(
    relationshipId,
    reverseResource,
    reverseResourceRelationships,
    reverseRelationshipName,
    newReverseRelationshipData
  );

  const relationships = (state.resources[resourceName].data[
    resourceId
  ].relationships =
    state.resources[resourceName].data[resourceId].relationships || {});

  relationships[relationshipName].data = newRelationshipData;

  state.resources[relationshipResourceType].data = {
    ...reverseStateResource.data,
    ...reverseRelationshipStateData,
  };
}

/**
 * An update operation replaces the whole relationship
 * but since we can get a list of relationships to update, we need to treat
 * the tail part of the list as an add and not an update, otherwise it would replace
 */
export function updateRelationships(
  state: WritableDraft<ApiState>,
  resourceName: string,
  resourceId: string,
  relationshipName: string,
  relationshipIds: string | string[]
) {
  let shouldAdd = false;
  relationshipIds = coerceToArray(relationshipIds);
  relationshipIds.forEach(relationshipId => {
    if (shouldAdd) {
      addRelationship(
        state,
        resourceName,
        resourceId,
        relationshipName,
        relationshipId
      );
    } else {
      updateRelationship(
        state,
        resourceName,
        resourceId,
        relationshipName,
        relationshipId
      );
    }

    shouldAdd = true;
  });
}

export function rollbackStateResource(
  state: WritableDraft<ApiState>,
  resourceName: string,
  resourceId: string
) {
  const stateResources = resourceNameInvariant(state, resourceName);
  const resourceData = stateResources.data;
  const previousValue = resourceData?.[resourceId].previousValue;

  if (resourceData && previousValue) {
    resourceData[resourceId] = previousValue;
  }
}

export function updateRelationship(
  state: WritableDraft<ApiState>,
  resourceName: string,
  resourceId: string,
  relationshipName: string,
  relationshipId: string | null
) {
  const {
    relationshipResourceType,
    relationshipType,
    reverseStateResource,
    reverseResource,
    reverseResourceRelationships,
    oldRelationshipIds,
    reverseRelationshipType,
    reverseRelationshipName,
    reverseRelationshipData,
  } = relationshipHelper(
    state,
    resourceName,
    resourceId,
    relationshipName,
    relationshipId
  );

  const newRelationship = relationshipId
    ? {
        type: relationshipResourceType,
        id: relationshipId,
      }
    : undefined;
  let newRelationshipData:
    | undefined
    | ResourceIdentifier
    | Array<ResourceIdentifier> = newRelationship;

  if (
    newRelationship &&
    newRelationshipData &&
    relationshipType === RELATIONSHIP_TYPE.MANY
  ) {
    newRelationshipData = [newRelationship];
  }

  const newReverseRelationship = { type: resourceName, id: resourceId };
  let newReverseRelationshipData:
    | undefined
    | ResourceIdentifier
    | Array<ResourceIdentifier> = newReverseRelationship;

  if (
    reverseRelationshipType === RELATIONSHIP_TYPE.MANY &&
    Array.isArray(reverseRelationshipData)
  ) {
    newReverseRelationshipData = reverseRelationshipData.concat([
      newReverseRelationship,
    ]);
  }

  const reverseRelationshipStateData = createReverseRelationshipStateData(
    relationshipId,
    reverseResource,
    reverseResourceRelationships,
    reverseRelationshipName,
    newReverseRelationshipData
  );

  update(
    state,
    [
      'resources',
      resourceName,
      'data',
      resourceId,
      'relationships',
      relationshipName,
      'data',
    ],
    () => newRelationshipData
  );

  state.resources[relationshipResourceType].data = {
    ...reverseStateResource.data,
    ...reverseRelationshipStateData,
  };

  // Since this is an update action, the new relationship data is the whole representation
  // So we need to make sure we go nuke the cache of the reverse relatinoships that are being removed
  if (oldRelationshipIds && oldRelationshipIds.length) {
    oldRelationshipIds.forEach(oldRelationshipId => {
      const oldRelationshipIdAsString = oldRelationshipId
        ? oldRelationshipId.toString()
        : null;

      if (oldRelationshipIdAsString) {
        const oldRelationshipResourceData =
          state.resources[relationshipResourceType].data;
        if (oldRelationshipResourceData) {
          const oldReverseResource =
            oldRelationshipResourceData[oldRelationshipIdAsString];

          if (oldReverseResource) {
            const oldReverseResourceRelationships =
              oldReverseResource.relationships;

            oldRelationshipResourceData[oldRelationshipIdAsString] = {
              ...oldReverseResource,
              relationships: {
                ...oldReverseResourceRelationships,
                [reverseRelationshipName]: {
                  data: undefined,
                },
              },
            };
          }
        }
      }
    });
  }
}

type RelationshipHelperData = {
  stateResource: StateResource;
  resource: StateResourceDataItem;
  resourceRelationships: ResourceRelationships | null;
  relationshipData: undefined | ResourceIdentifier | Array<ResourceIdentifier>;
  relationshipResourceType: ResourceTypes;
  relationshipType: RelationshipType;
  reverseStateResource: StateResource;
  reverseResource: StateResourceDataItem | null;
  reverseResourceRelationships: ResourceRelationships | null;
  reverseRelationshipType: RelationshipType;
  reverseRelationshipName: ResourceTypes;
  reverseRelationshipData:
    | undefined
    | ResourceIdentifier
    | Array<ResourceIdentifier>;
  oldRelationshipIds: Array<string | ResourceIdentifier>;
};

export function relationshipHelper(
  state: ApiState,
  resourceName: string,
  resourceId: string,
  relationshipName: string,
  relationshipId: string | string[] | null
): RelationshipHelperData {
  const stateResources = state.resources;
  relationshipId = coerceToItem(relationshipId);

  const stateResource = stateResources[resourceName];
  if (!stateResource) {
    throw new Error(`Resource "${resourceName}" does not exist!`);
  }

  const resource = stateResource.data ? stateResource.data[resourceId] : null;
  if (!resource) {
    throw new Error(
      `No resource exists for "${resourceName}" with ID "${resourceId}"`
    );
  }

  const relationshipDefinition = stateResource.__definition__.relationships;
  if (!relationshipDefinition) {
    throw new Error(`No relationships defined for "${resourceName}"`);
  }

  const { relationshipType, resourceType } =
    relationshipDefinition[relationshipName];
  const resourceRelationships = resource.relationships || null;
  const relationshipData = resource
    ? getRelationshipData(resource, relationshipName)
    : undefined;
  let relationshipResourceType = Array.isArray(resourceType)
    ? null
    : resourceType;

  if (!relationshipResourceType && !!relationshipData) {
    const dataArray = Array.isArray(relationshipData)
      ? relationshipData
      : [relationshipData];
    const identifier = dataArray.find(r => r.id === relationshipId);

    relationshipResourceType = identifier?.type as ResourceTypes;
  }

  if (!relationshipResourceType) {
    throw new Error(`Unable to look up resource type from relationship data.`);
  }

  const reverseStateResource =
    !!relationshipResourceType && stateResources[relationshipResourceType];
  if (!reverseStateResource) {
    throw new Error(`Resource "${relationshipResourceType}" does not exist!`);
  }

  let oldRelationshipIds: Array<string | ResourceIdentifier> = [];

  if (Array.isArray(relationshipData) && relationshipData.length) {
    oldRelationshipIds = relationshipData.map<string | ResourceIdentifier>(
      d => d.id
    );
  } else if (typeof relationshipData === 'string') {
    oldRelationshipIds = [relationshipData];
  }

  const reverseResource =
    reverseStateResource.data && relationshipId
      ? reverseStateResource.data[relationshipId]
      : null;

  const reverseRelationshipDefinition =
    reverseStateResource.__definition__.relationships;
  if (!reverseRelationshipDefinition) {
    throw new Error(`No relationships defined for "${relationshipName}"`);
  }

  const reverseRelationshipName = _getRelationshipDefinitionKey(
    reverseRelationshipDefinition,
    resource
  ) as ResourceTypes;
  if (!reverseRelationshipName) {
    throw new Error(
      `Could not find the proper relationship name for "${relationshipType}"`
    );
  }

  const { relationshipType: reverseRelationshipType } =
    reverseRelationshipDefinition[reverseRelationshipName];

  const reverseResourceRelationships =
    reverseResource && reverseResource.relationships
      ? reverseResource.relationships
      : null;

  const reverseRelationshipData = reverseResource
    ? getRelationshipData(reverseResource, reverseRelationshipName)
    : undefined;

  return {
    stateResource,
    resource,
    relationshipResourceType,
    resourceRelationships,
    relationshipData,
    relationshipType,
    reverseStateResource,
    reverseResource,
    reverseRelationshipType,
    reverseResourceRelationships,
    reverseRelationshipName,
    reverseRelationshipData,
    oldRelationshipIds,
  };
}

function getRelationshipData(
  resource: ApiResource,
  relationshipKey: string
): ResourceIdentifier | Array<ResourceIdentifier> {
  const relationship = _getRelationship(resource, relationshipKey);

  return relationship?.data ?? [];
}

function _getRelationshipDefinitionKey(
  reverseRelationshipResourceDefinitions: RelationshipsDefinition,
  resource: ApiResource
): string | undefined {
  return Object.keys(reverseRelationshipResourceDefinitions).find(
    relationshipKey => {
      const reverseDefinition =
        reverseRelationshipResourceDefinitions[relationshipKey];

      if ('polymorphicResourceName' in reverseDefinition) {
        return reverseDefinition.resourceType.some(t => t === resource.type);
      }

      return (
        reverseRelationshipResourceDefinitions[relationshipKey].resourceType ===
        resource.type
      );
    }
  );
}

function _getRelationship(
  resource: ApiResource,
  relationshipKey: string | null
): RelationshipObject | undefined {
  if (resource.relationships && relationshipKey) {
    return resource.relationships[relationshipKey];
  }
}

function _calculateReverseRelationship(
  sourceResourceIdentifier: ResourceIdentifier,
  existingTargetRelationships: RelationshipObject | undefined,
  reverseRelationshipType: RelationshipType,
  deleteRelationship: boolean
): undefined | ResourceIdentifier | Array<ResourceIdentifier> {
  let reverseRelationship;

  if (existingTargetRelationships && existingTargetRelationships.data) {
    if (reverseRelationshipType === RELATIONSHIP_TYPE.MANY) {
      reverseRelationship = _calculateReverseHasManyRelationship(
        sourceResourceIdentifier,
        existingTargetRelationships.data,
        deleteRelationship
      );
    } else {
      reverseRelationship = _calculateReverseHasOneRelationship(
        sourceResourceIdentifier,
        existingTargetRelationships.data,
        deleteRelationship
      );
    }
  } else if (reverseRelationshipType === RELATIONSHIP_TYPE.MANY) {
    if (deleteRelationship) {
      reverseRelationship = [];
    } else {
      reverseRelationship = [sourceResourceIdentifier];
    }
  } else if (deleteRelationship) {
    reverseRelationship = undefined;
  } else {
    reverseRelationship = sourceResourceIdentifier;
  }

  return reverseRelationship;
}

function _calculateReverseHasManyRelationship(
  sourceResourceIdentifier: ResourceIdentifier,
  existingTargetRelationshipsData:
    | ResourceIdentifier
    | Array<ResourceIdentifier>,
  deleteRelationship: boolean
): Array<ResourceIdentifier> {
  existingTargetRelationshipsData = coerceToArray(
    existingTargetRelationshipsData
  );

  if (deleteRelationship) {
    const initialRelationships: Array<ResourceIdentifier> = [];
    return existingTargetRelationshipsData.reduce(
      (newRelationships, existingRelationship) => {
        if (existingRelationship.id === sourceResourceIdentifier.id) {
          return newRelationships;
        } else {
          return [...newRelationships, existingRelationship];
        }
      },
      initialRelationships
    );
  } else {
    let updatedRelationshipData = existingTargetRelationshipsData;

    if (Array.isArray(existingTargetRelationshipsData)) {
      const match = existingTargetRelationshipsData.find(
        x => x.id === sourceResourceIdentifier.id
      );

      if (!match) {
        updatedRelationshipData = [
          ...existingTargetRelationshipsData,
          sourceResourceIdentifier,
        ];
      }
    }

    return updatedRelationshipData;
  }
}

function _calculateReverseHasOneRelationship(
  sourceResourceIdentifier: ResourceIdentifier,
  existingTargetRelationshipsData:
    | ResourceIdentifier
    | Array<ResourceIdentifier>,
  deleteRelationship: boolean
): ResourceIdentifier | undefined {
  const existingRelationshipData = coerceToItem(
    existingTargetRelationshipsData
  );

  if (deleteRelationship) {
    if (existingRelationshipData.id === sourceResourceIdentifier.id) {
      return undefined;
    } else {
      return existingRelationshipData;
    }
  } else {
    return sourceResourceIdentifier;
  }
}

function coerceToArray<T>(item: T | T[]): T[] {
  return Array.isArray(item) ? item : [item];
}

type Item<T> = T extends Array<infer I> ? I : T;
function coerceToItem<T>(item: T): Item<T> {
  return Array.isArray(item) ? item[0] : item;
}
