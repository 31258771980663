import dotloopColorPalette, {
  DEFAULT_BRAND_COLOR,
} from './dotloopColorPalette';

import { BrandedColor } from './types';

const OLD_BRAND_COLORS = ['#61B6FF', '#3BA2E5']; // Old dotloop blue

export function generateBrandColors(
  primaryColor: BrandedColor | null
): BrandedColor {
  let primaryBrandColor = primaryColor || DEFAULT_BRAND_COLOR;

  if (OLD_BRAND_COLORS.indexOf(primaryBrandColor.toUpperCase()) !== -1) {
    // One of the old brand colors was saved explicitly,
    // so update it to the current brand color
    primaryBrandColor = DEFAULT_BRAND_COLOR;
  }

  return primaryBrandColor;
}

type RGB = { r: number; g: number; b: number };
export function hexToRgb(hex: string): RGB | null {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function hexToRgba(hex: string, alpha: number): string {
  const result = hexToRgb(hex);

  if (result) {
    const { r, g, b } = result;

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return hex;
}

export { DEFAULT_BRAND_COLOR };

export default dotloopColorPalette;
