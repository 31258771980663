import loop from './loop';
import loopTemplate from './loopTemplate';
import document from './document';
import documentRevision from './documentRevision';
import complianceMessageRecipient from './complianceMessageRecipient';
import complianceReviewMessage from './complianceReviewMessage';
import complianceReviewNote from './complianceReviewNote';
import complianceGroup from './complianceGroup';
import complianceStatus from './complianceStatus';
import preference from './preference';
import tag from './tag';
import user from './user';
import profile from './profile';
import conversation from './conversation';
import participant from './participant';
import participantStatus from './participantStatus';
import message from './message';
import contact from './contact';
import announcement from './announcement';
import teamInvitation from './teamInvitation';
import loopFolder from './loopFolder';
import loopParticipant from './loopParticipant';
import loopParticipantLink from './loopParticipantLink';
import loopParticipantInvitation from './loopParticipantInvitation';
import loopRole from './loopRole';
import shareWorkflow from './shareWorkflow';
import shareWorkflowStep from './shareWorkflowStep';
import shareWorkflowTemplate from './shareWorkflowTemplate';
import shareWorkflowStepTemplate from './shareWorkflowStepTemplate';
import loopViewData from './loopViewData';
import fieldLabel from './fieldLabels';
import loopData from './loopData';
import documentField from './documentField';
import documentPage from './documentPage';
import userSignature from './userSignature';
import drawnSignature from './drawnSignature';
import loopDetails from './loopDetails';
import documentClass from './documentClass';
import templateFolder from './templateFolder';
import placeholder from './placeholder';
import LoopTaskList from './loopTaskList';
import LoopTaskListItem from './loopTaskListItem';
import loopPeople from './loopPeople';
import { ResourceDefinition } from './types';
import TemplateTaskList from './templateTaskList';
import TemplateTaskListItem from './templateTaskListItem';
import notification from './notification';

type ResourceDefinitions = { [resource: string]: ResourceDefinition };
const resourceDefinitions: ResourceDefinitions = {
  tags: tag,
  loops: loop,
  loopTemplates: loopTemplate,
  documents: document,
  documentRevisions: documentRevision,
  preferences: preference,
  complianceMessageRecipients: complianceMessageRecipient,
  complianceReviewMessages: complianceReviewMessage,
  complianceReviewNotes: complianceReviewNote,
  complianceGroups: complianceGroup,
  complianceStatuses: complianceStatus,
  users: user,
  profiles: profile,
  conversations: conversation,
  participants: participant,
  messages: message,
  participantStatuses: participantStatus,
  contacts: contact,
  userAnnouncements: announcement,
  teamInvitations: teamInvitation,
  loopFolders: loopFolder,
  loopParticipants: loopParticipant,
  loopParticipantLinks: loopParticipantLink,
  loopParticipantInvitations: loopParticipantInvitation,
  loopRoles: loopRole,
  shareWorkflows: shareWorkflow,
  shareWorkflowSteps: shareWorkflowStep,
  shareWorkflowTemplates: shareWorkflowTemplate,
  shareWorkflowStepTemplates: shareWorkflowStepTemplate,
  loopViewData,
  loopData,
  documentFields: documentField,
  documentPages: documentPage,
  userSignatures: userSignature,
  drawnSignatures: drawnSignature,
  fieldLabels: fieldLabel,
  loopDetails,
  documentClasses: documentClass,
  templateFolders: templateFolder,
  placeholders: placeholder,
  loopTaskLists: LoopTaskList,
  loopTaskListItems: LoopTaskListItem,
  templateTaskLists: TemplateTaskList,
  templateTaskListItems: TemplateTaskListItem,
  loopPeople: loopPeople,
  notifications: notification,
};

export default resourceDefinitions;
