import { Fragment, KeyboardEvent } from 'react';
import styled from 'styled-components';

import ConversationName from '~/components/Messaging/ConversationName';
import ConversationListLatestMessage from '~/components/Messaging/ConversationListLatestMessage';

import { Avatar } from '~/components/Avatar';
import { getMessageRelativeTimestamp } from './utils';

import { boxShadows, colors, fontWeights, spacing, utils } from '~/styles';

import { ParticipantStatus } from '~/store/features/api/resources/participantStatus/constants';

import { ConversationResource } from '~/store/features/api/resources/conversation/types';

const AVATAR_OFFSET = '2.5rem';
const mixins = {
  highlightedConversationItem: {
    borderLeftColor: colors.primaryLink,
    backgroundColor: colors.menuItemHighlight,
  },

  multipleParticipant: {
    width: '1.75rem',
    height: '1.75rem',
  },

  conversationTitleUnread: {
    fontWeight: fontWeights.bold,
  },
};

const StyledLatestMessageTimestamp = styled.div({
  flex: '1 0 auto',
  marginLeft: 'auto',
  textAlign: 'right',
  color: colors.hintText,
});

const StyledConversationTitle = styled.div<{
  $conversationTitleUnreadEnabled: boolean;
}>(props => ({
  ...utils.text.truncate,
  flex: '0 1 auto',
  fontWeight: fontWeights.semiBold,
  paddingRight: spacing.smaller,
  ...(props.$conversationTitleUnreadEnabled
    ? mixins.conversationTitleUnread
    : {}),
}));

const StyledConversationTitleContainer = styled.div({
  display: 'flex',
});

const StyledConversationDetails = styled.div({
  width: `calc(100% - ${AVATAR_OFFSET})`,
  paddingLeft: spacing.small,
});

const StyledParticipant = styled.div<{
  $multipleParticipantEnabled: boolean;
}>(props => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: AVATAR_OFFSET,
  height: AVATAR_OFFSET,

  '&:not(:first-of-type)': {
    top: 'unset',
    left: 'unset',
    bottom: 0,
    right: 0,
    borderRadius: '50%',
    boxShadow: boxShadows.surround,
  },

  ...(props.$multipleParticipantEnabled ? mixins.multipleParticipant : {}),
}));

const StyledParticipantList = styled.div({
  position: 'relative',
  height: AVATAR_OFFSET,
  width: AVATAR_OFFSET,
  minWidth: AVATAR_OFFSET,
});

const StyledConversationItemUnreadIndicator = styled.div({
  position: 'absolute',
  top: '50%',
  left: spacing.smallest,
  width: spacing.small,
  height: spacing.small,
  backgroundColor: colors.warningIcon,
  borderRadius: '50%',
  transform: 'translateY(-50%) ',
});

const StyledConversationItem = styled.li<{
  $highlightedConversationItemEnabled: boolean;
}>(props => ({
  position: 'relative',
  display: 'flex',
  margin: 0,
  padding: `${spacing.normal} ${spacing.normal} ${spacing.normal} ${spacing.large}`,
  borderLeft: '2px solid transparent',
  borderBottom: `1px solid ${colors.lightBorder}`,
  backgroundColor: colors.white,
  cursor: 'pointer',

  '&:hover, &:focus': {
    outline: 'none',
    backgroundColor: colors.menuItemHighlight,
  },

  ...(props.$highlightedConversationItemEnabled
    ? mixins.highlightedConversationItem
    : {}),
}));

const MAX_RECIPIENTS_TO_DISPLAY = 2;

export function isConversationUnread(conversation: ConversationResource) {
  const { participants, latestMessage } = conversation;
  const currentUserParticipant = !!participants
    ? participants.find(x => x.me)
    : null;
  const statuses =
    !!latestMessage && !!latestMessage.statuses ? latestMessage.statuses : [];
  const currentUserStatus = !!currentUserParticipant
    ? statuses.find(x => x.participantUuid === currentUserParticipant.id)
    : null;
  const isLatestMessageReadOrOpened =
    !!currentUserStatus &&
    (currentUserStatus.status === ParticipantStatus.Read ||
      currentUserStatus.status === ParticipantStatus.DocumentOpened);
  const isLatestMessageSentByCurrentUser =
    !!currentUserParticipant &&
    !!latestMessage &&
    currentUserParticipant.id === latestMessage.sender.id;
  const isUnread =
    !!latestMessage &&
    !isLatestMessageReadOrOpened &&
    !isLatestMessageSentByCurrentUser;

  return isUnread;
}

type Props = {
  conversation: ConversationResource;
  selectedConversationId: string | null;
  isConversationVisible: boolean;
  onConversationSelected: (conversationId: string) => void;
};

export default function ConversationListItem({
  conversation,
  selectedConversationId,
  isConversationVisible,
  onConversationSelected,
}: Props) {
  const { id, participants, latestMessage } = conversation;
  const recipients = !!participants ? participants.filter(x => !x.me) : [];
  const numRecipients = recipients.length;
  const hasMultipleRecipients = numRecipients > 1;
  const hasExcessRecipients = numRecipients > MAX_RECIPIENTS_TO_DISPLAY;
  const showUnreadIndicator = isConversationUnread(conversation);
  const showConversationItem = !!conversation.name || numRecipients > 0;

  return showConversationItem ? (
    <StyledConversationItem
      tabIndex={0}
      role="button"
      $highlightedConversationItemEnabled={
        id === selectedConversationId && isConversationVisible
      }
      onClick={() => onConversationSelected(id)}
      onKeyDown={(event: KeyboardEvent<HTMLLIElement>) => {
        if (event.key === 'Enter') {
          onConversationSelected(id);
        }
      }}
    >
      {showUnreadIndicator && <StyledConversationItemUnreadIndicator />}
      <StyledParticipantList>
        {recipients.length ? (
          recipients.map((participant, index) => {
            return hasExcessRecipients &&
              index >= MAX_RECIPIENTS_TO_DISPLAY ? null : (
              <StyledParticipant
                key={participant.id}
                $multipleParticipantEnabled={hasMultipleRecipients}
              >
                {hasExcessRecipients &&
                index === MAX_RECIPIENTS_TO_DISPLAY - 1 ? (
                  <Avatar
                    hasMultipleRecipients={hasMultipleRecipients}
                    additionalNumber={
                      numRecipients - MAX_RECIPIENTS_TO_DISPLAY + 1
                    }
                  />
                ) : (
                  <Avatar
                    hasMultipleRecipients={hasMultipleRecipients}
                    participant={participant}
                  />
                )}
              </StyledParticipant>
            );
          })
        ) : (
          <Avatar />
        )}
      </StyledParticipantList>
      <StyledConversationDetails>
        <StyledConversationTitleContainer>
          <StyledConversationTitle
            $conversationTitleUnreadEnabled={showUnreadIndicator}
          >
            <ConversationName conversation={conversation} />
          </StyledConversationTitle>
          {!!latestMessage && (
            <StyledLatestMessageTimestamp>
              {getMessageRelativeTimestamp(latestMessage.sentDate)}
            </StyledLatestMessageTimestamp>
          )}
        </StyledConversationTitleContainer>
        {!!latestMessage && (
          <Fragment>
            <ConversationListLatestMessage
              showUnreadIndicator={showUnreadIndicator}
              message={latestMessage}
              participants={participants}
            />
          </Fragment>
        )}
      </StyledConversationDetails>
    </StyledConversationItem>
  ) : null;
}
