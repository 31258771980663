import { Component, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { utils } from '~/styles';
import { datadogRum } from '@datadog/browser-rum';

import config from '~/config';
import { DocumentLargeIcon } from '~/components/icons';

import { DocumentResource } from '~/store/features/api/resources/document/types';
import { DocumentRevisionResource } from '~/store/features/api/resources/documentRevision/types';

const StyledImage = styled.img({
  opacity: 0,
  width: '100%',
  margin: 0,
  borderRadius: utils.baseBorderRadius,
  transition: 'opacity 0.5s ease',
});

const StyledImagePlaceholder = styled.div({
  width: '75%',
});

export const IMAGE_HEIGHT = '100px';
const {
  api: { phoenixBaseUrl: PHOENIX_API_URL },
} = config;

type Props = {
  document: DocumentResource;
  documentRevision: DocumentRevisionResource;
};

type State = {
  usePlaceholderImage: boolean;
};

function getThumbnailUrl(document: DocumentResource): string {
  return `${PHOENIX_API_URL}/document/${document.id}/page/1?width=84`;
}

export class DocumentMessageImage extends Component<Props, State> {
  documentImageVisible = false;
  documentImageRef: HTMLImageElement | null = null;

  state = {
    usePlaceholderImage: false,
  };

  showDocumentImage = () => {
    this.documentImageVisible = true;

    if (this.documentImageRef) {
      this.documentImageRef.style.opacity = '1';
    }
  };

  documentImageError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    datadogRum.addError(e, {
      action: 'document-thumbnail-image',
      url: getThumbnailUrl(this.props.document),
    });

    if (this.documentImageRef) {
      this.setState({
        usePlaceholderImage: true,
      });
    }
  };

  setupDocumentImageRef = (ref: HTMLImageElement | null) => {
    this.documentImageRef = ref;
  };

  render() {
    const { document, documentRevision } = this.props;
    const { usePlaceholderImage } = this.state;
    const documentThumbnailUrl = getThumbnailUrl(document);

    return usePlaceholderImage ? (
      <StyledImagePlaceholder id={`document-${documentRevision.id}-image`}>
        <DocumentLargeIcon width="100%" type="secondaryText" />
      </StyledImagePlaceholder>
    ) : (
      <StyledImage
        loading="lazy"
        id={`document-${documentRevision.id}-image`}
        src={documentThumbnailUrl}
        alt={`${document.name} thumbnail`}
        onLoad={this.showDocumentImage}
        onError={this.documentImageError}
        ref={this.setupDocumentImageRef}
        style={{ opacity: this.documentImageVisible ? 1 : 0 }}
      />
    );
  }
}

export default DocumentMessageImage;
