import { forwardRef, ForwardedRef, KeyboardEvent, ChangeEvent } from 'react';
import styled from 'styled-components';
import { SearchIcon, CloseIcon } from '~/components/icons';
import { colors, createTransform, spacing, utils } from '~/styles';
import { noop } from 'lodash-es';
import LinkButton, { LinkButtonType } from '~/components/buttons/LinkButton';

const mixins = {
  fullHeight: {
    height: '100%',
  },

  searchIconInset: {
    left: spacing.small,
  },

  inputWithPadding: {
    paddingLeft: `calc(${spacing.larger} + ${spacing.small})`,
  },

  fullHeightInput: {
    height: '100%',
    paddingTop: 0,
    paddingBottom: 0,
  },

  inputWithBorder: {
    border: `1px solid ${colors.lightBorder}`,
    borderRadius: utils.baseBorderRadius,

    '&:focus': {
      border: `1px solid ${colors.primaryAction}`,
    },
  },

  inputWithSearchResults: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    border: `1px solid ${colors.primaryAction}`,

    '&:focus': {
      border: `1px solid ${colors.primaryAction}`,
    },
  },

  closeIconInset: {
    right: spacing.smallest,
  },
};

const StyledCloseIcon = styled.div<{
  $closeIconInsetEnabled: boolean;
}>(props => ({
  position: 'absolute',
  top: '50%',
  right: 0,
  cursor: 'pointer',
  ...createTransform('translateY(-50%)'),
  ...(props.$closeIconInsetEnabled ? mixins.closeIconInset : {}),
}));

const StyledInput = styled.input<{
  $inputWithPaddingEnabled: boolean;
  $fullHeightInputEnabled: boolean;
  $inputWithBorderEnabled: boolean;
  $inputWithSearchResultsEnabled: boolean;
}>(props => ({
  width: '100%',
  padding: `${spacing.smaller} 0 ${spacing.smaller} calc(${spacing.larger} + ${spacing.smaller})`,
  backgroundColor: colors.white,
  border: 'none',
  outline: 'none',

  '&::-ms-clear': {
    display: 'none',
  },

  '&:focus': {
    border: 'none',
    outline: 'none',
  },

  '&::placeholder': {
    color: colors.secondaryText,
  },

  ...(props.$inputWithPaddingEnabled ? mixins.inputWithPadding : {}),
  ...(props.$fullHeightInputEnabled ? mixins.fullHeightInput : {}),
  ...(props.$inputWithBorderEnabled ? mixins.inputWithBorder : {}),
  ...(props.$inputWithSearchResultsEnabled
    ? mixins.inputWithSearchResults
    : {}),
}));

const StyledSearchIcon = styled.div<{
  $searchIconInsetEnabled: boolean;
}>(props => ({
  position: 'absolute',
  top: '50%',
  left: 0,
  width: spacing.medium,
  ...createTransform('translateY(-50%)'),
  ...(props.$searchIconInsetEnabled ? mixins.searchIconInset : {}),
}));

const StyledInputWrapper = styled.div<{
  $fullHeightEnabled: boolean;
}>(props => ({
  position: 'relative',
  WebkitTransformStyle: 'preserve-3d',
  WebkitBackfaceVisibility: 'hidden',
  WebkitPerspective: 1000,
  ...(props.$fullHeightEnabled ? mixins.fullHeight : {}),
}));

export type SearchInputProps = {
  id: string;
  name: string;
  value: string;
  inputLabel?: string;
  maxLength?: number;
  placeholder?: string;
  border?: boolean;
  padding?: boolean;
  fullHeight?: boolean;
  clearOnBlur?: boolean;
  hasSearchResults?: boolean;
  persistentCloseIcon?: boolean;
  hideCloseIcon?: boolean;
  onChange: (value: string) => void;
  onClose?: () => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
};

function SearchInputBase(
  {
    id,
    name,
    value,
    inputLabel,
    maxLength,
    placeholder,
    fullHeight,
    onClose,
    onChange,
    border = false,
    padding = true,
    clearOnBlur = false,
    hasSearchResults = false,
    persistentCloseIcon = false,
    hideCloseIcon = false,
    onKeyDown = noop,
    onKeyUp = noop,
  }: SearchInputProps,
  inputRef: ForwardedRef<HTMLInputElement>
) {
  const showCloseIcon = !hideCloseIcon && (value || persistentCloseIcon);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    onChange(value);
  };

  const handleBlur = () => {
    if (clearOnBlur) {
      clearSearch();
    }
  };

  const clearSearch = () => {
    if (onClose) {
      onClose();
    } else {
      onChange('');
    }
  };

  return (
    <StyledInputWrapper $fullHeightEnabled={!!fullHeight}>
      <StyledSearchIcon $searchIconInsetEnabled={padding}>
        <SearchIcon type="grey" width="100%" />
      </StyledSearchIcon>
      <StyledInput
        aria-label={inputLabel}
        type="text"
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        maxLength={maxLength}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onChange={handleInputChange}
        onBlur={handleBlur}
        ref={inputRef}
        autoComplete="off"
        $inputWithPaddingEnabled={padding}
        $fullHeightInputEnabled={!!fullHeight}
        $inputWithBorderEnabled={border}
        $inputWithSearchResultsEnabled={hasSearchResults}
      />
      {showCloseIcon && (
        <StyledCloseIcon $closeIconInsetEnabled={padding}>
          <LinkButton
            id={`${id}-clear-search`}
            name="clear-search"
            icon={CloseIcon}
            buttonStyle={LinkButtonType.Plain}
            defaultIconProps={{
              width: spacing.normal,
              type: 'grey',
            }}
            onClick={clearSearch}
          />
        </StyledCloseIcon>
      )}
    </StyledInputWrapper>
  );
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  SearchInputBase
);

export default SearchInput;
