import styled from 'styled-components';

import { FormattedMessage } from '~/components/i18n';
import { LockIcon } from '~/components/icons';
import { fontSizes, spacing, colors, fontWeights, breakpoints } from '~/styles';

const StyledSecondaryMessage = styled.div({
  marginTop: spacing.small,
  color: colors.secondaryText,
});

const StyledMainMessage = styled.div({
  ...fontSizes.secondaryTitle,
  marginTop: spacing.largest,
  color: colors.secondaryText,
  fontWeight: fontWeights.bold,
});

const StyledIllustration = styled.div({
  width: '4rem',
  margin: '0 auto',
});

const StyledContent = styled.div({
  marginTop: spacing.large,
  padding: `4rem ${spacing.normal}`,
  backgroundColor: colors.white,
  textAlign: 'center',
});

const StyledTitle = styled.h1({
  ...fontSizes.mainTitle,
  margin: 0,
});

const StyledContainer = styled.div({
  marginTop: spacing.large,

  [breakpoints.MEDIUM]: {
    marginTop: spacing.small,
  },
});

function NoAccess() {
  return (
    <StyledContainer>
      <StyledTitle>
        <FormattedMessage id="loops:noAccess.title" />
      </StyledTitle>
      <StyledContent>
        <StyledIllustration>
          <LockIcon width="100%" type="grey" />
        </StyledIllustration>
        <StyledMainMessage>
          <FormattedMessage id="loops:noAccess.message" />
        </StyledMainMessage>
        <StyledSecondaryMessage>
          <FormattedMessage id="loops:noAccess.secondaryMessage" />
        </StyledSecondaryMessage>
      </StyledContent>
    </StyledContainer>
  );
}

export default NoAccess;
