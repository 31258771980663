import { Component, ReactNode } from 'react';

import { NotificationContext, ContextValue } from './context';
import { NotificationType } from './types';

type NativeWebNotificationConfig = {
  message: string;
};

type Props = {
  /** Used to uniquely identify this notification */
  id: string;
  type: NotificationType;
  children: ReactNode | string;
  onDismiss: () => void;
  /** Time to dismiss, defaults to 5 seconds. Enter 0 to disable dismiss timer */
  dismissTime?: number;
  nativeNotificationConfig?: NativeWebNotificationConfig;
};

type BannerProps = ContextValue & Props;

const DISMISS_TIME = 5 * 1000;

export class SnackbarNotification extends Component<BannerProps> {
  dismissTimer: number | null = null;

  static defaultProps = {
    dismissTime: DISMISS_TIME,
  };

  componentDidMount() {
    this.showNotification();
  }

  componentDidUpdate(prevProps: Props) {
    const idChanged = prevProps.id !== this.props.id;
    const dismissTimeChanged = prevProps.dismissTime !== this.props.dismissTime;

    if (idChanged) {
      this.props.removeNotification(prevProps.id);
      this.showNotification();
    } else if (dismissTimeChanged) {
      this.setupDismissalTimer();
    }
  }

  componentWillUnmount() {
    const { id } = this.props;

    this.props.removeNotification(id);
  }

  showNotification = () => {
    const { id, type, children } = this.props;

    this.props.showNotification({
      id,
      type,
      children,
      dismiss: this.dismissNotification,
    });

    this.setupDismissalTimer();
  };

  setupDismissalTimer = () => {
    const { dismissTime } = this.props;

    this.clearTimer();

    if (!!dismissTime) {
      this.dismissTimer = window.setTimeout(() => {
        this.dismissNotification();
      }, dismissTime);
    }
  };

  dismissNotification = () => {
    this.clearTimer();

    if (this.props.onDismiss) {
      this.props.onDismiss();
    }
  };

  clearTimer = () => {
    if (this.dismissTimer) {
      window.clearTimeout(this.dismissTimer);
    }
  };

  render() {
    return null;
  }
}

const NotificationWrapper = ({
  id,
  type,
  children,
  onDismiss,
  dismissTime,
  nativeNotificationConfig,
}: Props) => (
  <NotificationContext.Consumer>
    {({
      showNotification,
      updateNotification,
      removeNotification,
    }: ContextValue) => (
      <SnackbarNotification
        id={id}
        type={type}
        onDismiss={onDismiss}
        dismissTime={dismissTime}
        nativeNotificationConfig={nativeNotificationConfig}
        showNotification={showNotification}
        updateNotification={updateNotification}
        removeNotification={removeNotification}
      >
        {children}
      </SnackbarNotification>
    )}
  </NotificationContext.Consumer>
);

export default NotificationWrapper;
