import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';

import { ResourceDefinition, ResourceTypes } from '../types';

const DrawnSignatures: ResourceDefinition = {
  attributes: {
    signatureUrl: ATTRIBUTE_TYPE.STRING,
    initialUrl: ATTRIBUTE_TYPE.STRING,
    signatureS3Key: ATTRIBUTE_TYPE.STRING,
    initialS3Key: ATTRIBUTE_TYPE.STRING,
  },
  relationships: {
    userSignature: {
      resourceType: ResourceTypes.UserSignatures,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
  },
};

export default DrawnSignatures;
