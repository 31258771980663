import { Fragment, ComponentType, ReactNode } from 'react';

import { IconProps } from '~/components/icons/types';

import BaseModal, { BaseModalConsumerProps, ModalType } from './BaseModal';
import ModalHeader, { ModalHeaderTitleID } from './ModalHeader';
import ModalBody from './ModalBody';
import { ModalAction } from './ModalActions';

type Props = BaseModalConsumerProps & {
  /** Determines if the large variant of the modal should be used */
  large?: boolean;
  /** Controls tite visibility, some modals are w/out a title */
  showTitle?: boolean;
  /** Allows control whether a max height should be set */
  enableScroll?: boolean;
  /** Optional icon to be shown in the header */
  headerIcon?: ComponentType<IconProps>;
  headerIconProps?: IconProps;
  /** Adds a back button to the modal in case there are multiple "steps" */
  canGoBack?: boolean;
  /** Event that is fired after the back button is clicked, only works if canGoBack is true */
  onNavigateBack?: () => void;
  /**
   * The primary action button for the modal: {labelId: i18nKey, onClick: event handler}
   * If this is a modal with a form, this will be a submit button for the form.
   */
  primaryAction?: ModalAction;
  /** The secondary action button for the modal: {labelId: i18nKey, onClick: event handler} */
  secondaryAction?: ModalAction;
  /**
   * Function called when the user clicks the "cancel" button.
   * If not specified, `onRequestClose` will be called instead.
   */
  cancelAction?: () => void;
  /** Hides cancel button on the modal */
  hideCancel?: boolean;
  /** If this modal contains a form, the HTML name of the form */
  formName?: string;
  /** The contents of the modal */
  children?: ReactNode;
  /** Needs to be proxied down in case this component is extended  */
  className?: string;
  /** Hides footer of the modal */
  hideFooter?: boolean;
};

export function AppModal(props: Props) {
  const {
    isOpen,
    headerIcon,
    headerIconProps,
    children,
    formName,
    cancelAction,
    hideCancel,
    primaryAction,
    secondaryAction,
    title,
    onNavigateBack,
    large = false,
    dismissable = true,
    showTitle = true,
    enableScroll = true,
    canGoBack = false,
    className,
    hideFooter = false,
    ...baseModalProps
  } = props;
  const a11yProps = showTitle
    ? { 'aria-labelledby': ModalHeaderTitleID }
    : { 'aria-label': title };

  return (
    <BaseModal
      type={large ? ModalType.Large : ModalType.Small}
      isOpen={isOpen}
      formName={formName}
      dismissable={dismissable}
      className={className}
      {...a11yProps}
      {...baseModalProps}
    >
      {({ onRequestClose }) => (
        <Fragment>
          <ModalHeader
            title={title}
            showTitle={showTitle}
            closeModal={onRequestClose}
            showClose={dismissable}
            canGoBack={canGoBack}
            headerIcon={headerIcon}
            headerIconProps={headerIconProps}
            onNavigateBack={onNavigateBack}
          />
          <ModalBody
            large={large}
            formName={formName}
            enableScroll={enableScroll}
            primaryAction={primaryAction}
            cancelAction={cancelAction || onRequestClose}
            hideCancel={hideCancel}
            secondaryAction={secondaryAction}
            hideFooter={hideFooter}
          >
            {children}
          </ModalBody>
        </Fragment>
      )}
    </BaseModal>
  );
}

export default AppModal;
