export const FILTER_QUERY_PARAMS = [
  'startDate',
  'endDate',
  'chartTypeId',
  'archivedFilter',
  'statusIds',
  'sortId',
  'sortDirection',
  'createdDate',
];

export const SEARCH_QUERY_PARAMS = [
  'loopSearch',
  'loopSearchSort',
  'loopSearchSortDirection',
  'useFilters',
];

export const VALID_FILTER_QUERY_PARAMS =
  FILTER_QUERY_PARAMS.concat(SEARCH_QUERY_PARAMS);
