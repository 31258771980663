enum Keys {
  BrowserDisclaimerKey = 'nova_browser_disclaimer',
  LoopViewType = 'LOOP_VIEW_TYPE',
  NovaFeatureFlags = 'NOVA_FEATURE_FLAGS',
  NovaLanguage = 'NOVA_LANGUAGE',
  AnnouncementSeen = 'ANNOUNCEMENT_SEEN',
}

class LocalStorageService {
  static KEYS = Keys;

  static get(key: Keys) {
    let result;

    try {
      const value = localStorage.getItem(key);

      if (value) {
        result = JSON.parse(value);
      }
    } catch (e) {}

    return result;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static set(key: Keys, value: any): boolean {
    let result = true;

    try {
      const valueAsString = JSON.stringify(value);
      localStorage.setItem(key, valueAsString);
    } catch (e) {
      result = false;
    }

    return result;
  }

  static remove(key: Keys): boolean {
    let result = true;

    try {
      localStorage.removeItem(key);
    } catch (e) {
      result = false;
    }

    return result;
  }
}

export default LocalStorageService;
