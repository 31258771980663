import { ATTRIBUTE_TYPE } from '../constants';
import { ResourceDefinition } from '../types';

const ShareWorkflowStep: ResourceDefinition = {
  attributes: {
    stepId: ATTRIBUTE_TYPE.NUMBER,
    recipientDisplayName: ATTRIBUTE_TYPE.STRING,
    workflowId: ATTRIBUTE_TYPE.NUMBER,
    revision: ATTRIBUTE_TYPE.NUMBER,
    orderNum: ATTRIBUTE_TYPE.NUMBER,
    stepIntent: ATTRIBUTE_TYPE.STRING,
    recipientViewId: ATTRIBUTE_TYPE.NUMBER,
    recipientUserId: ATTRIBUTE_TYPE.NUMBER,
    recipientProfileId: ATTRIBUTE_TYPE.NUMBER,
    recipientMemberId: ATTRIBUTE_TYPE.NUMBER,
    shareId: ATTRIBUTE_TYPE.NUMBER,
    executionTimestamp: ATTRIBUTE_TYPE.DATE,
    resolution: ATTRIBUTE_TYPE.STRING,
    completionTimestamp: ATTRIBUTE_TYPE.DATE,
    action: ATTRIBUTE_TYPE.STRING,
    emailAttachment: ATTRIBUTE_TYPE.BOOLEAN,
    stepMessage: ATTRIBUTE_TYPE.STRING,
  },
};

export default ShareWorkflowStep;
