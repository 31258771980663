import { ComponentType } from 'react';
import styled from 'styled-components';

import { ArrowIcon, CloseIcon } from '~/components/icons';
import { colors, fontSizes, fontWeights, spacing, zIndex } from '~/styles';
import { IconProps, RotationDirection } from '~/components/icons/types';
import { LinkButton } from '~/components/buttons';
import { LinkButtonType } from '~/components/buttons/LinkButton';
import EncircledIcon from '~/components/icons/EncircledIcon';

const buttonIconSize = spacing.medium; // Close and back buttons

// Gap between header items
const headerColumnGap = spacing.normal;

const StyledModalHeader = styled.div({
  flex: '0 0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

const StyledGoBackIconContainer = styled.div({
  height: buttonIconSize,
  cursor: 'pointer',
  zIndex: zIndex.aboveSibling,
  marginRight: headerColumnGap,
});

const StyledModalTitle = styled.div({
  ...fontSizes.secondaryTitle,
  color: colors.mainText,
  fontWeight: fontWeights.bold,
  flex: '1 1 auto',
});

const StyledCloseIconContainer = styled.div({
  marginLeft: headerColumnGap,
});

const ModalTitleContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

type Props = {
  title: string | null;
  showTitle?: boolean;
  showClose?: boolean;
  /** Optional icon to be shown in the header */
  headerIcon?: ComponentType<IconProps>;
  headerIconProps?: IconProps;
  /** Adds a back button to the modal in case there are multiple "steps" */
  canGoBack?: boolean;
  /** Event that is fired after the back button is clicked, only works if canGoBack is true */
  onNavigateBack?: () => void;
  closeModal: () => void;
};

export const ModalHeaderTitleID = 'modal-header-title';
export function ModalHeader(props: Props) {
  const {
    title,
    headerIcon,
    headerIconProps,
    showTitle = true,
    showClose = true,
    closeModal,
    canGoBack,
    onNavigateBack,
  } = props;

  return showTitle ? (
    <StyledModalHeader>
      {canGoBack && (
        <StyledGoBackIconContainer
          data-testid="modal-header-goBack"
          onClick={onNavigateBack}
          role="button"
        >
          <ArrowIcon
            type="grey"
            height="100%"
            direction={RotationDirection.Left}
          />
        </StyledGoBackIconContainer>
      )}

      <ModalTitleContainer>
        {!!headerIcon && (
          <EncircledIcon icon={headerIcon} iconProps={headerIconProps} />
        )}
        <StyledModalTitle
          id={ModalHeaderTitleID}
          data-testid={ModalHeaderTitleID}
        >
          {title}
        </StyledModalTitle>
      </ModalTitleContainer>

      {showClose && (
        <StyledCloseIconContainer>
          <LinkButton
            id="modal-close-button"
            icon={CloseIcon}
            buttonStyle={LinkButtonType.Plain}
            defaultIconProps={{
              height: buttonIconSize,
              type: 'grey',
            }}
            onClick={closeModal}
          />
        </StyledCloseIconContainer>
      )}
    </StyledModalHeader>
  ) : null;
}

export default ModalHeader;
