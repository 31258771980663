import { useRef } from 'react';
import {
  MINIMUM_IMAGE_HEIGHT,
  MINIMUM_IMAGE_WIDTH,
} from '~/pages/CreateLoop/constants';

interface Args {
  addPhotoFailure: () => void;
  addPhotoSuccess: (photo: string | null, photoFile: File | null) => void;
}

export function useUploadLoopPhoto({ addPhotoFailure, addPhotoSuccess }: Args) {
  const fileInputRef = useRef<null | HTMLInputElement>(null);

  const handleFileInputRef = (inputRef: HTMLInputElement | null) => {
    fileInputRef.current = inputRef;
  };

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const addLoopPhoto = (loopPhotoFile: File) => {
    const reader = new FileReader();

    reader.onload = () => {
      const photo = reader.result ?? null;
      if (photo && typeof photo === 'string') {
        const image = new Image();

        image.onload = () => {
          if (
            image.width < MINIMUM_IMAGE_WIDTH ||
            image.height < MINIMUM_IMAGE_HEIGHT
          ) {
            addPhotoFailure();
          } else {
            addPhotoSuccess(photo, loopPhotoFile);
          }
        };
        image.src = photo;
      }
    };

    reader.readAsDataURL(loopPhotoFile);
  };

  const handleFileUpload = (files: FileList) => {
    if (files && files.length === 1) {
      const loopPhotoFile = files[0];
      addLoopPhoto(loopPhotoFile);
    }
  };

  return {
    handleFileUpload,
    handleFileInputRef,
    openFileDialog,
    addLoopPhoto,
  };
}
