import { Component, Fragment, KeyboardEvent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Downshift, { StateChangeOptions } from 'downshift';
import { debounce } from 'lodash-es';

import analytics, { Categories } from '~/analytics';
import InlineTextInput from '~/components/inputs/text/InlineTextInput';
import { FormattedMessage } from '~/components/i18n';
import { CloseIcon } from '~/components/icons';

import { colors, fontSizes, fontWeights, spacing } from '~/styles';

import {
  makeAllResourcesForQuerySelector,
  areResourcesLoading,
} from '~/store/features/api/selectors';

import RecipientResultList from './RecipientResultList';
import { getRecipientName } from './utils';
import { ContactResource } from '~/store/features/api/resources/contact/types';
import { AppState } from '~/store';
import { ResourceTypes } from '~/store/features/api/resources/types';
import { fetchAllResources } from '~/store/features/api/apiSlice';

const mixins = {
  highlightChip: {
    border: `1px solid ${colors.primaryAction}`,
    backgroundColor: 'rgba(0,0,0,0.1)',
  },

  invalidChip: {
    color: colors.errorText,
    border: `1px solid ${colors.errorText}`,
    backgroundColor: colors.white,
  },

  invalidHighlightChip: {
    backgroundColor: colors.errorBackground,
  },

  selectable: {
    cursor: 'pointer',
  },

  removeInvalidChip: {
    backgroundColor: colors.errorText,
  },
};

const StyledInput = styled.div({
  flex: '1 1 auto',
  minWidth: spacing.largest,
  marginTop: spacing.small,
  padding: `${spacing.smallest} 0`,
  border: '1px solid transparent',
});

const StyledRemoveChip = styled.div<{
  $selectableEnabled: boolean;
  $removeInvalidChipEnabled: boolean;
}>(props => ({
  marginLeft: spacing.smaller,
  padding: spacing.smallest,
  backgroundColor: colors.primaryAction,
  borderRadius: '50%',
  ...(props.$selectableEnabled ? mixins.selectable : {}),
  ...(props.$removeInvalidChipEnabled ? mixins.removeInvalidChip : {}),
}));

const StyledSelectable = styled.span({
  cursor: 'pointer',
});

const StyledChipContainer = styled.span<{
  $highlightChipEnabled: boolean;
  $invalidChipEnabled: boolean;
  $invalidHighlightChipEnabled: boolean;
}>(props => ({
  backgroundColor: colors.infoBackground,
  color: colors.infoText,
  borderRadius: '18px',
  padding: `${spacing.smallest} ${spacing.smaller}`,
  marginLeft: spacing.smallest,
  display: 'flex',
  alignItems: 'center',
  marginTop: spacing.small,
  border: `1px solid transparent`,
  ...(props.$highlightChipEnabled ? mixins.highlightChip : {}),
  ...(props.$invalidChipEnabled ? mixins.invalidChip : {}),
  ...(props.$invalidHighlightChipEnabled ? mixins.invalidHighlightChip : {}),
}));

const StyledInputLabel = styled.label({
  ...fontSizes.body,
  fontWeight: fontWeights.semiBold,
  padding: `${spacing.smallest} 0`,
  marginTop: spacing.small,
});

const StyledInputContainer = styled.div({
  flex: '1 0 auto',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  width: '100%',
});

const StyledLabelContainer = styled.div({
  display: 'flex',
  marginTop: `-${spacing.small}`,
});

const StyledRecipientContactMethod = styled.span({
  fontWeight: fontWeights.bold,
  marginRight: spacing.smallest,
});

export enum RecipientRoutingType {
  Invalid = 'invalid',
  Email = 'email',
  Phone = 'phone',
  Mobile = 'mobile',
  Office = 'office',
}

export enum RecipientType {
  New = 'new',
  Existing = 'existing',
}

interface BaseRecipient {
  type: RecipientType;
}

export interface NewRecipient extends BaseRecipient {
  route: string;
  routingType: RecipientRoutingType;
  firstName?: string;
  lastName?: string;
  isValid: boolean;
  type: RecipientType.New;
}

type ExistingRecipientDetails = {
  selectedRoute: string;
  selectedRoutingType: RecipientRoutingType;
};

export interface ExistingRecipient
  extends BaseRecipient,
    ContactResource,
    ExistingRecipientDetails {
  type: RecipientType.Existing;
}

export type Recipient = NewRecipient | ExistingRecipient;

type ReduxProps = {
  isLoading: boolean;
  potentialRecipients: Array<ContactResource>;
  fetchContacts: (searchTerm: string) => Promise<Array<ContactResource>>;
};

type ComponentProps = {
  searchTerm: string;
  headerHeight: string;
  selectedRecipients: Array<Recipient>;
  updateHeaderHeight: () => void;
  addRecipient: (recipient: Recipient) => void;
  removeRecipient: (recipient: Recipient) => void;
  onSearchTermChanged: (searchTerm: string) => void;
};

type Props = ReduxProps & ComponentProps;

type State = {
  highlightedIndex: number | null;
  backspaceHighlight: boolean;
  potentialRecipients: Array<ContactResource>;
  expandedPotentialRecipient: ContactResource | null;
};

export const VALID_EMAIL_REGEX = /^[^\s]+@[^\s]+\.[^\s]+$/;
export const VALID_PHONE_REGEX = /^(\+\d+)?\d{10}$/;
function validateNewRecipientValue(value: string) {
  const isValidEmail = VALID_EMAIL_REGEX.test(value);
  const isValidPhone = VALID_PHONE_REGEX.test(value);

  return {
    routingType: isValidEmail
      ? RecipientRoutingType.Email
      : isValidPhone
      ? RecipientRoutingType.Phone
      : RecipientRoutingType.Invalid,
    isValid: isValidEmail || isValidPhone,
  };
}
function createNewRecipient(value: string): NewRecipient {
  const { routingType, isValid } = validateNewRecipientValue(value);

  return {
    route: value,
    routingType,
    isValid,
    type: RecipientType.New,
  };
}

function getRecipientDisplayValue(recipient: Recipient) {
  if (recipient.type === RecipientType.New) {
    return recipient.route;
  } else {
    return (
      <Fragment>
        <StyledRecipientContactMethod>
          <FormattedMessage
            id={`messaging.recipientContactMethod.${recipient.selectedRoutingType}`}
          />
        </StyledRecipientContactMethod>
        {getRecipientName(recipient)}
      </Fragment>
    );
  }
}

const CONTACT_TYPES: Array<keyof ContactResource> = [
  'emailAddress',
  'phoneNumber',
  'mobileNumber',
  'officeNumber',
];
function getNumberOfContactTypes(contact: ContactResource): number {
  return CONTACT_TYPES.reduce((acc, key) => {
    const contactType = contact[key as keyof ContactResource] as string;
    const hasContactType = !!contactType;
    let isValid = hasContactType;

    if (!!contactType) {
      const result = validateNewRecipientValue(contactType);

      isValid = result.isValid;
    }

    return isValid ? acc + 1 : acc;
  }, 0);
}

function contactHasMultipleContactTypes(
  contact: ContactResource | null | undefined
): boolean {
  const numTypes = !!contact ? getNumberOfContactTypes(contact) : 0;

  return numTypes > 1;
}

function getSingleContactType(
  contact: ContactResource
): ExistingRecipientDetails | undefined {
  const key = CONTACT_TYPES.find(t => !!contact[t]);
  const type =
    key === 'emailAddress'
      ? RecipientRoutingType.Email
      : RecipientRoutingType.Phone;

  if (key && (contact[key] as string)) {
    return {
      selectedRoute: contact[key] as string,
      selectedRoutingType: type,
    };
  }
}

function getExistingSelectedRecipient(
  selectedRecipients: Array<Recipient>,
  contact: ContactResource | ExistingRecipient | null | undefined
): Recipient | undefined {
  if (!!contact) {
    return selectedRecipients.find(r => {
      return 'id' in r && r.id === contact.id;
    });
  }
}

class RecipientMultiSelect extends Component<Props, State> {
  input: HTMLInputElement | null = null;
  loadingTimeout: number | null = null;
  debouncedFetchContacts: () => any;

  state: State = {
    highlightedIndex: 0,
    backspaceHighlight: false,
    potentialRecipients: [],
    expandedPotentialRecipient: null,
  };

  constructor(props: Props) {
    super(props);

    this.debouncedFetchContacts = debounce(this.fetchContacts, 300);
  }

  componentDidMount() {
    this.fetchContacts();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.searchTerm !== this.props.searchTerm) {
      this.debouncedFetchContacts();
    }
  }

  fetchContacts = () => {
    this.props.fetchContacts(this.props.searchTerm);
  };

  handleChange = (recipient: ContactResource | ExistingRecipient | null) => {
    const newRecipient = recipient;
    let existingRecipient: ExistingRecipient | null = null;
    let existingSelectedRecipient;

    if (newRecipient) {
      existingSelectedRecipient = getExistingSelectedRecipient(
        this.props.selectedRecipients,
        newRecipient
      );

      if (!existingSelectedRecipient) {
        if ('selectedRoute' in newRecipient) {
          // User has selected a contact type for the given recipient
          existingRecipient = { ...newRecipient, type: RecipientType.Existing };
        } else if (
          !('selectedRoute' in newRecipient) &&
          !('selectedRoutingType' in newRecipient)
        ) {
          // User has selected a root level contact. If that contact only
          // has one method of contact, use it. Otherwise, display the list
          // with that contact expanded to allow the user to pick one of
          // the multiple ways to contact this person
          const hasMultipleTypes = contactHasMultipleContactTypes(newRecipient);
          const firstType = getSingleContactType(newRecipient);

          if (!hasMultipleTypes && !!firstType) {
            existingRecipient = {
              ...newRecipient,
              ...firstType,
              type: RecipientType.Existing,
            };
          } else {
            this.setState(prevState => {
              const hideExpansion =
                !!prevState.expandedPotentialRecipient &&
                prevState.expandedPotentialRecipient.id === newRecipient.id;

              return {
                expandedPotentialRecipient: hideExpansion ? null : newRecipient,
              };
            });
          }
        }
      }
    }

    if (existingSelectedRecipient || existingRecipient) {
      if (existingSelectedRecipient) {
        analytics.track(
          Categories.INTERACTION,
          'messaging_create_conversation',
          'remove_recipient'
        );
        this.removeRecipient(existingSelectedRecipient);
      } else if (existingRecipient) {
        analytics.track(
          Categories.INTERACTION,
          'messaging_create_conversation',
          'add_existing_contact'
        );
        this.props.addRecipient(existingRecipient);
        this.props.onSearchTermChanged('');
        this.setState({ backspaceHighlight: false }, this.focusInput);
      }

      this.setState({ expandedPotentialRecipient: null });
    }
  };

  handleInputChange = (searchTerm: string) => {
    this.props.onSearchTermChanged(searchTerm);
    this.setState({ backspaceHighlight: false });
    this.props.updateHeaderHeight();
  };

  handleDownshiftStateChange = (
    changes: StateChangeOptions<ContactResource>
  ) => {
    if (changes.hasOwnProperty('highlightedIndex')) {
      const { expandedPotentialRecipient } = this.state;
      const { selectedRecipients } = this.props;
      const highlightClearing = changes.highlightedIndex === null;
      const hasSelectedItem = !!changes.selectedItem;
      const hasSelectedRoute =
        !!changes.selectedItem && 'selectedRoute' in changes.selectedItem;
      const hasSingleContactType =
        hasSelectedItem &&
        !contactHasMultipleContactTypes(changes.selectedItem);
      const isSelectableItem = hasSelectedRoute || hasSingleContactType;
      const isAlreadySelected =
        hasSelectedItem &&
        getExistingSelectedRecipient(selectedRecipients, changes.selectedItem);
      const isAlreadyExpanded =
        !!expandedPotentialRecipient &&
        !!changes.selectedItem &&
        expandedPotentialRecipient.id === changes.selectedItem.id;

      if (!highlightClearing || !changes.selectedItem || isSelectableItem) {
        // Only update highlighted index if we're not clearing the highlight or
        // we are clearing the highlight but the item is selectable
        this.setState({
          highlightedIndex:
            changes.highlightedIndex === undefined
              ? null
              : changes.highlightedIndex,
        });
      } else if (
        highlightClearing &&
        !hasSingleContactType &&
        !isAlreadySelected &&
        !isAlreadyExpanded
      ) {
        // We've selected a contact with multiple contact types, so advance
        // highlightedIndex so it selects the first available type if this
        // contact is not already expanded or selected
        this.setState(prevState => ({
          highlightedIndex: prevState.highlightedIndex || 0 + 1,
        }));
      }
    }
  };

  clearLoadingTimeout = () => {
    if (this.loadingTimeout) {
      clearTimeout(this.loadingTimeout);
    }
  };

  handleInputRef = (input: HTMLInputElement | null) => {
    this.input = input;
  };

  focusInput = () => {
    if (this.input) {
      this.input.focus();
    }
  };

  handleChipSelected = (recipient: Recipient) => {
    const value =
      recipient.type === RecipientType.New
        ? recipient.route
        : getRecipientName(recipient);

    this.removeRecipient(recipient);
    this.props.onSearchTermChanged(value);
    this.focusInput();
  };

  handleRemoveChip = (recipient: Recipient) => {
    this.removeRecipient(recipient);
  };

  handleAddNewItem = (value: string) => {
    if (!!value) {
      const newRecipient = createNewRecipient(value);

      analytics.track(
        Categories.INTERACTION,
        'messaging_create_conversation',
        'add_new_contact'
      );
      this.props.addRecipient(newRecipient);
      this.props.onSearchTermChanged('');
    }
  };

  removeLastChip = () => {
    const { selectedRecipients } = this.props;

    if (selectedRecipients && selectedRecipients.length) {
      const chipToRemove = selectedRecipients[selectedRecipients.length - 1];

      this.handleRemoveChip(chipToRemove);
      this.setState(
        {
          backspaceHighlight: false,
        },
        () => {
          if (this.input) {
            this.input.focus();
          }
        }
      );
    }
  };

  removeRecipient = (recipient: Recipient) => {
    analytics.track(
      Categories.INTERACTION,
      'messaging_create_conversation',
      'remove_recipient'
    );
    this.props.removeRecipient(recipient);
  };

  onInputBlur = () => {
    const { searchTerm } = this.props;

    if (!!searchTerm) {
      this.handleAddNewItem(searchTerm);
    }
  };

  onInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { searchTerm, potentialRecipients } = this.props;

    switch (event.key) {
      case 'Delete':
        if (this.state.backspaceHighlight) {
          event.preventDefault();
          this.removeLastChip();
        }
        break;
      case 'Backspace': // backspace
        if (!searchTerm) {
          event.preventDefault();

          if (this.state.backspaceHighlight) {
            this.removeLastChip();
          } else {
            this.setState({
              backspaceHighlight: true,
            });
          }
        } else {
          this.setState({
            backspaceHighlight: false,
          });
        }
        break;
      case 'Tab':
      case ',':
      case ';':
        if (potentialRecipients.length === 1) {
          event.preventDefault();
          this.handleChange(potentialRecipients[0]);
        } else if (searchTerm) {
          event.preventDefault();
          this.handleAddNewItem(searchTerm);
        }
        break;
      case ' ':
        if (searchTerm) {
          const { isValid } = validateNewRecipientValue(searchTerm);

          if (isValid) {
            event.preventDefault();
            this.handleAddNewItem(searchTerm);
          }
        }
        break;
      case 'Enter':
        const { highlightedIndex } = this.state;
        const noPotentialRecipients = potentialRecipients.length === 0;
        const noHighlightedIndex =
          highlightedIndex === null || highlightedIndex === undefined;

        if (searchTerm && (noPotentialRecipients || noHighlightedIndex)) {
          event.preventDefault();
          this.handleAddNewItem(searchTerm);
        }
        break;
      default:
        return;
    }
  };

  render() {
    const {
      headerHeight,
      potentialRecipients,
      selectedRecipients,
      isLoading,
      searchTerm,
    } = this.props;
    const { backspaceHighlight, highlightedIndex, expandedPotentialRecipient } =
      this.state;

    const validPotentialRecipients = potentialRecipients.filter(recipient => {
      return getNumberOfContactTypes(recipient) > 0;
    });

    return (
      <Downshift<ContactResource | ExistingRecipient>
        isOpen
        highlightedIndex={highlightedIndex}
        onChange={this.handleChange}
        onStateChange={this.handleDownshiftStateChange}
        inputValue={searchTerm}
        itemToString={item =>
          !!item && 'selectedRoute' in item ? item.selectedRoute : ''
        }
        selectedItem={null}
      >
        {({
          getRootProps,
          getLabelProps,
          getInputProps,
          getItemProps,
          highlightedIndex,
        }) => {
          const inputProps = getInputProps({
            onKeyDown: this.onInputKeyDown,
            onBlur: this.onInputBlur,
            size: 5,
          });
          const hasSelectedRecipients = selectedRecipients.length > 0;

          return (
            <div {...getRootProps(undefined, { suppressRefError: true })}>
              <StyledLabelContainer>
                <StyledInputContainer>
                  <StyledInputLabel {...getLabelProps()}>To:</StyledInputLabel>
                  {selectedRecipients.map((selectedItem, index) => {
                    const isHighlighted =
                      selectedRecipients.length === index + 1 &&
                      backspaceHighlight;
                    const isChipInvalid =
                      selectedItem.type === RecipientType.New &&
                      !!selectedItem.route &&
                      !selectedItem.isValid;

                    return (
                      <StyledChipContainer
                        key={index}
                        $highlightChipEnabled={isHighlighted}
                        $invalidChipEnabled={isChipInvalid}
                        $invalidHighlightChipEnabled={
                          isHighlighted && isChipInvalid
                        }
                      >
                        <StyledSelectable
                          onClick={() => this.handleChipSelected(selectedItem)}
                        >
                          {getRecipientDisplayValue(selectedItem)}
                        </StyledSelectable>
                        <StyledRemoveChip
                          $selectableEnabled
                          $removeInvalidChipEnabled={isChipInvalid}
                          onClick={() => this.handleRemoveChip(selectedItem)}
                        >
                          <CloseIcon
                            width="0.6rem"
                            type="primaryBackgroundText"
                          />
                        </StyledRemoveChip>
                      </StyledChipContainer>
                    );
                  })}
                  <StyledInput>
                    <InlineTextInput
                      {...inputProps}
                      id="recipient-input"
                      name="recipient-input"
                      label=""
                      value={searchTerm}
                      autoAdjustToContent
                      mutedPlaceholder
                      placeholder="Enter a name, phone number, or email"
                      showPlaceholder={!hasSelectedRecipients}
                      ref={this.handleInputRef}
                      onChange={this.handleInputChange}
                    />
                  </StyledInput>
                </StyledInputContainer>
              </StyledLabelContainer>
              <RecipientResultList
                topOffset={headerHeight}
                isLoading={isLoading}
                potentialRecipients={validPotentialRecipients}
                expandedPotentialRecipient={expandedPotentialRecipient}
                selectedRecipients={selectedRecipients}
                searchTerm={searchTerm}
                getItemProps={getItemProps}
                highlightedIndex={highlightedIndex}
              />
            </div>
          );
        }}
      </Downshift>
    );
  }
}

function generateSearchOptions(searchTerm: string) {
  return {
    additionalOptions: {
      [`filter[searchTerm]`]: searchTerm,
    },
  };
}
const mapDispatchToProps = {
  fetchContacts: (searchValue: string) =>
    fetchAllResources({
      resourceName: ResourceTypes.Contacts,
      options: generateSearchOptions(searchValue),
    }),
};

function makeMapStateToProps() {
  const matchingContactsSelector = makeAllResourcesForQuerySelector<
    ContactResource,
    ComponentProps
  >(ResourceTypes.Contacts, (_state: AppState, ownProps: ComponentProps) =>
    generateSearchOptions(ownProps.searchTerm)
  );

  return (state: AppState, ownProps: ComponentProps) => ({
    potentialRecipients: matchingContactsSelector(state, ownProps),
    isLoading: areResourcesLoading(state, ResourceTypes.Contacts),
  });
}

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(RecipientMultiSelect as any);
