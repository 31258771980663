import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';

import { ResourceDefinition, ResourceTypes } from '../types';

const Conversation: ResourceDefinition = {
  attributes: {
    name: ATTRIBUTE_TYPE.STRING,
    conversationType: ATTRIBUTE_TYPE.STRING,
    creatorParticipantId: ATTRIBUTE_TYPE.STRING,
    createdDate: ATTRIBUTE_TYPE.DATE,
    archived: ATTRIBUTE_TYPE.BOOLEAN,
  },

  relationships: {
    participants: {
      resourceType: ResourceTypes.Participants,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
    messages: {
      resourceType: ResourceTypes.Messages,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
    latestMessage: {
      resourceType: ResourceTypes.Messages,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
  },
};

export default Conversation;
