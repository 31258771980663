import { Fragment } from 'react';

import { ConversationType } from '~/store/features/api/resources/conversation/constants';

import { ConversationResource } from '~/store/features/api/resources/conversation/types';

export function getConversationName(
  conversation?: ConversationResource
): string | null {
  let conversationName: string | null = null;

  if (conversation) {
    const { conversationType, name, participants } = conversation;
    conversationName = name;

    if (conversationType === ConversationType.Direct) {
      const recipient = participants ? participants.find(x => !x.me) : null;

      if (recipient && recipient.firstName && recipient.lastName) {
        conversationName = `${recipient.firstName} ${recipient.lastName}`;
      } else if (recipient) {
        switch (recipient.routingType) {
          case 'EMAIL':
            conversationName = recipient.emailAddress as string;
            break;
          case 'PHONE_NUMBER':
            conversationName = recipient.phoneNumber as string;
            break;
          default:
            conversationName = 'Unknown';
        }
      } else {
        conversationName = 'Unknown';
      }
    }
  }

  return conversationName;
}

type Props = {
  conversation: ConversationResource;
};

export function ConversationName(props: Props) {
  const { conversation } = props;
  const name = getConversationName(conversation);

  return <Fragment>{name || null}</Fragment>;
}

export default ConversationName;
