import { useEffect, useRef } from 'react';
import { makePhoenixRequest, makeVegaRequest } from '~/lib/api/network';
import { Notification } from '~/pages/LoopDetails/components/Notifications/Notifications';
import { datadogRum } from '@datadog/browser-rum';

export type NotificationCount = {
  count: number;
};

export async function fetchNotificationsForLoop(
  loopId: string,
  batchSize = 20,
  batchNumber = 1
): Promise<Array<Notification>> {
  try {
    const response = await makePhoenixRequest(
      `notification/view/${loopId}?batchSize=${batchSize}&batchNumber=${batchNumber}`,
      {
        method: 'GET',
      }
    );

    const notifications: Array<Notification> = response.payload;

    return notifications || [];
  } catch (e) {
    return [];
  }
}

export async function clearNotification(notificationId: number) {
  try {
    const response = await makePhoenixRequest(
      `notification/${notificationId}/clear`,
      {
        method: 'POST',
      }
    );
    return response;
  } catch (e) {
    datadogRum.addError(e, { action: 'clear-notification' });
    return { status: 500 };
  }
}

export async function clearAllNotificationsInLoop(loopId: string) {
  try {
    const response = await makePhoenixRequest(
      `notification/view/${loopId}/clear`,
      {
        method: 'POST',
      }
    );
    return response;
  } catch (e) {
    datadogRum.addError(e, { action: 'clear-all-loop-notifications' });
    return { status: 500 };
  }
}

export async function deleteNotification(notificationId: number) {
  try {
    const response = await makeVegaRequest(`notifications/${notificationId}`, {
      method: 'DELETE',
    });
    return response;
  } catch (e) {
    datadogRum.addError(e, { action: 'delete-notification' });
    return { status: 500 };
  }
}

export async function fetchUnviewedNotificationCount(): Promise<number> {
  try {
    const response = await makeVegaRequest(
      'userNotifications/actions/unviewedCount',
      {
        method: 'GET',
      }
    );
    return response.payload.count;
  } catch (e) {
    return 0;
  }
}

export function useUserNotificationCount(): number {
  const countRef = useRef<number>(-1);

  useEffect(() => {
    if (countRef.current === -1) {
      fetchUnviewedNotificationCount().then(
        count => (countRef.current = count)
      );
    }
  }, []);

  return countRef.current === -1 ? 0 : countRef.current;
}

export default useUserNotificationCount;
