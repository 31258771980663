import { isValidElement, useMemo } from 'react';

import { encodeReactKey, injectReactElements } from '~/components/utils';
import { I18nContext } from './context';

export type Values = {
  [key: string]: any;
};
type Props = {
  id: string;
  values?: Values;
};
type Reducer = { nonReactElements: Values; reactElements: Values };

export function FormattedMessage(props: Props) {
  const { id, values } = props;
  const { nonReactElements, reactElements } = useMemo(() => {
    const initialData: Reducer = { nonReactElements: {}, reactElements: {} };
    if (values) {
      const result = Object.keys(values).reduce((data, key) => {
        const val = values[key];

        if (typeof val === 'object' && isValidElement(val)) {
          data.reactElements[key] = val;
          data.nonReactElements[key] = encodeReactKey(key);
        } else {
          data.nonReactElements[key] = val;
        }

        return data;
      }, initialData);

      return result;
    }

    return initialData;
  }, [values]);

  return (
    <I18nContext.Consumer>
      {context => {
        if (!context) {
          return null;
        }

        const result = context.i18n.t(id, nonReactElements);
        const finalResult = injectReactElements(result, reactElements);

        return finalResult;
      }}
    </I18nContext.Consumer>
  );
}

export default FormattedMessage;
