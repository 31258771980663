import { useEffect } from 'react';

import { usePubNubWebSockeService } from '~/hooks';
import { useAccount } from '~/hooks/api/account';
import store from '~/store';

const PubNubInitializer = () => {
  const webSocketService = usePubNubWebSockeService();
  const { userId } = useAccount();

  useEffect(() => {
    if (webSocketService) {
      webSocketService.init(store.dispatch);
    }
  }, [webSocketService]);

  useEffect(() => {
    if (userId > 0 && webSocketService) {
      webSocketService.setUserId(userId);
    }
  }, [userId, webSocketService]);

  return null;
};

export default PubNubInitializer;
