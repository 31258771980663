import { useRef, useState, Fragment, ReactNode } from 'react';
import styled from 'styled-components';
import {
  boxShadows,
  colors,
  fontSizes,
  spacing,
  utils,
  createTransform,
  breakpoints,
} from '~/styles';
import autoId from '~/lib/utils/auto-id';
import { FormattedMessage } from '~/components/i18n';

const ANIMATION_DURATION = '0.15s';
const ANIMATION_LONG = '0.3s';
const ANIMATION_EASING = 'ease-out';

const DEFAULT_CHECKBOX_WIDTH_HEIGHT = fontSizes.secondaryTitle.fontSize;
const SMALL_CHECKBOX_WIDTH_HEIGHT = fontSizes.headline.fontSize;

const StyledErrorMessage = styled.span({
  marginRight: spacing.smaller,
  ...fontSizes.callout,
  ...utils.text.truncate,
});

const StyledErrorText = styled.div({
  marginLeft: spacing.small,
  color: colors.errorText,
  height: spacing.large,
  display: 'flex',
});

const StyledCheckboxInput = styled.input<{
  $checkboxInputRelaxedEnabled: boolean;
  $checkboxInputSmallEnabled: boolean;
  $checkboxInputWithStrikethroughEnabled: boolean;
  $requiredCheckboxInputEnabled: boolean;
  $checkboxInputWithoutLabelEnabled: boolean;
  $rounded: boolean;
}>(props => ({
  position: 'absolute',
  display: 'inline',
  top: 0,
  left: 0,
  margin: 0,
  opacity: 0,
  outline: 0,

  '&:checked': {
    '~ label .svgCheckboxContainer': {
      borderColor: colors.primaryAction,
      backgroundColor: colors.primaryAction,

      'svg path': {
        strokeDashoffset: '0',
        transitionDelay: ANIMATION_DURATION,
      },
    },
  },

  '&:checked:disabled': {
    '~ label .svgCheckboxContainer': {
      borderColor: colors.lightBorder,
      transitionDelay: '0s',

      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: colors.lightBackground,
        opacity: 0.5,
      },
    },
  },

  '~ label': {
    display: 'block',
    margin: 0,
    width: '100%',
    minWidth: props.$checkboxInputSmallEnabled
      ? SMALL_CHECKBOX_WIDTH_HEIGHT
      : DEFAULT_CHECKBOX_WIDTH_HEIGHT,
    minHeight: props.$checkboxInputSmallEnabled
      ? SMALL_CHECKBOX_WIDTH_HEIGHT
      : DEFAULT_CHECKBOX_WIDTH_HEIGHT,
    paddingLeft: props.$checkboxInputWithoutLabelEnabled
      ? 0
      : props.$checkboxInputRelaxedEnabled
      ? `calc(${DEFAULT_CHECKBOX_WIDTH_HEIGHT} + ${spacing.normal})`
      : `calc(${DEFAULT_CHECKBOX_WIDTH_HEIGHT} + ${spacing.smaller})`,
    cursor: 'pointer',
    textDecoration: props.$checkboxInputWithStrikethroughEnabled
      ? 'line-through'
      : '',

    '.svgCheckboxContainer': {
      ...createTransform('translateY(-50%)'),
      position: 'absolute',
      top: '50%',
      left: props.$requiredCheckboxInputEnabled ? spacing.small : '0',
      width: props.$checkboxInputSmallEnabled
        ? SMALL_CHECKBOX_WIDTH_HEIGHT
        : DEFAULT_CHECKBOX_WIDTH_HEIGHT,
      height: props.$checkboxInputSmallEnabled
        ? SMALL_CHECKBOX_WIDTH_HEIGHT
        : DEFAULT_CHECKBOX_WIDTH_HEIGHT,
      border: `1px solid ${colors.lightBorder}`,
      borderRadius: props.$rounded ? '50%' : utils.baseBorderRadius,
      backgroundColor: colors.white,

      transition: `background-color ${ANIMATION_LONG} ${ANIMATION_EASING} ${ANIMATION_DURATION},
        border-color ${ANIMATION_LONG} ${ANIMATION_EASING} ${ANIMATION_DURATION},
        box-shadow ${ANIMATION_LONG} ${ANIMATION_EASING} ${ANIMATION_DURATION}`,

      svg: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',

        path: {
          stroke: colors.primaryBackgroundText,
          transition: `stroke-dashoffset ${ANIMATION_DURATION} ${ANIMATION_EASING}`,
        },
      },
    },
  },

  '&:focus ~ label .svgCheckboxContainer': {
    borderColor: colors.primaryAction,
    boxShadow: boxShadows.normalWithColor(colors.primaryAction),
  },

  '&[aria-invalid="true"] ~ label .svgCheckboxContainer': {
    borderColor: colors.errorColor,
    boxShadow: `0 0 0 1px ${colors.errorColor}`,
  },

  '&[aria-invalid="true"]:focus ~ label .svgCheckboxContainer': {
    borderColor: colors.errorColor,
    boxShadow: boxShadows.normalWithColor(colors.errorColor),
  },

  '&:disabled ~ label': {
    cursor: 'not-allowed',
    color: colors.secondaryText,
    '.svgCheckboxContainer': {
      backgroundColor: colors.backgroundGray,
      'svg path': {
        stroke: colors.black,
      },
    },
  },
}));

const StyledCheckboxContainer = styled.div<{
  $requiredCheckboxContainerEnabled: boolean;
  $requiredCheckboxContainerErrorStateEnabled: boolean;
}>(props => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  ...(props.$requiredCheckboxContainerEnabled
    ? {
        width: '100%',
        padding: spacing.normal,
        paddingLeft: 'none',
        border: '1px solid transparent',
        borderRadius: utils.baseBorderRadius,
      }
    : {}),
  ...(props.$requiredCheckboxContainerErrorStateEnabled
    ? { border: `1px solid ${colors.errorColor}` }
    : {}),
}));

const StyledTruncate = styled.div<{ $truncate: boolean }>(props =>
  props.$truncate
    ? {
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        [breakpoints.MEDIUM]: {
          WebkitLineClamp: 2,
        },
      }
    : {}
);

type Props = {
  /** HTML ID attribute for the checkbox input */
  id?: string;
  /** The label for the checkbox */
  label: string | ReactNode;
  /** State of the checkbox, checked or unchecked */
  isChecked?: boolean;
  /** Whether or not the checkbox is disabled */
  disabled?: boolean;
  /** Small variant of the checkbox */
  small?: boolean;
  /** Adds a strikethrough to the label */
  strikethrough?: boolean;
  /** Adds extra padding between label and checkbox */
  relaxedLabel?: boolean;
  /** Forces a specific width / height (used in document editor) */
  overrideSize?: number;
  /** Hides the label and places it as a title on the checkbox SVG */
  hideLabel?: boolean;
  /** Called when the checked state of the checkbox changes */
  onChange: (isChecked: boolean) => void;
  /** Function called to render the label for the radio button */
  labelRender?: (label: string) => ReactNode;
  /** shows Error css when checkbox is not checked and has been "touched" */
  required?: boolean;
  /**
   * Indicates the form has been submitted and the error state should be displayed
   * if {required} and not checked, even if the input wasn't yet "touched"
   */
  formSubmitted?: boolean;
  rounded?: boolean;
  truncate?: boolean;
  /** Needs to be proxied down in case this component is extended  */
  className?: string;
};

export function Checkbox(props: Props) {
  const {
    label,
    overrideSize,
    labelRender,
    onChange,
    small = false,
    disabled = false,
    hideLabel = false,
    required = false,
    isChecked = false,
    relaxedLabel = false,
    strikethrough = false,
    formSubmitted = false,
    rounded = false,
    truncate = false,
    className,
  } = props;
  const [wasTouched, setWasTouched] = useState(false);
  const idRef = useRef(props.id || autoId());
  const id = idRef.current;
  const isMissingAndRequired = required && !isChecked;
  const invalid = (wasTouched || formSubmitted) && isMissingAndRequired;
  const labelToRender =
    labelRender && typeof label === 'string' ? labelRender(label) : label;
  const overrideStyles =
    overrideSize === undefined
      ? {}
      : {
          width: overrideSize,
          height: overrideSize,
        };
  const labelOverrideStyles =
    overrideSize === undefined
      ? {}
      : {
          minWidth: overrideSize,
          minHeight: overrideSize,
          paddingLeft: `calc(${overrideSize}px + ${spacing.smaller})`,
        };

  const toggleChecked = () => {
    if (onChange) {
      onChange(!isChecked);
    }
  };

  return (
    <Fragment>
      <StyledCheckboxContainer
        $requiredCheckboxContainerEnabled={required}
        $requiredCheckboxContainerErrorStateEnabled={invalid}
      >
        <StyledCheckboxInput
          type="checkbox"
          id={id}
          checked={isChecked}
          disabled={disabled}
          onChange={toggleChecked}
          onBlur={() => setWasTouched(true)}
          $checkboxInputRelaxedEnabled={relaxedLabel}
          $checkboxInputSmallEnabled={small}
          $checkboxInputWithStrikethroughEnabled={strikethrough}
          $requiredCheckboxInputEnabled={required}
          $checkboxInputWithoutLabelEnabled={
            hideLabel || (!label && !labelRender)
          }
          aria-describedby="checkboxError"
          aria-invalid={invalid}
          $rounded={rounded}
          className={className}
        />

        <label htmlFor={id} style={labelOverrideStyles}>
          {!hideLabel && (
            <StyledTruncate $truncate={truncate}>
              {labelToRender}
            </StyledTruncate>
          )}
          <div className="svgCheckboxContainer" style={overrideStyles}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              {hideLabel && <title>{labelToRender}</title>}
              <path
                fill="none"
                strokeWidth="5"
                strokeDasharray="60"
                strokeDashoffset="60"
                d="M6 28l12 12 28-28"
              />
            </svg>
          </div>
        </label>
      </StyledCheckboxContainer>
      {required && (
        <StyledErrorText>
          {isMissingAndRequired && (wasTouched || formSubmitted) && (
            <StyledErrorMessage id="checkboxError">
              <FormattedMessage
                id="errors.inputs.requiredCheckbox"
                values={{ label: labelToRender }}
              />
            </StyledErrorMessage>
          )}
        </StyledErrorText>
      )}
    </Fragment>
  );
}

export default Checkbox;
