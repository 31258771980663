import styled from 'styled-components';

import ClampedText from '~/components/ClampedText';
import { injectI18n } from '~/components/i18n';
import { colors, fontWeights } from '~/styles';
import { MessageType } from '~/store/features/api/resources/message/constants';

import { getParticipantLabel } from './utils';

import {
  MessageResource,
  DocumentMessageResource,
  TextMessageResource,
} from '~/store/features/api/resources/message/types';
import { ParticipantResource } from '~/store/features/api/resources/participant/types';
import { I18nType } from '~/components/i18n/types';

const mixins = {
  latestMessageUnread: {
    fontWeight: fontWeights.bold,
  },
};

const StyledLatestMessage = styled.div<{
  $latestMessageUnreadEnabled: boolean;
}>(props => ({
  position: 'relative',
  width: '100%',
  margin: 0,
  color: colors.hintText,
  ...(props.$latestMessageUnreadEnabled ? mixins.latestMessageUnread : {}),
}));

type BaseProps = {
  i18n: I18nType;
  message: MessageResource;
  participants: Array<ParticipantResource>;
};

type Props = {
  showUnreadIndicator: boolean;
} & BaseProps;

function getParticipantLabelForMessage({
  message,
  participants,
  i18n,
}: BaseProps): string {
  const {
    sender: { id },
  } = message;
  const senderParticipant = participants && participants.find(x => x.id === id);
  let label;

  if (!!senderParticipant) {
    if (senderParticipant.me) {
      label = i18n.t('messaging.fromYou');
    } else {
      label = getParticipantLabel(senderParticipant);

      if (label) {
        label = `${label}: `;
      }
    }
  }

  return label || '';
}

type DocumentProps = {
  i18n: I18nType;
  showUnreadIndicator: boolean;
  message: DocumentMessageResource;
  participants: Array<ParticipantResource>;
};
function DocumentLatestMessage({
  message,
  participants,
  showUnreadIndicator,
  i18n,
}: DocumentProps) {
  const participantLabel = getParticipantLabelForMessage({
    message,
    participants,
    i18n,
  });
  const documentText = i18n.t('messaging.attachment', {
    name: message.document.name,
  });

  return (
    <StyledLatestMessage $latestMessageUnreadEnabled={showUnreadIndicator}>
      <ClampedText text={`${participantLabel}${documentText}`} lineClamp={2} />
    </StyledLatestMessage>
  );
}

type TextProps = {
  i18n: I18nType;
  showUnreadIndicator: boolean;
  message: TextMessageResource;
  participants: Array<ParticipantResource>;
};
function TextLatestMessage({
  message,
  participants,
  showUnreadIndicator,
  i18n,
}: TextProps) {
  const participantLabel = getParticipantLabelForMessage({
    message,
    participants,
    i18n,
  });

  return (
    <StyledLatestMessage $latestMessageUnreadEnabled={showUnreadIndicator}>
      <ClampedText text={`${participantLabel}${message.text}`} lineClamp={2} />
    </StyledLatestMessage>
  );
}

export function ConversationListLatestMessage({
  showUnreadIndicator,
  message,
  participants,
  i18n,
}: Props) {
  if (message.messageType === MessageType.Document) {
    return (
      <DocumentLatestMessage
        showUnreadIndicator={showUnreadIndicator}
        message={message}
        participants={participants}
        i18n={i18n}
      />
    );
  } else if (message.messageType === MessageType.Text) {
    return (
      <TextLatestMessage
        showUnreadIndicator={showUnreadIndicator}
        message={message}
        participants={participants}
        i18n={i18n}
      />
    );
  } else {
    return null;
  }
}

export default injectI18n(ConversationListLatestMessage);
