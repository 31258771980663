import { Fragment, useState } from 'react';
import styled from 'styled-components';

import config from '~/config';
import { LinkButton } from '~/components/buttons';
import { FormattedDate, FormattedMessage } from '~/components/i18n';
import { DATE_FORMATS } from '~/components/i18n/utils';

import DocumentMessageImage from './DocumentMessageImage';
import DocumentFieldChange from './DocumentFieldChange';

import { DocumentMessageResource } from '~/store/features/api/resources/message/types';
import { DocumentFieldChange as DocumentFieldChangeType } from '~/store/features/api/resources/documentRevision/types';

import { breakpoints, colors, fontSizes, fontWeights, spacing } from '~/styles';

const mixins = {
  documentMessageSentByMe: {
    marginLeft: 'auto',
  },
};

const StyledShowMoreButtonWrapper = styled.div({
  marginTop: spacing.smaller,
});

const StyledDocumentFieldChangeItem = styled.li({
  ...fontSizes.callout,
  margin: 0,
});

const StyledDocumentFieldChangeList = styled.ul({
  marginBottom: 0,
  padding: 0,
  listStyle: 'disc',
});

const StyledDocumentChangePage = styled.div({
  ...fontSizes.callout,

  '&:not(:first-of-type)': {
    marginTop: spacing.smaller,
  },
});

const StyledDocumentFieldChanges = styled.div({
  width: '100%',
  padding: spacing.normal,
  borderTop: `1px solid ${colors.lightBorder}`,
});

const StyledOpenDocumentLink = styled.div({
  marginTop: 'auto',
});

const StyledSecondaryDetails = styled.div({
  ...fontSizes.callout,
  flex: '0 0 auto',
  color: colors.secondaryText,
});

const StyledDocumentName = styled.div({
  flex: '0 0 auto',
  fontWeight: fontWeights.semiBold,
});

const StyledDocumentMessageDetails = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 auto',
  marginLeft: spacing.small,
  maxWidth: `calc(100% - 4rem - ${spacing.small})`,

  [breakpoints.MEDIUM]: {
    maxWidth: `calc(100% - 5rem - ${spacing.small})`,
  },
});

const StyledDocumentMessageImage = styled.a({
  display: 'flex',
  alignItems: 'center',
  flex: '0 0 4rem',
  width: '4rem',

  [breakpoints.MEDIUM]: {
    flex: '0 0 5.5rem',
    width: '5.5rem',
  },
});

const StyledDocumentMessage = styled.div<{
  $documentMessageSentByMeEnabled: boolean;
}>(props => ({
  display: 'flex',
  width: '100%',
  padding: spacing.normal,
  ...(props.$documentMessageSentByMeEnabled
    ? mixins.documentMessageSentByMe
    : {}),
}));

const StyledMessageContainer = styled.div({
  border: `1px solid ${colors.darkBorder}`,
  borderRadius: '12px',
});

const MAX_INITIAL_FIELD_CHANGES = 4;
const {
  ui: { phoenixUrl },
} = config;

type PageChanges = {
  [pageNumber: number]: {
    pageNumber: number;
    changes: Array<DocumentFieldChangeType>;
  };
};

type Props = {
  message: DocumentMessageResource;
  sentByMe: boolean;
};

export default function DocumentMessage(props: Props) {
  const {
    sentByMe,
    message,
    message: { document, documentRevision },
  } = props;
  const [showMoreFieldChanges, setShowMoreFieldChanges] = useState(false);
  const documentLinkUrl = `${phoenixUrl}/my/loop/${document.loop.id}/file/${document.id}-${documentRevision.revision}`;
  const fieldChanges = documentRevision.documentFieldChanges;
  const changesByPage: PageChanges = !!fieldChanges
    ? fieldChanges.reduce((pages: PageChanges, change) => {
        if (!pages[change.pageNumber]) {
          pages[change.pageNumber] = {
            pageNumber: change.pageNumber,
            changes: [change],
          };
        } else {
          pages[change.pageNumber].changes.push(change);
        }

        return pages;
      }, {})
    : {};
  const sortedFieldChanges = !!fieldChanges
    ? Object.keys(changesByPage)
        .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
        .map(pageNumber => ({
          pageNumber,
          changes: changesByPage[parseInt(pageNumber, 10)].changes,
        }))
    : [];
  const hasMoreFieldChanges = !!fieldChanges
    ? fieldChanges.length > MAX_INITIAL_FIELD_CHANGES
    : false;
  let currentNumberOfFieldChanges = 0;

  return (
    <StyledMessageContainer data-testid="conversation-document-message">
      <StyledDocumentMessage $documentMessageSentByMeEnabled={sentByMe}>
        <StyledDocumentMessageImage
          id={`open-document-${document.id}-image`}
          href={documentLinkUrl}
        >
          <DocumentMessageImage
            document={document}
            documentRevision={documentRevision}
          />
        </StyledDocumentMessageImage>
        <StyledDocumentMessageDetails>
          <StyledDocumentName>{document.name}</StyledDocumentName>
          <StyledSecondaryDetails>
            <FormattedMessage
              id="messaging.documentLoopName"
              values={{ name: document.loop.name }}
            />
          </StyledSecondaryDetails>
          <StyledSecondaryDetails>
            <FormattedDate
              date={message.sentDate}
              dateFormat={DATE_FORMATS.SHORT_DATE_TIME}
            />
          </StyledSecondaryDetails>
          <StyledOpenDocumentLink>
            <LinkButton
              removeSidePadding
              removeTopBottomPadding
              id={`open-document-${document.id}`}
              link={documentLinkUrl}
            >
              <FormattedMessage id="actions.open" />
            </LinkButton>
          </StyledOpenDocumentLink>
        </StyledDocumentMessageDetails>
      </StyledDocumentMessage>
      {!!sortedFieldChanges && sortedFieldChanges.length > 0 && (
        <StyledDocumentFieldChanges>
          {sortedFieldChanges.map((pageChanges, index) => {
            if (
              showMoreFieldChanges ||
              currentNumberOfFieldChanges + 1 <= MAX_INITIAL_FIELD_CHANGES
            ) {
              return (
                <Fragment key={index}>
                  <StyledDocumentChangePage>
                    <FormattedMessage
                      id={`messaging.documentFieldChanges.pageEdits`}
                      values={{ pageNumber: pageChanges.pageNumber }}
                    />
                  </StyledDocumentChangePage>
                  <StyledDocumentFieldChangeList>
                    {pageChanges.changes.map((fieldChange, index) => {
                      if (
                        showMoreFieldChanges ||
                        currentNumberOfFieldChanges + 1 <=
                          MAX_INITIAL_FIELD_CHANGES
                      ) {
                        currentNumberOfFieldChanges++;

                        return (
                          <StyledDocumentFieldChangeItem key={index}>
                            <DocumentFieldChange fieldChange={fieldChange} />
                          </StyledDocumentFieldChangeItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </StyledDocumentFieldChangeList>
                </Fragment>
              );
            } else {
              return null;
            }
          })}

          {hasMoreFieldChanges && (
            <StyledShowMoreButtonWrapper>
              <LinkButton
                removeSidePadding
                id={`document-${document.id}-toggle-field-changes`}
                onClick={() => setShowMoreFieldChanges(show => !show)}
              >
                <FormattedMessage
                  id={`messaging.documentFieldChanges.${
                    showMoreFieldChanges ? 'showLess' : 'showMore'
                  }`}
                />
              </LinkButton>
            </StyledShowMoreButtonWrapper>
          )}
        </StyledDocumentFieldChanges>
      )}
    </StyledMessageContainer>
  );
}
