import { AnchorHTMLAttributes } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import analytics, { Categories } from '~/analytics';

export interface AppLinkProps extends LinkProps {
  openNewTab?: boolean;
  /** Needs to be proxied down in case this component is extended  */
  className?: string;
}

const ANCHOR_TAG_REGEX = /^(https?:\/\/|mailto:|tel:)/;

const AppLink = (props: AppLinkProps) => {
  const { openNewTab, className, ...linkProps } = props;

  if (openNewTab) {
    linkProps.rel = 'noopener noreferrer';
    linkProps.target = '_blank';
  }

  if (typeof props.to === 'string' && ANCHOR_TAG_REGEX.test(props.to)) {
    const { to, children, ...rest } = linkProps;
    const anchorProps = rest as AnchorHTMLAttributes<HTMLAnchorElement>;

    if ((anchorProps as any).replace !== undefined) {
      (anchorProps as any).replace = undefined;
    }

    anchorProps.onClick = () => {
      analytics.track(
        Categories.INTERACTION,
        'external_link_click',
        to as string
      );
    };

    return (
      <a href={props.to} {...anchorProps} className={className}>
        {children}
      </a>
    );
  } else {
    return <Link {...linkProps} className={className} />;
  }
};

AppLink.defaultProps = {
  replace: false,
};

AppLink.displayName = 'AppLink';

export default AppLink;
