import {
  Fragment,
  useState,
  useEffect,
  ReactNode,
  MouseEvent,
  WheelEvent,
  ButtonHTMLAttributes,
  RefObject,
  InputHTMLAttributes,
} from 'react';
import { PropGetters, useSelect, UseSelectStateChange } from 'downshift';
import { motion } from 'framer-motion';

import styled from 'styled-components';
import { noop } from 'lodash-es';

import BaseDropdown from '~/components/dropdowns/BaseDropdown';
import Overlay from '~/components/Overlay';
import Portal from '~/components/Portal';

import { SelectedValue } from '../../dropdown/types';

import {
  boxShadows,
  breakpoints,
  colors,
  spacing,
  fontSizes,
  fontWeights,
  momentumScrollingStyles,
  zIndex,
  utils,
} from '~/styles';
import {
  getScrollDetails,
  preventOutsideScrollingProps,
} from '~/styles/utilities';
import { useApplicationScrolling, useIsDesktop } from '~/hooks';
import { MODAL_PORTAL_ID } from '~/components/Modal/BaseModal';
import { FormattedMessage } from '~/components/i18n';
import analytics, { Categories } from '~/analytics';
import { Position } from '~/hooks/usePopover';
import { MAX_MOBILE_HEIGHT } from '~/components/inputs/constants';

const mixins = {
  maxMenuHeight: {
    maxHeight: `calc(${MAX_MOBILE_HEIGHT} - ${spacing.normal} * 2 - ${fontSizes.body.lineHeight})`,
  },
  menuItemHighlighted: {
    backgroundColor: colors.menuItemHighlight,
  },

  menuItemSelected: {
    color: colors.primaryBackgroundText,
    backgroundColor: colors.primaryAction,
    fontWeight: fontWeights.semiBold,
  },

  menuHeaderWithShadow: {
    boxShadow: boxShadows.bottom,
  },

  disableMenuScrolling: {
    [breakpoints.MEDIUM]: {
      maxHeight: 'none',
    },
  },

  menuContainerInsideModal: {
    zIndex:
      zIndex.modalOverlayForeground + zIndex.aboveSibling + zIndex.aboveSibling,
  },

  menuContainerOpen: {
    pointerEvents: 'auto',
  },

  isInvalid: {
    marginBottom: 'unset',
  },
};

const StyledErrorMessage = styled.span({
  ...fontSizes.callout,
  ...utils.text.truncate,
  marginRight: spacing.smaller,
});

const StyledErrorContainer = styled.div<{
  $isInvalidEnabled: boolean;
}>(props => ({
  marginLeft: spacing.small,
  marginBottom: spacing.large,
  color: colors.errorText,
  display: 'flex',
  ...(props.$isInvalidEnabled ? mixins.isInvalid : {}),
}));

const DropdownInputContainer = styled.div({
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const StyledDropdown = styled.div({
  position: 'relative',
  overflow: 'hidden',
});

const StyledMenuContainer = styled(motion.div)<{
  $menuContainerInsideModalEnabled: boolean;
  $menuContainerOpenEnabled: boolean;
}>(props => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  pointerEvents: 'none',
  backgroundColor: colors.white,
  boxShadow: boxShadows.top,
  zIndex: zIndex.overlayForeground + zIndex.aboveSibling,
  ...(props.$menuContainerInsideModalEnabled
    ? mixins.menuContainerInsideModal
    : {}),
  ...(props.$menuContainerOpenEnabled ? mixins.menuContainerOpen : {}),
}));

const StyledMenuItems = styled.ul<{
  $disableMenuScrollingEnabled: boolean;
  $fullHeightMenu: boolean;
}>(props => ({
  ...momentumScrollingStyles,
  margin: 0,
  padding: 0,
  ...mixins.maxMenuHeight,
  ...(props.$disableMenuScrollingEnabled ? mixins.disableMenuScrolling : {}),

  [breakpoints.MEDIUM]: {
    ...(props.$fullHeightMenu
      ? { maxHeight: 'fit-content', marginBottom: spacing.normal }
      : mixins.maxMenuHeight),
  },
}));

const StyledMenuHeader = styled.div<{
  $menuHeaderWithShadowEnabled: boolean;
}>(props => ({
  ...fontSizes.body,
  fontWeight: fontWeights.semiBold,
  padding: spacing.normal,
  textAlign: 'center',
  borderBottom: `1px solid ${colors.lightBorder}`,
  position: 'relative',
  ...(props.$menuHeaderWithShadowEnabled ? mixins.menuHeaderWithShadow : {}),
}));

const StyledMenuItem = styled.li<{
  $menuItemHighlightedEnabled: boolean;
  $menuItemSelectedEnabled: boolean;
}>(props => ({
  padding: `${spacing.small}`,
  color: colors.mainText,
  backgroundColor: colors.white,
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  margin: 0,
  display: 'flex',
  alignItems: 'center',

  '&:not(:last-child)': {
    borderBottom: `1px solid ${colors.lightBorder}`,
  },

  ...(props.$menuItemHighlightedEnabled ? mixins.menuItemHighlighted : {}),
  ...(props.$menuItemSelectedEnabled ? mixins.menuItemSelected : {}),
}));

export function getDefaultPlaceholder() {
  return 'Select';
}

type DropdownItemProps<Item> = {
  getItemProps: PropGetters<Item>['getItemProps'];
  item: Item;
  index: number;
  selectedItem: Item;
  highlightedIndex: number | null;
  renderItem: (item: Item, isSelected: boolean) => ReactNode;
};
function DropdownItem<Item>({
  getItemProps,
  item,
  index,
  selectedItem,
  highlightedIndex,
  renderItem,
}: DropdownItemProps<Item>) {
  const isSelected = selectedItem === item;
  const isHighlighted = highlightedIndex === index;

  return (
    <StyledMenuItem
      {...getItemProps({
        index,
        item,
        onClick: (e: MouseEvent<HTMLLIElement>) => e.stopPropagation(),
      })}
      $menuItemHighlightedEnabled={isHighlighted}
      $menuItemSelectedEnabled={isSelected}
    >
      {renderItem(item, isSelected)}
    </StyledMenuItem>
  );
}

type DropdownHeaderProps = {
  header: string | ReactNode;
  showShadow: boolean;
};
function DropdownHeader({ header, showShadow }: DropdownHeaderProps) {
  return (
    <StyledMenuHeader $menuHeaderWithShadowEnabled={showShadow}>
      {header}
    </StyledMenuHeader>
  );
}

interface ToStringable {
  toString: () => string;
}
type DropdownInputMenuContentsProps<Item, IdType> = {
  items: Array<Item>;
  isDesktop: boolean;
  header?: string | ReactNode;
  showHeaderShadow: boolean;
  handleDropdownListScroll: (e: WheelEvent<HTMLElement>) => void;
  disableMenuScrolling?: boolean;
  getItemId: (item: Item) => IdType;
  renderItem: (item: Item, isSelected: boolean) => ReactNode;
  getMenuProps: PropGetters<Item>['getMenuProps'];
  getItemProps: PropGetters<Item>['getItemProps'];
  selectedItem: any;
  highlightedIndex: number | null;
  fullHeightMenu: boolean;
};
function DropdownInputMenuContents<Item, IdType extends ToStringable>({
  items,
  isDesktop,
  header,
  showHeaderShadow,
  handleDropdownListScroll,
  disableMenuScrolling,
  getItemId,
  renderItem,
  getMenuProps,
  getItemProps,
  selectedItem,
  highlightedIndex,
  fullHeightMenu,
}: DropdownInputMenuContentsProps<Item, IdType>) {
  return (
    <Fragment>
      {!isDesktop && !!header && (
        <DropdownHeader header={header} showShadow={showHeaderShadow} />
      )}

      <StyledMenuItems
        $fullHeightMenu={fullHeightMenu}
        $disableMenuScrollingEnabled={disableMenuScrolling}
        onScroll={!disableMenuScrolling ? handleDropdownListScroll : noop}
        {...getMenuProps()}
        {...preventOutsideScrollingProps}
      >
        {items.map((item, index) => (
          <DropdownItem
            key={`${getItemId(item).toString()}-${index}`}
            item={item}
            index={index}
            renderItem={renderItem}
            getItemProps={getItemProps}
            selectedItem={selectedItem}
            highlightedIndex={highlightedIndex}
          />
        ))}
      </StyledMenuItems>
    </Fragment>
  );
}

type DropdownInputMobileMenuProps = {
  children: any;
  isOpen: boolean;
  isInModal: boolean;
};
function DropdownInputMobileMenu({
  children,
  isOpen,
  isInModal,
}: DropdownInputMobileMenuProps) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isOpen && !isVisible) {
      setTimeout(() => setIsVisible(true), 0);
    } else if (!isOpen && isVisible) {
      setIsVisible(false);
    }
  }, [isOpen, isVisible]);

  return (
    <StyledMenuContainer
      data-open={isOpen}
      $menuContainerInsideModalEnabled={isInModal}
      $menuContainerOpenEnabled={isOpen}
      initial="closed"
      animate={isOpen && isVisible ? 'open' : isOpen ? 'calculate' : 'closed'}
      variants={{
        calculate: { display: 'block', y: '100%' },
        open: { display: 'block', y: 0 },
        closed: { y: '100%', transitionEnd: { display: 'none' } },
      }}
      transition={{ duration: 0.3 }}
    >
      {children}
    </StyledMenuContainer>
  );
}

type DropdownInputMenuProps<Item, IdType> = {
  isDesktop: boolean;
  isOpen: boolean;
  fullWidthMenu: boolean;
  triggerId: string;
  defaultPosition?: Position;
  fullHeightMenu: boolean;
} & DropdownInputMenuContentsProps<Item, IdType> &
  Pick<
    DropdownInputMobileMenuProps,
    Exclude<keyof DropdownInputMobileMenuProps, 'children'>
  >;
function DropdownInputMenu<Item, IdType>(
  props: DropdownInputMenuProps<Item, IdType>
) {
  return props.isDesktop ? (
    <BaseDropdown
      isOpen={props.isOpen}
      triggerId={props.triggerId}
      defaultPosition={props.defaultPosition}
    >
      <DropdownInputMenuContents {...props} />
    </BaseDropdown>
  ) : (
    <Portal id="mobile-select-input-portal" forceAppendToBody>
      <DropdownInputMobileMenu {...props}>
        <DropdownInputMenuContents {...props} />
      </DropdownInputMobileMenu>
    </Portal>
  );
}

interface BaseSelectInputProps<Item, IdType extends SelectedValue> {
  /** ID for the dropdown so it can be identified, mostly for testing */
  id: string;
  /**
   * Override the label sent to analytics for this button
   * (useful if button has a unique ID, for example buttons that trigger the datepicker input in a list of loop cards)
   */
  analyticsId?: string;
  /** If set, disables menu scrolling and will take up the height of the items */
  disableMenuScrolling?: boolean;
  /** Array of dropdown items */
  items: Array<Item>;
  /** Placeholder text to display when no value is selected */
  placeholder?: string;
  /** Used on mobile display a header for slide up menu, and in outlined dropdown */
  label?: string;
  /** Currently selected dropdown item */
  value: IdType | null;
  /**
   * Controls whether a selected value is required for this dropdown.
   * If set to {true} and the user "touches" this field (focus enters then leaves),
   * an error message will be displayed if no value was selected.
   */
  required?: boolean;
  /** controls whether the dropdown is disabled */
  disabled?: boolean;
  /** Function that is called to display dropdown items, commonly used for i18n, defaults to getItemText */
  renderItem?: (item: Item, isSelected: boolean) => ReactNode;
  /** Function that is called to display selected item, used for custom react nodes, defaults to getItemText */
  renderSelectedItem?: (item: Item) => ReactNode;
  /** Disable the use of placeholder text */
  disablePlaceholder?: boolean;
  /** Called when a dropdown item is selected */
  onChange: (updatedValue: IdType | null) => void;
  /** Focus event for the input field */
  onFocus?: () => void;
  /**
   * Indicates the form has been submitted and the error state should be displayed
   * if {required} and no value is selected, even if the dropdown wasn't yet "touched"
   */
  formSubmitted?: boolean;
  /** Blur event for the input field */
  onBlur?: () => void;
  /** Custom header component for the mobile dropdown */
  mobileHeader?: ReactNode;
}

export interface ConsumerSelectInputProps<Item, IdType extends SelectedValue>
  extends BaseSelectInputProps<Item, IdType> {
  /** Force the menu to be as wide as the trigger */
  fullWidthMenu?: boolean;
  /** Force the menu to be as tall as the list of menuItems */
  fullHeightMenu?: boolean;
  /** Get the ID for the given item */
  getItemId?: (item: Item) => IdType;
  /** Get the text for the given item */
  getItemText?: (item: Item) => string;
  /** Get the label text if available */
  getLabelText?: (item: Item) => string;
  /** Sets default popover position */
  defaultPosition?: Position;
}

interface ButtonAttrs extends ButtonHTMLAttributes<HTMLButtonElement> {
  id: string;
  ref: RefObject<HTMLButtonElement>;
}
interface InputAttrs extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
}
interface RenderTriggerRenderProps {
  isOpen: boolean;
  displayText: string | number | ReactNode;
  toggleButtonProps?: ButtonAttrs;
  toggleInputProps?: InputAttrs;
  selectedItem: any | Array<any>;
  clearSelection?: () => void;
  displayLabelText: string | number | undefined;
  invalid?: boolean;
}
export type RenderTrigger = (
  renderProps: RenderTriggerRenderProps
) => ReactNode;
interface Props<Item, IdType extends SelectedValue>
  extends ConsumerSelectInputProps<Item, IdType> {
  /** Render prop to render the triggering element for the dropdown */
  renderTrigger: RenderTrigger;
}

function BaseSelectInput<Item, IdType extends SelectedValue>({
  id,
  items,
  value,
  label,
  placeholder,
  getLabelText,
  onChange,
  analyticsId,
  renderItem,
  renderTrigger,
  renderSelectedItem,
  required = false,
  fullWidthMenu = false,
  formSubmitted = false,
  disablePlaceholder = false,
  disableMenuScrolling = false,
  defaultPosition,
  fullHeightMenu = false,
  getItemId = (item: Item) => (item as any).id,
  getItemText = (item: Item) => (item as any).text,
  mobileHeader,
}: Props<Item, IdType>) {
  const [showHeaderShadow, setShowHeaderShadow] = useState(false);
  const isDesktop = useIsDesktop();
  const [dropdownValue, setDropdownValue] = useState(value);
  const [insideModal, setInsideModal] = useState(false);
  const portalSelector = `#${MODAL_PORTAL_ID}`;
  const setContainerRef = (ref: HTMLElement | null) => {
    if (!!ref) {
      const isInModal = ref.closest(portalSelector) !== null;

      if (isInModal && !insideModal) {
        setInsideModal(true);
      }
    }
  };

  const handleChange = (change: UseSelectStateChange<Item>) => {
    const changedValue = !!change.selectedItem
      ? getItemId(change.selectedItem)
      : null;
    setDropdownValue(changedValue);
    onChange(changedValue);
    analytics.track(
      Categories.INTERACTION,
      'select_input_changed',
      analyticsId ?? id
    );
  };

  const clearSelection = () => {
    setDropdownValue(null);
    onChange(null);
    analytics.track(
      Categories.INTERACTION,
      'select_input_cleared',
      analyticsId ?? id
    );
  };

  const selectedItem =
    items.find(item => getItemId(item) === dropdownValue) || null;

  /**
   * We need two pieces of open states in order for things to function correctly.
   * We want to defer the open prop change of downshift until all downstream
   * effects and renders have finished. This is because it tries to focus
   * the menuRef when isOpen = true and if that component has display: none
   * it won't focus, and if it has display: block but visibility: hidden,
   * it will focus but not be positioned correctly. So once the first open
   * flushes, we have an effect on it that is delayed by the popover position delay
   * before it tells downshift to be open.
   */
  const [isOpen, setIsOpen] = useState(false);
  const [willOpen, setWillOpen] = useState(false);

  useEffect(() => {
    setIsOpen(willOpen);
  }, [willOpen]);
  const { getToggleButtonProps, getMenuProps, getItemProps, highlightedIndex } =
    useSelect({
      selectedItem,
      isOpen,
      items,
      itemToString: (item: Item | null) => {
        const itemText = item !== null ? getItemText(item) : undefined;
        return itemText ?? '';
      },
      id,
      menuId: `${id}-menu`,
      onSelectedItemChange: handleChange,
      onIsOpenChange: changes => {
        setWillOpen(changes.isOpen ?? false);
      },
    });

  useApplicationScrolling(isDesktop || !isOpen, 'dropdown');

  useEffect(() => {
    setDropdownValue(value);
  }, [value]);

  const renderItemFunc = (item: Item, isSelected: boolean) => {
    return renderItem ? renderItem(item, isSelected) : getItemText(item);
  };

  const handleDropdownListScroll = (e: WheelEvent<HTMLElement>) => {
    const { isTop, isBottom } = getScrollDetails(e, 5);
    const isInMiddle = !isTop && !isBottom;
    const showTop = !isTop || isInMiddle;

    setShowHeaderShadow(showTop);
  };

  const getLabelOrPlaceholder = (): string | number => {
    let result: string | number = getDefaultPlaceholder();

    if (label) {
      result = label;
    }

    if (disablePlaceholder && items.length) {
      result = getItemId(items[0]) as any;
    } else if (placeholder) {
      result = placeholder;
    }

    return result;
  };

  const triggerId = `${id}-button`;
  const displayText = !!selectedItem
    ? !!renderSelectedItem
      ? renderSelectedItem(selectedItem)
      : getItemText(selectedItem)
    : getLabelOrPlaceholder();

  const displayLabelText =
    !!selectedItem && !!getLabelText
      ? getLabelText(selectedItem)
      : getLabelOrPlaceholder();

  if (dropdownValue && !selectedItem) {
    console.warn('Dropdown value not found in items.', dropdownValue);
  }
  const invalid = formSubmitted && required && !selectedItem;

  return (
    <StyledDropdown id={`${id}-container`} key="dropdown">
      <div ref={setContainerRef} />
      {!isDesktop && (
        <Overlay
          isVisible={isOpen}
          zIndex={
            zIndex.aboveSibling +
            (insideModal
              ? zIndex.modalOverlayForeground
              : zIndex.overlayForeground)
          }
        />
      )}

      <DropdownInputContainer data-role="dropdown">
        {renderTrigger({
          isOpen,
          invalid,
          displayText,
          toggleButtonProps: getToggleButtonProps({
            id: triggerId,
            type: 'button',
          }),
          selectedItem,
          displayLabelText,
          clearSelection,
        })}

        <DropdownInputMenu
          triggerId={triggerId}
          fullWidthMenu={fullWidthMenu}
          disableMenuScrolling={disableMenuScrolling}
          header={mobileHeader ?? label}
          items={items}
          getItemId={getItemId}
          renderItem={renderItemFunc}
          highlightedIndex={highlightedIndex}
          getMenuProps={getMenuProps}
          getItemProps={getItemProps}
          selectedItem={selectedItem}
          isOpen={willOpen}
          isDesktop={isDesktop}
          isInModal={insideModal}
          handleDropdownListScroll={handleDropdownListScroll}
          showHeaderShadow={showHeaderShadow}
          defaultPosition={defaultPosition}
          fullHeightMenu={fullHeightMenu}
        />
      </DropdownInputContainer>

      {required && (
        <StyledErrorContainer $isInvalidEnabled={invalid}>
          {invalid && (
            <StyledErrorMessage id="dropdownError" role="alert">
              <FormattedMessage
                id="errors.inputs.requiredDropdown"
                values={{ label }}
              />
            </StyledErrorMessage>
          )}
        </StyledErrorContainer>
      )}
    </StyledDropdown>
  );
}

export default BaseSelectInput;
