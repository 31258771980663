import { Fragment, useState } from 'react';
import Portal from '~/components/Portal';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FormattedMessage } from '~/components/i18n';

import APP_CONFIG from '~/config';
import { useApplicationScrolling, useIsDesktop } from '~/hooks';
import LinkButton, { LinkButtonType } from '~/components/buttons/LinkButton';
import { ProfileAvatar } from '~/components/Avatar';
import Popover from '~/components/Popover';
import Overlay from '~/components/Overlay';
import {
  ActivityIcon,
  ChevronIcon,
  NotificationIcon,
  SignOutIcon,
} from '~/components/icons';
import { RotationDirection } from '~/components/icons/types';
import {
  breakpoints,
  boxShadows,
  colors,
  createTransform,
  fontSizes,
  fontWeights,
  momentumScrollingStyles,
  spacing,
  zIndex,
} from '~/styles';
import { ProfileResource } from '~/store/features/api/resources/profile/types';

import { MemberInfo } from './MemberInfo';
import { ProfileList } from './ProfileList';
import UserNotificationCount from '~/pages/MyLoops/components/UserNotificationCount';
import { useI18n } from '../../hooks';

const StyledSignOut = styled.div({
  padding: spacing.normal,
});

const StyledSupportNumber = styled.div({
  flex: '1 0 100%',
  minWidth: '100%',
});

const StyledHelpCenter = styled.div({
  flex: '1 0 100%',
  minWidth: '100%',
});

const StyledMobileMyAccountLink = styled.div({
  flex: '1 0 100%',
});

const StyledSupportInfo = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  padding: `${spacing.smaller} ${spacing.normal}`,
  borderTop: `1px solid ${colors.lightBorder}`,
  borderBottom: `1px solid ${colors.lightBorder}`,
});

const StyledMobileLinkAction = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const StyledMobileLinks = styled.div({
  padding: `${spacing.smaller} ${spacing.normal}`,

  [breakpoints.MEDIUM]: {
    display: 'none',
  },
});

const StyledProfileListContainer = styled(motion.div)({
  overflow: 'hidden',
  isolation: 'isolate',

  [breakpoints.MEDIUM]: {
    overflow: 'auto',
  },
});

const StyledDesktopMyAccountLink = styled.div({
  marginTop: spacing.normal,
});

const StyledMemberInfo = styled.div({
  marginTop: '-1px',
  padding: spacing.normal,
  borderTop: `1px solid ${colors.lightBorder}`,
  borderBottom: `1px solid ${colors.lightBorder}`,

  [breakpoints.MEDIUM]: {
    marginTop: 0,
  },
});

const StyledAccountMenuContent = styled.div({
  flex: '1 1 auto',
  zIndex: zIndex.hidden,
  ...momentumScrollingStyles,
});

const StyledMobileProfileToggle = styled.div({
  flex: '0 0 auto',
});

const StyledCurrentProfileParentName = styled.div({
  ...fontSizes.callout,
  color: colors.secondaryText,
});

const StyledCurrentProfileName = styled.div({
  ...fontSizes.secondaryTitle,
  fontWeight: fontWeights.semiBold,
});

const StyledCurrentProfileDetails = styled.div({
  flex: '1 1 90%',
});

const StyledCurrentProfileDetailsContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginTop: spacing.small,
});

const StyledCurrentProfileAvatar = styled.div({
  width: spacing.max,
  height: spacing.max,

  [breakpoints.MEDIUM]: {
    display: 'none',
  },
});

const StyledMobileAccountInfo = styled.div({
  flex: '0 0 auto',
  position: 'relative',
  padding: `${spacing.medium} ${spacing.normal} ${spacing.normal}`,
  backgroundColor: colors.backgroundGray,
  borderBottom: `1px solid ${colors.lightBorder}`,

  [breakpoints.MEDIUM]: {
    display: 'none',
  },
});

const StyledAccountMenu = styled.div<{
  $accountMenuOpenEnabled: boolean;
}>(props => ({
  ...createTransform('translateX(-100%)'),
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  width: '21rem',
  height: '100vh',
  maxWidth: '90%',
  backgroundColor: colors.white,
  color: colors.mainText,
  boxShadow: '4px 0 4px 0 rgba(0, 0, 0, 0.24)',
  opacity: 0,
  transition: 'transform 0.3s ease-in-out, opacity 0s ease 0.3s',
  zIndex: zIndex.profileSwitcher,
  ...(props.$accountMenuOpenEnabled
    ? {
        ...createTransform('translateX(0)'),
        opacity: 1,
        transition: 'transform 0.3s ease-in-out, opacity 0s ease',
      }
    : {}),

  [breakpoints.MEDIUM]: {
    ...createTransform(`translateY(100%) translateY(${spacing.small})`),
    position: 'absolute',
    top: 'auto',
    left: 'auto',
    right: 0,
    bottom: 0,
    width: '23rem',
    minWidth: '23rem',
    height: 'auto',
    pointerEvents: 'none',
    boxShadow: boxShadows.surroundDark,
    transition: 'opacity 0.2s ease',
    zIndex: zIndex.accountMenu,
    ...(props.$accountMenuOpenEnabled
      ? {
          ...createTransform(`translateY(100%) translateY(${spacing.small})`),
          pointerEvents: 'auto',
        }
      : {}),
  },
}));

const {
  ui: { phoenixUrl },
} = APP_CONFIG;

type Props = {
  isOpen: boolean;
  hasManageBranding: boolean;
  userId: number;
  fullName: string;
  currentProfile: ProfileResource | null;
  onCurrentProfileChanged: (newCurrentProfile: ProfileResource) => any;
  onProfileSettingSelected: (profile: ProfileResource) => void;
  openStateChanged: (isOpen: boolean) => void;
};

export function AccountMenu(props: Props) {
  const {
    isOpen,
    hasManageBranding,
    userId,
    fullName,
    currentProfile,
    onCurrentProfileChanged,
    onProfileSettingSelected,
  } = props;
  const i18n = useI18n();
  const currentProfileId = currentProfile ? currentProfile.id : '';
  const isDesktop = useIsDesktop();
  const [showMobileProfiles, setShowMobileProfiles] = useState(false);
  const allowScroll = isDesktop ? true : !isOpen;

  useApplicationScrolling(allowScroll, 'account-menu');

  function handleCloseAccountMenu() {
    props.openStateChanged(false);
  }

  const children = (
    <Popover
      isOpen={isOpen}
      shouldCalculatePosition={false}
      exitDuration={500}
      render={({ shouldRender, isVisible }) => {
        if (!shouldRender) {
          return null;
        }

        return (
          <div>
            {!isDesktop && shouldRender && (
              <Overlay
                shouldAnimate
                isVisible={isVisible}
                onClick={handleCloseAccountMenu}
                zIndex={zIndex.profileSwitcherOverlay}
              />
            )}
            <StyledAccountMenu $accountMenuOpenEnabled={isVisible}>
              <StyledMobileAccountInfo>
                {!!currentProfile && (
                  <Fragment>
                    <StyledCurrentProfileAvatar>
                      <ProfileAvatar profile={currentProfile} />
                    </StyledCurrentProfileAvatar>
                    <StyledCurrentProfileDetailsContainer>
                      <StyledCurrentProfileDetails>
                        <StyledCurrentProfileName>
                          {currentProfile.name}
                        </StyledCurrentProfileName>
                        {!!currentProfile.directParentProfileName && (
                          <StyledCurrentProfileParentName>
                            {currentProfile.directParentProfileName}
                          </StyledCurrentProfileParentName>
                        )}
                      </StyledCurrentProfileDetails>
                      <StyledMobileProfileToggle>
                        <LinkButton
                          id="mobile-profile-toggle"
                          icon={ChevronIcon}
                          buttonStyle={LinkButtonType.Secondary}
                          defaultIconProps={{
                            direction: showMobileProfiles
                              ? RotationDirection.Up
                              : RotationDirection.Down,
                          }}
                          aria-label={i18n.t('account.openProfileMenu')}
                          onClick={() =>
                            setShowMobileProfiles(!showMobileProfiles)
                          }
                        />
                      </StyledMobileProfileToggle>
                    </StyledCurrentProfileDetailsContainer>
                  </Fragment>
                )}
              </StyledMobileAccountInfo>
              <StyledAccountMenuContent>
                {isDesktop && (
                  <StyledMemberInfo>
                    <MemberInfo name={fullName} id={userId} />
                    <StyledDesktopMyAccountLink>
                      <LinkButton
                        id="settings-link"
                        removeTopBottomPadding
                        removeSidePadding
                        link={`${phoenixUrl}/my/account/#/settings`}
                      >
                        <FormattedMessage id="account.myAccount" />
                      </LinkButton>
                    </StyledDesktopMyAccountLink>
                  </StyledMemberInfo>
                )}

                <StyledProfileListContainer
                  initial={{ height: isDesktop ? 'auto' : '0' }}
                  animate={{
                    height: isDesktop
                      ? 'auto'
                      : showMobileProfiles
                      ? 'auto'
                      : '0',
                  }}
                  transition={{
                    duration: 0.3,
                    ease: 'easeInOut',
                  }}
                >
                  <ProfileList
                    hasManageBranding={hasManageBranding}
                    currentProfileId={currentProfileId}
                    onCurrentProfileChanged={onCurrentProfileChanged}
                    onProfileSettingSelected={onProfileSettingSelected}
                    setShowMobileProfiles={setShowMobileProfiles}
                  />
                </StyledProfileListContainer>

                {!isDesktop && (
                  <StyledMemberInfo>
                    <MemberInfo name={fullName} id={userId} />
                  </StyledMemberInfo>
                )}

                <StyledMobileLinks>
                  <StyledMobileLinkAction>
                    <LinkButton
                      id="activity-log-link"
                      removeSidePadding
                      link={`${phoenixUrl}/my/activity`}
                      icon={ActivityIcon}
                    >
                      <FormattedMessage id="account.activityLogLink" />
                    </LinkButton>
                  </StyledMobileLinkAction>
                  <StyledMobileLinkAction>
                    <LinkButton
                      id="notifications-link"
                      removeSidePadding
                      link={`${phoenixUrl}/my/notifications`}
                      icon={NotificationIcon}
                    >
                      <FormattedMessage id="account.notificationsLink" />
                    </LinkButton>
                    <UserNotificationCount />
                  </StyledMobileLinkAction>
                </StyledMobileLinks>
                <StyledSupportInfo>
                  {!isDesktop && (
                    <StyledMobileMyAccountLink>
                      <LinkButton
                        id="settings-link"
                        removeSidePadding
                        link={`${phoenixUrl}/my/account/#/settings`}
                      >
                        <FormattedMessage id="account.myAccount" />
                      </LinkButton>
                    </StyledMobileMyAccountLink>
                  )}
                  <StyledHelpCenter>
                    <LinkButton
                      id="help-center"
                      removeSidePadding
                      openNewTab
                      link="https://support.dotloop.com"
                    >
                      <FormattedMessage id="account.help" />
                    </LinkButton>
                  </StyledHelpCenter>
                  <StyledSupportNumber>
                    <LinkButton
                      id="support-link"
                      removeSidePadding
                      link="tel:+18883685667"
                    >
                      <FormattedMessage id="account.support" />
                    </LinkButton>
                  </StyledSupportNumber>
                </StyledSupportInfo>
                <StyledSignOut>
                  <LinkButton
                    id="logout-link"
                    removeSidePadding
                    icon={SignOutIcon}
                    buttonStyle={LinkButtonType.Error}
                    link={`${phoenixUrl}/my/logout`}
                  >
                    <FormattedMessage id="account.signOut" />
                  </LinkButton>
                </StyledSignOut>
              </StyledAccountMenuContent>
            </StyledAccountMenu>
          </div>
        );
      }}
    />
  );

  return isDesktop ? (
    <div>{children}</div>
  ) : (
    <Portal id="account-portal">{children}</Portal>
  );
}

export default AccountMenu;
