import styled, { keyframes } from 'styled-components';
import { colors } from '~/styles';

const loadingAnimation = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
    opacity: 0;
  }

  40% {
    transform: scale(1.0);
    opacity: 1;
  }
`;

const StyledContainer = styled.div<{ $containerWidth: string }>(props => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
  width: props.$containerWidth,
  textAlign: 'center',
}));

type ItemProps = {
  $itemSize: string;
  $itemMargin: string;
  $itemNumber: number;
  $animationFrames: number;
};
const StyledItem = styled.div<ItemProps>`
    width: ${props => props.$itemSize};
    height: ${props => props.$itemSize};
    margin-right: ${props => props.$itemMargin};
    border-radius: 100%;
    background-color: ${colors.infoText};
    animation: ${loadingAnimation} 1.7s infinite ease-in-out both;
    animation-delay: -${props => props.$itemNumber / props.$animationFrames}s;

    :last-child: {
      marginRight: 0;
    },
`;

type Props = {
  width: string;
  numberOfDots: number;
};

function LoadingMoreIndicator({ width, numberOfDots }: Props) {
  const totalMargin = `calc(${width} * 0.2)`;
  const itemMargin = `calc(${totalMargin} / ${numberOfDots - 1})`;
  const itemSize = `calc((${width} - ${totalMargin}) / ${numberOfDots})`;
  const animationFrames = numberOfDots + 1;
  const itemsWithDelay = [];

  for (let i = numberOfDots - 1; i >= 0; i--) {
    itemsWithDelay.push(
      <StyledItem
        key={`loading-dot-${i}`}
        $itemSize={itemSize}
        $itemMargin={itemMargin}
        $itemNumber={i}
        $animationFrames={animationFrames}
      />
    );
  }

  return (
    <StyledContainer $containerWidth={width}>{itemsWithDelay}</StyledContainer>
  );
}

LoadingMoreIndicator.defaultProps = {
  width: '100px',
  numberOfDots: 4,
};

export default LoadingMoreIndicator;
