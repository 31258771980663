import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import Highlight from './Highlight';

const ClampContainer = styled.div<{ $lineClamp: number }>`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${props => props.$lineClamp};
  -webkit-box-orient: vertical;
`;

export type ClampedTextProps = {
  /**
   * The Text to display in the component
   */
  text: string;
  /**
   * The number of lines to clamp the text to
   */
  lineClamp: number | 'unset';
  /**
   * The text to highlight in the sourceText
   */
  highlightText?: string;
  /**
   * Callback function to be called when the text is truncated
   */
  onTextTruncated?: (isTrunctated: boolean) => void;
};

const ClampedText: React.FC<ClampedTextProps> = ({
  text,
  lineClamp,
  highlightText,
  onTextTruncated,
}: ClampedTextProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkTextTruncation = () => {
      if (containerRef.current && onTextTruncated) {
        onTextTruncated(
          containerRef.current.clientHeight < containerRef.current.scrollHeight
        );
      }
    };

    window.addEventListener('resize', checkTextTruncation);
    checkTextTruncation();

    return () => {
      window.removeEventListener('resize', checkTextTruncation);
    };
  }, [text, lineClamp, onTextTruncated]);

  return (
    <ClampContainer $lineClamp={lineClamp} ref={containerRef}>
      {highlightText ? (
        <Highlight sourceText={text} findText={highlightText} />
      ) : (
        text
      )}
    </ClampContainer>
  );
};

export default ClampedText;
