import { DEFAULT_SORT } from '~/components/loops/utils/sorting';
import {
  ArchivedFilter,
  LoopInvitationStatus,
} from '~/store/features/api/resources/loop/constants';
import { LoopsListPreference } from '~/store/features/api/resources/preference/types';

import { JsonApiFetchOptions, SortDirectionType } from '~/lib/api/types';

export function generatePagedUnassignedLoopsApiOptions(
  pageNumber: number,
  pageSize: number,
  minLoopCreatedDate?: string
) {
  const sortDirection: SortDirectionType = 'desc';

  let filters: { [key: string]: string } = {
    archivedFilter: ArchivedFilter.HideArchived,
    invitationStatus: LoopInvitationStatus.Unaccepted,
  };

  if (minLoopCreatedDate) {
    filters = { ...filters, createdDate: minLoopCreatedDate };
  }

  return {
    useSearch: true,
    page: {
      size: pageSize,
      number: pageNumber,
    },
    filters,
    sort: {
      createdDate: sortDirection,
    },
  };
}

export function generateLoopsApiOptions(
  loopListPreference: LoopsListPreference | null | undefined,
  searchParams: any,
  pageNumber: number,
  pageSize: number,
  loopInvitationStatusFilter: LoopInvitationStatus = LoopInvitationStatus.All,
  minLoopCreatedDate?: string
): JsonApiFetchOptions {
  const hasSearchParams =
    !!searchParams && Object.keys(searchParams).length > 0;
  const isReportingQuery =
    hasSearchParams &&
    searchParams.chartTypeId !== undefined &&
    searchParams.chartTypeId !== null;
  const isSearchQuery =
    hasSearchParams && searchParams.loopSearch !== undefined;
  const searchValue = hasSearchParams
    ? { archivedFilter: ArchivedFilter.All, ...searchParams }
    : null;
  const preferenceValue = loopListPreference ? loopListPreference.value : {};

  const {
    sortId,
    sortDirection,
    transactionTypeIds,
    statusIds,
    complianceStatusIds,
    tags,
    archivedFilter,
    unsubmitted,
  } = preferenceValue;

  const options: JsonApiFetchOptions = {
    useSearch: true,
    page: { number: pageNumber, size: pageSize },
  };

  const sortType = isSearchQuery
    ? searchValue.loopSearchSort
    : sortId || DEFAULT_SORT.sortId;
  let direction = isSearchQuery
    ? searchValue.loopSearchSortDirection
    : sortDirection;

  if (sortType === DEFAULT_SORT.sortId && !direction) {
    direction = DEFAULT_SORT.defaultSortDirection;
  }

  options.sort = {
    [sortType]: direction,
  };

  let filters: { [filter: string]: any } = {};
  if (isReportingQuery) {
    filters = { ...searchParams };
  } else {
    if (unsubmitted) {
      filters.unsubmitted = true;
    }

    if (transactionTypeIds && transactionTypeIds.length > 0) {
      filters.transactionTypeIds = transactionTypeIds;
    }

    if (statusIds && statusIds.length > 0) {
      filters.statusIds = statusIds;
    }

    if (complianceStatusIds) {
      filters.complianceStatusIds = complianceStatusIds;
    }

    if (tags) {
      filters.tagNames = tags;
    }

    if (loopInvitationStatusFilter) {
      filters.invitationStatus = loopInvitationStatusFilter;
    }

    if (!!archivedFilter) {
      filters.archivedFilter = archivedFilter;
    }

    if (searchValue && searchValue.useFilters !== undefined) {
      filters = searchValue.useFilters
        ? filters
        : { archivedFilter: ArchivedFilter.All };
    }

    if (hasSearchParams && searchValue) {
      filters.search = searchValue.loopSearch;
    }

    if (minLoopCreatedDate) {
      filters.createdDate = minLoopCreatedDate;
    }
  }

  options.filters = filters;

  return options;
}
