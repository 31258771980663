import { useEffect, useState, Fragment } from 'react';
import {
  Navigate,
  Routes,
  Route,
  useLocation,
  useMatch,
} from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

import analytics from '~/analytics';
import APP_CONFIG from '~/config';
import { FormattedMessage } from '~/components/i18n';
import { useAccount } from '~/hooks/api/account';
import { fetchAccount } from '~/store/features/account/accountSlice';
import { AppDispatch } from '~/store';
import { usePrevious } from '~/hooks';

import AssumeControlBanner from '~/components/AssumeControlBanner';
import PendoInitializer from './components/Analytics/Pendo';
import OptOutBanner from './components/OptOutBanner';
import DefaultProfileSelection from '~/components/DefaultProfileSelection';
import SuperAdminFeatureFlagManagement from '~/components/SuperAdminFeatureFlagManagement';
import { DefaultLayout } from '~/components/layout';
import MessagingRouter from '~/components/Messaging/MessagingRouter';
import { colors, hasMomentumScrolling } from '~/styles';

import CompleteRegistration from './pages/CompleteRegistration';
import MyLoops from './pages/MyLoops';
import CreateLoop from './pages/CreateLoop';
import LoopDetails from './pages/LoopDetails';
import MessagingPage from './pages/Messaging/MessagingPage';
import NotFound from './pages/Error/NotFound';
import Login from './pages/Login';
import SupportBanner from './components/SupportBanner';
import { useAppDispatch } from '~/store/hooks';
import { useFetchAllPreferences } from './hooks/api/preference';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    background-color: ${colors.backgroundGray};
    transition: background-color 1s ease;
  }

  html.white,
  body.white {
    background-color: ${colors.white};
  }
`;

const mixins = {
  // https://github.com/kentor/react-click-outside#not-working-on-ios
  // This style is necessary for touch -> click events to work properly on iOS
  // If this style is not applied, click outside will break on iOS devices.
  cursorPointer: {
    cursor: 'pointer',
  },
};

const StyledDiv_1 = styled.div<{ $cursorPointerEnabled: boolean }>(props => ({
  ...(props.$cursorPointerEnabled ? mixins.cursorPointer : {}),
}));

const {
  ui: { phoenixUrl, isProductionBuild, isPullRequestBuild },
  api: { localProxyEnv },
} = APP_CONFIG;
const PHOENIX_ADMIN_URL = `${phoenixUrl}/my/admin`;

export function sanitizePath(path: string) {
  let santiizedPath = path
    .replace(/\d+\//g, '')
    .replace(/\d+$/g, 'details')
    .trim();

  if (path.startsWith('/index.html') || !santiizedPath) {
    santiizedPath = 'root';
  }

  return santiizedPath;
}

const Application = function Application() {
  const location = useLocation();
  const { pathname } = location;
  const useWhiteBackground = location.pathname.startsWith('/loops/create');
  const loopDetailsMatch = useMatch('loops/:loopId/:section/*');
  const removeBottomPadding = loopDetailsMatch !== null;
  const previousPathname = usePrevious(location.pathname);
  const shouldRedirectToAdmin = pathname.startsWith('/admin');

  useEffect(() => {
    if (shouldRedirectToAdmin) {
      window.location.href = PHOENIX_ADMIN_URL;
    }
  }, [shouldRedirectToAdmin]);

  useEffect(() => {
    if (!previousPathname || previousPathname !== pathname) {
      const path = sanitizePath(pathname);
      analytics.page(path);
    }
  }, [pathname, previousPathname]);

  // Fetch all preferences for authorized user when the app first loads
  // Only fetches preferences if not already loaded
  useFetchAllPreferences();

  return shouldRedirectToAdmin ? null : (
    <StyledDiv_1 $cursorPointerEnabled={hasMomentumScrolling()}>
      <AssumeControlBanner />
      <SupportBanner />
      <OptOutBanner />
      <MessagingRouter>
        <DefaultLayout
          whiteBackground={useWhiteBackground}
          removeBottomPadding={removeBottomPadding}
        >
          <Routes location={location}>
            <Route index element={<Navigate to="/loops" replace />} />
            <Route path="loops" element={<MyLoops />} />
            <Route path="loops/create" element={<CreateLoop />} />
            <Route path="loops/:loopId/*" element={<LoopDetails />} />
            <Route path="messages/*" element={<MessagingPage />} />
            <Route
              path="*"
              element={
                <NotFound
                  link="/loops"
                  actionText={
                    <FormattedMessage id="errors.notFound.actionText" />
                  }
                  message={<FormattedMessage id="errors.notFound.message" />}
                  subMessage={
                    <FormattedMessage id="errors.notFound.subMessage" />
                  }
                />
              }
            />
          </Routes>
        </DefaultLayout>
      </MessagingRouter>
      <DefaultProfileSelection />
      <SuperAdminFeatureFlagManagement />
      <PendoInitializer />
    </StyledDiv_1>
  );
};

async function getAccount(
  dispatch: AppDispatch,
  setShouldRenderLogin: (shouldRenderLogin: boolean) => void
) {
  try {
    await dispatch(fetchAccount());
  } catch (e) {
    if ((!isProductionBuild || isPullRequestBuild) && !!localProxyEnv) {
      setShouldRenderLogin(true);
    }
  }
}

export function App() {
  const accountData = useAccount();
  const dispatch = useAppDispatch();
  const { currentProfile, registrationComplete, emailVerified } = accountData;
  const previousRegistrationComplete = usePrevious(registrationComplete);
  const [shouldRenderLogin, setShouldRenderLogin] = useState(false);

  useEffect(() => {
    if (
      !currentProfile ||
      (previousRegistrationComplete === false && registrationComplete === true)
    ) {
      getAccount(dispatch, setShouldRenderLogin);
    }
  }, [
    dispatch,
    registrationComplete,
    previousRegistrationComplete,
    currentProfile,
  ]);

  const handleAuthenticatedSuccess = async () => {
    await getAccount(dispatch, setShouldRenderLogin);
    setShouldRenderLogin(false);
  };

  const hasCurrentProfile = !!currentProfile;
  // The user needs to have a verified email in order to complete their registration
  // We also do not want to prompt this if a dotloop admin is assuming control.
  const showCompleteRegistrationPage =
    !accountData.isAssumingControl && !registrationComplete && emailVerified;

  return (
    <Fragment>
      <GlobalStyle />

      {shouldRenderLogin ? (
        <Login onAuthenticatedSuccess={handleAuthenticatedSuccess} />
      ) : showCompleteRegistrationPage ? (
        <CompleteRegistration />
      ) : hasCurrentProfile ? (
        <Application />
      ) : null}
    </Fragment>
  );
}

export default App;
