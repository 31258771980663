import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';

import { ResourceDefinition, ResourceTypes } from '../types';

const LoopFolderResource: ResourceDefinition = {
  attributes: {
    folderId: ATTRIBUTE_TYPE.NUMBER,
    loopId: ATTRIBUTE_TYPE.NUMBER,
    name: ATTRIBUTE_TYPE.STRING,
    archived: ATTRIBUTE_TYPE.BOOLEAN,
    minimized: ATTRIBUTE_TYPE.BOOLEAN,
    publicLink: ATTRIBUTE_TYPE.BOOLEAN,
    sortOrder: ATTRIBUTE_TYPE.NUMBER,
    lastUpdatedDate: ATTRIBUTE_TYPE.DATE,
    groupId: ATTRIBUTE_TYPE.NUMBER,
    groupName: ATTRIBUTE_TYPE.STRING,
    requiredComplianceGroupId: ATTRIBUTE_TYPE.NUMBER,
    folderEmailDisplayName: ATTRIBUTE_TYPE.STRING,
    userId: ATTRIBUTE_TYPE.NUMBER,
    profileId: ATTRIBUTE_TYPE.NUMBER,
    submittedDate: ATTRIBUTE_TYPE.DATE,
    submittedDateTime: ATTRIBUTE_TYPE.DATE,
  },

  relationships: {
    items: {
      polymorphicResourceName: 'items',
      relationshipType: RELATIONSHIP_TYPE.MANY,
      resourceType: [ResourceTypes.Documents, ResourceTypes.Placeholders],
    },
    complianceGroup: {
      resourceType: ResourceTypes.ComplianceGroups,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
    complianceStatus: {
      resourceType: ResourceTypes.ComplianceStatuses,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
  },
};

export default LoopFolderResource;
