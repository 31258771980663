import { ATTRIBUTE_TYPE } from '../constants';
import { ResourceDefinition } from '../types';

const LoopDetails: ResourceDefinition = {
  attributes: {
    loopData: ATTRIBUTE_TYPE.AS_IS,
  },
};

export default LoopDetails;
