enum FeatureFlag {
  DarkMode = 'DARK_MODE',
  LoopDetails = 'LOOP_DETAILS',
  ActivityLogDownload = 'ACTIVITY_LOG_DOWNLOAD',
  EditInPrivate = 'EDIT_IN_PRIVATE',
  RequiredEditPermissionToShare = 'REQUIRED_EDIT_PERMISSION_TO_SHARE',
  UsePubNubToken = 'USE_PUBNUB_TOKEN',
}

export default FeatureFlag;
