import { useMemo } from 'react';
import { useAppSelector } from '~/store/hooks';

import { ProfileResource } from '~/store/features/api/resources/profile/types';
import { BrandedColor } from '~/styles/colors/types';
import { DEFAULT_BRAND_COLOR, generateBrandColors } from '~/styles/colors';
import { createRequiredContextHelper } from './lib/utils/context';

type Props = {
  children: React.ReactNode;
};

const [useBrand, BrandProviderInternal] =
  createRequiredContextHelper<BrandedColor>(DEFAULT_BRAND_COLOR);

function generateBrand(currentProfile: ProfileResource | null) {
  let primaryColor = null;

  if (currentProfile && currentProfile.primaryColor) {
    primaryColor = currentProfile.primaryColor;
  }

  return generateBrandColors(primaryColor);
}

function BrandProvider({ children }: Props) {
  const currentProfile = useAppSelector(state => state.account.currentProfile);
  const brand = useMemo(() => {
    return generateBrand(currentProfile);
  }, [currentProfile]);

  return (
    <BrandProviderInternal value={brand}>{children}</BrandProviderInternal>
  );
}

export { useBrand };
export default BrandProvider;
