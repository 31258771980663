import { ATTRIBUTE_TYPE } from '../constants';

import { ResourceDefinition } from '../types';

const ComplianceGroup: ResourceDefinition = {
  attributes: {
    name: ATTRIBUTE_TYPE.STRING,
    transactionTypeId: ATTRIBUTE_TYPE.NUMBER,
  },
};

export default ComplianceGroup;
