import { Component, Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';

import { FormattedMessage } from '~/components/i18n';
import LoadingMoreIndicator from '~/components/LoadingMoreIndicator';
import {
  breakpoints,
  colors,
  fontWeights,
  momentumScrollingStyles,
  spacing,
} from '~/styles';
import { MessageType } from '~/store/features/api/resources/message/constants';
import { ParticipantStatus } from '~/store/features/api/resources/participantStatus/constants';

import { makeResourceSelector } from '~/store/features/api/selectors';

import { getConversationName } from './ConversationName';
import ConversationHeader, {
  HEADER_HEIGHT,
  HEADER_HEIGHT_MEDIUM,
} from './ConversationHeader';
import ConversationActions from './ConversationActions';
import MessageListItem, { AVATAR_WIDTH } from './MessageListItem';

import { ConversationResource } from '~/store/features/api/resources/conversation/types';
import {
  MessageResource,
  TextMessageResource,
} from '~/store/features/api/resources/message/types';
import { JsonApiFetchOptions } from '~/lib/api/types';
import { AppState } from '~/store';
import { ResourceTypes } from '~/store/features/api/resources/types';
import {
  createResource,
  fetchAllResources,
  updateResource,
} from '~/store/features/api/apiSlice';

const MESSAGE_LIST_GUTTER = `${AVATAR_WIDTH} + (${spacing.smaller} * 2)`;
const StyledConversationActionsContainer = styled.div({
  position: 'absolute',
  bottom: spacing.smaller,
  left: spacing.smaller,
  width: `calc(100% - (${spacing.smaller} * 2))`,
});

const StyledConversationBeginningSubject = styled.span({
  fontWeight: fontWeights.bold,
});

const StyledConversationBeginning = styled.li({
  textAlign: 'center',
  color: colors.secondaryText,
  marginBottom: spacing.small,
});

const StyledLoadingContainer = styled.li({
  marginTop: spacing.small,
  padding: spacing.small,
  minHeight: `calc(${spacing.small} * 2 + 1rem)`,
  textAlign: 'center',
});

const StyledInfiniteScroll = styled(InfiniteScroll)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  minHeight: 'calc(100% - 1px)',
  marginTop: 0,
  marginBottom: 0,
  marginRight: spacing.larger,
  marginLeft: `calc(${MESSAGE_LIST_GUTTER})`,
  padding: `${spacing.medium} 0 0`,
});

const StyledConversationMessages = styled.div({
  ...momentumScrollingStyles,
  position: 'absolute',
  top: HEADER_HEIGHT,
  left: 0,
  width: '100%',

  [breakpoints.MEDIUM]: {
    top: HEADER_HEIGHT_MEDIUM,
  },
});

const StyledConversationContainer = styled.div({
  position: 'relative',
  width: '100%',
  height: '100%',
  backgroundColor: colors.white,
});

const SCROLL_THRESHOLD = 50;
export const MESSAGE_PAGE_SIZE = 20;

type ReduxProps = {
  conversation: ConversationResource | null;
  fetchConversationMessages: (
    conversationId: string,
    oldestMessageId?: string,
    reverseSort?: boolean
  ) => Promise<any>;
  markMessageAsRead: (
    conversationId: string,
    participantUuid: string,
    messageId: string
  ) => Promise<any>;
  sendConversationMessage: (
    conversationId: string,
    messageText: string
  ) => Promise<any>;
  retryConversationMessage: (
    conversationId: string,
    message: MessageResource
  ) => Promise<any>;
  updateLatestMessage: (conversationId: string, messageId: string) => void;
};

type ComponentProps = {
  conversationId: string;
  toggleConversationDetails: (conversationId: string) => void;
};

type Props = ReduxProps & ComponentProps;

type State = {
  currentDate: Date;
  currentConversationId: string;
  allowLoadMore: boolean;
  didLoadMessages: boolean;
  isLoadingMessages: boolean;
  didLoadMessagesFail: boolean;
  areAllMessagesLoaded: boolean;
  isSendingMessage: boolean;
  failedMessageIds: Array<string>;
};

function sortMessages(
  conversation: ConversationResource | null
): Array<MessageResource> {
  if (conversation && conversation.messages) {
    return conversation.messages.sort((a, b) => {
      const aDate = a.sentDate || a._clientSentDate;
      const bDate = b.sentDate || b._clientSentDate;

      return aDate.getTime() - bDate.getTime();
    });
  } else {
    return [];
  }
}

function getMessageResources(resources: Array<any>): Array<MessageResource> {
  return resources.filter(
    x => x.type === ResourceTypes.Messages
  ) as Array<MessageResource>;
}

export class Conversation extends Component<Props, State> {
  scrollContainerRef: HTMLElement | null = null;
  messageActionContainerRef: HTMLElement | null = null;
  currentScrollHeight: number | null = null;
  currentClientHeight: number | null = null;
  currentScrollTop: number | null = null;
  dateIntervalId: number | null = null;

  state: State = {
    currentDate: new Date(),
    allowLoadMore: false,
    didLoadMessages: false,
    isLoadingMessages: false,
    didLoadMessagesFail: false,
    areAllMessagesLoaded: false,
    isSendingMessage: false,
    failedMessageIds: [],
    currentConversationId: this.props.conversationId,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const conversationIdChanged =
      nextProps.conversationId !== prevState.currentConversationId;

    return {
      currentConversationId: nextProps.conversationId,
      allowLoadMore: !conversationIdChanged,
    };
  }

  componentDidMount() {
    if (!!this.props.conversation) {
      this.fetchMessages();
    }

    // Update `currentDate` every minute, so sent / read receipts stay updated
    this.dateIntervalId = window.setInterval(() => {
      this.updateCurrentDate();
    }, 1000 * 60);
  }

  shouldComponentUpdate(nextProps: Props) {
    if (!nextProps.conversationId && !!this.props.conversationId) {
      // If we removed the conversation ID, don't update since this means
      // we're on mobile and need the exit animation to go through
      return false;
    }

    return true;
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { conversation, conversationId } = this.props;
    const conversationIdChanged = prevProps.conversationId !== conversationId;
    const conversationWasLoaded = !prevProps.conversation && !!conversation;

    if (conversationIdChanged || conversationWasLoaded) {
      // When the conversation changes we want to reset the indicator
      // for all messages being loaded
      this.setState(
        { areAllMessagesLoaded: false, didLoadMessages: false },
        () => {
          this.scrollToBottom();
          this.fetchMessages();
        }
      );
    } else {
      this.manageScrollPosition(prevState);
    }

    if (prevState.currentDate === this.state.currentDate) {
      // Since we've just updated something other than `currentDate`, we can essentially
      // update the `currentDate` for 'free' now
      this.updateCurrentDate();
    }
  }

  componentWillUnmount() {
    if (this.dateIntervalId) {
      clearInterval(this.dateIntervalId);
    }
  }

  fetchMessages = async () => {
    const { conversation } = this.props;
    let numMessages = 0;

    if (conversation && conversation.messages) {
      numMessages = conversation.messages.length;
    }

    if (numMessages > 2) {
      // If we're rendering a conversation with more than 2 messages,
      // it means we've already loaded messages before, so we want
      // to make sure to try an load newer messages as well as older
      // messages to catch up, if needed
      this.setState({ didLoadMessages: true });
      await this.fetchNewerMessages();
    } else {
      await this.fetchOlderMessages();
    }
  };

  fetchOlderMessages = async (useCursor = false) => {
    const { conversation, conversationId } = this.props;
    let areAllMessagesLoaded = false;
    let didLoadMessagesFail = false;
    let oldestMessageId;

    try {
      this.setState({
        isLoadingMessages: true,
        areAllMessagesLoaded,
        didLoadMessagesFail,
      });

      if (useCursor) {
        const sortedMessages = sortMessages(conversation);

        oldestMessageId =
          sortedMessages.length > 0 ? sortedMessages[0].id : undefined;
      }

      const result = await this.props.fetchConversationMessages(
        conversationId,
        oldestMessageId
      );
      const messageResources = getMessageResources(result);

      areAllMessagesLoaded = messageResources.length !== MESSAGE_PAGE_SIZE;
    } catch (e) {
      didLoadMessagesFail = true;
    } finally {
      this.setState({
        didLoadMessages: true,
        isLoadingMessages: false,
        areAllMessagesLoaded,
        didLoadMessagesFail,
      });
    }
  };

  fetchNewerMessages = async () => {
    const { conversation, conversationId } = this.props;

    if (conversation) {
      try {
        const sortedMessages = sortMessages(conversation);
        let newestMessage = sortedMessages[sortedMessages.length - 1];
        let numMessages;

        do {
          const result = await this.props.fetchConversationMessages(
            conversationId,
            newestMessage.id,
            true
          );
          const messageResources = getMessageResources(result);

          numMessages = messageResources.length;

          if (numMessages === MESSAGE_PAGE_SIZE) {
            newestMessage = messageResources[numMessages - 1];
          }
        } while (numMessages === MESSAGE_PAGE_SIZE);
      } catch (e) {}
    }
  };

  updateCurrentDate = () => {
    this.setState({ currentDate: new Date() });
  };

  setupScrollContainerRef = (ref: HTMLElement | null) => {
    this.scrollContainerRef = ref;

    if (this.scrollContainerRef) {
      this.scrollContainerRef.onscroll = ({ target: { scrollTop } }: any) => {
        this.currentScrollTop = scrollTop;
      };
    }
  };

  manageScrollPosition = () => {
    const scrollContainerRef = this.scrollContainerRef;

    if (scrollContainerRef) {
      const { scrollHeight } = scrollContainerRef;
      let scrollToBottom = false;

      if (this.currentScrollHeight !== scrollHeight) {
        scrollToBottom = true;

        if (this.currentScrollHeight && this.currentClientHeight) {
          const previousMaxScroll =
            this.currentScrollHeight - this.currentClientHeight;
          const scrollHeightDifference =
            scrollHeight - this.currentScrollHeight;
          let prevScrollDiff = 0;
          let prevScrollTop = 0;

          if (
            this.currentScrollTop !== undefined &&
            this.currentScrollTop !== null
          ) {
            prevScrollTop = this.currentScrollTop;
            prevScrollDiff = previousMaxScroll - prevScrollTop;
          }

          if (
            // User was somewhere in the middle of the list when a new
            // message was loaded, so maintain their position
            previousMaxScroll > SCROLL_THRESHOLD &&
            prevScrollDiff > SCROLL_THRESHOLD
          ) {
            this.updateScrollPosition(prevScrollTop + scrollHeightDifference);
            scrollToBottom = false;
          }
        }
      }

      this.updateScrollContainerProps();

      if (scrollToBottom) {
        this.scrollToBottom();
      }
    }
  };

  updateScrollContainerProps = () => {
    if (this.scrollContainerRef) {
      const { scrollHeight, clientHeight } = this.scrollContainerRef;

      this.currentScrollHeight = scrollHeight;
      this.currentClientHeight = clientHeight;
    }
  };

  scrollToBottom = () => {
    if (this.scrollContainerRef) {
      const { scrollHeight } = this.scrollContainerRef;

      this.updateScrollPosition(scrollHeight);
    }
  };

  updateScrollPosition = (scrollTop: number) => {
    if (this.scrollContainerRef) {
      this.scrollContainerRef.scrollTop = scrollTop;
      this.currentScrollTop = this.scrollContainerRef.scrollTop;
    }
  };

  handleNumRowsChange = () => {
    this.scrollToBottom();
  };

  sendTextMessage = async (text: string) => {
    const { conversationId, sendConversationMessage, updateLatestMessage } =
      this.props;

    try {
      const response = await sendConversationMessage(conversationId, text);
      const message = response.find(
        (x: any) => x.type === ResourceTypes.Messages
      );

      updateLatestMessage(conversationId, message.id);
    } catch (e) {
      if (!!e.optional && !!e.optional.optimisticId) {
        this.addFailedMessageId(e.optional.optimisticId);
      }
    }
  };

  retryMessage = async (conversationId: string, message: MessageResource) => {
    const { retryConversationMessage, updateLatestMessage } = this.props;
    const failedMessageId = message.id;

    this.removeFailedMessageId(failedMessageId);

    try {
      const response = await retryConversationMessage(conversationId, message);
      const succeededMessage = response.find(
        (x: any) => x.type === ResourceTypes.Messages
      );

      updateLatestMessage(conversationId, succeededMessage.id);
    } catch (e) {
      this.addFailedMessageId(failedMessageId);
    }
  };

  addFailedMessageId = (messageId: string) => {
    this.setState(prevState => ({
      failedMessageIds: [...prevState.failedMessageIds, messageId],
    }));
  };

  removeFailedMessageId = (messageId: string) => {
    this.setState(prevState => ({
      failedMessageIds: prevState.failedMessageIds.filter(x => x !== messageId),
    }));
  };

  handleMessageActionContainerRef = (ref: HTMLElement | null) => {
    this.messageActionContainerRef = ref;
  };

  render() {
    const { conversation, toggleConversationDetails, markMessageAsRead } =
      this.props;
    const {
      currentDate,
      allowLoadMore,
      didLoadMessages,
      isLoadingMessages,
      areAllMessagesLoaded,
      didLoadMessagesFail,
      failedMessageIds,
    } = this.state;
    const sortedMessages = sortMessages(conversation);

    if (!conversation) {
      return null;
    }

    const messages = conversation.messages || [];
    const participants = conversation.participants || [];
    const hasMessages = !!messages && messages.length > 0;

    const messageActionContainerSize = !!this.messageActionContainerRef
      ? this.messageActionContainerRef.clientHeight
      : 0;

    // Account for bottom offset of text area + spacing above text area,
    // text area container size
    const messageContainerStyles = {
      bottom: `calc((${spacing.smaller} * 2) + ${spacing.small} + ${messageActionContainerSize}px`,
    };

    return (
      <StyledConversationContainer>
        <ConversationHeader
          conversation={conversation}
          toggleConversationDetails={toggleConversationDetails}
        />
        <StyledConversationMessages
          id="message-scroll-container"
          ref={this.setupScrollContainerRef}
          style={messageContainerStyles}
        >
          {hasMessages ? (
            <StyledInfiniteScroll
              id="conversation-message-list"
              isReverse
              useWindow={false}
              threshold={SCROLL_THRESHOLD}
              element="ul"
              hasMore={!areAllMessagesLoaded}
              initialLoad={didLoadMessages && allowLoadMore}
              loadMore={() => {
                if (
                  !isLoadingMessages &&
                  !areAllMessagesLoaded &&
                  !didLoadMessagesFail
                ) {
                  this.fetchOlderMessages(true);
                }
              }}
              loader={
                <StyledLoadingContainer key="loading-more-messages">
                  {isLoadingMessages ? <LoadingMoreIndicator /> : null}
                </StyledLoadingContainer>
              }
            >
              <Fragment>
                {areAllMessagesLoaded && (
                  <StyledConversationBeginning>
                    <FormattedMessage id="messaging.conversationBeginning" />{' '}
                    <StyledConversationBeginningSubject>
                      {getConversationName(conversation)}
                    </StyledConversationBeginningSubject>
                  </StyledConversationBeginning>
                )}
                {sortedMessages.map((message, index) => {
                  const messageFailedToSend =
                    failedMessageIds.indexOf(message.id) !== -1;

                  return (
                    <MessageListItem
                      key={message.id}
                      messageList={sortedMessages}
                      message={message}
                      messageIndex={index}
                      participants={participants}
                      currentDate={currentDate}
                      conversationId={conversation.id}
                      conversationType={conversation.conversationType}
                      markMessageAsRead={markMessageAsRead}
                      messageFailedToSend={messageFailedToSend}
                      retryFailedMessage={this.retryMessage}
                    />
                  );
                })}
              </Fragment>
            </StyledInfiniteScroll>
          ) : null}
        </StyledConversationMessages>
        <StyledConversationActionsContainer>
          <ConversationActions
            messageActionContainerRef={this.handleMessageActionContainerRef}
            onRowsChange={this.handleNumRowsChange}
            sendTextMessage={this.sendTextMessage}
          />
        </StyledConversationActionsContainer>
      </StyledConversationContainer>
    );
  }
}

const makeMapStateToProps = () => {
  const conversationSelector = makeResourceSelector<
    ConversationResource,
    ComponentProps
  >(
    ResourceTypes.Conversations,
    (_state: AppState, ownProps: ComponentProps) => ownProps.conversationId,
    {
      relationships: [
        ResourceTypes.Participants,
        'messages.sender',
        'messages.document',
        'messages.document.loop',
        'messages.documentRevision',
        'messages.statuses',
        'latestMessage',
      ],
    }
  );

  return (state: AppState, ownProps: ComponentProps) => ({
    conversation: conversationSelector(state, ownProps),
  });
};

const mapDispatchToProps = {
  fetchConversationMessages: (
    conversationId: string,
    cursorMessageId: string,
    reverseSort = false
  ) => {
    const options: JsonApiFetchOptions = {
      apiVersion: 'v1_1',
      page: {
        size: MESSAGE_PAGE_SIZE,
        cursor: cursorMessageId,
      },
      additionalOptions: {
        'filter[conversationId]': conversationId,
      },
    };

    if (reverseSort) {
      // Default sort is sentDate by descending order (newest first),
      // so reverse is ascending order, oldest first
      options.sort = {
        sentDate: 'asc',
      };
    }

    return fetchAllResources({ resourceName: ResourceTypes.Messages, options });
  },
  markMessageAsRead: (
    conversationId: string,
    participantUuid: string,
    messageId: string
  ) =>
    updateResource({
      resourceName: ResourceTypes.ParticipantStatuses,
      resourceId: `${participantUuid}:${messageId}`,

      attributesToUpdate: {
        participantUuid,
        conversationId,
        messageId,
        status: ParticipantStatus.Read,
      },

      resourceRelationships: null,
      updateOptions: { apiVersion: 'v1_1' },
    }),
  sendConversationMessage: (conversationId: string, messageText: string) =>
    createResource({
      resourceName: ResourceTypes.Messages,

      resourceAttributes: {
        text: messageText,
        messageType: MessageType.Text,
        _clientSentDate: new Date(),
      },

      resourceRelationships: { conversation: conversationId },
      createOptions: {
        apiVersion: 'v1_1',
        isOptimistic: true,
        allowRollback: false,
      },
    }),
  retryConversationMessage: (
    conversationId: string,
    message: TextMessageResource
  ) =>
    createResource({
      resourceName: ResourceTypes.Messages,

      resourceAttributes: {
        text: message.text,
        messageType: message.messageType,
        _clientSentDate: message._clientSentDate,
      },

      resourceRelationships: { conversation: conversationId },
      createOptions: {
        apiVersion: 'v1_1',
        isOptimistic: true,
        allowRollback: false,
      },
    }),
  updateLatestMessage: (conversationId: string, messageId: string) =>
    fetchAllResources.fulfilled(
      {
        type: 'success',
        data: [
          {
            id: conversationId,
            type: ResourceTypes.Conversations,
            attributes: {},
            relationships: {
              latestMessage: {
                data: { id: messageId, type: ResourceTypes.Messages },
              },
            },
          },
          // TODO: remove once the backend sends the correct conversation relationship with a new message
          {
            id: messageId,
            type: ResourceTypes.Messages,
            attributes: {},
            relationships: {
              conversation: {
                data: { id: conversationId, type: 'conversation' },
              },
            },
          },
        ],
      },
      ResourceTypes.Conversations,
      {
        resourceName: ResourceTypes.Conversations,
      }
    ),
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(Conversation as any);
