export type Dimensions =
  | { width: string }
  | { height: string }
  | { width: string; height: string };
export default function calculateDimensions(
  width?: string,
  height?: string
): Dimensions {
  if (width && !height) {
    return { width };
  } else if (height && !width) {
    return { height };
  } else if (width && height) {
    return { width, height };
  } else {
    return { width: '100%' };
  }
}
