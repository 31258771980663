import { ATTRIBUTE_TYPE } from '../constants';

import { ResourceDefinition } from '../types';

const ParticipantStatus: ResourceDefinition = {
  attributes: {
    date: ATTRIBUTE_TYPE.DATE,
    conversationId: ATTRIBUTE_TYPE.STRING,
    messageId: ATTRIBUTE_TYPE.STRING,
    participantUuid: ATTRIBUTE_TYPE.STRING,
    participantId: ATTRIBUTE_TYPE.STRING,
    status: ATTRIBUTE_TYPE.STRING,
    errorCode: ATTRIBUTE_TYPE.NUMBER,
    errorMessage: ATTRIBUTE_TYPE.STRING,
  },
};

export default ParticipantStatus;
