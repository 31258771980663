// Scale - DO NOT EDIT
// Only add application scoped values
const scale = {
  zIndexNegative: -1,
  zIndexAboveSibling: 1,
  zIndex0: 0,
  zIndexHalf: 50,
  zIndex1: 100,
  zIndex2: 200,
  zIndex3: 300,
  zIndex4: 400,
  zIndex5: 500,
  zIndex6: 600,
  zIndex7: 700,
  zIndex8: 800,
  zIndex9: 900,
  zIndex10: 1000,
};

// Application scoped z-index
const zIndex = {
  default: scale.zIndex0,
  hidden: scale.zIndexNegative,
  aboveSibling: scale.zIndexAboveSibling,
  foreground: scale.zIndex1,
  floatingButton: scale.zIndex1,
  dropZone: scale.zIndex1,
  header: scale.zIndex1,
  datepicker: scale.zIndex2,
  popoverMenu: scale.zIndex2,
  bottomNav: scale.zIndex3,
  messaging: scale.zIndex4,
  profileSwitcherOverlay: scale.zIndex4,
  fullPageOverlay: scale.zIndex4,
  profileSwitcher: scale.zIndex5,
  dropdown: scale.zIndex5,
  overlayBackground: scale.zIndex6,
  overlayForeground: scale.zIndex7,
  headerSearchResults: scale.zIndex7,
  modalOverlayBackground: scale.zIndex8,
  modalOverlayForeground: scale.zIndex9,
  nestedModalOverlayBackground: scale.zIndex9 + 1,
  nestedModalOverlayForeground: scale.zIndex9 + 2,
  accountMenu: scale.zIndex9,
  notificationSnackbars: scale.zIndex10,
};

export default zIndex;
