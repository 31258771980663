import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';
import { ResourceDefinition, ResourceTypes } from '../types';

const LoopData: ResourceDefinition = {
  attributes: {
    value: ATTRIBUTE_TYPE.STRING,
    name: ATTRIBUTE_TYPE.STRING,
    fieldLabelId: ATTRIBUTE_TYPE.NUMBER,
    fieldTypeId: ATTRIBUTE_TYPE.NUMBER,
    memberId: ATTRIBUTE_TYPE.STRING,
    lastUpdatedDate: ATTRIBUTE_TYPE.DATE,
  },
  relationships: {
    fieldLabel: {
      resourceType: ResourceTypes.FieldLabels,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
  },
};

export default LoopData;
