import { Fragment } from 'react';
import styled from 'styled-components';
import { ContactResource } from '~/store/features/api/resources/contact/types';
import ContactSearchResultItem from './ContactSearchResultItem';
import { colors, fontSizes, fontWeights, spacing } from '~/styles';
import { FormattedMessage } from '~/components/i18n';
import { AnimatePresence, motion } from 'framer-motion';
import LoadingMoreIndicator from '~/components/LoadingMoreIndicator';
import { LinkButton } from '~/components/buttons';

const DEFAULT_HEIGHT = '14rem';
const StyledSearchLoading = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: DEFAULT_HEIGHT,
  overflow: 'hidden',
});

const StyledNoResultsDetails = styled.div({
  ...fontSizes.callout,
  marginTop: spacing.smaller,
  color: colors.secondaryText,
  lineHeight: spacing.medium,
  fontWeight: fontWeights.semiBold,
  textAlign: 'center',
});

const StyledNoResultsHeader = styled.div({
  ...fontSizes.secondaryTitle,
  color: colors.secondaryText,
  fontWeight: fontWeights.medium,
  textAlign: 'center',
});

const StyledNoResultsContent = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '80%',
  transform: 'translate(-50%, -50%)',
});

const StyledNoResultsContainer = styled.div({
  position: 'relative',
  padding: `${spacing.normal} ${spacing.medium}`,
  height: DEFAULT_HEIGHT,
  overflow: 'hidden',
});

const StyledFadeContainer = styled(motion.div)({
  overflow: 'hidden',
});

const StyledSearchResultList = styled.ul({
  margin: 0,
  padding: 0,
  paddingBottom: spacing.smaller,
});

const StyledResultsContainer = styled(motion.div)({
  overflow: 'hidden',
});

type Props = {
  contacts: Array<ContactResource>;
  searchValue: string;
  isLoading: boolean;
  showSearchResults: boolean;
  importContact: (contact: ContactResource) => void;
  fetchAllContacts: () => void;
  allContacts: Array<ContactResource>;
  showAllContacts: boolean;
  haveContactsBeenRequested: boolean;
};

export function ContactSearchResults(props: Props) {
  const {
    contacts,
    searchValue,
    isLoading,
    showSearchResults,
    importContact,
    fetchAllContacts,
    allContacts,
    showAllContacts,
    haveContactsBeenRequested,
  } = props;
  const hasMatchingContacts = !!contacts && contacts.length > 0;
  const showNoResults =
    haveContactsBeenRequested && !isLoading && !hasMatchingContacts;

  let containerVariant =
    showSearchResults && !!searchValue ? 'default' : 'empty';
  let contentVariant;

  if ((showSearchResults && hasMatchingContacts) || showAllContacts) {
    containerVariant = 'results';
  } else if (!hasMatchingContacts) {
    if (!searchValue) {
      contentVariant = 'empty';
    } else if (showNoResults) {
      contentVariant = 'no-results';
    } else {
      contentVariant = 'loading';
    }
  }

  return (
    <Fragment>
      <StyledResultsContainer
        initial="empty"
        animate={containerVariant}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        variants={{
          empty: { height: '0px' },
          default: { height: DEFAULT_HEIGHT },
          results: { height: 'auto', transitionEnd: { overflow: 'visible' } },
        }}
      >
        {showAllContacts ? (
          <StyledSearchResultList>
            {allContacts.map(contact => (
              <ContactSearchResultItem
                key={contact.id}
                contact={contact}
                searchValue={searchValue}
                importContact={importContact}
              />
            ))}
          </StyledSearchResultList>
        ) : !hasMatchingContacts ? (
          <AnimatePresence>
            <StyledFadeContainer
              key={contentVariant}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {!searchValue ? null : showNoResults ? (
                <StyledNoResultsContainer>
                  <StyledNoResultsContent>
                    <StyledNoResultsHeader>
                      <FormattedMessage id="loop-details:people.participantForm.searchContacts.noResultsHeader" />
                    </StyledNoResultsHeader>
                    <StyledNoResultsDetails>
                      <FormattedMessage id="loop-details:people.participantForm.searchContacts.noResultsMessage" />
                      <LinkButton id="see-contacts" onClick={fetchAllContacts}>
                        <FormattedMessage id="loop-details:people.participantForm.searchContacts.seeContacts" />
                      </LinkButton>
                    </StyledNoResultsDetails>
                  </StyledNoResultsContent>
                </StyledNoResultsContainer>
              ) : (
                <StyledSearchLoading>
                  <LoadingMoreIndicator />
                </StyledSearchLoading>
              )}
            </StyledFadeContainer>
          </AnimatePresence>
        ) : (
          <StyledSearchResultList>
            {contacts.map(contact => (
              <ContactSearchResultItem
                key={contact.id}
                contact={contact}
                searchValue={searchValue}
                importContact={importContact}
              />
            ))}
          </StyledSearchResultList>
        )}
      </StyledResultsContainer>
    </Fragment>
  );
}

export default ContactSearchResults;
