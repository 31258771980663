import { ATTRIBUTE_TYPE } from '../constants';

import { ResourceDefinition } from '../types';

const Profile: ResourceDefinition = {
  attributes: {
    active: ATTRIBUTE_TYPE.BOOLEAN,
    name: ATTRIBUTE_TYPE.STRING,
    profileType: ATTRIBUTE_TYPE.STRING,
    subscriptionLevel: ATTRIBUTE_TYPE.STRING,
    primaryColor: ATTRIBUTE_TYPE.STRING,
    primaryUserId: ATTRIBUTE_TYPE.NUMBER,
    imageUrl: ATTRIBUTE_TYPE.STRING,
    logoUrl: ATTRIBUTE_TYPE.STRING,
    permissions: ATTRIBUTE_TYPE.AS_IS,
    directParentProfileName: ATTRIBUTE_TYPE.STRING,
    directParentProfileType: ATTRIBUTE_TYPE.STRING,
    features: ATTRIBUTE_TYPE.AS_IS,
    countryCodeId: ATTRIBUTE_TYPE.NUMBER,
    primaryContactUserId: ATTRIBUTE_TYPE.NUMBER,
    grandfatheredLoopLimit: ATTRIBUTE_TYPE.BOOLEAN,
    companyType: ATTRIBUTE_TYPE.STRING,
    address1: ATTRIBUTE_TYPE.STRING,
    address2: ATTRIBUTE_TYPE.STRING,
    city: ATTRIBUTE_TYPE.STRING,
    state: ATTRIBUTE_TYPE.STRING,
    zipcode: ATTRIBUTE_TYPE.STRING,
    phoneNumber: ATTRIBUTE_TYPE.STRING,
    faxNumber: ATTRIBUTE_TYPE.STRING,
    brokerAgentId: ATTRIBUTE_TYPE.STRING,
    userProvidedId: ATTRIBUTE_TYPE.STRING,
    photoUrl: ATTRIBUTE_TYPE.STRING,
    companyName: ATTRIBUTE_TYPE.STRING,
    companyAddress: ATTRIBUTE_TYPE.STRING,
    licenseNumber: ATTRIBUTE_TYPE.STRING,
  },
};

export default Profile;
