import { Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Account from '~/components/Header/Account';
import Container from '~/components/layout/Container';
import MainLogo from '~/components/MainLogo';
import { MenuIcon } from '~/components/icons';
import AppLink from '~/components/AppLink';
import { FormattedMessage } from '~/components/i18n';

import { spacing, headerHeight, colors } from '~/styles';
import { selectCurrentProfile } from '~/store/features/account/selectors';
import { ProfileResource } from '~/store/features/api/resources/profile/types';
import { AppState } from '~/store';

const mixins = {
  headerItem: {
    padding: `${spacing.small} 0`,
  },
};

const StyledLogo = styled.div<{
  $headerItemEnabled: boolean;
}>(props => ({
  display: 'flex',
  flex: '0 1 auto',
  alignItems: 'center',
  height: '100%',
  marginLeft: spacing.normal,
  ...(props.$headerItemEnabled ? mixins.headerItem : {}),
}));

const StyledAccount = styled.div<{
  $headerItemEnabled: boolean;
}>(props => ({
  flex: '0 1 auto',
  height: '80%',
  ...(props.$headerItemEnabled ? mixins.headerItem : {}),
}));

const StyledHeaderWrapper = styled.div({
  ...headerHeight.styles,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
});

const StyledIntranetLinkContainer = styled.div({
  paddingTop: spacing.small,
  paddingBottom: spacing.small,
  borderBottom: `1px solid ${colors.lightBorder}`,
});

type ReduxProps = {
  currentProfile: ProfileResource | null;
};

type Props = {
  children?: ReactNode;
} & ReduxProps;

export function DefaultMobileHeader(props: Props) {
  const { currentProfile, children } = props;

  return (
    <Fragment>
      {currentProfile &&
        !!currentProfile.intranetLinkUrl &&
        !!currentProfile.intranetLinkText && (
          <StyledIntranetLinkContainer>
            <Container>
              <AppLink to={currentProfile.intranetLinkUrl}>
                <span>
                  <FormattedMessage id="intranetLink.actionText" />
                  &nbsp;
                  <FormattedMessage id={currentProfile.intranetLinkText} />
                </span>
              </AppLink>
            </Container>
          </StyledIntranetLinkContainer>
        )}
      <Container>
        <StyledHeaderWrapper>
          <StyledAccount $headerItemEnabled>
            <Account
              menuToggle={<MenuIcon height={spacing.medium} type="grey" />}
            />
          </StyledAccount>

          <StyledLogo $headerItemEnabled>
            <MainLogo />
          </StyledLogo>

          {!!children && children}
        </StyledHeaderWrapper>
      </Container>
    </Fragment>
  );
}

const mapStateToProps = (state: AppState): ReduxProps => ({
  currentProfile: selectCurrentProfile(state),
});

export default connect(mapStateToProps)(DefaultMobileHeader);
