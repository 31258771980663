import { useLoop } from '~/hooks/api/loop';
import { useCurrentProfile } from '~/hooks/api/account';
import { SubscriptionLevel } from '~/store/features/api/resources/profile/constants';
import { LoopParticipantResource } from '~/store/features/api/resources/loopParticipant/types';

const ONE_DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
const LOOP_DAYS_LIMIT = 90;
const LOOP_PARTICIPANTS_LIMIT = 10;

export const useCanAddNewParticipant = (
  loopId: string,
  loopParticipants: LoopParticipantResource[]
) => {
  const loop = useLoop(loopId);
  const currentProfile = useCurrentProfile();
  const subscriptionLevel =
    currentProfile && currentProfile.subscriptionLevel
      ? currentProfile.subscriptionLevel.toLowerCase()
      : '';

  if (loop) {
    if (subscriptionLevel === SubscriptionLevel.Free) {
      // two scenarios we need to restrict adding a participant are loops that exceed 90 days
      // or if it already has 10 loop participants
      const filteredParticipants = loopParticipants.filter(p => !p.restricted);
      const loopCreationDate = loop.createdDate;
      const today = new Date();
      const difference = today.getTime() - loopCreationDate.getTime();
      const daysSinceLoopCreation = Math.round(
        difference / ONE_DAY_IN_MILLISECONDS
      );
      return (
        daysSinceLoopCreation < LOOP_DAYS_LIMIT &&
        filteredParticipants.length < LOOP_PARTICIPANTS_LIMIT
      );
    } else {
      return true;
    }
  }
};
