import { useEffect } from 'react';

import { generateLoopDetailsLink } from '~/services/loop-service';

type Props = {
  loopId: string;
};

const LoopDetailsRedirect = function ({ loopId }: Props) {
  useEffect(() => {
    // Assume if user is redirecting, they're not opted in
    const loopDetailsLink = generateLoopDetailsLink(loopId, false);
    if (!loopDetailsLink.indexOf('http')) {
      window.location.href = loopDetailsLink;
    }
  }, [loopId]);

  return null;
};

export default LoopDetailsRedirect;
