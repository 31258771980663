import { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from '~/components/i18n';

import APP_CONFIG from '~/config';

import AppLink from '~/components/AppLink';
import {
  InlineNotification,
  NotificationType,
} from '~/components/Notifications';

import { getLoopsRemaining } from '~/store/features/account/selectors';
import { AppState } from '~/store';

const {
  ui: { phoenixUrl },
} = APP_CONFIG;

export const PREMIUM_LINK_URL = `${phoenixUrl}/buy/premium`;
const NOTIFICATION_THRESHOLD = 8;

const StyledInlineNotification = styled.div({
  marginTop: '12px',
});

type Props = {
  loopsRemaining: number;
};

export class LoopLimitInlineNotification extends Component<Props> {
  render() {
    const { loopsRemaining } = this.props;

    if (
      loopsRemaining === -1 ||
      isNaN(loopsRemaining) ||
      loopsRemaining > NOTIFICATION_THRESHOLD
    ) {
      return null;
    }

    const messageId =
      loopsRemaining === 0
        ? 'errors.loopLimit.zero'
        : 'errors.loopLimit.default';

    return (
      <StyledInlineNotification>
        <InlineNotification type={NotificationType.Info}>
          <FormattedMessage id={messageId} values={{ count: loopsRemaining }} />
          <AppLink to={PREMIUM_LINK_URL}>
            {' '}
            <FormattedMessage id="errors.loopLimit.link" />
          </AppLink>
        </InlineNotification>
      </StyledInlineNotification>
    );
  }
}

const mapStateToProps = (state: AppState): Props => ({
  loopsRemaining: getLoopsRemaining(state),
});

export default connect(mapStateToProps)(LoopLimitInlineNotification);
