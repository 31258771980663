import { colors } from '~/styles';
import { PersonResource } from '~/components/Avatar/Avatar';

export function getColorForName(name?: string | null) {
  if (!!name) {
    const hash = computeHash(name);
    const index = Math.abs(hash) % colors.avatars.length;

    return colors.avatars[index];
  }

  return colors.white;
}

function computeHash(contents: string) {
  let hash = 0;
  let chr;

  if (!contents || contents.length === 0) {
    return hash;
  }

  for (let i = 0; i < contents.length; i++) {
    chr = contents.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
}

export function getPersonName(participant: PersonResource): string | null {
  if ('name' in participant) {
    return participant.name;
  } else if (participant.firstName && participant.lastName) {
    return `${participant.firstName} ${participant.lastName}`;
  } else {
    return null;
  }
}
