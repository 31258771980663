import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';
import { ResourceDefinition, ResourceTypes } from '../types';

const DocumentClass: ResourceDefinition = {
  attributes: {
    name: ATTRIBUTE_TYPE.STRING,
    systemId: ATTRIBUTE_TYPE.NUMBER,
    active: ATTRIBUTE_TYPE.BOOLEAN,
    hidden: ATTRIBUTE_TYPE.BOOLEAN,
  },
  relationships: {
    documents: {
      resourceType: ResourceTypes.Documents,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
  },
};

export default DocumentClass;
