import { datadogRum } from '@datadog/browser-rum';
import config from '~/config';

const {
  ui: {
    isProductionBuild,
    datadogRumAppId,
    datadogRumClientToken,
    environment,
    version,
    phoenixUrl,
  },
} = config;

const isLowerEnv = environment === 'dev' || environment === 'qa';

export function initializeDatadog() {
  if (isProductionBuild && environment !== 'local') {
    // RUM is enabled in deployed environments only, not localhost
    datadogRum.init({
      applicationId: datadogRumAppId,
      clientToken: datadogRumClientToken,
      site: 'datadoghq.com',
      service: 'dotloop-nova',
      env: environment,
      // 5% sample rate in prod and non-lower envs due to dotloop's overall traffic
      // 100% sampling for dev and QA, (cypress runs against dev, so this should help there)
      sampleRate: isLowerEnv ? 100 : 5,
      // Do not auto-track interactions due to PII, all tracking done manually
      trackInteractions: false,
      trackViewsManually: true,
      allowedTracingOrigins: [phoenixUrl],
      version,
      replaySampleRate: 0,
    });
  }
}
