import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';

import { ResourceDefinition, ResourceTypes } from '../types';

const Document: ResourceDefinition = {
  attributes: {
    name: ATTRIBUTE_TYPE.STRING,
    revision: ATTRIBUTE_TYPE.NUMBER,
    viewId: ATTRIBUTE_TYPE.STRING,
    locked: ATTRIBUTE_TYPE.BOOLEAN,
    privateDocument: ATTRIBUTE_TYPE.BOOLEAN,
    unreadComplianceReviewNotes: ATTRIBUTE_TYPE.NUMBER,
    archived: ATTRIBUTE_TYPE.BOOLEAN,
    required: ATTRIBUTE_TYPE.BOOLEAN,
    saved: ATTRIBUTE_TYPE.BOOLEAN,
    shareStatus: ATTRIBUTE_TYPE.STRING,
    reviewStatus: ATTRIBUTE_TYPE.STRING,
    sharedWithViewIds: ATTRIBUTE_TYPE.AS_IS,
    verificationUrl: ATTRIBUTE_TYPE.STRING,
    documentSignaturesVerification: ATTRIBUTE_TYPE.AS_IS,
    profileId: ATTRIBUTE_TYPE.NUMBER,
    workflowId: ATTRIBUTE_TYPE.NUMBER,
    workflowStatus: ATTRIBUTE_TYPE.STRING,
    lastUpdated: ATTRIBUTE_TYPE.DATE,
    sortValue: ATTRIBUTE_TYPE.STRING,
    placeholderId: ATTRIBUTE_TYPE.NUMBER,
    lastSortSeq: ATTRIBUTE_TYPE.STRING,
    shareWorkflowTemplateId: ATTRIBUTE_TYPE.NUMBER,
    shareEmailFailedParticipantIds: ATTRIBUTE_TYPE.AS_IS,
    pdfConversionStatus: ATTRIBUTE_TYPE.STRING,
    malwareScanStatus: ATTRIBUTE_TYPE.STRING,
    fileSize: ATTRIBUTE_TYPE.NUMBER,
    packetStatus: ATTRIBUTE_TYPE.AS_IS,
    initialSharerUserMemberId: ATTRIBUTE_TYPE.STRING,
  },
  relationships: {
    loop: {
      resourceType: ResourceTypes.Loops,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
    revisions: {
      resourceType: ResourceTypes.DocumentRevisions,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
    pages: {
      resourceType: ResourceTypes.DocumentPages,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
    latestRevision: {
      resourceType: ResourceTypes.DocumentRevisions,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
    documentClass: {
      resourceType: ResourceTypes.DocumentClasses,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
    loopFolder: {
      resourceType: ResourceTypes.LoopFolders,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
    templateFolder: {
      resourceType: ResourceTypes.TemplateFolders,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
    tags: {
      resourceType: ResourceTypes.Tags,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
    complianceReviewNotes: {
      resourceType: ResourceTypes.ComplianceReviewNote,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
  },
};

export default Document;
