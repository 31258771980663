export enum ProfileType {
  Individual = 'INDIVIDUAL',
  Office = 'OFFICE',
  Company = 'COMPANY',
  Association = 'ASSOCIATION',
  NationalPartner = 'NATIONAL_PARTNER',
  Team = 'TEAM',
}

export const profileTypeIdToEnum = (profileTypeId: number) => {
  switch (profileTypeId) {
    case 2:
      return ProfileType.Office;
    case 3:
      return ProfileType.Company;
    case 4:
      return ProfileType.Association;
    case 5:
      return ProfileType.NationalPartner;
    case 6:
      return ProfileType.Team;
    default:
      return ProfileType.Individual;
  }
};

export enum ProfileIdType {
  Individual = 1,
  Office = 2,
  Company = 3,
  Association = 4,
  NationalPartner = 5,
  Team = 6,
}

export enum SubscriptionLevel {
  Free = 'free',
  Premium = 'premium',
  Business = 'business',
  BusinessPlus = 'business_plus',
  BusinessLite = 'business_lite',
  Teams = 'teams',
}
