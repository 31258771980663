import { forwardRef, ForwardedRef } from 'react';

import BaseInput from './__internal__/BaseInput';
import {
  colors,
  spacing,
  fontSizes,
  fontWeights,
  utils,
  zIndex,
} from '~/styles';

import { ConsumerInputProps } from './__internal__/BaseInput';

const styles = {
  container: {
    position: 'relative',
    width: '100%',
    padding: `${spacing.smallest} ${spacing.small}`,
    border: `1px solid ${colors.lightBorder}`,
    borderRadius: utils.baseBorderRadius,
    backgroundColor: colors.white,
    textAlign: 'left',
  },
  containerFocused: {
    borderColor: colors.primaryAction,
    boxShadow: `0 0 0 1px ${colors.primaryAction}`,
  },
  containerDisabled: {
    borderColor: 'transparent',
    backgroundColor: colors.lightBackground,
    cursor: 'no-drop',
  },
  containerInvalid: {
    borderColor: colors.errorColor,
  },
  containerInvalidFocus: {
    borderColor: colors.errorColor,
    boxShadow: `0 0 0 1px ${colors.errorColor}`,
  },
  containerHighlighted: {
    backgroundColor: colors.highlight,
  },
  label: {
    ...fontSizes.callout,
    lineHeight: spacing.normal,
    color: colors.secondaryText,
    cursor: 'pointer',
    zIndex: zIndex.aboveSibling,
  },
  labelFocused: {
    color: colors.primaryAction,
  },
  labelDisabled: {
    color: colors.hintText,
    cursor: 'not-allowed',
  },
  labelInvalid: {
    color: colors.errorIcon,
  },
  formattedValue: {
    ...utils.text.truncate,
    ...fontSizes.body,
    color: colors.black,
    fontWeight: fontWeights.regular,
  },
  input: {
    ...fontSizes.body,
    width: '100%',
    margin: 0,
    padding: 0,
    color: colors.black,
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'initial',

    '&::placeholder': {
      color: colors.secondaryText,
    },

    '&::-ms-clear': {
      display: 'none',
    },

    '&:focus, &:active': {
      border: 'none',
      outline: 'none',
    },

    '&:disabled': {
      cursor: 'not-allowed',
    },
  },
  subtext: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    transform: `translateY(calc(100% + ${spacing.smallest}))`,
  },
};

function OutlinedTextInputBase(
  props: ConsumerInputProps,
  inputRef: ForwardedRef<HTMLInputElement>
) {
  return (
    <BaseInput
      {...props}
      ref={inputRef}
      containerClasses={{
        default: styles.container,
        hovered: styles.containerFocused,
        focused: styles.containerFocused,
        disabled: styles.containerDisabled,
        invalid: styles.containerInvalid,
        invalidFocused: styles.containerInvalidFocus,
        invalidHovered: styles.containerInvalidFocus,
        highlighted: styles.containerHighlighted,
      }}
      labelClasses={{
        default: styles.label,
        hovered: styles.labelFocused,
        focused: styles.labelFocused,
        disabled: styles.labelDisabled,
        invalid: styles.labelInvalid,
      }}
      subtextClasses={{ default: styles.subtext }}
      inputClasses={{ default: styles.input }}
      formattedValueClasses={{ default: styles.formattedValue }}
      placeholder={props.placeholder}
      showErrorOnEmpty={props.showErrorOnEmpty}
    />
  );
}

export const OutlinedTextInput = forwardRef<
  HTMLInputElement,
  ConsumerInputProps
>(OutlinedTextInputBase);

export default OutlinedTextInput;
