import styled from 'styled-components';

import ConversationName from './ConversationName';
import { InformationIcon } from '~/components/icons';
import { LinkButton } from '~/components/buttons';
import { ArrowIcon } from '~/components/icons';

import {
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  spacing,
  utils,
} from '~/styles';
import { LinkButtonType } from '~/components/buttons/LinkButton';
import { ConversationResource } from '~/store/features/api/resources/conversation/types';
import { RotationDirection } from '~/components/icons/types';

export const HEADER_HEIGHT = '4.5rem';
export const HEADER_HEIGHT_MEDIUM = '5rem';

const StyledDetailsIcon = styled.div({
  position: 'absolute',
  top: '50%',
  right: spacing.normal,
  transform: 'translateY(-50%)',
  cursor: 'pointer',

  [breakpoints.LARGE]: {
    bottom: spacing.smaller,
    top: 'unset',
    transform: 'none',
  },
});

const StyledConversationHeaderTitle = styled.div({
  ...utils.text.truncate,
  ...fontSizes.headline,
  fontWeight: fontWeights.bold,
  textAlign: 'center',
  maxWidth: `calc(100% - 5rem)`,

  [breakpoints.MEDIUM]: {
    textAlign: 'left',
  },
});

const StyledBackButton = styled.div({
  position: 'absolute',
  top: '50%',
  left: spacing.normal,
  transform: 'translateY(-50%)',

  [breakpoints.MEDIUM]: {
    display: 'none',
  },
});

const StyledConversationHeader = styled.div({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: HEADER_HEIGHT,
  borderBottom: `1px solid ${colors.lightBorder}`,

  [breakpoints.MEDIUM]: {
    height: HEADER_HEIGHT_MEDIUM,
    justifyContent: 'flex-start',
    padding: spacing.normal,
  },

  [breakpoints.LARGE]: {
    alignItems: 'flex-end',
    padding: `${spacing.normal} ${spacing.large}`,
  },
});

type Props = {
  conversation: ConversationResource;
  toggleConversationDetails: () => void;
};

export function ConversationHeader(props: Props) {
  const { conversation, toggleConversationDetails } = props;

  return (
    <StyledConversationHeader>
      <StyledBackButton>
        <LinkButton
          id="back-to-messages-button"
          buttonStyle={LinkButtonType.Plain}
          removeSidePadding
          icon={ArrowIcon}
          defaultIconProps={{ direction: RotationDirection.Left }}
          link="/messages"
        />
      </StyledBackButton>
      <StyledConversationHeaderTitle>
        <ConversationName conversation={conversation} />
      </StyledConversationHeaderTitle>
      <StyledDetailsIcon>
        <LinkButton
          id="conversation-details-button"
          onClick={toggleConversationDetails}
          icon={InformationIcon}
          defaultIconProps={{ height: spacing.large }}
        />
      </StyledDetailsIcon>
    </StyledConversationHeader>
  );
}

export default ConversationHeader;
