import { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  SnackbarNotification,
  NotificationType,
} from '~/components/Notifications';
import { createParticipants } from '~/services/messaging-service';

import { ConversationType } from '~/store/features/api/resources/conversation/constants';

import NewConversationHeader from './NewConversationHeader';

import { Recipient } from './RecipientMultiSelect';
import { ParticipantResource } from '~/store/features/api/resources/participant/types';
import { useI18n } from '~/hooks';
import { ResourceTypes } from '~/store/features/api/resources/types';
import { createResource } from '~/store/features/api/apiSlice';

const StyledContainer = styled.div({
  position: 'relative',
  height: '100%',
});

type Props = {
  searchTerm?: string | null;
  createConversation: (
    participants: Array<ParticipantResource>,
    groupName?: string
  ) => any;
  viewConversation: (conversationId: string) => void;
  hideConversation?: () => void;
};

export function NewConversation(props: Props) {
  const { searchTerm, hideConversation, createConversation, viewConversation } =
    props;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const i18n = useI18n();

  const handleCreateConversation = async (
    selectedRecipients: Array<Recipient>,
    groupName?: string
  ) => {
    try {
      const participants = await createParticipants(selectedRecipients);
      const result = await createConversation(participants, groupName);

      if (result) {
        const conversationId = result.payload?.data[0]?.id;
        !!conversationId && viewConversation(conversationId);
      }
    } catch (e: any) {
      if (!!e.data && e.data.length === 1) {
        const { code } = e.data[0];
        let errorMessage;

        if (code === 'invalid.participant.email.address.added.self') {
          errorMessage = i18n.t('messaging.create.errors.sendingToOwnEmail');
        } else if (code === 'invalid.participant.phone.number.added.self') {
          errorMessage = i18n.t('messaging.create.errors.sendingToOwnPhone');
        } else if (code === 'invalid.format.participant.id') {
          errorMessage = i18n.t('messaging.create.errors.invalidEmailOrPhone');
        } else {
          errorMessage = e.data[0].detail;
        }

        setErrorMessage(errorMessage);
      }
    }
  };

  const handleCancelNewConversation = () => {
    if (hideConversation) {
      hideConversation();
    }
  };

  const dismissError = () => {
    setErrorMessage(null);
  };

  return (
    <StyledContainer>
      {!!errorMessage && (
        <SnackbarNotification
          id="create-conversation-error"
          type={NotificationType.Error}
          onDismiss={dismissError}
        >
          {errorMessage}
        </SnackbarNotification>
      )}
      <NewConversationHeader
        createConversation={handleCreateConversation}
        onCancelNewConversation={handleCancelNewConversation}
        searchTerm={searchTerm}
      />
    </StyledContainer>
  );
}

const mapDispatchToProps = {
  createConversation: (
    participants: Array<ParticipantResource>,
    groupName?: string
  ) =>
    createResource({
      resourceName: ResourceTypes.Conversations,

      resourceAttributes: {
        conversationType:
          participants.length > 1
            ? ConversationType.Group
            : ConversationType.Direct,
        name: groupName,
      },

      resourceRelationships: { participants: participants.map(p => p.id) },
      createOptions: { apiVersion: 'v1_1' },
    }),
};

export default connect(null, mapDispatchToProps)(NewConversation);
