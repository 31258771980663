import AppLink, { AppLinkProps } from '~/components/AppLink';

import { useLoopDetailsLink } from '~/services/loop-service';

type Props = {
  loopId: string;
} & Pick<AppLinkProps, Exclude<keyof AppLinkProps, 'to'>>;

function LoopDetailsLink(props: Props) {
  const { loopId, children, ...rest } = props;
  const link = useLoopDetailsLink(loopId);

  return (
    <AppLink to={link} {...rest}>
      {children}
    </AppLink>
  );
}

export default LoopDetailsLink;
