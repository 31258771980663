import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';

import { ResourceDefinition, ResourceTypes } from '../types';

const Participant: ResourceDefinition = {
  attributes: {
    firstName: ATTRIBUTE_TYPE.STRING,
    middleName: ATTRIBUTE_TYPE.STRING,
    lastName: ATTRIBUTE_TYPE.STRING,
    me: ATTRIBUTE_TYPE.BOOLEAN,
    contactId: ATTRIBUTE_TYPE.NUMBER,
    routingType: ATTRIBUTE_TYPE.STRING,
    emailAddress: ATTRIBUTE_TYPE.STRING,
    phoneNumber: ATTRIBUTE_TYPE.STRING,
  },

  relationships: {
    conversations: {
      resourceType: ResourceTypes.Conversations,
      relationshipType: RELATIONSHIP_TYPE.MANY,
    },
  },
};

export default Participant;
