import { ComponentType, ReactNode, createElement } from 'react';
import styled from 'styled-components';

import { FormattedMessage } from '~/components/i18n';
import { IconProps } from '~/components/icons/types';
import { zIndex, createTransform } from '~/styles';

const mixins = {
  active: {
    opacity: 1,
    transition: 'opacity 1s ease',
    zIndex: zIndex.dropZone,
  },

  focused: {
    backgroundColor: 'rgba(0,0,0,0.7)',
  },

  dropZoneFocusedImage: {
    ...createTransform('translate(-50%, -150%)'),
  },

  focusedTextVisible: {
    opacity: 1,
    transition: 'opacity 0.25s ease-in-out 0.1s',
  },
};

const StyledFocusedText = styled.div<{
  $focusedTextVisibleEnabled: boolean;
}>(props => ({
  color: 'white',
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '80%',
  ...createTransform('translate(-50%, -50%)'),
  textAlign: 'center',
  opacity: 0,
  transition: 'opacity 0.25s ease-in-out',
  ...(props.$focusedTextVisibleEnabled ? mixins.focusedTextVisible : {}),
}));

const StyledDropZoneImage = styled.div<{
  $dropZoneFocusedImageEnabled: boolean;
}>(props => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '10%',
  ...createTransform('translate(-50%, -50%)'),
  transition: 'transform 0.25s ease-in-out',
  ...(props.$dropZoneFocusedImageEnabled ? mixins.dropZoneFocusedImage : {}),
}));

const StyledDropZoneOverlay = styled.div<{
  $activeEnabled: boolean;
  $focusedEnabled: boolean;
}>(props => ({
  position: 'absolute',
  height: '100%',
  width: '100%',
  backgroundColor: 'rgba(0,0,0,0.5)',
  opacity: 0,
  ...(props.$activeEnabled ? mixins.active : {}),
  ...(props.$focusedEnabled ? mixins.focused : {}),
}));

const StyledDropZoneOverlayContainer = styled.div<{
  $activeEnabled: boolean;
}>(props => ({
  position: 'absolute',
  top: 2,
  left: 2,
  right: 2,
  bottom: 2,
  zIndex: zIndex.hidden,
  ...(props.$activeEnabled ? mixins.active : {}),
}));

type Props = {
  //** State of the overlay. Invalid -> Doesn't support file type */
  isValid: boolean;
  /** State of the overlay. Active -> visible */
  isActive: boolean;
  /** State of the overlay. Focused -> user cursor inside dropzone */
  isFocused: boolean;
  /** Text to display in the overlay when it has focus */
  focusedText: string;
  /** Icon to be displayed in the overlay when active */
  icon?: ComponentType<IconProps>;
  /** Contents of the overlay */
  children?: ReactNode;
};

const DropZoneOverlay = (props: Props) => {
  const { isActive, isFocused, isValid, focusedText, icon } = props;

  return (
    <StyledDropZoneOverlayContainer $activeEnabled={isActive}>
      <StyledDropZoneOverlay
        $activeEnabled={isActive}
        $focusedEnabled={isFocused}
      >
        <StyledDropZoneImage $dropZoneFocusedImageEnabled={isFocused}>
          {icon && createElement(icon, { type: 'white' }, null)}
        </StyledDropZoneImage>
        {focusedText && (
          <StyledFocusedText $focusedTextVisibleEnabled={isFocused}>
            {isValid ? (
              <FormattedMessage id={focusedText} />
            ) : (
              <FormattedMessage id="imageDropError" />
            )}
          </StyledFocusedText>
        )}
      </StyledDropZoneOverlay>
      {props.children}
    </StyledDropZoneOverlayContainer>
  );
};

DropZoneOverlay.displayName = 'DropZoneOverlay';

export default DropZoneOverlay;
