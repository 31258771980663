import { ATTRIBUTE_TYPE, RELATIONSHIP_TYPE } from '../constants';
import {
  ResourceDefinition,
  ResourceTypes,
} from '~/store/features/api/resources/types';

const TemplateTaskListItem: ResourceDefinition = {
  attributes: {
    text: ATTRIBUTE_TYPE.STRING,
    timeFrame: ATTRIBUTE_TYPE.STRING,
    dueDate: ATTRIBUTE_TYPE.DATE,
    sortOrder: ATTRIBUTE_TYPE.NUMBER,
    complete: ATTRIBUTE_TYPE.BOOLEAN,
    deleted: ATTRIBUTE_TYPE.BOOLEAN,
    parentTaskId: ATTRIBUTE_TYPE.NUMBER,
  },
  relationships: {
    taskList: {
      resourceType: ResourceTypes.TemplateTaskLists,
      relationshipType: RELATIONSHIP_TYPE.ONE,
    },
  },
};
export default TemplateTaskListItem;
