import { ATTRIBUTE_TYPE } from '../constants';
import { ResourceDefinition } from '~/store/features/api/resources/types';

const LoopPeople: ResourceDefinition = {
  attributes: {
    id: ATTRIBUTE_TYPE.STRING,
    memberId: ATTRIBUTE_TYPE.STRING,
    userId: ATTRIBUTE_TYPE.STRING,
    profileId: ATTRIBUTE_TYPE.STRING,
    invitationId: ATTRIBUTE_TYPE.STRING,
    name: ATTRIBUTE_TYPE.STRING,
    emailAddress: ATTRIBUTE_TYPE.STRING,
    profileName: ATTRIBUTE_TYPE.STRING,
    roleName: ATTRIBUTE_TYPE.STRING,
    isAdmin: ATTRIBUTE_TYPE.BOOLEAN,
    imageUrl: ATTRIBUTE_TYPE.STRING,
    isMe: ATTRIBUTE_TYPE.BOOLEAN,
    profileTypeId: ATTRIBUTE_TYPE.NUMBER,
  },
};
export default LoopPeople;
