import { useState } from 'react';
import styled from 'styled-components';
import { spacing } from '~/styles';
import { SecondaryButton, LinkButton } from '~/components/buttons';
import { SttpIcon } from '~/components/icons';
import { FormattedMessage } from '~/components/i18n';
import { useIsDesktop, useOnMount } from '~/hooks';
import APP_CONFIG from '~/config';
import {
  Engagement,
  fetchEngagement,
} from '~/services/stpp-engagement-service';

const {
  ui: { stppBaseUrl },
} = APP_CONFIG;

const StyledSttpLink = styled.span({
  marginRight: spacing.smaller,
});

type Props = {
  loopId: string;
};

function buildLink(engagement: Engagement): string {
  return `${stppBaseUrl}/workspaces/${engagement.workspaceId}/clients/${engagement.engagementId}/overview`;
}

export function StppLink(props: Props) {
  const { loopId } = props;
  const isDesktop = useIsDesktop();
  const [engagement, setEngagement] = useState<Engagement | null>(null);

  useOnMount(async () => {
    const engagement = await fetchEngagement(loopId);
    setEngagement(engagement);
  });

  if (!!stppBaseUrl && !!engagement?.workspaceId) {
    return isDesktop ? (
      <StyledSttpLink>
        <SecondaryButton
          id="showing-time-plus-link"
          link={buildLink(engagement)}
          openNewTab
          icon={SttpIcon}
        >
          <FormattedMessage id="loop-details:overview.actions.stppLink" />
        </SecondaryButton>
      </StyledSttpLink>
    ) : (
      <LinkButton
        id="mobile-showing-time-plus-link"
        link={buildLink(engagement)}
        openNewTab
        icon={SttpIcon}
      />
    );
  }

  return null;
}

export default StppLink;
