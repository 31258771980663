import { Fragment } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from '~/components/i18n';

import HighlightTokens from '~/components/HighlightTokens';
import { TagIcon } from '~/components/icons';
import LoopDetailsLink from '~/components/LoopDetailsLink';
import {
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  spacing,
  utils,
} from '~/styles';

import LoopCardComplianceStatuses from '~/components/loops/LoopCard/LoopCardComplianceStatuses';
import LoopListCardBadge, {
  hasBadge,
} from '~/components/loops/LoopCard/LoopListCard/LoopListCardBadge';
import { getSortDisplay } from '~/components/loops/LoopCard/utils';
import { SortKey, AppliedSortType } from '~/components/loops/utils/sorting';
import {
  LoopResource,
  TextHighlight,
} from '~/store/features/api/resources/loop/types';
import { TransactionType } from '~/lib/utils/refData';
import { GetItemPropsOptions } from 'downshift';

const mixins = {
  searchResultItemHighlight: {
    backgroundColor: colors.menuItemHighlight,
  },
};

const StyledSecondaryLoopBadge = styled.div({
  display: 'inline-block',
  marginTop: 'auto',
});

const StyledSecondarySortDisplay = styled.div({
  ...fontSizes.callout,
  color: colors.secondaryText,
});

const StyledSearchResultSecondaryContent = styled.div({
  display: 'none',

  [breakpoints.XLARGE]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    flex: '0 0 10rem',
    marginLeft: 'auto',
    width: '10rem',
  },
});

const StyledMainLoopBadge = styled.div({
  display: 'inline-block',
  marginTop: spacing.smaller,

  [breakpoints.XLARGE]: {
    display: 'none',
  },
});

const StyledMainSortDisplay = styled.div({
  ...fontSizes.callout,
  color: colors.secondaryText,
  lineHeight: spacing.medium,
  marginTop: spacing.smallest,

  [breakpoints.XLARGE]: {
    display: 'none',
  },
});

const StyledCreator = styled.div({
  ...utils.text.truncate,
  ...fontSizes.callout,
  color: colors.secondaryText,
  lineHeight: spacing.medium,
  marginTop: spacing.smallest,
});

const StyledRemainingHighlights = styled.div({
  ...fontSizes.callout,
  lineHeight: spacing.medium,
  marginTop: spacing.smallest,
  color: colors.secondaryText,
});

const StyledTagList = styled.div({
  flex: '1 1 auto',
  ...utils.text.truncate,
});

const StyledTagIcon = styled.div({
  flex: '0 0 auto',
  height: spacing.small,
  marginRight: spacing.smaller,
});

const StyledTags = styled.div({
  ...fontSizes.callout,
  display: 'flex',
  alignItems: 'center',
  marginTop: spacing.smallest,
  lineHeight: spacing.medium,
  color: colors.secondaryText,
});

const StyledComplianceContainer = styled.div({
  ...fontSizes.callout,
  lineHeight: spacing.medium,
  marginTop: spacing.smallest,
});

const StyledLoopTypeAndStatus = styled.div({
  ...fontSizes.callout,
  lineHeight: spacing.medium,
  color: colors.secondaryText,
  marginTop: spacing.smallest,
});

const StyledLoopAddress = styled.div({
  ...fontSizes.callout,
  lineHeight: spacing.medium,
  marginTop: spacing.smallest,
});

const StyledLoopName = styled.div({
  ...utils.text.truncate,
  fontWeight: fontWeights.semiBold,
  marginBottom: `-${spacing.smallest}`,
});

const StyledSearchResultMainContent = styled.div({
  flex: '0 0 100%',
  width: '100%',

  [breakpoints.XLARGE]: {
    flex: '1 1 auto',
    minWidth: 0,
    width: 'auto',
  },
});

const StyledSearchResultContainer = styled.div({
  display: 'flex',
});

const StyledLoopDetailsLink = styled(LoopDetailsLink)({
  color: colors.black,
});

const StyledSearchResultItem = styled.li<{
  $searchResultItemHighlightEnabled: boolean;
}>(props => ({
  margin: 0,
  padding: `${spacing.normal} ${spacing.medium}`,
  cursor: 'pointer',

  '&:not(:first-of-type)': {
    borderTop: `1px solid ${colors.divider}`,
  },

  ...(props.$searchResultItemHighlightEnabled
    ? mixins.searchResultItemHighlight
    : {}),
}));

const StyledSeparator = styled.span({
  marginLeft: spacing.smallest,
  marginRight: spacing.smallest,
});

function Separator() {
  return <StyledSeparator>&bull;</StyledSeparator>;
}

type Props = {
  loop: LoopResource;
  loopIndex: number;
  isAdmin: boolean;
  searchValue: string;
  hasComplianceGroups: boolean;
  appliedSort: AppliedSortType;
  transactionTypes: Array<TransactionType>;
  highlightedIndex: number | null;
  getItemProps: (
    props: GetItemPropsOptions<LoopResource>
  ) => Record<string, unknown>;
  updateSearchQueryParameter: (searchValue: string) => void;
};

function findTextHighlightListByKey(
  highlights: Array<TextHighlight>,
  fieldKey: string
): Array<TextHighlight> {
  return highlights.filter(highlight => highlight.fieldKey === fieldKey);
}

function findTextHighlightByKey(
  highlights: Array<TextHighlight>,
  fieldKey: string
): TextHighlight | null {
  const items: Array<TextHighlight> = findTextHighlightListByKey(
    highlights,
    fieldKey
  );
  return items.length > 0 ? items[0] : null;
}

function LoopSearchResultItem(props: Props) {
  const {
    loop,
    loopIndex,
    getItemProps,
    isAdmin,
    searchValue,
    hasComplianceGroups,
    appliedSort,
    highlightedIndex,
    transactionTypes,
    updateSearchQueryParameter,
  } = props;
  const sortDisplay = getSortDisplay(appliedSort, loop, [SortKey.AgentName]);
  const hasCardBadge = hasBadge(loop);
  const searchHighlights: Array<TextHighlight> = loop.searchHighlights || [];
  const addressHighlight: TextHighlight | null = findTextHighlightByKey(
    searchHighlights,
    'fullAddress'
  );
  const showAddress =
    !!loop.fullAddress &&
    loop.name !== loop.fullAddress &&
    !!loop.fullAddress &&
    loop.name !== loop.fullAddress &&
    !!addressHighlight;
  const loopTitle = loop.name || loop.fullAddress;
  const loopTitleHighlight: TextHighlight | null = !!loop.name
    ? findTextHighlightByKey(searchHighlights, 'name')
    : addressHighlight;
  const tagHighlights: Array<TextHighlight> = findTextHighlightListByKey(
    searchHighlights,
    'loopTags.name'
  );
  const tagHighlightTokens = tagHighlights.reduce(
    (tokens: Array<string>, tagHighlight) => {
      return tokens.concat(tagHighlight.highlightTokens);
    },
    []
  );
  const remainingHighlights = searchHighlights
    .filter(highlight => {
      return (
        [loopTitleHighlight, addressHighlight, ...tagHighlights].indexOf(
          highlight
        ) === -1
      );
    })
    .splice(0, 2); // Show at most two additional fields
  let transactionType, loopStatus;

  if (loop.transactionTypeId) {
    transactionType = transactionTypes.find(
      x => x.id === loop.transactionTypeId
    );

    if (!!transactionType && loop.loopStatusId) {
      loopStatus = transactionType.statuses.find(
        x => x.id === loop.loopStatusId
      );
    }
  }

  return (
    <StyledSearchResultItem
      {...getItemProps({ item: loop, index: loopIndex })}
      $searchResultItemHighlightEnabled={loopIndex === highlightedIndex}
    >
      <StyledLoopDetailsLink
        loopId={loop.id}
        onClick={() => updateSearchQueryParameter(searchValue)}
      >
        <StyledSearchResultContainer>
          <StyledSearchResultMainContent>
            <StyledLoopName>
              <HighlightTokens
                sourceText={loopTitle}
                tokens={
                  loopTitleHighlight ? loopTitleHighlight.highlightTokens : []
                }
              />
            </StyledLoopName>

            {showAddress && (
              <StyledLoopAddress>
                <HighlightTokens
                  sourceText={loop.fullAddress}
                  tokens={
                    addressHighlight ? addressHighlight.highlightTokens : null
                  }
                />
              </StyledLoopAddress>
            )}

            {!!transactionType && (
              <StyledLoopTypeAndStatus>
                {!!transactionType && (
                  <Fragment>
                    {transactionType.text}
                    {!!loopStatus && (
                      <Fragment>
                        <Separator />
                        {loopStatus.text}
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </StyledLoopTypeAndStatus>
            )}

            {hasComplianceGroups && (
              <StyledComplianceContainer>
                <LoopCardComplianceStatuses loopData={loop} />
              </StyledComplianceContainer>
            )}

            {!!loop.tags &&
              loop.tags.length > 0 &&
              tagHighlights.length > 0 && (
                <StyledTags>
                  <StyledTagIcon>
                    <TagIcon type="hintText" height="100%" />
                  </StyledTagIcon>
                  <StyledTagList>
                    {loop.tags.map((tag, index, list) => (
                      <Fragment key={tag.name}>
                        <HighlightTokens
                          sourceText={tag.name}
                          tokens={tagHighlightTokens}
                        />
                        {index !== list.length - 1 && <Separator />}
                      </Fragment>
                    ))}
                  </StyledTagList>
                </StyledTags>
              )}

            {remainingHighlights.map((highlight, index) => {
              return (
                <StyledRemainingHighlights key={index}>
                  <strong>{highlight.fieldName}: </strong>
                  <HighlightTokens
                    sourceText={highlight.value}
                    tokens={highlight.highlightTokens}
                  />
                </StyledRemainingHighlights>
              );
            })}

            {loop.agentName && isAdmin && (
              <StyledCreator>
                <FormattedMessage
                  id="loops:createdBy"
                  values={{ user: loop.agentName }}
                />
              </StyledCreator>
            )}

            {!!sortDisplay && (
              <StyledMainSortDisplay>
                <FormattedMessage
                  id={sortDisplay.labelId}
                  values={{ value: sortDisplay.value }}
                />
              </StyledMainSortDisplay>
            )}

            {hasCardBadge && (
              <StyledMainLoopBadge>
                <LoopListCardBadge loopData={loop} />
              </StyledMainLoopBadge>
            )}
          </StyledSearchResultMainContent>
          <StyledSearchResultSecondaryContent>
            {!!sortDisplay && (
              <StyledSecondarySortDisplay>
                <FormattedMessage
                  id={sortDisplay.labelId}
                  values={{ value: sortDisplay.value }}
                />
              </StyledSecondarySortDisplay>
            )}

            {hasCardBadge && (
              <StyledSecondaryLoopBadge>
                <LoopListCardBadge loopData={loop} />
              </StyledSecondaryLoopBadge>
            )}
          </StyledSearchResultSecondaryContent>
        </StyledSearchResultContainer>
      </StyledLoopDetailsLink>
    </StyledSearchResultItem>
  );
}

export default LoopSearchResultItem;
